import { deleteFile } from "@/api/endpoints/files";
import {
    deletePost,
    editPost,
    getAssignedPosts,
    getPost,
    getPosts,
    postPost,
} from "@/api/endpoints/posts";
import { queryClient } from "@/api/query-client";
import { PostAddSchemaType, PostEditSchemaType } from "@/schemas/post.schema";
import { InfiniteData, useInfiniteQuery, useMutation, useQuery } from "@tanstack/react-query";
import type { PostPublic } from "@/types";

export const useGetPostsQuery = () => {
    return useQuery({
        queryKey: ["posts"],
        queryFn: () => getPosts(),
    });
};

export const useGetAssignedPostsInfiniteQuery = () => {
    return useInfiniteQuery({
        queryKey: ["postsInfinite"],
        queryFn: ({ pageParam }) => getAssignedPosts(pageParam),
        initialPageParam: { page: 0, pageSize: 20, sortOrder: "DESC" as const },
        getNextPageParam: (lastPage, allPages, lastPageParam) => {
            if (lastPage.length < lastPageParam.pageSize) {
                return undefined;
            }
            return { ...lastPageParam, page: lastPageParam.page + 1 };
        },
    });
};

export const useGetPostQuery = (id?: number) => {
    return useQuery({
        queryKey: ["posts", id],
        queryFn: () => getPost(id),
        enabled: !!id,
    });
};

export const usePostPostMutation = () => {
    return useMutation({
        mutationKey: ["postPost"],
        mutationFn: (data: PostAddSchemaType) => postPost(data),
        onSuccess: async data => {
            queryClient.setQueryData(["posts"], (curr?: PostPublic[]) => {
                if (curr) {
                    return [...curr, data];
                }
                return [data];
            });
            await queryClient.invalidateQueries({
                queryKey: ["postsInfinite"],
                exact: true,
            });
        },
    });
};

export const useEditPostMutation = () => {
    return useMutation({
        mutationFn: (data: PostEditSchemaType) => editPost(data),
        onSuccess: data => {
            queryClient.setQueryData(["posts", data.id], data);
            queryClient.setQueryData(["posts"], (curr?: PostPublic[]) => {
                if (!curr) {
                    return;
                }
                return curr.map(p => (p.id === data.id ? data : p));
            });
            queryClient.setQueryData(
                ["postsInfinite"],
                (curr?: InfiniteData<PostPublic[], unknown>) => {
                    if (!curr) {
                        return;
                    }
                    return {
                        ...curr,
                        pages: curr.pages.map(page => {
                            return page.map(p => (p.id === data.id ? data : p));
                        }),
                    };
                },
            );
        },
    });
};

export const useDeletePostMutation = () => {
    return useMutation({
        mutationFn: (id: number) => deletePost(id),
        onMutate: async (id: number) => {
            await queryClient.cancelQueries({ queryKey: ["posts"] });

            const previousPosts = queryClient.getQueryData<PostPublic[]>(["posts"]);

            queryClient.setQueryData(["posts"], (prev?: PostPublic[]) =>
                prev ? prev.filter((post: PostPublic) => post.id !== id) : [],
            );

            return { previousPosts };
        },
        onError: (err, id, context) => {
            queryClient.setQueryData(["posts"], context?.previousPosts);
        },
        onSettled: () => {
            void queryClient.invalidateQueries({ queryKey: ["posts"] });
            void queryClient.invalidateQueries({ queryKey: ["postsInfinite"] });
        },
        onSuccess: (data, id) => {
            queryClient.setQueryData(["posts"], (prev?: PostPublic[]) =>
                prev ? prev.filter((post: PostPublic) => post.id !== id) : [],
            );
        },
    });
};

export const useDeletePostFileMutation = (id?: number) => {
    return useMutation({
        mutationKey: ["deletePostFile"],
        mutationFn: (id: string) => deleteFile(id),
        onSuccess: async () => {
            await queryClient.invalidateQueries({ queryKey: ["posts", id] });
        },
    });
};
