import { httpErrorHandler } from "@/api/api";
import { usePostPasswordChangeMutation } from "@/api/queries/authQueries";
import { Button } from "@/components/ui/button/Button";
import {
    Form,
    FormControl,
    FormField,
    FormItem,
    FormLabel,
    FormMessage,
} from "@/components/ui/form/Form";
import { ProgressiveImageLoader } from "@/components/ui/image/ProgressiveImageLoader";
import { Input, InputActionButton, InputWrapper } from "@/components/ui/input/Input";
import { cn } from "@/lib/utils";
import { PasswordChangeSchema, type PasswordChangeSchemaType } from "@/schemas/auth.schema";
import { yupResolver } from "@hookform/resolvers/yup";
import { Eye, EyeOff, LoaderCircle } from "lucide-react";
import { useState } from "react";
import { useForm } from "react-hook-form";
import {useLocation, useNavigate, useParams} from 'react-router-dom';

export default function PasswordChangePage() {
    const location = useLocation();
    const navigate = useNavigate();
    const params = useParams();
    const authToken = params.t;
    const { mutateAsync, isPending } = usePostPasswordChangeMutation();

    const [isVisible1, setIsVisible1] = useState<boolean>(false);
    const toggleVisibility1 = () => setIsVisible1(prevState => !prevState);

    const [isVisible2, setIsVisible2] = useState<boolean>(false);
    const toggleVisibility2 = () => setIsVisible2(prevState => !prevState);

    const form = useForm<PasswordChangeSchemaType>({
        defaultValues: {
            password: "",
            confirmPassword: "",
            authToken: authToken,
        },
        mode: "all",
        resolver: yupResolver(PasswordChangeSchema),
    });

    const checkIfCanGoBack = (): boolean => {
        return location.key !== "default";
    };

    const onSubmit = async (data: PasswordChangeSchemaType) => {
        mutateAsync(data)
            .then(() => {
                if (authToken) {
                    void navigate("/");
                } else {
                    void navigate("/settings");
                }
            })
            .catch(error => {
                const { detail } = httpErrorHandler(error, {
                    detail: "Wystąpił błąd podczas zmiany hasła",
                });
                form.setError("password", {
                    type: "manual",
                    message: detail,
                });
                form.setError("confirmPassword", {
                    type: "manual",
                    message: detail,
                });
            });
    };

    return (
        <>
            <ProgressiveImageLoader
                alt="Background image"
                className={"absolute inset-0 z-0 opacity-80"}
                sources={{
                    mobile: {
                        lowRes: {
                            webp: "/images/background/auth_bg_img_small.webp",
                            jpeg: "/auth_bg/auth_bg_img_small.jpeg",
                        },
                        highRes: {
                            webp: "/images/background/auth_bg_img_medium.webp",
                            jpeg: "/images/background/auth_bg_img_medium.jpeg",
                        },
                    },
                    tablet: {
                        lowRes: {
                            webp: "/images/background/auth_bg_img_medium.webp",
                            jpeg: "/images/background/auth_bg_img_medium.jpeg",
                        },
                        highRes: {
                            webp: "/images/background/auth_bg_img_large.webp",
                            jpeg: "/images/background/auth_bg_img_large.jpeg",
                        },
                    },
                    desktop: {
                        lowRes: {
                            webp: "/images/background/auth_bg_img_large.webp",
                            jpeg: "/images/background/auth_bg_img_large.jpeg",
                        },
                        highRes: {
                            webp: "/images/background/auth_bg_img_2k.webp",
                            jpeg: "/images/background/auth_bg_img_2k.jpeg",
                        },
                    },
                }}
            />
            <div
                className={
                    "flex min-h-dvh w-full items-end md:items-center justify-center p-0 pt-6 md:p-6"
                }
            >
                <div
                    className={
                        "z-10 flex p-4 md:p-6 h-[85dvh] max-h-[calc(100dvh_-_var(--spacing)*4*2)] md:h-fit min-h-100 w-full rounded-b-none md:rounded-2xl border-0 md:border md:w-[clamp(20rem,50dvw,26rem)] flex-col items-center gap-6 overflow-auto rounded-2xl border-border-primary bg-surface-primary"
                    }
                >
                    <Form {...form}>
                        <form
                            className={"flex w-full h-full flex-col gap-7"}
                            onSubmit={form.handleSubmit(onSubmit)}
                            noValidate
                        >
                            <h1 className={"py-3 text-4xl font-semibold text-fg-primary"}>
                                Zmień hasło
                            </h1>
                            <fieldset className={"flex flex-col gap-2"}>
                                <FormField
                                    name={"password"}
                                    control={form.control}
                                    render={({ field }) => (
                                        <FormItem>
                                            <FormLabel>Hasło</FormLabel>
                                            <FormControl>
                                                <InputWrapper>
                                                    <Input
                                                        {...field}
                                                        type={isVisible1 ? "text" : "password"}
                                                        placeholder={"Hasło"}
                                                        autoComplete={"current-password"}
                                                        className={"rounded-e-none"}
                                                    />
                                                    <InputActionButton
                                                        className={"-ms-px rounded-s-none"}
                                                        onClick={toggleVisibility1}
                                                        aria-label={
                                                            isVisible1
                                                                ? "Hide password"
                                                                : "Show password"
                                                        }
                                                        aria-pressed={isVisible1}
                                                        aria-controls={"password"}
                                                    >
                                                        {isVisible1 ? <EyeOff /> : <Eye />}
                                                    </InputActionButton>
                                                </InputWrapper>
                                            </FormControl>
                                            <FormMessage />
                                        </FormItem>
                                    )}
                                />
                                <FormField
                                    name={"confirmPassword"}
                                    control={form.control}
                                    render={({ field }) => (
                                        <FormItem>
                                            <FormLabel>Hasło</FormLabel>
                                            <FormControl>
                                                <InputWrapper>
                                                    <Input
                                                        {...field}
                                                        type={isVisible2 ? "text" : "password"}
                                                        placeholder={"Hasło"}
                                                        autoComplete={"current-password"}
                                                        className={"rounded-e-none"}
                                                    />
                                                    <InputActionButton
                                                        className={"-ms-px rounded-s-none"}
                                                        onClick={toggleVisibility2}
                                                        aria-label={
                                                            isVisible2
                                                                ? "Hide password"
                                                                : "Show password"
                                                        }
                                                        aria-pressed={isVisible2}
                                                        aria-controls={"password"}
                                                    >
                                                        {isVisible1 ? <EyeOff /> : <Eye />}
                                                    </InputActionButton>
                                                </InputWrapper>
                                            </FormControl>
                                            <FormMessage />
                                        </FormItem>
                                    )}
                                />
                            </fieldset>
                            <footer className={"flex flex-row gap-3 mt-auto"}>
                                <Button
                                    type={"button"}
                                    size={"lg"}
                                    variant={"outline"}
                                    onClick={() => void navigate(-1)}
                                    className={cn(checkIfCanGoBack() ? "w-full" : "hidden")}
                                >
                                    Cofnij
                                </Button>
                                <Button
                                    disabled={isPending}
                                    type={"submit"}
                                    size={"lg"}
                                    onClick={form.handleSubmit(onSubmit)}
                                    className={"w-full"}
                                >
                                    {isPending ? (
                                        <>
                                            <LoaderCircle
                                                className={"animate-spin"}
                                                aria-hidden={"true"}
                                            />
                                            Zapisywanie...
                                        </>
                                    ) : (
                                        "Zapisz"
                                    )}
                                </Button>
                            </footer>
                        </form>
                    </Form>
                </div>
            </div>
        </>
    );
}
