import { ButtonProps } from "@/components/ui/button/Button";
import { cn } from "@/lib/utils";
import * as React from "react";

const InputActionButton = React.forwardRef<HTMLButtonElement, ButtonProps>(
    ({ className, ...props }, ref) => {
        return (
            <button
                ref={ref}
                className={cn(
                    "inline-flex items-center border border-border-primary bg-fill-primary px-3 text-sm font-medium text-text-primary rounded-sm transition-colors hover:bg-fill-primary-hover hover:text-text-primary focus-visible:z-10 outline-ring-focus outline-0 focus-visible:outline-2 disabled:cursor-not-allowed disabled:opacity-50 [&_svg]:pointer-events-none [&_svg]:size-4 [&_svg]:shrink-0 *:stroke-icon-tertiary hover:*:stroke-icon-primary cursor-pointer",
                    className,
                )}
                type={"button"}
                {...props}
            />
        );
    },
);
InputActionButton.displayName = "InputActionButton";

const InputWrapper = React.forwardRef<HTMLDivElement, React.ComponentProps<"div">>(
    ({ className, ...props }, ref) => {
        return (
            <div
                ref={ref}
                className={cn(
                    "relative flex flex-row [&_svg]:pointer-events-none [&_svg]:size-4 [&_svg]:shrink-0 *:stroke-icon-tertiary",
                    className,
                )}
                {...props}
            />
        );
    },
);
InputWrapper.displayName = "InputWrapper";

const Input = React.forwardRef<HTMLInputElement, React.ComponentProps<"input">>(
    ({ className, type, ...props }, ref) => {
        return (
            <input
                type={type}
                className={cn(
                    "flex h-10 w-full rounded-sm border border-border-primary bg-transparent px-3 py-1 text-base duration-100 transition-colors transition-outline file:border-0 file:bg-transparent file:text-sm file:font-medium file:text-text-secondary placeholder:text-text-tertiary/50 focus-visible:z-10 outline-ring-focus outline-0 focus-visible:outline-2 disabled:cursor-not-allowed disabled:opacity-50 [&::-webkit-search-cancel-button]:appearance-none [&::-webkit-search-decoration]:appearance-none [&::-webkit-search-results-button]:appearance-none [&::-webkit-search-results-decoration]:appearance-none",
                    className,
                )}
                ref={ref}
                {...props}
            />
        );
    },
);
Input.displayName = "Input";

export { Input, InputActionButton, InputWrapper };
