import { Button } from "@/components/ui/button/Button";
import {
    EmptyState,
    EmptyStateCircles,
    EmptyStateContent,
    EmptyStateDescription,
    EmptyStateFooter,
    EmptyStateTitle,
} from "@/components/ui/empty-state/EmptyState";
import { FeaturedIcon } from "@/components/ui/featured-icon/FeaturedIcon";
import { MessagesSquare } from "lucide-react";

interface ChatIndexEmptyProps {
    openCreateConversation: () => void;
}

export default function ChatIndexEmpty({ openCreateConversation }: ChatIndexEmptyProps) {
    return (
        <div
            className={
                "flex grow items-center justify-center md:rounded-lg md:border border-border-primary md:bg-surface-primary md:p-4"
            }
        >
            <EmptyState>
                <EmptyStateCircles className={"z-0"} />
                <FeaturedIcon size={"lg"} className={"z-10"}>
                    <MessagesSquare />
                </FeaturedIcon>
                <EmptyStateContent>
                    <EmptyStateTitle>Utwórz konwersację</EmptyStateTitle>
                    <EmptyStateDescription>
                        Aby skontaktować się z dowolnym użytkownikiem,
                        <br />
                        naciśnij przycisk “Utwórz konwersację”
                        <br />i wyszukaj użytkownika i pytaj o co chcesz.
                    </EmptyStateDescription>
                    <EmptyStateFooter>
                        <Button onClick={openCreateConversation} variant={"outline"}>
                            Utwórz konwersację
                        </Button>
                    </EmptyStateFooter>
                </EmptyStateContent>
            </EmptyState>
        </div>
    );
}
