import { useGetManagedUsersQuery } from "@/api/queries/usersQueries";
import ActivateUser from "@/components/features/manage/manage-users/ActivateUser";
import CreateUser from "@/components/features/manage/manage-users/CreateUser";
import DeleteUser from "@/components/features/manage/manage-users/DeleteUser";
import EditUser from "@/components/features/manage/manage-users/EditUser";
import ResetPassword from "@/components/features/manage/manage-users/ResetPassword";
import { TanstackTable } from "@/components/features/manage/tanstack-table/TanstackTable";
import { Badge } from "@/components/ui/badge/Badge";
import { Button } from "@/components/ui/button/Button";
import { Checkbox } from "@/components/ui/input/checkbox/Checkbox";
import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuGroup,
    DropdownMenuItem,
    DropdownMenuSeparator,
    DropdownMenuTrigger,
} from "@/components/ui/input/dropdown-menu/DropdownMenu";
import { useMediaQuery } from "@/hooks/useMediaQuery";
import { ColumnDef } from "@tanstack/react-table";
import { format, isValid, parseISO } from "date-fns";
import { Edit, ListPlus, Plus, Trash2 } from "lucide-react";
import { useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { UserPrivate } from "@/types";

export default function ManageUsers() {
    const breakpoint = useMediaQuery("md");

    const navigate = useNavigate();

    const [openResetPassword, setOpenResetPassword] = useState<boolean>(false);
    const [openChangeActive, setOpenChangeActive] = useState<boolean>(false);
    const [openEdit, setOpenEdit] = useState<boolean>(false);
    const [openDelete, setOpenDelete] = useState<boolean>(false);
    const [openUserAdd, setOpenUserAdd] = useState<boolean>(false);

    const [selectedRows, setSelectedRows] = useState<UserPrivate[]>([]);
    const [selectedUser, setSelectedUser] = useState<UserPrivate | null>(null);

    const { data = [], isPending } = useGetManagedUsersQuery();
    const getSelectedRowsFn = (rows: UserPrivate[]) => {
        setSelectedRows(rows);
        setOpenDelete(true);
    };

    const triggerPopupEdit = (user?: UserPrivate) => {
        if (user) {
            setSelectedUser(user);
            setOpenEdit(true);
        }
    };
    const triggerPopupChangeActive = (user?: UserPrivate) => {
        if (user) {
            setSelectedUser(user);
            setOpenChangeActive(true);
        }
    };
    const triggerPopupResetPassword = (user?: UserPrivate) => {
        if (user) {
            setSelectedUser(user);
            setOpenResetPassword(true);
        }
    };
    const triggerPopupAddUser = () => {
        setOpenUserAdd(true);
    };

    const addGroup = useMemo(() => {
        if (breakpoint) {
            return [
                {
                    id: 1,
                    label: "Dodaj uż.",
                    icon: <Plus className={"h-4 w-4"} />,
                    func: () => triggerPopupAddUser(),
                },
                {
                    id: 2,
                    label: "Dodaj uży.",
                    icon: <ListPlus className={"h-4 w-4"} />,
                    func: () => void navigate("/manage/users/massImport"),
                },
            ];
        }
        return [
            {
                id: 1,
                label: "Dodaj uż.",
                icon: <Plus className={"h-4 w-4"} />,
                func: () => triggerPopupAddUser(),
            },
        ];
    }, [breakpoint, navigate]);
    const columns: ColumnDef<UserPrivate>[] = useMemo(
        () => [
            {
                id: "select",
                header: ({ table }) => (
                    <Checkbox
                        checked={
                            table.getIsAllPageRowsSelected()
                                ? true
                                : table.getIsSomePageRowsSelected()
                                  ? "indeterminate"
                                  : false
                        }
                        onCheckedChange={value => table.toggleAllPageRowsSelected(!!value)}
                        aria-label="Select all"
                    />
                ),
                cell: ({ row }) => (
                    <Checkbox
                        checked={row.getIsSelected()}
                        onCheckedChange={value => row.toggleSelected(!!value)}
                        aria-label={"Select row"}
                    />
                ),
                enableSorting: false,
                enableHiding: false,
            },
            {
                meta: "Stanowisko",
                id: "lvl",
                accessorKey: "lvl",

                cell: ({ getValue }) => {
                    const lvl = getValue();
                    if (lvl === 0) {
                        return <Badge variant={"blue"}>Uczeń</Badge>;
                    }
                    if (lvl === 1) {
                        return <Badge variant={"turquoise"}>Nauczyciel</Badge>;
                    }
                    if (lvl === 2) {
                        return <Badge variant={"purple"}>Administrator</Badge>;
                    }
                },
                header: "Stanowisko",
                filterFn: (row, id, value) => {
                    return value.includes(row.getValue(id));
                },
            },
            {
                meta: "Imię",
                id: "name",
                accessorKey: "name",
                header: "Imię",
            },
            {
                meta: "Nazwisko",
                id: "surname",
                accessorKey: "surname",
                header: "Nazwisko",
            },
            {
                meta: "Email",
                id: "email",
                accessorKey: "email",
                header: "Email",
            },
            {
                meta: "Nr.tel",
                id: "phone",
                accessorKey: "phone",
                header: " Nr.tel",
            },
            {
                meta: "Data ur.",
                id: "birthdate",
                accessorKey: "birthdate",
                sortType: "datetime",
                cell: ({ getValue }) => {
                    const date = getValue();
                    if (typeof date === "string" && isValid(parseISO(date))) {
                        return format(new Date(date), "dd.MM.yyyy");
                    }
                    return date;
                },
                header: "Data ur.",
            },
            {
                meta: "Utworzono",
                id: "createdAt",
                accessorKey: "createdAt",
                sortType: "datetime",
                cell: ({ getValue }) => {
                    const date = getValue();
                    if (typeof date === "string" && isValid(parseISO(date))) {
                        return format(new Date(date), "dd.MM.yyyy");
                    }
                    return date;
                },
                header: "Utworzono",
            },
            {
                meta: "Autoryzacja",
                id: "last_login",
                accessorKey: "last_login",
                cell: ({ getValue }) => {
                    const last_login = getValue();
                    if (last_login) {
                        return <Badge variant={"success"}>Zautoryzowany</Badge>;
                    } else {
                        return <Badge variant={"destructive"}>Niezautoryzowany</Badge>;
                    }
                },
                header: "Autoryzacja",
                filterFn: (row, id, value) => {
                    return value.includes(row.getValue(id));
                },
            },
            {
                meta: "Aktywność",
                id: "active",
                accessorKey: "active",
                cell: ({ getValue }) => {
                    const active = getValue();
                    if (active === true) {
                        return <Badge variant={"success"}>Aktywny</Badge>;
                    }
                    if (active === false) {
                        return <Badge variant={"destructive"}>Nieaktywny</Badge>;
                    }
                    return "";
                },
                header: "Aktywność",
                filterFn: (row, id, value) => {
                    return value.includes(row.getValue(id));
                },
            },
            {
                id: "actions",
                enableHiding: false,
                enableSorting: false,
                cell: ({ row }) => {
                    const rowOrigin = row.original;
                    return (
                        <div className={"flex w-full justify-end"}>
                            <Button
                                size={"sm"}
                                variant={"ghost"}
                                variantColor={"destructive"}
                                onClick={() => getSelectedRowsFn([rowOrigin])}
                            >
                                <Trash2 />
                            </Button>
                            <DropdownMenu>
                                <DropdownMenuTrigger asChild>
                                    <Button size={"sm"} variant={"ghost"}>
                                        <Edit />
                                    </Button>
                                </DropdownMenuTrigger>
                                <DropdownMenuContent align={"end"}>
                                    <DropdownMenuGroup>
                                        <DropdownMenuItem
                                            onClick={() => triggerPopupEdit(rowOrigin)}
                                        >
                                            Edytuj
                                        </DropdownMenuItem>
                                    </DropdownMenuGroup>
                                    <DropdownMenuSeparator />
                                    <DropdownMenuGroup>
                                        <DropdownMenuItem
                                            className={"text-text-destructive"}
                                            onClick={() => triggerPopupChangeActive(rowOrigin)}
                                        >
                                            {rowOrigin.active ? "Dezaktywuj" : "Aktywuj"}
                                        </DropdownMenuItem>
                                        <DropdownMenuItem
                                            className={"text-text-destructive"}
                                            onClick={() => triggerPopupResetPassword(rowOrigin)}
                                        >
                                            Resetuj hasło
                                        </DropdownMenuItem>
                                    </DropdownMenuGroup>
                                </DropdownMenuContent>
                            </DropdownMenu>
                        </div>
                    );
                },
            },
        ],
        [],
    );

    return (
        <>
            <CreateUser open={openUserAdd} onOpenChange={setOpenUserAdd} />
            <EditUser
                user={selectedUser}
                open={openEdit}
                onOpenChange={setOpenEdit}
                callback={() => {
                    setSelectedUser(null);
                }}
            />
            <DeleteUser selected={selectedRows} open={openDelete} onOpenChange={setOpenDelete} />
            <ActivateUser
                user={selectedUser}
                open={openChangeActive}
                onOpenChange={setOpenChangeActive}
            />
            <ResetPassword
                user={selectedUser}
                open={openResetPassword}
                onOpenChange={setOpenResetPassword}
            />
            <div className={"base-direction-col"}>
                <TanstackTable
                    className={"flex flex-col gap-4 w-full viewport-height"}
                    label={"Użytkownicy"}
                    name={"users"}
                    isLoading={isPending}
                    columns={columns}
                    defaultColumnVisibility={{
                        phone: false,
                        birthdate: false,
                        last_login: false,
                        active: false,
                        createdAt: false,
                    }}
                    data={data}
                    getSelectedRows={getSelectedRowsFn}
                    filterBy={[
                        {
                            id: "lvl",
                            title: "Stanowisko",
                            options: [
                                {
                                    value: 0,
                                    label: "Uczeń",
                                },
                                {
                                    value: 1,
                                    label: "Nauczyciel",
                                },
                                {
                                    value: 2,
                                    label: "Administrator",
                                },
                            ],
                        },
                        {
                            id: "active",
                            title: "Aktywność",
                            options: [
                                {
                                    label: "Aktywny",
                                    value: true,
                                },
                                {
                                    label: "Nieaktywny",
                                    value: false,
                                },
                            ],
                        },
                    ]}
                    addGroup={addGroup}
                />
            </div>
        </>
    );
}
