"use no memo";

import { useTanstackTableDynamicContext } from "@/components/features/manage/tanstack-table-dynamic/TanstackTableDynamic";
import { Form, FormControl, FormField, FormItem } from "@/components/ui/form/Form";
import { Input, InputWrapper } from "@/components/ui/input/Input";
import {
    Select,
    SelectContent,
    SelectGroup,
    SelectItem,
    SelectTrigger,
    SelectValue,
} from "@/components/ui/input/select/Select";
import { Cell, Column, Row } from "@tanstack/react-table";
import { isValid } from "date-fns";
import { ChangeEvent, Fragment, ReactNode } from "react";
import { useForm } from "react-hook-form";
import { DateInput, DatePicker } from "@/components/ui/input/date-picker/DatetimePicker";
import { parseDate, parseAbsoluteToLocal, type ZonedDateTime } from "@internationalized/date";

interface selectOptionsT {
    label: string;
    value: string;
}

interface DataTableDynamicCellT<TData, TValue> {
    cell: Cell<TData, TValue>;
    row: Row<TData>;
    column: Column<TData, TValue>;
    getValue: () => TValue;
    type: "text" | "select" | "number" | "date" | "phone" | "email";
    options?: selectOptionsT[];
    viewCell?: () => ReactNode | undefined | string;
    placeholder?: string;
}

interface dataTableDynamicCellFormT {
    value: string | number | undefined;
}

export default function TanstackTableDynamicCell<TData, TValue>({
    row,
    column,
    type,
    options,
    getValue,
    viewCell,
    placeholder,
}: DataTableDynamicCellT<TData, TValue>) {
    const { editedRows, updateData } = useTanstackTableDynamicContext();

    const isViewCell = viewCell !== undefined;

    const initialValue = getValue();

    const onBlur = (event: ChangeEvent<HTMLInputElement>) => {
        const eventValue = event.target.value;
        updateData(row.index, column.id, eventValue);
    };

    const onSubmit = (data: dataTableDynamicCellFormT) => {
        updateData(row.index, column.id, data.value);
    };

    const form = useForm<dataTableDynamicCellFormT>({
        mode: "all",
        values: {
            value: initialValue as string,
        },
        defaultValues: {
            value: initialValue as string,
        },
    });

    let content;

    if (editedRows[row.index]) {
        if (type === "text") {
            content = (
                <FormField
                    name={"value"}
                    control={form.control}
                    render={({ field }) => (
                        <FormItem>
                            <FormControl>
                                <Input {...field} placeholder={placeholder} onBlur={onBlur} />
                            </FormControl>
                        </FormItem>
                    )}
                />
            );
        }
        if (type === "select") {
            content = (
                <FormField
                    control={form.control}
                    name={"value"}
                    render={({ field }) => (
                        <FormItem>
                            <Select
                                defaultValue={field.value?.toString()}
                                onValueChange={newValue => {
                                    field.onChange(newValue);
                                    onSubmit(
                                        newValue
                                            ? { value: newValue.toString() }
                                            : { value: newValue },
                                    );
                                }}
                            >
                                <FormControl>
                                    <SelectTrigger>
                                        <SelectValue placeholder="Wybierz..." />
                                    </SelectTrigger>
                                </FormControl>
                                <SelectContent>
                                    <SelectGroup>
                                        {options?.map(option => (
                                            <SelectItem
                                                key={option.value}
                                                value={String(option.value)}
                                            >
                                                {option.label}
                                            </SelectItem>
                                        ))}
                                    </SelectGroup>
                                </SelectContent>
                            </Select>
                        </FormItem>
                    )}
                />
            );
        }
        if (type === "email") {
            content = (
                <FormField
                    name={"value"}
                    control={form.control}
                    render={({ field }) => (
                        <FormItem>
                            <FormControl>
                                <Input {...field} placeholder={placeholder} onBlur={onBlur} />
                            </FormControl>
                        </FormItem>
                    )}
                />
            );
        }
        if (type === "phone") {
            content = (
                <FormField
                    name={"value"}
                    control={form.control}
                    render={({ field }) => (
                        <FormItem>
                            <FormControl>
                                <Input {...field} placeholder={placeholder} onBlur={onBlur} />
                            </FormControl>
                        </FormItem>
                    )}
                />
            );
        }
        if (type === "date") {
            content = (
                <FormField
                    control={form.control}
                    name={"value"}
                    rules={{ required: { value: true, message: "Wymagane" } }}
                    render={({ field }) => {
                        let dateISOstring: ZonedDateTime | null = null;
                        if (field.value) {
                            const date = new Date(field.value);
                            if (isValid(date)) {
                                dateISOstring = parseAbsoluteToLocal(date.toISOString());
                            }
                        }
                        return (
                            <FormItem className="w-full">
                                <DatePicker
                                    aria-label={"birthdate"}
                                    granularity={"day"}
                                    value={dateISOstring}
                                    onChange={date => {
                                        if (date) {
                                            field.onChange(date.toString());
                                            onSubmit({
                                                value: date.toString(),
                                            });
                                        } else {
                                            field.onChange(null);
                                            onSubmit({
                                                value: undefined,
                                            });
                                        }
                                    }}
                                >
                                    <InputWrapper>
                                        <DateInput />
                                    </InputWrapper>
                                </DatePicker>
                            </FormItem>
                        );
                    }}
                />
            );
        }
        return (
            <Form {...form}>
                <form onSubmit={form.handleSubmit(onSubmit)} noValidate>
                    {content}
                </form>
            </Form>
        );
    } else {
        return <Fragment>{isViewCell ? viewCell() : (initialValue as string)}</Fragment>;
    }
}
