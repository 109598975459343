import { Button } from "@/components/ui/button/Button";
import { cn } from "@/lib/utils";
import { ArrowLeft } from "lucide-react";

interface FilesNavigationBackProps {
    onClick: () => void;
    show: boolean;
}

export function FilesNavigationBack({ onClick, show }: FilesNavigationBackProps) {
    return (
        <Button onClick={onClick} className={cn(!show && "hidden")} variant="ghost">
            <ArrowLeft />
        </Button>
    );
}
