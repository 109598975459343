import { usePostLogoutMutation } from "@/api/queries/authQueries";
import SettingOptionHeader from "@/components/features/settings/SettingOptionHeader.js";
import { Avatar, AvatarEdit, AvatarFallback, AvatarImage } from "@/components/ui/avatar/Avatar";
import { Button } from "@/components/ui/button/Button";
import { Tabs, TabsList, TabsTrigger } from "@/components/ui/tabs/Tabs";
import { useTheme } from "@/context/Theme";
import { useLvl } from "@/hooks/useLvl";
import { useManageNotifications } from "@/hooks/useNotifications";
import { cn } from "@/lib/utils";
import { useCredentials, useRemoveCredentials } from "@/store/authStore";
import { BellOff, BellRing, Lock, LogOut, UploadCloud, User } from "lucide-react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { ThemeRadioGroup } from "@/components/features/settings/ThemeRadioGroup";
import { getAvatar } from "@/utils/getAvatar";
import { UserAvatarEditor } from "@/components/features/user/UserAvatarEditor";

export default function SettingsPage() {
    const navigate = useNavigate();
    const removeCredentials = useRemoveCredentials();
    const { name, surname, lvl, avatarURL } = useCredentials();
    const userRole = useLvl(lvl);
    const { mutate, isPending } = usePostLogoutMutation();
    const { isGranted, areNotificationsSupported, enableNotifications, disableNotifications } =
        useManageNotifications();

    const [openCropper, setOpenCropper] = useState<boolean>(false);

    const [notificationValue, setNotificationValue] = useState<
        "notifications-off" | "notifications-on"
    >(isGranted ? "notifications-on" : "notifications-off");

    const onNotificationValueChange = () => {
        if (isGranted) {
            setNotificationValue("notifications-off");
            disableNotifications()
                .then(() => setNotificationValue("notifications-off"))
                .catch(() => setNotificationValue("notifications-on"));
        } else {
            setNotificationValue("notifications-on");
            enableNotifications()
                .then(() => setNotificationValue("notifications-on"))
                .catch(() => setNotificationValue("notifications-off"));
        }
    };

    const handleLogOut = () => {
        mutate();
        removeCredentials();
        void navigate("/login");
    };

    const handleClickPasswordChange = () => {
        void navigate("/auth/passwordChange/");
    };

    return (
        <>
            <UserAvatarEditor open={openCropper} onOpenChange={setOpenCropper} />
            <div className={"base-direction-col"}>
                <div
                    className={
                        "flex min-h-full w-full flex-col md:rounded-xl md:border border-border-primary md:bg-surface-primary p-0"
                    }
                >
                    <div className={"flex items-center gap-4 py-6 md:px-3"}>
                        <Avatar size={"lg"}>
                            <AvatarImage src={getAvatar(avatarURL)} alt="avatar" />
                            <AvatarFallback>
                                <User className={"h-4 w-4"} />
                            </AvatarFallback>
                        </Avatar>
                        <div className={"space-y-0.5"}>
                            <h5 className={"text-lg font-semibold"}>{name + " " + surname}</h5>
                            <p className={"text-text-tertiary text-sm"}>{userRole}</p>
                        </div>
                    </div>
                    <div
                        className={
                            "flex gap-4 md:border-t-1 border-border-primary py-6 md:px-4 flex-col md:flex-row"
                        }
                    >
                        <SettingOptionHeader
                            className={"md:w-54 w-full"}
                            title={"Zmień motyw"}
                            description={"Wybierz swój motyw."}
                        />
                        <div className={"flex gap-4 w-full"}>
                            <ThemeRadioGroup />
                        </div>
                    </div>
                    <div
                        className={
                            "flex gap-4 md:border-t-1 border-border-primary py-6 md:px-4 flex-col md:flex-row"
                        }
                    >
                        <SettingOptionHeader
                            className={"md:w-56 w-full"}
                            title={"Zmień Awatar"}
                            description={
                                "Naciśnij, i zmień swojego awatara na jakiego tylko chcesz."
                            }
                        />
                        <div className={"flex items-center gap-4"}>
                            <AvatarEdit className={"size-20"} onClick={() => setOpenCropper(true)}>
                                <AvatarImage src={getAvatar(avatarURL)} alt="avatar" />
                            </AvatarEdit>
                            <Button variant={"outline"} onClick={() => setOpenCropper(true)}>
                                <UploadCloud />
                                Dodaj zdjęcie
                            </Button>
                        </div>
                    </div>
                    <div
                        className={
                            "flex gap-4  md:border-t-1 border-border-primary py-6 md:px-4 flex-col md:flex-row"
                        }
                    >
                        <SettingOptionHeader
                            className={"md:w-56 w-full"}
                            title={"Zmień hasło"}
                            description={"Naciśnij, przycisk aby zmienić swoje hasło."}
                        />
                        <Button
                            onClick={handleClickPasswordChange}
                            variant={"outline"}
                            className="w-fit"
                        >
                            <Lock />
                            Zmień hasło
                        </Button>
                    </div>
                    <div
                        className={cn(
                            "flex gap-4  md:border-t-1 border-border-primary py-6 md:px-4 flex-col md:flex-row",
                            !areNotificationsSupported && "hidden",
                        )}
                    >
                        <SettingOptionHeader
                            className={"md:w-56 w-full"}
                            title={"Powiadomienia"}
                            description={
                                "Pozostań na bieżąco! Wyraź zgodę na powiadomienia, aby otrzymywać najnowsze informacje."
                            }
                        />
                        <Tabs
                            value={notificationValue}
                            onValueChange={onNotificationValueChange}
                            className={"shrink-0"}
                        >
                            <TabsList>
                                <TabsTrigger value={"notifications-off"}>
                                    <BellOff />
                                    Off
                                </TabsTrigger>
                                <TabsTrigger value={"notifications-on"}>
                                    <BellRing />
                                    On
                                </TabsTrigger>
                            </TabsList>
                        </Tabs>
                    </div>
                    <div
                        className={
                            "flex gap-4 md:hidden  md:border-t-1 border-border-primary py-6 md:px-4 flex-col md:flex-row"
                        }
                    >
                        <SettingOptionHeader
                            className={"md:w-54 w-full"}
                            title={"Wyloguj się"}
                            description={"Naciśnij, przycisk aby wylogować sie z konta."}
                        />
                        <Button
                            disabled={isPending}
                            onClick={handleLogOut}
                            variant={"outline"}
                            variantColor={"destructive"}
                            className={"w-fit"}
                        >
                            <LogOut />
                            {isPending ? "Wylogowanie..." : "Wyloguj się"}
                        </Button>
                    </div>
                </div>
            </div>
        </>
    );
}
