import { forwardRef, HTMLAttributes, useMemo, type ComponentProps } from "react";
import { Check, CheckCheck, Info, User, Download, LoaderCircle, Play } from "lucide-react";
import { Avatar, AvatarFallback, AvatarImage } from "@/components/ui/avatar/Avatar";
import { getAvatar } from "@/utils/getAvatar";
import { cn } from "@/lib/utils";
import Image from "@/components/ui/image/Image";
import VideoPlayer, { MediaVideoPlayerProps } from "@/components/ui/media-viewer/VideoPlayer";
import AudioPlayer from "@/components/ui/media-viewer/AudioPlayer";
import { AudioPlayerProps } from "@/components/ui/media-viewer/AudioPlayer";
import { FileCard } from "@/components/ui/file-card/FileCard";
import { Button } from "@/components/ui/button/Button";
import { FileCardContent, FileCardTitle } from "@/components/ui/file-card/FileCard";
import { FileIcon } from "@/components/ui/file-icon/FileIcon";
import { geFileExtension } from "@/utils/files";
import { Link } from "react-router";
import { format, formatDistanceToNow, isToday } from "date-fns";
import { pl } from "date-fns/locale";

interface MessageBubbleProps extends HTMLAttributes<HTMLDivElement> {
    side: "start" | "end";
    variant: "primary" | "secondary";
}

const MessageBubble = forwardRef<HTMLDivElement, MessageBubbleProps>(
    ({ variant, side, className, ...props }, ref) => {
        return (
            <div
                ref={ref}
                data-side={side}
                data-variant={variant}
                className={cn(
                    "group data-[side=end]:justify-start data-[side=end]:flex-row-reverse full flex flex-row gap-2 py-2 w-full",
                    className,
                )}
                {...props}
            />
        );
    },
);
MessageBubble.displayName = "MessageBubble";

const MessageBubbleContainer = forwardRef<HTMLDivElement, HTMLAttributes<HTMLDivElement>>(
    ({ className, ...props }, ref) => {
        return (
            <div
                ref={ref}
                className={cn(
                    "space-y-1 group-data-[side=end]:ps-12 group-data-[side=start]:pe-12 overflow-hidden",
                    className,
                )}
                {...props}
            />
        );
    },
);
MessageBubbleContainer.displayName = "MessageBubbleContainer";

const MessageBubbleContent = forwardRef<HTMLDivElement, HTMLAttributes<HTMLDivElement>>(
    ({ className, ...props }, ref) => {
        return (
            <div
                ref={ref}
                className={cn(
                    "rounded-md border group-data-[side=start]:border-border-primary group-data-[variant=primary]:border-border-brand group-data-[variant=secondary]:bg-fill-secondary group-data-[variant=primary]:bg-fill-brand",
                    className,
                )}
                {...props}
            />
        );
    },
);
MessageBubbleContent.displayName = "MessageBubbleContent";

const MessageBubbleMessage = forwardRef<HTMLParagraphElement, HTMLAttributes<HTMLParagraphElement>>(
    ({ className, ...props }, ref) => {
        return (
            <p
                ref={ref}
                className={cn(
                    "p-2 w-full overflow-hidden whitespace-pre-line break-words text-sm group-data-[variant=secondary]:text-text-secondary group-data-[variant=primary]:text-text-on-brand-fill",
                    className,
                )}
                {...props}
            />
        );
    },
);
MessageBubbleMessage.displayName = "MessageBubbleMessage";

const MessageBubbleImage = forwardRef<HTMLImageElement, ComponentProps<typeof Image>>(
    ({ className, ...props }, ref) => {
        return (
            <div className={"p-1"}>
                <Image
                    ref={ref}
                    className={cn(
                        "grow rounded-sm max-h-68 md:max-h-76 lg:max-h-86 min-w-14",
                        className,
                    )}
                    layoutClassName={"rounded-sm grow w-full"}
                    loading={"lazy"}
                    {...props}
                />
            </div>
        );
    },
);
MessageBubbleImage.displayName = "MessageBubbleImage";

interface MessageBubbleVideoProps extends MediaVideoPlayerProps {
    className?: string;
}
const MessageBubbleVideo = ({ className, onClick, ...props }: MessageBubbleVideoProps) => {
    return (
        <div className={"p-1 relative my-auto"} onClick={onClick}>
            <span
                className={
                    "absolute top-1/2 left-1/2 z-50 -translate-1/2 p-2 rounded-full bg-fill-gray/40 backdrop-blur-sm"
                }
            >
                <Play
                    className={"fill-text-on-gray-fill stroke-text-on-gray-fill size-4"}
                    aria-hidden={"true"}
                />
            </span>
            <VideoPlayer
                variant={"minimal"}
                className={cn("max-h-52 md:max-h-62 lg:max-h-72", className)}
                {...props}
            />
        </div>
    );
};
MessageBubbleVideo.displayName = "MessageBubbleVideo";

interface MessageBubbleAudioProps extends AudioPlayerProps {
    className?: string;
}
const MessageBubbleAudio = ({ className, ...props }: MessageBubbleAudioProps) => {
    return (
        <div className={"p-1"}>
            <AudioPlayer className={cn("bg-surface-primary rounded-sm", className)} {...props} />
        </div>
    );
};
MessageBubbleAudio.displayName = "MessageBubbleAudio";

interface MessageBubbleFileProps {
    name?: string;
    url?: string;
    className?: string;
}
const MessageBubbleFile = ({ className, name, url, ...props }: MessageBubbleFileProps) => {
    return (
        <div className={"p-1"}>
            <FileCard className={cn("rounded-sm", className)} {...props}>
                <FileCardContent>
                    <FileIcon fileExtension={geFileExtension(name)} />
                    <FileCardTitle>{name}</FileCardTitle>
                    {url ? (
                        <Link to={url}>
                            <Button variant={"ghost"} size={"sm"}>
                                <Download />
                            </Button>
                        </Link>
                    ) : null}
                </FileCardContent>
            </FileCard>
        </div>
    );
};
MessageBubbleFile.displayName = "MessageBubbleFile";

interface MessageBubbleReplyProps {
    username?: string;
    content?: string;
    className?: string;
    children?: React.ReactNode;
}
const MessageBubbleReply = ({
    className,
    username,
    content,
    children,
    ...props
}: MessageBubbleReplyProps) => {
    return (
        <div className={cn("rounded-sm mx-1 mt-1 bg-surface-primary", className)} {...props}>
            <div>{children}</div>
            <div className={"space-y-1 w-full p-2"}>
                <p className="text-xs text-text-brand truncate">{username}</p>
                <p
                    className={
                        "line-clamp-10 whitespace-pre-line break-words text-sm text-text-tertiary empty:hidden"
                    }
                >
                    {content}
                </p>
            </div>
        </div>
    );
};
MessageBubbleReply.displayName = "MessageBubbleReply";

interface MessageBubbleAvatarProps extends HTMLAttributes<HTMLDivElement> {
    avatarURL?: string;
}
const MessageBubbleAvatar = forwardRef<HTMLDivElement, MessageBubbleAvatarProps>(
    ({ avatarURL, className, ...props }, ref) => {
        return (
            <div ref={ref} className={cn("h-full", className)} {...props}>
                <Avatar size={"sm"} className={"mt-auto"}>
                    <AvatarImage src={getAvatar(avatarURL)} />
                    <AvatarFallback>
                        <User />
                    </AvatarFallback>
                </Avatar>
            </div>
        );
    },
);
MessageBubbleAvatar.displayName = "MessageBubbleAvatar";

interface MessageBubbleUsernameProps extends HTMLAttributes<HTMLDivElement> {
    username: string;
}
const MessageBubbleUsername = forwardRef<HTMLDivElement, MessageBubbleUsernameProps>(
    ({ username, className, ...props }, ref) => {
        return (
            <div
                ref={ref}
                className={cn(
                    "w-full group-data-[side=start]:ps-3 group-data-[side=end]:pe-3",
                    className,
                )}
                {...props}
            >
                <p
                    className={
                        "text-xs truncate whitespace-nowrap text-text-tertiary/75 w-full group-data-[side=start]:text-start group-data-[side=end]:text-end"
                    }
                >
                    {username}
                </p>
            </div>
        );
    },
);
MessageBubbleUsername.displayName = "MessageBubbleUsername";

interface MessageBubbleFooterProps extends HTMLAttributes<HTMLDivElement> {
    createdAt?: string;
    isPending?: boolean;
    isSuccess?: boolean;
    isError?: boolean;
    onRetry?: () => void;
}
const MessageBubbleFooter = forwardRef<HTMLDivElement, MessageBubbleFooterProps>(
    ({ className, ...props }, ref) => {
        const { isPending, isSuccess, isError, createdAt, onRetry } = props;

        const icon = useMemo(() => {
            if (isPending) {
                return (
                    <LoaderCircle
                        className={
                            "size-3.5 group-data-[variant=secondary]:text-text-secondary group-data-[variant=primary]:text-text-on-brand-fill animate-spin"
                        }
                    />
                );
            } else if (isSuccess) {
                return (
                    <CheckCheck
                        className={
                            "size-3.5 group-data-[variant=secondary]:text-text-secondary group-data-[variant=primary]:text-text-on-brand-fill"
                        }
                    />
                );
            } else if (isError) {
                return (
                    <Info
                        className={
                            "size-3.5 group-data-[variant=secondary]:text-text-destructive group-data-[variant=primary]:text-text-on-brand-fill"
                        }
                    />
                );
            }
        }, [isError, isPending, isSuccess]);

        return (
            <div
                ref={ref}
                className={cn("flex items-center justify-end gap-1 px-2 pb-2", className)}
                onClick={onRetry}
            >
                {icon}
                <p
                    className={
                        "text-xs group-data-[variant=secondary]:text-text-tertiary group-data-[variant=primary]:text-text-on-brand-fill group-data-[side=end]:text-end"
                    }
                >
                    {createdAt ? formatTimeAgo(new Date(createdAt)) : ""}
                </p>
            </div>
        );
    },
);
MessageBubbleFooter.displayName = "MessageBubbleFooter";

const formatTimeAgo = (date: Date): string => {
    if (isToday(date)) {
        return format(date, "HH:mm");
    } else if (date > new Date(Date.now() - 30 * 24 * 60 * 60 * 1000)) {
        return formatDistanceToNow(date, { addSuffix: true, locale: pl });
    } else {
        return format(date, "MMM d, yyyy");
    }
};

export {
    MessageBubble,
    MessageBubbleContainer,
    MessageBubbleAudio,
    MessageBubbleVideo,
    MessageBubbleFile,
    MessageBubbleReply,
    MessageBubbleAvatar,
    MessageBubbleUsername,
    MessageBubbleFooter,
    MessageBubbleContent,
    MessageBubbleMessage,
    MessageBubbleImage,
};
