import { cn } from "@/lib/utils";
import * as AvatarPrimitive from "@radix-ui/react-avatar";
import { VariantProps, cva } from "class-variance-authority";
import { ImagePlus } from "lucide-react";
import React from "react";

const avatarVariants = cva(
    "relative overflow-hidden bg-fill-secondary flex shrink-0 rounded-md [&_svg]:pointer-events-none [&_svg]:shrink-0",
    {
        variants: {
            size: {
                xs: "size-4 text-xs [&_svg]:size-3",
                sm: "size-7 text-xs [&_svg]:size-4",
                md: "size-10 text-sm [&_svg]:size-4",
                lg: "size-11 text-md [&_svg]:size-5",
                xl: "size-12 text-md [&_svg]:size-5",
            },
        },
        defaultVariants: {
            size: "md",
        },
    },
);

interface AvatarProps
    extends React.ComponentPropsWithoutRef<typeof AvatarPrimitive.Root>,
        VariantProps<typeof avatarVariants> {
    className?: string;
}

const Avatar = React.forwardRef<React.ElementRef<typeof AvatarPrimitive.Root>, AvatarProps>(
    ({ className, size, ...props }, ref) => (
        <AvatarPrimitive.Root
            ref={ref}
            className={cn(avatarVariants({ size }), className)}
            {...props}
        />
    ),
);
Avatar.displayName = AvatarPrimitive.Root.displayName;

const AvatarImage = React.forwardRef<
    React.ElementRef<typeof AvatarPrimitive.Image>,
    React.ComponentPropsWithoutRef<typeof AvatarPrimitive.Image>
>(({ className, src, ...props }, ref) => (
    <AvatarPrimitive.Image
        ref={ref}
        className={cn("aspect-square h-full w-full", className)}
        src={src ? src : undefined}
        {...props}
    />
));
AvatarImage.displayName = AvatarPrimitive.Image.displayName;

const AvatarFallback = React.forwardRef<
    React.ElementRef<typeof AvatarPrimitive.Fallback>,
    React.ComponentPropsWithoutRef<typeof AvatarPrimitive.Fallback>
>(({ className, ...props }, ref) => (
    <AvatarPrimitive.Fallback
        ref={ref}
        className={cn(
            "flex h-full w-full items-center justify-center text-text-tertiary *:stroke-icon-tertiary",
            className,
        )}
        {...props}
    />
));
AvatarFallback.displayName = AvatarPrimitive.Fallback.displayName;

const AvatarEdit = React.forwardRef<React.ElementRef<typeof AvatarPrimitive.Root>, AvatarProps>(
    ({ className, size, children, ...props }, ref) => (
        <AvatarPrimitive.Root
            role={"button"}
            tabIndex={0}
            ref={ref}
            className={cn(
                avatarVariants({ size }),
                "group relative cursor-pointer hover:fill-secondary-hover active:fill-secondary-active ease-out duration-100 transition-all disabled:cursor-not-allowed disabled:pointer-events-none outline-ring-focus outline-0 focus-visible:outline-2 disabled:opacity-75",
                className,
            )}
            {...props}
        >
            <span
                className={
                    "absolute group-hover:opacity-75 opacity-50 inset-0 m-auto size-7 rounded-full bg-fill-gray grid place-items-center"
                }
            >
                <ImagePlus className={"pointer-events-none text-text-on-gray-fill"} />
            </span>
            {children}
        </AvatarPrimitive.Root>
    ),
);
AvatarEdit.displayName = "AvatarEdit";

export { Avatar, AvatarImage, AvatarFallback, AvatarEdit, avatarVariants };
