import { httpErrorHandler } from "@/api/api";
import { useGetMeetingQuery } from "@/api/queries/meetingsQuery";
import EditMeeting from "@/components/features/calendar/form/EditMeeting";
import LocationCard from "@/components/features/location/LocationCard";
import MeetingAttendance from "@/components/features/meeting/MeetingAttendance";
import MeetingMembers from "@/components/features/meeting/MeetingMembers";
import MeetingRemoteLink from "@/components/features/meeting/meeting-calendar-card/MeetingRemoteLink";
import { Button } from "@/components/ui/button/Button";
import { Separator } from "@/components/ui/separator/Separator";
import { Skeleton } from "@/components/ui/skeleton/Skeleton";
import { cn } from "@/lib/utils";
import { useCredentials } from "@/store/authStore";
import { format } from "date-fns";
import { pl } from "date-fns/locale";
import { PenLine, MapPinned, BuildingIcon, CalendarRange } from "lucide-react";
import { useEffect, useState } from "react";
import { useParams } from "react-router";
import { toast } from "sonner";

export default function MeetingPage() {
    const { id } = useParams();
    const [openEditDialog, setEditDialog] = useState<boolean>(false);
    const { data, isPending, isSuccess, isError, error, refetch } = useGetMeetingQuery(Number(id));
    const { lvl } = useCredentials();

    const handleOpenEdit = () => {
        setEditDialog(true);
    };

    useEffect(() => {
        if (isError) {
            const { title, detail } = httpErrorHandler(error);
            toast.error(title, {
                description: detail,
                action: (
                    <Button
                        size={"sm"}
                        className={"ml-auto"}
                        variant={"tertiary"}
                        onClick={() => {
                            void refetch();
                        }}
                    >
                        Ponów
                    </Button>
                ),
            });
        }
    }, [isError, error, refetch]);

    return (
        <div className={"base-direction-row"}>
            {/* edit meeting dialog */}
            {lvl > 0 && (
                <EditMeeting id={Number(id)} open={openEditDialog} onOpenChange={setEditDialog} />
            )}
            {/* main data container */}
            <div
                className={
                    "border rounded-lg bg-surface-primary w-full border-border-primary h-full"
                }
            >
                {/* page header */}
                <header className={"flex flex-wrap items-center gap-2 p-3 md:p-4"}>
                    <div
                        className={cn(
                            "h-10 w-1 min-w-1 max-w-1 grow rounded-xs",
                            data?.type === "remote" ? "bg-fill-brand" : "bg-fill-accent",
                            data?.isCancelled && "bg-fill-destructive",
                        )}
                    />
                    <h2 className={"truncate text-xl font-semibold"}>
                        {isSuccess && data.name}
                        {isPending && (
                            <Skeleton
                                className={"h-4 max-h-4 w-16 max-w-[256px] grow rounded-sm"}
                            />
                        )}
                    </h2>
                    {/* edit button */}
                    {lvl > 0 && (
                        <Button className={"ml-auto"} variant={"outline"} onClick={handleOpenEdit}>
                            <PenLine />
                        </Button>
                    )}
                </header>
                <Separator />
                {/* meeting data */}
                <div className="grid grid-cols-[auto_1fr] gap-x-2 gap-y-3 p-3 md:p-4 w-full">
                    {/* Type row */}
                    <div className="flex gap-1 items-center">
                        <MapPinned className="size-4 stroke-icon-tertiary" />
                        <p className="text-sm text-text-tertiary">Typ:</p>
                    </div>
                    <div className="capitalize font-medium text-sm text-text-secondary">
                        {isPending ? (
                            <Skeleton className="h-5 w-12" />
                        ) : isSuccess ? (
                            data.type === "remote" ? (
                                "Zdalne"
                            ) : (
                                "Stacjonarne"
                            )
                        ) : null}
                    </div>

                    {isSuccess && data.type === "stationary" && data.location && (
                        <>
                            <div className="flex gap-1 items-center">
                                <BuildingIcon className="size-4 stroke-icon-tertiary" />
                                <p className="text-sm text-text-tertiary">Lokacja:</p>
                            </div>
                            <p className="capitalize font-medium text-sm text-text-secondary">
                                {data.location.name}
                            </p>
                        </>
                    )}

                    {isPending && (
                        <>
                            <div className="flex gap-1 items-center">
                                <BuildingIcon className="size-4 stroke-icon-tertiary" />
                                <p className="text-sm text-text-tertiary">Lokacja:</p>
                            </div>
                            <Skeleton className="h-5 w-20" />
                        </>
                    )}

                    <div className="flex gap-1 items-center">
                        <CalendarRange className="size-4 stroke-icon-tertiary" />
                        <p className="text-sm text-text-tertiary">Data:</p>
                    </div>
                    <div className="font-medium text-sm text-text-secondary">
                        {isPending ? (
                            <Skeleton className="h-5 w-32" />
                        ) : isSuccess ? (
                            `${format(data.startDate, "do MMM yyyy", { locale: pl })} | ${format(data.startDate, "HH:mm", { locale: pl })}-${format(data.endDate, "HH:mm", { locale: pl })}`
                        ) : null}
                    </div>
                </div>
                <Separator />
                {/* meeting description */}
                <div className="p-3 md:p-4 space-y-3">
                    <h2 className={"text-md font-medium text-text-primary"}>Opis</h2>
                    <div className={"w-full"}>
                        {isPending && <Skeleton className="h-10 w-full rounded-md" />}
                        {isSuccess &&
                            (data.desc ? (
                                <p className={"text-sm text-text-secondary"}>{data.desc}</p>
                            ) : (
                                <p className="italic text-sm text-text-tertiary">brak opisu</p>
                            ))}
                    </div>
                </div>
                {/* meeting location or meeting link, display only if there is data */}
                {isSuccess &&
                    ((data.type == "stationary" && data.location?.id) ||
                        (data.type == "remote" && data.meetingURL)) && (
                        <>
                            <Separator />
                            <div className="p-3 md:p-4 space-y-3">
                                <h2 className={"text-md font-medium text-text-primary"}>
                                    {data.type == "stationary" ? "Lokacja" : "Link do spotkania"}
                                </h2>
                                <div>
                                    {data.type == "stationary" ? (
                                        data.location && (
                                            <LocationCard
                                                location={data.location}
                                                className="h-82 md:h-96"
                                            />
                                        )
                                    ) : (
                                        <MeetingRemoteLink meeting={data} />
                                    )}
                                </div>
                            </div>
                        </>
                    )}
            </div>
            {/* meeting attendance or members */}
            <div className="border border-border-primary rounded-lg bg-surface-primary w-full md:w-144">
                {isSuccess &&
                    (lvl <= 0 ? (
                        <MeetingMembers groups={data.groups} users={data.users} />
                    ) : (
                        <MeetingAttendance meetingId={data.id} attendance={data.attendances} />
                    ))}
            </div>
        </div>
    );
}
