import FsDirectoryCard from "@/components/features/files/FsDirectoryCard";
import { useNavigate } from "react-router-dom";
import { useFSEntityInteraction } from "@/components/features/files/hooks/useFSEntityInteraction";
import FSEntityWrapper from "@/components/features/files/FSEntityWrapper";
import type { DirectoryPublic } from "@/types";

interface DirectoryProps {
    directory: DirectoryPublic;
}

export default function Directory({ directory }: DirectoryProps) {
    const navigate = useNavigate();

    const navigateToDirectory = () => {
        navigate(`/fs/dir/${directory.id}`, { state: directory.id });
    };

    const {
        isEntitySelected,
        size,
        handleClick,
        handleKeyDown,
        handleDoubleClick,
        longPressHandlers,
    } = useFSEntityInteraction({
        entity: directory,
        entityType: "directory",
        onOpen: navigateToDirectory,
    });

    return (
        <FSEntityWrapper entity={directory} entityType="directory">
            <FsDirectoryCard
                aria-selected={isEntitySelected}
                tabIndex={0}
                onClick={handleClick}
                onDoubleClick={handleDoubleClick}
                onKeyDown={handleKeyDown}
                onPointerDown={longPressHandlers.onPointerDown}
                onPointerMove={longPressHandlers.onPointerMove}
                onPointerUp={longPressHandlers.onPointerUp}
                directory={directory}
                disabledMenu={size > 1}
                className="aria-selected:bg-surface-secondary aria-selected:border-border-primary-active"
            />
        </FSEntityWrapper>
    );
}
