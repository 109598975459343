import {
    Carousel,
    CarouselApi,
    CarouselContent,
    CarouselItem,
} from "@/components/ui/carousel/Carousel";
import { cn } from "@/lib/utils";
import { getWeek } from "@/utils/dateFormat";
import { addWeeks, endOfWeek, format, isSameDay, isToday, startOfWeek, subWeeks } from "date-fns";
import { pl } from "date-fns/locale";
import { EmblaCarouselType } from "embla-carousel";
import {
    Dispatch,
    ReactElement,
    SetStateAction,
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
    JSX,
} from "react";

interface WeekRowProps {
    selected: Date;
    onSelect: (day: Date) => void;
    visibleWeeksOffset?: number;
    currentVisibleWeekDay?: Date;
    setCurrentVisibleWeekDay?: Dispatch<SetStateAction<Date>>;
    renderContextMenu?: (content: JSX.Element, day: Date, key: string) => JSX.Element;
    className?: string;
    setScrollPrev?: (scrollPrev: () => void) => void;
    setScrollNext?: (scrollNext: () => void) => void;
}

export default function WeekCards(props: WeekRowProps) {
    const {
        selected = new Date(),
        onSelect,
        renderContextMenu,
        visibleWeeksOffset,
        setCurrentVisibleWeekDay,
        className,
        setScrollPrev,
        setScrollNext,
    } = props;
    const selectedScrollSnapRef = useRef<number | undefined>(visibleWeeksOffset);
    const [weeks, setWeeks] = useState<Date[][]>([]);
    const [api, setApi] = useState<CarouselApi>();

    const handleOnClick = useCallback(
        (day: Date) => {
            onSelect(day);
        },
        [onSelect],
    );

    useEffect(() => {
        if (weeks.length !== 0) {
            return;
        }

        if (!visibleWeeksOffset) {
            setWeeks([getWeek(new Date())]);
        } else {
            const weeks: Date[][] = [];
            const referenceStartOfWeek = startOfWeek(new Date(), {
                weekStartsOn: 1,
            });
            for (let i = -visibleWeeksOffset; i <= visibleWeeksOffset; i++) {
                const weekStart = addWeeks(referenceStartOfWeek, i);
                weeks.push(getWeek(weekStart));
            }
            setWeeks(weeks);
        }
    }, [visibleWeeksOffset, weeks.length]);

    const setCurrentVisibleWeekDayCn = useCallback(
        (emblaEventApi: EmblaCarouselType) => {
            const prevSelectedScrollSnap = selectedScrollSnapRef.current;
            const isPrevSelectedScrollSnap = prevSelectedScrollSnap !== undefined;
            const newSelectedScrollSnap = emblaEventApi.selectedScrollSnap();

            if (isPrevSelectedScrollSnap && prevSelectedScrollSnap < newSelectedScrollSnap) {
                setCurrentVisibleWeekDay?.(prev =>
                    endOfWeek(addWeeks(prev, 1), {
                        weekStartsOn: 1,
                    }),
                );
            }
            if (isPrevSelectedScrollSnap && prevSelectedScrollSnap > newSelectedScrollSnap) {
                setCurrentVisibleWeekDay?.(prev =>
                    endOfWeek(subWeeks(prev, 1), {
                        weekStartsOn: 1,
                    }),
                );
            }
            if (isPrevSelectedScrollSnap) {
                selectedScrollSnapRef.current = newSelectedScrollSnap;
            }
        },
        [setCurrentVisibleWeekDay],
    );

    const scrollPrev = useCallback(() => {
        api?.scrollPrev();
    }, [api]);

    const scrollNext = useCallback(() => {
        api?.scrollNext();
    }, [api]);

    useEffect(() => {
        api?.on("select", setCurrentVisibleWeekDayCn);

        if (setScrollPrev) {
            setScrollPrev(scrollPrev);
        }
        if (setScrollNext) {
            setScrollNext(scrollNext);
        }

        return () => {
            api?.off("select", setCurrentVisibleWeekDayCn);
        };
    }, [api, setCurrentVisibleWeekDayCn, setScrollPrev, setScrollNext, scrollPrev, scrollNext]);

    useEffect(() => {
        api?.scrollTo(visibleWeeksOffset ?? 0, true);
    }, [api, visibleWeeksOffset]);

    const content = useMemo(() => {
        if (visibleWeeksOffset) {
            return (
                <Carousel setApi={setApi} className={"w-full"} opts={{ align: "center" }}>
                    <CarouselContent className={"p-1"}>
                        {weeks.map((week, index) => (
                            <CarouselItem key={index}>
                                <WeekCardsRow
                                    week={week}
                                    selected={selected}
                                    className={className}
                                    onClick={handleOnClick}
                                    renderContextMenu={renderContextMenu}
                                />
                            </CarouselItem>
                        ))}
                    </CarouselContent>
                </Carousel>
            );
        } else if (weeks.length === 1) {
            return (
                <WeekCardsRow
                    week={weeks[0]}
                    selected={selected}
                    className={className}
                    onClick={handleOnClick}
                    renderContextMenu={renderContextMenu}
                />
            );
        }
        return null;
    }, [className, handleOnClick, renderContextMenu, selected, visibleWeeksOffset, weeks]);

    return (
        <div className={"relative m-auto flex w-full items-center gap-1 overflow-hidden px-0"}>
            {content}
        </div>
    );
}

type WeekCardsProps = {
    week: Date[];
    selected: Date;
    onClick: (day: Date) => void;
    renderContextMenu?: (children: ReactElement, day: Date, key: string) => ReactElement;
    className?: string;
};

function WeekCardsRow({ week, renderContextMenu, selected, onClick, ...props }: WeekCardsProps) {
    return (
        <div className={"flex w-full gap-1"}>
            {week.map(day => (
                <WeekCard
                    key={day.toString()}
                    day={day}
                    renderContextMenu={renderContextMenu}
                    selected={selected}
                    onClick={onClick}
                    {...props}
                />
            ))}
        </div>
    );
}

type WeekCardProps = {
    day: Date;
    selected: Date;
    onClick: (day: Date) => void;
    renderContextMenu?: (children: ReactElement, day: Date, key: string) => ReactElement;
    className?: string;
};

function WeekCard({
    day,
    selected,
    renderContextMenu,
    onClick,
    className,
    ...props
}: WeekCardProps) {
    const elKey = day.toString();
    const weekDay = format(day, "cccccc", { locale: pl });
    const monthDate = format(day, "dd", { locale: pl });
    const today = isToday(day);
    const sameDay = isSameDay(day, selected);

    const content = (
        <button
            key={"weekDay_" + elKey}
            aria-checked={sameDay}
            data-selected={sameDay}
            className={cn(
                "relative flex w-11 min-w-7 cursor-pointer select-none flex-col items-center gap-0.5 rounded-md border px-4 py-2 opacity-100 transition-[color,opacity] duration-100 ease-out outline-0 outline-ring-focus focus-visible:outline-2 disabled:opacity-75 data-[selected=false]:border-border-primary data-[selected=false]:bg-fill-primary hover:data-[selected=false]:bg-fill-primary-hover active:data-[selected=false]:bg-fill-primary-active data-[selected=true]:border-border-brand data-[selected=true]:bg-gradient-to-b data-[selected=true]:from-fill-brand-hover data-[selected=true]:to-fill-brand-active data-[selected=true]:text-text-on-brand-fill hover:data-[selected=true]:opacity-80",
                className,
            )}
            onClick={() => onClick(day)}
            {...props}
        >
            <small
                className={cn(
                    "text-[10px] leading-4 tracking-tight",
                    sameDay ? "text-text-on-brand-fill" : "text-text-tertiary",
                )}
            >
                {weekDay}
            </small>
            <span
                className={cn(
                    "text-sm",
                    sameDay ? "text-text-on-brand-fill" : "text-text-secondary",
                )}
            >
                {monthDate}
            </span>
            {today && (
                <span
                    className={cn(
                        "absolute bottom-1 h-1 min-h-1 w-1 min-w-1 rounded-full",
                        sameDay ? "bg-text-on-brand-fill" : "bg-text-tertiary",
                    )}
                />
            )}
        </button>
    );
    if (!renderContextMenu) {
        return content;
    }
    return renderContextMenu(content, day, "contextDay_" + elKey);
}

export { WeekCardsRow };
