import { phoneRegex } from "@/lib/regex";
import { InferType, object, ref, string } from "yup";

const LoginSchema = object({
    email: string().email("Niepoprawny email").lowercase().trim().required("Email jest wymagany"),
    password: string().trim().required("Hasło jest wymagane"),
});

type LoginSchemaType = InferType<typeof LoginSchema>;

const PasswordResetSchema = object({
    email: string().email("Niepoprawny email").lowercase().trim().required("Email jest wymagany"),
    authToken: string().nullable(),
});

type PasswordResetSchemaType = InferType<typeof PasswordResetSchema>;

const PasswordChangeSchema = object({
    password: string().trim().required("Hasło jest wymagane"),
    confirmPassword: string()
        .trim()
        .oneOf([ref("password")], "Hasła muszą być identyczne")
        .required("Powtórz hasło"),
    authToken: string().nullable(),
});

type PasswordChangeSchemaType = InferType<typeof PasswordChangeSchema>;

const SignupSchema = object({
    email: string().default("").email("Niepoprawny email").required("Email jest wymagany").trim(),
    phone: string()
        .matches(phoneRegex, {
            message: "Niepoprawny numer telefonu",
            excludeEmptyString: true,
        })
        .optional()
        .trim()
        .default(""),
    birthdate: string().nullable().optional(),
    authToken: string().nullable(),
});

type SignupSchemaType = InferType<typeof SignupSchema>;

export { LoginSchema, SignupSchema, PasswordResetSchema, PasswordChangeSchema };
export type {
    LoginSchemaType,
    SignupSchemaType,
    PasswordResetSchemaType,
    PasswordChangeSchemaType,
};
