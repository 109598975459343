import { useMemo } from "react";
import { UserPermissions, GroupPermissions } from "@/types";
import { PermissionChangesSchemaType } from "@/schemas/globals.schema";

interface PermissionChangesProps {
    selectedUsers: UserPermissions[];
    selectedGroups: GroupPermissions[];
    defaultUsers?: UserPermissions[];
    defaultGroups?: GroupPermissions[];
}

export function usePermissionChanges({
    selectedUsers,
    selectedGroups,
    defaultUsers,
    defaultGroups,
}: PermissionChangesProps) {
    return useMemo(() => {
        const payload: PermissionChangesSchemaType[] = [];
        const readPermissionsUsers: number[] = [];
        const writePermissionsUsers: number[] = [];
        const editPermissionsUsers: number[] = [];
        const readPermissionsGroups: number[] = [];
        const writePermissionsGroups: number[] = [];
        const editPermissionsGroups: number[] = [];

        const usersToRemove = (defaultUsers || [])
            .filter(user => !selectedUsers.some(element => element.id === user.id))
            .map(user => user.id);

        const groupsToRemove = (defaultGroups || [])
            .filter(group => !selectedGroups.some(element => element.id === group.id))
            .map(group => group.id);

        const usersToAdd = selectedUsers.filter(
            user =>
                !defaultUsers?.some(u => u.id === user.id && u.permissions === user.permissions),
        );

        const groupsToAdd = selectedGroups.filter(
            group =>
                !defaultGroups?.some(g => g.id === group.id && g.permissions === group.permissions),
        );

        // Sort users by permission level
        usersToAdd.forEach(user => {
            if (user.permissions.edit) {
                editPermissionsUsers.push(user.id);
                return;
            }
            if (user.permissions.write) {
                writePermissionsUsers.push(user.id);
                return;
            }
            if (user.permissions.read) {
                readPermissionsUsers.push(user.id);
                return;
            }
        });

        // Sort groups by permission level
        groupsToAdd.forEach(group => {
            if (group.permissions.edit) {
                editPermissionsGroups.push(group.id);
                return;
            }
            if (group.permissions.write) {
                writePermissionsGroups.push(group.id);
                return;
            }
            if (group.permissions.read) {
                readPermissionsGroups.push(group.id);
                return;
            }
        });

        // Build payload for removals
        if (usersToRemove.length > 0 || groupsToRemove.length > 0) {
            payload.push({
                userIds: usersToRemove,
                groupIds: groupsToRemove,
                remove: true,
            });
        }

        // Build payloads for each permission level
        if (readPermissionsUsers.length > 0 || readPermissionsGroups.length > 0) {
            payload.push({
                userIds: readPermissionsUsers,
                groupIds: readPermissionsGroups,
                add: true,
                read: true,
                write: false,
                edit: false,
            });
        }

        if (writePermissionsUsers.length > 0 || writePermissionsGroups.length > 0) {
            payload.push({
                userIds: writePermissionsUsers,
                groupIds: writePermissionsGroups,
                add: true,
                read: true,
                write: true,
                edit: false,
            });
        }

        if (editPermissionsUsers.length > 0 || editPermissionsGroups.length > 0) {
            payload.push({
                userIds: editPermissionsUsers,
                groupIds: editPermissionsGroups,
                add: true,
                read: true,
                write: true,
                edit: true,
            });
        }

        return payload;
    }, [selectedUsers, selectedGroups, defaultUsers, defaultGroups]);
}

export default usePermissionChanges;
