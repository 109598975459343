import WeekCards from "@/components/features/calendar/WeekCards";
import { Button } from "@/components/ui/button/Button";
import { cn } from "@/lib/utils";
import { format, startOfWeek } from "date-fns";
import { pl } from "date-fns/locale";
import { ChevronLeft, ChevronRight } from "lucide-react";
import { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

interface MeetingsListHeaderProps {
    date: Date;
    setDate: (day: Date) => void;
    defaultNavigate?: string;
    renderContextMenu?: (content: JSX.Element, day: Date, key: string) => JSX.Element;
    className?: string;
}

export default function MeetingsListHeader({
    date,
    setDate,
    defaultNavigate = "/calendar/?viewKey=month",
    renderContextMenu,
    className,
}: MeetingsListHeaderProps) {
    const navigate = useNavigate();
    const [currentVisibleWeekDay, setCurrentVisibleWeekDay] = useState<Date>(
        startOfWeek(date, {
            weekStartsOn: 1,
        }),
    );

    const scrollPrevRef = useRef<() => void>();
    const scrollNextRef = useRef<() => void>();
    return (
        <div className={cn("flex flex-col gap-5", className)}>
            <div className={"flex items-center justify-between px-3 md:px-4"}>
                <button
                    className={"truncate text-xl font-semibold"}
                    onClick={() => navigate(defaultNavigate)}
                >
                    {format(currentVisibleWeekDay, "LLLL yyyy", { locale: pl })}
                </button>

                <div className={"flex shrink-0 gap-2"}>
                    <Button
                        className={"h-8 min-h-8 w-8 min-w-8"}
                        size={"sm"}
                        variant={"outline"}
                        onClick={() => scrollPrevRef.current?.()}
                    >
                        <ChevronLeft />
                    </Button>
                    <Button
                        className={"h-8 min-h-8 w-8 min-w-8"}
                        size={"sm"}
                        variant={"outline"}
                        onClick={() => scrollNextRef.current?.()}
                    >
                        <ChevronRight />
                    </Button>
                </div>
            </div>
            <div className={"flex w-full justify-center"}>
                <div className={"flex grow gap-1 overflow-x-auto px-3 md:px-4 py-1"}>
                    <WeekCards
                        selected={date}
                        onSelect={setDate}
                        visibleWeeksOffset={8}
                        currentVisibleWeekDay={currentVisibleWeekDay}
                        setCurrentVisibleWeekDay={setCurrentVisibleWeekDay}
                        className={"w-full"}
                        renderContextMenu={renderContextMenu}
                        setScrollPrev={scrollPrev => (scrollPrevRef.current = scrollPrev)}
                        setScrollNext={scrollNext => (scrollNextRef.current = scrollNext)}
                    />
                </div>
            </div>
        </div>
    );
}
