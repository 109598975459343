import { Button } from "@/components/ui/button/Button";
import { FileUp, FolderPlus, FolderUp, ListFilter, X } from "lucide-react";
import { FileUploadTrigger } from "@/components/ui/file-upload/FileUpload";

interface FilesActionButtonsProps {
    onCreateDir: () => void;
    onToggleFilters?: () => void;
    isDisabled: boolean;
    openFilters: boolean;
}

export default function FilesActionButtons({
    onCreateDir,
    onToggleFilters,
    isDisabled,
    openFilters,
}: FilesActionButtonsProps) {
    return (
        <>
            <Button onClick={onCreateDir} variant="outline" disabled={isDisabled}>
                <FolderPlus />
            </Button>

            <FileUploadTrigger multiple={true}>
                <Button variant="outline" disabled={isDisabled}>
                    <FileUp />
                </Button>
            </FileUploadTrigger>

            <FileUploadTrigger
                className="hidden md:block"
                disabled={isDisabled}
                webkitdirectory={"true"}
            >
                <Button variant="outline">
                    <FolderUp />
                </Button>
            </FileUploadTrigger>
            <Button
                data-state={openFilters ? "on" : "off"}
                className="group flex md:hidden"
                onClick={onToggleFilters}
                variant="outline"
            >
                <ListFilter className="absolute shrink-0 scale-100 opacity-100 transition-all group-data-[state=on]:scale-0 group-data-[state=on]:opacity-0" />
                <X className="shrink-0 scale-0 opacity-0 transition-all group-data-[state=on]:scale-100 group-data-[state=on]:opacity-100" />
            </Button>
        </>
    );
}
