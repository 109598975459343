import GroupElementHeader from "@/components/features/groups/layout/GroupElementHeader";
import { Button } from "@/components/ui/button/Button";
import {
    Dialog,
    DialogBody,
    DialogContent,
    DialogFooter,
    DialogHeader,
    DialogTitle,
} from "@/components/ui/dialog/Dialog";
import {
    Drawer,
    DrawerBody,
    DrawerContent,
    DrawerFooter,
    DrawerHeader,
    DrawerTitle,
} from "@/components/ui/drawer/Drawer";
import { Skeleton } from "@/components/ui/skeleton/Skeleton";
import { useMediaQuery } from "@/hooks/useMediaQuery";
import { cn } from "@/lib/utils";
import { ChevronRight } from "lucide-react";
import { useCallback, useEffect, useMemo, useState } from "react";

interface GroupTasksProps {
    className?: string;
}

export default function GroupTasks(props: GroupTasksProps) {
    const { className } = props;
    const [open, setOpen] = useState<boolean>(false);

    const handleNavigate = () => {};

    const onOpenCb = useCallback(() => {
        setOpen(true);
    }, []);

    const [data, setData] = useState<any[]>([]);

    useEffect(() => {
        const timeout = setTimeout(() => {
            setData([]);
        }, 4000);
        return () => {
            clearTimeout(timeout);
        };
    }, []);

    const [dataSliced, isSliced] = useMemo(() => {
        const res = data.slice(0, 5);
        return [res, res.length < data.length];
    }, [data]);

    const noDataRender = useMemo(() => {
        return (
            <div className={"w-full h-full"}>
                <p className={"text-sm text-text-tertiary text-center"}>
                    Brak przypisanych zadań do grupy
                </p>
            </div>
        );
    }, []);

    return (
        <>
            <GroupTasksDialog open={open} setOpen={setOpen} data={data} />
            <div
                className={cn(
                    "flex flex-col rounded-lg border-1 border-border-primary bg-surface-primary",
                    className,
                )}
            >
                <GroupElementHeader label={"Zadania"} callback={handleNavigate} />
                <div
                    className={
                        "flex h-full min-h-14 items-center gap-2 overflow-hidden px-4 pb-4 snap-x sm:snap-none snap-mandatory scroll-pl-4 flex-col sm:flex-col overflow-x-auto sm:overflow-x-hidden"
                    }
                >
                    {noDataRender}
                    {dataSliced.map((_, index) => (
                        <Skeleton
                            key={index}
                            className="h-14 min-h-14 w-full min-w-[200px] snap-start snap-always rounded-sm"
                        />
                    ))}
                    <Button
                        onClick={onOpenCb}
                        disabled={!isSliced}
                        className={cn(
                            "w-full shrink-0 sm:w-fit",
                            isSliced ? "opacity-100" : "opacity-0!",
                        )}
                        size={"sm"}
                        variant={"ghost"}
                    >
                        Więcej <ChevronRight />
                    </Button>
                </div>
            </div>
        </>
    );
}

interface GroupTasksDialogProps {
    open: boolean;
    setOpen: (open: boolean) => void;
    data: any[];
}

function GroupTasksDialog(props: GroupTasksDialogProps) {
    const { open, setOpen, data } = props;
    const breakpoint = useMediaQuery("sm");

    const onCloseCb = useCallback(() => {
        setOpen(false);
    }, [setOpen]);

    if (breakpoint) {
        return (
            <Dialog open={open} onOpenChange={setOpen}>
                <DialogContent className={"max-w-[30rem]"}>
                    <DialogHeader>
                        <DialogTitle>Zadania</DialogTitle>
                    </DialogHeader>
                    <DialogBody className={"flex flex-col gap-2"}>
                        {data.map((_, index) => (
                            <Skeleton
                                key={index}
                                className="h-14 min-h-14 w-full min-w-[200px] snap-start snap-always rounded-sm"
                            />
                        ))}
                    </DialogBody>
                    <DialogFooter>
                        <Button onClick={onCloseCb} variant={"tertiary"}>
                            Zamknij
                        </Button>
                    </DialogFooter>
                </DialogContent>
            </Dialog>
        );
    }
    return (
        <Drawer open={open} onOpenChange={setOpen}>
            <DrawerContent>
                <DrawerHeader>
                    <DrawerTitle>Zadania</DrawerTitle>
                </DrawerHeader>
                <DrawerBody className={"flex flex-col gap-2"}>
                    {data.map((_, index) => (
                        <Skeleton
                            key={index}
                            className="h-14 min-h-14 w-full min-w-[200px] snap-start snap-always rounded-sm"
                        />
                    ))}
                </DrawerBody>
                <DrawerFooter>
                    <Button onClick={onCloseCb} variant={"tertiary"}>
                        Zamknij
                    </Button>
                </DrawerFooter>
            </DrawerContent>
        </Drawer>
    );
}
