import { InferType, object, array, boolean, number } from "yup";

const PermissionChangesSchema = object({
    userIds: array().of(number()).required(),
    groupIds: array().of(number()).required(),
    add: boolean().optional(),
    remove: boolean().optional(),
    edit: boolean().optional(),
    read: boolean().optional(),
    write: boolean().optional(),
});

type PermissionChangesSchemaType = InferType<typeof PermissionChangesSchema>;

export { PermissionChangesSchema };
export type { PermissionChangesSchemaType };
