import { ErrorBoundaryWrapper } from "@/dev/error-boundary/ErrorBoundaryWrapper";
import MainLayout from "@/page/MainLayout";
import LoginPage from "@/page/auth/LoginPage";
import PasswordChangePage from "@/page/auth/PasswordChangePage";
import PasswordResetPage from "@/page/auth/PasswordResetPage";
import SignupPage from "@/page/auth/SignupPage";
import NotAllowedPage from "@/page/errors/NotAllowedPage";
import NotFoundPage from "@/page/errors/NotFoundPage";
import CalendarPage from "@/page/pages/calendar/CalendarPage";
import MeetingPage from "@/page/pages/calendar/MeetingPage";
import ChatPage from "@/page/pages/chat/ChatPage";
import FilesPage from "@/page/pages/files/FilesPage";
import GroupPage from "@/page/pages/groups/GroupPage";
import GroupsPage from "@/page/pages/groups/GroupsPage";
import HomePage from "@/page/pages/home/HomePage";
import ManageGroups from "@/page/pages/manage/ManageGroups";
import ManageLocations from "@/page/pages/manage/ManageLocations";
import ManagePosts from "@/page/pages/manage/ManagePosts";
import ManageUsers from "@/page/pages/manage/ManageUsers";
import ManageUsersMassImport from "@/page/pages/manage/ManageUsersMassImport";
import SettingsPage from "@/page/pages/settings/SettingsPage";
import { PrivateRoutes } from "@/routes/PrivateRoutes";
import { ProtectedRoutes } from "@/routes/ProtectedRoutes";
import { createBrowserRouter } from "react-router-dom";

const router = createBrowserRouter([
    {
        // wrapper for error boundary integration with react-router
        element: <ErrorBoundaryWrapper />,
        children: [
            {
                path: "/login",
                element: <LoginPage />,
            },
            {
                path: "/auth/signup/",
                element: <SignupPage />,
            },
            {
                path: "/auth/passwordChange/",
                element: <PasswordChangePage />,
            },
            {
                path: "/auth/passwordReset/",
                element: <PasswordResetPage />,
            },
            {
                path: "/",
                element: <PrivateRoutes />,
                children: [
                    {
                        path: "/",
                        element: <MainLayout />,
                        children: [
                            {
                                path: "/",
                                element: <HomePage />,
                            },
                            {
                                path: "/chat/*",
                                element: <ChatPage />,
                                children: [
                                    {
                                        path: ":id",
                                        element: <ChatPage />,
                                    },
                                ],
                            },
                            {
                                path: "/calendar/",
                                element: <CalendarPage />,
                            },
                            {
                                path: "/calendar/meeting/:id",
                                element: <MeetingPage />,
                            },
                            {
                                path: "/groups",
                                element: <GroupsPage />,
                            },
                            {
                                path: "group/:id",
                                element: <GroupPage />,
                            },
                            {
                                path: "/manage",
                                element: <ProtectedRoutes redirectURL={"/notAllowed"} minLvl={1} />,
                                children: [
                                    {
                                        path: "/manage/users",
                                        element: <ManageUsers />,
                                    },
                                    {
                                        path: "/manage/users/massImport",
                                        element: <ManageUsersMassImport />,
                                    },
                                    {
                                        path: "/manage/groups",
                                        element: <ManageGroups />,
                                    },
                                    {
                                        path: "/manage/posts",
                                        element: <ManagePosts />,
                                    },
                                    {
                                        path: "/manage/locations",
                                        element: (
                                            <ProtectedRoutes redirectURL={"/notAllowed"} minLvl={2}>
                                                <ManageLocations />
                                            </ProtectedRoutes>
                                        ),
                                    },
                                ],
                            },
                            {
                                path: "/fs",
                                element: <FilesPage />,
                            },
                            {
                                path: "/fs/dir/:id",
                                element: <FilesPage />,
                            },
                            {
                                path: "/settings",
                                element: <SettingsPage />,
                            },
                            {
                                path: "/notAllowed",
                                element: <NotAllowedPage />,
                            },
                            {
                                path: "*",
                                element: <NotFoundPage />,
                            },
                        ],
                    },
                ],
            },
            {
                path: "*",
                element: <NotFoundPage />,
            },
        ],
    },
]);

export { router };
