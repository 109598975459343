import { Tabs, TabsList, TabsTrigger } from "@/components/ui/tabs/Tabs";

type MeetingAttendanceTogglesProps = {
    status: "present" | "absent" | "late";
    userId: number;
    onValueChange: (value: string, id: number) => void;
};

export default function MeetingAttendanceToggles(props: MeetingAttendanceTogglesProps) {
    const { status, userId, onValueChange } = props;

    const handleValueChange = (value: string) => {
        onValueChange(value, userId);
    };

    return (
        <Tabs value={status} onValueChange={handleValueChange} className={"shrink-0"}>
            <TabsList className={"grid grid-cols-3"}>
                <TabsTrigger
                    className={
                        "w-7 h-7 border-0 px-2 data-[state=active]:bg-fill-success-active data-[state=active]:text-text-on-success-fill"
                    }
                    value={"present"}
                >
                    O
                </TabsTrigger>
                <TabsTrigger
                    className={
                        "w-7 h-7 border-0 px-2 data-[state=active]:bg-fill-warning-active data-[state=active]:text-text-on-warning-fill"
                    }
                    value={"late"}
                >
                    S
                </TabsTrigger>
                <TabsTrigger
                    className={
                        "w-7 h-7 border-0 px-2 data-[state=active]:bg-fill-destructive-active data-[state=active]:text-text-on-destructive-fill"
                    }
                    value={"absent"}
                >
                    N
                </TabsTrigger>
            </TabsList>
        </Tabs>
    );
}
