import { httpErrorHandler } from "@/api/api";
import { useInfiniteQueryResult } from "@/api/api-utils";
import { useEditGroupMutation, useGetGroupUsersQuery } from "@/api/queries/groupsQueries";
import { useGetUsersInfiniteQuery } from "@/api/queries/usersQueries";
import { Button } from "@/components/ui/button/Button";
import {
    Dialog,
    DialogBody,
    DialogContent,
    DialogFooter,
    DialogDescription,
    DialogHeader,
    DialogTitle,
} from "@/components/ui/dialog/Dialog";
import { Input, InputWrapper } from "@/components/ui/input/Input";
import { SelectDataListElement } from "@/components/ui/select-list/SelectDataListElement";
import SelectDataList from "@/components/ui/select-list/SelectDataList";
import { SelectListSeparator } from "@/components/ui/select-list/SelectList";
import { useDebounceValue } from "@/hooks/useDebounceValue";
import { LoaderCircle, Search, CircleX, CheckCircle2, Users } from "lucide-react";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { getUserLvl } from "@/utils/getUserLvl";
import { FeaturedIcon } from "@/components/ui/featured-icon/FeaturedIcon";
import { toast } from "sonner";
import { UserPublic } from "@/types";

interface EditGroupUsersProps {
    callback?: () => void;
    open: boolean;
    onOpenChange: Dispatch<SetStateAction<boolean>>;
    id?: number;
}

interface memberChangesT {
    userIds: number[];
    add?: boolean;
    remove?: boolean;
}

export default function EditGroupUsers({ id, callback, open, onOpenChange }: EditGroupUsersProps) {
    const [searchUserValue, setSearchUserValue] = useState<string>("");
    const searchUserDebouncedValue = useDebounceValue(searchUserValue, 400);
    const [selectedUsers, setSelectedUsers] = useState<UserPublic[]>([]);
    const { data: groupUsers = [], ...groupUsersResults } = useGetGroupUsersQuery(id);

    const getUsersInfiniteQuery = useGetUsersInfiniteQuery({
        name: searchUserDebouncedValue,
    });

    const {
        data: users,
        isEmpty: usersIsEmpty,
        noResults: usersNoResults,
    } = useInfiniteQueryResult(getUsersInfiniteQuery.data, searchUserDebouncedValue);
    const { mutateAsync: groupEditMutation, isPending } = useEditGroupMutation();

    const onClose = () => {
        onOpenChange(false);
        callback?.();
        setSearchUserValue("");
        setSelectedUsers([]);
    };

    const onSubmit = async () => {
        if (!id) {
            return;
        }

        const usersToAdd = selectedUsers.filter(
            user => !groupUsers.some(groupUser => groupUser.id === user.id),
        );
        const usersToRemove = groupUsers.filter(
            user => !selectedUsers.some(selectedUser => selectedUser.id === user.id),
        );

        const memberChanges: memberChangesT[] = [];
        if (usersToAdd.length) {
            memberChanges.push({
                userIds: usersToAdd.map(user => user.id),
                add: true,
            });
        }
        if (usersToRemove.length) {
            memberChanges.push({
                userIds: usersToRemove.map(user => user.id),
                remove: true,
            });
        }

        await groupEditMutation({
            id: id,
            memberChanges: memberChanges,
        })
            .then(() => {
                onClose();
            })
            .catch(error => {
                const { title, detail } = httpErrorHandler(error);
                toast.error(title, {
                    description: detail,
                });
            });
    };

    useEffect(() => {
        if (groupUsersResults.isSuccess && open) {
            setSelectedUsers(groupUsers);
        }
    }, [groupUsersResults.isSuccess, open]);

    useEffect(() => {
        if (groupUsersResults.isError) {
            onClose();
            const { title, detail } = httpErrorHandler(groupUsersResults.error);
            toast.error(title, {
                description: detail,
            });
        }
    }, [groupUsersResults.isError, groupUsersResults.error, toast, callback, onOpenChange]);

    return (
        <Dialog open={open} onOpenChange={onOpenChange}>
            <DialogContent
                className={"sm:max-w-148"}
                onCloseAutoFocus={onClose}
                onEscapeKeyDown={onClose}
            >
                <DialogDescription className={"sr-only"}>
                    Formularz do edycji użytkowników grupy
                </DialogDescription>
                <DialogBody>
                    {isPending && (
                        <div
                            className={
                                "absolute inset-0 z-60 m-auto flex flex-wrap items-center justify-center gap-3 bg-surface-primary/75 backdrop-blur-md"
                            }
                        >
                            <LoaderCircle className={"animate-spin"} aria-hidden={"true"} />
                            <h5 className={"font-medium"}>Trwa zapisywanie zmian...</h5>
                        </div>
                    )}
                    {groupUsersResults.isFetching && (
                        <div
                            className={
                                "absolute inset-0 z-60 m-auto flex flex-wrap items-center justify-center gap-3 bg-surface-primary/75 backdrop-blur-md"
                            }
                        >
                            <LoaderCircle className={"animate-spin"} aria-hidden={"true"} />
                            <h5 className={"font-medium"}>Trwa ładowanie danych...</h5>
                        </div>
                    )}
                    <DialogHeader className={"border-b border-border-primary"}>
                        <FeaturedIcon variant={"ghost"}>
                            <Users />
                        </FeaturedIcon>
                        <div className={"flex flex-col gap-0.5 min-w-0 grow"}>
                            <DialogTitle>Edytuj użytkowników grupy</DialogTitle>
                            <p className={"text-text-tertiary text-xs"}>
                                Wybierz użytkowników grupy.
                            </p>
                        </div>
                    </DialogHeader>
                    <div className={"flex flex-col gap-3 p-3 md:p-4 grow overflow-y-auto"}>
                        <div>
                            <div className={"flex flex-col gap-3 grow"}>
                                <InputWrapper className={"w-full"}>
                                    <Input
                                        value={searchUserValue}
                                        onChange={e => setSearchUserValue(e.target.value)}
                                        className="peer pe-8 ps-8"
                                        placeholder="Wyszukaj..."
                                    />
                                    <div className="pointer-events-none absolute inset-y-0 start-0 flex items-center justify-center ps-2 peer-disabled:opacity-50">
                                        <Search className="size-3 stroke-icon-tertiary" />
                                    </div>
                                    {searchUserValue && (
                                        <button
                                            className="absolute cursor-pointer inset-y-0 end-0 flex h-full w-9 items-center justify-center rounded-e-sm text-text-tertiary transition-colors hover:text-text-tertiary-hover z-10 focus-visible:outline-2 outline-ring-focus disabled:pointer-events-none disabled:cursor-not-allowed disabled:opacity-50"
                                            aria-label="Clear input"
                                            onClick={() => setSearchUserValue("")}
                                        >
                                            <CircleX className={"size-3"} aria-hidden="true" />
                                        </button>
                                    )}
                                </InputWrapper>
                                <SelectDataList
                                    query={getUsersInfiniteQuery}
                                    selected={selectedUsers}
                                    setSelected={setSelectedUsers}
                                    isEmpty={usersIsEmpty}
                                    isNoResults={usersNoResults}
                                    emptyMessage={"Wyszukaj użytkownika"}
                                    searchNoResultsMessage={"Nie znaleziono uzytkownika"}
                                >
                                    {!searchUserDebouncedValue && (
                                        <>
                                            {selectedUsers.map(entity => (
                                                <SelectDataListElement
                                                    key={entity.id}
                                                    entity={entity}
                                                    data={{
                                                        title: entity.name + " " + entity.surname,
                                                        avatarURL: entity.avatarURL,
                                                        detail: getUserLvl(entity.lvl),
                                                    }}
                                                />
                                            ))}
                                            <SelectListSeparator />
                                        </>
                                    )}
                                    {users.map(entity => (
                                        <SelectDataListElement
                                            key={entity.id}
                                            entity={entity}
                                            data={{
                                                title: entity.name + " " + entity.surname,
                                                avatarURL: entity.avatarURL,
                                                detail: getUserLvl(entity.lvl),
                                            }}
                                        />
                                    ))}
                                </SelectDataList>
                            </div>
                        </div>
                    </div>
                </DialogBody>
                <DialogFooter className={"border-t border-border-primary"}>
                    <Button
                        onClick={onClose}
                        type={"button"}
                        variant={"tertiary"}
                        className={"w-full md:w-fit"}
                    >
                        Anuluj
                    </Button>
                    <Button
                        disabled={isPending}
                        onClick={onSubmit}
                        type={"submit"}
                        variant={"primary"}
                        className={"w-full md:w-fit"}
                    >
                        {isPending ? (
                            <>
                                <LoaderCircle className={"animate-spin"} aria-hidden={"true"} />
                                Zapisywanie...
                            </>
                        ) : (
                            <>
                                <CheckCircle2 />
                                Zapisz
                            </>
                        )}
                    </Button>
                </DialogFooter>
            </DialogContent>
        </Dialog>
    );
}
