import { ButtonGroup, ButtonGroupButton } from "@/components/ui/button/ButtonGroup";
import { ChevronLeft, ChevronRight } from "lucide-react";
import { SetNavigate } from "@/types";

interface CalendarHeaderNavProps {
    changeDate: (value: number) => void;
    setNavigate: SetNavigate;
}

export default function CalendarHeaderNavigation({
    changeDate,
    setNavigate,
}: CalendarHeaderNavProps) {
    return (
        <div className="ml-auto flex flex-row gap-2">
            {/* date navigation */}
            <ButtonGroup>
                {/* prev */}
                <ButtonGroupButton onClick={() => changeDate(-1)}>
                    <ChevronLeft />
                </ButtonGroupButton>
                {/* select date */}
                <ButtonGroupButton onClick={() => setNavigate({ newDate: new Date() })}>
                    Dzisiaj
                </ButtonGroupButton>
                <ButtonGroupButton onClick={() => changeDate(1)}>
                    <ChevronRight />
                </ButtonGroupButton>
            </ButtonGroup>
        </div>
    );
}
