import { MeetingPublic } from "@/types";
import { useMemo } from "react";

export function useMapMeetingsByDate(dates: Date[], meetings: MeetingPublic[]) {
    return useMemo(() => {
        const meetingsByDate: Record<string, MeetingPublic[]> = {};
        dates.forEach(date => {
            const dateString = date.toISOString();

            meetingsByDate[dateString] = meetings.filter(meeting => {
                const meetingDate = new Date(meeting.startDate);

                return (
                    meetingDate.getDate() === date.getDate() &&
                    meetingDate.getMonth() === date.getMonth() &&
                    meetingDate.getFullYear() === date.getFullYear()
                );
            });
        });

        return meetingsByDate;
    }, [dates, meetings]);
}
