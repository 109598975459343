import { FileWithPath } from "file-selector";
import { createDirectory } from "@/api/endpoints/directories";
import { useState, useMemo } from "react";
import { v4 as uuidv4 } from "uuid";
import { uploadFile, type FileUploadParams } from "@/api/endpoints/files";
import { AxiosProgressEvent } from "axios";
import { queryClient } from "@/api/query-client";
import { httpErrorHandler } from "@/api/api";

interface DirectoryT {
    directoryName: string | null;
    tempUUID?: string;
    directoryId: string | null;
    parentDirId?: string | null;
    files: (File | FileWithPath)[];
    children: DirectoryT[];
}

interface GetFilesTreeProps {
    files: (FileWithPath | DataTransferItem)[] | FileList;
    baseId: string;
}

type EntryStatus = "uploading" | "completed" | "error" | "aborted";

export interface AggregatedEntry {
    loaded: number;
    total: number;
    type: "FILE" | "DIR";
    name: string;
    childrenPaths: Set<string>;
    status: EntryStatus;
    errors: { path: string; message: string }[];
    abort?: () => void;
    retry?: () => void;
}

export interface UploadProgressEntry {
    event: AxiosProgressEvent;
    file: FileWithPath;
    status: EntryStatus;
    abort?: () => void;
    retry?: () => void;
    error?: Error;
}

interface useUploadFsElementsProps {
    shouldAggregateData?: boolean;
}

function extractFile(
    file: FileWithPath | DataTransferItem | File,
): FileWithPath | File | undefined {
    if (file instanceof DataTransferItem) {
        return file.getAsFile() || undefined;
    }

    return file;
}

function extractFilePath(file: FileWithPath | DataTransferItem | File): string {
    // Extract the raw path based on file type
    let path = "";

    if ("path" in file) {
        // Handle FileWithPath objects
        path = (file as FileWithPath).path || "";
    } else if (file instanceof File) {
        // Handle standard File objects
        path = file.webkitRelativePath;
    } else if (file instanceof DataTransferItem) {
        // Handle DataTransferItem objects
        const fileAsFile = file.getAsFile();
        path = fileAsFile?.webkitRelativePath || "";
    }

    // Normalize the path by removing leading "./" or "/"
    if (path.startsWith("./")) {
        return path.slice(2);
    }
    if (path.startsWith("/")) {
        return path.slice(1);
    }

    return path;
}

function useUploadFsElements({ shouldAggregateData = false }: useUploadFsElementsProps) {
    const [uploadProgress, setUploadProgress] = useState<Map<string, UploadProgressEntry>>(
        new Map(),
    );
    const resetUploadProgress = () => {
        setUploadProgress(new Map());
    };
    const deleteUplaodProgressEntry = (entry: AggregatedEntry) => {
        setUploadProgress(prev => {
            const newMap = new Map(prev);

            if (entry.type === "DIR" && entry.childrenPaths.size > 0) {
                // For directories, remove all children entries
                entry.childrenPaths.forEach(path => {
                    prev.forEach((value, key) => {
                        if (value.file.path === path) {
                            newMap.delete(key);
                        }
                    });
                });
            } else {
                // For single files, find by name
                prev.forEach((value, key) => {
                    if (value.file.name === entry.name) {
                        newMap.delete(key);
                    }
                });
            }

            return newMap;
        });
    };
    const aggregatedData = useMemo(() => {
        if (!shouldAggregateData) return null;

        const newAggregatedData = new Map<string, AggregatedEntry>();

        uploadProgress.forEach((entry, key) => {
            if (!entry.file.path) return;

            let path = entry.file.path;

            // Strip path of ./ or /
            if (path.startsWith("./")) {
                path = path.slice(2);
            }
            if (path.startsWith("/")) {
                path = path.slice(1);
            }

            const splitPath = path.split("/");
            const rootName = splitPath[0];
            const depth = splitPath.length;

            const entryKey = depth > 1 ? rootName : key;
            let aggregated = newAggregatedData.get(entryKey);

            // Initialize structure if needed
            if (!aggregated) {
                newAggregatedData.set(entryKey, {
                    total: 0,
                    loaded: 0,
                    type: depth > 1 ? "DIR" : "FILE",
                    name: rootName,
                    childrenPaths: new Set(),
                    status: "uploading",
                    errors: [],
                });
                aggregated = newAggregatedData.get(entryKey);
            }

            // Only if aggregated exists (which it should always do at this point)
            if (aggregated) {
                // Track all abort functions for bulk cancellation
                if (entry.abort) {
                    const originalAbort = aggregated.abort;
                    aggregated.abort = () => {
                        if (originalAbort) originalAbort();
                        entry.abort?.();
                    };
                }

                // Track all retry functions for bulk retry
                if (entry.retry) {
                    const originalRetry = aggregated.retry;
                    aggregated.retry = () => {
                        if (originalRetry) originalRetry();
                        entry.retry?.();
                    };
                }

                // If new file encountered, add to total
                if (!aggregated.childrenPaths.has(entry.file.path)) {
                    aggregated.total += entry.event.total || 0;
                    aggregated.childrenPaths.add(entry.file.path);
                }

                aggregated.loaded += entry.event.loaded;

                // Normalize loaded value if it exceeds total
                if (aggregated.loaded > aggregated.total) {
                    aggregated.loaded = aggregated.total;
                }

                // Update status based on child entries
                if (entry.status === "error") {
                    // Check if this error is already in the errors array
                    const errorExists = aggregated.errors.some(err => err.path === entry.file.path);
                    if (!errorExists) {
                        const { detail } = httpErrorHandler(entry.error);
                        aggregated.errors.push({
                            path: entry.file.path,
                            message: detail,
                        });
                    }
                }

                // Determine overall status
                if (entry.status === "aborted" && aggregated.status !== "error") {
                    aggregated.status = "aborted";
                }
                if (entry.status === "error") {
                    aggregated.status = "error";
                }
                if (aggregated.loaded >= aggregated.total && aggregated.errors.length === 0) {
                    aggregated.status = "completed";
                }
            }
        });

        return newAggregatedData;
    }, [uploadProgress, shouldAggregateData]);

    const aggregatedDataArray = useMemo(() => {
        if (!aggregatedData) return [];
        return Array.from(aggregatedData.values());
    }, [aggregatedData]);

    const buildFilesTree = ({ files, baseId }: GetFilesTreeProps) => {
        const root: DirectoryT = {
            directoryName: null,
            directoryId: baseId,
            files: [],
            children: [],
        };

        for (const f of files) {
            const file = extractFile(f);
            if (!file) continue;

            const filePath = extractFilePath(f);

            // Split path into components
            const parts = filePath.split("/");
            // Remove the file name
            parts.pop();

            // If no directory parts, it's a root-level file
            if (parts.length === 0) {
                root.files.push(file);
            } else {
                // Navigate the tree, creating directories as needed
                let currentNode = root;

                for (const dirName of parts) {
                    if (!dirName) continue; // Skip empty parts

                    // Find existing directory or create a new one
                    let childDir = currentNode.children.find(
                        child => child.directoryName === dirName,
                    );

                    if (!childDir) {
                        childDir = {
                            directoryName: dirName,
                            directoryId: null,
                            tempUUID: uuidv4(),
                            parentDirId: currentNode.directoryId,
                            children: [],
                            files: [],
                        } as DirectoryT;
                        currentNode.children.push(childDir);
                    }

                    currentNode = childDir;
                }

                // Add file to the final directory
                currentNode.files.push(file);
            }
        }

        return root;
    };

    const uploadsFile = async ({ file, directoryId }: FileUploadParams) => {
        const tempUUID = uuidv4();
        let controller = new AbortController();

        const uploadFunction = async () => {
            controller = new AbortController();

            try {
                await uploadFile({
                    file: file,
                    directoryId: directoryId,
                    abortController: controller,
                    progressCallBack: e => {
                        setUploadProgress(
                            prev =>
                                new Map(
                                    prev.set(tempUUID, {
                                        event: e,
                                        file: file,
                                        status: "uploading",
                                        abort: () => controller.abort(),
                                        retry: () => uploadFunction(),
                                    }),
                                ),
                        );
                    },
                });

                setUploadProgress(prev => {
                    const entry = prev.get(tempUUID);
                    if (entry) {
                        prev.set(tempUUID, {
                            ...entry,
                            status: "completed",
                            retry: undefined,
                            abort: undefined,
                        });
                    }
                    return new Map(prev);
                });

                // file upload ended revalidate query
                queryClient.invalidateQueries({
                    queryKey: ["directory", directoryId],
                });
            } catch (error) {
                if (controller.signal.aborted) {
                    setUploadProgress(prev => {
                        const entry = prev.get(tempUUID);
                        if (entry) {
                            prev.set(tempUUID, {
                                ...entry,
                                status: "aborted",
                                abort: undefined,
                                retry: () => uploadFunction(),
                            });
                        }
                        return new Map(prev);
                    });
                } else {
                    setUploadProgress(prev => {
                        const entry = prev.get(tempUUID);
                        if (entry) {
                            prev.set(tempUUID, {
                                ...entry,
                                status: "error",
                                abort: undefined,
                                retry: () => uploadFunction(),
                                error: error as Error,
                            });
                        }
                        return new Map(prev);
                    });
                }
            }
        };

        await uploadFunction();
        return tempUUID;
    };
    const uploadFSDirs = async (rootNode: DirectoryT) => {
        // Queue for BFS
        const queue: { node: DirectoryT; parentId: string }[] = [
            { node: rootNode, parentId: rootNode.directoryId || "" },
        ];

        Promise.allSettled(
            rootNode.files.map(file =>
                uploadsFile({ file, directoryId: rootNode.directoryId || "" }),
            ),
        );

        // Process all directories level by level
        while (queue.length > 0) {
            const { node, parentId } = queue.shift()!;
            let currentDirId = parentId;

            // Create directory if it's not the root
            if (node.directoryName !== null) {
                try {
                    const response = await createDirectory({
                        name: node.directoryName,
                        parentDirId: parentId,
                    });
                    currentDirId = response.id;
                    node.directoryId = currentDirId;
                    Promise.allSettled(
                        node.files.map(file => uploadsFile({ file, directoryId: currentDirId })),
                    );
                } catch (_error) {
                    break;
                }
            }

            // Add all children to the queue
            node.children.forEach(child => {
                queue.push({ node: child, parentId: currentDirId });
            });
        }
    };
    return {
        buildFilesTree,
        uploadFSDirs,
        uploadProgress,
        setUploadProgress,
        resetUploadProgress,
        deleteUplaodProgressEntry,
        aggregatedData,
        aggregatedDataArray,
    };
}

export { useUploadFsElements };
