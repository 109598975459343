const getUserLvl = (lvl: number) => {
    if (lvl === 0) {
        return "Uczeń";
    }
    if (lvl === 1) {
        return "Nauczyciel";
    }
    if (lvl === 2) {
        return "Administrator";
    }
    return "";
};
export { getUserLvl };
