import { httpErrorHandler } from "@/api/api";
import { usePostPostMutation } from "@/api/queries/postsQueries";
import { Button } from "@/components/ui/button/Button";
import {
    Dialog,
    DialogBody,
    DialogContent,
    DialogDescription,
    DialogFooter,
    DialogHeader,
    DialogTitle,
} from "@/components/ui/dialog/Dialog";
import { FeaturedIcon } from "@/components/ui/featured-icon/FeaturedIcon";
import {
    Form,
    FormControl,
    FormField,
    FormItem,
    FormLabel,
    FormMessage,
} from "@/components/ui/form/Form";
import { Input } from "@/components/ui/input/Input";
import FileInput from "@/components/ui/input/file-input/FileInput";
import FileInputCard from "@/components/ui/input/file-input/FileInputCard";
import { Textarea } from "@/components/ui/input/textarea/Textarea";
import { Label } from "@/components/ui/label/Label";
import { PostAddSchema, PostAddSchemaType } from "@/schemas/post.schema";
import { yupResolver } from "@hookform/resolvers/yup";
import { BookText, LoaderCircle, CheckCircle2 } from "lucide-react";
import { ChangeEvent, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { useForm } from "react-hook-form";
import { toast } from "sonner";

interface PostAddFormPropsT {
    open: boolean;
    onOpenChange: (bool: boolean) => void;
}

export default function CreatePost({ open, onOpenChange }: PostAddFormPropsT) {
    const [files, setFiles] = useState<{ id: string; file: File }[]>([]);

    const { mutateAsync, isPending } = usePostPostMutation();

    const handleAddFiles = async (e: ChangeEvent<HTMLInputElement>) => {
        if (!e.target.files) {
            return;
        }
        const newFiles = Array.from(e.target.files).map(file => {
            if (file.size > 500 * 1024 * 1024) {
                toast.error("Błąd", {
                    description: "Maksymalny rozmiar pliku to 500MB",
                });
            }
            return {
                id: uuidv4(),
                file,
            };
        });
        setFiles(prevFiles => [...prevFiles, ...newFiles]);
        return e.target.files;
    };

    const onRemove = (id: string) => {
        setFiles(curr => curr.filter(file => file.id !== id));
    };

    const onClose = () => {
        onOpenChange(false);
        setFiles([]);
        form.reset();
    };

    const onSubmit = (data: PostAddSchemaType) => {
        const attachments = files.map(({ file }) => file);
        mutateAsync({ ...data, attachments: attachments })
            .then(() => {
                onClose();
            })
            .catch(error => {
                const { title, detail } = httpErrorHandler(error);
                toast.error(title, {
                    description: detail,
                });
            });
    };

    const form = useForm<PostAddSchemaType>({
        defaultValues: {
            name: "",
            content: "",
            active: true,
            attachments: undefined,
        },
        mode: "onBlur",
        resolver: yupResolver(PostAddSchema),
    });

    return (
        <Dialog open={open} onOpenChange={onOpenChange}>
            <DialogContent
                className={"sm:max-w-148"}
                onCloseAutoFocus={onClose}
                onEscapeKeyDown={onClose}
            >
                <DialogDescription className={"sr-only"}>
                    Formularz do tworzenia postu
                </DialogDescription>
                <Form {...form}>
                    <DialogBody>
                        {isPending && (
                            <div
                                className={
                                    "absolute inset-0 z-60 m-auto flex flex-wrap items-center justify-center gap-3 bg-surface-primary/75 backdrop-blur-md"
                                }
                            >
                                <LoaderCircle className={"animate-spin"} aria-hidden={"true"} />
                                <h5 className={"font-medium"}>Trwa tworzenie posta...</h5>
                            </div>
                        )}
                        <DialogHeader className={"border-b border-border-primary"}>
                            <FeaturedIcon variant={"ghost"}>
                                <BookText />
                            </FeaturedIcon>
                            <div className={"flex flex-col gap-0.5 min-w-0 grow"}>
                                <DialogTitle>Utwórz Post</DialogTitle>
                                <p className={"text-text-tertiary text-xs"}>
                                    Stwórz opisz dla posta
                                </p>
                            </div>
                        </DialogHeader>
                        <form
                            className={"flex flex-col gap-3 p-3 md:p-4 overflow-auto grow"}
                            onSubmit={form.handleSubmit(onSubmit)}
                            noValidate
                        >
                            <FormField
                                name={"name"}
                                control={form.control}
                                render={({ field }) => (
                                    <FormItem>
                                        <FormLabel>Tytuł</FormLabel>
                                        <FormControl>
                                            <Input {...field} placeholder={"Tytuł"} />
                                        </FormControl>
                                        <FormMessage />
                                    </FormItem>
                                )}
                            />
                            <FormField
                                name={"content"}
                                control={form.control}
                                render={({ field }) => (
                                    <FormItem>
                                        <FormLabel>Treść</FormLabel>
                                        <FormControl>
                                            <Textarea
                                                placeholder={"Co chcesz przekazać ?"}
                                                {...field}
                                            />
                                        </FormControl>
                                        <FormMessage />
                                    </FormItem>
                                )}
                            />
                            <div className={"flex flex-col gap-1"}>
                                <Label>Media</Label>
                                <FileInput onAddFiles={handleAddFiles}>
                                    {files.map(({ id, file }) => (
                                        <FileInputCard
                                            key={id}
                                            file={file}
                                            onRemove={() => onRemove(id)}
                                        />
                                    ))}
                                </FileInput>
                            </div>
                        </form>
                    </DialogBody>
                    <DialogFooter className={"border-t border-border-primary"}>
                        <Button
                            onClick={onClose}
                            type={"button"}
                            variant={"tertiary"}
                            className={"w-full md:w-fit"}
                        >
                            Anuluj
                        </Button>
                        <Button
                            disabled={isPending}
                            onClick={form.handleSubmit(onSubmit)}
                            type={"submit"}
                            variant={"primary"}
                            className={"w-full md:w-fit"}
                        >
                            {isPending ? (
                                <>
                                    <LoaderCircle className={"animate-spin"} aria-hidden={"true"} />
                                    Zapisywanie...
                                </>
                            ) : (
                                <>
                                    <CheckCircle2 />
                                    Zapisz
                                </>
                            )}
                        </Button>
                    </DialogFooter>
                </Form>
            </DialogContent>
        </Dialog>
    );
}
