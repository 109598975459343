import { Label } from "@/components/ui/label/Label";
import { cn } from "@/lib/utils";
import * as LabelPrimitive from "@radix-ui/react-label";
import { Slot } from "@radix-ui/react-slot";
import * as React from "react";
import {
    Controller,
    ControllerProps,
    FieldError,
    FieldPath,
    FieldValues,
    FormProvider,
    useFormContext,
} from "react-hook-form";

const Form = FormProvider;

interface FormFieldContextValue<
    TFieldValues extends FieldValues = FieldValues,
    TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> {
    name: TName;
}

const FormFieldContext = React.createContext<FormFieldContextValue | null>(
    {} as FormFieldContextValue,
);

const FormField = <
    TFieldValues extends FieldValues = FieldValues,
    TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>({
    ...props
}: ControllerProps<TFieldValues, TName>) => {
    return (
        <FormFieldContext.Provider value={{ name: props.name }}>
            <Controller {...props} />
        </FormFieldContext.Provider>
    );
};

const useFormField = () => {
    const fieldContext = React.useContext(FormFieldContext);

    if (!fieldContext) {
        throw new Error("useFormField should be used within <FormField>");
    }

    const itemContext = React.useContext(FormItemContext);
    const { getFieldState, formState } = useFormContext();
    const fieldState = getFieldState(fieldContext.name, formState);

    const { id } = itemContext;

    return {
        id,
        name: fieldContext.name,
        formItemId: `${id}-form-item`,
        formDescriptionId: `${id}-form-item-description`,
        formMessageId: `${id}-form-item-message`,
        ...fieldState,
    };
};

interface FormItemContextValue {
    id: string;
}

const FormItemContext = React.createContext<FormItemContextValue>({} as FormItemContextValue);

const FormItem = React.forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLDivElement>>(
    ({ className, ...props }, ref) => {
        const id = React.useId();

        return (
            <FormItemContext.Provider value={{ id }}>
                <div ref={ref} className={cn("flex flex-col gap-2 w-full", className)} {...props} />
            </FormItemContext.Provider>
        );
    },
);
FormItem.displayName = "FormItem";

const FormLabel = React.forwardRef<
    React.ElementRef<typeof LabelPrimitive.Root>,
    React.ComponentPropsWithoutRef<typeof LabelPrimitive.Root>
>(({ className, ...props }, ref) => {
    const { error, formItemId } = useFormField();
    return (
        <Label
            ref={ref}
            className={cn(error && "text-text-destructive", className)}
            htmlFor={formItemId}
            {...props}
        />
    );
});
FormLabel.displayName = "FormLabel";

const FormControl = React.forwardRef<
    React.ElementRef<typeof Slot>,
    React.ComponentPropsWithoutRef<typeof Slot>
>(({ ...props }, ref) => {
    const { error, formItemId, formDescriptionId, formMessageId } = useFormField();

    return (
        <Slot
            ref={ref}
            id={formItemId}
            aria-describedby={
                !error ? `${formDescriptionId}` : `${formDescriptionId} ${formMessageId}`
            }
            aria-invalid={!!error}
            {...props}
        />
    );
});
FormControl.displayName = "FormControl";

const FormDescription = React.forwardRef<
    HTMLParagraphElement,
    React.HTMLAttributes<HTMLParagraphElement>
>(({ className, ...props }, ref) => {
    const { formDescriptionId } = useFormField();

    return (
        <p
            ref={ref}
            id={formDescriptionId}
            className={cn("text-sm text-text-tertiary", className)}
            {...props}
        />
    );
});
FormDescription.displayName = "FormDescription";

const FormMessage = React.forwardRef<
    HTMLParagraphElement,
    React.HTMLAttributes<HTMLParagraphElement>
>(({ className, children, ...props }, ref) => {
    const { error, formMessageId } = useFormField();
    const body = error ? String(error.message) : children;

    return (
        <small
            ref={ref}
            id={formMessageId}
            className={cn("relative h-5 truncate text-xs text-text-destructive", className)}
            {...props}
        >
            {body}
        </small>
    );
});
FormMessage.displayName = "FormMessage";

const FormMessages = React.forwardRef<
    HTMLParagraphElement | HTMLUListElement,
    React.HTMLAttributes<HTMLParagraphElement | HTMLUListElement> & {
        keys: string[];
        listView?: boolean;
    }
>(({ className, keys = [], listView = false, ...props }, ref) => {
    const {
        formState: { errors },
    } = useFormContext();
    const messages: FieldError[] = keys
        .map(key => errors[key])
        .filter((error): error is FieldError => error !== undefined);

    if (listView) {
        return (
            <ul
                ref={ref as React.RefObject<HTMLUListElement>}
                className={
                    "space-y-1 h-fit overflow-hidden transition-all duration-500 ease-in-out"
                }
            >
                {messages.map(({ message }, index) => (
                    <li
                        key={index}
                        aria-label={"error-chat-message"}
                        className={cn("relative text-xs text-text-destructive", className)}
                    >
                        {message}
                    </li>
                ))}
            </ul>
        );
    }
    return (
        <small
            ref={ref}
            aria-label={"error-chat-message"}
            className={cn("relative h-5 truncate text-xs text-text-destructive", className)}
            {...props}
        >
            {messages.join(", ")}
        </small>
    );
});
FormMessages.displayName = "FormMessages";

export {
    useFormField,
    Form,
    FormItem,
    FormLabel,
    FormControl,
    FormDescription,
    FormMessage,
    FormMessages,
    FormField,
};
