import { httpErrorHandler } from "@/api/api";
import { useGetAssignedMeetingsQuery } from "@/api/queries/meetingsQuery";
import CalendarContentHours from "@/components/features/calendar/layout/content/CalendarContentHours";
import CalendarContentRowLines from "@/components/features/calendar/layout/content/CalendarContentRowLines";
import CalendarContentTimeLine from "@/components/features/calendar/layout/content/CalendarContentTimeLine";
import MeetingContext from "@/components/features/meeting/MeetingContext";
import MeetingCalendarCard from "@/components/features/meeting/meeting-calendar-card/MeetingCalendarCard";
import { eventOverlap } from "@/utils/eventOverlap";
import { endOfDay, isToday, startOfDay } from "date-fns";
import { useEffect, useMemo } from "react";
import { toast } from "sonner";
import { Button } from "@/components/ui/button/Button";

interface CalendarContentDayProps {
    date: Date;
    containerHeight: number;
}

export default function CalendarContentDay({ date, containerHeight }: CalendarContentDayProps) {
    return (
        <div
            className={"relative flex w-full flex-row grow"}
            style={{ height: `${containerHeight}px`, minHeight: `${containerHeight}px` }}
        >
            <CalendarContentHours />
            <CalendarContentRowLines
                space={containerHeight / 24}
                className={"absolute h-full w-full pl-12 md:pl-14"}
            />
            <div className={"relative flex w-full"}>
                <CalendarContentTimeLine hide={!isToday(date)} containerHeight={containerHeight} />
                <CalendarContentDataDay date={date} containerHeight={containerHeight} />
            </div>
        </div>
    );
}

interface CalendarContentDataDayProps {
    date: Date;
    containerHeight: number;
}

function CalendarContentDataDay({ date, containerHeight }: CalendarContentDataDayProps) {
    const queryParams = useMemo(
        () => ({
            after: startOfDay(date).toISOString(),
            before: endOfDay(date).toISOString(),
        }),
        [date],
    );
    const {
        data: meetings = [],
        isError,
        error,
        refetch,
    } = useGetAssignedMeetingsQuery(queryParams);

    useEffect(() => {
        if (isError) {
            const { title, detail } = httpErrorHandler(error);
            toast.error(title, {
                description: detail,
                action: (
                    <Button
                        size={"sm"}
                        className={"ml-auto"}
                        variant={"tertiary"}
                        onClick={() => {
                            refetch();
                        }}
                    >
                        Ponów
                    </Button>
                ),
            });
        }
    }, [error, isError, refetch]);

    const styles = useMemo(() => eventOverlap(meetings), [meetings]);

    return meetings.map(meeting => {
        return (
            <MeetingContext key={meeting.id} meeting={meeting} queryParams={queryParams}>
                <MeetingCalendarCard
                    meeting={meeting}
                    options={{
                        containerHeight: containerHeight,
                        style: styles[meeting.id],
                    }}
                />
            </MeetingContext>
        );
    });
}
