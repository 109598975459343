import { Button } from "@/components/ui/button/Button";
import { useCopyToClipboard } from "@/hooks/useCopyToClipboard";
import { cn } from "@/lib/utils";
import { useState } from "react";
import type { MeetingPublic } from "@/types";
import { Link2, CheckIcon, CopyIcon } from "lucide-react";

interface MeetingRemoteLinkProps {
    meeting: MeetingPublic;
}

export default function MeetingRemoteLink({ meeting }: MeetingRemoteLinkProps) {
    const { meetingURL } = meeting;
    const [copied, setCopied] = useState<boolean>(false);

    const [_, copy] = useCopyToClipboard();

    const handleCopy = async () => {
        if (!meetingURL) return;
        try {
            await copy(meetingURL);
            setCopied(true);
            setTimeout(() => setCopied(false), 1500);
        } catch (err) {
            console.error("Failed to copy text: ", err);
        }
    };

    return (
        <div className={"flex w-full gap-3"}>
            {meetingURL ? (
                <a href={meetingURL} target="_blank" rel="noreferrer">
                    <Button variant={"tertiary"}>
                        <Link2 />
                        Link do spotkania
                    </Button>
                </a>
            ) : (
                <Button variant={"tertiary"} disabled>
                    Brak linku do spotkania
                </Button>
            )}
            <Button
                variant="ghost"
                className="disabled:opacity-100"
                onClick={() => void handleCopy()}
                aria-label={copied ? "Copied" : "Copy to clipboard"}
                disabled={copied}
            >
                <div
                    className={cn(
                        "transition-all",
                        copied ? "scale-100 opacity-100" : "scale-0 opacity-0",
                    )}
                >
                    <CheckIcon className="stroke-icon-success" aria-hidden="true" />
                </div>
                <div
                    className={cn(
                        "absolute transition-all",
                        copied ? "scale-0 opacity-0" : "scale-100 opacity-100",
                    )}
                >
                    <CopyIcon aria-hidden="true" />
                </div>
            </Button>
        </div>
    );
}
