import { Avatar, AvatarFallback, AvatarImage } from "@/components/ui/avatar/Avatar";
import {
    Identifiable,
    SelectListElementContent,
    SelectListElementDescription,
    useSelectListContext,
} from "@/components/ui/select-list/SelectList";
import { getAvatar } from "@/utils/getAvatar";
import { User } from "lucide-react";
import { useCallback } from "react";
import { Checkbox } from "@/components/ui/input/checkbox/Checkbox";
import { SelectListElement, SelectListElementTitle } from "@/components/ui/select-list/SelectList";

interface SelectDataListElementProps<T extends Identifiable> {
    entity: T;
    data: {
        title?: string;
        detail?: string;
        avatarURL?: string;
    };
    className?: string;
    disabled?: boolean;
}

const SelectDataListElement = <T extends Identifiable>(props: SelectDataListElementProps<T>) => {
    const {
        entity,
        data: { title, detail, avatarURL },
        className,
        disabled = false,
    } = props;

    const { unselectElement, selectElement, isSelected } = useSelectListContext();

    const checked = isSelected(entity.id);

    const onCheckedChange = useCallback(() => {
        if (checked) {
            unselectElement(entity.id);
        } else {
            selectElement(entity);
        }
    }, [checked, selectElement, unselectElement, entity]);

    const hasAvatarProp = Object.prototype.hasOwnProperty.call(props.data, "avatarURL");

    return (
        <SelectListElement className={className}>
            {hasAvatarProp && (
                <Avatar size={"md"}>
                    <AvatarImage src={getAvatar(avatarURL)} alt="avatar" />
                    <AvatarFallback>
                        <User />
                    </AvatarFallback>
                </Avatar>
            )}
            <SelectListElementContent>
                {title && <SelectListElementTitle>{title}</SelectListElementTitle>}
                {detail && <SelectListElementDescription>{detail}</SelectListElementDescription>}
            </SelectListElementContent>
            <Checkbox disabled={disabled} checked={checked} onCheckedChange={onCheckedChange} />
        </SelectListElement>
    );
};

export { SelectDataListElement };
