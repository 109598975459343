import { timeToHeight } from "@/utils/timeToHeight";
import { format, isValid } from "date-fns";
import { useEffect, useState } from "react";

/**
 * TimeLineProps interface
 *
 * This interface defines the properties for the TimeLine component.
 *
 * @interface
 * @property {number} containerHeight - The height of the container.
 * @property {boolean} hide - A flag to hide the component, default is false.
 */
interface TimeLineProps {
    containerHeight: number;
    hide?: boolean;
}

/**
 * TimeLine component
 *
 * This component displays a timeline that updates every 15 seconds to reflect the current time.
 * The timeline's vertical position corresponds to the current time, with the top of the container representing the start of the day and the bottom representing the end of the day.
 * If the hide property is true, the component will not be rendered.
 *
 * @component
 * @param {TimeLineProps} props - The properties that define how the component should be rendered.
 * @returns {JSX.Element | null} A timeline that reflects the current time or null if the hide property is true.
 */

export default function CalendarContentTimeLine({ containerHeight, hide = false }: TimeLineProps) {
    const [now, setNow] = useState<Date>(new Date());

    const handleDateTime = () => {
        if (isValid(now)) {
            return format(now, "HH:mm");
        }
        return "";
    };

    useEffect(() => {
        const interval = setInterval(() => {
            setNow(new Date());
        }, 15000);

        return () => clearInterval(interval);
    }, []);

    const offset = timeToHeight(now, containerHeight);

    if (hide) {
        return null;
    }

    return (
        <span
            className={"absolute z-5 w-full h-px border-t-3 border-border-accent border-dashed"}
            style={{ top: `${offset}px` }}
        >
            <span
                className={"absolute -bottom-1.25 left-0 z-5 size-3 rounded-full bg-border-accent"}
            />
            <p
                className={
                    "absolute -bottom-2 -left-11 z-5 flex h-fit shrink-0 items-center justify-center rounded-xs bg-fill-primary py-0.5 px-1 text-xs text-text-accent shadow-md border-border-secondary"
                }
            >
                {handleDateTime()}
            </p>
        </span>
    );
}
