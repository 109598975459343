import { httpErrorHandler } from "@/api/api";
import { useInfiniteQueryResult } from "@/api/api-utils";
import { useEditGroupMutation, useGetGroupsInfiniteQuery } from "@/api/queries/groupsQueries";
import {
    useEditUserMutation,
    useGetUserGroupsQuery,
    useGetUserQuery,
} from "@/api/queries/usersQueries";
import { Button } from "@/components/ui/button/Button";
import {
    Dialog,
    DialogBody,
    DialogContent,
    DialogFooter,
    DialogHeader,
    DialogTitle,
    DialogDescription,
} from "@/components/ui/dialog/Dialog";
import {
    Form,
    FormControl,
    FormField,
    FormItem,
    FormLabel,
    FormMessage,
} from "@/components/ui/form/Form";
import { Input, InputActionButton, InputWrapper } from "@/components/ui/input/Input";
import {
    Select,
    SelectContent,
    SelectGroup,
    SelectItem,
    SelectTrigger,
    SelectValue,
} from "@/components/ui/input/select/Select";
import { SelectDataListElement } from "@/components/ui/select-list/SelectDataListElement";
import SelectDataList, { SelectDataListLabel } from "@/components/ui/select-list/SelectDataList";
import { useDebounceValue } from "@/hooks/useDebounceValue";
import { cn } from "@/lib/utils";
import { UserEditSchema, UserEditSchemaType } from "@/schemas/user.schema";
import { useCredentials } from "@/store/authStore";
import type { GroupPrivate } from "@/types";
import { serializeData } from "@/utils/serializeData";
import { yupResolver } from "@hookform/resolvers/yup";
import {
    Search,
    LoaderCircle,
    User,
    CalendarIcon,
    ChevronLeft,
    CircleX,
    Plus,
    CheckCircle2,
    Users,
} from "lucide-react";
import { Dispatch, SetStateAction, useEffect, useState, useCallback } from "react";
import { useForm } from "react-hook-form";
import { FeaturedIcon } from "@/components/ui/featured-icon/FeaturedIcon";
import { Badge } from "@/components/ui/badge/Badge";
import { PhoneInput } from "@/components/ui/input/phone-input/PhoneInput";
import {
    Calendar,
    CalendarPopoverContent,
    CalendarPopoverTrigger,
    DateInput,
    DatePicker,
} from "@/components/ui/input/date-picker/DatetimePicker";
import { parseAbsoluteToLocal } from "@internationalized/date";
import { CalendarPopover } from "@/components/ui/input/date-picker/DatetimePicker";
import { Tabs, TabsContent } from "@/components/ui/tabs/Tabs";
import { Tag } from "@/components/ui/tags/Tags";
import { toast } from "sonner";
import type { UserPrivate } from "@/types";
import { Avatar, AvatarFallback, AvatarImage } from "@/components/ui/avatar/Avatar";
import { getAvatar } from "../../../../utils/getAvatar";

interface UserEditFormPropsT {
    open: boolean;
    onOpenChange: Dispatch<SetStateAction<boolean>>;
    callback?: () => void;
    user?: UserPrivate | null;
}

export default function EditUser({ user, open, onOpenChange, callback }: UserEditFormPropsT) {
    const [isPending, setIsPending] = useState<boolean>(false);

    const { lvl } = useCredentials();

    const [tabs, setTabs] = useState<"main" | "selectedList">("main");

    const [searchUserValue, setSearchUserValue] = useState<string>("");
    const searchGroupDebouncedValue = useDebounceValue(searchUserValue, 400);
    const [selectedGroups, setSelectedGroups] = useState<GroupPrivate[]>([]);
    const { data: userData, ...userResults } = useGetUserQuery(user?.id);
    const { data: userGroups = [], ...userGroupsResults } = useGetUserGroupsQuery(user?.id);

    const isMainTab = tabs === "main";

    const getGroupsInfiniteQuery = useGetGroupsInfiniteQuery({
        name: searchGroupDebouncedValue,
    });

    const {
        data: groups,
        isEmpty: groupsIsEmpty,
        noResults: groupsNoResults,
    } = useInfiniteQueryResult(getGroupsInfiniteQuery.data, searchGroupDebouncedValue);

    const { mutateAsync: userEditMutation } = useEditUserMutation();
    const { mutate: groupEditMutation } = useEditGroupMutation();

    const form = useForm<UserEditSchemaType>({
        mode: "onBlur",
        defaultValues: {
            name: "",
            surname: "",
            email: "",
            lvl: "0",
            phone: "",
            birthdate: null,
            active: true,
            force_password_change: undefined,
        },
        values: userData && UserEditSchema.cast(serializeData(userData)),
        resolver: yupResolver(UserEditSchema),
    });

    const onRemoveGroup = (id: number) => {
        setSelectedGroups(prev => prev.filter(user => user.id !== id));
    };

    const onTabsValueChange = (tab: string) => {
        setTabs(tab as "main" | "selectedList");
    };

    const onSubmit = async (data: UserEditSchemaType) => {
        setIsPending(true);

        const groupsToAdd = selectedGroups.filter(selectedGroup =>
            userGroups.every(userGroup => userGroup.id !== selectedGroup.id),
        );
        const groupsToRemove = userGroups.filter(
            userGroup => !selectedGroups.find(selectedGroup => selectedGroup.id === userGroup.id),
        );

        groupsToAdd.forEach(({ id }) => {
            groupEditMutation({
                id: id,
                memberChanges: [
                    {
                        userIds: user?.id ? [user.id] : [],
                        add: true,
                    },
                ],
            });
        });
        groupsToRemove.forEach(({ id }) => {
            groupEditMutation({
                id: id,
                memberChanges: [
                    {
                        userIds: user?.id ? [user.id] : [],
                        remove: true,
                    },
                ],
            });
        });

        const castedData = UserEditSchema.cast(serializeData(data));

        await userEditMutation(castedData)
            .then(() => {
                onClose();
            })
            .catch(error => {
                const { title, detail } = httpErrorHandler(error);
                toast.error(title, {
                    description: detail,
                });
            });

        setIsPending(false);
    };

    const onClose = useCallback(() => {
        callback?.();
        onOpenChange(false);
        setSearchUserValue("");
        setSelectedGroups([]);
        form.reset();
    }, [callback, form, onOpenChange]);

    useEffect(() => {
        if (userGroupsResults.isSuccess && open) {
            setSelectedGroups(userGroups);
        }
    }, [userGroupsResults.isSuccess, open, userGroups]);

    useEffect(() => {
        if (userResults.isError) {
            const { title, detail } = httpErrorHandler(userResults.error);
            onClose();
            toast.error(title, {
                description: detail,
            });
        } else if (userGroupsResults.isError) {
            const { title, detail } = httpErrorHandler(userGroupsResults.error);
            onClose();
            toast.error(title, {
                description: detail,
            });
        }
    }, [
        userResults.isError,
        userGroupsResults.isError,
        userGroupsResults.error,
        userResults.error,
        onClose,
    ]);

    return (
        <Dialog open={open} onOpenChange={onOpenChange}>
            <DialogContent
                isClosable={isMainTab}
                className={"sm:max-w-148"}
                onCloseAutoFocus={onClose}
                onEscapeKeyDown={onClose}
            >
                <DialogDescription className={"sr-only"}>
                    Formularz do edycji użytkownika
                </DialogDescription>
                <Form {...form}>
                    <DialogBody>
                        {isPending && (
                            <div
                                className={
                                    "absolute inset-0 z-60 m-auto flex flex-wrap items-center justify-center gap-3 bg-surface-primary/75 backdrop-blur-md"
                                }
                            >
                                <LoaderCircle className={"animate-spin"} aria-hidden={"true"} />
                                <h5 className={"font-medium"}>Trwa zapisywanie zmian...</h5>
                            </div>
                        )}
                        {(userResults.isFetching || userGroupsResults.isFetching) && (
                            <div
                                className={
                                    "absolute inset-0 z-60 m-auto flex flex-wrap items-center justify-center gap-3 bg-surface-primary/75 backdrop-blur-md"
                                }
                            >
                                <LoaderCircle className={"animate-spin"} aria-hidden={"true"} />
                                <h5 className={"font-medium"}>Trwa ładowanie danych...</h5>
                            </div>
                        )}
                        {isMainTab ? (
                            <DialogHeader className={"border-b border-border-primary"}>
                                <FeaturedIcon variant={"ghost"}>
                                    <User />
                                </FeaturedIcon>
                                <div className={"flex flex-col gap-0.5 min-w-0 grow"}>
                                    <DialogTitle>Edycja Użytkownika</DialogTitle>
                                    <p className={"text-text-tertiary text-xs"}>
                                        Wprawdź wymagane dane użytkownika.
                                    </p>
                                </div>
                            </DialogHeader>
                        ) : (
                            <DialogHeader className={"border-b border-border-primary"}>
                                <DialogTitle className={"sr-only"}>Edytuj Grupę</DialogTitle>
                                <Button variant={"outline"} onClick={() => setTabs("main")}>
                                    <ChevronLeft />
                                </Button>
                                <InputWrapper className={"w-full"}>
                                    <Input
                                        value={searchUserValue}
                                        onChange={e => setSearchUserValue(e.target.value)}
                                        className="peer pe-8 ps-8"
                                        placeholder="Wyszukaj..."
                                    />
                                    <div className="pointer-events-none absolute inset-y-0 start-0 flex items-center justify-center ps-2 peer-disabled:opacity-50">
                                        <Search className="size-3 stroke-icon-tertiary" />
                                    </div>
                                    {searchUserValue && (
                                        <button
                                            className="absolute cursor-pointer inset-y-0 end-0 flex h-full w-9 items-center justify-center rounded-e-sm text-text-tertiary transition-colors hover:text-text-tertiary-hover z-10 focus-visible:outline-2 outline-ring-focus disabled:pointer-events-none disabled:cursor-not-allowed disabled:opacity-50"
                                            aria-label="Clear input"
                                            onClick={() => setSearchUserValue("")}
                                        >
                                            <CircleX className={"size-3"} aria-hidden="true" />
                                        </button>
                                    )}
                                </InputWrapper>
                            </DialogHeader>
                        )}
                        <Tabs
                            value={tabs}
                            onValueChange={onTabsValueChange}
                            className={"flex flex-col gap-3 p-3 md:p-4 overflow-auto grow"}
                        >
                            <TabsContent value={"main"}>
                                <form
                                    className={"flex flex-col gap-3 grow"}
                                    onSubmit={form.handleSubmit(onSubmit)}
                                    noValidate
                                >
                                    <FormField
                                        name={"name"}
                                        control={form.control}
                                        render={({ field }) => (
                                            <FormItem className={"col-span-1"}>
                                                <FormLabel>Imię</FormLabel>
                                                <FormControl>
                                                    <Input {...field} placeholder={"Imię"} />
                                                </FormControl>
                                                <FormMessage />
                                            </FormItem>
                                        )}
                                    />
                                    <FormField
                                        name={"surname"}
                                        control={form.control}
                                        render={({ field }) => (
                                            <FormItem className={"col-span-1"}>
                                                <FormLabel>Nazwisko</FormLabel>
                                                <FormControl>
                                                    <Input {...field} placeholder={"Nazwisko"} />
                                                </FormControl>
                                                <FormMessage />
                                            </FormItem>
                                        )}
                                    />
                                    <FormField
                                        name={"email"}
                                        control={form.control}
                                        render={({ field }) => (
                                            <FormItem className={"col-span-2"}>
                                                <FormLabel>Email</FormLabel>
                                                <FormControl>
                                                    <Input {...field} placeholder={"Email"} />
                                                </FormControl>
                                                <FormMessage />
                                            </FormItem>
                                        )}
                                    />
                                    <FormField
                                        name={"lvl"}
                                        control={form.control}
                                        render={({ field }) => (
                                            <FormItem>
                                                <FormLabel>Poświadczenia</FormLabel>
                                                <Select
                                                    onValueChange={field.onChange}
                                                    defaultValue={field.value}
                                                >
                                                    <FormControl>
                                                        <SelectTrigger className="ps-2 [&>span]:flex [&>span]:items-center [&>span]:gap-2 [&>span_[data-square]]:shrink-0">
                                                            <SelectValue
                                                                placeholder={"Poświadczenia"}
                                                            />
                                                        </SelectTrigger>
                                                    </FormControl>
                                                    <SelectContent className="[&_*[role=option]]:ps-2 [&_*[role=option]]:pe-8 [&_*[role=option]>span]:start-auto [&_*[role=option]>span]:end-2 [&_*[role=option]>span]:flex [&_*[role=option]>span]:items-center [&_*[role=option]>span]:gap-2">
                                                        <SelectGroup>
                                                            <SelectItem value={"0"}>
                                                                <Badge variant={"blue"}>U</Badge>
                                                                <span className={"truncate"}>
                                                                    Uczeń
                                                                </span>
                                                            </SelectItem>
                                                            <SelectItem value={"1"}>
                                                                <Badge variant={"turquoise"}>
                                                                    N
                                                                </Badge>
                                                                <span className={"truncate"}>
                                                                    Nauczyciel
                                                                </span>
                                                            </SelectItem>
                                                            <SelectItem
                                                                disabled={lvl != 2}
                                                                value={"2"}
                                                            >
                                                                <Badge variant={"purple"}>A</Badge>
                                                                <span className={"truncate"}>
                                                                    Administrator
                                                                </span>
                                                            </SelectItem>
                                                        </SelectGroup>
                                                    </SelectContent>
                                                </Select>
                                                <FormMessage />
                                            </FormItem>
                                        )}
                                    />
                                    <FormField
                                        name={"phone"}
                                        control={form.control}
                                        render={({ field }) => (
                                            <FormItem>
                                                <FormLabel>
                                                    Numer telefonu{" "}
                                                    <small className={"text-text-tertiary text-sm"}>
                                                        (opcionalne)
                                                    </small>
                                                </FormLabel>
                                                <FormControl>
                                                    <PhoneInput
                                                        {...field}
                                                        defaultCountry={"PL"}
                                                        placeholder={"Numer telefonu"}
                                                    />
                                                </FormControl>
                                                <FormMessage />
                                            </FormItem>
                                        )}
                                    />
                                    <FormField
                                        name={"birthdate"}
                                        control={form.control}
                                        render={({ field }) => {
                                            return (
                                                <FormItem>
                                                    <FormLabel>
                                                        Data urodzenia{" "}
                                                        <small
                                                            className={"text-sm text-text-tertiary"}
                                                        >
                                                            (opcionalne)
                                                        </small>
                                                    </FormLabel>
                                                    <FormControl>
                                                        <DatePicker
                                                            aria-label={"birthdate"}
                                                            granularity={"day"}
                                                            value={
                                                                field.value
                                                                    ? parseAbsoluteToLocal(
                                                                          field.value,
                                                                      )
                                                                    : null
                                                            }
                                                            onChange={date => {
                                                                if (date) {
                                                                    field.onChange(date.toString());
                                                                } else {
                                                                    field.onChange(null);
                                                                }
                                                            }}
                                                        >
                                                            <CalendarPopover>
                                                                <InputWrapper>
                                                                    <DateInput
                                                                        className={"rounded-e-none"}
                                                                    />
                                                                    <CalendarPopoverTrigger asChild>
                                                                        <InputActionButton
                                                                            className={
                                                                                "-ms-px rounded-s-none"
                                                                            }
                                                                        >
                                                                            <CalendarIcon />
                                                                        </InputActionButton>
                                                                    </CalendarPopoverTrigger>
                                                                </InputWrapper>
                                                                <CalendarPopoverContent
                                                                    align={"end"}
                                                                    className={"p-2"}
                                                                >
                                                                    <Calendar />
                                                                </CalendarPopoverContent>
                                                            </CalendarPopover>
                                                        </DatePicker>
                                                    </FormControl>
                                                    <FormMessage />
                                                </FormItem>
                                            );
                                        }}
                                    />
                                </form>
                                <div className={"space-y-3"}>
                                    <div className={"flex justify-between"}>
                                        <h5 className={"font-medium"}>Grupy:</h5>
                                        <Button
                                            size="sm"
                                            variant="tertiary"
                                            onClick={() => onTabsValueChange("selectedList")}
                                        >
                                            <Plus />
                                            Dodaj
                                        </Button>
                                    </div>
                                    <div className="flex flex-wrap gap-1">
                                        {selectedGroups.map(({ id, name, avatarURL }) => (
                                            <Tag
                                                key={id}
                                                onRemove={() => onRemoveGroup(id)}
                                                variant={"muted"}
                                                border
                                            >
                                                <Avatar size={"xs"}>
                                                    <AvatarImage
                                                        src={getAvatar(avatarURL)}
                                                        alt={name}
                                                    />
                                                    <AvatarFallback>
                                                        <Users />
                                                    </AvatarFallback>
                                                </Avatar>
                                                {name}
                                            </Tag>
                                        ))}
                                        {selectedGroups.length === 0 && (
                                            <p
                                                className={
                                                    "w-full text-center p-4 text-text-tertiary text-sm"
                                                }
                                            >
                                                Brak wybranych grup
                                            </p>
                                        )}
                                    </div>
                                </div>
                            </TabsContent>
                            <TabsContent value={"selectedList"}>
                                <SelectDataList
                                    query={getGroupsInfiniteQuery}
                                    selected={selectedGroups}
                                    setSelected={setSelectedGroups}
                                    isEmpty={groupsIsEmpty}
                                    isNoResults={groupsNoResults}
                                    emptyMessage={"Wyszukaj grupę"}
                                    searchNoResultsMessage={"Nie znaleziono grupy"}
                                >
                                    <SelectDataListLabel
                                        className={cn(
                                            (!selectedGroups.length || searchGroupDebouncedValue) &&
                                                "hidden",
                                        )}
                                    >
                                        Dodane:
                                    </SelectDataListLabel>
                                    {!searchGroupDebouncedValue &&
                                        selectedGroups.map(entity => (
                                            <SelectDataListElement
                                                key={entity.id}
                                                entity={entity}
                                                data={{
                                                    title: entity.name,
                                                    avatarURL: entity.avatarURL,
                                                }}
                                            />
                                        ))}
                                    <SelectDataListLabel>Wyszukiwane:</SelectDataListLabel>
                                    {groups.map(entity => (
                                        <SelectDataListElement
                                            key={entity.id}
                                            entity={entity}
                                            data={{
                                                title: entity.name,
                                                avatarURL: entity.avatarURL,
                                            }}
                                        />
                                    ))}
                                </SelectDataList>
                            </TabsContent>
                        </Tabs>
                    </DialogBody>
                    <DialogFooter className={"border-t border-border-primary"}>
                        <Button
                            onClick={onClose}
                            type={"button"}
                            variant={"tertiary"}
                            className={"w-full md:w-fit"}
                        >
                            Anuluj
                        </Button>
                        <Button
                            disabled={isPending}
                            onClick={form.handleSubmit(onSubmit)}
                            type={"submit"}
                            variant={"primary"}
                            className={"w-full md:w-fit"}
                        >
                            {isPending ? (
                                <>
                                    <LoaderCircle className={"animate-spin"} aria-hidden={"true"} />
                                    Zapisywanie...
                                </>
                            ) : (
                                <>
                                    <CheckCircle2 />
                                    Zapisz
                                </>
                            )}
                        </Button>
                    </DialogFooter>
                </Form>
            </DialogContent>
        </Dialog>
    );
}
