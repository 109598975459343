import NotificationImage from "@/assets/images/notification.svg?react";
import {
    EmptyState,
    EmptyStateCircles,
    EmptyStateContent,
    EmptyStateDescription,
    EmptyStateTitle,
} from "@/components/ui/empty-state/EmptyState";
import { FeaturedIcon } from "@/components/ui/featured-icon/FeaturedIcon";

export default function NotificationsEmpty() {
    return (
        <EmptyState>
            <EmptyStateCircles className={"z-0"} />
            <FeaturedIcon size={"lg"} className={"z-10"}>
                <NotificationImage />
            </FeaturedIcon>
            <EmptyStateContent>
                <EmptyStateTitle>Brak powiadomień</EmptyStateTitle>
                <EmptyStateDescription>
                    W tej chwili nie masz nowych powiadomień. Wróć za jakiś czas, aby sprawdzić, czy
                    pojawiły się nowe informacje.
                </EmptyStateDescription>
            </EmptyStateContent>
        </EmptyState>
    );
}
