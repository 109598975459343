import { MeetingPublic } from "@/types";
import { cn } from "@/lib/utils";
import { format, getDate, isToday, endOfDay, endOfMonth, startOfDay, startOfMonth } from "date-fns";
import { pl } from "date-fns/locale";
import { useEffect, useRef, useState, useCallback, useMemo, useLayoutEffect } from "react";
import MeetingContext from "@/components/features/meeting/MeetingContext";
import MeetingCalendarCard from "@/components/features/meeting/meeting-calendar-card/MeetingCalendarCard";
import { httpErrorHandler } from "@/api/api";
import { useGetAssignedMeetingsQuery } from "@/api/queries/meetingsQuery";
import { useMapMeetingsByDate } from "@/components/features/calendar/layout/content/map-meetings-by-date";
import { SetNavigate, VIEW_KEY } from "@/types";
import { getMonth } from "@/utils/dateFormat";
import { useMediaQuery } from "@/hooks/useMediaQuery";
import {
    Dialog,
    DialogContent,
    DialogDescription,
    DialogHeader,
    DialogTitle,
} from "@/components/ui/dialog/Dialog";
import {
    Drawer,
    DrawerContent,
    DrawerDescription,
    DrawerHeader,
    DrawerTitle,
} from "@/components/ui/drawer/Drawer";
import { MeetingCard } from "@/components/features/meeting/MeetingCard";
import { toast } from "sonner";
import { Button } from "@/components/ui/button/Button";

interface CalendarContentMonthProps {
    date: Date;
    setNavigate: SetNavigate;
}

export default function CalendarContentMonth({ date, setNavigate }: CalendarContentMonthProps) {
    const month = getMonth(date, { fixedWeeks: true });

    const queryParams = useMemo(
        () => ({
            after: startOfDay(startOfMonth(date)).toISOString(),
            before: endOfDay(endOfMonth(date)).toISOString(),
        }),
        [date],
    );

    const { data = [], isError, error, refetch } = useGetAssignedMeetingsQuery(queryParams);

    const meetings = useMapMeetingsByDate(month, data);

    const handleOnClick = useCallback(
        (monthDayDate: Date): void => {
            setNavigate({ newDate: monthDayDate, newViewKey: VIEW_KEY.DAY });
        },
        [setNavigate],
    );

    const isSameMonth = useCallback(
        (monthDayDate: Date) => monthDayDate.getMonth() === date.getMonth(),
        [date],
    );

    useEffect(() => {
        if (isError) {
            const { title, detail } = httpErrorHandler(error);
            toast.error(title, {
                description: detail,
                action: (
                    <Button
                        size={"sm"}
                        className={"ml-auto"}
                        variant={"tertiary"}
                        onClick={() => {
                            refetch();
                        }}
                    >
                        Ponów
                    </Button>
                ),
            });
        }
    }, [error, isError, refetch]);

    return (
        <div
            className={
                "relative overflow-hidden grow grid h-full w-full grid-cols-7 grid-rows-6 p-0 [&>*:nth-child(7n)]:border-r-0! [&>*:nth-child(n)]:border-b [&>*:nth-child(n+36)]:border-b-0 [&>*:nth-child(n)]:border-r"
            }
        >
            {month.map(monthDayDate => (
                <MonthElement
                    key={monthDayDate.toISOString()}
                    date={monthDayDate}
                    params={queryParams}
                    meetings={meetings[monthDayDate.toISOString()] ?? []}
                    onClick={() => handleOnClick(monthDayDate)}
                    isSameMonth={() => isSameMonth(monthDayDate)}
                />
            ))}
        </div>
    );
}

export interface MonthElementProps {
    date: Date;
    onClick: () => void;
    isSameMonth: () => boolean;
    meetings: MeetingPublic[];
    params: { after: string; before: string };
}
export function MonthElement({
    date,
    onClick,
    isSameMonth,
    params,
    meetings = [],
}: MonthElementProps) {
    const containerRef = useRef<HTMLDivElement>(null);
    const [visibleMeetingsCount, setVisibleMeetingsCount] = useState<number>(0);
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
    const breakpoint = useMediaQuery("md");

    const hiddenMeetingsCount = useMemo(() => {
        return Math.max(0, meetings.length - visibleMeetingsCount);
    }, [meetings, visibleMeetingsCount]);

    const handleContainerClick = useCallback(() => {
        if (!breakpoint) {
            setIsModalOpen(true);
        }
    }, [breakpoint]);

    const calculateVisibleMeetings = useCallback(() => {
        const container = containerRef.current;
        if (!container || !meetings.length) {
            setVisibleMeetingsCount(0);
            return;
        }

        const meetingElements = container.querySelectorAll("[data-meeting-item]");
        if (meetingElements.length === 0) return;

        let totalHeight = 0;
        const containerHeight = container.clientHeight;
        let count = 0;

        for (let i = 0; i < meetingElements.length; i++) {
            const element = meetingElements[i] as HTMLElement;
            totalHeight += element.offsetHeight;
            if (totalHeight > containerHeight) break;
            count++;
        }

        setVisibleMeetingsCount(count);
    }, [meetings]);

    useLayoutEffect(() => {
        calculateVisibleMeetings();
    }, [calculateVisibleMeetings]);

    useEffect(() => {
        const resizeObserver = new ResizeObserver(calculateVisibleMeetings);

        if (containerRef.current) {
            resizeObserver.observe(containerRef.current);
        }

        return () => resizeObserver.disconnect();
    }, [calculateVisibleMeetings]);

    return (
        <div
            className={cn(
                "relative flex flex-col overflow-hidden border-border-primary h-full",
                !isSameMonth() && "bg-surface-secondary text-text-tertiary",
            )}
        >
            <div
                ref={containerRef}
                className="relative flex grow flex-col overflow-hidden md:after:hidden after:absolute after:z-10 after:inset-0 after:bg-transparent"
                onClick={handleContainerClick}
            >
                {meetings.map((meeting, index) => {
                    return (
                        <MeetingContext key={meeting.id} meeting={meeting} queryParams={params}>
                            <MeetingCalendarCard
                                meeting={meeting}
                                data-meeting-item
                                style={{
                                    display: index < visibleMeetingsCount ? "block" : "none",
                                }}
                            />
                        </MeetingContext>
                    );
                })}
            </div>

            <MonthElementFooter
                date={date}
                onOpenModal={() => setIsModalOpen(true)}
                onNavigate={onClick}
                hiddenMeetingsCount={hiddenMeetingsCount}
            />

            {breakpoint ? (
                <Dialog open={isModalOpen} onOpenChange={setIsModalOpen}>
                    <DialogContent className="sm:max-w-78" overflowTransparent>
                        <MonthModalContent date={date} meetings={meetings} />
                    </DialogContent>
                </Dialog>
            ) : (
                <Drawer open={isModalOpen} onOpenChange={setIsModalOpen}>
                    <DrawerContent>
                        <MonthModalContent date={date} meetings={meetings} isDrawer />
                    </DrawerContent>
                </Drawer>
            )}
        </div>
    );
}

interface MonthElementFooterProps {
    date: Date;
    onNavigate: () => void;
    onOpenModal: () => void;
    hiddenMeetingsCount: number;
}

export function MonthElementFooter({
    date,
    onNavigate,
    onOpenModal,
    hiddenMeetingsCount,
}: MonthElementFooterProps) {
    return (
        <div className="relative flex justify-between items-end p-0.5">
            <button
                data-more={!!hiddenMeetingsCount}
                onClick={onOpenModal}
                className={
                    "m-px text-xs data-[more=false]:invisible bg-fill-secondary hover:bg-fill-secondary-hover text-text-secondary hover:text-text-primary flex h-5 w-full max-w-10 cursor-pointer items-center justify-center rounded-xs p-0.5"
                }
            >
                +{hiddenMeetingsCount}
            </button>

            <button
                className={cn(
                    "m-px text-xs text-text-secondary hover:text-text-primary",
                    isToday(date) && "text-text-brand",
                    "flex size-5 shrink-0 cursor-pointer items-center justify-center rounded-xs p-0.5 hover:bg-fill-primary-hover",
                )}
                onClick={onNavigate}
            >
                {getDate(date)}
            </button>
        </div>
    );
}

interface MonthModalContentProps {
    meetings: MeetingPublic[];
    date: Date;
    isDrawer?: boolean;
}

export function MonthModalContent({ meetings, date, isDrawer }: MonthModalContentProps) {
    const HeaderComponent = isDrawer ? DrawerHeader : DialogHeader;
    const TitleComponent = isDrawer ? DrawerTitle : DialogTitle;
    const DescriptionComponent = isDrawer ? DrawerDescription : DialogDescription;

    return (
        <>
            <HeaderComponent className="border-b border-border-primary">
                <div className="flex items-center justify-between">
                    <TitleComponent className="text-sm font-medium text-text-secondary">
                        {format(date, "do EEEE", { locale: pl })}
                    </TitleComponent>
                    <DescriptionComponent className={"sr-only"}>Lista spotkań</DescriptionComponent>
                </div>
            </HeaderComponent>

            <div className="flex flex-col gap-1 p-2 overflow-y-auto max-h-[60vh]">
                {meetings.length > 0 ? (
                    meetings.map(meeting => <MeetingCard key={meeting.id} meeting={meeting} />)
                ) : (
                    <div className="py-4 text-center text-text-tertiary">
                        Brak spotkań na ten dzień
                    </div>
                )}
            </div>
        </>
    );
}
