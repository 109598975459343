"use no memo";

import { tableAddGroupT } from "@/components/features/manage/tanstack-table/TanstackTable";
import { Button } from "@/components/ui/button/Button";
import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuGroup,
    DropdownMenuItem,
    DropdownMenuTrigger,
} from "@/components/ui/input/dropdown-menu/DropdownMenu";
import { useMediaQuery } from "@/hooks/useMediaQuery";
import { cn } from "@/lib/utils";
import { ChevronDown, Plus, Trash2 } from "lucide-react";

export default function TanstackTableActions({
    addGroup,
    removeRows,
    isSelection,
}: {
    addGroup: tableAddGroupT[];
    removeRows: () => void;
    isSelection: boolean;
}) {
    const breakpoint = useMediaQuery("md");

    let content;
    if (addGroup.length === 1) {
        content = (
            <Button onClick={addGroup[0].func}>
                {addGroup[0].icon ? addGroup[0].icon : <Plus />}
                <span className={"md:block hidden"}>Dodaj</span>
            </Button>
        );
    } else {
        content = (
            <DropdownMenu>
                <DropdownMenuTrigger asChild>
                    <Button>
                        {breakpoint ? <ChevronDown /> : <Plus />}
                        <span className={"md:block hidden"}>Dodaj</span>
                    </Button>
                </DropdownMenuTrigger>
                <DropdownMenuContent align={"end"}>
                    <DropdownMenuGroup>
                        {addGroup.map(btn => {
                            return (
                                <DropdownMenuItem key={btn.id} onClick={btn.func}>
                                    {btn.icon}
                                    {btn.label}
                                </DropdownMenuItem>
                            );
                        })}
                    </DropdownMenuGroup>
                </DropdownMenuContent>
            </DropdownMenu>
        );
    }
    return (
        <div className={cn("flex shrink-0 grow justify-end gap-2")}>
            <Button
                variant={"ghost"}
                variantColor={"destructive"}
                onClick={removeRows}
                disabled={!isSelection}
            >
                <Trash2 />
                <span className={"md:block hidden"}>Usuń</span>
            </Button>
            {content}
        </div>
    );
}
