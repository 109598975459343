import { unsubscribeWebPush, subscribeWebPush } from "@/api/endpoints/notifications";
import { useLocalStorage } from "@/hooks/useLocalStorage";
import WebPushService from "@/services/webPush";
import { useEffect } from "react";

type NotificationsPermission = "default" | "denied" | "granted";

export const useManageNotifications = () => {
    const isSupported = WebPushService.isSupported();

    const initialPermission = isSupported ? WebPushService.getCurrentPermission() : "denied";

    const [permission, setPermission] = useLocalStorage<NotificationsPermission>(
        "notificationPermission",
        initialPermission,
    );

    const requestNotificationPermission = async () => {
        if (isSupported) {
            const browserPermission = await WebPushService.requestNotificationPermission();
            setPermission(browserPermission);
            return browserPermission;
        }
        return null;
    };

    const enableNotifications = async () => {
        const currentPermission = WebPushService.getCurrentPermission();
        try {
            const requestPermission = await requestNotificationPermission();
            if (requestPermission === "granted") {
                let subscription = await WebPushService.getSubscription();
                if (!subscription) {
                    subscription = await WebPushService.subscribe();
                }
                await subscribeWebPush({ subscription: subscription });
            } else {
                setPermission(currentPermission);
            }
        } catch (error) {
            console.error("Notifications Error: ", error);
            setPermission(currentPermission);
        }
    };

    const disableNotifications = async () => {
        const currentPermission = WebPushService.getCurrentPermission();
        try {
            setPermission("denied");
            const subscription = await WebPushService.getSubscription();
            const hasUnsubscribed = await WebPushService.unsubscribe();
            if (hasUnsubscribed && subscription) {
                await unsubscribeWebPush({ subscription: subscription });
            }
        } catch (error) {
            console.error("Notifications Error: ", error);
            setPermission(currentPermission);
        }
    };

    useEffect(() => {
        if (isSupported) {
            const browserPermission = WebPushService.getCurrentPermission();
            if (browserPermission !== permission) {
                setPermission(browserPermission);
            }
        }
    }, [isSupported, setPermission]);

    return {
        isGranted: permission === "granted",
        isDefault: permission === "default",
        isDenied: permission === "denied",
        areNotificationsSupported: WebPushService.isSupported(),
        enableNotifications,
        disableNotifications,
    };
};
