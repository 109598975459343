import { httpErrorHandler } from "@/api/api";
import { InfiniteScroll } from "@/components/ui/infinite-scroll/InfiniteScroll";
import {
    Identifiable,
    SelectList,
    SelectListElementSkeleton,
} from "@/components/ui/select-list/SelectList";
import { InfiniteData, UseInfiniteQueryResult } from "@tanstack/react-query";
import { forwardRef, HTMLAttributes, ReactNode, useMemo } from "react";
import {
    EmptyState,
    EmptyStateContent,
    EmptyStateDescription,
    EmptyStateTitle,
} from "@/components/ui/empty-state/EmptyState";
import { cn } from "@/lib/utils";

interface SelectDataListProps<T extends Identifiable> {
    children: ReactNode | ReactNode[] | null;
    root?: Element | Document | null;
    query: UseInfiniteQueryResult<InfiniteData<T[] | T, unknown>, Error>;
    isEmpty: boolean;
    isNoResults: boolean;
    selected: T[];
    setSelected: (data: T[]) => void;
    emptyMessage?: string;
    searchNoResultsMessage?: string;
}

type DefaultEmptyListElementProps = {
    message?: string;
};

const DefaultEmptyListElement = ({ message = "Wyszukaj" }: DefaultEmptyListElementProps) => {
    return (
        <EmptyState>
            <EmptyStateContent>
                <EmptyStateDescription>{message}</EmptyStateDescription>
            </EmptyStateContent>
        </EmptyState>
    );
};

const SelectDataList = <T extends Identifiable>({
    children,
    root,
    query,
    isEmpty,
    isNoResults,
    selected,
    setSelected,
    emptyMessage,
    searchNoResultsMessage,
}: SelectDataListProps<T>) => {
    const { hasNextPage, isLoading, isFetchingNextPage, isFetching, fetchNextPage, error, status } =
        query;

    const { title, detail } = httpErrorHandler(error);

    const next = async () => {
        await fetchNextPage();
    };

    const empty = useMemo(() => {
        const displayEmpty = isEmpty && !isFetchingNextPage && !isFetching;
        const displayNoResults = isNoResults && !isFetchingNextPage && !isFetching;

        if (displayEmpty) {
            return <DefaultEmptyListElement message={emptyMessage} />;
        } else if (displayNoResults) {
            return <DefaultEmptyListElement message={searchNoResultsMessage} />;
        }
        return null;
    }, [
        emptyMessage,
        isEmpty,
        isFetching,
        isFetchingNextPage,
        isNoResults,
        searchNoResultsMessage,
    ]);

    if (status === "error") {
        return (
            <EmptyState>
                <EmptyStateContent>
                    <EmptyStateTitle>{title}</EmptyStateTitle>
                    <EmptyStateDescription>{detail}</EmptyStateDescription>
                </EmptyStateContent>
            </EmptyState>
        );
    }

    return (
        <>
            <SelectList selected={selected} setSelected={setSelected}>
                {children}
            </SelectList>
            {empty}
            {isLoading && (
                <>
                    <SelectListElementSkeleton />
                    <SelectListElementSkeleton />
                </>
            )}

            <InfiniteScroll
                hasNextPage={hasNextPage}
                isLoading={isLoading}
                isFetching={isFetching}
                next={next}
                threshold={0.65}
                root={root}
            >
                {hasNextPage && <SelectListElementSkeleton />}
            </InfiniteScroll>
        </>
    );
};

const SelectDataListLabel = forwardRef<HTMLHeadingElement, HTMLAttributes<HTMLHeadingElement>>(
    ({ className, ...props }, ref) => {
        return (
            <h5
                ref={ref}
                className={cn(
                    "w-full text-sm space-y-1 text-text-tertiary truncate whitespace-nowrap",
                    className,
                )}
                {...props}
            />
        );
    },
);
SelectDataListLabel.displayName = "SelectDataListLabel";

export { SelectDataListLabel };
export default SelectDataList;
