class WebPushService {
    static hasPermission() {
        return Notification.permission === "granted";
    }

    static isSupported() {
        return "serviceWorker" in navigator && "PushManager" in window && "Notification" in window;
    }

    static getCurrentPermission() {
        return Notification.permission;
    }

    static async requestNotificationPermission() {
        return await Notification.requestPermission();
    }

    static async getSubscription() {
        const registration = await navigator.serviceWorker.ready;
        return await registration.pushManager.getSubscription();
    }

    static async subscribe() {
        const registration = await navigator.serviceWorker.ready;
        const subscription = await registration.pushManager.subscribe({
            userVisibleOnly: true,
            applicationServerKey: import.meta.env.VITE_VAPID_PUBLIC_KEY,
        });
        return subscription;
    }

    static async unsubscribe() {
        const subscription = await this.getSubscription();
        if (subscription) {
            return await subscription.unsubscribe();
        }
        return false;
    }
}

export default WebPushService;
