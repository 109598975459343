import { ZustandHookSelectors, createSelectorHooks } from "auto-zustand-selectors-hook";
import { create } from "zustand";
import { persist } from "zustand/middleware";

interface State {
    isNavbarOpen: boolean; // Navbar open state
    pathURL: string | null; // URL path to redirect after login
}

interface Action {
    updateNavbarState: (isOpen: State["isNavbarOpen"]) => void;
    setPathURL: (pathURL: State["pathURL"]) => void;
}

const useStateStoreBase = create<Action & State>()(
    persist(
        set => ({
            isNavbarOpen: false,
            pathURL: null,
            setPathURL: (pathURL: State["pathURL"]) => set({ pathURL }),
            updateNavbarState: (isOpen: State["isNavbarOpen"]) => set({ isNavbarOpen: isOpen }),
        }),
        {
            name: "state",
            partialize: state => ({
                pathURL: state.pathURL,
                isNavbarOpen: state.isNavbarOpen,
            }),
        },
    ),
);

const useStateStore = createSelectorHooks(useStateStoreBase) as typeof useStateStoreBase &
    ZustandHookSelectors<Action & State>;

export const { useIsNavbarOpen, useUpdateNavbarState } = useStateStore;
export { useStateStore, useStateStoreBase };
