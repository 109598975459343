import { CircleSlash } from "lucide-react";
import {
    EmptyState,
    EmptyStateCircles,
    EmptyStateContent,
    EmptyStateDescription,
    EmptyStateTitle,
} from "@/components/ui/empty-state/EmptyState";
import { FeaturedIcon } from "@/components/ui/featured-icon/FeaturedIcon";

export default function FilesPageError() {
    return (
        <EmptyState>
            <EmptyStateCircles className={"z-0"} />
            <FeaturedIcon size={"xl"} className={"z-10"}>
                <CircleSlash />
            </FeaturedIcon>
            <EmptyStateContent>
                <EmptyStateTitle>Wystąpił błąd</EmptyStateTitle>
                <EmptyStateDescription>
                    Podczas ładowania zawartości folderu wystąpił nieoczekiwany błąd.
                </EmptyStateDescription>
            </EmptyStateContent>
        </EmptyState>
    );
}
