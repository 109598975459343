import { InferType, array, number, object, string } from "yup";

const ChatEditSchema = object({
    id: number().required(),
    name: string().trim().required("Nazwa jest wymagana"),
    addUserIdArr: array().of(number().required()).optional(),
    removeUserIdArr: array().of(number().required()).optional(),
    addGroupIdArr: array().of(number().required()).optional(),
    removeGroupIdArr: array().of(number().required()).optional(),
});
type ChatEditSchemaType = InferType<typeof ChatEditSchema>;

export { ChatEditSchema };
export type { ChatEditSchemaType };
