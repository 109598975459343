import { cn } from "@/lib/utils";
import * as TabsPrimitive from "@radix-ui/react-tabs";
import * as React from "react";

const Tabs = TabsPrimitive.Root;

const TabsList = React.forwardRef<
    React.ElementRef<typeof TabsPrimitive.List>,
    React.ComponentPropsWithoutRef<typeof TabsPrimitive.List>
>(({ className, ...props }, ref) => (
    <TabsPrimitive.List
        ref={ref}
        className={cn(
            "inline-flex h-fit w-fit items-center justify-center p-1 rounded-md bg-fill-secondary",
            className,
        )}
        {...props}
    />
));
TabsList.displayName = TabsPrimitive.List.displayName;

export type tabsTriggerProps = React.ComponentPropsWithoutRef<typeof TabsPrimitive.Trigger>;
const TabsTrigger = React.forwardRef<
    React.ElementRef<typeof TabsPrimitive.Trigger>,
    tabsTriggerProps
>(({ className, ...props }, ref) => {
    return (
        <TabsPrimitive.Trigger
            ref={ref}
            className={cn(
                "relative inline-flex items-center justify-center rounded-sm overflow-hidden h-9 px-4 gap-2 text-md font-medium text-text-tertiary whitespace-nowrap transition-all ease-out duration-100 opacity-100 cursor-pointer disabled:cursor-not-allowed disabled:pointer-events-none outline-ring-focus outline-0 focus-visible:outline-2 disabled:text-text-disabled disabled:*stroke-icon-disabled [&_svg]:pointer-events-none [&_svg]:size-4 [&_svg]:shrink-0 data-[state=active]:bg-fill-primary data-[state=active]:text-text-secondary data-[state=active]:shadow-sm",
                className,
            )}
            {...props}
        />
    );
});
TabsTrigger.displayName = TabsPrimitive.Trigger.displayName;

const TabsContent = React.forwardRef<
    React.ElementRef<typeof TabsPrimitive.Content>,
    React.ComponentPropsWithoutRef<typeof TabsPrimitive.Content>
>(({ className, ...props }, ref) => (
    <TabsPrimitive.Content
        ref={ref}
        className={cn("focus-visible:outline-hidden", className)}
        {...props}
    />
));
TabsContent.displayName = TabsPrimitive.Content.displayName;

export { Tabs, TabsList, TabsTrigger, TabsContent };
