import { VariantProps, cva } from "class-variance-authority";
import React from "react";
import { cn } from "@/lib/utils";

const featuredIconVariants = cva(
    "[&_svg]:pointer-events-none shrink-0 [&_svg]:shrink-0 grid place-content-center rounded-sm",
    {
        variants: {
            size: {
                sm: "[&_svg]:size-4 w-8 h-8",
                md: "[&_svg]:size-4 w-10 h-10",
                lg: "[&_svg]:size-4 w-12 h-12",
                xl: "[&_svg]:size-5 w-16 h-16",
            },
            variant: {
                default: "border border-border-primary bg-fill-primary *stroke-icon-tertiary",
                ghost: "bg-surface-brand [&_svg]:stroke-icon-brand",
            },
        },
        compoundVariants: [],
        defaultVariants: {
            variant: "default",
            size: "md",
        },
    },
);

export interface FeaturedIconProps
    extends React.HTMLAttributes<HTMLDivElement>,
        VariantProps<typeof featuredIconVariants> {
    children: React.ReactNode;
}

const FeaturedIcon = React.forwardRef<HTMLDivElement, FeaturedIconProps>(
    ({ className, variant, size, ...props }, ref) => {
        return (
            <div
                ref={ref}
                className={cn(featuredIconVariants({ variant, size, className }))}
                {...props}
            />
        );
    },
);
FeaturedIcon.displayName = "FeaturedIcon";

export { FeaturedIcon };
