import { useGetLocationsQuery } from "@/api/queries/locationsQueries";
import CreateLocation from "@/components/features/manage/manage-locations/CreateLocation";
import DeleteLocation from "@/components/features/manage/manage-locations/DeleteLocation";
import EditLocation from "@/components/features/manage/manage-locations/EditLocation";
import { TanstackTable } from "@/components/features/manage/tanstack-table/TanstackTable";
import { Button } from "@/components/ui/button/Button";
import { Dialog } from "@/components/ui/dialog/Dialog";
import { Checkbox } from "@/components/ui/input/checkbox/Checkbox";
import { LocationPublic } from "@/types";
import { ColumnDef } from "@tanstack/react-table";
import { Edit, Plus, Trash2 } from "lucide-react";
import { useMemo, useState } from "react";

export default function ManageLocations() {
    const [openEdit, setOpenEdit] = useState<boolean>(false);
    const [openDelete, setOpenDelete] = useState<boolean>(false);
    const [openLocationAdd, setOpenLocationAdd] = useState<boolean>(false);

    const [selectedRows, setSelectedRows] = useState<LocationPublic[]>([]);
    const [selectedLocations, setSelectedLocations] = useState<LocationPublic | undefined>(
        undefined,
    );

    const { data = [], isPending } = useGetLocationsQuery();

    const getSelectedRowsFn = (rows: LocationPublic[]) => {
        setSelectedRows(rows);
        setOpenDelete(true);
    };

    const triggerPopupEdit = (location?: LocationPublic) => {
        if (location) {
            setSelectedLocations(location);
            setOpenEdit(true);
        }
    };

    const triggerPopupAddLocation = () => {
        setOpenLocationAdd(true);
    };

    const addGroup = useMemo(() => {
        return [
            {
                id: 1,
                label: "Dodaj Lokację",
                icon: <Plus className="h-4 w-4" />,
                func: () => triggerPopupAddLocation(),
            },
        ];
    }, []);

    const columns: ColumnDef<LocationPublic>[] = useMemo(
        () => [
            {
                id: "select",
                header: ({ table }) => (
                    <Checkbox
                        checked={
                            table.getIsAllPageRowsSelected()
                                ? true
                                : table.getIsSomePageRowsSelected()
                                  ? "indeterminate"
                                  : false
                        }
                        onCheckedChange={value => table.toggleAllPageRowsSelected(!!value)}
                        aria-label="Select all"
                    />
                ),
                cell: ({ row }) => (
                    <Checkbox
                        checked={row.getIsSelected()}
                        onCheckedChange={value => row.toggleSelected(!!value)}
                        aria-label={"Select row"}
                    />
                ),
                enableSorting: false,
                enableHiding: false,
            },
            {
                meta: "Miejsce",
                id: "name",
                accessorKey: "name",
                header: "Miejsce",
            },
            {
                meta: "Opis",
                id: "desc",
                accessorKey: "desc",
                header: () => {
                    return (
                        <Button size={"sm"} variant={"ghost"} className={"w-fit"}>
                            Opis
                        </Button>
                    );
                },
            },
            {
                meta: "Współrzędne",
                id: "coordinates",
                accessorKey: "coordinates",
                header: "Współrzędne",
            },
            {
                id: "actions",
                enableHiding: false,
                enableSorting: false,
                cell: ({ row }) => {
                    const rowOrigin = row.original;
                    return (
                        <div className={"flex w-full justify-end"}>
                            <Button
                                size={"sm"}
                                variant={"ghost"}
                                variantColor={"destructive"}
                                onClick={() => getSelectedRowsFn([rowOrigin])}
                            >
                                <Trash2 />
                            </Button>
                            <Button
                                size={"sm"}
                                variant={"ghost"}
                                onClick={() => triggerPopupEdit(rowOrigin)}
                            >
                                <Edit />
                            </Button>
                        </div>
                    );
                },
            },
        ],
        [],
    );

    return (
        <>
            <CreateLocation open={openLocationAdd} onOpenChange={setOpenLocationAdd} />
            <Dialog open={openEdit} onOpenChange={setOpenEdit}>
                <EditLocation
                    open={openEdit}
                    onOpenChange={setOpenEdit}
                    location={selectedLocations}
                    callback={() => {
                        setSelectedLocations(undefined);
                    }}
                />
            </Dialog>
            <DeleteLocation
                selected={selectedRows}
                open={openDelete}
                onOpenChange={setOpenDelete}
            />
            <div className={"base-direction-col"}>
                <TanstackTable
                    className={"flex flex-col gap-4 w-full viewport-height"}
                    label={"Lokacje"}
                    name={"locations"}
                    isLoading={isPending}
                    columns={columns}
                    defaultColumnVisibility={{
                        desc: false,
                    }}
                    data={data}
                    getSelectedRows={getSelectedRowsFn}
                    filterBy={[]}
                    addGroup={addGroup}
                />
            </div>
        </>
    );
}
