import { CalendarFormSchemaType } from "@/schemas/meeting.schema";
import { AnyObjectSchema, ValidationError } from "yup";

const meetingFormResolver = (validationSchema: AnyObjectSchema, method: "once" | "schedule") => {
    const selectedSchema =
        method === "once"
            ? validationSchema.pick([
                  "name",
                  "desc",
                  "date",
                  "startTime",
                  "endTime",
                  "type",
                  "meetingURL",
                  "locationId",
              ])
            : validationSchema.pick([
                  "name",
                  "desc",
                  "startDate",
                  "endDate",
                  "excludeHolidays",
                  "schedule",
              ]);

    return async (data: CalendarFormSchemaType) => {
        try {
            const values = await selectedSchema.validate(data, { abortEarly: false });
            return {
                values,
                errors: {},
            };
        } catch (error: unknown) {
            if (error instanceof ValidationError) {
                return {
                    values: {},
                    errors: error.inner.reduce(
                        (
                            allErrors: Record<string, { type: string; message: string }>,
                            currentError,
                        ) => ({
                            ...allErrors,
                            [currentError.path as string]: {
                                type: currentError.type ?? "validation",
                                message: currentError.message,
                            },
                        }),
                        {},
                    ),
                };
            }
            return {
                values: {},
                errors: {
                    _error: {
                        type: "unknown",
                        message: "An unknown error occurred.",
                    },
                },
            };
        }
    };
};

export default meetingFormResolver;
