import { MESSAGE_CONFIRMATION_TIMEOUT } from "@/components/features/chat/ChatSocketService";
import { MessagePublic, MessageSend, MessageConfirmation, FilePublic } from "@/types";
import { create } from "zustand";
import { QueryStatus } from "@tanstack/react-query";

interface State {
    chatId: number | null;
    viewedFile?: FilePublic | File;
    openViewer: boolean;
    connectionStatus: "disconnected" | "connecting" | "connected";
    isDisconnected: boolean;
    messages: MessageSend[];
    hasMore: boolean;
    loading: boolean;
    replyingTo?: MessagePublic;
    last?: number;
    newest?: number;
    messageConfirmationTimeouts: Map<string, number>;
}

interface Action {
    setActiveChat: (chatId: number) => void;
    setLoading: (loading: boolean) => void;
    setReplyingTo: (replyingTo?: MessagePublic) => void;
    setLast: (last: number) => void;
    setNewest: (newest: number) => void;
    setHasMore: (hasMore: boolean) => void;
    confirmMessage: (res: MessageConfirmation) => void;
    setIsDisconnected: (isDisconnected: boolean) => void;
    deleteMessage: (messageId: number, content: string) => void;
    appendMessages: (messages: MessageSend[]) => void;
    prependMessages: (messages: MessagePublic[]) => void;
    setConnectionStatus: (status: State["connectionStatus"]) => void;
    setMessageConfirmationTimeout: (tempUUID: string) => void;
    clearMessageConfirmationTimeout: (tempUUID: string) => void;
    updateMessageStatus: (tempUUID: string, status: QueryStatus) => void;
    setViewedFile: (file?: FilePublic | File) => void;
    resetToDefaults: () => void;
    setOpenViewer: (bool: boolean) => void;
}

const defaultState: State = {
    chatId: null,
    connectionStatus: "disconnected",
    isDisconnected: false,
    messages: [],
    last: undefined,
    newest: undefined,
    hasMore: true,
    loading: false,
    replyingTo: undefined,
    viewedFile: undefined,
    openViewer: false,
    messageConfirmationTimeouts: new Map(),
};

const useChatStore = create<State & Action>((set, get) => ({
    ...defaultState,
    setActiveChat: (chatId: number) => set({ chatId }),
    setLoading: (loading: boolean) => set({ loading }),
    setReplyingTo: (replyingTo?: MessagePublic) => set({ replyingTo }),
    setIsDisconnected: (isDisconnected: boolean) => set({ isDisconnected }),
    setLast: (last: number) => set({ last }),
    setNewest: (newest: number) => set({ newest }),
    setHasMore: (hasMore: boolean) => set({ hasMore }),
    setViewedFile: (viewedFile?: FilePublic | File) => set({ viewedFile }),
    setOpenViewer: (openViewer: boolean) => set({ openViewer }),
    setConnectionStatus: status => {
        set({ connectionStatus: status });
    },
    deleteMessage: (id, content) => {
        set(({ messages }) => {
            messages.forEach(message => {
                if (message.id === id) {
                    message.content = content;
                }
            });
            return { messages: [...messages] };
        });
    },
    appendMessages: (newMessages: MessageSend[]) =>
        set(({ messages }) => {
            const sortedNewMessages = [...newMessages].sort(
                (a, b) => new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime(),
            );
            return {
                messages: [...messages, ...sortedNewMessages],
            };
        }),
    prependMessages: (newMessages: MessagePublic[]) =>
        set(({ messages }) => {
            const sortedNewMessages = [...newMessages].sort(
                (a, b) => new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime(),
            );
            return {
                messages: [...sortedNewMessages, ...messages],
            };
        }),
    confirmMessage: ({ action, id, tempUUID, content }) => {
        set(({ messages }) => {
            const newMessages = [...messages].map(message => {
                if (action === "sent" && message.tempUUID === tempUUID && id) {
                    return { ...message, id, tempUUID: undefined, status: undefined };
                } else if (action === "deleted" && message.id === id) {
                    return { ...message, type: "deleted", content: content as string };
                } else if (action === "failed" && message.tempUUID === tempUUID) {
                    return { ...message, type: "error", status: "error" as const };
                }
                return { ...message };
            });
            return { messages: newMessages };
        });

        if (tempUUID) {
            get().clearMessageConfirmationTimeout(tempUUID);
        }
    },

    updateMessageStatus: (tempUUID, status) => {
        set(({ messages }) => {
            const newMessages = [...messages].map(message => {
                if (message.tempUUID === tempUUID) {
                    return {
                        ...message,
                        status,
                    };
                }
                return message;
            });
            return { messages: newMessages };
        });
    },

    setMessageConfirmationTimeout: (tempUUID: string) => {
        const { messageConfirmationTimeouts } = get();

        // Clear existing timeout if it exists
        if (messageConfirmationTimeouts.has(tempUUID)) {
            clearTimeout(messageConfirmationTimeouts.get(tempUUID));
        }

        // Set new timeout
        const timeoutId = window.setTimeout(() => {
            get().confirmMessage({
                action: "failed",
                tempUUID: tempUUID,
                content: "Nie udało się wysłać wiadomości",
                type: "text",
            });
            get().clearMessageConfirmationTimeout(tempUUID);
        }, MESSAGE_CONFIRMATION_TIMEOUT);

        // Store the timeout ID
        set(state => ({
            messageConfirmationTimeouts: new Map(state.messageConfirmationTimeouts).set(
                tempUUID,
                timeoutId,
            ),
        }));
    },

    clearMessageConfirmationTimeout: (tempUUID: string) => {
        const { messageConfirmationTimeouts } = get();

        if (messageConfirmationTimeouts.has(tempUUID)) {
            clearTimeout(messageConfirmationTimeouts.get(tempUUID));

            set(state => {
                const newTimeouts = new Map(state.messageConfirmationTimeouts);
                newTimeouts.delete(tempUUID);
                return { messageConfirmationTimeouts: newTimeouts };
            });
        }
    },

    resetToDefaults: () =>
        set({
            ...defaultState,
        }),
}));

export { useChatStore };
