"use no memo";

import { useTanstackTableDynamicContext } from "@/components/features/manage/tanstack-table-dynamic/TanstackTableDynamic";
import { Button } from "@/components/ui/button/Button";
import { Row } from "@tanstack/react-table";
import { Check, CircleSlash, Pencil, X } from "lucide-react";

interface DataTableEditCellT<TData> {
    row: Row<TData>;
}

export function TanstackTableDynamicEditCell<TData>({ row }: DataTableEditCellT<TData>) {
    const { id, index } = row;
    const { saveChanges, setEditedRows, removeRow, editedRows, revertData, restoreOriginalData } =
        useTanstackTableDynamicContext();

    const handleSetEditedRows = () => {
        restoreOriginalData();
        setEditedRows(curr => {
            return {
                ...curr,
                [id]: !curr[id],
            };
        });
    };

    const handleRevertData = () => {
        handleSetEditedRows();
    };

    const handleCancelRow = () => {
        handleSetEditedRows();
        revertData(index);
    };

    const handleSaveChanges = () => {
        handleSetEditedRows();
        saveChanges(index);
    };

    const handleRemoveRow = () => {
        removeRow(index);
    };

    return (
        <div className={"flex h-full justify-end gap-1"}>
            {editedRows[id] ? (
                <div className={"flex gap-1"}>
                    <Button
                        onClick={handleCancelRow}
                        type={"button"}
                        variant={"outline"}
                        name={"cancel"}
                        size={"sm"}
                    >
                        <CircleSlash />
                    </Button>
                    <Button
                        onClick={handleSaveChanges}
                        type={"button"}
                        variant={"outline"}
                        name={"done"}
                        size={"sm"}
                    >
                        <Check />
                    </Button>
                </div>
            ) : (
                <div className={"flex gap-1"}>
                    <Button
                        onClick={handleRevertData}
                        type={"button"}
                        variant={"outline"}
                        name={"edit"}
                        size={"sm"}
                    >
                        <Pencil />
                    </Button>
                    <Button
                        onClick={handleRemoveRow}
                        type={"button"}
                        variant={"outline"}
                        name={"remove"}
                        size={"sm"}
                    >
                        <X />
                    </Button>
                </div>
            )}
        </div>
    );
}
