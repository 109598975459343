import MediaAudioViewer from "@/components/features/media/MediaAudioViewer";
import MediaFileViewer from "@/components/features/media/MediaFileViewer";
import MediaImageViewer from "@/components/features/media/MediaImageViewer";
import MediaPDFViewer from "@/components/features/media/MediaPDFViewer";
import MediaVideoViewer from "@/components/features/media/MediaVideoViewer";
import { Dialog, DialogContent } from "@/components/ui/dialog/Dialog";
import { cn } from "@/lib/utils";
import type { FilePublic } from "@/types";
import { FileTypeE, getFileType } from "@/utils/getFileType";
import { Dispatch, SetStateAction } from "react";

interface MediaViewerProps {
    file?: FilePublic | File;
    open: boolean;
    onOpenChange: Dispatch<SetStateAction<boolean>> | ((bool: boolean) => void);
}

export interface MediaViewerFile {
    file: FilePublic | File;
    open: boolean;
    onOpenChange: Dispatch<SetStateAction<boolean>> | ((bool: boolean) => void);
}

export default function MediaViewer({ file, open, onOpenChange }: MediaViewerProps) {
    const { serveType, subtype } = getFileType(file);

    if (!file) {
        return null;
    }

    if (subtype === "pdf") {
        return <MediaPDFViewer file={file} open={open} onOpenChange={onOpenChange} />;
    } else if (serveType === FileTypeE.IMAGE) {
        return <MediaImageViewer file={file} open={open} onOpenChange={onOpenChange} />;
    } else if (serveType === FileTypeE.VIDEO) {
        return <MediaVideoViewer file={file} open={open} onOpenChange={onOpenChange} />;
    } else if (serveType === FileTypeE.AUDIO) {
        return <MediaAudioViewer file={file} open={open} onOpenChange={onOpenChange} />;
    } else {
        return <MediaFileViewer file={file} open={open} onOpenChange={onOpenChange} />;
    }
}
