import { MediaViewerFile } from "@/components/features/media/MediaViewer";
import { Button } from "@/components/ui/button/Button";
import { Link } from "@/components/ui/link/Link";
import getFileUrl from "@/utils/getFileUrl";
import { Download } from "lucide-react";
import { useMemo } from "react";
import {
    DialogBody,
    DialogHeader,
    DialogTitle,
    Dialog,
    DialogContent,
    DialogDescription,
} from "@/components/ui/dialog/Dialog";

export default function MediaFileViewer({ file, open, onOpenChange }: MediaViewerFile) {
    const { isID, fileURL } = useMemo(() => {
        if ("id" in file) {
            return { isID: true, fileURL: getFileUrl(file.id) };
        }
        if (file instanceof File) {
            return { isID: false, fileURL: URL.createObjectURL(file) };
        }
        return { isID: false, fileURL: "" };
    }, [file]);

    return (
        <Dialog open={open} onOpenChange={onOpenChange}>
            <DialogContent className={"h-fit"}>
                <DialogBody>
                    <DialogHeader>
                        <DialogTitle>{file.name}</DialogTitle>
                        <DialogDescription className={"sr-only"}>
                            {file.name} - podgląd
                        </DialogDescription>
                    </DialogHeader>
                    <div className="flex h-full w-full flex-col items-center justify-center gap-3">
                        <p className="text-text-tertiary">Podgląd niedostępny</p>
                        {isID ? (
                            <Link href={fileURL}>
                                <Button>
                                    <Download />
                                    Pobierz
                                </Button>
                            </Link>
                        ) : null}
                    </div>
                </DialogBody>
            </DialogContent>
        </Dialog>
    );
}
