import { cn } from "@/lib/utils";
import { Slot } from "@radix-ui/react-slot";
import { type VariantProps, cva } from "class-variance-authority";
import React from "react";

const colorVariants = {
    primary: {
        default:
            "bg-linear-to-b from-fill-brand-hover to-fill-brand-active text-text-on-brand-fill *:stroke-text-on-brand-fill border border-border-brand hover:bg-fill-brand-hover active:bg-fill-brand-active",
        destructive:
            "bg-fill-destructive text-text-on-destructive-fill *:stroke-text-destructive-on border border-border-primary-destructive hover:bg-fill-destructive-hover active:bg-fill-destructive-active",
    },
    secondary: {
        default:
            "bg-fill-gray text-text-on-gray-fill *:stroke-text-on-gray-fill border-fill-gray hover:bg-fill-gray-hover active:bg-fill-gray-active",
        destructive:
            "bg-fill-destructive text-text-on-destructive-fill *:stroke-text-destructive-on border-border-primary-destructive hover:bg-fill-destructive-hover active:bg-fill-destructive-active",
    },
    tertiary: {
        default:
            "bg-fill-secondary text-text-primary *:stroke-icon-tertiary border border-border-primary hover:bg-fill-secondary-hover active:bg-fill-secondary-active",
        destructive:
            "bg-fill-destructive-secondary text-text-destructive *stroke-icon-destructive hover:bg-fill-destructive-secondary-hover active:bg-fill-destructive-secondary-active",
    },
    outline: {
        default:
            "bg-fill-primary text-text-primary *:stroke-icon-tertiary border border-border-primary hover:bg-fill-primary-hover active:bg-fill-primary-active",
        destructive:
            "bg-fill-primary text-text-destructive *:stroke-icon-destructive border border-border-primary-destructive hover:bg-fill-destructive-secondary-hover active:bg-fill-destructive-secondary-active",
    },
    ghost: {
        default:
            "bg-transparent text-text-primary *:stroke-icon-tertiary hover:bg-fill-primary-hover active:bg-fill-primary-active",
        destructive:
            "bg-transparent text-text-destructive *:stroke-icon-destructive hover:bg-fill-destructive-secondary-hover active:bg-fill-destructive-secondary-active",
    },
    linkPrimary: {
        default:
            "bg-transparent text-text-primary *:stroke-icon-tertiary hover:text-text-primary-hover active:text-text-primary-hover",
        destructive:
            "bg-transparent text-text-destructive *:stroke-icon-destructive hover:text-text-destructive-hover active:text-text-destructive-hover",
    },
    linkSecondary: {
        default:
            "bg-transparent text-text-brand *:stroke-icon-brand hover:text-text-brand-hover active:text-text-brand-hover",
        destructive:
            "bg-transparent text-text-destructive *:stroke-icon-destructive hover:text-text-destructive-hover active:text-text-destructive-hover",
    },
};

const buttonVariants = cva(
    "group relative inline-flex whitespace-nowrap overflow-hidden items-center justify-center rounded-md text-md font-medium ease-out duration-100 transition-colors transition-outline opacity-100 cursor-pointer disabled:cursor-not-allowed disabled:pointer-events-none focus-visible:z-10 outline-ring-focus outline-0 focus-visible:outline-2 disabled:opacity-75 [&_svg]:pointer-events-none [&_svg]:size-4 [&_svg]:shrink-0",
    {
        variants: {
            size: {
                sm: "h-9 min-h-9 min-w-9 gap-2 px-2 text-sm rounded-sm",
                md: "h-10 min-h-10 min-w-10 gap-2 px-3 text-base rounded-sm",
                lg: "h-11 min-h-11 min-w-11 gap-3 px-3 text-base rounded-md",
            },
            variant: {
                primary: "",
                secondary: "",
                tertiary: "",
                outline: "",
                ghost: "",
                linkPrimary: "",
                linkSecondary: "",
            },
            variantColor: {
                default: "",
                destructive: "",
            },
        },
        compoundVariants: [
            {
                variant: "primary",
                variantColor: "default",
                className: colorVariants.primary.default,
            },
            {
                variant: "secondary",
                variantColor: "default",
                className: colorVariants.secondary.default,
            },
            {
                variant: "tertiary",
                variantColor: "default",
                className: colorVariants.tertiary.default,
            },
            {
                variant: "outline",
                variantColor: "default",
                className: colorVariants.outline.default,
            },
            {
                variant: "ghost",
                variantColor: "default",
                className: colorVariants.ghost.default,
            },
            {
                variant: "linkPrimary",
                variantColor: "default",
                className: colorVariants.linkPrimary.default,
            },
            {
                variant: "linkSecondary",
                variantColor: "default",
                className: colorVariants.linkSecondary.default,
            },
            {
                variant: "primary",
                variantColor: "destructive",
                className: colorVariants.primary.destructive,
            },
            {
                variant: "secondary",
                variantColor: "destructive",
                className: colorVariants.secondary.destructive,
            },
            {
                variant: "tertiary",
                variantColor: "destructive",
                className: colorVariants.tertiary.destructive,
            },
            {
                variant: "outline",
                variantColor: "destructive",
                className: colorVariants.outline.destructive,
            },
            {
                variant: "ghost",
                variantColor: "destructive",
                className: colorVariants.ghost.destructive,
            },
            {
                variant: "linkPrimary",
                variantColor: "destructive",
                className: colorVariants.linkPrimary.destructive,
            },
            {
                variant: "linkSecondary",
                variantColor: "destructive",
                className: colorVariants.linkSecondary.destructive,
            },
            {
                variant: "linkPrimary",
                className: "hover:underline px-0 focus:underline",
            },
            {
                variant: "linkSecondary",
                className: "hover:underline px-0 focus:underline",
            },
            {
                variant: "linkPrimary",
                size: "lg",
                className: "min-h-9 h-9",
            },
            {
                variant: "linkSecondary",
                size: "lg",
                className: "min-h-9 h-9",
            },
            {
                variant: "linkPrimary",
                size: "md",
                className: "min-h-8 h-8",
            },
            {
                variant: "linkSecondary",
                size: "md",
                className: "min-h-8 h-8",
            },
            {
                variant: "linkPrimary",
                size: "sm",
                className: "min-h-7 h-7",
            },
            {
                variant: "linkSecondary",
                size: "sm",
                className: "min-h-7 h-7",
            },
        ],
        defaultVariants: {
            variant: "primary",
            variantColor: "default",
            size: "md",
        },
    },
);

export interface ButtonProps
    extends React.ButtonHTMLAttributes<HTMLButtonElement>,
        VariantProps<typeof buttonVariants> {
    asChild?: boolean;
}
const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
    ({ className, variant, variantColor, size, asChild = false, ...props }, ref) => {
        const Comp = asChild ? Slot : "button";
        return (
            <Comp
                ref={ref}
                className={cn(buttonVariants({ variant, variantColor, size, className }))}
                {...props}
            />
        );
    },
);
Button.displayName = "Button";
export { Button, buttonVariants, colorVariants };
