import { Button } from "@/components/ui/button/Button";
import { FileCard, FileCardContent, FileCardTitle } from "@/components/ui/file-card/FileCard";
import { DropdownMenuTrigger } from "@/components/ui/input/dropdown-menu/DropdownMenu";
import { Skeleton } from "@/components/ui/skeleton/Skeleton";
import { cn } from "@/lib/utils";
import type { DirectoryPublic } from "@/types";
import { MoreVertical } from "lucide-react";
import { HTMLAttributes, forwardRef, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { FolderIcon } from "@/components/ui/file-icon/FileIcon";

type FsDirectoryCardProps = HTMLAttributes<HTMLDivElement> & {
    directory: DirectoryPublic;
    disabledMenu?: boolean;
};

const FsDirectoryCard = forwardRef<HTMLDivElement, FsDirectoryCardProps>(
    ({ directory, className, disabledMenu = false, onDoubleClick, ...props }, ref) => {
        const navigate = useNavigate();

        const dirNavigate = useCallback(() => {
            navigate(`/fs/dir/${directory.id}`);
        }, [directory, navigate]);

        return (
            <FileCard
                ref={ref}
                key={directory.id}
                onDoubleClick={onDoubleClick || dirNavigate}
                className={cn(className, "cursor-pointer")}
                {...props}
            >
                <FileCardContent>
                    <FolderIcon />
                    <FileCardTitle>{directory.name}</FileCardTitle>
                    <DropdownMenuTrigger disabled={disabledMenu} asChild>
                        <Button variant={"ghost"} size={"sm"}>
                            <MoreVertical />
                        </Button>
                    </DropdownMenuTrigger>
                </FileCardContent>
            </FileCard>
        );
    },
);

FsDirectoryCard.displayName = "FsDirectoryCard";

function DirectoryCardSkeleton() {
    return (
        <div
            className={
                "inline-flex w-full items-center gap-2 rounded-md border border-border-primary bg-surface-primary p-2"
            }
        >
            <Skeleton className={"size-10 shrink-0 rounded-sm"} />
            <div className={"w-full"}>
                <Skeleton className={"h-3 w-14 rounded-md"} />
            </div>
        </div>
    );
}

DirectoryCardSkeleton.displayName = "DirectoryCardSkeleton";

export default FsDirectoryCard;
export { DirectoryCardSkeleton };
