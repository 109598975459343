import { cn } from "@/lib/utils";
import * as DialogPrimitive from "@radix-ui/react-dialog";
import { X } from "lucide-react";
import * as React from "react";

const Dialog = DialogPrimitive.Root;

const DialogTrigger = DialogPrimitive.Trigger;

const DialogPortal = DialogPrimitive.Portal;

const DialogClose = DialogPrimitive.Close;

const DialogOverlay = React.forwardRef<
    React.ElementRef<typeof DialogPrimitive.Overlay>,
    React.ComponentPropsWithoutRef<typeof DialogPrimitive.Overlay>
>(({ className, ...props }, ref) => (
    <DialogPrimitive.Overlay
        ref={ref}
        className={cn(
            "dark fixed inset-0 z-50 bg-surface-base/75 data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0",
            className,
        )}
        {...props}
    />
));
DialogOverlay.displayName = DialogPrimitive.Overlay.displayName;

const DialogContent = React.forwardRef<
    React.ElementRef<typeof DialogPrimitive.Content>,
    React.ComponentPropsWithoutRef<typeof DialogPrimitive.Content> & {
        overflowTransparent?: boolean;
        isClosable?: boolean;
    }
>(({ className, children, isClosable = true, overflowTransparent = false, ...props }, ref) => (
    <DialogPortal>
        <DialogOverlay
            className={cn(overflowTransparent && "bg-transparent")}
            onContextMenu={e => {
                e.preventDefault();
            }}
        />
        <DialogPrimitive.Content
            ref={ref}
            className={cn(
                "fixed sm:bottom-[50%] sm:left-[50%] w-[clamp(14rem,44rem,100%)] sm:translate-x-[-50%] sm:translate-y-[50%] bottom-[0%] min-h-[75dvh] sm:min-h-20 translate-y-[0%] grow rounded-lg rounded-b-none sm:rounded-lg duration-200 z-50 max-w-5xl flex flex-col max-h-[calc(100dvh_-_theme(spacing.11))] overflow-y-hidden border border-border-primary bg-surface-primary p-0 shadow-lg outline-hidden data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:animate-zoom-out-95 data-[state=open]:animate-zoom-in-95 data-[state=open]:animate-slide-in-from-bottom overflow-x-hidden",
                className,
            )}
            {...props}
        >
            {isClosable && (
                <DialogPrimitive.Close
                    className={
                        "absolute right-1 top-1 w-9 h-9 grid place-items-center rounded-sm opacity-70 text-icon-tertiary transition-opacity hover:opacity-100 focus:outline-none cursor-pointer disabled:cursor-not-allowed disabled:pointer-events-none outline-ring-focus outline-0 focus-visible:outline-2 disabled:opacity-75"
                    }
                >
                    <X className={"pointer-events-none size-4 shrink-0"} />
                    <span className="sr-only">Close</span>
                </DialogPrimitive.Close>
            )}
            {children}
        </DialogPrimitive.Content>
    </DialogPortal>
));
DialogContent.displayName = DialogPrimitive.Content.displayName;

const DialogHeader = ({ className, ...props }: React.HTMLAttributes<HTMLDivElement>) => (
    <div
        className={cn("flex flex-row items-center gap-2 p-3 md:p-4 text-left", className)}
        {...props}
    />
);
DialogHeader.displayName = "DialogHeader";

const DialogBody = ({ className, ...props }: React.HTMLAttributes<HTMLDivElement>) => (
    <div className={cn("relative space-y-0 text-left contents", className)} {...props} />
);
DialogBody.displayName = "DialogBody";

const DialogTitle = React.forwardRef<
    React.ElementRef<typeof DialogPrimitive.Title>,
    React.ComponentPropsWithoutRef<typeof DialogPrimitive.Title>
>(({ className, ...props }, ref) => (
    <DialogPrimitive.Title
        ref={ref}
        className={cn(
            "mr-9 whitespace-nowrap w-full truncate text-lg font-semibold leading-none tracking-tight",
            className,
        )}
        {...props}
    />
));
DialogTitle.displayName = DialogPrimitive.Title.displayName;

const DialogDescription = React.forwardRef<
    React.ElementRef<typeof DialogPrimitive.Description>,
    React.ComponentPropsWithoutRef<typeof DialogPrimitive.Description>
>(({ className, ...props }, ref) => (
    <DialogPrimitive.Description
        ref={ref}
        className={cn("text-text-tertiary text-sm", className)}
        {...props}
    />
));
DialogDescription.displayName = DialogPrimitive.Description.displayName;

const DialogFooter = ({ className, ...props }: React.HTMLAttributes<HTMLDivElement>) => (
    <div className={cn("flex justify-end space-x-3 p-3 md:p-4", className)} {...props} />
);
DialogFooter.displayName = "DialogFooter";

export {
    Dialog,
    DialogTrigger,
    DialogContent,
    DialogHeader,
    DialogFooter,
    DialogTitle,
    DialogBody,
    DialogDescription,
    DialogClose,
};
