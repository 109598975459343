import { httpErrorHandler } from "@/api/api";
import { useGetGroupUsersQuery } from "@/api/queries/groupsQueries";
import GroupElementHeader from "@/components/features/groups/layout/GroupElementHeader";
import UserCard from "@/components/features/user/UserCard";
import { Avatar, AvatarFallback, AvatarImage } from "@/components/ui/avatar/Avatar";
import { Button } from "@/components/ui/button/Button";
import {
    Dialog,
    DialogBody,
    DialogContent,
    DialogFooter,
    DialogHeader,
    DialogTitle,
} from "@/components/ui/dialog/Dialog";
import {
    HoverCard,
    HoverCardContent,
    HoverCardTrigger,
} from "@/components/ui/hover-card/HoverCard";
import { Skeleton } from "@/components/ui/skeleton/Skeleton";
import { useLvl } from "@/hooks/useLvl";
import { useMediaQuery } from "@/hooks/useMediaQuery";
import { cn } from "@/lib/utils";
import type { UserPublic } from "@/types";
import { getAvatar } from "@/utils/getAvatar";
import { User } from "lucide-react";
import { memo, useCallback, useEffect, useState } from "react";
import { toast } from "sonner";

interface GroupMembersProps {
    groupId?: number;
    className?: string;
}

export default function GroupMembers(props: GroupMembersProps) {
    const { className, groupId } = props;
    const [open, onOpenChange] = useState<boolean>(false);
    const {
        data: groupUsers = [],
        isLoading,
        isSuccess,
        isError,
        error,
        refetch,
    } = useGetGroupUsersQuery(groupId);

    useEffect(() => {
        if (isError) {
            const { title, detail } = httpErrorHandler(error);
            toast.error(title, {
                description: detail,
                action: (
                    <Button
                        size={"sm"}
                        className={"ml-auto"}
                        variant={"tertiary"}
                        onClick={() => {
                            refetch();
                        }}
                    >
                        Ponów
                    </Button>
                ),
            });
        }
    }, [error, isError]);

    const handleOpenDialog = useCallback(() => onOpenChange(true), []);
    const handleCloseDialog = useCallback(() => onOpenChange(false), []);

    return (
        <>
            <Dialog open={open} onOpenChange={onOpenChange}>
                <DialogContent className={"md:max-w-96"}>
                    <DialogBody>
                        <DialogHeader>
                            <DialogTitle>Członkowie grupy</DialogTitle>
                        </DialogHeader>
                        <div className={"flex flex-col gap-3 p-3 md:p-4 overflow-auto grow"}>
                            {groupUsers.map(user => (
                                <UserCard key={user.id} user={user} />
                            ))}
                        </div>
                    </DialogBody>
                    <DialogFooter>
                        <Button onClick={handleCloseDialog} variant={"outline"}>
                            Zamknij
                        </Button>
                    </DialogFooter>
                </DialogContent>
            </Dialog>
            <div
                className={cn(
                    "flex flex-col rounded-lg border-1 border-border-primary bg-surface-primary",
                    className,
                )}
            >
                <GroupElementHeader label={"Członkowie grupy"} callback={handleOpenDialog} />
                <div
                    className={
                        "flex grow flex-wrap gap-2 overflow-y-auto overscroll-contain px-4 pb-4"
                    }
                >
                    {isSuccess && groupUsers.length === 0 && (
                        <p className={"w-full py-4 text-center text-sm text-text-tertiary"}>
                            Brak członków grupy
                        </p>
                    )}
                    {isLoading && (
                        <>
                            <GroupMemberCardSkeleton />
                            <GroupMemberCardSkeleton />
                            <GroupMemberCardSkeleton />
                            <GroupMemberCardSkeleton />
                            <GroupMemberCardSkeleton />
                            <GroupMemberCardSkeleton />
                            <GroupMemberCardSkeleton />
                            <GroupMemberCardSkeleton />
                            <GroupMemberCardSkeleton />
                            <GroupMemberCardSkeleton />
                            <GroupMemberCardSkeleton />
                            <GroupMemberCardSkeleton />
                        </>
                    )}

                    {groupUsers.map(user => (
                        <GroupMemberCard key={user.id} user={user} />
                    ))}
                </div>
            </div>
        </>
    );
}

interface GroupMemberCardProps {
    user: UserPublic;
}

function GroupMemberCard(props: GroupMemberCardProps) {
    const { user } = props;
    const breakpoint = useMediaQuery("sm");
    const role = useLvl(user.lvl);
    const avatar = getAvatar(user.avatarURL);
    if (breakpoint) {
        return (
            <HoverCard openDelay={500} closeDelay={200}>
                <HoverCardTrigger asChild>
                    <Avatar size={"md"} className={"cursor-pointer"}>
                        <AvatarImage src={avatar} />
                        <AvatarFallback>
                            <User className={"size-4"} />
                        </AvatarFallback>
                    </Avatar>
                </HoverCardTrigger>
                <HoverCardContent className={"flex gap-2 p-2 shadow-md"} sideOffset={8}>
                    <Avatar size={"sm"}>
                        <AvatarImage src={avatar} />
                        <AvatarFallback>
                            <User className={"size-4"} />
                        </AvatarFallback>
                    </Avatar>
                    <div className={"flex flex-col justify-center"}>
                        <p className={"font-medium"}>
                            {user.name} {user.surname}
                        </p>
                        <p className="text-xs text-fg-muted">{role}</p>
                    </div>
                </HoverCardContent>
            </HoverCard>
        );
    }

    return (
        <Avatar size={"md"} className={"cursor-pointer"}>
            <AvatarImage src={avatar} />
            <AvatarFallback>
                <User className={"size-4"} />
            </AvatarFallback>
        </Avatar>
    );
}

const GroupMemberCardSkeleton = memo(() => {
    return <Skeleton className={"h-11 w-11 rounded-md"} />;
});
GroupMemberCardSkeleton.displayName = "GroupMemberCardSkeleton";
