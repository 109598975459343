import { Button } from "@/components/ui/button/Button";
import Image from "@/components/ui/image/Image";
import AudioPlayer from "@/components/ui/media-viewer/AudioPlayer";
import VideoPlayer from "@/components/ui/media-viewer/VideoPlayer";
import { cn } from "@/lib/utils";
import type { FilePublic } from "@/types";
import { FileTypeE, getServeFileType } from "@/utils/getFileType";
import getFileUrl from "@/utils/getFileUrl";
import { AudioMimeType, VideoMimeType } from "@vidstack/react";
import { FileUp, X } from "lucide-react";
import prettyBytes from "pretty-bytes";
import { forwardRef, useMemo } from "react";

interface FileUploadCardProps {
    className?: string;
    file: File | FilePublic;
    onRemove: () => void;
}

const FileInputCard = forwardRef<HTMLDivElement, FileUploadCardProps>(
    ({ className, file, onRemove, ...props }, ref) => {
        const remove = (e: React.MouseEvent<HTMLButtonElement>) => {
            onRemove();
            e.preventDefault();
        };

        return (
            <div
                ref={ref}
                className={cn(
                    "relative flex h-54 gap-2 overflow-hidden rounded-md border border-border-primary",
                    className,
                )}
                {...props}
            >
                <FileInputCardContent file={file} />
                <Button
                    size={"sm"}
                    type={"button"}
                    variant={"primary"}
                    variantColor={"destructive"}
                    className={"absolute right-1 top-1 z-20 h-6 min-h-6 w-6 min-w-6 rounded-full"}
                    onClick={remove}
                >
                    <X />
                </Button>
            </div>
        );
    },
);

FileInputCard.displayName = "FileInputCard";

interface FileUploadCardContentProps {
    className?: string;
    file: File | FilePublic;
}

const FileInputCardContent = ({ file, className, ...props }: FileUploadCardContentProps) => {
    const isFileInstance = file instanceof File;

    const { fileType, mimeType } = useMemo(() => {
        const type = isFileInstance ? file.type : file.fileType;
        return {
            fileType: getServeFileType(type, {
                pick: [FileTypeE.IMAGE, FileTypeE.VIDEO, FileTypeE.AUDIO],
            }),
            mimeType: type,
        };
    }, [file, isFileInstance]);

    const objectURL = useMemo(() => {
        if (isFileInstance) {
            return URL.createObjectURL(file);
        }
        return "";
    }, []);

    const fileURL = useMemo(() => {
        if (isFileInstance) {
            return objectURL;
        } else if (file.id) {
            return getFileUrl(file.id);
        }
        return "";
    }, [isFileInstance, file, objectURL]);

    switch (fileType) {
        case FileTypeE.IMAGE:
            return (
                <Image
                    src={fileURL}
                    alt={file.name}
                    thumbnail={!isFileInstance ? file.thumbnail : undefined}
                    type={mimeType}
                    className={"object-cover h-full w-full"}
                    layoutClassName={"w-full h-full justify-center"}
                />
            );
        case FileTypeE.VIDEO:
            return (
                <VideoPlayer
                    src={{
                        src: fileURL,
                        type: mimeType as VideoMimeType,
                    }}
                    title={"Video"}
                    className={"aspect-square z-10 h-full w-full object-cover"}
                    variant={"minimal"}
                    autoPlay={true}
                    muted={true}
                />
            );
        case FileTypeE.AUDIO:
            return (
                <AudioPlayer
                    src={{
                        src: fileURL,
                        type: mimeType as AudioMimeType,
                    }}
                    title={"Audio"}
                    variant={"minimal"}
                    className={"z-10 h-full w-full"}
                />
            );
        default:
            return (
                <div
                    className={cn(
                        "flex h-full w-full flex-col items-center justify-center",
                        className,
                    )}
                    {...props}
                >
                    <FileUp className={"h-5 w-5 text-icon-tertiary"} />
                    <p className="truncate w-full text-xs font-medium text-center text-text-tertiary mt-3 px-1">
                        {file.name}
                    </p>
                    <span className="text-center w-full text-xs font-medium text-text-tertiary mt-1 px-1">
                        {isFileInstance && prettyBytes(file.size)}
                    </span>
                </div>
            );
    }
};

export default FileInputCard;
