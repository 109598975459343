import { useMemo } from "react";

export default function CalendarContentHours() {
    const content = useMemo(() => Array.from({ length: 23 }, (_, index) => index + 1), []);

    return (
        <div className={"flex w-12 min-w-12 md:w-14 md:min-w-14 flex-col"}>
            <div className={"relative h-full"} />
            {content.map(hour => {
                return (
                    <div className={"relative h-full me-2"} key={hour}>
                        <p
                            aria-label={`hour-${hour}`}
                            className={"absolute -top-2 text-xs text-text-tertiary w-full text-end"}
                        >
                            {hour}:00
                        </p>
                    </div>
                );
            })}
        </div>
    );
}
