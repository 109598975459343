import FilePageContextMenu from "@/components/features/files/FilePageContextMenu";
import FilesPageError from "@/components/features/files/layouts/files-page/FilePageError";
import FilesContentGrid from "@/components/features/files/layouts/files-page/FilesPageContentGrid";
import FilesPageEmpty from "@/components/features/files/layouts/files-page/FilesPageEmpty";
import FilesPageNoResults from "@/components/features/files/layouts/files-page/FilesPageNoResults";
import { Row } from "@tanstack/react-table";
import { useMemo } from "react";
import { useMediaQuery } from "@/hooks/useMediaQuery";
import { FileUploadDropzone } from "@/components/ui/file-upload/FileUpload";
import { DirectoryPublic, FilePublicWithPermissions } from "@/types";

interface FilesStorageContentProps {
    files?: Row<FilePublicWithPermissions>[];
    directories?: Row<DirectoryPublic>[];
    isSuccess: boolean;
    isLoading: boolean;
    isError: boolean;
    isEmpty: boolean;
}

export default function FilesPageContent({
    files = [],
    directories = [],
    isEmpty,
    isLoading,
    isError,
    isSuccess,
}: FilesStorageContentProps) {
    const breakpoint = useMediaQuery("md");

    // Memoize transformed arrays to avoid recalculation on each render
    const filesArray = useMemo(() => files.map(file => file.original), [files]);
    const directoriesArray = useMemo(
        () => directories.map(directory => directory.original),
        [directories],
    );

    // Determine if context menu should be disabled
    const isContextMenuDisabled = isError || isLoading || !breakpoint;

    const content = useMemo(() => {
        if (isError) {
            return <FilesPageError />;
        }

        if (isSuccess && isEmpty) {
            return (
                <FilePageContextMenu disabled={isContextMenuDisabled}>
                    <FilesPageEmpty />
                </FilePageContextMenu>
            );
        }

        if (isSuccess && filesArray.length === 0 && directoriesArray.length === 0) {
            return (
                <FilePageContextMenu disabled={isContextMenuDisabled}>
                    <FilesPageNoResults />
                </FilePageContextMenu>
            );
        }

        return (
            <FilePageContextMenu disabled={isContextMenuDisabled}>
                <FilesContentGrid
                    files={filesArray}
                    directories={directoriesArray}
                    isSuccess={isSuccess}
                    isLoading={isLoading}
                />
            </FilePageContextMenu>
        );
    }, [
        filesArray,
        directoriesArray,
        isError,
        isSuccess,
        isLoading,
        isEmpty,
        isContextMenuDisabled,
    ]);

    return <FileUploadDropzone className={"h-full rounded-md"}>{content}</FileUploadDropzone>;
}
