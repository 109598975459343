import { cn } from "@/lib/utils";
import {
    createContext,
    forwardRef,
    HTMLAttributes,
    PropsWithChildren,
    useContext,
    useEffect,
    useState,
} from "react";

const FileCard = forwardRef<HTMLDivElement, HTMLAttributes<HTMLDivElement>>(
    ({ className, ...props }, ref) => {
        return (
            <div
                ref={ref}
                className={cn(
                    "flex flex-col gap-2 p-2 bg-surface-primary hover:bg-surface-primary-hover border border-border-primary rounded-md ease-out duration-100 transition-colors transition-outline opacity-100  disabled:cursor-not-allowed disabled:pointer-events-none outline-ring-focus outline-0 focus-visible:outline-2 disabled:opacity-75 [&_svg]:pointer-events-none",
                    className,
                )}
                {...props}
            />
        );
    },
);

FileCard.displayName = "FileCard";

const FileCardTitle = forwardRef<HTMLHeadingElement, HTMLAttributes<HTMLHeadingElement>>(
    ({ className, ...props }, ref) => {
        return (
            <h5
                ref={ref}
                className={cn(
                    "text-text-primary text-sm whitespace-nowrap truncate w-full",
                    className,
                )}
                {...props}
            />
        );
    },
);
FileCardTitle.displayName = "FileCardTitle";

const FileCardContent = forwardRef<HTMLDivElement, HTMLAttributes<HTMLDivElement>>(
    ({ className, ...props }, ref) => {
        return <div ref={ref} className={cn("flex items-center gap-2", className)} {...props} />;
    },
);
FileCardContent.displayName = "FileCardContent";

interface FileCardPreviewContextProps {
    hasError: boolean;
    triggerError: () => void;
}
const FileCardPreviewContext = createContext<FileCardPreviewContextProps>({
    hasError: false,
    triggerError: () => {},
});

const FileCardPreview = forwardRef<
    HTMLDivElement,
    PropsWithChildren<HTMLAttributes<HTMLDivElement>>
>(({ className, children, ...props }, ref) => {
    const [hasError, setHasError] = useState<boolean>(false);
    const triggerError = () => setHasError(true);

    const contextValue: FileCardPreviewContextProps = { hasError, triggerError };

    return (
        <div
            ref={ref}
            className={cn(
                "relative overflow-hidden border border-border-primary rounded-sm h-24 w-full",
                className,
            )}
            {...props}
        >
            <FileCardPreviewContext.Provider value={contextValue}>
                {children}
            </FileCardPreviewContext.Provider>
        </div>
    );
});
FileCardPreview.displayName = "FileCardPreview";

interface FileCardPreviewImageProps extends HTMLAttributes<HTMLImageElement> {
    src?: string | null;
}

const FileCardPreviewImage = ({ src, className, ...props }: FileCardPreviewImageProps) => {
    const { hasError, triggerError } = useContext(FileCardPreviewContext);

    useEffect(() => {
        if (!src) {
            triggerError();
        }
    }, [src, triggerError]);

    if (hasError || !src) {
        return null;
    }

    return (
        <img
            src={src}
            aria-label={"Preview"}
            alt={"Preview"}
            onError={triggerError}
            className={cn("object-cover w-full h-full", className)}
            {...props}
        />
    );
};

const FileCardPreviewFallback = ({ className, ...props }: HTMLAttributes<HTMLSpanElement>) => {
    const { hasError } = useContext(FileCardPreviewContext);
    if (!hasError) return null;
    return (
        <span
            aria-label="Fallback preview"
            className={cn(
                "object-cover flex h-full w-full items-center justify-center text-text-tertiary *:stroke-icon-tertiary",
                className,
            )}
            {...props}
        />
    );
};

export {
    FileCard,
    FileCardTitle,
    FileCardContent,
    FileCardPreview,
    FileCardPreviewImage,
    FileCardPreviewFallback,
};
