import { axiosInstance } from "@/api/api";
import {
    LoginSchemaType,
    PasswordChangeSchemaType,
    PasswordResetSchemaType,
    SignupSchemaType,
} from "@/schemas/auth.schema";
import { UserPrivate } from "@/types";

export interface PostLoginResponse {
    redirectURL: string;
    user: UserPrivate;
}

export const postLogin = async (data: LoginSchemaType): Promise<PostLoginResponse> => {
    return await axiosInstance.post("/auth/login", data);
};

export const postLogout = async (): Promise<string> => {
    return await axiosInstance.post("/auth/logout");
};

export const getSignup = async (token?: string): Promise<UserPrivate> => {
    return await axiosInstance.get("/auth/signup", {
        params: { t: token },
    });
};

export const postSignup = async (data: SignupSchemaType): Promise<string> => {
    return await axiosInstance.post("/auth/signup", data);
};
export const postPasswordReset = async (data: PasswordResetSchemaType): Promise<string> => {
    return await axiosInstance.post("/auth/resetPassword", data);
};

export const postPasswordChange = async (data: PasswordChangeSchemaType): Promise<string> => {
    return await axiosInstance.post("/auth/passwordChange", data);
};
