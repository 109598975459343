import {
    EditMeetingProps,
    GetMeetingsProps,
    PostMeetingProps,
    PostMeetingScheduleProps,
    deleteMeeting,
    editMeeting,
    getAssignedMeetings,
    getMeeting,
    postMeeting,
    postMeetingSchedule,
    editAttendance,
    type EditAttendanceProps,
    type GetMeetingPrefetchProps,
} from "@/api/endpoints/meetings";
import { queryClient } from "@/api/query-client";
import { MeetingPublic, MeetingPrivate } from "@/types";
import { useInfiniteQuery, useMutation, useQuery } from "@tanstack/react-query";

export const useGetAssignedMeetingsQuery = (params: GetMeetingsProps) => {
    return useQuery({
        queryKey: ["meetings", params],
        queryFn: () => getAssignedMeetings(params),
    });
};

export const useGetAssignedMeetingsInfiniteQuery = (queryParams: GetMeetingsProps) => {
    return useInfiniteQuery({
        queryKey: ["meetingsInfinite", queryParams],
        queryFn: ({ pageParam }) => {
            return getAssignedMeetings(pageParam);
        },
        initialPageParam: {
            page: 0,
            pageSize: 20,
            name: undefined,
            sortOrder: "ASC" as const,
            ...queryParams,
        },
        getNextPageParam: (lastPage, allPages, lastPageParam) => {
            if (lastPage.length < lastPageParam.pageSize) {
                return undefined;
            }
            return { ...lastPageParam, page: lastPageParam.page + 1 };
        },
    });
};

export const useGetMeetingQuery = (id: number, enabled?: boolean) => {
    return useQuery({
        queryKey: ["meetings", id],
        queryFn: () => getMeeting(id),
        enabled: enabled === undefined ? true : enabled,
    });
};

export const getMeetingPrefetch = ({ id, initialData }: GetMeetingPrefetchProps) => {
    void queryClient.prefetchQuery({
        queryKey: ["meetings", id],
        queryFn: () => getMeeting(id),
        initialData: initialData,
        staleTime: 60000,
    });
};

export const usePostMeetingMutation = () => {
    return useMutation({
        mutationFn: (data: PostMeetingProps) => postMeeting(data),
        onSettled: () => {
            void queryClient.invalidateQueries({ queryKey: ["meetings"] });
            void queryClient.invalidateQueries({ queryKey: ["meetingsInfinite"] });
        },
        mutationKey: ["addMeeting"],
    });
};

export const usePostScheduleMeetingMutation = () => {
    return useMutation({
        mutationFn: (data: PostMeetingScheduleProps) => postMeetingSchedule(data),
        onSettled: () => queryClient.invalidateQueries({ queryKey: ["meetings"] }),
        mutationKey: ["addMeeting"],
    });
};

export const useEditMeetingMutation = (queryParams?: { before: string; after: string }) => {
    return useMutation({
        mutationFn: (data: EditMeetingProps) => editMeeting(data),
        onSettled: data => {
            queryClient.invalidateQueries({ queryKey: ["meetings", data?.id] });
            queryClient.invalidateQueries({ queryKey: ["meetings", queryParams] });
        },
    });
};

export const useDeleteMeetingMutation = (queryParams: { before: string; after: string }) => {
    return useMutation({
        mutationFn: (id: number) => deleteMeeting(id),
        onMutate: async (id: number) => {
            await queryClient.cancelQueries({ queryKey: ["meetings"] });
            const previousData = queryClient.getQueryData<MeetingPublic[]>([
                "meetings",
                queryParams,
            ]);

            queryClient.setQueryData(["meetings", queryParams], (prev: MeetingPublic[]) =>
                prev.filter((m: MeetingPublic) => m.id !== id),
            );
            return { previousData };
        },
        onError: (err, id, context) => {
            if (context) {
                queryClient.setQueryData(["meetings", queryParams], context.previousData);
            }
        },
        onSettled: () => {
            void queryClient.invalidateQueries({ queryKey: ["meetings"] });
        },
    });
};

export const useEditAttendanceMutation = () => {
    return useMutation({
        mutationFn: (data: EditAttendanceProps) => editAttendance(data),
        onSettled: (data, error, payload) => {
            void queryClient.invalidateQueries({ queryKey: ["meetings", payload.id] });
        },
    });
};
