import { httpErrorHandler } from "@/api/api";
import { useGetSignupQuery, usePostSignupMutation } from "@/api/queries/authQueries";
import { Button } from "@/components/ui/button/Button";
import {
    Form,
    FormControl,
    FormField,
    FormItem,
    FormLabel,
    FormMessage,
} from "@/components/ui/form/Form";
import { ProgressiveImageLoader } from "@/components/ui/image/ProgressiveImageLoader";
import { Input, InputActionButton, InputWrapper } from "@/components/ui/input/Input";
import { useLvl } from "@/hooks/useLvl.js";
import { cn } from "@/lib/utils";
import { SignupSchema, SignupSchemaType } from "@/schemas/auth.schema";
import { useCredentials, useSetCredentials } from "@/store/authStore";
import { getAvatar } from "@/utils/getAvatar";
import { serializeData } from "@/utils/serializeData";
import { yupResolver } from "@hookform/resolvers/yup";
import { motion } from "framer-motion";
import { AlertCircle, CalendarIcon, LoaderCircle } from "lucide-react";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
    Calendar,
    CalendarPopoverContent,
    CalendarPopoverTrigger,
    DateInput,
    DatePicker,
    CalendarPopover,
} from "@/components/ui/input/date-picker/DatetimePicker";
import { parseAbsoluteToLocal } from "@internationalized/date";
import { PhoneInput } from "@/components/ui/input/phone-input/PhoneInput";
import { AvatarEdit, AvatarImage } from "@/components/ui/avatar/Avatar";
import type { UserPrivate } from "@/types";
import { UserAvatarEditor } from "@/components/features/user/UserAvatarEditor";

export default function SignupPage() {
    const location = useLocation();
    const navigate = useNavigate();
    const params = useParams();
    const authToken = params.t;

    const setCredentials = useSetCredentials();
    const { avatarURL } = useCredentials();
    const [open, setOpen] = useState<boolean>(false);
    const [avatarSrc, setAvatarSrc] = useState<string | undefined>(undefined);

    const { mutateAsync, isPending, isError, error } = usePostSignupMutation();

    const { data, ...getSignupResults } = useGetSignupQuery(authToken);
    const userRole = useLvl(data?.lvl);

    const { detail } = httpErrorHandler(error);

    const form = useForm<SignupSchemaType>({
        values: {
            authToken: authToken,
            ...SignupSchema.cast(serializeData(data)),
        },
        resetOptions: {
            keepDirtyValues: false,
        },
        mode: "onBlur",
        resolver: yupResolver(SignupSchema),
    });

    const onSubmit = async (formData: SignupSchemaType) => {
        await mutateAsync(formData).then(() => {
            const newCredentials = { ...data, ...formData } as UserPrivate;
            setCredentials(newCredentials);
            setAvatarSrc(getAvatar(newCredentials.avatarURL));
            void navigate(`/auth/passwordChange?t=${authToken}`);
        });
    };

    const checkIfCanGoBack = (): boolean => {
        return location.key !== "default";
    };

    useEffect(() => {
        if (avatarURL) {
            setAvatarSrc(getAvatar(avatarURL));
        }
    }, [avatarURL]);

    useEffect(() => {
        if (data) {
            setAvatarSrc(getAvatar(data.avatarURL));
        }
    }, [data]);

    return (
        <>
            <ProgressiveImageLoader
                alt="Background image"
                className={"absolute inset-0 z-0 opacity-80"}
                sources={{
                    mobile: {
                        lowRes: {
                            webp: "/images/background/auth_bg_img_small.webp",
                            jpeg: "/auth_bg/auth_bg_img_small.jpeg",
                        },
                        highRes: {
                            webp: "/images/background/auth_bg_img_medium.webp",
                            jpeg: "/images/background/auth_bg_img_medium.jpeg",
                        },
                    },
                    tablet: {
                        lowRes: {
                            webp: "/images/background/auth_bg_img_medium.webp",
                            jpeg: "/images/background/auth_bg_img_medium.jpeg",
                        },
                        highRes: {
                            webp: "/images/background/auth_bg_img_large.webp",
                            jpeg: "/images/background/auth_bg_img_large.jpeg",
                        },
                    },
                    desktop: {
                        lowRes: {
                            webp: "/images/background/auth_bg_img_large.webp",
                            jpeg: "/images/background/auth_bg_img_large.jpeg",
                        },
                        highRes: {
                            webp: "/images/background/auth_bg_img_2k.webp",
                            jpeg: "/images/background/auth_bg_img_2k.jpeg",
                        },
                    },
                }}
            />

            <UserAvatarEditor onOpenChange={setOpen} open={open} />
            <div
                className={
                    "flex min-h-dvh h-dvh w-full items-end md:items-center justify-center p-0 pt-6 md:p-6"
                }
            >
                <div
                    className={
                        "z-10 flex p-4 md:p-6 h-[85dvh] max-h-[calc(100dvh_-_var(--spacing)*4*2)] md:h-fit min-h-100 w-full rounded-b-none md:rounded-2xl border-0 md:border md:w-[clamp(20rem,50dvw,26rem)] flex-col items-center gap-6 overflow-auto rounded-2xl border-border-primary bg-surface-primary"
                    }
                >
                    {getSignupResults.isFetching && (
                        <div
                            className={
                                "absolute inset-0 z-60 m-auto flex flex-wrap items-center justify-center gap-3 bg-surface-primary/75 backdrop-blur-md"
                            }
                        >
                            <LoaderCircle className={"animate-spin"} aria-hidden={"true"} />
                            <h5 className={"font-medium"}>Trwa ładowanie danych...</h5>
                        </div>
                    )}
                    <h1 className={"w-full py-3 text-4xl font-semibold"}>Zarejestruj się</h1>
                    <div className={"flex w-full flex-col gap-4"}>
                        <h5 className={"text-lg font-medium"}>Zmień swój awatar</h5>
                        <div className={"flex flex-row items-center gap-4"}>
                            <AvatarEdit className={"size-20"} onClick={() => setOpen(true)}>
                                <AvatarImage src={avatarSrc} alt="avatar" />
                            </AvatarEdit>
                            {getSignupResults.isSuccess && (
                                <div className={"flex flex-col gap-1"}>
                                    <p className={"font-medium"}>
                                        {data?.name + " " + data?.surname}
                                    </p>
                                    <p className={"text-sm text-text-tertiart"}>{userRole}</p>
                                </div>
                            )}
                        </div>
                    </div>
                    <div className={"flex w-full grow flex-col gap-4"}>
                        <h5 className={"text-lg font-medium"}>Podstawowe dane</h5>

                        <Form {...form}>
                            <form
                                className={"flex w-full grow flex-col gap-3"}
                                onSubmit={form.handleSubmit(onSubmit)}
                                noValidate
                            >
                                <FormField
                                    name={"email"}
                                    control={form.control}
                                    render={({ field }) => (
                                        <FormItem>
                                            <FormLabel>Email</FormLabel>
                                            <FormControl>
                                                <Input
                                                    {...field}
                                                    placeholder={"Email"}
                                                    autoComplete={"username"}
                                                />
                                            </FormControl>
                                            <FormMessage />
                                        </FormItem>
                                    )}
                                />
                                <FormField
                                    name={"birthdate"}
                                    control={form.control}
                                    render={({ field }) => {
                                        return (
                                            <FormItem>
                                                <FormLabel>
                                                    Data urodzenia{" "}
                                                    <small className={"text-sm text-text-tertiary"}>
                                                        (opcionalne)
                                                    </small>
                                                </FormLabel>
                                                <FormControl>
                                                    <DatePicker
                                                        aria-label={"birthdate"}
                                                        granularity={"day"}
                                                        value={
                                                            field.value
                                                                ? parseAbsoluteToLocal(field.value)
                                                                : null
                                                        }
                                                        onChange={date => {
                                                            if (date) {
                                                                field.onChange(date.toString());
                                                            } else {
                                                                field.onChange(null);
                                                            }
                                                        }}
                                                    >
                                                        <CalendarPopover>
                                                            <InputWrapper>
                                                                <DateInput
                                                                    className={"rounded-e-none"}
                                                                />
                                                                <CalendarPopoverTrigger asChild>
                                                                    <InputActionButton
                                                                        className={
                                                                            "-ms-px rounded-s-none"
                                                                        }
                                                                    >
                                                                        <CalendarIcon />
                                                                    </InputActionButton>
                                                                </CalendarPopoverTrigger>
                                                            </InputWrapper>
                                                            <CalendarPopoverContent
                                                                align={"end"}
                                                                className={"p-2"}
                                                            >
                                                                <Calendar />
                                                            </CalendarPopoverContent>
                                                        </CalendarPopover>
                                                    </DatePicker>
                                                </FormControl>
                                                <FormMessage />
                                            </FormItem>
                                        );
                                    }}
                                />
                                <FormField
                                    name={"phone"}
                                    control={form.control}
                                    render={({ field }) => (
                                        <FormItem>
                                            <FormLabel>
                                                Numer telefonu{" "}
                                                <small className={"text-text-tertiary text-sm"}>
                                                    (opcionalne)
                                                </small>
                                            </FormLabel>
                                            <FormControl>
                                                <PhoneInput
                                                    {...field}
                                                    defaultCountry={"PL"}
                                                    placeholder={"Numer telefonu"}
                                                />
                                            </FormControl>
                                            <FormMessage />
                                        </FormItem>
                                    )}
                                />
                                <motion.div
                                    initial={{ opacity: 0, height: 0 }}
                                    animate={{
                                        opacity: isError ? 1 : 0,
                                        height: isError ? "auto" : 0,
                                    }}
                                    transition={{ duration: 0.2 }}
                                    className={
                                        "flex flex-row items-center gap-2 overflow-hidden text-text-destructive"
                                    }
                                >
                                    <AlertCircle className={"h-4 w-4"} />
                                    <p className={"truncate text-sm"}>{isError && detail}</p>
                                </motion.div>

                                <footer className={"mt-auto flex flex-row gap-3"}>
                                    <Button
                                        type={"button"}
                                        size={"lg"}
                                        variant={"outline"}
                                        className={cn(checkIfCanGoBack() ? "w-full" : "hidden")}
                                        onClick={() => void navigate(-1)}
                                    >
                                        Cofnij
                                    </Button>
                                    <Button
                                        type={"submit"}
                                        size={"lg"}
                                        className={"w-full"}
                                        disabled={isPending}
                                        onClick={form.handleSubmit(onSubmit)}
                                    >
                                        Dalej
                                    </Button>
                                </footer>
                            </form>
                        </Form>
                    </div>
                </div>
            </div>
        </>
    );
}
