import { useCredentials, useIsLogged } from "@/store/authStore";
import { ReactNode } from "react";
import { Navigate, Outlet } from "react-router-dom";

interface ProtectedRoutesProps {
    children?: ReactNode;
    redirectURL?: string;
    minLvl: number;
}

function ProtectedRoutes({ redirectURL = "/notAllowed", minLvl, children }: ProtectedRoutesProps) {
    const { lvl } = useCredentials();
    const isLogged = useIsLogged();
    if (!isLogged()) {
        return <Navigate to={redirectURL} replace />;
    }

    return lvl >= minLvl ? children ? children : <Outlet /> : <Navigate to={redirectURL} replace />;
}

export { ProtectedRoutes };
