import { httpErrorHandler } from "@/api/api";
import { useEditMeetingMutation, useGetMeetingQuery } from "@/api/queries/meetingsQuery";
import MeetingFormContent from "@/components/features/calendar/form/meeting-form-content/MeetingFormContent";
import {
    convertDataToForm,
    convertFormToData,
} from "@/components/features/calendar/form/meeting-form-content/convert-data";
import { Dialog, DialogContent, DialogDescription } from "@/components/ui/dialog/Dialog";
import { CalendarFormSchemaType } from "@/schemas/meeting.schema";
import { useMemo, Dispatch, SetStateAction } from "react";
import { LoaderCircle } from "lucide-react";
import { toast } from "sonner";

type EditMeetingProps = {
    id: number;
    open: boolean;
    onOpenChange: Dispatch<SetStateAction<boolean>>;
    queryParams?: { after: string; before: string };
};

export default function EditMeeting({ id, open, onOpenChange, queryParams }: EditMeetingProps) {
    const { mutateAsync, isPending } = useEditMeetingMutation(queryParams);

    const { data: fetchedData, isFetching } = useGetMeetingQuery(id, open);
    const formData = useMemo(() => convertDataToForm(fetchedData), [fetchedData]);

    const onClose = () => {
        onOpenChange(false);
    };

    const onSubmit = (data: CalendarFormSchemaType) => {
        const { groupArr, userArr, ...meeting } = convertFormToData(data);

        console.log("meeting:", meeting);

        const memberChanges = [
            {
                userIds: userArr.filter(
                    userId => !(fetchedData?.users || []).some(user => userId === user.id),
                ),
                groupIds: groupArr.filter(
                    groupId => !(fetchedData?.groups || []).some(group => groupId === group.id),
                ),
                add: true,
                remove: false,
            },
            {
                userIds: (fetchedData?.users || [])
                    .filter(user => !userArr.some(userId => userId === user.id))
                    .map(user => user.id),
                groupIds: (fetchedData?.groups || [])
                    .filter(group => !groupArr.some(groupId => groupId === group.id))
                    .map(group => group.id),
                add: false,
                remove: true,
            },
        ];

        mutateAsync({ ...meeting, id: id, memberChanges: memberChanges })
            .then(() => {
                onClose();
            })
            .catch(error => {
                const { title, detail } = httpErrorHandler(error);
                toast.error(title, {
                    description: detail,
                });
            });
    };

    return (
        <Dialog open={open} onOpenChange={onOpenChange}>
            <DialogContent
                className={"sm:max-w-148 overflow-x-hidden"}
                onCloseAutoFocus={onClose}
                onEscapeKeyDown={onClose}
                isClosable={false}
            >
                <DialogDescription className={"sr-only"}>
                    Formularz do edycji spotkania
                </DialogDescription>
                {isPending && (
                    <div
                        className={
                            "absolute inset-0 z-60 m-auto flex flex-wrap items-center justify-center gap-3 bg-surface-primary/75 backdrop-blur-md"
                        }
                    >
                        <LoaderCircle className={"animate-spin"} aria-hidden={"true"} />
                        <h5 className={"font-medium"}>Trwa zapisywanie zmian...</h5>
                    </div>
                )}
                {isFetching && (
                    <div
                        className={
                            "absolute inset-0 z-60 m-auto flex flex-wrap items-center justify-center gap-3 bg-surface-primary/75 backdrop-blur-md"
                        }
                    >
                        <LoaderCircle className={"animate-spin"} aria-hidden={"true"} />
                        <h5 className={"font-medium"}>Trwa ładowanie danych...</h5>
                    </div>
                )}
                <MeetingFormContent
                    callback={onSubmit}
                    onOpenChange={onOpenChange}
                    formData={formData}
                />
            </DialogContent>
        </Dialog>
    );
}
