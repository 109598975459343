import { axiosInstance } from "@/api/api";
import { CalendarScheduleInstanceSchemaType } from "@/schemas/meeting.schema";

import {
    DatetimeCursorPaginationI,
    PaginationWithSortI,
    MeetingType,
    MeetingPrivate,
    MeetingPublic,
    MeetingAttendance,
} from "@/types";

export interface BaseMeetingProps {
    name: string;
    desc?: string;
    startDate: string;
    endDate: string;
    userArr: number[];
    groupArr: number[];
}

export interface PostMeetingProps extends BaseMeetingProps {
    type: MeetingType;
    locationId?: number;
    meetingURL?: string;
}

export interface PostMeetingScheduleProps extends BaseMeetingProps {
    excludeHolidays: boolean;
    schedule: CalendarScheduleInstanceSchemaType[];
}

export interface MeetingMemberChanges {
    userIds?: number[];
    groupIds?: number[];
    add?: boolean;
    remove?: boolean;
}

export interface EditMeetingProps {
    id: number;
    name?: string;
    desc?: string;
    type?: MeetingType;
    startDate?: string;
    endDate?: string;
    locationId?: number;
    meetingURL?: string;
    isCancelled?: boolean;
    memberChanges?: MeetingMemberChanges[];
}

export interface GetMeetingsProps
    extends Partial<DatetimeCursorPaginationI>,
        Partial<PaginationWithSortI> {
    id?: number;
}

export interface EditAttendanceProps {
    id: number;
    attendanceData: MeetingAttendance[];
}

export interface GetMeetingPrefetchProps {
    id: number;
    initialData: Partial<MeetingPublic>;
}

export const getMeeting = async (id: number): Promise<MeetingPrivate> => {
    return await axiosInstance.get("/api/meeting", {
        params: { id },
    });
};

export const getAssignedMeetings = async (params: GetMeetingsProps): Promise<MeetingPublic[]> => {
    return await axiosInstance.get("/api/meeting/getAssigned", {
        params: params,
    });
};

export const postMeetingSchedule = async (
    data: PostMeetingScheduleProps,
): Promise<MeetingPrivate> => {
    return await axiosInstance.post("/api/meeting/schedule", data);
};

export const postMeeting = async (data: PostMeetingProps): Promise<MeetingPrivate> => {
    return await axiosInstance.post("/api/meeting", data);
};

export const editMeeting = async (data: EditMeetingProps): Promise<MeetingPrivate> => {
    return await axiosInstance.put("/api/meeting", data);
};

export const deleteMeeting = async (id: number): Promise<number> => {
    return await axiosInstance.delete("/api/meeting", {
        data: { id: id },
    });
};

export const editAttendance = async ({
    id,
    attendanceData,
}: EditAttendanceProps): Promise<void> => {
    return await axiosInstance.put("/api/meeting/attendance", {
        meetingId: id,
        userIds: attendanceData,
    });
};
