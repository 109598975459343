import { useInfiniteQueryResult } from "@/api/api-utils";
import {
    useGetLastConversationsInfiniteQuery,
    type ConversationsListFilter,
} from "@/api/queries/chatQueries";
import {
    ConversationsListItem,
    ConversationsListSkeletonItem,
} from "@/components/features/chat/layout/chat-conversations/ConversationsListItem";
import { Button } from "@/components/ui/button/Button";
import { InfiniteScroll } from "@/components/ui/infinite-scroll/InfiniteScroll";
import { Input, InputWrapper } from "@/components/ui/input/Input";
import { useDebounceValue } from "@/hooks/useDebounceValue";
import { useLocalStorage } from "@/hooks/useLocalStorage";
import { CircleX, Search, Settings2 } from "lucide-react";
import { useState } from "react";
import { ConversationsListFilters } from "./ConversationListFilters";

interface ChatConversationsListProps {
    openCreateConversation: () => void;
}

export default function ConversationsList({ openCreateConversation }: ChatConversationsListProps) {
    const [searchValue, setSearchValue] = useState<string>("");
    const searchDebouncedValue = useDebounceValue(searchValue, 400);

    const [chatFilterValue, onChatFilterValueChange] = useLocalStorage<ConversationsListFilter>(
        "chat-conversations-list-filter",
        "all",
    );

    const { data, isLoading, isFetching, isError, hasNextPage, fetchNextPage } =
        useGetLastConversationsInfiniteQuery({
            name: searchDebouncedValue ? searchDebouncedValue : undefined,
        });

    const {
        data: conversations,
        isEmpty: conversationsIsEmpty,
        noResults: conversationsNoResults,
    } = useInfiniteQueryResult(data, searchDebouncedValue);

    const next = async () => {
        await fetchNextPage();
    };

    const onValueChange = (value: string) => {
        onChatFilterValueChange(value as ConversationsListFilter);
    };

    return (
        <section className={"w-full h-full lg:w-fit"}>
            <div
                className={
                    "w-full lg:w-76 h-[calc(100dvh-var(--spacing)*23)] md:h-[calc(100dvh-var(--spacing)*4*2)] md:min-w-76 md:sticky top-4 flex shrink-0 flex-col overflow-y-auto overflow-x-hidden md:rounded-lg md:border border-border-primary md:bg-fill-primary"
                }
            >
                <div
                    className={
                        "p-3 md:p-0 border md:border-0 border-border-primary bg-surface-primary rounded-lg"
                    }
                >
                    <div className={"flex gap-2 md:border-b border-border-primary md:p-4"}>
                        <InputWrapper className={"w-full"}>
                            <Input
                                value={searchValue}
                                onChange={e => setSearchValue(e.target.value)}
                                className="peer pe-8 ps-8"
                                placeholder="Wyszukaj..."
                            />
                            <div className="pointer-events-none absolute inset-y-0 start-0 flex items-center justify-center ps-2 peer-disabled:opacity-50">
                                <Search className="size-3 stroke-icon-tertiary" />
                            </div>
                            {searchValue && (
                                <button
                                    className="absolute cursor-pointer inset-y-0 end-0 flex h-full w-9 items-center justify-center rounded-e-sm text-text-tertiary transition-colors hover:text-text-tertiary-hover z-10 focus-visible:outline-2 outline-ring-focus disabled:pointer-events-none disabled:cursor-not-allowed disabled:opacity-50"
                                    aria-label="Clear input"
                                    onClick={() => setSearchValue("")}
                                >
                                    <CircleX className={"size-3"} aria-hidden="true" />
                                </button>
                            )}
                        </InputWrapper>
                        <Button variant={"outline"} onClick={openCreateConversation}>
                            <Settings2 />
                        </Button>
                    </div>
                </div>
                <div
                    className={
                        "flex h-full grow flex-col gap-2 overflow-auto p-3 md:px-4 md:pb-4 mt-4 md:mt-0 border md:border-0 border-border-primary bg-surface-primary rounded-lg overflow-x-hidden"
                    }
                >
                    {/* <ConversationsListFilters
                        value={chatFilterValue}
                        onValueChange={onValueChange}
                    /> */}
                    <ul>
                        {isLoading && (
                            <>
                                <ConversationsListSkeletonItem />
                                <ConversationsListSkeletonItem />
                                <ConversationsListSkeletonItem />
                                <ConversationsListSkeletonItem />
                            </>
                        )}
                        {conversationsNoResults && !isFetching ? (
                            <div className={"flex flex-col items-center gap-4 py-11"}>
                                <h5 className={"text-center text-sm text-text-tertiary italic"}>
                                    Brak konwersacji o podanej nazwie
                                </h5>
                            </div>
                        ) : null}
                        {conversationsIsEmpty && !isFetching ? (
                            <div className={"flex flex-col items-center gap-4 py-11"}>
                                <h5 className={"text-center text-sm text-text-tertiary italic"}>
                                    Brak konwersacji
                                </h5>
                            </div>
                        ) : null}
                        {conversations.map(conversation => (
                            <ConversationsListItem key={conversation.id} {...conversation} />
                        ))}
                        <InfiniteScroll
                            hasNextPage={hasNextPage}
                            isLoading={isLoading}
                            isFetching={isFetching}
                            next={next}
                            threshold={0.65}
                        >
                            {hasNextPage && !isError && <ConversationsListSkeletonItem />}
                        </InfiniteScroll>
                    </ul>
                </div>
            </div>
        </section>
    );
}
