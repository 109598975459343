import UnexpectedError from "@/assets/images/errors/UnexpectedError.svg?react";
import { Badge } from "@/components/ui/badge/Badge";
import { Button } from "@/components/ui/button/Button";
import { Terminal } from "lucide-react";
import React from "react";
import { FallbackProps } from "react-error-boundary";

export default function ErrorBoundaryFallback({ error, resetErrorBoundary }: FallbackProps) {
    const linkRef = React.useRef<HTMLAnchorElement>(null);

    const handleRestart = () => {
        resetErrorBoundary();
    };
    const handleReport = () => {
        linkRef.current?.click();
    };

    return (
        <>
            <div
                className={
                    "flex min-h-dvh w-full justify-center md:p-6 items-end md:items-center p-0 pt-6"
                }
            >
                <div
                    role={"alert"}
                    className={
                        "z-30 flex min-h-96 md:w-[clamp(380px,60dvw,620px)] flex-col gap-7 overflow-auto overflow-x-hidden rounded-xl md:rounded-xl border border-border-primary bg-surface-primary shadow-md-sharp-border h-[85dvh] w-full rounded-b-none p-3 md:p-4"
                    }
                >
                    <UnexpectedError className={"max-h-68 w-fit"} />
                    <a
                        ref={linkRef}
                        rel="noreferrer"
                        className={"hidden"}
                        target={"_blank"}
                        href={
                            "https://bettercallpaul.youtrack.cloud/form/ae26043a-18a2-4f34-8f82-e10053d5c6d6"
                        }
                    />
                    <div className={"flex flex-col gap-3"}>
                        <h1 className={"text-3xl font-semibold text-text-primary"}>
                            Wystąpił błąd
                        </h1>
                        <h5 className={"text-sm text-text-tertiary"}>
                            Po więcej informacji sprawdź konsolę przegladarki.
                        </h5>
                        <Badge variant={"muted"}>Prawy przycisk myszy --/ Zbadaj</Badge>
                    </div>

                    <div className={"flex flex-row gap-2"}>
                        <Terminal className={"h-5 w-5 stroke-icon-tertiary"} />
                        <pre className={"text-wrap text-md text-text-secondary"}>
                            {error.message}
                        </pre>
                    </div>
                    <div className={"flex justify-end gap-3 flex-col md:flex-row"}>
                        <Button className={"w-full"} onClick={() => handleRestart()}>
                            Spróbuj ponownie
                        </Button>
                        <Button
                            className={"w-full"}
                            variantColor={"destructive"}
                            variant={"tertiary"}
                            onClick={() => handleReport()}
                        >
                            Zgłoś błąd
                        </Button>
                    </div>
                </div>
            </div>
        </>
    );
}
