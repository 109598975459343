import { ReactElement, SVGProps } from "react";

type MeetingFormContentLabelIconProps = {
    children: ReactElement<SVGProps<SVGSVGElement>>;
};

export default function MeetingFormContentLabelIcon({
    children,
}: MeetingFormContentLabelIconProps) {
    return (
        <div
            className={
                "w-11 min-w-11 shrink-0 hidden sm:flex items-center justify-center [&_svg]:size-3 [&_svg]:stroke-icon-secondary"
            }
        >
            {children}
        </div>
    );
}
