import type { UserPrivate } from "@/types";
import { ZustandHookSelectors, createSelectorHooks } from "auto-zustand-selectors-hook";
import { create } from "zustand";
import { persist } from "zustand/middleware";

interface Action {
    setCredentials: (payload: UserPrivate) => void;
    removeCredentials: () => void;
    isLogged: () => boolean;
}

interface State {
    credentials: UserPrivate;
}

const defaultCredentials: UserPrivate = {
    id: -1,
    name: "",
    surname: "",
    email: "",
    lvl: 0,
    directoryId: "",
    phone: "",
    birthdate: "",
    avatarURL: "",
    active: true,
    last_login: "",
    force_password_change: false,
    force_email_change: false,
    createdAt: "",
    updatedAt: "",
};

const useAuthStoreBase = create<Action & State>()(
    persist(
        (set, get) => ({
            credentials: defaultCredentials,
            isLogged: () => {
                const credentials = get().credentials;
                return !!(credentials.id !== -1);
            },
            setCredentials: (payload: UserPrivate) => set({ credentials: payload }),
            removeCredentials: () => {
                set({ credentials: defaultCredentials });
                useAuthStore.persist.clearStorage();
            },
        }),
        {
            name: "credentials",
        },
    ),
);

const useAuthStore = createSelectorHooks(useAuthStoreBase) as typeof useAuthStoreBase &
    ZustandHookSelectors<Action & State>;

export const { useCredentials, useIsLogged, useSetCredentials, useRemoveCredentials } =
    useAuthStore;
export { useAuthStore, useAuthStoreBase, defaultCredentials };
