import { SidebarTrigger, SidebarHeader } from "@/components/ui/sidebar/Sidebar";
import { ArrowLeft } from "lucide-react";
export function NavHeader() {
    return (
        <SidebarHeader className={"flex flex-row justify-between gap-0"}>
            <img
                src={"/logo.png"}
                className={
                    "transition-scale duration-100 group-data-[state=collapsed]:scale-0 group-data-[state=collapsed]:w-0 aspect-square size-10"
                }
            />
            <SidebarTrigger variant={"primary"} className={"w-fit hidden md:flex"}>
                <ArrowLeft
                    className={
                        "transition-transform duration-100 group-data-[state=collapsed]:rotate-180"
                    }
                />
            </SidebarTrigger>
        </SidebarHeader>
    );
}
