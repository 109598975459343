import PageNotFound from "@/assets/images/errors/PageNotFound.svg?react";
import { Button } from "@/components/ui/button/Button";
import { useNavigate } from "react-router-dom";

export default function NotAllowedPage() {
    const navigate = useNavigate();

    return (
        <div className={"base-direction-row md:flex-col lg:flex-row items-center"}>
            <div className={"flex w-full items-center justify-center h-fit max-w-128"}>
                <PageNotFound className="w-full" />
            </div>
            <div className={"flex h-fit lg:h-full shrink-0 flex-col justify-center gap-6"}>
                <div className={"flex flex-col gap-2"}>
                    <h1
                        className={
                            "whitespace-nowrap text-center md:text-start text-4xl md:text-6xl font-semibold leading-snug"
                        }
                    >
                        Nie masz <br /> wystarczających <br /> uprawnień
                    </h1>
                    <p
                        className={
                            "text-sm md:text-md text-text-tertiary text-center md:text-start"
                        }
                    >
                        Niestety nie masz odpowiednich uprawnień aby otworzyć tą stronę.
                    </p>
                </div>
                <div className={"flex flex-col md:flex-row gap-3"}>
                    <Button variant={"secondary"} onClick={() => void navigate("/")}>
                        Wróć do strony główna
                    </Button>
                    <Button variant={"tertiary"} onClick={() => void navigate(-1)}>
                        Wróć
                    </Button>
                </div>
            </div>
        </div>
    );
}
