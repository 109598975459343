import { httpErrorHandler } from "@/api/api";
import { useDeleteDirectoriesMutation } from "@/api/queries/directoryQueries";
import { useDeleteFilesMutation } from "@/api/queries/filesQueries";
import { Badge } from "@/components/ui/badge/Badge";
import {
    AlertDialog,
    AlertDialogBody,
    AlertDialogContent,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogTitle,
    AlertDialogAction,
    AlertDialogCancel,
    AlertDialogDescription,
} from "@/components/ui/dialog/AlertDialog";
import { useMemo } from "react";
import { Folders } from "lucide-react";
import { FeaturedIcon } from "@/components/ui/featured-icon/FeaturedIcon";
import { toast } from "sonner";
import type { DirectoryElements } from "@/types";

interface DeleteFSEntryProps {
    elements: DirectoryElements;
    open: boolean;
    onOpenChange: (open: boolean) => void;
    callback?: () => void;
    directoryId?: string;
}

export default function DeleteFSEntry({
    elements,
    open,
    onOpenChange,
    callback,
    directoryId,
}: DeleteFSEntryProps) {
    const { executeMutations: directoryMutations } = useDeleteDirectoriesMutation(directoryId);
    const { executeMutations: filesMutations } = useDeleteFilesMutation(directoryId);

    const onClose = () => {
        onOpenChange(false);
    };

    const onSubmit = async () => {
        const { files, directories } = { ...elements };
        onClose();
        callback?.();
        filesMutations(files.map(({ id }) => id)).catch(error => {
            const { title, detail } = httpErrorHandler(error);
            toast.error(title, {
                description: detail,
            });
        });
        directoryMutations(directories.map(({ id }) => id)).catch(error => {
            const { title, detail } = httpErrorHandler(error);
            toast.error(title, {
                description: detail,
            });
        });
    };

    const elementsLen = useMemo(() => {
        return elements.directories.length + elements.files.length;
    }, [elements]);

    const elementsNames = useMemo(() => {
        return [...elements.directories, ...elements.files].map(element => element.name);
    }, [elements]);

    return (
        <AlertDialog onOpenChange={onOpenChange} open={open}>
            <AlertDialogContent className={"sm:max-w-96"}>
                <AlertDialogDescription className={"sr-only"}>
                    Usuwanie elementów z modułu plików
                </AlertDialogDescription>
                <AlertDialogBody>
                    <AlertDialogHeader>
                        <FeaturedIcon variant={"ghost"}>
                            <Folders />
                        </FeaturedIcon>
                        <div className={"flex flex-col gap-0.5 min-w-0 grow"}>
                            <AlertDialogTitle>
                                Usunąć {"( " + elementsLen + " )"} ?
                            </AlertDialogTitle>
                            <p className={"text-text-tertiary text-xs"}>Usuń wybrane elementy.</p>
                        </div>
                    </AlertDialogHeader>
                    <div className={"flex flex-col gap-3 px-3 md:px-4 overflow-auto"}>
                        <p className="text-sm text-text-tertiary">
                            Czy na pewno chcesz usunąć wybrane elementy ?
                        </p>
                        <div className={"flex flex-wrap gap-2"}>
                            {elementsNames.map((name, idx) => (
                                <Badge key={name + "_" + idx} variant={"muted"} size={"sm"}>
                                    {name}
                                </Badge>
                            ))}
                        </div>
                    </div>
                </AlertDialogBody>
                <AlertDialogFooter>
                    <AlertDialogCancel variant={"tertiary"} onClick={onClose}>
                        Zamknij
                    </AlertDialogCancel>
                    <AlertDialogAction onClick={onSubmit}>Potwierdź</AlertDialogAction>
                </AlertDialogFooter>
            </AlertDialogContent>
        </AlertDialog>
    );
}
