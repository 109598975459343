"use no memo";

import MeetingFormContentScheduleElement from "@/components/features/calendar/form/meeting-form-content/MeetingFormContentScheduleElement";
import { Accordion } from "@/components/ui/accordion/Accordion";
import { Button } from "@/components/ui/button/Button";
import { getAvatar } from "@/utils/getAvatar";

import { DialogBody, DialogFooter } from "@/components/ui/dialog/Dialog";
import {
    Form,
    FormControl,
    FormField,
    FormItem,
    FormLabel,
    FormMessage,
    FormMessages,
} from "@/components/ui/form/Form";
import { Input, InputActionButton, InputWrapper } from "@/components/ui/input/Input";
import { Checkbox } from "@/components/ui/input/checkbox/Checkbox";
import { Textarea } from "@/components/ui/input/textarea/Textarea";
import { Tabs, TabsContent } from "@/components/ui/tabs/Tabs";
import { useDebounceValue } from "@/hooks/useDebounceValue";
import { cn } from "@/lib/utils";
import { CalendarFormSchema, CalendarFormSchemaType } from "@/schemas/meeting.schema";
import type { UserPublic } from "@/types";
import {
    ArrowRight,
    Plus,
    CheckCircle2,
    CalendarIcon,
    User,
    CalendarRange,
    Type,
    Text,
    Users,
    CalendarDays,
    Clock,
    MapPinned,
    CalendarX,
} from "lucide-react";
import { useEffect, useState } from "react";
import { useFieldArray, useForm, useWatch } from "react-hook-form";
import meetingFormResolver from "@/components/features/calendar/form/meeting-form-content/meetingFormResolver";
import MeetingFormContentCloseAlert from "./MeetingFormContentCloseAlert";
import MeetingFormContentHeader from "./MeetingFormContentHeader";
import { GroupsSelectTab, UsersSelectTab } from "./MeetingFormContentSelectTabs";
import {
    DatePicker,
    Calendar,
    CalendarPopoverContent,
    CalendarPopoverTrigger,
    DateInput,
    CalendarPopover,
    TimePicker,
} from "@/components/ui/input/date-picker/DatetimePicker";
import { parseAbsoluteToLocal, parseTime } from "@internationalized/date";
import { Avatar, AvatarFallback, AvatarImage } from "@/components/ui/avatar/Avatar";
import { Tag } from "@/components/ui/tags/Tags";
import MeetingFormMethodRadioGroup from "./MeetingFormMethodRadioGroup";
import MeetingFormContentLabelIcon from "./MeetingFormContentLabelIcon";
import { Separator } from "@/components/ui/separator/Separator";
import MeetingFormTypeRadioGroup from "./MeetingFormTypeRadioGroup";
import type { GroupPublic } from "@/types";

interface DialogCalendarContentProps {
    callback: (data: CalendarFormSchemaType) => void;
    onOpenChange: (open: boolean) => void;
    formData?: Partial<CalendarFormSchemaType>;
    defaultValues?: Partial<CalendarFormSchemaType>;
}

type MethodT = "once" | "schedule";

export default function MeetingFormContent({
    callback,
    onOpenChange,
    formData,
    defaultValues,
}: DialogCalendarContentProps) {
    const [openCloseAlert, setOpenCloseAlert] = useState<boolean>(false);
    const [tabs, setTabs] = useState<string>("main");
    const [resolverType, setResolverType] = useState<MethodT>(
        formData?.method || defaultValues?.method || ("once" as MethodT),
    );

    //search value
    const [searchValue, setSearchValue] = useState<string>("");
    const searchDebouncedValue = useDebounceValue(searchValue, 400);

    const isMainTab = tabs === "main";
    const isUsersSelectTab = tabs === "usersSelect";
    const isGroupsSelectTab = tabs === "groupsSelect";

    const form = useForm<CalendarFormSchemaType>({
        mode: "onBlur",
        defaultValues: {
            name: "",
            desc: "",
            method: "once",
            type: "remote",
            users: [],
            groups: [],
            ...defaultValues,
            ...formData,
        },
        resolver: meetingFormResolver(CalendarFormSchema, resolverType),
    });

    const { setValue } = form;

    // Watch method and type fields for changes need to use useWatch hook instead of watch function because of re-rendering issues with react-compiler
    const method = useWatch({ control: form.control, name: "method" });
    const groups = useWatch({ control: form.control, name: "groups" });
    const users = useWatch({ control: form.control, name: "users" });


    const { fields, append, remove } = useFieldArray({
        control: form.control,
        name: "schedule",
    });

    // Handle users/groups changes and sync with form
    const handleSelectedUsersChange = (users: UserPublic[]) => {
        setValue("users", users);
    };

    const handleSelectedGroupsChange = (groups: GroupPublic[]) => {
        setValue("groups", groups);
    };

    const onSubmit = (data: CalendarFormSchemaType) => {
        callback(data);
    };

    const onClose = () => {
        form.reset();
        onOpenChange(false);
    };

    const onCloseConfirmationDialog = () => {
        setOpenCloseAlert(false);
        onClose();
    };

    const onNewScheduleEntityAppend = () => {
        append({
            day: "1",
            type: "remote",
            locationId: undefined,
            meetingURL: undefined,
            start: "",
            end: "",
        });
    };

    const onRemoveUser = (id: number) => {
        setValue(
            "users",
            users.filter(user => user.id !== id),
        );
    };

    const onRemoveGroup = (id: number) => {
        setValue(
            "groups",
            groups.filter(user => user.id !== id),
        );
    };

    // Handle method and type field changes with watch
    useEffect(() => {
        const subscription = form.watch((values, { name }) => {
            if (name === "method" && values.method) {
                setResolverType(values.method);
            }
        });

        return () => subscription.unsubscribe();
    }, [form]);

    return (
        <>
            <MeetingFormContentCloseAlert
                open={openCloseAlert}
                onOpenChange={setOpenCloseAlert}
                confirmAction={onCloseConfirmationDialog}
            />
            <DialogBody>
                <MeetingFormContentHeader
                    tabs={tabs}
                    setTabs={setTabs}
                    searchValue={searchValue}
                    setSearchValue={setSearchValue}
                />
                <Tabs
                    value={tabs}
                    onValueChange={setTabs}
                    className={"flex flex-col gap-3 overflow-y-auto grow"}
                >
                    <TabsContent value={"main"}>
                        <Form {...form}>
                            <form
                                onSubmit={form.handleSubmit(onSubmit)}
                                className={"flex flex-col gap-5 py-3 md:py-4"}
                            >
                                {/* Method type */}
                                <div
                                    className={cn("flex gap-3 px-3 md:px-4", formData && "hidden")}
                                >
                                    <MeetingFormContentLabelIcon>
                                        <CalendarRange />
                                    </MeetingFormContentLabelIcon>
                                    <FormField
                                        name={"method"}
                                        control={form.control}
                                        render={({ field }) => (
                                            <FormItem>
                                                <FormControl>
                                                    <MeetingFormMethodRadioGroup
                                                        onValueChange={field.onChange}
                                                        defaultValue={field.value}
                                                    />
                                                </FormControl>
                                            </FormItem>
                                        )}
                                    />
                                </div>

                                {/* Title */}
                                <div className={"flex gap-3 px-3 md:px-4"}>
                                    <MeetingFormContentLabelIcon>
                                        <Type />
                                    </MeetingFormContentLabelIcon>
                                    <FormField
                                        name={"name"}
                                        control={form.control}
                                        render={({ field }) => (
                                            <FormItem className={"w-full"}>
                                                <FormLabel>Nazwa</FormLabel>
                                                <FormControl>
                                                    <Input
                                                        placeholder={"Nazwa spotkania"}
                                                        {...field}
                                                    />
                                                </FormControl>
                                                <FormMessage />
                                            </FormItem>
                                        )}
                                    />
                                </div>

                                {/* Description */}
                                <div className={"flex gap-3 px-3 md:px-4"}>
                                    <MeetingFormContentLabelIcon>
                                        <Text />
                                    </MeetingFormContentLabelIcon>
                                    <FormField
                                        name={"desc"}
                                        control={form.control}
                                        render={({ field }) => (
                                            <FormItem className={"w-full"}>
                                                <FormLabel>
                                                    Opis{" "}
                                                    <small className={"text-sm text-text-tertiary"}>
                                                        (opcionalne)
                                                    </small>
                                                </FormLabel>
                                                <FormControl>
                                                    <Textarea
                                                        placeholder={"Opis spotkania"}
                                                        {...field}
                                                    />
                                                </FormControl>
                                                <FormMessage />
                                            </FormItem>
                                        )}
                                    />
                                </div>

                                <Separator />

                                {method === "once" ? (
                                    <>
                                        {/* Date */}
                                        <div className={"flex gap-3 px-3 md:px-4"}>
                                            <MeetingFormContentLabelIcon>
                                                <CalendarDays />
                                            </MeetingFormContentLabelIcon>
                                            <FormField
                                                name={"date"}
                                                control={form.control}
                                                render={({ field }) => (
                                                    <FormItem className={"w-full"}>
                                                        <FormLabel>Data</FormLabel>
                                                        <FormControl>
                                                            <DatePicker
                                                                aria-label={"date-picker"}
                                                                granularity={"day"}
                                                                value={
                                                                    field.value
                                                                        ? parseAbsoluteToLocal(
                                                                              field.value,
                                                                          )
                                                                        : null
                                                                }
                                                                onChange={date => {
                                                                    if (date) {
                                                                        const jsDate =
                                                                            date.toDate();
                                                                        field.onChange(
                                                                            jsDate.toISOString(),
                                                                        );
                                                                    } else {
                                                                        field.onChange(null);
                                                                    }
                                                                }}
                                                            >
                                                                <CalendarPopover>
                                                                    <InputWrapper>
                                                                        <DateInput
                                                                            className={
                                                                                "rounded-e-none"
                                                                            }
                                                                        />
                                                                        <CalendarPopoverTrigger
                                                                            asChild
                                                                        >
                                                                            <InputActionButton
                                                                                className={
                                                                                    "-ms-px rounded-s-none"
                                                                                }
                                                                            >
                                                                                <CalendarIcon />
                                                                            </InputActionButton>
                                                                        </CalendarPopoverTrigger>
                                                                    </InputWrapper>
                                                                    <CalendarPopoverContent
                                                                        align={"end"}
                                                                        className={"p-2"}
                                                                    >
                                                                        <Calendar />
                                                                    </CalendarPopoverContent>
                                                                </CalendarPopover>
                                                            </DatePicker>
                                                        </FormControl>
                                                        <FormMessage />
                                                    </FormItem>
                                                )}
                                            />
                                        </div>

                                        {/* Time StartTime -> EndTime */}
                                        <div className={"flex gap-3 px-3 md:px-4"}>
                                            <MeetingFormContentLabelIcon>
                                                <Clock />
                                            </MeetingFormContentLabelIcon>
                                            <div className={"w-full space-y-2"}>
                                                <div className={"flex gap-2 w-full"}>
                                                    <FormField
                                                        name={"startTime"}
                                                        control={form.control}
                                                        render={({ field }) => {
                                                            return (
                                                                <FormItem className={"w-full"}>
                                                                    <FormLabel>Czas</FormLabel>
                                                                    <FormControl>
                                                                        <TimePicker
                                                                            value={
                                                                                field.value
                                                                                    ? parseTime(
                                                                                          field.value,
                                                                                      )
                                                                                    : null
                                                                            }
                                                                            onChange={time => {
                                                                                if (time) {
                                                                                    field.onChange(
                                                                                        time.toString(),
                                                                                    );
                                                                                } else {
                                                                                    field.onChange(
                                                                                        null,
                                                                                    );
                                                                                }
                                                                            }}
                                                                            aria-label={
                                                                                "start-time"
                                                                            }
                                                                        />
                                                                    </FormControl>
                                                                </FormItem>
                                                            );
                                                        }}
                                                    />
                                                    <ArrowRight
                                                        className={
                                                            "size-4 shrink-0 stroke-icon-tertiary mt-9"
                                                        }
                                                    />
                                                    <FormField
                                                        name={"endTime"}
                                                        control={form.control}
                                                        render={({ field }) => (
                                                            <FormItem className={"w-full"}>
                                                                <FormLabel className={"invisible"}>
                                                                    Koniec
                                                                </FormLabel>
                                                                <FormControl>
                                                                    <TimePicker
                                                                        value={
                                                                            field.value
                                                                                ? parseTime(
                                                                                      field.value,
                                                                                  )
                                                                                : null
                                                                        }
                                                                        onChange={time => {
                                                                            if (time) {
                                                                                field.onChange(
                                                                                    time.toString(),
                                                                                );
                                                                            } else {
                                                                                field.onChange(
                                                                                    null,
                                                                                );
                                                                            }
                                                                        }}
                                                                        aria-label={"start-time"}
                                                                    />
                                                                </FormControl>
                                                            </FormItem>
                                                        )}
                                                    />
                                                </div>
                                                <FormMessages
                                                    keys={["startTime", "endTime"]}
                                                    listView
                                                />
                                            </div>
                                        </div>

                                        {/* Meeting Type */}
                                        <div className={"flex gap-3 px-3 md:px-4"}>
                                            <MeetingFormContentLabelIcon>
                                                <MapPinned />
                                            </MeetingFormContentLabelIcon>
                                            <MeetingFormTypeRadioGroup form={form} />
                                        </div>
                                    </>
                                ) : (
                                    <>
                                        {/* Date */}
                                        <div className={"flex gap-3 px-3 md:px-4"}>
                                            <MeetingFormContentLabelIcon>
                                                <CalendarDays />
                                            </MeetingFormContentLabelIcon>
                                            <div className={"flex flex-col gap-3 w-full"}>
                                                <FormField
                                                    name={"startDate"}
                                                    control={form.control}
                                                    render={({ field }) => (
                                                        <FormItem className={"w-full"}>
                                                            <FormLabel>Od</FormLabel>
                                                            <FormControl>
                                                                <DatePicker
                                                                    aria-label={"date-picker"}
                                                                    granularity={"day"}
                                                                    value={
                                                                        field.value
                                                                            ? parseAbsoluteToLocal(
                                                                                  field.value,
                                                                              )
                                                                            : null
                                                                    }
                                                                    onChange={date => {
                                                                        if (date) {
                                                                            const jsDate =
                                                                                date.toDate();
                                                                            field.onChange(
                                                                                jsDate.toISOString(),
                                                                            );
                                                                        } else {
                                                                            field.onChange(null);
                                                                        }
                                                                    }}
                                                                >
                                                                    <CalendarPopover>
                                                                        <InputWrapper>
                                                                            <DateInput
                                                                                className={
                                                                                    "rounded-e-none"
                                                                                }
                                                                            />
                                                                            <CalendarPopoverTrigger
                                                                                asChild
                                                                            >
                                                                                <InputActionButton
                                                                                    className={
                                                                                        "-ms-px rounded-s-none"
                                                                                    }
                                                                                >
                                                                                    <CalendarIcon />
                                                                                </InputActionButton>
                                                                            </CalendarPopoverTrigger>
                                                                        </InputWrapper>
                                                                        <CalendarPopoverContent
                                                                            align={"end"}
                                                                            className={"p-2"}
                                                                        >
                                                                            <Calendar />
                                                                        </CalendarPopoverContent>
                                                                    </CalendarPopover>
                                                                </DatePicker>
                                                            </FormControl>
                                                            <FormMessage />
                                                        </FormItem>
                                                    )}
                                                />
                                                <FormField
                                                    name={"endDate"}
                                                    control={form.control}
                                                    render={({ field }) => (
                                                        <FormItem className={"w-full"}>
                                                            <FormLabel>Do</FormLabel>
                                                            <FormControl>
                                                                <DatePicker
                                                                    aria-label={"date-picker"}
                                                                    granularity={"day"}
                                                                    value={
                                                                        field.value
                                                                            ? parseAbsoluteToLocal(
                                                                                  field.value,
                                                                              )
                                                                            : null
                                                                    }
                                                                    onChange={date => {
                                                                        if (date) {
                                                                            const jsDate =
                                                                                date.toDate();
                                                                            field.onChange(
                                                                                jsDate.toISOString(),
                                                                            );
                                                                        } else {
                                                                            field.onChange(null);
                                                                        }
                                                                    }}
                                                                >
                                                                    <CalendarPopover>
                                                                        <InputWrapper>
                                                                            <DateInput
                                                                                className={
                                                                                    "rounded-e-none"
                                                                                }
                                                                            />
                                                                            <CalendarPopoverTrigger
                                                                                asChild
                                                                            >
                                                                                <InputActionButton
                                                                                    className={
                                                                                        "-ms-px rounded-s-none"
                                                                                    }
                                                                                >
                                                                                    <CalendarIcon />
                                                                                </InputActionButton>
                                                                            </CalendarPopoverTrigger>
                                                                        </InputWrapper>
                                                                        <CalendarPopoverContent
                                                                            align={"end"}
                                                                            className={"p-2"}
                                                                        >
                                                                            <Calendar />
                                                                        </CalendarPopoverContent>
                                                                    </CalendarPopover>
                                                                </DatePicker>
                                                            </FormControl>
                                                            <FormMessage />
                                                        </FormItem>
                                                    )}
                                                />
                                            </div>
                                        </div>

                                        {/* Omit holidays */}
                                        <div className={"flex gap-3 px-3 md:px-4"}>
                                            <MeetingFormContentLabelIcon>
                                                <CalendarX />
                                            </MeetingFormContentLabelIcon>
                                            <FormField
                                                name={"excludeHolidays"}
                                                control={form.control}
                                                render={({ field }) => (
                                                    <FormItem
                                                        className={
                                                            "flex flex-row items-start gap-2"
                                                        }
                                                    >
                                                        <FormControl>
                                                            <Checkbox
                                                                checked={field.value}
                                                                onCheckedChange={field.onChange}
                                                            />
                                                        </FormControl>
                                                        <div className="grid grow gap-2">
                                                            <FormLabel>Pomiń dni wolne</FormLabel>
                                                            <p className="text-text-tertiary text-xs">
                                                                Pozwoli uniknąć tworzenie spotkań w
                                                                czasie świąt oraz innych dni wolnych
                                                            </p>
                                                        </div>
                                                    </FormItem>
                                                )}
                                            />
                                        </div>

                                        {/* Schedule Elements */}
                                        <div className={"flex gap-3 px-3 md:px-4"}>
                                            <div className={"w-11 min-w-11"} />
                                            <Accordion
                                                type={"single"}
                                                className={"w-full space-y-2"}
                                            >
                                                {fields.map((item, index) => {
                                                    return (
                                                        <MeetingFormContentScheduleElement
                                                            key={item.id}
                                                            form={form}
                                                            index={index}
                                                            remove={remove}
                                                        />
                                                    );
                                                })}
                                                {fields.length === 0 && (
                                                    <p
                                                        className={
                                                            "text-xs my-11 text-text-tertiary w-full text-center"
                                                        }
                                                    >
                                                        Brak spotkań w harmonogramie dodaj klikając
                                                        w przycisk poniżej
                                                    </p>
                                                )}
                                                <div className={"flex justify-end"}>
                                                    <Button
                                                        type={"button"}
                                                        className={"ml-auto"}
                                                        variant={"ghost"}
                                                        onClick={onNewScheduleEntityAppend}
                                                    >
                                                        <Plus />
                                                        Dodaj
                                                    </Button>
                                                </div>
                                            </Accordion>
                                        </div>
                                    </>
                                )}

                                <Separator />

                                {/* Users list */}
                                <div className={"flex gap-3 px-3 md:px-4"}>
                                    <MeetingFormContentLabelIcon>
                                        <User />
                                    </MeetingFormContentLabelIcon>
                                    <div className={"w-full space-y-2"}>
                                        <div className={"flex justify-between"}>
                                            <h5 className={"font-medium"}>Użytkownicy:</h5>
                                            <Button
                                                size="sm"
                                                variant="tertiary"
                                                onClick={() => setTabs("usersSelect")}
                                            >
                                                <Plus />
                                                Dodaj
                                            </Button>
                                        </div>
                                        <div className="flex flex-wrap gap-1">
                                            {users.map(({ id, name, surname, avatarURL }) => (
                                                <Tag
                                                    key={id}
                                                    onRemove={() => onRemoveUser(id)}
                                                    variant={"muted"}
                                                    border
                                                >
                                                    <Avatar size={"xs"}>
                                                        <AvatarImage
                                                            src={getAvatar(avatarURL)}
                                                            alt={name + "_" + surname}
                                                        />
                                                        <AvatarFallback>
                                                            <User />
                                                        </AvatarFallback>
                                                    </Avatar>
                                                    {name} {surname.at(0)}.
                                                </Tag>
                                            ))}
                                            {users.length === 0 && (
                                                <p
                                                    className={
                                                        "w-full text-center p-4 text-text-tertiary text-sm"
                                                    }
                                                >
                                                    Brak wybranych użytkowników
                                                </p>
                                            )}
                                        </div>
                                    </div>
                                </div>

                                {/* Groups list */}
                                <div className={"flex gap-3 px-3 md:px-4"}>
                                    <MeetingFormContentLabelIcon>
                                        <Users />
                                    </MeetingFormContentLabelIcon>
                                    <div className={"w-full space-y-2"}>
                                        <div className={"flex justify-between"}>
                                            <h5 className={"font-medium"}>Grupy:</h5>
                                            <Button
                                                size="sm"
                                                variant="tertiary"
                                                onClick={() => setTabs("groupsSelect")}
                                            >
                                                <Plus />
                                                Dodaj
                                            </Button>
                                        </div>
                                        <div className="flex flex-wrap gap-1">
                                            {groups.map(({ id, name, avatarURL }) => (
                                                <Tag
                                                    key={id}
                                                    onRemove={() => onRemoveGroup(id)}
                                                    variant={"muted"}
                                                    border
                                                >
                                                    <Avatar size={"xs"}>
                                                        <AvatarImage
                                                            src={getAvatar(avatarURL)}
                                                            alt={name}
                                                        />
                                                        <AvatarFallback>
                                                            <Users />
                                                        </AvatarFallback>
                                                    </Avatar>
                                                    {name}
                                                </Tag>
                                            ))}
                                            {groups.length === 0 && (
                                                <p
                                                    className={
                                                        "w-full text-center p-4 text-text-tertiary text-sm"
                                                    }
                                                >
                                                    Brak wybranych grup
                                                </p>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </Form>
                    </TabsContent>
                    <TabsContent value={"usersSelect"} className={"p-3 md:p-4"}>
                        <UsersSelectTab
                            selected={users}
                            setSelected={handleSelectedUsersChange}
                            searchValue={searchDebouncedValue}
                            enabled={isUsersSelectTab}
                        />
                    </TabsContent>
                    <TabsContent value={"groupsSelect"} className={"p-3 md:p-4"}>
                        <GroupsSelectTab
                            selected={groups}
                            setSelected={handleSelectedGroupsChange}
                            searchValue={searchDebouncedValue}
                            enabled={isGroupsSelectTab}
                        />
                    </TabsContent>
                </Tabs>
            </DialogBody>
            <DialogFooter className={cn("border-t border-border-primary", !isMainTab && "hidden")}>
                <Button
                    onClick={onClose}
                    type={"button"}
                    variant={"tertiary"}
                    className={"w-full md:w-fit"}
                >
                    Anuluj
                </Button>
                <Button
                    onClick={form.handleSubmit(onSubmit, errors => console.log("ERRORS: ", errors))}
                    type={"submit"}
                    variant={"primary"}
                    className={"w-full md:w-fit"}
                >
                    <CheckCircle2 />
                    Zapisz
                </Button>
            </DialogFooter>
        </>
    );
}
