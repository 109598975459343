import { cn } from "@/lib/utils";
import { VariantProps, cva } from "class-variance-authority";
import React, { forwardRef, type HTMLAttributes } from "react";

const hyperlinkVariants = cva(
    "relative flex gap-2 truncate cursor-pointer group items-center transition-all duration-400 ease-in-out hover:opacity-90 disabled:opacity-50 outline-hidden focus-visible:outline-hidden focus-visible:underline hover:underline disabled:cursor-not-allowed",
    {
        variants: {
            variant: {
                brand: "text-text-brand",
                default: "text-text-primary",
            },
            size: {
                sm: "text-sm gap-1",
                md: "text-md",
                lg: "text-lg",
            },
        },
        defaultVariants: {
            variant: "default",
            size: "md",
        },
    },
);

interface linkProps
    extends VariantProps<typeof hyperlinkVariants>,
        HTMLAttributes<HTMLAnchorElement> {
    children: React.ReactNode;
    href: string;
    openInNewTab?: boolean;
}

const Link = forwardRef<HTMLAnchorElement, linkProps>(
    ({ className, variant, size, openInNewTab, ...props }, ref) => {
        return (
            <a
                ref={ref}
                target={openInNewTab ? "_blank" : undefined}
                className={cn(hyperlinkVariants({ variant, size }), className)}
                {...props}
            />
        );
    },
);

Link.displayName = "Link";

export { Link };
