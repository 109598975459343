import ChatEditName from "@/components/features/chat/form/ChatEditName";
import ChatUsersEdit from "@/components/features/chat/form/ChatUsersEdit";
import DeleteChat from "@/components/features/chat/form/DeleteChat";
import { Button } from "@/components/ui/button/Button";
import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuGroup,
    DropdownMenuItem,
    DropdownMenuSeparator,
    DropdownMenuTrigger,
} from "@/components/ui/input/dropdown-menu/DropdownMenu";
import { useCredentials } from "@/store/authStore";
import type { ConversationType } from "@/types";
import { ArrowLeft, MoreVertical, TextCursorInput, Trash2, UserPlus, User } from "lucide-react";
import { Fragment, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Avatar, AvatarFallback, AvatarImage } from "@/components/ui/avatar/Avatar";
import { useMediaQuery } from "@/hooks/useMediaQuery";
import { getAvatar } from "@/utils/getAvatar";

interface ChatConversationHeaderProps {
    id: number;
    name: string;
    avatarURL?: string;
    conversationType: ConversationType;
}

export default function ChatHeader({
    id,
    name,
    avatarURL,
    conversationType,
}: ChatConversationHeaderProps) {
    const navigate = useNavigate();
    const { lvl } = useCredentials();
    const breakpoint = useMediaQuery("sm");

    const [openEditName, setOpenEditName] = useState<boolean>(false);
    const [openEditUsers, setOpenEditUsers] = useState<boolean>(false);
    const [openDelete, setOpenDelete] = useState<boolean>(false);

    const isGreaterThanUser = lvl > 0;

    const handleGoBack = () => {
        navigate("/chat");
    };

    return (
        <>
            {isGreaterThanUser ? (
                <>
                    <ChatUsersEdit id={id} open={openEditUsers} onOpenChange={setOpenEditUsers} />
                    <ChatEditName
                        id={id}
                        name={name}
                        open={openEditName}
                        onOpenChange={setOpenEditName}
                    />
                    <DeleteChat
                        name={name}
                        id={id}
                        open={openDelete}
                        onOpenChange={setOpenDelete}
                    />
                </>
            ) : null}
            <header className="relative items-center gap-3 p-3 md:p-4 border-border-primary border-b">
                <div className={"flex h-10 items-center gap-1 sm:gap-2"}>
                    <Button
                        size={"sm"}
                        variant={"ghost"}
                        onClick={handleGoBack}
                        className="lg:hidden"
                    >
                        <ArrowLeft />
                    </Button>
                    <Avatar size={breakpoint ? "md" : "sm"}>
                        <AvatarImage src={getAvatar(avatarURL)} alt={name} />
                        <AvatarFallback>
                            <User />
                        </AvatarFallback>
                    </Avatar>

                    <h2
                        className={
                            "w-full truncate whitespace-nowrap font-medium text-text-primary"
                        }
                    >
                        {name}
                    </h2>
                    {isGreaterThanUser && (
                        <DropdownMenu>
                            <DropdownMenuTrigger asChild>
                                <Button size={"sm"} variant={"ghost"}>
                                    <MoreVertical />
                                </Button>
                            </DropdownMenuTrigger>
                            <DropdownMenuContent align={"end"}>
                                {conversationType === "mixed" && (
                                    <Fragment>
                                        <DropdownMenuGroup>
                                            <DropdownMenuItem onClick={() => setOpenEditName(true)}>
                                                <TextCursorInput />
                                                Zmień nazwę
                                            </DropdownMenuItem>
                                            <DropdownMenuItem
                                                onClick={() => setOpenEditUsers(true)}
                                            >
                                                <UserPlus />
                                                Edytuj uczestników
                                            </DropdownMenuItem>
                                        </DropdownMenuGroup>
                                        <DropdownMenuSeparator />
                                    </Fragment>
                                )}

                                <DropdownMenuGroup>
                                    <DropdownMenuItem
                                        className={"text-text-destructive"}
                                        onClick={() => setOpenDelete(true)}
                                    >
                                        <Trash2 />
                                        Usuń
                                    </DropdownMenuItem>
                                </DropdownMenuGroup>
                            </DropdownMenuContent>
                        </DropdownMenu>
                    )}
                </div>
            </header>
        </>
    );
}
