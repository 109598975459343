import ConversationsList from "@/components/features/chat/layout/chat-conversations/ConversationsList";
import CreateChat from "@/components/features/chat/form/CreateChat";
import Chat from "@/components/features/chat/layout/Chat";
import ChatIndexEmpty from "@/components/features/chat/layout/ChatIndexEmpty";
import { useMediaQuery } from "@/hooks/useMediaQuery";
import { useCallback, useState } from "react";
import { Navigate, Route, Routes, useLocation } from "react-router";

interface ChatContentProps {
    onOpenCreate: () => void;
}

function ChatContent({ onOpenCreate }: ChatContentProps) {
    const breakpoint = useMediaQuery("lg");

    if (breakpoint) {
        return <ChatIndexEmpty openCreateConversation={onOpenCreate} />;
    }
    return <ConversationsList openCreateConversation={onOpenCreate} />;
}

interface ChatSidebarProps {
    onOpenCreate: () => void;
}

function ChatSidebar({ onOpenCreate }: ChatSidebarProps) {
    const breakpoint = useMediaQuery("lg");

    if (breakpoint) {
        return <ConversationsList openCreateConversation={onOpenCreate} />;
    }
    return null;
}

function ChatWrapper() {
    const location = useLocation();

    if (!location.state) {
        return <Navigate to="/chat" replace />;
    }

    return <Chat />;
}

export default function ChatPage() {
    const [openCreate, setOpenCreate] = useState<boolean>(false);

    const handleOpenCreateConversation = useCallback(() => {
        setOpenCreate(true);
    }, []);

    return (
        <div className="base-direction-row">
            <CreateChat open={openCreate} onOpenChange={setOpenCreate} />
            <ChatSidebar onOpenCreate={handleOpenCreateConversation} />
            <Routes>
                <Route
                    index
                    element={<ChatContent onOpenCreate={handleOpenCreateConversation} />}
                />
                <Route path={":id"} element={<ChatWrapper />} />
            </Routes>
        </div>
    );
}
