import { axiosInstance } from "@/api/api";
import { GetMeetingsProps } from "@/api/endpoints/meetings";
import { GroupAddSchemaType } from "@/schemas/group.schema";
import { GroupEditType } from "@/schemas/group.schema";
import { MeetingPublic, UserPublic, GroupPrivate } from "@/types";
import { IdCardIcon } from "lucide-react";

export interface GetAssignedGroupsInfiniteQueryProps {
    name?: string;
}

export interface GetGroupsInfiniteQueryProps {
    name?: string;
    enabled?: boolean;
}

export interface GroupAvatarUploadParams {
    b64: Blob;
    id: number;
}

export const getGroups = async (params?: {
    page?: number;
    pageSize?: number;
    name?: string;
}): Promise<GroupPrivate[]> => {
    return await axiosInstance.get("/api/group/getAll", {
        params: params,
    });
};

export const getAssignedGroups = async (params?: {
    page?: number;
    pageSize?: number;
    name?: string;
}): Promise<GroupPrivate[]> => {
    return await axiosInstance.get("/api/group/getAssigned", {
        params: params,
    });
};

export const getGroup = async (id?: number): Promise<GroupPrivate> => {
    return await axiosInstance.get("/api/group", { params: { id } });
};

export const getGroupUsers = async (id?: number): Promise<UserPublic[]> => {
    return await axiosInstance.get("/api/group/getUsers", { params: { id } });
};

export const getGroupMeetings = async (params: GetMeetingsProps): Promise<MeetingPublic[]> => {
    return await axiosInstance.get("/api/group/getMeetings", {
        params: params,
    });
};

export const addGroup = async (data: GroupAddSchemaType): Promise<GroupPrivate> => {
    return await axiosInstance.post("/api/group", data);
};

export const editGroup = async (data: GroupEditType): Promise<GroupPrivate> => {
    return await axiosInstance.put("/api/group", data);
};

export const deleteGroup = async (id: number): Promise<string> => {
    return await axiosInstance.delete("/api/group/", { data: { id: id } });
};

export const postGroupAvatar = async ({ b64, id }: GroupAvatarUploadParams): Promise<string> => {
    const formData = new FormData();
    formData.set("avatar", b64);
    formData.set("id", id.toString());
    return await axiosInstance.post("/api/group/avatar", formData, {
        params: { id: id },
        headers: { "Content-Type": "multipart/form-data" },
    });
};

export const deleteGroupAvatar = async (id: number): Promise<string> => {
    return await axiosInstance.delete("/api/group/avatar", { data: { id: id } });
};
