import { Button } from "@/components/ui/button/Button";
import { cn } from "@/lib/utils";
import { FileUp, ImagePlus, Video } from "lucide-react";
import { ChangeEvent, ReactNode, useRef } from "react";

interface FileInputProps {
    className?: string;
    onAddFiles: (e: ChangeEvent<HTMLInputElement>) => void;
    children: ReactNode;
}

export default function FileInput({ onAddFiles, children, className }: FileInputProps) {
    const attachmentsInputRef = useRef<HTMLInputElement>(null);

    const imagesTrigger = () => {
        if (attachmentsInputRef.current) {
            attachmentsInputRef.current.accept =
                "image/jpeg, image/png, image/gif, image/svg+xml, image/webp";
            attachmentsInputRef.current.click();
        }
    };
    const videoTrigger = () => {
        if (attachmentsInputRef.current) {
            attachmentsInputRef.current.accept =
                "video/mp4, video/webm, video/3gp, video/ogg, video/avi, video/mpeg audio/mpeg, audio/ogg, audio/3gp, audio/mp4, audio/webm, audio/flac, audio/object";
            attachmentsInputRef.current.click();
        }
    };

    const allFilesTrigger = () => {
        if (attachmentsInputRef.current) {
            attachmentsInputRef.current.accept = "*";
            attachmentsInputRef.current.click();
        }
    };

    return (
        <fieldset
            className={cn(
                "relative flex flex-col gap-3 w-full rounded-md border border-border-primary p-3",
                className,
            )}
        >
            <input
                ref={attachmentsInputRef}
                onChange={onAddFiles}
                multiple
                className={"hidden"}
                type={"file"}
            />
            <div className={"flex justify-between gap-2"}>
                <div className={"flex w-full justify-end gap-2"}>
                    <Button
                        type={"button"}
                        size={"sm"}
                        variant={"tertiary"}
                        onClick={imagesTrigger}
                    >
                        <ImagePlus />
                    </Button>
                    <Button type={"button"} size={"sm"} variant={"tertiary"} onClick={videoTrigger}>
                        <Video />
                    </Button>
                    <Button
                        type={"button"}
                        size={"sm"}
                        variant={"tertiary"}
                        onClick={allFilesTrigger}
                    >
                        <FileUp />
                    </Button>
                </div>
            </div>
            <div className="w-full grid grid-cols-[repeat(auto-fit,minmax(6rem,1fr))] grid-rows-[auto] gap-2 empty:hidden">
                {children}
            </div>
        </fieldset>
    );
}
