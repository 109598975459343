import { axiosInstance } from "@/api/api";
import { EditFileType } from "@/schemas/files.schema";
import type { FilePrivate } from "@/types";
import { AxiosProgressEvent } from "axios";
import { GroupPermissions, UserPermissions } from "@/types";

export interface getFilePermissionsResponse {
    users: UserPermissions[];
    groups: GroupPermissions[];
}

export interface FileUploadParams {
    directoryId: string;
    file: File;
    tempUUID?: string;
    progressCallBack?: (progressEvent: AxiosProgressEvent) => void;
    abortController?: AbortController;
}

export const getFile = async (id?: string): Promise<FilePrivate> => {
    return await axiosInstance.get("/api/fs/file", { params: { id } });
};

export const getFilePermissions = async (id?: string): Promise<getFilePermissionsResponse> => {
    return await axiosInstance.get("/api/fs/file/getPermissions", {
        params: { id },
    });
};

export const uploadFile = async ({
    directoryId,
    file,
    abortController,
    progressCallBack,
}: FileUploadParams): Promise<FilePrivate> => {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("directoryId", directoryId);
    return await axiosInstance.post("/api/fs/file", formData, {
        headers: { "Content-Type": "multipart/form-data" },
        onUploadProgress: (progressEvent: AxiosProgressEvent) => {
            progressCallBack?.(progressEvent);
        },
        signal: abortController?.signal,
    });
};

export const editFile = async (data: EditFileType): Promise<string> => {
    return await axiosInstance.put("/api/fs/file", data);
};

export const deleteFile = async (id: string): Promise<string> => {
    return await axiosInstance.delete("/api/fs/file", { data: { id } });
};
