import UserMassImport from "@/components/features/manage/manage-users/UserMassImport";
import UserMassImportContent from "@/components/features/manage/manage-users/UserMassImportContent";
import { UserMassImportError } from "@/components/features/manage/manage-users/UserMassImportError";
import TanstackTableDynamic from "@/components/features/manage/tanstack-table-dynamic/TanstackTableDynamic";
import TanstackTableDynamicCell from "@/components/features/manage/tanstack-table-dynamic/TanstackTableDynamicCell";
import { TanstackTableDynamicEditCell } from "@/components/features/manage/tanstack-table-dynamic/TanstackTableDynamicEditCell";
import { Badge } from "@/components/ui/badge/Badge";
import {
    Breadcrumb,
    BreadcrumbItem,
    BreadcrumbLink,
    BreadcrumbList,
    BreadcrumbPage,
    BreadcrumbSeparator,
} from "@/components/ui/breadcrumb/Breadcrumb";
import { Button } from "@/components/ui/button/Button";
import { Separator } from "@/components/ui/separator/Separator";
import { ColumnDef, CellContext } from "@tanstack/react-table";
import { format, isValid } from "date-fns";
import { Check, ChevronLeft, HelpCircle, Import } from "lucide-react";
import { useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import { ValidationError } from "yup";
import { UserMassImportSchema } from "@/schemas/user.schema";
import UserMassImportInfo from "@/components/features/manage/manage-users/UserMassImportInfo";

export interface UsersMassImportRowT {
    id: string;
    lvl: string;
    name: string;
    surname: string;
    email: string;
    phone: string;
    birthdate: string;
}

export interface ErrorsT {
    errors: string[];
    data: UsersMassImportRowT;
    index: number;
}

export default function ManageUsersMassImport() {
    const navigate = useNavigate();

    const [openSubmitDialog, setOpenSubmitDialog] = useState<boolean>(false);
    const [openErrorDialog, setOpenErrorDialog] = useState<boolean>(false);
    const [openImportDialog, setOpenImportDialog] = useState<boolean>(false);
    const [openInfoDialog, setOpenInfoDialog] = useState<boolean>(false);

    const [userList, setUserList] = useState<UsersMassImportRowT[]>([]);
    const [errors, setErrors] = useState<ErrorsT[]>([]);

    const handleOpenImportDialog = () => {
        setOpenImportDialog(true);
    };

    const handleOpenInfoDialog = () => {
        setOpenInfoDialog(true);
    };
    const handleSaveChanges = () => {
        const isError = checkErrors();
        if (isError) {
            setOpenErrorDialog(true);
        } else {
            setOpenSubmitDialog(true);
        }
    };

    const checkErrors = () => {
        const errorsArray: ErrorsT[] = [];
        userList.forEach((user, index) => {
            try {
                UserMassImportSchema.validateSync(user, { abortEarly: false });
            } catch (error) {
                if (error instanceof ValidationError) {
                    errorsArray.push({ errors: error.errors, data: user, index: index });
                }
            }
        });
        setErrors(errorsArray);
        return errorsArray.length !== 0;
    };

    const columns: ColumnDef<UsersMassImportRowT>[] = useMemo(
        () => [
            {
                id: "lvl",
                accessorKey: "lvl",
                cell: (props: CellContext<UsersMassImportRowT, unknown>) => (
                    <TanstackTableDynamicCell
                        {...props}
                        placeholder={"Stanowisko"}
                        type={"select"}
                        options={[
                            { value: "0", label: "Uczeń" },
                            { value: "1", label: "Nauczyciel" },
                            { value: "2", label: "Administrator" },
                        ]}
                        viewCell={() => {
                            const value = props.getValue();

                            if (value === "1") {
                                return <Badge variant={"turquoise"}>Nauczyciel</Badge>;
                            }
                            if (value === "2") {
                                return <Badge variant={"purple"}>Administrator</Badge>;
                            }

                            return <Badge variant={"blue"}>Uczeń</Badge>;
                        }}
                    />
                ),
                header: "Rola",
            },
            {
                id: "name",
                accessorKey: "name",
                cell: (props: CellContext<UsersMassImportRowT, unknown>) => (
                    <TanstackTableDynamicCell {...props} type={"text"} placeholder={"Imię"} />
                ),
                header: "Imię",
            },
            {
                id: "surname",
                accessorKey: "surname",
                cell: (props: CellContext<UsersMassImportRowT, unknown>) => (
                    <TanstackTableDynamicCell {...props} placeholder={"Nazwisko"} type={"text"} />
                ),
                header: "Nazwisko",
            },
            {
                id: "email",
                accessorKey: "email",
                cell: (props: CellContext<UsersMassImportRowT, unknown>) => (
                    <TanstackTableDynamicCell {...props} placeholder={"Email"} type={"email"} />
                ),
                header: "Email",
            },
            {
                id: "phone",
                accessorKey: "phone",
                cell: (props: CellContext<UsersMassImportRowT, unknown>) => (
                    <TanstackTableDynamicCell {...props} placeholder={"Nr. tel"} type={"phone"} />
                ),
                header: "Nr.tel",
            },
            {
                id: "birthdate",
                accessorKey: "birthdate",
                cell: (props: CellContext<UsersMassImportRowT, unknown>) => (
                    <TanstackTableDynamicCell
                        {...props}
                        type={"date"}
                        viewCell={() => {
                            const date = props.getValue();
                            if (typeof date === "string" && isValid(new Date(date))) {
                                return format(new Date(date), "dd.MM.yyyy");
                            }
                            if (date === undefined || date === "") {
                                return "";
                            }
                            return "Invalid date format";
                        }}
                    />
                ),
                header: "Data ur.",
            },
            {
                id: "actions",
                enableHiding: false,
                enableSorting: false,
                cell: ({ row }) => <TanstackTableDynamicEditCell row={row} />,
            },
        ],
        [],
    );

    return (
        <>
            <UserMassImportContent
                open={openImportDialog}
                onOpenChange={setOpenImportDialog}
                setData={setUserList}
            />
            <UserMassImport
                data={userList}
                open={openSubmitDialog}
                onOpenChange={setOpenSubmitDialog}
            />
            <UserMassImportError
                errors={errors}
                open={openErrorDialog}
                onOpenChange={setOpenErrorDialog}
            />
            <UserMassImportInfo open={openInfoDialog} onOpenChange={setOpenInfoDialog} />
            <div className={"base-direction-col"}>
                <div className={"flex flex-col gap-4 w-full viewport-height"}>
                    <header
                        className={
                            "bg-surface-primary flex flex-col rounded-lg border border-border-primary"
                        }
                    >
                        <div className={"flex w-full items-center justify-between p-3 md:p-4"}>
                            <div className={"flex items-center gap-1"}>
                                <Button
                                    size={"sm"}
                                    variant={"outline"}
                                    onClick={() => void navigate("/manage/users")}
                                >
                                    <ChevronLeft />
                                </Button>
                                <Breadcrumb>
                                    <BreadcrumbList>
                                        <BreadcrumbItem>
                                            <BreadcrumbLink href="/manage/users">
                                                Użytkownicy
                                            </BreadcrumbLink>
                                        </BreadcrumbItem>
                                        <BreadcrumbSeparator />
                                        <BreadcrumbItem>
                                            <BreadcrumbPage>Masowy import</BreadcrumbPage>
                                        </BreadcrumbItem>
                                    </BreadcrumbList>
                                </Breadcrumb>
                            </div>
                            <Button onClick={handleSaveChanges} disabled={!userList.length}>
                                <Check />
                                Zapisz
                            </Button>
                        </div>
                        <Separator />
                        <div className={"w-full flex justify-between gap-3 p-3 md:p-4"}>
                            <Button
                                variant={"outline"}
                                className={"w-fit"}
                                onClick={handleOpenImportDialog}
                            >
                                <Import />
                                Importuj dane
                            </Button>
                            <Button variant={"outline"} onClick={handleOpenInfoDialog}>
                                <HelpCircle />
                            </Button>
                        </div>
                    </header>

                    <TanstackTableDynamic
                        data={userList}
                        setData={setUserList}
                        columns={columns}
                        newRowTemplate={{
                            id: uuidv4(),
                            lvl: "0",
                            name: "",
                            surname: "",
                            email: "",
                            phone: "",
                            birthdate: "",
                        }}
                    />
                </div>
            </div>
        </>
    );
}
