import { cn } from "@/lib/utils";
import { VariantProps, cva } from "class-variance-authority";
import { useMemo } from "react";
import * as React from "react";

const spinnerVariants = cva(
    "absolute w-full h-full rounded-full border-3 border-t-transparent border-l-transparent border-r-transparent",
    {
        variants: {
            variant: {
                brand: "border-b-fg-brand",
                "on-brand": "border-b-fg-brand-on",
                accent: "border-b-fg-accent",
                "on-accent": "border-b-fg-accent-on",
                muted: "border-b-fg-muted",
                "on-muted": "border-b-fg-primary",
                success: "border-b-fg-success",
                "on-success": "border-b-fg-success-on",
                warning: "border-b-fg-warning",
                "on-warning": "border-b-fg-warning-on",
                destructive: "border-b-fg-destructive",
                "on-destructive": "border-b-fg-destructive-on",
            },
        },
        defaultVariants: {
            variant: "brand",
        },
    },
);
const spinnerVariantsSize = cva("", {
    variants: {
        size: {
            sm: "w-4 h-4",
            md: "w-5 h-5",
            lg: "w-6 h-6",
        },
    },
    defaultVariants: {
        size: "md",
    },
});

const spinnerVariantsLabel = cva("", {
    variants: {
        size: {
            sm: "text-sm",
            md: "text",
            lg: "text-lg",
        },
    },
    defaultVariants: {
        size: "md",
    },
});

export interface SpinnerProps
    extends React.ComponentPropsWithoutRef<"div">,
        VariantProps<typeof spinnerVariants>,
        VariantProps<typeof spinnerVariantsLabel>,
        VariantProps<typeof spinnerVariantsSize> {
    children?: React.ReactNode;
    label?: string;
    className?: string;
}

function Spinner({ label, variant, size, className, children, ...props }: SpinnerProps) {
    const labelProp = label || children;

    const ariaLabel = useMemo(() => {
        if (labelProp && typeof labelProp === "string") {
            return labelProp;
        }
        return "";
    }, [children, label, props["aria-label"]]);

    return (
        <div
            className={cn("relative inline-flex flex-col items-center gap-2", className)}
            {...props}
        >
            <div className={cn(spinnerVariantsSize({ size }), "relative flex")}>
                <i
                    className={cn(
                        spinnerVariantsSize({ size }),
                        spinnerVariants({ variant }),
                        "animate-spinner-ease-spin border-solid",
                    )}
                />
                <i
                    className={cn(
                        spinnerVariantsSize({ size }),
                        spinnerVariants({ variant }),
                        "animate-spinner-linear-spin border-dotted opacity-75",
                    )}
                />
            </div>
            {label && (
                <span aria-label={ariaLabel} className={spinnerVariantsLabel({ size })}>
                    {label}
                </span>
            )}
        </div>
    );
}

Spinner.displayName = "Spinner";
export { Spinner, spinnerVariants, spinnerVariantsSize, spinnerVariantsLabel };
