import { httpErrorHandler } from "@/api/api";
import { useEditPostMutation } from "@/api/queries/postsQueries";
import { Alert, AlertDescription } from "@/components/ui/alert/Alert";
import { Button } from "@/components/ui/button/Button";
import {
    AlertDialog,
    AlertDialogBody,
    AlertDialogCancel,
    AlertDialogContent,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogTitle,
} from "@/components/ui/dialog/AlertDialog";
import { FeaturedIcon } from "@/components/ui/featured-icon/FeaturedIcon";
import { motion } from "framer-motion";
import { AlertCircle, BookText, LoaderCircle } from "lucide-react";
import type { PostPublic } from "@/types";

type ActivatePostProps = {
    post?: PostPublic;
    open: boolean;
    onOpenChange: (value: boolean) => void;
};

export default function ActivatePost({ post, open, onOpenChange }: ActivatePostProps) {
    const { mutateAsync, isPending, isError, error } = useEditPostMutation();

    const onSubmit = () => {
        if (post) {
            mutateAsync({ id: post.id, active: !post.active }).then(() => {
                onOpenChange(false);
            });
        }
    };
    const { detail } = httpErrorHandler(error);

    return (
        <AlertDialog open={open} onOpenChange={onOpenChange}>
            <AlertDialogContent className={"sm:max-w-96"}>
                <AlertDialogBody>
                    <AlertDialogHeader>
                        <FeaturedIcon variant={"ghost"}>
                            <BookText />
                        </FeaturedIcon>
                        <div className={"flex flex-col gap-0.5 min-w-0 grow"}>
                            <AlertDialogTitle>Zmienić aktywność posta ?</AlertDialogTitle>
                            <p className={"text-text-tertiary text-xs"}>
                                Zmień akywność posta aby go ukryć / pokazać.
                            </p>
                        </div>
                    </AlertDialogHeader>
                    <div className={"flex flex-col p-3 md:p-4"}>
                        <p className={"text-sx text-text-tertiary"}>
                            Czy na pewno chcesz zmienić aktywność posta <b>{post?.name} ?</b>
                        </p>
                        <motion.div
                            initial={{ opacity: 0, height: 0 }}
                            animate={{
                                opacity: isError ? 1 : 0,
                                height: isError ? "auto" : 0,
                            }}
                            transition={{ duration: 0.2 }}
                            className={"overflow-hidden px-6 py-4 sm:px-4"}
                        >
                            <Alert variant={"destructive"}>
                                <FeaturedIcon>
                                    <AlertCircle />
                                </FeaturedIcon>
                                <AlertDescription>{detail}</AlertDescription>
                            </Alert>
                        </motion.div>
                    </div>
                </AlertDialogBody>
                <AlertDialogFooter>
                    <AlertDialogCancel variant={"tertiary"}>Anuluj</AlertDialogCancel>
                    <Button disabled={isPending} onClick={onSubmit}>
                        {isPending ? (
                            <>
                                <LoaderCircle className={"animate-spin"} aria-hidden={"true"} />
                                Ładowanie...
                            </>
                        ) : (
                            "Potwierdzam"
                        )}
                    </Button>
                </AlertDialogFooter>
            </AlertDialogContent>
        </AlertDialog>
    );
}
