import { httpErrorHandler } from "@/api/api";
import { PostMeetingProps, PostMeetingScheduleProps } from "@/api/endpoints/meetings";
import {
    usePostMeetingMutation,
    usePostScheduleMeetingMutation,
} from "@/api/queries/meetingsQuery";
import MeetingFormContent from "@/components/features/calendar/form/meeting-form-content/MeetingFormContent";
import { convertFormToData } from "@/components/features/calendar/form/meeting-form-content/convert-data";
import { Dialog, DialogContent, DialogDescription } from "@/components/ui/dialog/Dialog";
import { CalendarFormSchemaType } from "@/schemas/meeting.schema";
import { useCallback, type Dispatch, type SetStateAction } from "react";
import { useLocation, useNavigate } from "react-router";
import { LoaderCircle } from "lucide-react";
import { toast } from "sonner";

interface CreateMeetingProps {
    open: boolean;
    onOpenChange: Dispatch<SetStateAction<boolean>>;
    defaultValues?: Partial<CalendarFormSchemaType>;
}

export default function CreateMeeting({ open, onOpenChange, defaultValues }: CreateMeetingProps) {
    console.log("defaultValues;", defaultValues);
    const navigate = useNavigate();
    const location = useLocation();

    const { mutateAsync: MeetingMutate, isPending: MeetingPending } = usePostMeetingMutation();
    const { mutateAsync: MeetingScheduleMutate, isPending: MeetingSchedulePending } =
        usePostScheduleMeetingMutation();

    const isPending = MeetingPending || MeetingSchedulePending;

    const clearLocationState = useCallback(() => {
        navigate({ pathname: location.pathname, search: location.search }, { replace: true });
    }, [navigate, location.pathname, location.search]);

    const onSubmit = (data: CalendarFormSchemaType) => {
        if (data.method === "once") {
            const meeting = convertFormToData(data) as PostMeetingProps;
            MeetingMutate(meeting)
                .then(() => {
                    onClose();
                })
                .catch(error => {
                    const { title, detail } = httpErrorHandler(error);
                    toast.error(title, {
                        description: detail,
                    });
                });
        } else {
            const meeting = convertFormToData(data) as PostMeetingScheduleProps;
            MeetingScheduleMutate(meeting)
                .then(() => {
                    onClose();
                })
                .catch(error => {
                    const { title, detail } = httpErrorHandler(error);
                    toast.error(title, {
                        description: detail,
                    });
                });
        }
    };

    const onClose = () => {
        clearLocationState();
        onOpenChange(false);
    };

    return (
        <Dialog open={open} onOpenChange={onOpenChange}>
            <DialogContent
                className={"sm:max-w-148 overflow-x-hidden"}
                onCloseAutoFocus={onClose}
                onEscapeKeyDown={onClose}
                isClosable={false}
            >
                <DialogDescription className={"sr-only"}>
                    Formularz do tworzenia spotkania
                </DialogDescription>
                {isPending && (
                    <div
                        className={
                            "absolute inset-0 z-60 m-auto flex flex-wrap items-center justify-center gap-3 bg-surface-primary/75 backdrop-blur-md"
                        }
                    >
                        <LoaderCircle className={"animate-spin"} aria-hidden={"true"} />
                        <h5 className={"font-medium"}>Trwa zapisywanie zmian...</h5>
                    </div>
                )}
                <MeetingFormContent
                    callback={onSubmit}
                    onOpenChange={onOpenChange}
                    defaultValues={defaultValues}
                />
            </DialogContent>
        </Dialog>
    );
}
