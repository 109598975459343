"use no memo";

import { useCallback } from "react";
import useLongPress from "@/hooks/useLongPress";
import { useFilesStorage } from "@/store/filesStore";
import { DirectoryPublic, FilePublicWithPermissions } from "@/types";

type FSEntityType = "file" | "directory";

interface UseFSEntityInteractionProps<T extends FilePublicWithPermissions | DirectoryPublic> {
    entity: T;
    entityType: FSEntityType;
    onOpen?: () => void;
}

export const useFSEntityInteraction = <T extends FilePublicWithPermissions | DirectoryPublic>({
    entity,
    entityType,
    onOpen,
}: UseFSEntityInteractionProps<T>) => {
    const { onFSEntityClick, isSelected, selectedSize } = useFilesStorage();

    const size = selectedSize();
    const isEntitySelected = isSelected(entity.id, entityType);

    const handleClick = useCallback(
        (event: React.PointerEvent<HTMLDivElement>) => {
            const isTouch = event.nativeEvent.pointerType !== "mouse";
            if ((isTouch && size > 0) || !isTouch) {
                onFSEntityClick({ [entityType]: entity, event });
            } else if (onOpen && entity.permissions.read) {
                onOpen();
            }
        },
        [entity, entityType, size, onFSEntityClick, onOpen],
    );

    const handleKeyDown = useCallback(
        (event: React.KeyboardEvent) => {
            if (event.key === "Enter" && entity.permissions.read && onOpen) {
                onOpen();
            }
        },
        [entity, onOpen],
    );

    const handleDoubleClick = useCallback(() => {
        if (entity.permissions.read && onOpen) {
            onOpen();
        }
    }, [entity, onOpen]);

    const handleHold = useCallback(
        (event: React.PointerEvent<HTMLDivElement>) => {
            onFSEntityClick({ [entityType]: entity, event });
        },
        [onFSEntityClick, entity, entityType],
    );

    const longPressHandlers = useLongPress(handleHold, {
        preventDefault: true,
    });

    return {
        isEntitySelected,
        size,
        handleClick,
        handleKeyDown,
        handleDoubleClick,
        longPressHandlers,
    };
};
