import { Badge } from "@/components/ui/badge/Badge";
import { Button } from "@/components/ui/button/Button";
import { Link } from "@/components/ui/link/Link";
import { Skeleton } from "@/components/ui/skeleton/Skeleton";
import { cn } from "@/lib/utils";
import { NotificationPublic, NotificationType } from "@/types";
import { format, parseISO } from "date-fns";
import { pl } from "date-fns/locale";
import { Dot, LinkIcon } from "lucide-react";
import { forwardRef, useMemo } from "react";
import { useNavigate } from "react-router";

type NotificationCardProps = NotificationPublic & {
    makeRead: (id: number) => void;
};

const NotificationCard = forwardRef<HTMLDivElement, NotificationCardProps>((props, ref) => {
    const { id, name, content, url, type, makeRead, read, createdAt } = props;
    const navigate = useNavigate();
    const isExternalUrl = useMemo((): boolean => {
        if (!url) return false;
        return url.startsWith("http://") || url.startsWith("https://");
    }, [url]);

    return (
        <div
            ref={ref}
            className={cn(
                "relative space-y-3 rounded-md border border-border-primary p-2 bg-surface-primary duration-200 hover:bg-surface-primary-hover",
                read && "opacity-80",
            )}
        >
            <header className={"flex flex-col gap-1"}>
                <NotificationCardTypeBadge type={type} />
                <h5 className={cn("mr-8 truncate text-md", read ? "font-medium" : "font-semibold")}>
                    {name}
                </h5>
                <Button
                    onClick={() => makeRead(id)}
                    className={cn("absolute right-0.5 top-0.5", read && "invisible opacity-0")}
                    size={"sm"}
                    variant={"ghost"}
                >
                    <Dot className={"fill-brand stroke-fill-brand stroke-[10px]"} />
                </Button>
            </header>
            <div className={"flex flex-col gap-1"}>
                {url ? (
                    isExternalUrl ? (
                        <Link href={url} openInNewTab size={"sm"} className={"w-fit"}>
                            <LinkIcon className={"size-4"} />
                            Link
                        </Link>
                    ) : (
                        <span
                            className={
                                "flex w-fit cursor-pointer gap-1 text-sm text-text-brand hover:underline"
                            }
                            onClick={() => {
                                navigate(url);
                                makeRead(id);
                            }}
                        >
                            <LinkIcon className={"size-4"} />
                            Link
                        </span>
                    )
                ) : null}
                <p className={"line-clamp-4 text-pretty text-sm text-text-secondary"}>{content}</p>
            </div>
            <footer className={"flex justify-between text-text-tertiary"}>
                <p className={"text-xs"}>
                    {format(parseISO(createdAt), "EEEE H:mm", { locale: pl })}
                </p>
                <p className={"text-xs"}>
                    {format(parseISO(createdAt), "MMM d, yyyy", { locale: pl })}
                </p>
            </footer>
        </div>
    );
});

NotificationCard.displayName = "NotificationCard";

const NotificationCardTypeBadge = ({ type }: { type: NotificationType }) => {
    switch (type) {
        case "assignment":
            return (
                <Badge size={"sm"} variant={"muted"}>
                    Zadanie
                </Badge>
            );
        case "calendar":
            return (
                <Badge size={"sm"} variant={"muted"}>
                    Kalendarz
                </Badge>
            );
        case "chat":
            return (
                <Badge size={"sm"} variant={"muted"}>
                    Chat
                </Badge>
            );
        case "grade":
            return (
                <Badge size={"sm"} variant={"muted"}>
                    Ocena
                </Badge>
            );
        case "group":
            return (
                <Badge size={"sm"} variant={"muted"}>
                    Grupy
                </Badge>
            );
    }
};

const NotificationCardSkeleton = forwardRef<HTMLDivElement>((props, ref) => {
    return (
        <div
            ref={ref}
            {...props}
            className={
                "relative space-y-3 rounded-md border border-border bg-surface-primary p-2 transition-all"
            }
        >
            <div className="flex flex-col gap-2">
                <Skeleton className={"h-4 w-13 rounded-sm"} />
                <Skeleton className={"h-4 w-1/2 rounded-sm"} />
            </div>
            <div className="flex flex-col gap-2">
                <Skeleton className={"h-3 w-full rounded-sm"} />
                <Skeleton className={"h-3 w-2/3 rounded-sm"} />
            </div>
            <div className="flex justify-between">
                <Skeleton className={"h-4 w-12 rounded-sm"} />
                <Skeleton className={"h-4 w-13 rounded-sm"} />
            </div>
        </div>
    );
});

NotificationCardSkeleton.displayName = "NotificationCardSkeleton";

export { NotificationCard, NotificationCardSkeleton };
