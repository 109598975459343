import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuRadioGroup,
    DropdownMenuRadioItem,
    DropdownMenuTrigger,
} from "@/components/ui/input/dropdown-menu/DropdownMenu";
import { Button } from "@/components/ui/button/Button";
import { SetNavigate, VIEW_KEY } from "@/types";
import { ChevronDown } from "lucide-react";

interface CalendarToggleProps {
    viewKey: VIEW_KEY;
    setNavigate: SetNavigate;
}

export default function CalendarToggle({ viewKey, setNavigate }: CalendarToggleProps) {
    const names = {
        [VIEW_KEY.DAY]: "Dzień",
        [VIEW_KEY.WEEK]: "Tydzień",
        [VIEW_KEY.MONTH]: "Miesiąc",
    };

    const handleChange = (value: VIEW_KEY) => {
        setNavigate({ newViewKey: value });
    };

    return (
        <DropdownMenu>
            <DropdownMenuTrigger asChild className="w-fit">
                <Button variant="outline">
                    {names[viewKey]} <ChevronDown />
                </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent>
                <DropdownMenuRadioGroup onValueChange={e => handleChange(e as VIEW_KEY)}>
                    <DropdownMenuRadioItem value={VIEW_KEY.DAY}>
                        {names[VIEW_KEY.DAY]}
                    </DropdownMenuRadioItem>
                    <DropdownMenuRadioItem value={VIEW_KEY.WEEK}>
                        {names[VIEW_KEY.WEEK]}
                    </DropdownMenuRadioItem>
                    <DropdownMenuRadioItem value={VIEW_KEY.MONTH}>
                        {names[VIEW_KEY.MONTH]}
                    </DropdownMenuRadioItem>
                </DropdownMenuRadioGroup>
            </DropdownMenuContent>
        </DropdownMenu>
    );
}
