import {
    AccordionContent,
    AccordionHeader,
    AccordionItem,
    AccordionTrigger,
} from "@/components/ui/accordion/Accordion";
import { Badge } from "@/components/ui/badge/Badge";
import { Button } from "@/components/ui/button/Button";
import {
    FormControl,
    FormField,
    FormItem,
    FormMessages,
    FormLabel,
} from "@/components/ui/form/Form";
import {
    Select,
    SelectContent,
    SelectGroup,
    SelectItem,
    SelectLabel,
    SelectTrigger,
    SelectValue,
} from "@/components/ui/input/select/Select";
import { cn } from "@/lib/utils";
import { CalendarFormSchemaType } from "@/schemas/meeting.schema";
import { format } from "date-fns";
import { pl } from "date-fns/locale";
import { ArrowRight, ChevronDown, GripVertical, Trash2 } from "lucide-react";
import { useCallback, useMemo, useEffect } from "react";
import { UseFieldArrayRemove, UseFormReturn, useFormContext, useWatch } from "react-hook-form";
import { parseTime } from "@internationalized/date";
import { TimePicker } from "@/components/ui/input/date-picker/DatetimePicker";
import MeetingFormTypeRadioGroup from "./MeetingFormTypeRadioGroup";

interface CalendarScheduleElementProps {
    form: UseFormReturn<CalendarFormSchemaType>;
    remove: UseFieldArrayRemove;
    index: number;
}

export default function MeetingFormContentScheduleElement({
    form,
    remove,
    index,
}: CalendarScheduleElementProps) {
    const { setValue, control } = form;

    const {
        formState: { errors },
    } = useFormContext();

    // Watch important fields
    const type = useWatch({ control: control, name: `schedule.${index}.type` });
    const day = useWatch({ control: control, name: `schedule.${index}.day` });
    const start = useWatch({ control: control, name: `schedule.${index}.start` });
    const end = useWatch({ control: control, name: `schedule.${index}.end` });

    // Check for errors in this schedule element
    const isError = useMemo(
        () =>
            Boolean(
                errors[`schedule[${index}].end`] ||
                    errors[`schedule[${index}].start`] ||
                    errors[`schedule[${index}].day`],
            ),
        [errors, index],
    );

    // Reset fields based on type
    useEffect(() => {
        if (type === "remote") {
            setValue(`schedule.${index}.locationId`, undefined);
        } else {
            setValue(`schedule.${index}.meetingURL`, undefined);
        }
    }, [type, setValue, index]);

    // Format time to HH:mm
    const handleFormatTime = useCallback((time: string) => {
        return time.substring(0, 5);
    }, []);

    // Get day name from number
    const getDayName = useCallback((dayNumber: number) => {
        const date = new Date();
        date.setDate(date.getDate() - date.getDay() + dayNumber);
        return format(date, "EEE", { locale: pl });
    }, []);

    // Rendered content for different sections
    const AccordionSummary = useMemo(() => {
        if (isError) {
            return <p className={"text-md font-normal text-text-destructive"}>Popraw dane</p>;
        }

        return (
            <div className={"flex shrink gap-1 overflow-hidden"}>
                {day && (
                    <Badge variant={"muted"} border size={"sm"}>
                        {getDayName(Number(day))}
                    </Badge>
                )}
                <Badge variant={"muted"} border size={"sm"}>
                    {type === "remote" ? "Zdalnie" : "Stacjonarnie"}
                </Badge>
                {start && end && (
                    <Badge variant={"muted"} border size={"sm"}>
                        {handleFormatTime(start)} - {handleFormatTime(end)}
                    </Badge>
                )}
            </div>
        );
    }, [isError, day, type, start, end, getDayName, handleFormatTime]);

    return (
        <AccordionItem
            value={`Accordion.${index}`}
            className={cn(
                "group gap-4 rounded-md border border-border-primary overflow-hidden",
                isError && "border-border-destructive",
            )}
        >
            <AccordionHeader
                className={cn(
                    "flex h-fit grow border-border-primary group-data-[state=open]:border-b bg-surface-secondary",
                    isError && "group-data-[state=open]:border-border-destructive",
                )}
            >
                <AccordionTrigger
                    className={cn(
                        "peer flex w-full grow flex-row justify-start gap-3 overflow-hidden p-1 hover:no-underline",
                    )}
                    chevronHide
                >
                    <span className={"size-10 flex justify-center items-center"}>
                        <GripVertical className={"size-4 stroke-icon-tertiary"} />
                    </span>
                    {AccordionSummary}
                    <div className={"flex shrink-0 items-center ml-auto"}>
                        <ChevronDown className={"size-4 stroke-icon-tertiary"} />
                    </div>
                </AccordionTrigger>
                <div className={"flex items-center p-1"}>
                    <Button
                        type={"button"}
                        size={"sm"}
                        variant={"ghost"}
                        variantColor={"destructive"}
                        onClick={() => remove(index)}
                    >
                        <Trash2 />
                    </Button>
                </div>
            </AccordionHeader>

            <AccordionContent className={"flex flex-col gap-3 p-3 md:p-4 sm:pl-12"}>
                {/* WeekDay select */}
                <div className={"flex flex-col gap-3"}>
                    <FormField
                        control={form.control}
                        name={`schedule.${index}.day`}
                        render={({ field }) => (
                            <FormItem>
                                <FormLabel>Dzień tygodnia</FormLabel>
                                <Select onValueChange={field.onChange} value={field.value}>
                                    <FormControl>
                                        <SelectTrigger>
                                            <SelectValue placeholder={"Wybierz dzień tygodnia"} />
                                        </SelectTrigger>
                                    </FormControl>
                                    <SelectContent>
                                        <SelectGroup>
                                            <SelectLabel className="ps-2">
                                                Dzień tygodnia
                                            </SelectLabel>
                                            <SelectItem value={"1"}>Poniedziałek</SelectItem>
                                            <SelectItem value={"2"}>Wtorek</SelectItem>
                                            <SelectItem value={"3"}>Środa</SelectItem>
                                            <SelectItem value={"4"}>Czwartek</SelectItem>
                                            <SelectItem value={"5"}>Piątek</SelectItem>
                                            <SelectItem value={"6"}>Sobota</SelectItem>
                                            <SelectItem value={"7"}>Niedziela</SelectItem>
                                        </SelectGroup>
                                    </SelectContent>
                                </Select>
                            </FormItem>
                        )}
                    />
                    {/* Time start -> end */}
                    <div className={"flex gap-2 w-full"}>
                        <FormField
                            name={`schedule.${index}.start`}
                            control={form.control}
                            render={({ field }) => {
                                return (
                                    <FormItem className={"w-full"}>
                                        <FormLabel>Czas</FormLabel>
                                        <FormControl>
                                            <TimePicker
                                                value={field.value ? parseTime(field.value) : null}
                                                onChange={time => {
                                                    if (time) {
                                                        field.onChange(time.toString());
                                                    } else {
                                                        field.onChange(null);
                                                    }
                                                }}
                                                aria-label={"start-time"}
                                            />
                                        </FormControl>
                                    </FormItem>
                                );
                            }}
                        />
                        <ArrowRight className={"size-4 shrink-0 stroke-icon-tertiary mt-9"} />
                        <FormField
                            name={`schedule.${index}.end`}
                            control={form.control}
                            render={({ field }) => (
                                <FormItem className={"w-full"}>
                                    <FormLabel className={"invisible"}>Koniec</FormLabel>
                                    <FormControl>
                                        <TimePicker
                                            value={field.value ? parseTime(field.value) : null}
                                            onChange={time => {
                                                if (time) {
                                                    field.onChange(time.toString());
                                                } else {
                                                    field.onChange(null);
                                                }
                                            }}
                                            aria-label={"start-time"}
                                        />
                                    </FormControl>
                                </FormItem>
                            )}
                        />
                    </div>

                    <FormMessages
                        keys={[
                            `schedule[${index}].day`,
                            `schedule[${index}].start`,
                            `schedule[${index}].end`,
                        ]}
                        listView
                    />
                </div>

                {/* Meeting Type */}
                <MeetingFormTypeRadioGroup
                    form={form}
                    meetingURL={`schedule.${index}.meetingURL`}
                    typeName={`schedule.${index}.type`}
                />
            </AccordionContent>
        </AccordionItem>
    );
}
