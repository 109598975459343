import { httpErrorHandler } from "@/api/api";
import { useEditUserMutation } from "@/api/queries/usersQueries";
import {
    AlertDialogBody,
    AlertDialogCancel,
    AlertDialogContent,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogTitle,
    AlertDialog,
    AlertDialogAction,
} from "@/components/ui/dialog/AlertDialog";
import { FeaturedIcon } from "@/components/ui/featured-icon/FeaturedIcon";
import { Lock } from "lucide-react";
import { useEffect } from "react";
import { toast } from "sonner";
import type { UserPrivate } from "@/types";

type ResetPasswordProps = {
    user?: UserPrivate | null;
    open: boolean;
    onOpenChange: (value: boolean) => void;
};

export default function ResetPassword({ user, open, onOpenChange }: ResetPasswordProps) {
    const { mutateAsync, reset } = useEditUserMutation();

    useEffect(() => {
        if (open) {
            reset();
        }
    }, [open, reset]);

    const onSubmit = () => {
        if (user) {
            mutateAsync({
                id: user.id,
                force_password_change: true,
            }).catch(error => {
                const { title, detail } = httpErrorHandler(error);
                toast.error(title, {
                    description: detail,
                });
            });
            onClose();
        }
    };

    const onClose = () => {
        onOpenChange(false);
    };

    return (
        <AlertDialog open={open} onOpenChange={onOpenChange}>
            <AlertDialogContent className={"sm:max-w-96"}>
                <AlertDialogBody>
                    <AlertDialogHeader>
                        <FeaturedIcon variant={"ghost"}>
                            <Lock />
                        </FeaturedIcon>
                        <div className={"flex flex-col gap-0.5 min-w-0 grow"}>
                            <AlertDialogTitle>Czy chcesz resetowa hasło ?</AlertDialogTitle>
                            <p className={"text-text-tertiary text-xs"}>
                                Resetuj hasło dla wybranego/nych użytkowników.
                            </p>
                        </div>
                    </AlertDialogHeader>
                    <div className={"flex flex-col gap-3 px-3 md:px-4 overflow-auto"}>
                        <p className={"text-sx text-text-secondary"}>
                            Czy na pewno chcesz resetować hasło użytkownika{" "}
                            <b>{user?.name + " " + user?.surname}</b> ?
                        </p>
                    </div>
                </AlertDialogBody>
                <AlertDialogFooter>
                    <AlertDialogCancel variant={"tertiary"} onClick={onClose}>
                        Zamknij
                    </AlertDialogCancel>
                    <AlertDialogAction onClick={onSubmit}>Potwierdź</AlertDialogAction>
                </AlertDialogFooter>
            </AlertDialogContent>
        </AlertDialog>
    );
}
