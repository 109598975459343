import { httpErrorHandler } from "@/api/api";
import { useGetGroupQuery } from "@/api/queries/groupsQueries";
import GroupGrades from "@/components/features/groups/GroupGrades";
import GroupLessons from "@/components/features/groups/GroupLessons";
import GroupMeetings from "@/components/features/groups/GroupMeetings";
import GroupMembers from "@/components/features/groups/GroupMembers";
import GroupQuickMenu from "@/components/features/groups/GroupQuickMenu";
import GroupTasks from "@/components/features/groups/GroupTasks";
import GroupHeader from "@/components/features/groups/layout/GroupHeader";
import { cn } from "@/lib/utils";
import { useCredentials } from "@/store/authStore";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { toast } from "sonner";
import { Button } from "@/components/ui/button/Button";

export default function GroupPage() {
    const { lvl } = useCredentials();
    const { id } = useParams();
    const { data, isError, error, refetch } = useGetGroupQuery(Number(id));

    useEffect(() => {
        if (isError) {
            const { title, detail } = httpErrorHandler(error);
            toast.error(title, {
                description: detail,
                action: (
                    <Button
                        size={"sm"}
                        className={"ml-auto"}
                        variant={"tertiary"}
                        onClick={() => {
                            void refetch();
                        }}
                    >
                        Ponów
                    </Button>
                ),
            });
        }
    }, [error, isError, toast]);

    return (
        <div className={"base-direction-col relative flex flex-1 flex-col gap-4"}>
            <div className="flex flex-col lg:flex-row w-full h-fit lg:h-full gap-4">
                {/* left side desktop */}
                <div className="flex flex-col w-full gap-4">
                    <GroupHeader className={"w-full"} group={data} />
                    <div className="flex flex-col lg:flex-row gap-4 h-fit w-full">
                        <GroupQuickMenu group={data} />
                        {lvl <= 0 ? <GroupGrades className={"w-full"} /> : null}
                    </div>
                    <div className="flex flex-col lg:flex-row gap-4 h-full">
                        <GroupLessons
                            className={cn("h-full min-h-[256px] w-full overflow-hidden")}
                        />
                        <GroupTasks className={cn("h-full min-h-[256px] w-full overflow-hidden")} />
                    </div>
                </div>
                {/* right side desktop */}
                <div className="flex flex-col gap-4 w-full lg:w-90 h-full">
                    <GroupMeetings
                        groupId={Number(id)}
                        group={data}
                        className={cn("h-full min-h-[456px]")}
                    />
                    <GroupMembers groupId={Number(id)} />
                </div>
            </div>
        </div>
    );
}
