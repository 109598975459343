import { cn } from "@/lib/utils";
import { format } from "date-fns";
import { ArrowRight } from "lucide-react";

export default function MeetingArrowTime({
    startDate,
    endDate,
    className,
}: {
    startDate: string;
    endDate: string;
    className?: string;
}) {
    return (
        <p
            className={cn(
                "flex items-center h-fit truncate whitespace-nowrap text-[clamp(0.7rem,1.5vw,0.8rem)] ",
                className,
            )}
        >
            {format(new Date(startDate), "H:mm")}
            <ArrowRight className="size-2 mx-px" />
            {format(new Date(endDate), "H:mm")}
        </p>
    );
}
