export const serializeData = (data: unknown): unknown => {
    if (data === null) {
        return undefined;
    } else if (data instanceof Date) {
        return data;
    } else if (Array.isArray(data)) {
        return data.map(item => serializeData(item));
    } else if (typeof data === "object") {
        return Object.entries(data).reduce(
            (acc, [key, value]) => ({
                ...acc,
                [key]: serializeData(value),
            }),
            {},
        );
    }

    return data;
};
