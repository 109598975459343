import ActivatePost from "@/components/features/manage/manage-posts/ActivatePost";
import DeletePost from "@/components/features/manage/manage-posts/DeletePost";
import EditPost from "@/components/features/manage/manage-posts/EditPost";
import { Avatar, AvatarFallback, AvatarImage } from "@/components/ui/avatar/Avatar";
import { Button } from "@/components/ui/button/Button";
import {
    Carousel,
    CarouselApi,
    CarouselContent,
    CarouselItem,
    CarouselNext,
    CarouselPrevious,
} from "@/components/ui/carousel/Carousel";
import Image from "@/components/ui/image/Image";
import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuGroup,
    DropdownMenuItem,
    DropdownMenuSeparator,
    DropdownMenuTrigger,
} from "@/components/ui/input/dropdown-menu/DropdownMenu";
import AudioPlayer from "@/components/ui/media-viewer/AudioPlayer";
import VideoPlayer from "@/components/ui/media-viewer/VideoPlayer";
import { Skeleton } from "@/components/ui/skeleton/Skeleton";
import { cn } from "@/lib/utils";
import { useCredentials } from "@/store/authStore";
import type { FilePublic } from "@/types";
import type { PostPublic } from "@/types";
import { FileTypeE, getFileType } from "@/utils/getFileType";
import getFileUrl from "@/utils/getFileUrl";
import { AudioMimeType, VideoMimeType } from "@vidstack/react";
import { formatDistance, parseISO } from "date-fns";
import { pl } from "date-fns/locale";
import {
    ChevronLeft,
    ChevronRight,
    DownloadIcon,
    Minus,
    MoreVertical,
    PenLine,
    Plus,
    Trash2,
    User,
} from "lucide-react";
import { Ref, forwardRef, useEffect, useMemo, useState } from "react";
import { FileCard, FileCardContent, FileCardTitle } from "@/components/ui/file-card/FileCard";
import { FileIcon } from "@/components/ui/file-icon/FileIcon";
import { geFileExtension } from "@/utils/files";
import { getAvatar } from "@/utils/getAvatar";

export const PostSkeleton = forwardRef<HTMLDivElement>((props, ref: Ref<HTMLDivElement>) => {
    return (
        <div
            ref={ref}
            className={
                "flex w-full flex-col gap-5 rounded-lg border border-border-primary bg-surface-primary p-3 md:p-4"
            }
            {...props}
        >
            <div className={"flex gap-4"}>
                <Skeleton className={"h-10 min-h-10 w-10 min-w-10 rounded-sm"} />
                <div className={"flex w-full flex-col justify-center gap-2"}>
                    <Skeleton className={"h-3 w-14 rounded-sm"} />
                    <Skeleton className={"h-3 w-10 rounded-sm"} />
                </div>
            </div>
            <div className={"flex flex-col gap-2"}>
                <Skeleton className={"h-3 w-[100%] rounded-sm"} />
                <Skeleton className={"h-3 w-[100%] rounded-sm"} />
                <Skeleton className={"h-3 w-[80%] rounded-sm"} />
                <Skeleton className={"h-3 w-[20%] rounded-sm"} />
            </div>
        </div>
    );
});
PostSkeleton.displayName = "PostSkeleton";

type PostProps = {
    className?: string;
    post: PostPublic;
};

interface PostMediaProps {
    file: FilePublic;
    serveType: FileTypeE;
    mimeType: string;
}

export function Post({ className, post }: PostProps) {
    const { lvl } = useCredentials();
    const isAdmin = lvl === 2;
    const [postMedia, postFiles] = useMemo(() => {
        const media: PostMediaProps[] = [];
        const files: FilePublic[] = [];
        if (post.files.length > 0) {
            post.files.forEach(file => {
                const { serveType, mimeType } = getFileType(file, {
                    pick: [FileTypeE.IMAGE, FileTypeE.AUDIO, FileTypeE.VIDEO],
                });
                if (serveType === FileTypeE.FILE) {
                    files.push(file);
                } else {
                    media.push({
                        file: file,
                        serveType: serveType,
                        mimeType: mimeType,
                    });
                }
            });
        }
        return [media, files];
    }, [post]);
    const isPostFiles = postFiles.length > 0;
    const [openDelete, setOpenDelete] = useState<boolean>(false);
    const [openEdit, setOpenEdit] = useState<boolean>(false);
    const [openChangeActive, setOpenChangeActive] = useState<boolean>(false);

    const downloadFile = (file: FilePublic) => {
        const url = getFileUrl(file.id);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", file.name);
        document.body.appendChild(link);
        link.click();
        link.remove();
    };

    const passedTime = useMemo(
        () =>
            formatDistance(parseISO(post.createdAt), new Date(), {
                addSuffix: true,
                locale: pl,
            }),
        [post.createdAt],
    );

    return (
        <>
            {isAdmin ? (
                <>
                    <EditPost open={openEdit} onOpenChange={setOpenEdit} post={post} />
                    <ActivatePost
                        open={openChangeActive}
                        onOpenChange={setOpenChangeActive}
                        post={post}
                    />
                    <DeletePost selected={post} open={openDelete} onOpenChange={setOpenDelete} />
                </>
            ) : null}

            <div
                aria-disabled={!post.active}
                className={cn(
                    "flex w-full flex-col gap-4 rounded-lg border border-border-primary aria-disabled:opacity-80 bg-surface-primary p-3 md:p-4",
                    className,
                )}
            >
                <header className={"flex items-center gap-2"}>
                    <Avatar size={"md"}>
                        <AvatarImage src={getAvatar(post.creator.avatarURL)} alt="avatar" />
                        <AvatarFallback>
                            <User />
                        </AvatarFallback>
                    </Avatar>
                    <div className={"flex w-full grow flex-col"}>
                        <h3 className={"text-md font-semibold text-text-primary"}>
                            {post.creator.name} {post.creator.surname}
                        </h3>
                        <p className={"text-xs text-text-tertiary"}>{passedTime}</p>
                    </div>
                    {isAdmin ? (
                        <DropdownMenu>
                            <DropdownMenuTrigger asChild>
                                <Button size={"sm"} variant={"ghost"}>
                                    <MoreVertical />
                                </Button>
                            </DropdownMenuTrigger>
                            <DropdownMenuContent align={"end"}>
                                <DropdownMenuGroup>
                                    <DropdownMenuItem onClick={() => setOpenEdit(true)}>
                                        <PenLine />
                                        Edytuj
                                    </DropdownMenuItem>
                                </DropdownMenuGroup>
                                <DropdownMenuSeparator />
                                <DropdownMenuGroup>
                                    <DropdownMenuItem
                                        className={"text-text-destructive"}
                                        onClick={() => setOpenChangeActive(true)}
                                    >
                                        {post.active ? (
                                            <>
                                                <Minus />
                                                Dezaktywuj
                                            </>
                                        ) : (
                                            <>
                                                <Plus />
                                                Aktywuj
                                            </>
                                        )}
                                    </DropdownMenuItem>
                                    <DropdownMenuItem
                                        className={"text-text-destructive"}
                                        onClick={() => setOpenDelete(true)}
                                    >
                                        <Trash2 />
                                        Usuń
                                    </DropdownMenuItem>
                                </DropdownMenuGroup>
                            </DropdownMenuContent>
                        </DropdownMenu>
                    ) : null}
                </header>
                <p
                    className={
                        "hyphens-auto whitespace-pre-wrap text-pretty break-words text-sm text-text-secondary"
                    }
                >
                    {post.content}
                </p>
                <PostCarousel media={postMedia} />
                {isPostFiles ? (
                    <div className={"flex flex-col gap-2"}>
                        <p className={"text-xs text-text-tertiary"}>Załączniki:</p>
                        <div className={"grid grid-auto-flow gap-2"}>
                            {postFiles.map(file => (
                                <FileCard key={file.id}>
                                    <DropdownMenu>
                                        <FileCardContent>
                                            <FileIcon fileExtension={geFileExtension(file.name)} />
                                            <FileCardTitle>{file.name}</FileCardTitle>
                                            <DropdownMenuTrigger asChild>
                                                <Button variant={"ghost"} size={"sm"}>
                                                    <MoreVertical />
                                                </Button>
                                            </DropdownMenuTrigger>
                                        </FileCardContent>
                                        <DropdownMenuContent align={"end"}>
                                            <DropdownMenuGroup>
                                                <DropdownMenuItem
                                                    onClick={() => downloadFile(file)}
                                                >
                                                    <DownloadIcon className={"h-4 w-4"} />
                                                    Pobierz
                                                </DropdownMenuItem>
                                            </DropdownMenuGroup>
                                        </DropdownMenuContent>
                                    </DropdownMenu>
                                </FileCard>
                            ))}
                        </div>
                    </div>
                ) : null}
            </div>
        </>
    );
}

function PostCarousel({ media }: { media: PostMediaProps[] }) {
    const [api, setApi] = useState<CarouselApi>();
    const [current, setCurrent] = useState<number>(0);
    const [count, setCount] = useState<number>(0);

    const { isEmpty, isOne } = useMemo(() => {
        return {
            isEmpty: media.length === 0,
            isOne: media.length === 1,
        };
    }, [media.length]);

    useEffect(() => {
        if (!api) {
            return;
        }
        setCount(api.scrollSnapList().length);
        setCurrent(api.selectedScrollSnap() + 1);
    }, [api]);

    useEffect(() => {
        if (!api) {
            return;
        }
        const setCurrentOnSelect = () => {
            setCurrent(api.selectedScrollSnap() + 1);
        };
        api.on("select", () => {
            setCurrentOnSelect();
        });

        return () => {
            api.off("select", setCurrentOnSelect);
        };
    }, [api, media]);

    if (isEmpty) {
        return null;
    }

    return (
        <Carousel setApi={setApi} className={"relative w-full"}>
            <span
                className={cn(
                    "absolute right-2 top-2 z-40 flex items-center gap-1 rounded-md bg-surface-primary px-2 py-0.5 text-xs font-medium tracking-wide media-buffering:text-text-secondary",
                    isOne && "hidden",
                )}
            >
                {current}/{count}
            </span>
            <CarouselContent>
                {media.map(mediaProps => (
                    <CarouselItem key={mediaProps.file.id}>
                        <PostMedia
                            file={mediaProps.file}
                            serveType={mediaProps.serveType}
                            mimeType={mediaProps.mimeType}
                        />
                    </CarouselItem>
                ))}
            </CarouselContent>
            <CarouselPrevious
                variant={"tertiary"}
                size={"sm"}
                className={cn("h-8 min-h-8 w-8 min-w-8", isOne && "hidden")}
            >
                <ChevronLeft />
            </CarouselPrevious>
            <CarouselNext
                variant={"tertiary"}
                size={"sm"}
                className={cn("h-8 min-h-8 w-8 min-w-8", isOne && "hidden")}
            >
                <ChevronRight />
            </CarouselNext>
        </Carousel>
    );
}

function PostMedia({ file, mimeType, serveType }: PostMediaProps) {
    const { id, name, fileType: type, thumbnail } = file;
    const content = useMemo(() => {
        switch (serveType) {
            case FileTypeE.IMAGE:
                return (
                    <Image
                        src={getFileUrl(id)}
                        alt={name}
                        thumbnail={thumbnail}
                        type={type}
                        loading={"lazy"}
                        className={"h-full w-full rounded-sm object-contain"}
                    />
                );
            case FileTypeE.VIDEO:
                return (
                    <VideoPlayer
                        src={{
                            src: getFileUrl(id),
                            type: mimeType as VideoMimeType,
                        }}
                        title={name}
                        className={"h-full w-full rounded-sm object-contain"}
                    />
                );
            case FileTypeE.AUDIO:
                return (
                    <AudioPlayer
                        src={{
                            src: getFileUrl(id),
                            type: mimeType as AudioMimeType,
                        }}
                        title={name}
                        className={"flex h-full w-full rounded-sm"}
                    />
                );
        }
    }, [serveType, id, name, thumbnail, type, mimeType]);

    return (
        <div
            className={
                "relative flex min-h-50 h-[40vh] max-h-120 justify-center gap-2 overflow-hidden rounded-md"
            }
        >
            {content}
        </div>
    );
}
