import { Alert, AlertDescription } from "@/components/ui/alert/Alert";
import {
    AlertDialog,
    AlertDialogBody,
    AlertDialogCancel,
    AlertDialogContent,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogTitle,
} from "@/components/ui/dialog/AlertDialog";
import { FeaturedIcon } from "@/components/ui/featured-icon/FeaturedIcon";
import { ErrorsT } from "@/page/pages/manage/ManageUsersMassImport";
import { CircleX } from "lucide-react";
import { SetStateAction, Dispatch } from "react";

type UserMassImportErrorProps = {
    errors: ErrorsT[];
    open: boolean;
    onOpenChange: Dispatch<SetStateAction<boolean>>;
};

export function UserMassImportError({ errors, open, onOpenChange }: UserMassImportErrorProps) {
    return (
        <AlertDialog open={open} onOpenChange={onOpenChange}>
            <AlertDialogContent className={"md:max-w-128"}>
                <AlertDialogBody>
                    <AlertDialogHeader>
                        <FeaturedIcon variant={"ghost"}>
                            <CircleX />
                        </FeaturedIcon>
                        <div className={"flex flex-col gap-0.5  min-w-0 grow"}>
                            <AlertDialogTitle>
                                Wystąpił błąd podczas walidacji danych
                            </AlertDialogTitle>
                            <p className={"text-text-tertiary text-xs"}>
                                Dane zostały wprowadzone niepoprawnie spradź i popraw je.
                            </p>
                        </div>
                    </AlertDialogHeader>
                    <div className={"flex flex-col gap-3 px-3 md:px-4 overflow-auto"}>
                        <p className={"text-sx text-text-tertiary"}>
                            Wystąpił problem z walidacją danych, sprawdź czy wszystkie pola są
                            poprawnie uzupełnione
                        </p>
                        <div className={"flex flex-col gap-2"}>
                            {errors.map(({ errors, data, index }, idx) => (
                                <Alert key={idx}>
                                    <AlertDescription
                                        className={"flex flex-col gap-2 overflow-hidden"}
                                    >
                                        <h5 className={"text-sm font-medium"}>Indeks: {index}</h5>
                                        <p className={"w-full truncate text-sm"}>
                                            {data.name}, {data.surname}, {data.email}, {data.phone}
                                        </p>
                                        <div>
                                            {errors.map((error, idx) => (
                                                <p
                                                    key={idx}
                                                    className={"text-xs text-fg-destructive"}
                                                >
                                                    *{error}
                                                </p>
                                            ))}
                                        </div>
                                    </AlertDescription>
                                </Alert>
                            ))}
                        </div>
                    </div>
                </AlertDialogBody>
                <AlertDialogFooter>
                    <AlertDialogCancel variant={"tertiary"}>Zamknij</AlertDialogCancel>
                </AlertDialogFooter>
            </AlertDialogContent>
        </AlertDialog>
    );
}
