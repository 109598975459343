import { axiosInstance } from "@/api/api";
import { CreateDirectorySchemaType, EditDirectoryType } from "@/schemas/directory.schema";
import type { GroupPermissions, UserPermissions } from "@/types";
import type { DirectoryPublic, DirectoryPrivate } from "@/types";

export interface GetDirectoryPermissionsResponse {
    users: UserPermissions[];
    groups: GroupPermissions[];
}

export const getDirectory = async (directoryId: string): Promise<DirectoryPrivate> => {
    return await axiosInstance.get("/api/fs/dir", {
        params: { id: directoryId },
    });
};

export const getSharedDirectory = async (): Promise<DirectoryPrivate> => {
    return await axiosInstance.get("/api/fs/dir/getShared");
};

export const getDirectoryPermissions = async (
    id?: string,
): Promise<GetDirectoryPermissionsResponse> => {
    return await axiosInstance.get("/api/fs/dir/getPermissions", {
        params: { id },
    });
};

export const createDirectory = async (
    data: CreateDirectorySchemaType,
): Promise<DirectoryPublic> => {
    return await axiosInstance.post("/api/fs/dir", data);
};

export const editDirectory = async (data: EditDirectoryType): Promise<string> => {
    return await axiosInstance.put("/api/fs/dir", data);
};

export const deleteDirectory = async (id: string): Promise<string> => {
    return await axiosInstance.delete("/api/fs/dir", { data: { id } });
};
