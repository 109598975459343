import { httpErrorHandler } from "@/api/api";
import { useInfiniteQueryResult } from "@/api/api-utils";
import {
    usePostGroupMutation as useAddGroup,
    useEditGroupMutation as useEditGroup,
} from "@/api/queries/groupsQueries";
import { useGetUsersInfiniteQuery } from "@/api/queries/usersQueries";
import { Button } from "@/components/ui/button/Button";
import {
    DialogBody,
    DialogContent,
    DialogFooter,
    DialogHeader,
    DialogTitle,
    Dialog,
    DialogDescription,
} from "@/components/ui/dialog/Dialog";
import {
    Form,
    FormControl,
    FormField,
    FormItem,
    FormLabel,
    FormMessage,
} from "@/components/ui/form/Form";
import { Input, InputWrapper } from "@/components/ui/input/Input";
import { Textarea } from "@/components/ui/input/textarea/Textarea";
import { SelectDataListElement } from "@/components/ui/select-list/SelectDataListElement";
import SelectDataList from "@/components/ui/select-list/SelectDataList";
import { useDebounceValue } from "@/hooks/useDebounceValue";
import { GroupAddSchema, GroupAddSchemaType } from "@/schemas/group.schema";
import { UserPublic } from "@/types";
import { yupResolver } from "@hookform/resolvers/yup";
import {
    Search,
    Users,
    LoaderCircle,
    CheckCircle2,
    User,
    Plus,
    CircleX,
    ChevronLeft,
} from "lucide-react";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { FeaturedIcon } from "@/components/ui/featured-icon/FeaturedIcon";
import { Avatar, AvatarFallback, AvatarImage } from "@/components/ui/avatar/Avatar";
import { Tag } from "@/components/ui/tags/Tags";
import { Tabs, TabsContent } from "@/components/ui/tabs/Tabs";
import { getUserLvl } from "@/utils/getUserLvl";
import { cn } from "@/lib/utils";
import { SelectDataListLabel } from "@/components/ui/select-list/SelectDataList";
import { toast } from "sonner";

interface GroupAddFormPropsT {
    open?: boolean;
    onOpenChange?: (bool: boolean) => void;
}

export default function CreateGroup({ open, onOpenChange }: GroupAddFormPropsT) {
    const [tabs, setTabs] = useState<"main" | "selectedList">("main");

    const [searchUserValue, setSearchUserValue] = useState<string>("");
    const searchUserDebouncedValue = useDebounceValue(searchUserValue, 400);

    const [selectedUsers, setSelectedUsers] = useState<UserPublic[]>([]);

    const { mutateAsync: addGroup, isPending: postIsPending } = useAddGroup();
    const { mutateAsync: editGroup, isPending: patchIsPending } = useEditGroup();

    const isPending = postIsPending || patchIsPending;

    const isMainTab = tabs === "main";

    const getUsersInfiniteQuery = useGetUsersInfiniteQuery({
        name: searchUserDebouncedValue,
    });

    const {
        data: users,
        isEmpty: usersIsEmpty,
        noResults: usersNoResults,
    } = useInfiniteQueryResult(getUsersInfiniteQuery.data, searchUserDebouncedValue);

    const onRemoveUser = (id: number) => {
        setSelectedUsers(prev => prev.filter(user => user.id !== id));
    };

    const onTabsValueChange = (tab: string) => {
        setTabs(tab as "main" | "selectedList");
    };

    const onClose = () => {
        onOpenChange?.(false);
        setSearchUserValue("");
        setSelectedUsers([]);
        form.reset();
    };

    const onSubmit = async (data: GroupAddSchemaType) => {
        await addGroup(data)
            .then(async response => {
                await editGroup({
                    id: response.id,
                    memberChanges: [{ userIds: selectedUsers.map(user => user.id), add: true }],
                })
                    .then(() => {
                        onClose();
                    })
                    .catch(error => {
                        const { title, detail } = httpErrorHandler(error);
                        toast.error(title, {
                            description: detail,
                        });
                    });
            })
            .catch(error => {
                const { title, detail } = httpErrorHandler(error);
                toast.error(title, {
                    description: detail,
                });
            });
    };

    const form = useForm<GroupAddSchemaType>({
        defaultValues: {
            name: "",
            desc: "",
        },
        mode: "onBlur",
        resolver: yupResolver(GroupAddSchema),
    });

    return (
        <Dialog open={open} onOpenChange={onOpenChange}>
            <DialogContent
                isClosable={isMainTab}
                className={"sm:max-w-148"}
                onCloseAutoFocus={onClose}
                onEscapeKeyDown={onClose}
            >
                <DialogDescription className={"sr-only"}>
                    Formularz do tworzenia grupy
                </DialogDescription>
                <Form {...form}>
                    <DialogBody>
                        {isPending && (
                            <div
                                className={
                                    "absolute inset-0 z-60 m-auto flex flex-wrap items-center justify-center gap-3 bg-surface-primary/75 backdrop-blur-md"
                                }
                            >
                                <LoaderCircle className={"animate-spin"} aria-hidden={"true"} />
                                <h5 className={"font-medium"}>Trwa tworzenie grupy...</h5>
                            </div>
                        )}

                        {isMainTab ? (
                            <DialogHeader className={"border-b border-border-primary"}>
                                <FeaturedIcon variant={"ghost"}>
                                    <Users />
                                </FeaturedIcon>
                                <div className={"flex flex-col gap-0.5 min-w-0 grow"}>
                                    <DialogTitle>Utwórz Grupę</DialogTitle>
                                    <p className={"text-text-tertiary text-xs"}>
                                        Wprawdź nazwę grupy i dodaj do niej członków.
                                    </p>
                                </div>
                            </DialogHeader>
                        ) : (
                            <DialogHeader className={"border-b border-border-primary"}>
                                <DialogTitle className={"sr-only"}>Utwórz Grupę</DialogTitle>

                                <Button variant={"outline"} onClick={() => setTabs("main")}>
                                    <ChevronLeft />
                                </Button>
                                <InputWrapper className={"w-full"}>
                                    <Input
                                        value={searchUserValue}
                                        onChange={e => setSearchUserValue(e.target.value)}
                                        className="peer pe-8 ps-8"
                                        placeholder="Wyszukaj..."
                                    />
                                    <div className="pointer-events-none absolute inset-y-0 start-0 flex items-center justify-center ps-2 peer-disabled:opacity-50">
                                        <Search className="size-3 stroke-icon-tertiary" />
                                    </div>
                                    {searchUserValue && (
                                        <button
                                            className="absolute cursor-pointer inset-y-0 end-0 flex h-full w-9 items-center justify-center rounded-e-sm text-text-tertiary transition-colors hover:text-text-tertiary-hover z-10 focus-visible:outline-2 outline-ring-focus disabled:pointer-events-none disabled:cursor-not-allowed disabled:opacity-50"
                                            aria-label="Clear input"
                                            onClick={() => setSearchUserValue("")}
                                        >
                                            <CircleX className={"size-3"} aria-hidden="true" />
                                        </button>
                                    )}
                                </InputWrapper>
                            </DialogHeader>
                        )}
                        <Tabs
                            value={tabs}
                            onValueChange={onTabsValueChange}
                            className={"flex flex-col gap-3 p-3 md:p-4 overflow-auto grow"}
                        >
                            <TabsContent value={"main"}>
                                <form
                                    className={"flex flex-col gap-3 grow"}
                                    onSubmit={form.handleSubmit(onSubmit)}
                                    noValidate
                                >
                                    <FormField
                                        name={"name"}
                                        control={form.control}
                                        render={({ field }) => (
                                            <FormItem className={"col-span-1"}>
                                                <FormLabel>Nazwa</FormLabel>
                                                <FormControl>
                                                    <Input {...field} placeholder={"Nazwa"} />
                                                </FormControl>
                                                <FormMessage />
                                            </FormItem>
                                        )}
                                    />
                                    <FormField
                                        name={"desc"}
                                        control={form.control}
                                        render={({ field }) => (
                                            <FormItem>
                                                <FormLabel>
                                                    Opis{" "}
                                                    <small className={"text-sm text-text-tertiary"}>
                                                        (opcjonalne)
                                                    </small>
                                                </FormLabel>
                                                <FormControl>
                                                    <Textarea placeholder={"Opis"} {...field} />
                                                </FormControl>
                                                <FormMessage />
                                            </FormItem>
                                        )}
                                    />
                                </form>
                                <div className="space-y-3">
                                    <div className={"flex justify-between"}>
                                        <h5 className={"font-medium"}>Użytkownicy:</h5>
                                        <Button
                                            size="sm"
                                            variant="tertiary"
                                            onClick={() => onTabsValueChange("selectedList")}
                                        >
                                            <Plus />
                                            Dodaj
                                        </Button>
                                    </div>
                                    <div className="flex flex-wrap gap-1">
                                        {selectedUsers.map(({ id, name, surname, avatarURL }) => (
                                            <Tag
                                                key={id}
                                                onRemove={() => onRemoveUser(id)}
                                                variant={"muted"}
                                                border
                                            >
                                                <Avatar size={"xs"}>
                                                    <AvatarImage
                                                        src={avatarURL}
                                                        alt={name + "_" + surname}
                                                    />
                                                    <AvatarFallback>
                                                        <User />
                                                    </AvatarFallback>
                                                </Avatar>
                                                {name} {surname.at(0)}.
                                            </Tag>
                                        ))}
                                        {selectedUsers.length === 0 && (
                                            <p
                                                className={
                                                    "w-full text-center p-4 text-text-tertiary text-sm"
                                                }
                                            >
                                                Brak wybranych użytkowników
                                            </p>
                                        )}
                                    </div>
                                </div>
                            </TabsContent>
                            <TabsContent value={"selectedList"}>
                                <SelectDataList
                                    query={getUsersInfiniteQuery}
                                    selected={selectedUsers}
                                    setSelected={setSelectedUsers}
                                    isEmpty={usersIsEmpty}
                                    isNoResults={usersNoResults}
                                    emptyMessage={"Wyszukaj użytkownika"}
                                    searchNoResultsMessage={"Nie znaleziono uzytkownika"}
                                >
                                    <SelectDataListLabel
                                        className={cn(
                                            (!selectedUsers.length || searchUserDebouncedValue) &&
                                                "hidden",
                                        )}
                                    >
                                        Dodani:
                                    </SelectDataListLabel>
                                    {!searchUserDebouncedValue &&
                                        selectedUsers.map(entity => (
                                            <SelectDataListElement
                                                key={entity.id}
                                                entity={entity}
                                                data={{
                                                    title: entity.name + " " + entity.surname,
                                                    avatarURL: entity.avatarURL,
                                                    detail: getUserLvl(entity.lvl),
                                                }}
                                            />
                                        ))}
                                    <SelectDataListLabel>Wyszukiwane:</SelectDataListLabel>
                                    {users.map(entity => (
                                        <SelectDataListElement
                                            key={entity.id}
                                            entity={entity}
                                            data={{
                                                title: entity.name + " " + entity.surname,
                                                avatarURL: entity.avatarURL,
                                                detail: getUserLvl(entity.lvl),
                                            }}
                                        />
                                    ))}
                                </SelectDataList>
                            </TabsContent>
                        </Tabs>
                    </DialogBody>
                    <DialogFooter
                        className={cn("border-t border-border-primary", !isMainTab && "hidden")}
                    >
                        <Button
                            onClick={onClose}
                            type={"button"}
                            variant={"tertiary"}
                            className={"w-full md:w-fit"}
                        >
                            Anuluj
                        </Button>
                        <Button
                            disabled={isPending}
                            onClick={form.handleSubmit(onSubmit)}
                            type={"submit"}
                            variant={"primary"}
                            className={"w-full md:w-fit"}
                        >
                            {isPending ? (
                                <>
                                    <LoaderCircle className={"animate-spin"} aria-hidden={"true"} />
                                    Zapisywanie...
                                </>
                            ) : (
                                <>
                                    <CheckCircle2 />
                                    Zapisz
                                </>
                            )}
                        </Button>
                    </DialogFooter>
                </Form>
            </DialogContent>
        </Dialog>
    );
}
