import { MediaViewerFile } from "@/components/features/media/MediaViewer";
import { Button } from "@/components/ui/button/Button";
import {
    DialogBody,
    DialogContent,
    DialogFooter,
    DialogHeader,
    DialogTitle,
    Dialog,
    DialogDescription,
} from "@/components/ui/dialog/Dialog";
import { Link } from "@/components/ui/link/Link";
import AudioPlayer from "@/components/ui/media-viewer/AudioPlayer";
import getFileUrl from "@/utils/getFileUrl";
import { AudioMimeType } from "@vidstack/react";
import { Download } from "lucide-react";
import { useMemo } from "react";

export default function MediaAudioViewer({ file, open, onOpenChange }: MediaViewerFile) {
    const isFilesInstance = "id" in file;

    const { fileURL, type } = useMemo(() => {
        if ("id" in file) {
            return { fileURL: getFileUrl(file.id), type: file.fileType };
        } else if (file instanceof File) {
            return { fileURL: URL.createObjectURL(file), type: file.type };
        }
        return { fileURL: "", type: "" };
    }, [file]);

    return (
        <Dialog open={open} onOpenChange={onOpenChange}>
            <DialogContent className={"h-fit"}>
                <DialogBody>
                    <DialogHeader>
                        <DialogTitle>{file.name}</DialogTitle>
                        <DialogDescription className={"sr-only"}>
                            {file.name} - podgląd
                        </DialogDescription>
                    </DialogHeader>
                    <div className="flex h-full w-ful grow flex-col p-3 md:p-4">
                        <AudioPlayer
                            src={{
                                src: fileURL,
                                type: type as AudioMimeType,
                            }}
                            title={file.name}
                        />
                    </div>
                </DialogBody>
                <DialogFooter>
                    {isFilesInstance ? (
                        <Link href={fileURL}>
                            <Button variant={"ghost"}>
                                <Download />
                            </Button>
                        </Link>
                    ) : null}
                </DialogFooter>
            </DialogContent>
        </Dialog>
    );
}
