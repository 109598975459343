import GroupCardSmall from "@/components/features/groups/GroupCardSmall";
import UserCard from "@/components/features/user/UserCard";
import { Separator } from "@/components/ui/separator/Separator";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs/Tabs";
import type { GroupPublic } from "@/types";
import type { UserPublic } from "@/types";

interface MeetingMembersProps {
    groups: GroupPublic[];
    users: UserPublic[];
}

export default function MeetingMembers({ groups, users }: MeetingMembersProps) {
    return (
        <div className={"flex h-full w-full grow flex-col overflow-auto"}>
            <header className={"flex items-center justify-between gap-2 p-4"}>
                <div className={"flex h-10 items-center"}>
                    <h3 className={"w-full truncate text-lg font-semibold"}>Goście spotkania</h3>
                </div>
            </header>
            <Separator />
            <Tabs defaultValue={"users"} className={"flex w-full grow flex-col overflow-auto"}>
                <div className={"p-4"}>
                    <TabsList className={"grid w-full grid-cols-2"}>
                        <TabsTrigger value={"users"}>Użytkownicy</TabsTrigger>
                        <TabsTrigger value={"groups"}>Grupy</TabsTrigger>
                    </TabsList>
                </div>
                <TabsContent value={"users"} className={"h-full px-4 pb-4"}>
                    {users.map(user => (
                        <UserCard key={user.id} user={user} />
                    ))}
                    {users.length === 0 && (
                        <p className={"text-center text-sm text-text-tertiary"}>
                            Brak użytknowników
                        </p>
                    )}
                </TabsContent>
                <TabsContent value={"groups"} className={"h-full px-4 pb-4"}>
                    {groups.map(group => (
                        <GroupCardSmall key={group.id} group={group} />
                    ))}
                    {groups.length === 0 && (
                        <p className={"text-center text-sm text-text-tertiary"}>Brak grup</p>
                    )}
                </TabsContent>
            </Tabs>
        </div>
    );
}
