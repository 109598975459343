import { InferType, boolean, mixed, number, object, string } from "yup";

const PostAddSchema = object({
    name: string().required("Tytuł jest wymagany"),
    content: string().required("Treść jest wymagana"),
    active: boolean().default(true),
    attachments: mixed<File[]>().optional(),
});

type PostAddSchemaType = InferType<typeof PostAddSchema>;

const PostEditSchema = object({
    id: number().required(),
    name: string().optional(),
    content: string().optional(),
    active: boolean().optional(),
    attachments: mixed<File[]>().optional(),
});

type PostEditSchemaType = InferType<typeof PostEditSchema>;

const PostEditFormSchema = object({
    id: number().required(),
    name: string().required("Tytuł jest wymagany"),
    content: string().required("Treść jest wymagana"),
    active: boolean().optional().default(true),
    attachments: mixed<File[]>().optional(),
});

type PostEditFormSchemaType = InferType<typeof PostEditFormSchema>;

export { PostAddSchema, PostEditSchema, PostEditFormSchema };
export type { PostAddSchemaType, PostEditSchemaType, PostEditFormSchemaType };
