import { Button } from "@/components/ui/button/Button";

import { useFilesStorage } from "@/store/filesStore";
import { FilePlus2, Files, FolderPlus } from "lucide-react";
import { useCallback } from "react";
import {
    EmptyState,
    EmptyStateCircles,
    EmptyStateContent,
    EmptyStateDescription,
    EmptyStateFooter,
    EmptyStateTitle,
} from "@/components/ui/empty-state/EmptyState";
import { FeaturedIcon } from "@/components/ui/featured-icon/FeaturedIcon";
import { FileUploadTrigger } from "../../../../ui/file-upload/FileUpload";

export default function FilesPageEmpty() {
    const { setOpenCreateDir, permissions } = useFilesStorage();

    const handleOnCreateDir = useCallback(() => {
        setOpenCreateDir(true);
    }, [setOpenCreateDir]);

    return (
        <EmptyState>
            <EmptyStateCircles className={"z-0"} />
            <FeaturedIcon size={"xl"} className={"z-10"}>
                <Files />
            </FeaturedIcon>
            <EmptyStateContent>
                <EmptyStateTitle>Folder jest pusty</EmptyStateTitle>
                <EmptyStateDescription>
                    Folder jest pusty dodaj plik i/lub folder
                </EmptyStateDescription>
                <EmptyStateFooter className={"flex-col sm:flex-row"}>
                    <FileUploadTrigger multiple={true}>
                        <Button variant={"outline"} disabled={!permissions.write}>
                            <FilePlus2 />
                            Dodaj nowy plik
                        </Button>
                    </FileUploadTrigger>
                    <Button
                        onClick={handleOnCreateDir}
                        variant={"outline"}
                        disabled={!permissions.write}
                    >
                        <FolderPlus />
                        Dodaj nowy folder
                    </Button>
                </EmptyStateFooter>
            </EmptyStateContent>
        </EmptyState>
    );
}
