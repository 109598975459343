import { Sidebar, SidebarContent, SidebarFooter } from "@/components/ui/sidebar/Sidebar";
import {
    Bell,
    CalendarDays,
    Folder,
    Home,
    MessageCircle,
    Settings2,
    Users,
    LucideIcon,
} from "lucide-react";
import { NavUser } from "@/components/features/app-sidebar/NavUser";
import { NavMain } from "@/components/features/app-sidebar/NavMain";
import { NavHeader } from "@/components/features/app-sidebar/NavHeader";
import { useCredentials } from "@/store/authStore";
import { useMemo } from "react";
import { useNotificationsSheet } from "../notifications/NotificationsSheet";
import { NotificationType } from "@/types/notifications";

export type NavMainItemT = {
    title: string;
    url: string;
    name?: NotificationType;
    icon?: LucideIcon;
    isActive?: boolean;
    onClick?: () => void;
    items?: {
        title: string;
        url: string;
        onClick?: () => void;
    }[];
};

export function AppSidebar({ ...props }: React.ComponentProps<typeof Sidebar>) {
    const { lvl } = useCredentials();
    const { onOpenChange } = useNotificationsSheet();

    const data = useMemo(() => {
        const navMain: NavMainItemT[] = [
            {
                title: "Strona Główna",
                url: "/",
                icon: Home,
                isActive: true,
            },
            {
                title: "Powiadomienia",
                url: "#",
                name: "all",
                onClick: () => onOpenChange(true),
                icon: Bell,
            },
            {
                title: "Chat",
                name: "chat",
                url: "/chat",
                icon: MessageCircle,
            },
            {
                title: "Kalendarz",
                name: "calendar",
                url: "/calendar",
                icon: CalendarDays,
            },
            {
                title: "Grupy",
                name: "group",
                url: "/groups",
                icon: Users,
            },
            {
                title: "Pliki",
                url: "/fs",
                icon: Folder,
            },
        ];

        if (lvl >= 1) {
            let manageItems: NavMainItemT[] = [
                { title: "Użytkownicy", url: "/manage/users" },
                { title: "Grupy", url: "/manage/groups" },
                { title: "Posty", url: "/manage/posts" },
            ];

            if (lvl >= 2) {
                manageItems = [
                    ...manageItems,
                    { title: "Lokalizacje", url: "/manage/locations" },
                    { title: "Dni wolne", url: "/manage/holidays" },
                ];
            }

            navMain.push({
                title: "Zarządzanie",
                url: "/manage",
                icon: Settings2,
                items: manageItems,
            });
        }

        return { navMain };
    }, [lvl]);

    return (
        <>
            <Sidebar collapsible={"icon"} variant={"floating"} {...props}>
                <NavHeader />
                <SidebarContent>
                    <NavMain items={data.navMain} />
                </SidebarContent>
                <SidebarFooter className={"border-t p-0 border-border-primary"}>
                    <NavUser />
                </SidebarFooter>
            </Sidebar>
        </>
    );
}
