// components/features/files/FSEntityWrapper.tsx
import { ContextMenu, ContextMenuTrigger } from "@/components/ui/context-menu/ContextMenu";
import { DropdownMenu } from "@/components/ui/input/dropdown-menu/DropdownMenu";
import { useMediaQuery } from "@/hooks/useMediaQuery";
import FSEntitiesMenu from "@/components/features/files/FSEntitiesMenu";
import { ReactNode } from "react";
import { useEntityMenus } from "@/components/features/files/hooks/useEntityMenus";
import type { DirectoryPublic, FilePublicWithPermissions } from "@/types";

interface FSEntityWrapperProps {
    entity: FilePublicWithPermissions | DirectoryPublic;
    entityType: "file" | "directory";
    children: ReactNode;
}

export default function FSEntityWrapper({ entity, entityType, children }: FSEntityWrapperProps) {
    const breakpoint = useMediaQuery("sm");

    const { openDropdownMenu, handleOpenDropdownMenu, handleOpenContextMenu } = useEntityMenus({
        entity,
        entityType,
    });

    return (
        <ContextMenu onOpenChange={handleOpenContextMenu}>
            <DropdownMenu onOpenChange={handleOpenDropdownMenu} open={openDropdownMenu}>
                <ContextMenuTrigger disabled={!breakpoint} asChild>
                    {children}
                </ContextMenuTrigger>
                <FSEntitiesMenu
                    element={entity}
                    type={entityType}
                    showContextMenu={!breakpoint}
                    showDropdownMenu={true}
                />
            </DropdownMenu>
        </ContextMenu>
    );
}
