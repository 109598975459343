"use client";

import * as React from "react";
import * as TogglePrimitive from "@radix-ui/react-toggle";
import { cva, type VariantProps } from "class-variance-authority";

import { cn } from "@/lib/utils";

const toggleVariants = cva(
    "inline-flex items-center justify-center gap-2 rounded-sm cursor-pointer text-sm font-medium text-text-tertiary transition-colors hover:bg-fill-primary-hover hover:text-text-primary outline-ring-focus outline-0 focus-visible:outline-2 disabled:opacity-75 disabled:pointer-events-none data-[state=on]:bg-fill-primary-active data-[state=on]:text-text-primary [&_svg]:pointer-events-none [&_svg]:size-4 [&_svg]:shrink-0",
    {
        variants: {
            variant: {
                default: "bg-transparent",
                outline: "border border-border-primary bg-fill-primary hover:bg-fill-primary-hover",
            },
            size: {
                sm: "h-6 px-2 py-0.5 min-h-6 rounded-sm",
                md: "h-7 px-3 py-1 min-h-7 rounded-sm",
            },
        },
        defaultVariants: {
            variant: "default",
            size: "md",
        },
    },
);

const Toggle = React.forwardRef<
    React.ElementRef<typeof TogglePrimitive.Root>,
    React.ComponentPropsWithoutRef<typeof TogglePrimitive.Root> &
        VariantProps<typeof toggleVariants>
>(({ className, variant, size, ...props }, ref) => (
    <TogglePrimitive.Root
        ref={ref}
        className={cn(toggleVariants({ variant, size, className }))}
        {...props}
    />
));

Toggle.displayName = TogglePrimitive.Root.displayName;

export { Toggle, toggleVariants };
