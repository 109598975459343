import {
    ContextMenuContent,
    ContextMenuGroup,
    ContextMenuItem,
    ContextMenuLabel,
    ContextMenuSeparator,
} from "@/components/ui/context-menu/ContextMenu";
import {
    DropdownMenuContent,
    DropdownMenuGroup,
    DropdownMenuItem,
    DropdownMenuLabel,
    DropdownMenuSeparator,
} from "@/components/ui/input/dropdown-menu/DropdownMenu";
import { useFilesStorage } from "@/store/filesStore";
import type { DirectoryPublic, FilePublicWithPermissions } from "@/types";
import { getIsFSEntityFile } from "@/utils/files";
import { Download, FileEdit, FolderSymlink, Info, Share2, Trash2 } from "lucide-react";
import { Fragment, useMemo } from "react";

interface FSEntitiesMenuProps {
    element?: FilePublicWithPermissions | DirectoryPublic;
    type: "file" | "directory";
    showContextMenu: boolean;
    showDropdownMenu: boolean;
    visibility?: {
        editName?: boolean;
        share?: boolean;
        download?: boolean;
        move?: boolean;
        moreInfo?: boolean;
        delete?: boolean;
    };
}

const defaultVisibility = {
    editName: true,
    share: true,
    download: true,
    move: true,
    moreInfo: true,
    delete: true,
};

export default function FSEntitiesMenu(props: FSEntitiesMenuProps) {
    const { element, showContextMenu, showDropdownMenu } = props;

    const visibility = useMemo(
        () => ({ ...defaultVisibility, ...props.visibility }),
        [props.visibility],
    );
    const permissions = useMemo(() => element?.permissions, [element]);
    const isFile = getIsFSEntityFile(element);

    const {
        selectedSize,
        setOpenEditName,
        setOpenShare,
        setOpenDownload,
        setOpenMove,
        setOpenMoreInfo,
        setOpenDelete,
    } = useFilesStorage();

    const size = selectedSize();

    const menuItems = useMemo(() => {
        if (element === undefined || permissions === undefined) {
            return [];
        }

        return [
            {
                label: "Zmień nazwę",
                icon: <FileEdit className={"size-4"} />,
                action: () => setOpenEditName(true),
                visible: visibility.editName,
                disable: !permissions.edit || size > 1,
            },
            {
                label: "Udostępnij",
                icon: <Share2 className={"size-4"} />,
                action: () => setOpenShare(true),
                visible: visibility.share,
                disable: !permissions.edit,
            },
            {
                label: "Pobierz",
                icon: <Download className={"size-4"} />,
                action: () => setOpenDownload(true),
                visible: visibility.download,
            },
            {
                label: "Przenieś",
                icon: <FolderSymlink className={"size-4"} />,
                action: () => setOpenMove(true),
                visible: visibility.move,
                disable: !permissions.edit,
            },
            {
                label: "Więcej informacji",
                icon: <Info className={"size-4"} />,
                action: () => setOpenMoreInfo(true),
                visible: visibility.moreInfo,
                disable: size > 1,
            },
            {
                label: "Usuń",
                icon: <Trash2 className={"size-4"} />,
                action: () => setOpenDelete(true),
                visible: visibility.delete,
                disable: isFile ? !permissions.write : !permissions.edit,
                className: "text-text-destructive",
            },
        ];
    }, [
        element,
        isFile,
        permissions,
        setOpenDelete,
        setOpenDownload,
        setOpenEditName,
        setOpenMoreInfo,
        setOpenMove,
        setOpenShare,
        size,
        visibility.delete,
        visibility.download,
        visibility.editName,
        visibility.moreInfo,
        visibility.move,
        visibility.share,
    ]);

    if (element === undefined || permissions === undefined) {
        return null;
    }

    return (
        <Fragment>
            {showDropdownMenu ? (
                <DropdownMenuContent align={"end"}>
                    <DropdownMenuLabel>
                        {isFile ? "Ustawienia Pliku" : "Ustawienia Folderu"}
                    </DropdownMenuLabel>
                    <DropdownMenuSeparator />
                    <DropdownMenuGroup>
                        {menuItems.map(item =>
                            item.visible ? (
                                <DropdownMenuItem
                                    key={item.label}
                                    disabled={item.disable}
                                    onClick={item.action}
                                    className={item.className}
                                >
                                    {item.icon}
                                    {item.label}
                                </DropdownMenuItem>
                            ) : null,
                        )}
                    </DropdownMenuGroup>
                </DropdownMenuContent>
            ) : null}
            {showContextMenu ? (
                <ContextMenuContent>
                    <ContextMenuLabel>
                        {isFile ? "Ustawienia Pliku" : "Ustawienia Folderu"}
                    </ContextMenuLabel>
                    <ContextMenuSeparator />
                    <ContextMenuGroup>
                        {menuItems.map(item =>
                            item.visible ? (
                                <ContextMenuItem
                                    key={item.label}
                                    disabled={item.disable}
                                    onClick={item.action}
                                    className={item.className}
                                >
                                    {item.icon}
                                    {item.label}
                                </ContextMenuItem>
                            ) : null,
                        )}
                    </ContextMenuGroup>
                </ContextMenuContent>
            ) : null}
        </Fragment>
    );
}
