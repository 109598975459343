import CalendarContentDay from "@/components/features/calendar/layout/content/CalendarContentDay";
import CalendarContentMonth from "@/components/features/calendar/layout/content/CalendarContentMonth";
import CalendarContentWeek from "@/components/features/calendar/layout/content/CalendarContentWeek";
import { CONTAINER_HEIGHT } from "@/page/pages/calendar/CalendarPage";
import { useMediaQuery } from "@/hooks/useMediaQuery";
import { NavigationI, VIEW_KEY } from "@/types";

type CalendarContentProps = {
    viewKey: VIEW_KEY;
    date: Date;
    onNavigate: (props: NavigationI) => void;
};

export default function CalendarContent({ viewKey, date, onNavigate }: CalendarContentProps) {
    const breakpoint = useMediaQuery("sm");

    switch (viewKey) {
        case VIEW_KEY.DAY:
            return <CalendarContentDay date={date} containerHeight={CONTAINER_HEIGHT} />;
        case VIEW_KEY.WEEK:
            return breakpoint ? (
                <CalendarContentWeek date={date} containerHeight={CONTAINER_HEIGHT} />
            ) : (
                <CalendarContentDay date={date} containerHeight={CONTAINER_HEIGHT} />
            );
        case VIEW_KEY.MONTH:
            return <CalendarContentMonth date={date} setNavigate={onNavigate} />;
        default:
            return null;
    }
}
