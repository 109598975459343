import FSEntitiesMenu from "@/components/features/files/FSEntitiesMenu";
import {
    Breadcrumb,
    BreadcrumbEllipsis,
    BreadcrumbItem,
    BreadcrumbLink,
    BreadcrumbList,
    BreadcrumbPage,
    BreadcrumbSeparator,
} from "@/components/ui/breadcrumb/Breadcrumb";
import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuGroup,
    DropdownMenuItem,
    DropdownMenuTrigger,
} from "@/components/ui/input/dropdown-menu/DropdownMenu";
import { Skeleton } from "@/components/ui/skeleton/Skeleton";
import { cn } from "@/lib/utils";
import { useCredentials } from "@/store/authStore";
import { Folder, HardDrive } from "lucide-react";
import { useMemo, useState } from "react";
import { Link } from "react-router-dom";
import type { DirectoryPrivate, DirectoryPath } from "@/types";

interface FSPathProps {
    className?: string;
    fsEntry?: DirectoryPrivate;
    isLoading: boolean;
    isError: boolean;
    onRouteChange?: (id: string) => void;
}

export default function FSPath({
    className,
    fsEntry,
    isLoading,
    isError,
    onRouteChange,
}: FSPathProps) {
    const { directoryId: defaultDirectoryId } = useCredentials();
    const [open, setOpen] = useState<boolean>(false);

    const paths = useMemo(() => {
        if (!fsEntry) {
            return [];
        }
        return [...fsEntry.path.filter(({ id }) => id !== defaultDirectoryId)].reverse();
    }, [defaultDirectoryId, fsEntry]);

    const currentPath = useMemo(() => {
        return fsEntry
            ? fsEntry.id !== defaultDirectoryId &&
                  ({
                      name: fsEntry.name,
                      id: fsEntry.id,
                      parentDirId: fsEntry.parentDir.id,
                  } as DirectoryPath)
            : null;
    }, [defaultDirectoryId, fsEntry]);

    return (
        <Breadcrumb className={cn("flex flex-nowrap", className)}>
            <BreadcrumbList className="h-fit">
                {onRouteChange ? (
                    <BreadcrumbItem
                        className="h-10 w-10 items-center justify-center"
                        onClick={() => onRouteChange(defaultDirectoryId)}
                    >
                        <span className={"hidden md:block"}>Dysk</span>
                        <HardDrive className={"h-4 w-4 block md:hidden"} />
                    </BreadcrumbItem>
                ) : (
                    <BreadcrumbItem>
                        <BreadcrumbLink href={"/fs"}>
                            <span className={"hidden md:block"}>Dysk</span>
                            <HardDrive className={"h-4 w-4 block md:hidden"} />
                        </BreadcrumbLink>
                    </BreadcrumbItem>
                )}
                {paths.length ? (
                    <>
                        <BreadcrumbSeparator />
                        <BreadcrumbItem>
                            <DropdownMenu open={open} onOpenChange={setOpen}>
                                <DropdownMenuTrigger aria-label={"Toggle menu"}>
                                    <BreadcrumbEllipsis className={"items-center justify-center"} />
                                </DropdownMenuTrigger>
                                <DropdownMenuContent align={"start"}>
                                    <DropdownMenuGroup>
                                        {paths.map((item, index) => {
                                            if (onRouteChange) {
                                                return (
                                                    <DropdownMenuItem
                                                        key={index}
                                                        onClick={() => onRouteChange(item.id)}
                                                    >
                                                        <Folder className={"size-4"} />
                                                        {item.name}
                                                    </DropdownMenuItem>
                                                );
                                            } else {
                                                return (
                                                    <DropdownMenuItem key={index}>
                                                        <Link
                                                            to={`/fs/dir/${item.id}`}
                                                            className={
                                                                "flex h-full w-full max-w-36 items-center gap-2 truncate"
                                                            }
                                                        >
                                                            <Folder className={"size-4"} />
                                                            {item.name}
                                                        </Link>
                                                    </DropdownMenuItem>
                                                );
                                            }
                                        })}
                                    </DropdownMenuGroup>
                                </DropdownMenuContent>
                            </DropdownMenu>
                        </BreadcrumbItem>
                    </>
                ) : null}

                {currentPath ? (
                    <>
                        <BreadcrumbSeparator />
                        <FSPathPageMenu directory={fsEntry}>
                            <BreadcrumbItem>
                                <BreadcrumbPage className={"max-w-[25ch] truncate"}>
                                    {currentPath.name}
                                </BreadcrumbPage>
                            </BreadcrumbItem>
                        </FSPathPageMenu>
                    </>
                ) : null}

                {isLoading && (
                    <>
                        <BreadcrumbSeparator />
                        <BreadcrumbItem>
                            <Skeleton className={"h-10 w-10"} />
                        </BreadcrumbItem>
                        <BreadcrumbSeparator />
                        <BreadcrumbItem>
                            <Skeleton className={"h-10 w-14"} />
                        </BreadcrumbItem>
                    </>
                )}
                {isError && (
                    <BreadcrumbItem>
                        <BreadcrumbPage className={"text-sm italic text-text-destructive"}>
                            Wystąpił błąd przy ładowaniu ścieżki pliku
                        </BreadcrumbPage>
                    </BreadcrumbItem>
                )}
            </BreadcrumbList>
        </Breadcrumb>
    );
}

interface FSPathPageMenuProps {
    directory?: DirectoryPrivate;
    children: React.ReactNode;
}

function FSPathPageMenu(props: FSPathPageMenuProps) {
    const { directory, children } = props;

    const [openDropdownMenu, setOpenDropdownMenu] = useState<boolean>(false);

    return (
        <DropdownMenu onOpenChange={setOpenDropdownMenu} open={openDropdownMenu}>
            <DropdownMenuTrigger asChild>{children}</DropdownMenuTrigger>
            <FSEntitiesMenu
                element={directory}
                type={"directory"}
                showContextMenu={false}
                showDropdownMenu={true}
                visibility={{
                    move: false,
                    delete: false,
                }}
            />
        </DropdownMenu>
    );
}
