import MeetingArrowTime from "@/components/features/meeting/meeting-calendar-card/MeetingArrowTime";
import { MeetingLink } from "@/components/features/meeting/meeting-calendar-card/MeetingLink";
import MeetingLocation from "@/components/features/meeting/meeting-calendar-card/MeetingLocation";
import MeetingName from "@/components/features/meeting/meeting-calendar-card/MeetingName";
import { cn } from "@/lib/utils";
import { MeetingPrivate, MeetingPublic } from "@/types";
import { timeToHeight } from "@/utils/timeToHeight";
import { parseISO } from "date-fns";
import { forwardRef, type HTMLAttributes } from "react";

interface MeetingCardOptions {
    containerHeight: number;
    style: { size: number; left: number };
}

interface MeetingTriggerContentProps extends HTMLAttributes<HTMLDivElement> {
    meeting: MeetingPrivate | MeetingPublic;
    options?: MeetingCardOptions;
    className?: string;
}

const MeetingCalendarCard = forwardRef<HTMLDivElement, MeetingTriggerContentProps>(
    ({ meeting, options, className, style, ...props }, ref) => {
        const { startDate, endDate, isCancelled } = meeting;

        const { styles, height } = useMeetingCardDimensions(startDate, endDate, options);

        return (
            <div
                ref={ref}
                role={"button"}
                className={"px-1 py-0.5"}
                {...props}
                style={{ ...styles, ...style }}
            >
                <div
                    className={cn(
                        "relative flex flex-row h-full shrink-0 cursor-pointer gap-px overflow-hidden rounded-xs bg-fill-primary hover:bg-fill-primary-hover transition-color duration-100 p-1 ps-2 border border-border-secondary",
                        isCancelled &&
                            "bg-surface-destructive border-border-destructive text-text-destructive opacity-75",
                        "before:w-1 before:h-full before:absolute before:top-0 before:left-0 before:bg-fill-brand/75",
                        className,
                    )}
                >
                    <MeetingCalendarCardContent meeting={meeting} height={height} />
                </div>
            </div>
        );
    },
);

MeetingCalendarCard.displayName = "MeetingCalendarCard";

type MeetingCalendarCardContentProps = {
    height: number;
    meeting: MeetingPrivate | MeetingPublic;
};

const MeetingCalendarCardContent = ({ height, meeting }: MeetingCalendarCardContentProps) => {
    const { name, startDate, endDate, meetingURL } = meeting;

    if (height <= 56) {
        return <MeetingName name={name} />;
    } else if (height <= 96) {
        return (
            <div className={"flex flex-col gap-0.5"}>
                <MeetingName name={name} />
                <MeetingArrowTime startDate={startDate} endDate={endDate} />
            </div>
        );
    } else {
        return (
            <div className={"flex flex-col gap-0.5"}>
                <MeetingName name={name} />
                <MeetingArrowTime startDate={startDate} endDate={endDate} />
                {"location" in meeting && (
                    <MeetingLocation
                        locationName={meeting.location?.name || "Nieznana lokalizacja"}
                    />
                )}
                {meetingURL && <MeetingLink meetingURL={meetingURL} />}
            </div>
        );
    }
};

interface MeetingCardDimensions {
    styles: React.CSSProperties;
    height: number;
}

function useMeetingCardDimensions(
    startDate: string,
    endDate: string,
    options?: MeetingCardOptions,
): MeetingCardDimensions {
    if (!options) {
        return {
            styles: {},
            height: 0,
        };
    }

    // Calculate position and dimensions
    const parsedStartDate = parseISO(startDate);
    const parsedEndDate = parseISO(endDate);

    const startHeight = timeToHeight(parsedStartDate, options.containerHeight);
    const endHeight = timeToHeight(parsedEndDate, options.containerHeight);
    const cardHeight = Math.max(endHeight - startHeight, 28); // Ensure non-negative height

    const { left, size } = options.style;

    const styles: React.CSSProperties = {
        position: "absolute",
        top: `${startHeight}px`,
        left: `${left}%`,
        height: `${cardHeight}px`,
        width: `${size}%`,
    };

    return {
        styles,
        height: cardHeight,
    };
}

export default MeetingCalendarCard;
