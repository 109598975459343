import { httpErrorHandler } from "@/api/api";
import { useDeleteUserAvatarMutation, usePostUserAvatarMutation } from "@/api/queries/usersQueries";
import { useCredentials, useSetCredentials } from "@/store/authStore";
import { getAvatar } from "@/utils/getAvatar";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { ImageCropper, Image } from "@/components/ui/image-cropper/ImageCropper";
import { Trash2, LoaderCircle } from "lucide-react";
import { ButtonGroupButton } from "@/components/ui/button/ButtonGroup";
import { useParams } from "react-router-dom";

interface UserAvatarEditorProps {
    open: boolean;
    onOpenChange: Dispatch<SetStateAction<boolean>>;
}

export function UserAvatarEditor({ open, onOpenChange }: UserAvatarEditorProps) {
    const [imageSrc, setImageSrc] = useState<string | undefined>(undefined);
    const [image, setImage] = useState<Image | null>(null);

    const params = useParams();
    const authToken = params.t;

    const setCredentials = useSetCredentials();
    const credentials = useCredentials();
    const { avatarURL } = credentials;

    useEffect(() => {
        if (open && avatarURL) {
            setImageSrc(getAvatar(avatarURL));
        }
    }, [open, avatarURL]);

    const { mutateAsync: deleteAvatar, isPending: isPendingDeleteAvatar } =
        useDeleteUserAvatarMutation();

    const { mutateAsync: postAvatar, isPending: isPendingPostAvatar } = usePostUserAvatarMutation();

    const handleDeleteAvatar = () => {
        deleteAvatar({ authToken: authToken ? authToken : undefined }).then(() => {
            setCredentials({ ...credentials, avatarURL: "" });
            setImageSrc(undefined);
            setImage(null);
        });
    };

    const handleCropAvatar = async (blob: Blob) => {
        await postAvatar({
            b64: blob,
            authToken: authToken ? authToken : undefined,
        })
            .then(({ avatarURL }) => {
                setCredentials({ ...credentials, avatarURL: avatarURL });
            })
            .catch(error => {
                const { detail } = httpErrorHandler(error);
                let errorMessage = detail;
                if (error.response.status === 403) {
                    errorMessage = "Token logowania wygasł, Zaloguj się ponownie.";
                }
                throw new Error(errorMessage);
            });
    };

    const deleteControl = (
        <ButtonGroupButton
            disabled={isPendingDeleteAvatar}
            className={"w-full"}
            onClick={() => handleDeleteAvatar()}
        >
            {isPendingDeleteAvatar ? (
                <LoaderCircle className={"animate-spin"} aria-hidden={"true"} />
            ) : (
                <Trash2 className="*stroke-icon-brand" />
            )}
        </ButtonGroupButton>
    );

    return (
        <ImageCropper
            image={image}
            setImage={setImage}
            defaultSrc={imageSrc}
            open={open}
            onOpenChange={onOpenChange}
            onCrop={handleCropAvatar}
            isPending={isPendingPostAvatar}
            title="Edytuj awatar"
            extraControls={deleteControl}
            aspectRatio={1}
            maxHeight={256}
            maxWidth={256}
        />
    );
}
