import { useBatchMutation } from "@/api/api-utils";
import { deleteFile, editFile, getFile, getFilePermissions } from "@/api/endpoints/files";
import { queryClient } from "@/api/query-client";
import { EditFileType } from "@/schemas/files.schema";
import type { DirectoryPrivate } from "@/types";
import { useMutation, useQueries, useQuery } from "@tanstack/react-query";

export const useGetFileQuery = (id?: string) => {
    return useQuery({
        queryKey: ["files", id],
        queryFn: () => getFile(id),
        enabled: !!id,
    });
};

export const useGetFilePermissionsQuery = (id?: string, enabled = true) => {
    return useQuery({
        queryKey: ["filePermissions", id],
        queryFn: () => getFilePermissions(id),
        enabled: enabled && !!id,
    });
};

export const useGetFilePermissionsQueries = (ids: string[], enabled = true) => {
    return useQueries({
        queries: ids.map(id => ({
            queryKey: ["filePermissions", id],
            queryFn: () => getFilePermissions(id),
            enabled: enabled && !!id,
        })),
        combine: results => {
            return {
                data: results.map(result => result.data),
                pending: results.some(result => result.isPending),
            };
        },
    });
};

export const useEditFileMutation = (directoryId?: string) => {
    return useMutation({
        mutationKey: ["editFile"],
        mutationFn: (data: EditFileType) => editFile(data),
        onSettled: () => {
            queryClient.invalidateQueries({
                queryKey: ["directory", directoryId],
            });
        },
    });
};

export const useEditFilesMutation = (directoryId?: string) => {
    return useBatchMutation({
        mutationKey: ["editFile"],
        mutationFn: (data: EditFileType) => editFile(data),
        onSettled: () => {
            queryClient.invalidateQueries({
                queryKey: ["directory", directoryId],
            });
        },
    });
};

export const useDeleteFileMutation = (directoryId?: string) => {
    return useMutation({
        mutationKey: ["deleteFile"],
        mutationFn: (id: string) => deleteFile(id),
        onMutate: async id => {
            await queryClient.cancelQueries({ queryKey: ["directory", directoryId] });
            const prevDirectory = queryClient.getQueryData<DirectoryPrivate>([
                "directory",
                directoryId,
            ]);

            if (prevDirectory) {
                queryClient.setQueryData<DirectoryPrivate>(["directory", directoryId], prev => {
                    if (!prev) {
                        return;
                    }
                    return {
                        ...prev,
                        files: prev.files.filter(file => file.id !== id),
                    };
                });
            }

            return { prevDirectory };
        },
        onError: (err, variables, context) => {
            if (context?.prevDirectory) {
                queryClient.setQueryData(["directory", directoryId], context.prevDirectory);
            }
        },
        onSettled: () => {
            queryClient.invalidateQueries({
                queryKey: ["directory", directoryId],
            });
        },
    });
};

export const useDeleteFilesMutation = (directoryId?: string) => {
    return useBatchMutation({
        mutationKey: ["deleteFile"],
        mutationFn: (id: string) => deleteFile(id),
        onMutate: async id => {
            await queryClient.cancelQueries({ queryKey: ["directory", directoryId] });
            const prevDirectory = queryClient.getQueryData<DirectoryPrivate>([
                "directory",
                directoryId,
            ]);

            if (prevDirectory) {
                queryClient.setQueryData<DirectoryPrivate>(["directory", directoryId], prev => {
                    if (!prev) {
                        return;
                    }
                    return {
                        ...prev,
                        files: prev.files.filter(file => file.id !== id),
                    };
                });
            }

            return { prevDirectory };
        },
        onError: (err, variables, context) => {
            if (context?.prevDirectory) {
                queryClient.setQueryData(["directory", directoryId], context.prevDirectory);
            }
        },
        onSettled: () => {
            queryClient.invalidateQueries({
                queryKey: ["directory", directoryId],
            });
        },
    });
};
