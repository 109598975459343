import { useInfiniteQueryResult } from "@/api/api-utils";
import {
    useGetNotificationsInfiniteQuery,
    usePostReadAllNotificationsMutation,
} from "@/api/queries/notificationsQueries";
import { usePostReadNotificationsMutation } from "@/api/queries/notificationsQueries";
import NotificationsList from "@/components/features/notifications/NotificationsList";
import {
    Drawer,
    DrawerBody,
    DrawerContent,
    DrawerDescription,
    DrawerFooter,
    DrawerHeader,
    DrawerTitle,
} from "@/components/ui/drawer/Drawer";
import {
    Sheet,
    SheetBody,
    SheetContent,
    SheetDescription,
    SheetFooter,
    SheetHeader,
    SheetTitle,
} from "@/components/ui/sheet/Sheet";
import { useMediaQuery } from "@/hooks/useMediaQuery";
import { createContext, useState, useCallback, useContext } from "react";
import { cn } from "@/lib/utils";
import React from "react";
import { Slot } from "@radix-ui/react-slot";
import { Button } from "@/components/ui/button/Button";
import { ButtonProps } from "../../ui/button/Button";
import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuGroup,
    DropdownMenuItem,
    DropdownMenuTrigger,
} from "@/components/ui/input/dropdown-menu/DropdownMenu";
import { CheckCheck, Settings } from "lucide-react";
import { useNavigate } from "react-router";

interface NotificationsSheetContextType {
    open: boolean;
    onOpenChange: (open: boolean) => void;
}

const NotificationsSheetContext = createContext<NotificationsSheetContextType | undefined>(
    undefined,
);

type NotificationsSheetProviderProps = {
    children: React.ReactNode;
};

function NotificationsSheetProvider({ children }: NotificationsSheetProviderProps) {
    const [open, setOpen] = useState<boolean>(false);

    const onOpenChange = (open: boolean) => {
        setOpen(open);
    };

    return (
        <NotificationsSheetContext.Provider value={{ open, onOpenChange }}>
            {children}
        </NotificationsSheetContext.Provider>
    );
}

function useNotificationsSheet() {
    const context = useContext(NotificationsSheetContext);
    if (context === undefined) {
        throw new Error("useNotificationsSheet must be used within a NotificationsSheetProvider");
    }
    return context;
}

function NotificationsSheet() {
    const { open, onOpenChange } = useNotificationsSheet();
    const breakpoint = useMediaQuery("sm");

    const getNotificationsQuery = useGetNotificationsInfiniteQuery();
    const readNotificationsMutation = usePostReadNotificationsMutation();
    const readAllNotificationsMutation = usePostReadAllNotificationsMutation();

    const { data: notifications } = useInfiniteQueryResult(getNotificationsQuery.data);

    const makeNotificationsRead = useCallback((array: number[]) => {
        readNotificationsMutation.mutateAsync({ arr: array });
    }, []);

    const makeAllVisibleNotificationsRead = useCallback(() => {
        readNotificationsMutation.mutateAsync({
            arr: notifications.map(m => m.id),
        });
    }, [notifications]);

    const makeAllNotificationsRead = useCallback(() => {
        readAllNotificationsMutation.mutateAsync();
    }, []);

    if (breakpoint) {
        return (
            <Sheet open={open} onOpenChange={onOpenChange}>
                <SheetContent className={"max-w-96"} side={"right"}>
                    <SheetBody>
                        <SheetHeader className="border-border-primary border-b">
                            <SheetTitle>Powiadomienia</SheetTitle>
                        </SheetHeader>
                        <SheetDescription className={"sr-only"}>
                            Lista powiadomień aplikacji
                        </SheetDescription>
                        <NotificationsList
                            {...getNotificationsQuery}
                            makeNotificationsRead={makeNotificationsRead}
                        />
                    </SheetBody>
                    <SheetFooter className="border-border-primary border-t">
                        <NotificationsSheetActions
                            makeAllVisibleNotificationsRead={makeAllVisibleNotificationsRead}
                            makeAllNotificationsRead={makeAllNotificationsRead}
                        />
                    </SheetFooter>
                </SheetContent>
            </Sheet>
        );
    }

    return (
        <Drawer open={open} onOpenChange={onOpenChange}>
            <DrawerContent>
                <DrawerBody>
                    <DrawerHeader className="border-border-primary border-b">
                        <DrawerTitle>Powiadomienia</DrawerTitle>
                    </DrawerHeader>
                    <DrawerDescription className={"sr-only"}>
                        Lista powiadomień aplikacji
                    </DrawerDescription>
                    <div>
                        <NotificationsList
                            {...getNotificationsQuery}
                            makeNotificationsRead={makeNotificationsRead}
                        />
                    </div>
                </DrawerBody>
                <DrawerFooter className="border-border-primary border-t">
                    <NotificationsSheetActions
                        makeAllVisibleNotificationsRead={makeAllVisibleNotificationsRead}
                        makeAllNotificationsRead={makeAllNotificationsRead}
                    />
                </DrawerFooter>
            </DrawerContent>
        </Drawer>
    );
}
NotificationsSheet.displayName = "NotificationSheet";

const NotificationsSheetTrigger = React.forwardRef<HTMLButtonElement, ButtonProps>(
    ({ className, asChild = false, onClick, ...props }, ref) => {
        const { onOpenChange } = useNotificationsSheet();

        const handleClick = e => {
            onClick?.(e);
            onOpenChange(true);
        };

        const Comp = asChild ? Slot : "button";
        return <Comp ref={ref} className={cn(className)} onClick={handleClick} {...props} />;
    },
);
NotificationsSheetTrigger.displayName = "NotificationsSheetTrigger";

type NotificationsSheetFooterProps = {
    makeAllVisibleNotificationsRead: () => void;
    makeAllNotificationsRead: () => void;
};

export default function NotificationsSheetActions(props: NotificationsSheetFooterProps) {
    const { makeAllVisibleNotificationsRead, makeAllNotificationsRead } = props;
    const navigate = useNavigate();

    const navigateNotificationSettings = () => {
        navigate("/settings");
    };

    return (
        <div className={"flex w-full justify-between"}>
            <DropdownMenu>
                <DropdownMenuTrigger asChild>
                    <Button size={"sm"} variant={"ghost"}>
                        <Settings />
                    </Button>
                </DropdownMenuTrigger>
                <DropdownMenuContent>
                    <DropdownMenuGroup>
                        <DropdownMenuItem onClick={makeAllNotificationsRead}>
                            <CheckCheck className={"size-4"} />
                            Zaznacz wszystkie jako przeczytane
                        </DropdownMenuItem>
                        <DropdownMenuItem onClick={navigateNotificationSettings}>
                            <Settings className={"size-4"} />
                            Zmień ustawienia powiadomień
                        </DropdownMenuItem>
                    </DropdownMenuGroup>
                </DropdownMenuContent>
            </DropdownMenu>
            <Button onClick={makeAllVisibleNotificationsRead} size={"sm"} variant={"ghost"}>
                <CheckCheck />
                Zaznacz jako przeczytane
            </Button>
        </div>
    );
}

export {
    NotificationsSheet,
    NotificationsSheetTrigger,
    NotificationsSheetProvider,
    useNotificationsSheet,
};
