import { httpErrorHandler } from "@/api/api";
import { useDeleteMeetingMutation as useDeleteMeeting } from "@/api/queries/meetingsQuery";
import { Badge } from "@/components/ui/badge/Badge";
import {
    AlertDialogAction,
    AlertDialogBody,
    AlertDialogCancel,
    AlertDialogContent,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogTitle,
    AlertDialog,
} from "@/components/ui/dialog/AlertDialog";
import { FeaturedIcon } from "@/components/ui/featured-icon/FeaturedIcon";
import { MeetingPublic } from "@/types";
import { format } from "date-fns";
import { Calendar, Dot } from "lucide-react";
import { Dispatch, SetStateAction } from "react";
import { toast } from "sonner";

interface CalendarDeleteMeetingProps {
    open: boolean;
    onOpenChange: Dispatch<SetStateAction<boolean>>;
    callback?: () => void;
    data: MeetingPublic;
    queryParams: { before: string; after: string };
}

export default function DeleteMeeting({
    open,
    onOpenChange,
    callback,
    data,
    queryParams,
}: CalendarDeleteMeetingProps) {
    const { id, startDate, endDate, name, type } = data;

    const { mutateAsync, reset } = useDeleteMeeting(queryParams);

    const onSubmit = () => {
        mutateAsync(id)
            .then(() => {
                onClose();
            })
            .catch(error => {
                const { title, detail } = httpErrorHandler(error, {
                    title: "Usunięcie nie powiodło się",
                });
                toast.error(title, {
                    description: detail,
                });
            });
    };

    const onClose = () => {
        onOpenChange(false);
        reset();
        callback?.();
    };

    return (
        <AlertDialog onOpenChange={onOpenChange} open={open}>
            <AlertDialogContent className={"sm:max-w-96"}>
                <AlertDialogBody>
                    <AlertDialogHeader>
                        <FeaturedIcon variant={"ghost"}>
                            <Calendar />
                        </FeaturedIcon>
                        <div className={"flex flex-col gap-0.5 min-w-0 grow"}>
                            <AlertDialogTitle>Usunąć wybrane spotkanie</AlertDialogTitle>
                            <p className={"text-text-tertiary text-xs"}>
                                Usuń wybrane spotkanie z kalendarza.
                            </p>
                        </div>
                    </AlertDialogHeader>
                    <div className={"flex flex-col gap-3 px-3 md:px-4 overflow-auto"}>
                        <div className={"flex min-h-9 items-center"}>
                            <span className={"min-w-14 text-text-tertiary"}>Nazwa:</span>
                            <p>{name}</p>
                        </div>
                        <div className={"flex min-h-9 items-center"}>
                            <span className={"min-w-14 text-text-tertiary"}>Data:</span>
                            <p className={"flex items-center"}>
                                {format(new Date(startDate), "dd MMM yyyy")}
                                <Dot className={"size-4"} />
                                {format(new Date(startDate), "H:mm")}-
                                {format(new Date(endDate), "H:mm")}
                            </p>
                        </div>
                        <div className={"flex min-h-9 items-center"}>
                            <span className={"min-w-14 text-text-tertiary"}>Typ:</span>
                            <Badge variant={"accent"}>
                                {type === "remote" ? "Zdalnie" : "Stacjonarnie"}
                            </Badge>
                        </div>
                    </div>
                </AlertDialogBody>
                <AlertDialogFooter>
                    <AlertDialogCancel variant={"tertiary"} onClick={onClose}>
                        Zamknij
                    </AlertDialogCancel>
                    <AlertDialogAction onClick={onSubmit}>Potwierdź</AlertDialogAction>
                </AlertDialogFooter>
            </AlertDialogContent>
        </AlertDialog>
    );
}
