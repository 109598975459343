import { useInfiniteQueryResult } from "@/api/api-utils";
import { useGetAssignedPostsInfiniteQuery } from "@/api/queries/postsQueries";
import { Post, PostSkeleton } from "@/components/features/post/Post";
import { InfiniteScroll } from "@/components/ui/infinite-scroll/InfiniteScroll";
import type { PostPublic } from "@/types";
import { useMutationState } from "@tanstack/react-query";
import { Fragment } from "react";
import EmptyPostsList from "@/components/features/post/EmptyPostList";

export default function PostList() {
    const { data, isSuccess, isFetching, isLoading, hasNextPage, isError, fetchNextPage } =
        useGetAssignedPostsInfiniteQuery();

    const { data: posts, isEmpty: postsIsEmpty } = useInfiniteQueryResult(data);

    const next = async () => {
        await fetchNextPage();
    };

    const variables = useMutationState<PostPublic>({
        filters: { mutationKey: ["postPost"], status: "pending" },
        select: mutation => mutation.state.variables as PostPublic,
    });

    return (
        <div className={"flex w-full flex-col gap-4"}>
            {variables.map(variable => (
                <PostSkeleton key={variable.name} />
            ))}
            {posts.map(post => (
                <Post key={post.id} post={post} />
            ))}
            {isSuccess && postsIsEmpty && <EmptyPostsList />}
            {isLoading && (
                <Fragment>
                    <PostSkeleton />
                    <PostSkeleton />
                    <PostSkeleton />
                </Fragment>
            )}
            <InfiniteScroll
                hasNextPage={hasNextPage}
                isLoading={isLoading}
                isFetching={isFetching}
                next={next}
                threshold={0.25}
                root={null}
            >
                {hasNextPage && !isError && <PostSkeleton />}
            </InfiniteScroll>
        </div>
    );
}
