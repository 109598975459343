import { linkURLRegex, timeRegex } from "@/lib/regex";
import { isBefore, parse } from "date-fns";
import { InferType, array, boolean, number, object, string } from "yup";
import type { UserPublic, GroupPublic, LocationPublic } from "@/types";

const CalendarScheduleInstanceSchema = object({
    day: string().required("Dzień tygodnia jest wymagany"),
    type: string().oneOf(["remote", "stationary"]).required("Typ jest wymagany").defined(),
    locationId: number().optional(),
    meetingURL: string()
        .matches(linkURLRegex, {
            message: "Niepoprawny format linku",
            excludeEmptyString: true,
        })
        .optional(),
    start: string()
        .matches(timeRegex, {
            message: "Niepoprawny czas",
            excludeEmptyString: true,
        })
        .required("Godzina początkowa jest wymagana"),
    end: string()
        .matches(timeRegex, {
            message: "Niepoprawny czas",
            excludeEmptyString: true,
        })
        .required("Godzina końcowa jest wymagana")
        .test(
            "after-start-time",
            "Prosimy ustalić czas rozpoczęcia przed końcem.",
            function (value) {
                const { start } = this.parent;
                return isBefore(
                    parse(start, "HH:mm:ss", new Date()),
                    parse(value, "HH:mm:ss", new Date()),
                );
            },
        ),
});

type CalendarScheduleInstanceSchemaType = InferType<typeof CalendarScheduleInstanceSchema>;

const CalendarFormSchema = object({
    name: string().trim().required("Nazwa jest wymagana"),
    desc: string().optional(),
    date: string().required("Data jest wymagana"),
    type: string().oneOf(["remote", "stationary"]).required("Typ jest wymagany"),
    location: object({
        id: number().required(),
        name: string().required(),
        desc: string().optional(),
        coordinates: string().optional(),
    }).optional(),
    users: array<UserPublic>().default([]),
    groups: array<GroupPublic>().default([]),
    excludeHolidays: boolean().required().default(false),
    method: string().oneOf(["once", "schedule"]).required("Metoda jest wymagana").default("once"),
    meetingURL: string()
        .matches(linkURLRegex, {
            message: "Niepoprawny format linku",
            excludeEmptyString: true,
        })
        .optional(),
    schedule: array().of(CalendarScheduleInstanceSchema).default([]).required(),
    startTime: string()
        .matches(timeRegex, {
            message: "Niepoprawny czas",
            excludeEmptyString: true,
        })
        .required("Godzina początkowa jest wymagana"),
    endTime: string()
        .matches(timeRegex, {
            message: "Niepoprawny czas",
            excludeEmptyString: true,
        })
        .required("Godzina końcowa jest wymagana")
        .test(
            "after-start-time",
            "Prosimy ustalić czas rozpoczęcia przed końcem.",
            function (value) {
                const { startTime } = this.parent;
                return isBefore(
                    parse(startTime, "HH:mm:ss", new Date()),
                    parse(value, "HH:mm:ss", new Date()),
                );
            },
        ),
    startDate: string().required("Data początkowa jest wymagana"),
    endDate: string()
        .required("Data końcowa jest wymagana")
        .test(
            "data-range",
            "Data końcowa musi być późniejsza niż data początkowa",
            function (value) {
                const { startDate } = this.parent;
                return isBefore(new Date(startDate), new Date(value));
            },
        ),
});

type CalendarFormSchemaType = InferType<typeof CalendarFormSchema>;

export { CalendarFormSchema, CalendarScheduleInstanceSchema };
export type { CalendarFormSchemaType, CalendarScheduleInstanceSchemaType };
