import {
    EmptyState,
    EmptyStateContent,
    EmptyStateTitle,
    EmptyStateDescription,
    EmptyStateCircles,
} from "@/components/ui/empty-state/EmptyState";
import { FeaturedIcon } from "@/components/ui/featured-icon/FeaturedIcon";
import { Contact } from "lucide-react";

export default function EmptyPostsList() {
    return (
        <EmptyState>
            <EmptyStateCircles />
            <FeaturedIcon size={"lg"}>
                <Contact />
            </FeaturedIcon>
            <EmptyStateContent>
                <EmptyStateTitle>Brak Postów</EmptyStateTitle>
                <EmptyStateDescription>
                    Żaden post nie został <br /> dotychczas utworzony na twojej stronie
                </EmptyStateDescription>
            </EmptyStateContent>
        </EmptyState>
    );
}
