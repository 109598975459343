import { useMediaQuery } from "@/hooks/useMediaQuery";
import { cn } from "@/lib/utils";
import { SetNavigate } from "@/types";
import { getWeek } from "@/utils/dateFormat";
import { format, getDate, isToday } from "date-fns";
import { pl } from "date-fns/locale";
import { useMemo } from "react";
import { WeekCardsRow } from "../../WeekCards";

interface CalendarContentNavWeekProps {
    date: Date;
    setNavigate: SetNavigate;
}

export default function CalendarHeaderWeek({ date, setNavigate }: CalendarContentNavWeekProps) {
    const breakpoint = useMediaQuery("sm");
    const weekArray = useMemo(() => getWeek(date), [date]);

    const onNavigate = (day: Date) => {
        setNavigate({ newDate: day });
    };

    return (
        <div className={"flex w-full flex-row justify-center"}>
            {breakpoint ? (
                <CalendarHeaderWeekList week={weekArray} />
            ) : (
                <div className={"w-full px-3 md:px-4 pb-3 md:pb-4 divide-border-primary divide-x"}>
                    <WeekCardsRow
                        selected={date}
                        onClick={onNavigate}
                        className={"w-full"}
                        week={weekArray}
                    />
                </div>
            )}
        </div>
    );
}

type CalendarHeaderWeekListProps = {
    week: Date[];
};

function CalendarHeaderWeekList({ week }: CalendarHeaderWeekListProps) {
    return (
        <div
            className={
                "h-8 md:h-9 w-full border-y border-border-primary divide-border-primary divide-x flex"
            }
        >
            <div className={"w-12 md:w-14 h-full shrink-0"} />
            {week.map(date => (
                <CalendarHeaderWeekItem key={date.toString()} date={date} />
            ))}
        </div>
    );
}

type CalendarHeaderWeekItemProps = {
    date: Date;
};

function CalendarHeaderWeekItem({ date }: CalendarHeaderWeekItemProps) {
    const dateDay = getDate(date);
    const weekDay = format(date, "EEE", { locale: pl });
    const today = isToday(date);

    return (
        <div
            className={
                "flex w-full h-full flex-col items-center justify-center border-r border-border-primary last:border-0"
            }
            key={date.toString()}
        >
            <p className={cn("text-xs md:text-sm text-text-secondary", today && "text-text-brand")}>
                {dateDay} {weekDay}
            </p>
        </div>
    );
}
