import CalendarHeaderWeek from "@/components/features/calendar/layout/header/CalendarHeaderWeek";
import CalendarHeaderMonth from "@/components/features/calendar/layout/header/CalendarHeaderMonth";
import { useMediaQuery } from "@/hooks/useMediaQuery";
import { Separator } from "@/components/ui/separator/Separator";
import { NavigationI, VIEW_KEY } from "@/types";

type CalendarSubHeaderProps = {
    viewKey: VIEW_KEY;
    date: Date;
    handleNavigate: (props: NavigationI) => void;
};

export default function CalendarSubHeader({
    date,
    viewKey,
    handleNavigate,
}: CalendarSubHeaderProps) {
    const breakpoint = useMediaQuery("sm");

    if (viewKey === VIEW_KEY.WEEK || (viewKey === VIEW_KEY.DAY && !breakpoint)) {
        return <CalendarHeaderWeek date={date} setNavigate={handleNavigate} />;
    } else if (viewKey === VIEW_KEY.DAY && breakpoint) {
        return <Separator className={"w-full"} />;
    }
    return <CalendarHeaderMonth date={date} />;
}
