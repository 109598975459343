import { useFilesStorage } from "@/store/filesStore";
import { useRef } from "react";
import { FsFileCard } from "@/components/features/files/FsFileCard";
import { useFSEntityInteraction } from "@/components/features/files/hooks/useFSEntityInteraction";
import FSEntityWrapper from "@/components/features/files/FSEntityWrapper";
import type { FilePublicWithPermissions } from "@/types";

interface FileProps {
    file: FilePublicWithPermissions;
}

export default function File({ file }: FileProps) {
    const fileRef = useRef<HTMLDivElement>(null);
    const { setOpenViewer, setFileToOpen } = useFilesStorage();

    const openFile = () => {
        setFileToOpen(file);
        setOpenViewer(true);
    };

    const {
        isEntitySelected,
        size,
        handleClick,
        handleKeyDown,
        handleDoubleClick,
        longPressHandlers,
    } = useFSEntityInteraction({
        entity: file,
        entityType: "file",
        onOpen: openFile,
    });

    return (
        <FSEntityWrapper entity={file} entityType="file">
            <FsFileCard
                aria-selected={isEntitySelected}
                tabIndex={0}
                ref={fileRef}
                onClick={handleClick}
                onDoubleClick={handleDoubleClick}
                onKeyDown={handleKeyDown}
                onPointerDown={longPressHandlers.onPointerDown}
                onPointerMove={longPressHandlers.onPointerMove}
                onPointerUp={longPressHandlers.onPointerUp}
                file={file}
                disabledMenu={size > 1}
                className="aria-selected:bg-surface-secondary aria-selected:border-border-primary-active"
            />
        </FSEntityWrapper>
    );
}
