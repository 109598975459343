import { MediaViewerFile } from "@/components/features/media/MediaViewer";
import { Button } from "@/components/ui/button/Button";
import {
    DialogBody,
    DialogFooter,
    DialogHeader,
    DialogTitle,
    Dialog,
    DialogContent,
    DialogDescription,
} from "@/components/ui/dialog/Dialog";
import { Link } from "@/components/ui/link/Link";
import VideoPlayer from "@/components/ui/media-viewer/VideoPlayer";
import getFileUrl from "@/utils/getFileUrl";
import { VideoMimeType } from "@vidstack/react";
import { Download } from "lucide-react";
import { useMemo } from "react";

export default function MediaVideoViewer({ file, open, onOpenChange }: MediaViewerFile) {
    const isFilesInstance = "id" in file;

    const { fileURL, type } = useMemo(() => {
        if ("id" in file) {
            return { fileURL: getFileUrl(file.id), type: file.fileType };
        } else if (file instanceof File) {
            return { fileURL: URL.createObjectURL(file), type: file.type };
        }
        return { fileURL: "", type: "" };
    }, [file]);

    return (
        <Dialog open={open} onOpenChange={onOpenChange}>
            <DialogContent className={"h-full"}>
                <DialogBody>
                    <DialogHeader>
                        <DialogTitle>{file.name}</DialogTitle>
                        <DialogDescription className={"sr-only"}>
                            {file.name} - podgląd
                        </DialogDescription>
                    </DialogHeader>
                    <VideoPlayer
                        src={{
                            src: fileURL,
                            type: type as VideoMimeType,
                        }}
                        title={file.name}
                        className={"h-full p-3 md:p-4"}
                    />
                </DialogBody>
                <DialogFooter>
                    {isFilesInstance ? (
                        <Link href={fileURL}>
                            <Button variant={"ghost"}>
                                <Download />
                            </Button>
                        </Link>
                    ) : null}
                </DialogFooter>
            </DialogContent>
        </Dialog>
    );
}
