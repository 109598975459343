import Message, { MessageSkeleton } from "@/components/features/chat/Message";
import {
    EmptyState,
    EmptyStateCircles,
    EmptyStateContent,
    EmptyStateDescription,
    EmptyStateTitle,
} from "@/components/ui/empty-state/EmptyState";
import { FeaturedIcon } from "@/components/ui/featured-icon/FeaturedIcon";
import { InfiniteScroll } from "@/components/ui/infinite-scroll/InfiniteScroll";
import { useChatStore } from "@/store/chatStore";
import type { ConversationType } from "@/types";
import { MessageCircle } from "lucide-react";
import { forwardRef, useMemo } from "react";
import { chatSocketService } from "../../ChatSocketService";

interface ChatConversationContentProps {
    conversationType: ConversationType;
}

const ChatContent = forwardRef<HTMLDivElement, ChatConversationContentProps>(
    ({ conversationType }, ref) => {
        const connectionStatus = useChatStore(state => state.connectionStatus);
        const messages = useChatStore(state => state.messages);
        const hasMore = useChatStore(state => state.hasMore);
        const isLoading = useChatStore(state => state.loading);
        const isEmpty = useMemo(
            () =>
                messages.length === 0 && !isLoading && !hasMore && connectionStatus === "connected",
            [messages, isLoading, connectionStatus],
        );

        const nextPage = () => {
            chatSocketService.requestHistory();
        };

        return (
            <div
                ref={ref}
                aria-label="Chat messages"
                aria-live="polite"
                className={
                    "flex p-2 md:p-4 h-full w-full grow flex-col-reverse overflow-x-hidden overflow-y-scroll overscroll-contain scroll-smooth"
                }
                style={{
                    overflowAnchor: "none",
                }}
            >
                <div className={"flex w-full flex-col gap-2"}>
                    <InfiniteScroll
                        hasNextPage={hasMore}
                        isLoading={isLoading}
                        isFetching={isLoading}
                        next={nextPage}
                        threshold={0.65}
                        reverse={true}
                        rootMargin={"0px 0px 100px 0px"}
                    >
                        {hasMore && !isLoading && connectionStatus === "connected" && (
                            <MessageSkeleton />
                        )}
                    </InfiniteScroll>

                    {messages.map(message => (
                        <Message
                            key={message.id ? message.id : message.tempUUID}
                            conversationType={conversationType}
                            message={message}
                        />
                    ))}
                    {isEmpty && (
                        <EmptyState className="mb-26">
                            <EmptyStateCircles className={"z-0"} />
                            <FeaturedIcon size={"lg"} className={"z-10"}>
                                <MessageCircle />
                            </FeaturedIcon>
                            <EmptyStateContent>
                                <EmptyStateTitle>Brak wiadomości</EmptyStateTitle>
                                <EmptyStateDescription>
                                    Brak wiadomości dla wskazanego chatu, <br />
                                    bądź pierwszym który wyśle wiadomość.
                                </EmptyStateDescription>
                            </EmptyStateContent>
                        </EmptyState>
                    )}
                </div>
            </div>
        );
    },
);
ChatContent.displayName = "ChatContent";

export default ChatContent;
