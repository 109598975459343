import { cn } from "@/lib/utils";
import React, { HTMLAttributes } from "react";
import { VariantProps, cva } from "class-variance-authority";
import { Slot } from "@radix-ui/react-slot";

const ButtonGroup = React.forwardRef<HTMLDivElement, HTMLAttributes<HTMLDivElement>>(
    ({ className, ...props }, ref) => {
        return (
            <div
                ref={ref}
                className={cn(
                    "inline-flex w-fit -space-x-px rounded-md rtl:space-x-reverse",
                    className,
                )}
                {...props}
            />
        );
    },
);
ButtonGroup.displayName = "ButtonGroup";

const buttonGroupButtonVariants = cva(
    "group relative inline-flex whitespace-nowrap overflow-hidden items-center justify-center rounded-none text-md font-medium ease-out duration-100 transition-colors transition-outline opacity-100 cursor-pointer disabled:cursor-not-allowed disabled:pointer-events-none focus-visible:z-10 outline-ring-focus outline-0 focus-visible:outline-2 disabled:opacity-75 [&_svg]:pointer-events-none [&_svg]:size-4 [&_svg]:shrink-0 bg-fill-primary text-text-primary *:stroke-icon-tertiary border border-border-primary hover:bg-fill-secondary-hover active:bg-fill-secondary-active",
    {
        variants: {
            size: {
                sm: "h-9 min-h-9 min-w-9 gap-2 px-2 text-sm first:rounded-s-sm last:rounded-e-sm",
                md: "h-10 min-h-10 min-w-10 gap-2 px-3 text-base first:rounded-s-sm last:rounded-e-sm",
                lg: "h-11 min-h-11 min-w-11 gap-3 px-3 text-base first:rounded-s-md last:rounded-e-md",
            },
        },
        defaultVariants: {
            size: "md",
        },
    },
);

export interface ButtonGroupButtonProps
    extends React.ButtonHTMLAttributes<HTMLButtonElement>,
        VariantProps<typeof buttonGroupButtonVariants> {
    asChild?: boolean;
}

const ButtonGroupButton = React.forwardRef<HTMLButtonElement, ButtonGroupButtonProps>(
    ({ className, size, asChild = false, ...props }, ref) => {
        const Comp = asChild ? Slot : "button";
        return (
            <Comp
                ref={ref}
                className={cn(buttonGroupButtonVariants({ size, className }))}
                {...props}
            />
        );
    },
);
ButtonGroupButton.displayName = "ButtonGroupButton";

export { ButtonGroup, ButtonGroupButton, buttonGroupButtonVariants };
