import { Avatar, AvatarFallback, AvatarImage } from "@/components/ui/avatar/Avatar";
import type { UserPublic } from "@/types";
import { getAvatar } from "@/utils/getAvatar";
import { getUserLvl } from "@/utils/getUserLvl";
import { User } from "lucide-react";

interface UserCardProps {
    user: UserPublic;
}

export default function UserCard({ user }: UserCardProps) {
    const { avatarURL, name, surname, lvl } = user;

    return (
        <div
            className={
                "group flex items-center justify-between gap-3 rounded-sm py-2 min-w-0 flex-1 overflow-hidden"
            }
        >
            <Avatar size={"md"}>
                <AvatarImage src={getAvatar(avatarURL)} alt={"avatar"} />
                <AvatarFallback>
                    <User />
                </AvatarFallback>
            </Avatar>
            <div className={"flex flex-col gap-0.5 w-full min-w-0"}>
                <p className={"w-full truncate text-sm text-text-secondary"}>
                    {name} {surname}
                </p>
                <small className={"text-xs text-text-tertiary"}>{getUserLvl(lvl)}</small>
            </div>
        </div>
    );
}
