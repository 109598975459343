import { GetMeetingsProps } from "@/api/endpoints/meetings";
import { useGetGroupMeetingsInfiniteQuery } from "@/api/queries/groupsQueries";
import CalendarCard from "@/components/features/calendar/CalendarCard";
import {
    ContextMenu,
    ContextMenuContent,
    ContextMenuGroup,
    ContextMenuItem,
    ContextMenuLabel,
    ContextMenuSeparator,
    ContextMenuTrigger,
} from "@/components/ui/context-menu/ContextMenu";
import { useCredentials } from "@/store/authStore";
import type { GroupPrivate } from "@/types";
import { endOfDay, format, startOfDay } from "date-fns";
import { pl } from "date-fns/locale";
import { ReactElement, useCallback, useState } from "react";
import { useNavigate } from "react-router";
import { CalendarFormSchemaType } from "@/schemas/meeting.schema";

interface GroupMeetingsProps {
    groupId: number;
    group?: GroupPrivate;
    className?: string;
}

export default function GroupMeetings({ groupId, group, className }: GroupMeetingsProps) {
    const navigate = useNavigate();
    const { lvl } = useCredentials();
    const [queryParams, setQueryParams] = useState<GetMeetingsProps>({
        id: groupId,
        after: startOfDay(new Date()).toISOString(),
        before: endOfDay(new Date()).toISOString(),
        sortOrder: "ASC",
        page: 0,
        pageSize: 15,
    });

    const query = useGetGroupMeetingsInfiniteQuery(queryParams);

    const handleRenderContextMenu = useCallback(
        (children: ReactElement, day: Date, key: string) => {
            const handleNavigateDay = () => {
                navigate(`/calendar/?viewKey=day&date=${format(day, "dd-MM-yyyy")}`);
            };

            const handleOpenMeetingForm = () => {
                navigate(`/calendar/?viewKey=day&date=${format(day, "dd-MM-yyyy")}`, {
                    state: {
                        action: "createMeeting",
                        value: { date: day.toISOString(), groups: [group] },
                    } as Partial<CalendarFormSchemaType>,
                });
            };

            return (
                <ContextMenu key={key}>
                    <ContextMenuTrigger asChild>{children}</ContextMenuTrigger>
                    <ContextMenuContent>
                        <ContextMenuLabel>
                            {format(day, "EEEE, d.MM.yyyy", { locale: pl })}
                        </ContextMenuLabel>
                        <ContextMenuSeparator />
                        <ContextMenuGroup>
                            <ContextMenuItem onClick={handleNavigateDay}>
                                Otwórz w kalendarzu
                            </ContextMenuItem>
                            <ContextMenuItem onClick={handleOpenMeetingForm}>
                                Utwórz spotkanie
                            </ContextMenuItem>
                        </ContextMenuGroup>
                    </ContextMenuContent>
                </ContextMenu>
            );
        },
        [group, navigate],
    );

    return (
        <CalendarCard
            setQueryParams={setQueryParams}
            query={query}
            renderNavigateContextMenu={lvl > 0 ? handleRenderContextMenu : undefined}
            defaultScheduleParams={{
                before: startOfDay(new Date(Date.now() + 5 * 24 * 60 * 60 * 1000)).toISOString(),
            }}
            className={className}
            defaultType={"day"}
        />
    );
}
