import {
    deleteChat,
    editChat,
    getChat,
    getCreateConversationUsers,
    getLastConversations,
    postChatFileUpload,
    postChat,
    ChatConversationProps,
    ChatFileUploadProps,
} from "@/api/endpoints/chat";
import { queryClient } from "@/api/query-client";
import { ChatEditSchemaType } from "@/schemas/chat.schema";
import { useInfiniteQuery, useMutation, useQuery } from "@tanstack/react-query";
import { useEffect } from "react";
import { ConversationPublic } from "@/types/chat";

export interface GetCreateConversationUsersInfiniteQueryProps {
    name?: string;
}

export type ConversationsListFilter = "all" | "users" | "groups";

export interface GetLastConversationsInfiniteQueryProps {
    name?: string;
    type?: ConversationsListFilter;
}

export const useGetConversationQuery = (id: number) => {
    return useQuery({
        queryKey: ["chat", id],
        queryFn: () => getChat(id),
    });
};

export const useGetCreateConversationUsersInfiniteQuery = ({
    name,
}: GetCreateConversationUsersInfiniteQueryProps) => {
    return useInfiniteQuery({
        queryKey: ["conversationUsersInfinite", name],
        queryFn: ({ pageParam }) => getCreateConversationUsers({ ...pageParam, name }),
        initialPageParam: { page: 0, pageSize: 20, name: undefined },
        getNextPageParam: (lastPage, allPages, lastPageParam) => {
            if (lastPage.length < lastPageParam.pageSize) {
                return undefined;
            }
            return { ...lastPageParam, page: lastPageParam.page + 1 };
        },
    });
};

export const useGetLastConversationsInfiniteQuery = ({
    name,
    type,
}: GetLastConversationsInfiniteQueryProps) => {
    useEffect(() => {
        const eventSource = new EventSource(
            import.meta.env.VITE_API_DOMAIN + "/api/chat/messages/sse",
            {
                withCredentials: true,
            },
        );
        eventSource.onmessage = (event: MessageEvent) => {
            try {
                const data: ConversationPublic = JSON.parse(event.data);
                queryClient.invalidateQueries({ queryKey: ["lastConversationsInfinite"] });
            } catch {
                console.error("Failed to parse event data");
            }
        };

        return () => eventSource.close();
    }, []);
    return useInfiniteQuery({
        queryKey: ["lastConversationsInfinite", name],
        queryFn: ({ pageParam }) => getLastConversations({ ...pageParam, name }),
        initialPageParam: { page: 0, pageSize: 20, name: undefined },
        getNextPageParam: (lastPage, allPages, lastPageParam) => {
            if (lastPage.length < lastPageParam.pageSize) {
                return undefined;
            }
            return { ...lastPageParam, page: lastPageParam.page + 1 };
        },
    });
};

export const usePostConversationMutation = () => {
    return useMutation({
        mutationFn: (data: ChatConversationProps) => postChat(data),
        onSuccess: () => {
            void queryClient.invalidateQueries({
                queryKey: ["lastConversationsInfinite"],
            });
        },
    });
};

export const useEditConversationMutation = () => {
    return useMutation({
        mutationFn: (data: ChatEditSchemaType) => editChat(data),
        onSuccess: () => {
            void queryClient.invalidateQueries({
                queryKey: ["lastConversationsInfinite"],
            });
        },
    });
};

export const useDeleteConversationMutation = () => {
    return useMutation({
        mutationFn: (id: number) => deleteChat(id),
        onSuccess: () => {
            void queryClient.invalidateQueries({
                queryKey: ["lastConversationsInfinite"],
            });
        },
    });
};

export const usePostFileUploadMutation = () => {
    return useMutation({
        mutationKey: ["postChatFileUpload"],
        mutationFn: (data: ChatFileUploadProps) => postChatFileUpload(data),
    });
};
