import { buttonVariants } from "@/components/ui/button/Button";
import { cn } from "@/lib/utils";
import {
    FullscreenButton,
    MuteButton,
    PIPButton,
    PlayButton,
    useMediaState,
} from "@vidstack/react";
import {
    Minimize as FullscreenExitIcon,
    Maximize as FullscreenIcon,
    VolumeX as MuteIcon,
    PauseIcon,
    PictureInPictureIcon as PictureInPictureExitIcon,
    PictureInPicture2 as PictureInPictureIcon,
    PlayIcon,
    Volume2 as VolumeHighIcon,
    Volume1 as VolumeLowIcon,
    LoaderCircle,
} from "lucide-react";
import { useMemo } from "react";

export interface MediaButtonProps {
    className?: string;
}

export function Play({ className }: MediaButtonProps) {
    const isPaused = useMediaState("paused");
    const buffered = useMediaState("buffered");

    const PlayButtonIcon = useMemo(() => {
        if (buffered.length) {
            return isPaused ? (
                <PlayIcon className={"size-5 translate-x-px"} />
            ) : (
                <PauseIcon className={"size-5"} />
            );
        } else {
            return (
                <LoaderCircle className={"animate-spin text-text-tertiary"} aria-hidden={"true"} />
            );
        }
    }, [isPaused, buffered]);

    return (
        <PlayButton
            className={cn(
                buttonVariants({
                    variant: "ghost",
                    size: "sm",
                }),
                className,
            )}
        >
            {PlayButtonIcon}
        </PlayButton>
    );
}

export function Mute() {
    const volume = useMediaState("volume"),
        isMuted = useMediaState("muted");
    return (
        <MuteButton
            className={buttonVariants({
                variant: "ghost",
                size: "sm",
            })}
        >
            {isMuted || volume == 0 ? (
                <MuteIcon className={"size-5"} />
            ) : volume < 0.5 ? (
                <VolumeLowIcon className={"size-5"} />
            ) : (
                <VolumeHighIcon className={"size-5"} />
            )}
        </MuteButton>
    );
}

export function Pip() {
    const isActive = useMediaState("pictureInPicture");
    return (
        <PIPButton
            className={buttonVariants({
                variant: "ghost",
                size: "sm",
            })}
        >
            {isActive ? (
                <PictureInPictureExitIcon className={"size-5"} />
            ) : (
                <PictureInPictureIcon className={"size-5"} />
            )}
        </PIPButton>
    );
}

export function Fullscreen() {
    const isActive = useMediaState("fullscreen");
    return (
        <FullscreenButton
            className={buttonVariants({
                variant: "ghost",
                size: "sm",
            })}
        >
            {isActive ? (
                <FullscreenExitIcon className={"size-5"} />
            ) : (
                <FullscreenIcon className={"size-5"} />
            )}
        </FullscreenButton>
    );
}
