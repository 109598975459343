import { Avatar, AvatarFallback, AvatarImage } from "@/components/ui/avatar/Avatar";
import type { GroupPublic } from "@/types";
import { getAvatar } from "@/utils/getAvatar";
import { Users } from "lucide-react";
import { Link } from "react-router-dom";

interface GroupCardSmallProps {
    group: GroupPublic;
}

export default function GroupCardSmall({ group }: GroupCardSmallProps) {
    const { id, name, desc, avatarURL } = group;

    return (
        <Link
            className={"group flex h-fit items-center justify-between gap-3 rounded-sm py-2"}
            to={`/group/${id}`}
        >
            <div className={"flex items-center gap-3"}>
                <Avatar size={"sm"}>
                    <AvatarImage alt={"avatar"} src={getAvatar(avatarURL)} />
                    <AvatarFallback>
                        <Users />
                    </AvatarFallback>
                </Avatar>
                <div className={"flex flex-col gap-1"}>
                    <h5 className={"w-full truncate text-md"}>{name}</h5>
                    <small className={"text-xs text-text-muted truncate"}>{desc}</small>
                </div>
            </div>
        </Link>
    );
}
