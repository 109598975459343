import { httpErrorHandler } from "@/api/api";
import { useCreateDirectoryMutation } from "@/api/queries/directoryQueries";
import { Button } from "@/components/ui/button/Button";
import {
    Dialog,
    DialogBody,
    DialogContent,
    DialogFooter,
    DialogHeader,
    DialogTitle,
    DialogDescription,
} from "@/components/ui/dialog/Dialog";
import { Form, FormControl, FormField, FormItem, FormMessage } from "@/components/ui/form/Form";
import { Input, InputWrapper } from "@/components/ui/input/Input";
import { CreateDirectorySchema, CreateDirectorySchemaType } from "@/schemas/directory.schema";
import { yupResolver } from "@hookform/resolvers/yup";
import { LoaderCircle, ArrowRight, Folder } from "lucide-react";
import { useEffect } from "react";
import { useForm } from "react-hook-form";

interface DirectoryAddFormProps {
    parentDirId: string;
    onOpenChange: (open: boolean) => void;
    open: boolean;
}

export default function CreateFSDir({ parentDirId, onOpenChange, open }: DirectoryAddFormProps) {
    const { mutateAsync, isPending } = useCreateDirectoryMutation();

    const form = useForm<CreateDirectorySchemaType>({
        mode: "onBlur",
        defaultValues: {
            name: "Nowy Folder",
            parentDirId: parentDirId,
        },
        resolver: yupResolver(CreateDirectorySchema),
    });

    const onClose = () => {
        form.reset();
        onOpenChange(false);
    };

    const onSubmit = (data: CreateDirectorySchemaType) => {
        mutateAsync(data)
            .then(() => {
                onOpenChange(false);
            })
            .catch(error => {
                const { title } = httpErrorHandler(error);
                form.setError("name", { type: "custom", message: title });
            });
    };

    useEffect(() => {
        if (open) {
            form.reset({
                name: "Nowy Folder",
                parentDirId: parentDirId,
            });
        }
    }, [form, open, parentDirId]);

    return (
        <Dialog open={open} onOpenChange={onOpenChange}>
            <DialogContent
                className={"sm:max-w-108"}
                onCloseAutoFocus={onClose}
                onEscapeKeyDown={onClose}
            >
                <DialogDescription className={"sr-only"}>
                    Formularz do tworzenia folderu
                </DialogDescription>
                <Form {...form}>
                    <DialogBody>
                        <DialogHeader>
                            <DialogTitle>Nowy Folder</DialogTitle>
                        </DialogHeader>
                        <form
                            className={"flex flex-col gap-3 p-3 md:p-4 overflow-auto grow"}
                            onSubmit={form.handleSubmit(onSubmit)}
                            noValidate
                        >
                            <FormField
                                name={"name"}
                                control={form.control}
                                render={({ field }) => (
                                    <FormItem className={"col-span-1"}>
                                        <FormControl>
                                            <InputWrapper>
                                                <Input
                                                    {...field}
                                                    placeholder={"Nazwa"}
                                                    className="peer ps-9"
                                                />
                                                <div className="text-text-tertiary pointer-events-none absolute inset-y-0 start-0 flex items-center justify-center ps-3 peer-disabled:opacity-50">
                                                    <Folder aria-hidden="true" />
                                                </div>
                                            </InputWrapper>
                                        </FormControl>
                                        <FormMessage />
                                    </FormItem>
                                )}
                            />
                        </form>
                    </DialogBody>
                    <DialogFooter>
                        <Button
                            onClick={onClose}
                            type={"button"}
                            variant={"tertiary"}
                            className={"w-full md:w-fit"}
                        >
                            Anuluj
                        </Button>
                        <Button
                            disabled={isPending}
                            onClick={form.handleSubmit(onSubmit)}
                            type={"submit"}
                            variant={"primary"}
                            className={"w-full md:w-fit"}
                        >
                            {isPending ? (
                                <>
                                    <LoaderCircle className={"animate-spin"} aria-hidden={"true"} />
                                    Zapisywanie...
                                </>
                            ) : (
                                <>
                                    Utwórz
                                    <ArrowRight
                                        className={
                                            " transition-transform group-hover:translate-x-0.5"
                                        }
                                    />
                                </>
                            )}
                        </Button>
                    </DialogFooter>
                </Form>
            </DialogContent>
        </Dialog>
    );
}
