import {
    addGroup,
    deleteGroup,
    editGroup,
    getAssignedGroups,
    getGroup,
    getGroupMeetings,
    getGroupUsers,
    getGroups,
    type GetAssignedGroupsInfiniteQueryProps,
    type GetGroupsInfiniteQueryProps,
} from "@/api/endpoints/groups";
import { GetMeetingsProps } from "@/api/endpoints/meetings";
import { queryClient } from "@/api/query-client";
import { GroupAddSchemaType, GroupEditType } from "@/schemas/group.schema";
import type { GroupPrivate } from "@/types";
import { useInfiniteQuery, useMutation, useQuery } from "@tanstack/react-query";
import {
    GroupAvatarUploadParams,
    postGroupAvatar,
    deleteGroupAvatar,
} from "@/api/endpoints/groups";

export const useGetGroupsInfiniteQuery = ({
    name,
    enabled = true,
}: GetGroupsInfiniteQueryProps) => {
    return useInfiniteQuery({
        queryKey: ["groupsInfinite", name],
        queryFn: ({ pageParam }) => getGroups({ ...pageParam, name }),
        initialPageParam: { page: 0, pageSize: 20, name: undefined },
        getNextPageParam: (lastPage, allPages, lastPageParam) => {
            if (lastPage.length < lastPageParam.pageSize) {
                return undefined;
            }
            return { ...lastPageParam, page: lastPageParam.page + 1 };
        },
        enabled: enabled || !!name ? true : false,
    });
};

export const useGetAssignedGroupsInfiniteQuery = ({
    name,
}: GetAssignedGroupsInfiniteQueryProps) => {
    return useInfiniteQuery({
        queryKey: ["assignedGroupsInfinite", name],
        queryFn: ({ pageParam }) => getAssignedGroups({ ...pageParam, name }),
        initialPageParam: { page: 0, pageSize: 20, name: undefined },
        getNextPageParam: (lastPage, allPages, lastPageParam) => {
            if (lastPage.length < lastPageParam.pageSize) {
                return undefined;
            }
            return { ...lastPageParam, page: lastPageParam.page + 1 };
        },
    });
};

export const useGetGroupsQuery = () => {
    return useQuery({
        queryKey: ["groups"],
        queryFn: () => getGroups(),
    });
};

export const useGetGroupQuery = (id?: number) => {
    return useQuery({
        queryKey: ["groups", id],
        queryFn: () => getGroup(id),
        enabled: !!id, // only run the query if id is not null or undefined
    });
};

export const useGetGroupMeetingsInfiniteQuery = (queryParams: GetMeetingsProps) => {
    return useInfiniteQuery({
        queryKey: ["groupMeetingsInfinite", queryParams],
        queryFn: ({ pageParam }) => {
            return getGroupMeetings(pageParam);
        },
        initialPageParam: {
            page: 0,
            pageSize: 20,
            name: undefined,
            sortOrder: "ASC" as const,
            ...queryParams,
        },
        getNextPageParam: (lastPage, allPages, lastPageParam) => {
            if (lastPage.length < lastPageParam.pageSize) {
                return undefined;
            }
            return { ...lastPageParam, page: lastPageParam.page + 1 };
        },
    });
};

export const getGroupPrefetch = ({
    id,
    initialData,
}: {
    id: number;
    initialData: GroupPrivate;
}) => {
    void queryClient.prefetchQuery({
        queryKey: ["groups", id],
        queryFn: () => getGroup(id),
        initialData: initialData,
        staleTime: 60000,
    });
};

export const useGetGroupUsersQuery = (id?: number) => {
    return useQuery({
        queryKey: ["groupUsers", id],
        queryFn: () => getGroupUsers(id),
        enabled: !!id, // only run the query if id is not null or undefined
    });
};

export const usePostGroupMutation = () => {
    return useMutation({
        mutationFn: (data: GroupAddSchemaType) => addGroup(data),
        onSuccess: data => {
            queryClient.setQueryData(["groups"], (curr: GroupPrivate[]) => [...curr, data]);
        },
    });
};

export const useEditGroupMutation = () => {
    return useMutation({
        mutationFn: (data: GroupEditType) => editGroup(data),

        onSettled: (data, error, variables) => {
            void queryClient.invalidateQueries({
                queryKey: ["groups"],
            });
            void queryClient.invalidateQueries({
                queryKey: ["groups", variables.id],
            });
            void queryClient.invalidateQueries({
                queryKey: ["groupUsers", variables.id],
            });

            variables.memberChanges?.forEach(memberChange => {
                memberChange.userIds?.forEach(userId => {
                    void queryClient.invalidateQueries({
                        queryKey: ["userGroups", userId],
                    });
                });
            });
        },
    });
};

export const useDeleteGroupMutation = () => {
    return useMutation({
        mutationFn: (id: number) => deleteGroup(id),
        onMutate: async (id: number) => {
            await queryClient.cancelQueries({ queryKey: ["groups"] });

            const previousGroups = queryClient.getQueryData<GroupPrivate[]>(["groups"]);

            queryClient.setQueryData(["groups"], (prev?: GroupPrivate[]) =>
                prev ? prev.filter((group: GroupPrivate) => group.id !== id) : [],
            );

            return { previousGroups };
        },
        onError: (err, id, context) => {
            void queryClient.setQueryData(["groups"], context?.previousGroups);
        },
        onSettled: () => {
            void queryClient.invalidateQueries({ queryKey: ["groups"] });
        },
        onSuccess: (data, id) => {
            queryClient.setQueryData(["groups"], (prev?: GroupPrivate[]) =>
                prev ? prev.filter((group: GroupPrivate) => group.id !== id) : [],
            );
        },
    });
};

export const usePostGroupAvatarMutation = () => {
    return useMutation({
        mutationFn: (data: GroupAvatarUploadParams) => postGroupAvatar(data),
    });
};

export const useDeleteGroupAvatarMutation = () => {
    return useMutation({
        mutationFn: (id: number) => deleteGroupAvatar(id),
    });
};
