import { Alert, AlertDescription } from "@/components/ui/alert/Alert";
import { Button } from "@/components/ui/button/Button";
import {
    Dialog,
    DialogBody,
    DialogContent,
    DialogDescription,
    DialogFooter,
    DialogHeader,
    DialogTitle,
} from "@/components/ui/dialog/Dialog";
import { motion } from "framer-motion";
import {
    AlertCircle,
    ImagePlus,
    RefreshCcw,
    RotateCcw,
    RotateCw,
    LoaderCircle,
    CheckCircle2,
    MessagesSquare,
} from "lucide-react";
import {
    ChangeEvent,
    Dispatch,
    ReactNode,
    SetStateAction,
    useEffect,
    useRef,
    useState,
} from "react";
import {
    FixedCropper,
    FixedCropperRef,
    ImageRestriction,
    RectangleStencil,
} from "react-advanced-cropper";
import { ButtonGroup, ButtonGroupButton } from "@/components/ui/button/ButtonGroup";
import "react-advanced-cropper/dist/style.css";
import "react-advanced-cropper/dist/themes/classic.css";
import { FeaturedIcon } from "@/components/ui/featured-icon/FeaturedIcon";
import {
    EmptyState,
    EmptyStateCircles,
    EmptyStateContent,
    EmptyStateFooter,
    EmptyStateTitle,
} from "@/components/ui/empty-state/EmptyState";

export interface Image {
    type?: string;
    src: string;
}

export interface ImageCropperProps {
    defaultSrc?: string;
    open: boolean;
    onOpenChange: Dispatch<SetStateAction<boolean>>;
    onCrop: (blob: Blob) => Promise<void>;
    isPending?: boolean;
    title?: string;
    extraControls?: ReactNode;
    aspectRatio?: number;
    maxHeight?: number;
    maxWidth?: number;
    image?: Image | null;
    setImage?: Dispatch<SetStateAction<Image | null>>;
}

export function ImageCropper({
    defaultSrc,
    open,
    onOpenChange,
    onCrop,
    isPending = false,
    title = "Edit Image",
    extraControls,
    aspectRatio = 1,
    maxHeight = 256,
    maxWidth = 256,
    image: externalImage,
    setImage: externalSetImage,
}: ImageCropperProps) {
    const inputRef = useRef<HTMLInputElement>(null);
    const cropperRef = useRef<FixedCropperRef>(null);

    const [internalImage, setInternalImage] = useState<Image | null>(null);

    // Use external state if provided, otherwise use internal state
    const image = externalImage !== undefined ? externalImage : internalImage;
    const setImage = externalSetImage || setInternalImage;

    const [errorMessage, setErrorMessage] = useState<string | null>(null);

    useEffect(() => {
        if (defaultSrc && open) {
            setImage({ src: defaultSrc });
        }
    }, [open, defaultSrc]);

    const onClose = () => {
        setImage(null);
        onOpenChange(false);
        setErrorMessage(null);
    };

    const clickFileInput = () => {
        if (inputRef.current) {
            inputRef.current.click();
        }
    };

    const rotate = (angle: number) => {
        if (cropperRef.current) {
            cropperRef.current.rotateImage(angle);
        }
    };

    const reset = () => {
        if (cropperRef.current) {
            cropperRef.current.reset();
        }
    };

    const onLoadImage = (event: ChangeEvent<HTMLInputElement>) => {
        const { files } = event.target;
        if (files && files.length > 0) {
            const blob = URL.createObjectURL(files[0]);

            setImage({
                src: blob,
                type: files[0].type,
            });
        }
        event.target.value = "";
    };

    const onSubmit = () => {
        if (!cropperRef.current) {
            return;
        }
        const canvas = cropperRef.current.getCanvas({
            minHeight: 1,
            minWidth: 1,
            maxHeight,
            maxWidth,
        });

        if (canvas) {
            canvas.toBlob(async blob => {
                if (blob) {
                    try {
                        await onCrop(blob);
                        onClose();
                    } catch (error) {
                        setErrorMessage(error instanceof Error ? error.message : String(error));
                    }
                }
            }, "image/png");
        }
    };

    useEffect(() => {
        return () => {
            if (image && image.src && image.src.startsWith("blob:")) {
                URL.revokeObjectURL(image.src);
            }
        };
    }, [image]);

    return (
        <Dialog open={open} onOpenChange={onOpenChange}>
            <DialogContent onCloseAutoFocus={onClose} onEscapeKeyDown={onClose}>
                <DialogBody className={"grow"}>
                    <DialogHeader className={"border-b border-border-primary"}>
                        <DialogTitle>{title}</DialogTitle>
                    </DialogHeader>
                    <DialogDescription className={"sr-only"}>
                        Wybierz zdjęcie i wykadruj je w odpowiednim miejscu.
                    </DialogDescription>
                    <div
                        className={"flex flex-col items-center gap-6 p-3 md:p-4 overflow-auto grow"}
                    >
                        <input
                            className={"hidden"}
                            ref={inputRef}
                            type={"file"}
                            accept={"image/*"}
                            onChange={onLoadImage}
                        />
                        <div
                            className={
                                "relative flex h-full max-h-[400px] min-h-[256px] w-full items-center justify-center overflow-hidden rounded-lg"
                            }
                        >
                            {image?.src ? (
                                <FixedCropper
                                    ref={cropperRef}
                                    className={"min-h-full min-w-full bg-bg-element"}
                                    src={image.src}
                                    stencilProps={{
                                        grid: true,
                                        aspectRatio,
                                    }}
                                    stencilComponent={RectangleStencil}
                                    stencilSize={{ width: maxWidth, height: maxHeight }}
                                    imageRestriction={ImageRestriction.stencil}
                                />
                            ) : (
                                <EmptyState>
                                    <EmptyStateCircles className={"z-0"} />
                                    <FeaturedIcon size={"lg"} className={"z-10"}>
                                        <ImagePlus />
                                    </FeaturedIcon>
                                    <EmptyStateContent>
                                        <EmptyStateTitle>Nie wybrano zdjęcia</EmptyStateTitle>

                                        <EmptyStateFooter>
                                            <Button
                                                onClick={clickFileInput}
                                                variant={"outline"}
                                                size={"sm"}
                                            >
                                                Wybierz zdjęcie
                                            </Button>
                                        </EmptyStateFooter>
                                    </EmptyStateContent>
                                </EmptyState>
                            )}
                        </div>
                        <ButtonGroup className={"w-full max-w-90"}>
                            <ButtonGroupButton className={"w-full"} onClick={() => rotate(-45)}>
                                <RotateCcw />
                            </ButtonGroupButton>
                            <ButtonGroupButton className={"w-full"} onClick={() => rotate(45)}>
                                <RotateCw />
                            </ButtonGroupButton>
                            <ButtonGroupButton className={"w-full"} onClick={() => reset()}>
                                <RefreshCcw />
                            </ButtonGroupButton>
                            <ButtonGroupButton
                                className={"w-full"}
                                onClick={() => clickFileInput()}
                            >
                                <ImagePlus />
                            </ButtonGroupButton>
                            {extraControls}
                        </ButtonGroup>
                    </div>
                    <motion.div
                        initial={{ opacity: 0, height: 0 }}
                        animate={{
                            opacity: errorMessage ? 1 : 0,
                            height: errorMessage ? "auto" : 0,
                        }}
                        transition={{ duration: 0.2 }}
                        className={"px-6 py-4 sm:px-4"}
                    >
                        <Alert variant={"destructive"}>
                            <FeaturedIcon>
                                <AlertCircle />
                            </FeaturedIcon>
                            <AlertDescription>{errorMessage}</AlertDescription>
                        </Alert>
                    </motion.div>
                </DialogBody>
                <DialogFooter className={"border-t border-border-primary"}>
                    <Button
                        className={"w-full md:w-fit"}
                        type={"button"}
                        variant={"tertiary"}
                        onClick={onClose}
                    >
                        Cancel
                    </Button>
                    <Button
                        disabled={isPending}
                        className={"w-full md:w-fit"}
                        type={"button"}
                        onClick={onSubmit}
                    >
                        {isPending ? (
                            <>
                                <LoaderCircle className={"animate-spin"} aria-hidden={"true"} />
                                Zapisywanie...
                            </>
                        ) : (
                            <>
                                <CheckCircle2 />
                                Zapisz
                            </>
                        )}
                    </Button>
                </DialogFooter>
            </DialogContent>
        </Dialog>
    );
}
