import { InferType, object, string, array } from "yup";
import type { MakeOptionalExcept } from "@/types";
import { PermissionChangesSchema } from "@/schemas/globals.schema";

const CreateDirectorySchema = object({
    name: string().required("Nazwa folderu jest wymagana"),
    parentDirId: string().required(),
});

type CreateDirectorySchemaType = InferType<typeof CreateDirectorySchema>;

const EditDirectorySchema = object({
    id: string().required(),
    name: string().required("Nazwa folderu jest wymagana"),
    parentDirId: string().optional(),
    permissionChanges: array().of(PermissionChangesSchema).optional(),
});

type EditDirectorySchemaType = InferType<typeof EditDirectorySchema>;

type EditDirectoryType = MakeOptionalExcept<EditDirectorySchemaType, "id">;

export { CreateDirectorySchema, EditDirectorySchema };
export type { CreateDirectorySchemaType, EditDirectorySchemaType, EditDirectoryType };
