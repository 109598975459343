import { axiosInstance } from "@/api/api";
import { AddLocationSchemaType, EditLocationSchemaType } from "@/schemas/location.schema";
import { LocationPublic } from "@/types";

export const getLocation = async (id?: number): Promise<LocationPublic> => {
    return await axiosInstance.get("/api/location", { params: { id } });
};

export const getLocations = async (): Promise<LocationPublic[]> => {
    return await axiosInstance.get("/api/location/getAll");
};

export const postLocation = async (data: AddLocationSchemaType): Promise<LocationPublic> => {
    return await axiosInstance.post("/api/location", data);
};

export const editLocation = async (data: EditLocationSchemaType): Promise<LocationPublic> => {
    return await axiosInstance.put("/api/location", data);
};

export const deleteLocation = async (id: number): Promise<string> => {
    return await axiosInstance.delete("/api/location", { data: { id: id } });
};
