import { Button } from "@/components/ui/button/Button";
import { DialogHeader, DialogTitle } from "@/components/ui/dialog/Dialog";
import { Input, InputWrapper } from "@/components/ui/input/Input";
import { ChevronLeft, Search, CircleX, CalendarPlus } from "lucide-react";
import { FeaturedIcon } from "@/components/ui/featured-icon/FeaturedIcon";
import type { Dispatch, SetStateAction } from "react";

type ManipulateMeetingContentHeaderProps = {
    tabs: string;
    setTabs: Dispatch<SetStateAction<string>>;
    searchValue: string;
    setSearchValue: Dispatch<SetStateAction<string>>;
};

export default function MeetingFormContentHeader({
    tabs,
    setTabs,
    searchValue,
    setSearchValue,
}: ManipulateMeetingContentHeaderProps) {
    if (tabs === "main") {
        return (
            <DialogHeader className={"border-b border-border-primary"}>
                <FeaturedIcon variant={"ghost"}>
                    <CalendarPlus />
                </FeaturedIcon>
                <div className={"flex flex-col gap-0.5 min-w-0 grow"}>
                    <DialogTitle>Spotkanie</DialogTitle>
                    <p className={"text-text-tertiary text-xs"}>Wprowadź dane spotkania.</p>
                </div>
            </DialogHeader>
        );
    }

    return (
        <DialogHeader className={"border-b border-border-primary"}>
            <DialogTitle className={"sr-only"}>
                {tabs === "usersSelect" ? "Dodaj użytkowników" : "Dodaj grupy"}
            </DialogTitle>

            <Button variant={"outline"} onClick={() => setTabs("main")}>
                <ChevronLeft />
            </Button>
            <InputWrapper className={"w-full"}>
                <Input
                    value={searchValue}
                    onChange={e => setSearchValue(e.target.value)}
                    className="peer pe-8 ps-8"
                    placeholder="Wyszukaj..."
                />
                <div className="pointer-events-none absolute inset-y-0 start-0 flex items-center justify-center ps-2 peer-disabled:opacity-50">
                    <Search className="size-3 stroke-icon-tertiary" />
                </div>
                {searchValue && (
                    <button
                        className="absolute cursor-pointer inset-y-0 end-0 flex h-full w-9 items-center justify-center rounded-e-sm text-text-tertiary transition-colors hover:text-text-tertiary-hover z-10 focus-visible:outline-2 outline-ring-focus disabled:pointer-events-none disabled:cursor-not-allowed disabled:opacity-50"
                        aria-label="Clear input"
                        onClick={() => setSearchValue("")}
                    >
                        <CircleX className={"size-3"} aria-hidden="true" />
                    </button>
                )}
            </InputWrapper>
        </DialogHeader>
    );
}
