"use no memo";
import { Button } from "@/components/ui/button/Button";
import {
    Table,
    TableBody,
    TableCell,
    TableFooter,
    TableHead,
    TableHeader,
    TableRow,
} from "@/components/ui/table/Table";
import { ColumnDef, flexRender, getCoreRowModel, useReactTable } from "@tanstack/react-table";
import { Plus } from "lucide-react";
import { Dispatch, SetStateAction, createContext, useContext, useState } from "react";

export interface DataTableDynamicPropsT<TData, TValue> {
    columns: ColumnDef<TData, TValue>[];
    newRowTemplate: TData;
    data: TData[];
    setData: Dispatch<SetStateAction<TData[]>>;
    getSelectedRows?: (rows: TData[]) => void;
}

export type editableRowT = Record<string, boolean>;

interface TanstackTableDynamicContextT {
    editedRows: editableRowT;
    setEditedRows: Dispatch<SetStateAction<editableRowT>>;
    addRow: () => void;
    removeRow: (rowIndex: number) => void;
    updateData: (rowIndex: number, columnId: string, value: unknown) => void;
    revertData: (rowIndex: number) => void;
    saveChanges: (rowIndex: number) => void;
    restoreOriginalData: () => void;
}

const TanstackTableDynamicContext = createContext<TanstackTableDynamicContextT>({
    editedRows: {},
    setEditedRows: () => {},
    addRow: () => {},
    removeRow: () => {},
    updateData: () => {},
    revertData: () => {},
    saveChanges: () => {},
    restoreOriginalData: () => {},
});

export const useTanstackTableDynamicContext = () => {
    return useContext(TanstackTableDynamicContext);
};

export default function TanstackTableDynamic<TData, TValue>({
    columns,
    newRowTemplate,
    data,
    setData,
}: DataTableDynamicPropsT<TData, TValue>) {
    const [originalData, setOriginalData] = useState<TData[]>([]);
    const [editedRows, setEditedRows] = useState<editableRowT>({});

    const restoreOriginalData = () => {
        if (data.length !== originalData.length) {
            setOriginalData(curr => [...curr, ...data]);
        }
    };

    const addRow = () => {
        setData([...data, newRowTemplate]);
        setOriginalData([...originalData, newRowTemplate]);
    };

    const updateData = (rowIndex: number, columnId: string, value: unknown) => {
        setData((curr: TData[]) => {
            return curr.map((row: TData, index: number) => {
                if (index === rowIndex) {
                    return {
                        ...curr[rowIndex],
                        [columnId]: value,
                    } as TData;
                }
                return row;
            });
        });
    };

    const revertData = (rowIndex: number) => {
        setData((curr: TData[]) =>
            curr.map((row, index) => (index === rowIndex ? originalData[rowIndex] : row)),
        );
    };

    const saveChanges = (rowIndex: number) => {
        setOriginalData(old =>
            old.map((row, index) => (index === rowIndex ? data[rowIndex] : row)),
        );
    };

    const removeRow = (rowIndex: number) => {
        setData(old => old.filter((_row, index) => index !== rowIndex));
        setOriginalData(old => old.filter((_row, index) => index !== rowIndex));
    };

    const table = useReactTable<TData>({
        data,
        columns,
        getCoreRowModel: getCoreRowModel(),
        enableRowSelection: true,
    });

    return (
        <TanstackTableDynamicContext.Provider
            value={{
                restoreOriginalData: restoreOriginalData,
                editedRows: editedRows,
                setEditedRows: setEditedRows,
                addRow: addRow,
                updateData: updateData,
                removeRow: removeRow,
                revertData: revertData,
                saveChanges: saveChanges,
            }}
        >
            <Table className={"h-fit text-center"}>
                <TableHeader className={"w-full overflow-x-auto"}>
                    {table.getHeaderGroups().map(headerGroup => (
                        <TableRow key={headerGroup.id}>
                            {headerGroup.headers.map(header => (
                                <TableHead className={"p-1 text-center"} key={header.id}>
                                    {header.isPlaceholder
                                        ? null
                                        : flexRender(
                                              header.column.columnDef.header,
                                              header.getContext(),
                                          )}
                                </TableHead>
                            ))}
                        </TableRow>
                    ))}
                </TableHeader>
                <TableBody>
                    {table.getRowModel().rows.map(row => (
                        <TableRow key={row.id}>
                            {row.getVisibleCells().map(cell => (
                                <TableCell key={cell.id}>
                                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                </TableCell>
                            ))}
                        </TableRow>
                    ))}
                </TableBody>
                <TableFooter className={"sticky bottom-0"}>
                    <TableRow className={"border-b-0 border-border-primary border-t"}>
                        <TableHead colSpan={table.getCenterLeafColumns().length}>
                            <FooterCell />
                        </TableHead>
                    </TableRow>
                </TableFooter>
            </Table>
        </TanstackTableDynamicContext.Provider>
    );
}

export function FooterCell() {
    const { addRow } = useTanstackTableDynamicContext();
    return (
        <div className={"flex justify-end gap-2 py-1"}>
            <Button variant={"ghost"} type={"button"} onClick={addRow}>
                <Plus />
                Dodaj
            </Button>
        </div>
    );
}
