import { cn } from "@/lib/utils";
import { forwardRef, HTMLAttributes } from "react";
import { cva, VariantProps } from "class-variance-authority";

type ListElement = HTMLUListElement | HTMLOListElement;
type ListProps = HTMLAttributes<ListElement> & {
    as?: "ul" | "ol";
};

const List = forwardRef<ListElement, ListProps>(({ as = "ul", className, ...props }, ref) => {
    if (as === "ol") {
        return (
            <ol
                ref={ref as React.ForwardedRef<HTMLOListElement>}
                className={cn("group/list flex flex-col", className)}
                {...props}
            />
        );
    }

    return (
        <ul
            ref={ref as React.ForwardedRef<HTMLUListElement>}
            className={cn("group/list flex flex-col", className)}
            {...props}
        />
    );
});

List.displayName = "List";

const listElementVariants = cva(
    "relative group/item text-text-primary bg-fill-primary hover:bg-fill-primary-hover active:bg-fill-primary-active text-text-primary inline-flex p-2 whitespace-nowrap overflow-hidden items-center rounded-md text-md ease-out duration-100 transition-colors transition-outline opacity-100 disabled:pointer-events-none outline-ring-focus outline-0 focus-visible:outline-2 disabled:opacity-75 [&_svg]:pointer-events-none [&_svg]:text-text-tertiary [&_svg]:shrink-0",
    {
        variants: {
            size: {
                default: "[&_svg]:size-4 gap-2 h-12 min-h-12",
                double: "[&_svg]:size-5 gap-3 h-14 min-h-14",
            },
        },
        defaultVariants: {
            size: "default",
        },
    },
);

interface ListItemProps
    extends HTMLAttributes<HTMLLIElement>,
        VariantProps<typeof listElementVariants> {}

const ListItem = forwardRef<HTMLLIElement, ListItemProps>(({ className, size, ...props }, ref) => {
    return (
        <li
            ref={ref}
            tabIndex={0}
            className={cn(listElementVariants({ size }), className)}
            {...props}
        />
    );
});
ListItem.displayName = "ListItem";

const ListItemContent = forwardRef<HTMLDivElement, HTMLAttributes<HTMLDivElement>>(
    ({ className, ...props }, ref) => {
        return <div ref={ref} className={cn("flex flex-col gap-0.5 grow", className)} {...props} />;
    },
);
ListItemContent.displayName = "ListItemContent";

const ListItemTitle = forwardRef<HTMLDivElement, HTMLAttributes<HTMLDivElement>>(
    ({ className, ...props }, ref) => {
        return (
            <div
                ref={ref}
                className={cn("text-text-primary truncate whitespace-nowrap text-sm", className)}
                {...props}
            />
        );
    },
);
ListItemTitle.displayName = "ListItemTitle";

const ListItemDescription = forwardRef<HTMLDivElement, HTMLAttributes<HTMLDivElement>>(
    ({ className, ...props }, ref) => {
        return (
            <div
                ref={ref}
                className={cn("text-text-tertiary truncate  whitespace-nowrap text-xs", className)}
                {...props}
            />
        );
    },
);
ListItemDescription.displayName = "ListItemDescription";

export { List, ListItem, ListItemContent, ListItemTitle, ListItemDescription, listElementVariants };
