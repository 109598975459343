import { MeetingPrivate, MeetingPublic } from "@/types";
import { UseInfiniteQueryResult, UseQueryResult } from "@tanstack/react-query";
import { ReactNode, createContext, useContext } from "react";

interface MeetingListContextT {
    meetings: MeetingPrivate[] | MeetingPublic[];
    isSuccess: boolean;
    isLoading: boolean;
    isFetching: boolean;
    fetchNextPage?: () => void;
    hasNextPage?: boolean;
}

const MeetingListContext = createContext<MeetingListContextT>({
    meetings: [],
    isSuccess: false,
    isLoading: false,
    isFetching: false,
});

export const useMeetingListContext = () => {
    return useContext(MeetingListContext);
};

interface MeetingsListProps {
    query: UseInfiniteQueryResult | UseQueryResult;
    meetings: MeetingPrivate[] | MeetingPublic[];
    children: ReactNode;
}

export default function MeetingsList({ children, meetings, query }: MeetingsListProps) {
    return (
        <MeetingListContext.Provider
            value={{
                meetings,
                isSuccess: query.isSuccess,
                isLoading: query.isLoading,
                isFetching: query.isFetching,
                fetchNextPage: (query as UseInfiniteQueryResult).fetchNextPage,
                hasNextPage: (query as UseInfiniteQueryResult).hasNextPage,
            }}
        >
            {children}
        </MeetingListContext.Provider>
    );
}
