import { httpErrorHandler } from "@/api/api";
import { MediaViewerFile } from "@/components/features/media/MediaViewer";
import { Button } from "@/components/ui/button/Button";
import {
    DialogBody,
    DialogFooter,
    DialogHeader,
    DialogTitle,
    Dialog,
    DialogContent,
} from "@/components/ui/dialog/Dialog";
import getFileUrl from "@/utils/getFileUrl";
import { ArrowLeft, ArrowRight, LoaderCircle, Minus, Plus, Scaling, Download } from "lucide-react";
import { memo, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import "react-pdf/dist/Page/AnnotationLayer.css";
import "react-pdf/dist/Page/TextLayer.css";
import { Link } from "@/components/ui/link/Link";

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
    "pdfjs-dist/build/pdf.worker.min.mjs",
    import.meta.url,
).toString();

const MAX_PDF_PAGE_WIDTH = 800;

const options = { withCredentials: true };

export default function MediaPDFViewer({ file, open, onOpenChange }: MediaViewerFile) {
    const isFilesInstance = file instanceof File;

    const fileURL = useMemo(() => {
        if (!isFilesInstance) {
            return getFileUrl(file.id);
        }
        return URL.createObjectURL(file);
    }, [file, isFilesInstance]);

    const [numPages, setNumPages] = useState<number | null>(null);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [renderedPages, setRenderedPages] = useState<number[]>([1]);
    const [scale, setScale] = useState<number>(1.0);
    const [loadingMorePages, setLoadingMorePages] = useState<boolean>(false);
    const [error, setError] = useState<Error | null>(null);
    const [searchText, _setSearchText] = useState<string>("");
    const containerRef = useRef<HTMLDivElement>(null);
    const pageRefs = useRef<{ [key: number]: HTMLDivElement | null }>({});

    // Handle document load success
    const onDocumentLoadSuccess = ({ numPages }) => {
        setNumPages(numPages);
        // Initially render first few pages
        setRenderedPages(Array.from({ length: Math.min(3, numPages) }, (_, i) => i + 1));
    };

    const onDocumentLoadError = error => {
        setError(error);
    };

    // Ensure that a specific page is in the rendered pages list
    const ensurePageIsRendered = useCallback(
        pageNumber => {
            if (!renderedPages.includes(pageNumber)) {
                setRenderedPages(prev => {
                    const newPages = [...prev, pageNumber].sort((a, b) => a - b);
                    return newPages;
                });
            }
        },
        [renderedPages],
    );

    // Navigation functions
    const goToPrevPage = useCallback(() => {
        if (currentPage > 1) {
            const newPage = currentPage - 1;
            setCurrentPage(newPage);
            ensurePageIsRendered(newPage);

            // Use setTimeout to ensure page rendering has a chance to happen
            setTimeout(() => {
                if (pageRefs.current[newPage]) {
                    pageRefs.current[newPage].scrollIntoView();
                }
            }, 100);
        }
    }, [currentPage, ensurePageIsRendered]);

    const goToNextPage = useCallback(() => {
        if (numPages && currentPage < numPages) {
            const newPage = currentPage + 1;
            setCurrentPage(newPage);
            ensurePageIsRendered(newPage);

            setTimeout(() => {
                if (pageRefs.current[newPage]) {
                    pageRefs.current[newPage].scrollIntoView();
                }
            }, 100);
        }
    }, [currentPage, numPages, ensurePageIsRendered]);

    // Handle scroll for lazy loading
    const handleScroll = useCallback(() => {
        if (!containerRef.current || !numPages || loadingMorePages) return;

        const { scrollTop, clientHeight, scrollHeight } = containerRef.current;
        const scrollPosition = scrollTop + clientHeight;
        const scrollThreshold = scrollHeight * 0.8;

        // Determine current page based on scroll position
        const containerRect = containerRef.current.getBoundingClientRect();
        let foundCurrentPage = false;

        // Check which page is most visible in the viewport
        Object.entries(pageRefs.current).forEach(([pageNumber, ref]) => {
            if (!ref || foundCurrentPage) return;

            const rect = (ref as HTMLDivElement).getBoundingClientRect();
            // If page is in view (center of the viewport)
            const isVisible =
                rect.top <= containerRect.top + containerRect.height / 2 &&
                rect.bottom >= containerRect.top + containerRect.height / 2;

            if (isVisible) {
                const page = Number(pageNumber);
                setCurrentPage(page);
                foundCurrentPage = true;
            }
        });

        // Load more pages when approaching the bottom
        if (scrollPosition >= scrollThreshold) {
            setLoadingMorePages(true);
            const maxRendered = Math.max(...renderedPages);

            if (maxRendered < numPages) {
                const newPagesToRender: number[] = [];
                for (let i = maxRendered + 1; i <= Math.min(maxRendered + 3, numPages); i++) {
                    if (!renderedPages.includes(i)) {
                        newPagesToRender.push(i);
                    }
                }

                if (newPagesToRender.length > 0) {
                    setRenderedPages(prev => [...prev, ...newPagesToRender]);
                }
            }

            setTimeout(() => setLoadingMorePages(false), 500);
        }
    }, [renderedPages, numPages, loadingMorePages]);

    // Add scroll event listener
    useEffect(() => {
        const controller = new AbortController();
        const container = containerRef.current;
        if (container) {
            container.addEventListener("scroll", handleScroll, { signal: controller.signal });
            return () => controller.abort();
        }
    }, [handleScroll]);

    // Calculate the width based on container
    const getPageWidth = () => {
        if (!containerRef.current) return MAX_PDF_PAGE_WIDTH;
        return Math.min(MAX_PDF_PAGE_WIDTH, containerRef.current.clientWidth - 12 * 2);
    };

    // Render PDF pages
    const renderPages = () => {
        return renderedPages.map(pageNumber => (
            <div
                key={`page-${pageNumber}`}
                className={"relative w-full flex justify-center"}
                ref={el => (pageRefs.current[pageNumber] = el)}
                data-page-number={pageNumber}
                style={{ marginBottom: "1rem" }}
            >
                <Page
                    key={`page_${pageNumber}_${scale}`}
                    pageNumber={pageNumber}
                    width={getPageWidth()}
                    scale={scale}
                    renderTextLayer={true}
                    renderAnnotationLayer={true}
                    loading={<MediaPDFViewerPageLoader />}
                    customTextRenderer={item => {
                        if (!searchText) return item.str;

                        // Highlight search matches
                        const regex = new RegExp(`(${item.str})`, "gi");
                        return item.str.replace(regex, "<mark>$1</mark>");
                    }}
                />
                <div className="absolute bottom-2 right-2 bg-fill-secondary text-white px-2 py-1 rounded-sm text-xs">
                    {pageNumber} / {numPages}
                </div>
            </div>
        ));
    };

    return (
        <Dialog open={open} onOpenChange={onOpenChange}>
            <DialogContent className={"h-full md:max-w-196"}>
                <DialogBody>
                    <DialogHeader className={"flex-col gap-4 border-b border-border-primary"}>
                        <DialogTitle>{file.name}</DialogTitle>
                        <div className="flex justify-between w-full">
                            <div className="flex items-center gap-2">
                                <Button
                                    variant="outline"
                                    size="sm"
                                    onClick={goToPrevPage}
                                    disabled={currentPage <= 1}
                                >
                                    <ArrowLeft />
                                </Button>
                                <div className="flex items-center gap-2">
                                    <span
                                        className={"w-14 text-center text-sm text-text-secondary"}
                                    >
                                        {currentPage} z {numPages}
                                    </span>
                                </div>
                                <Button
                                    variant="outline"
                                    size="sm"
                                    onClick={goToNextPage}
                                    disabled={numPages === null || currentPage >= numPages}
                                >
                                    <ArrowRight />
                                </Button>
                            </div>
                            <div className="flex items-center gap-2">
                                <Button
                                    variant="outline"
                                    size="sm"
                                    onClick={() => setScale(scale - 0.1)}
                                    disabled={scale <= 0.5}
                                >
                                    <Minus />
                                </Button>
                                <Button
                                    variant="outline"
                                    size="sm"
                                    onClick={() => setScale(scale + 0.1)}
                                >
                                    <Plus />
                                </Button>
                                <Button
                                    variant="outline"
                                    size="sm"
                                    disabled={scale === 1.0}
                                    onClick={() => setScale(1.0)}
                                >
                                    <Scaling />
                                </Button>
                            </div>
                        </div>
                    </DialogHeader>
                    <div ref={containerRef} className={"grow overflow-auto p-3"}>
                        <Document
                            file={fileURL}
                            options={options}
                            onLoadSuccess={onDocumentLoadSuccess}
                            onLoadError={onDocumentLoadError}
                            loading={<MediaPDFViewerDocumentLoader />}
                            error={<MediaPDFViewerDocumentError error={error} />}
                        >
                            {renderPages()}
                        </Document>
                    </div>
                </DialogBody>
                {isFilesInstance && (
                    <DialogFooter className={"border-t border-border-primary"}>
                        <Link href={fileURL}>
                            <Button variant={"ghost"}>
                                <Download />
                            </Button>
                        </Link>
                    </DialogFooter>
                )}
            </DialogContent>
        </Dialog>
    );
}

const MediaPDFViewerDocumentLoader = memo(() => {
    return (
        <div className={"flex h-full flex-col items-center justify-center py-20"}>
            <LoaderCircle
                className={"animate-spin size-6 text-text-tertiary"}
                aria-hidden={"true"}
            />
            <p className={"italic text-text-tertiary"}>Trwa ładowanie pliku...</p>
        </div>
    );
});

MediaPDFViewerDocumentLoader.displayName = "MediaPDFViewerDocumentLoader";

interface MediaPDFViewerDocumentErrorProps {
    error?: Error | null;
}

const MediaPDFViewerDocumentError = ({ error }: MediaPDFViewerDocumentErrorProps) => {
    const { title, detail } = httpErrorHandler(error);
    return (
        <div className={"flex h-full items-center justify-center py-14"}>
            <h5 className="text-center font-medium">{title}</h5>
            <p className={"max-w-[35ch] text-pretty text-center text-xs text-text-secondary"}>
                {detail}
            </p>
        </div>
    );
};

const MediaPDFViewerPageLoader = () => {
    return (
        <div className={"flex w-full items-center justify-center py-44 px-3 md:px-4"}>
            <p className={"text-text-tertiary text-sm"}>Ładowanie strony...</p>
        </div>
    );
};

const MediaPDFViewerNoData = memo(() => {
    return (
        <div className={"flex h-full items-center justify-center"}>
            <p className={"text-text-secondary"}>Brak danych pliku.</p>
        </div>
    );
});

MediaPDFViewerNoData.displayName = "MediaPDFViewerNoData";

const MediaPDFViewerPageError = memo(() => {
    return (
        <div className={"flex h-full items-center justify-center"}>
            <p className={"text-text-secondary"}>Nie udało się załadować strony.</p>
        </div>
    );
});

MediaPDFViewerPageError.displayName = "MediaPDFViewerPageError";
