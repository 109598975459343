import {
    InfiniteData,
    UseMutationOptions,
    UseMutationResult,
    useMutation,
} from "@tanstack/react-query";
import { useMemo, useState as useReactState } from "react";

interface MutationStatus<TParams, TResult> {
    params: TParams;
    status: UseMutationResult["status"];
    data?: TResult;
    error?: Error;
    abort?: () => void;
    retry?: () => void;
    progress?: number;
}

type BatchMutationProps<TResult, Error, TParams, TContext> = UseMutationOptions<
    TResult,
    Error,
    TParams,
    TContext
> & {
    state?: MutationStatus<TParams, TResult>[];
    setState?: React.Dispatch<React.SetStateAction<MutationStatus<TParams, TResult>[]>>;
};

function useBatchMutation<TResult, Error, TParams, TContext>({
    mutationFn,
    onMutate,
    onSuccess,
    onError,
    state,
    setState,
    ...props
}: BatchMutationProps<TResult, Error, TParams, TContext>) {
    const [internalStatus, internalSetStatus] = useReactState<MutationStatus<TParams, TResult>[]>(
        [],
    );

    const statuses = state ?? internalStatus;
    const setStatuses = setState ?? internalSetStatus;

    const mutation = useMutation<TResult, Error, TParams, TContext>({
        mutationFn,
        onMutate: params => {
            const retry = () => {
                mutation.mutate(params);
            };
            const reset = async () => {
                mutation.reset();
            };
            setStatuses(prev => {
                const existingIndex = prev.findIndex(status => status.params === params);
                if (existingIndex !== -1) {
                    const updatedStatus = {
                        ...prev[existingIndex],
                        status: "pending",
                        isPending: true,
                        isError: false,
                        isSuccess: false,
                        retry,
                        reset,
                    } as MutationStatus<TParams, TResult>;
                    return [
                        ...prev.slice(0, existingIndex),
                        updatedStatus,
                        ...prev.slice(existingIndex + 1),
                    ];
                } else {
                    return [
                        ...prev,
                        {
                            params,
                            status: "pending",
                            isPending: true,
                            isError: false,
                            isSuccess: false,
                            retry,
                            reset,
                        },
                    ];
                }
            });
            void onMutate?.(params);
            return undefined;
        },
        onSuccess: (data, params, context) => {
            setStatuses(prev =>
                prev.map(status =>
                    status.params === params
                        ? {
                              ...status,
                              status: "success",
                              data,
                              isPending: false,
                              isError: false,
                              isSuccess: true,
                          }
                        : status,
                ),
            );
            onSuccess?.(data, params, context);
        },
        onError: (error, params, context) => {
            setStatuses(prev =>
                prev.map(status =>
                    status.params === params
                        ? ({
                              ...status,
                              status: "error",
                              error,
                              isPending: false,
                              isError: true,
                              isSuccess: false,
                          } as MutationStatus<TParams, TResult>)
                        : status,
                ),
            );
            onError?.(error, params, context);
        },
        ...props,
    });

    const executeMutations = async (
        paramsArray: TParams[],
        options?: { clearStatuses?: boolean },
    ) => {
        const { clearStatuses = true } = options || {};

        if (clearStatuses) {
            setStatuses([]);
        }
        const mutationPromises = paramsArray.map(params => mutation.mutateAsync(params));

        const results = await Promise.allSettled(mutationPromises);

        const fulfilledResults = results
            .filter(result => result.status === "fulfilled")
            .map(result => (result as PromiseFulfilledResult<TResult>).value);

        const rejectedResults = results
            .filter(result => result.status === "rejected")
            .map(result => (result as PromiseRejectedResult).reason);

        return { fulfilledResults, rejectedResults };
    };

    const clearStatuses = () => {
        setStatuses([]);
    };

    return {
        ...mutation,
        executeMutations,
        clearStatuses,
        statuses,
    };
}

function flatQueryPages<TData>(data: InfiniteData<TData[], unknown> | undefined) {
    if (data) {
        return data.pages.flat();
    } else {
        return [];
    }
}
function useInfiniteQueryResult<TData>(
    data: InfiniteData<TData[], unknown> | undefined,
    searchValue = "",
) {
    return useMemo(() => {
        if (!data) {
            return { data: [], isEmpty: true, noResults: false };
        }
        const flatData = flatQueryPages(data);
        const isEmpty = flatData.length === 0 && searchValue.length === 0;
        const noResults = flatData.length === 0 && searchValue.length > 0;
        return { data: flatData, isEmpty: isEmpty, noResults: noResults };
    }, [data, searchValue]);
}

export { flatQueryPages, useBatchMutation, useInfiniteQueryResult };

export type { MutationStatus };
