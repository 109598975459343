import { cn } from "@/lib/utils";
import { type VariantProps, cva } from "class-variance-authority";
import * as React from "react";

const alertVariants = cva("relative w-full flex flex-row rounded-md border gap-3 p-3", {
    variants: {
        variant: {
            default:
                "border-border-primary bg-surface-primary hover:bg-surface-primary-hover *text-text-primary",
            success:
                "border-border-success bg-surface-primary hover:bg-surface-primary-hover *text-text-success",
            warning:
                "border-border-warning bg-surface-primary hover:bg-surface-primary-hover *text-text-warning",
            destructive:
                "border-border-destructive bg-surface-primary hover:bg-surface-primary-hover *text-text-destructive",
        },
    },
    defaultVariants: {
        variant: "default",
    },
});

const Alert = React.forwardRef<
    HTMLDivElement,
    React.HTMLAttributes<HTMLDivElement> & VariantProps<typeof alertVariants>
>(({ className, variant, ...props }, ref) => (
    <div
        ref={ref}
        role={"alert"}
        className={cn(alertVariants({ variant }), className)}
        {...props}
    />
));
Alert.displayName = "Alert";

const AlertContent = React.forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLDivElement>>(
    ({ className, ...props }, ref) => (
        <div ref={ref} className={cn("flex flex-col gap-1", className)} {...props} />
    ),
);
AlertContent.displayName = "AlertContent";

const AlertTitle = React.forwardRef<HTMLParagraphElement, React.HTMLAttributes<HTMLHeadingElement>>(
    ({ className, ...props }, ref) => (
        <h5 ref={ref} className={cn("text-md", className)} {...props} />
    ),
);
AlertTitle.displayName = "AlertTitle";

const AlertDescription = React.forwardRef<
    HTMLParagraphElement,
    React.HTMLAttributes<HTMLParagraphElement>
>(({ className, ...props }, ref) => (
    <p ref={ref} className={cn("text-sm leading-relaxed", className)} {...props} />
));
AlertDescription.displayName = "AlertDescription";

export { Alert, AlertContent, AlertTitle, AlertDescription };
