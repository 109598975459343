import {
    EmptyState,
    EmptyStateCircles,
    EmptyStateContent,
    EmptyStateDescription,
    EmptyStateTitle,
} from "@/components/ui/empty-state/EmptyState";
import { TableCell, TableRow } from "@/components/ui/table/Table";
import { useMediaQuery } from "@/hooks/useMediaQuery";
import { FeaturedIcon } from "@/components/ui/featured-icon/FeaturedIcon";
import { Package } from "lucide-react";

export default function TanstackTableEmpty({ colSpan }: { colSpan: number }) {
    const breakpoint = useMediaQuery("md");

    const content = (
        <EmptyState className="pb-11">
            <EmptyStateCircles className={"z-0"} />
            <FeaturedIcon size={"lg"} className={"z-10"}>
                <Package />
            </FeaturedIcon>
            <EmptyStateContent>
                <EmptyStateTitle>Brak wyników</EmptyStateTitle>
                <EmptyStateDescription>
                    Brak wierszy, wprowadź nowe dane lub zmień opcje wyszukiwania
                </EmptyStateDescription>
            </EmptyStateContent>
        </EmptyState>
    );

    if (breakpoint) {
        return (
            <TableRow>
                <TableCell colSpan={colSpan}>{content}</TableCell>
            </TableRow>
        );
    }
    return content;
}
