import { cn } from "@/lib/utils";

export default function MeetingName({ name, className }: { name: string; className?: string }) {
    return (
        <p
            className={cn(
                "truncate text-start font-medium text-[clamp(0.7rem,1.5vw,0.8rem)]",
                className,
            )}
        >
            {name}
        </p>
    );
}
