import { useState, useMemo } from "react";
import { useIsomorphicLayoutEffect } from "@/hooks/useIsomorphicLayoutEffect";

type UseMediaQueryOptions = {
    defaultValue?: boolean;
    initializeWithValue?: boolean;
};

const IS_SERVER = typeof window === "undefined";

/// Tailwindcss breakpoints - https://tailwindcss.com/docs/responsive-design
const MediaQuerySizes = {
    sm: "640px", //40rem
    md: "768px", //48rem
    lg: "1024px", //64rem
    xl: "1280px", //80rem
    "2xl": "1536px", //96rem
} as const;

export type sizeOfMediaQuery = keyof typeof MediaQuerySizes;

export function useMediaQuery(
    queryOptionSize: sizeOfMediaQuery,
    { defaultValue = false, initializeWithValue = true }: UseMediaQueryOptions = {},
): boolean {
    const query = useMemo(() => {
        return `(min-width: ${MediaQuerySizes[queryOptionSize]})`;
    }, [queryOptionSize]);
    const getMatches = (query: string): boolean => {
        if (IS_SERVER) {
            return defaultValue;
        }
        return window.matchMedia(query).matches;
    };

    const [matches, setMatches] = useState<boolean>(() => {
        if (initializeWithValue) {
            return getMatches(query);
        }
        return defaultValue;
    });

    // Handles the change event of the media query.
    function handleChange() {
        setMatches(getMatches(query));
    }

    useIsomorphicLayoutEffect(() => {
        const matchMedia = window.matchMedia(query);

        // Triggered at the first client-side load and if query changes
        handleChange();

        // All modern browsers now support addEventListener
        matchMedia.addEventListener("change", handleChange);

        return () => {
            matchMedia.removeEventListener("change", handleChange);
        };
    }, [query]);

    return matches;
}
