import { useAuthStore } from "@/store/authStore";
import { Navigate, Outlet, useLocation } from "react-router";

function PrivateRoutes() {
    const location = useLocation();
    const { isLogged } = useAuthStore();
    if (!isLogged()) {
        return <Navigate to={"/login"} replace state={{ from: location }} />;
    }
    return <Outlet />;
}

export { PrivateRoutes };
