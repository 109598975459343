import { Badge } from "@/components/ui/badge/Badge";
import { Skeleton } from "@/components/ui/skeleton/Skeleton";
import { cn } from "@/lib/utils";
import { MeetingPrivate, MeetingPublic } from "@/types";
import { format, parseISO } from "date-fns";
import { pl } from "date-fns/locale";
import { Building, Clock, Link2 } from "lucide-react";
import { forwardRef, useCallback, useEffect, useMemo, useState } from "react";
import { Link as RRLink, useNavigate } from "react-router-dom";

const MeetingCard = forwardRef<HTMLAnchorElement, { meeting: MeetingPrivate | MeetingPublic }>(
    ({ meeting, ...props }, ref) => {
        const { id, name, startDate, endDate, meetingURL, isCancelled } = meeting;
        const [isCurrent, setIsCurrent] = useState<boolean>(false);
        const navigate = useNavigate();

        const date = useMemo(() => format(parseISO(startDate), "d", { locale: pl }), [startDate]);
        const month = useMemo(
            () => format(parseISO(startDate), "MMM", { locale: pl }),
            [startDate],
        );

        const timeRange = useMemo(
            () => format(parseISO(startDate), "H:mm") + " - " + format(parseISO(endDate), "H:mm"),
            [endDate, startDate],
        );

        const currentMeetingCb = useCallback(() => {
            const now = new Date();
            if (now >= parseISO(startDate) && now <= parseISO(endDate) && !isCancelled) {
                setIsCurrent(true);
            } else {
                setIsCurrent(false);
            }
        }, [endDate, startDate, isCancelled]);

        useEffect(() => {
            currentMeetingCb();
            const interval = setInterval(currentMeetingCb, 15000);

            return () => clearInterval(interval);
        }, [currentMeetingCb]);

        return (
            <RRLink
                ref={ref}
                to={`/calendar/meeting/${id}`}
                aria-label={"meeting-card"}
                aria-disabled={isCancelled}
                data-current={isCurrent}
                data-state={!isCancelled && isCurrent}
                className={
                    "group relative p-2 gap-2 inline-flex w-full overflow-hidden rounded-md ease-out duration-100 transition-colors transition-outline opacity-100 cursor-pointer aria-disabled:cursor-not-allowed disabled:pointer-events-none outline-ring-focus outline-0 focus-visible:outline-2 disabled:opacity-75 bg-fill-primary text-text-primary border border-border-primary hover:bg-fill-primary-hover active:bg-fill-primary-active data-[state=true]:border-border-brand"
                }
                {...props}
            >
                <div
                    className={
                        "flex h-11.5 min-h- w-11.5 min-w-11.5 flex-col items-center justify-between rounded-sm p-0.5 bg-fill-secondary group-data-[current=true]:bg-fill-brand-secondary"
                    }
                >
                    <p
                        className={
                            "text-xs text-text-secondary group-data-[current=true]:text-text-brand"
                        }
                    >
                        {month}
                    </p>
                    <div
                        className={"flex w-full justify-center rounded-[6px] bg-fill-primary p-0.5"}
                    >
                        <p
                            className={
                                "text-sm font-medium text-text-secondary group-data-[current=true]:text-text-brand"
                            }
                        >
                            {date}
                        </p>
                    </div>
                </div>
                <div className={"h-full space-y-0.5"}>
                    <div className={"flex gap-1"}>
                        <span
                            className={
                                "my-0.5 w-1 min-w-1 rounded-sm bg-fill-brand aria-disabled:bg-fill-destructive"
                            }
                        />
                        <p
                            className={
                                "truncate text-start text-sm font-medium text-text-secondary"
                            }
                        >
                            {name}
                        </p>
                    </div>
                    <div className={"flex flex-nowrap gap-1 overflow-hidden"}>
                        <Badge
                            size={"sm"}
                            variant={"muted"}
                            className={"aria-disabled:line-through"}
                        >
                            <Clock />
                            {timeRange}
                        </Badge>
                        {"location" in meeting && meeting.location ? (
                            <Badge
                                size={"sm"}
                                variant={"muted"}
                                className={"aria-disabled:line-through"}
                            >
                                <Building />
                                {meeting.location.name}
                            </Badge>
                        ) : null}
                        {meetingURL ? (
                            <Badge
                                size={"sm"}
                                variant={"brand"}
                                className={"aria-disabled:line-through"}
                                onClick={() => navigate(meetingURL)}
                            >
                                <Link2 />
                                Online
                            </Badge>
                        ) : null}
                    </div>
                </div>
            </RRLink>
        );
    },
);
MeetingCard.displayName = "MeetingCard";

interface MeetingCardSkeletonProps {
    className?: string;
}

const MeetingCardSkeleton = forwardRef<HTMLDivElement, MeetingCardSkeletonProps>(
    ({ className, ...props }, ref) => {
        return (
            <div
                ref={ref}
                {...props}
                className={cn(
                    "flex gap-1 rounded-md border border-border-primary bg-fill-primary p-2",
                    className,
                )}
            >
                <Skeleton className={"h-11 min-h-11 w-11 min-w-11 rounded-sm"} />
                <div className={"bg-fill-secondary h-full w-0.5 min-w-0.5 rounded-xs"} />
                <div className={"flex flex-col justify-center gap-1"}>
                    <Skeleton className={"h-3 w-14 rounded-sm"} />
                    <Skeleton className={"h-3 w-12 rounded-sm"} />
                </div>
            </div>
        );
    },
);

MeetingCardSkeleton.displayName = "MeetingCardSkeleton";

export { MeetingCard, MeetingCardSkeleton };
