import { getWeek } from "@/utils/dateFormat";
import { format } from "date-fns";
import { pl } from "date-fns/locale";
import { useMemo } from "react";

interface CalendarContentNavMonthProps {
    date: Date;
}

export default function CalendarHeaderMonth({ date }: CalendarContentNavMonthProps) {
    const weekArray = useMemo(() => getWeek(date), [date]);
    const content = weekArray.map(date => {
        const weekDay = format(date, "EE", { locale: pl });
        return (
            <div
                className={
                    "w-full flex justify-center items-center border-r last:border-r-0 border-border-primary"
                }
                key={date.toString()}
            >
                <p className={"text-center text-xs md:text-sm text-text-tertiary"}>{weekDay}</p>
            </div>
        );
    });
    return (
        <div className={"flex flex-row h-8 md:h-9 border-y border-border-primary"}>{content}</div>
    );
}
