import { Button } from "@/components/ui/button/Button";
import { cn } from "@/lib/utils";
import type { DirectoryElements } from "@/types";
import getFileUrl from "@/utils/getFileUrl";
import { motion, AnimatePresence } from "framer-motion";
import { ChevronDown, X, RotateCcw, FileArchive } from "lucide-react";
import { useCallback, useEffect, useState } from "react";
import { UseMutationResult } from "@tanstack/react-query";
import { v4 as uuidv4 } from "uuid";
import {
    FileUploadList,
    FileUploadListItem,
    FileUploadListHeader,
    FileUploadListIcon,
    FileUploadListInfo,
    FileUploadListDescription,
    FileUploadListDescriptionText,
    FileUploadListName,
    FileUploadListAction,
    FileUploadListActions,
} from "@/components/ui/file-upload/FileUploadList";

interface DownloadCompressingElement {
    UUID: string;
    names: string[];
    amount: number;
    status: UseMutationResult["status"];
    abort: () => void;
    retry: () => void;
}

interface DownloadFSEntryProps {
    elements: DirectoryElements;
    open: boolean;
    onOpenChange: (open: boolean) => void;
    callback?: () => void;
}

export default function DownloadFSEntry({
    elements,
    open,
    onOpenChange,
    callback,
}: DownloadFSEntryProps) {
    const [openDrawer, setOpenDrawer] = useState<boolean>(false);

    const [downloadingElements, setDownloadingElements] = useState<
        DownloadCompressingElement[] | null
    >(null);

    const onDownload = useCallback(async () => {
        const { files, directories } = { ...elements };
        onOpenChange(false);
        callback?.();

        if (files.length === 1 && directories.length === 0) {
            // Single file download
            const url = getFileUrl(files[0].id);
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", files[0].name);
            document.body.appendChild(link);
            link.click();
            link.remove();
        } else {
            alert("Multiple files/directories - not implemented yet");
            return;

            // Multiple files/directories - show drawer and start compression
            setOpenDrawer(true);
            const newElement = {
                UUID: uuidv4(),
                names: [...files.map(el => el.name), ...directories.map(el => el.name)],
                amount: files.length + directories.length,
                status: "pending",
                abort: () => {},
                retry: () => {},
            } as DownloadCompressingElement;
            setDownloadingElements(prev => (prev ? [...prev, newElement] : [newElement]));
        }
    }, [elements, onOpenChange, callback]);

    useEffect(() => {
        // downloadInitiated.current = false;
        if (open && (elements.files.length > 0 || elements.directories.length > 0)) {
            onDownload();
            //   downloadInitiated.current = true;
        }
    }, [elements, onDownload]);

    useEffect(() => {
        if (!openDrawer) {
            setDownloadingElements(null);
        }
    }, [openDrawer]);

    return (
        <DownloadFsEntryDrawer open={openDrawer} onOpenChange={setOpenDrawer}>
            <FileUploadList>
                {downloadingElements?.map(el => (
                    <DownloadFsEntryListElement key={el.UUID} {...el} />
                ))}
            </FileUploadList>
        </DownloadFsEntryDrawer>
    );
}

function DownloadFsEntryListElement({
    names,
    amount,
    abort,
    retry,
    status,
}: DownloadCompressingElement) {
    return (
        <FileUploadListItem>
            <FileUploadListHeader>
                <FileUploadListIcon>
                    <FileArchive />
                </FileUploadListIcon>
                <FileUploadListInfo>
                    <FileUploadListName
                        className={cn(status === "error" && "text-text-destructive")}
                    >
                        {names}
                    </FileUploadListName>
                    <FileUploadListDescription>
                        <FileUploadListDescriptionText>
                            {amount}: {names.join(", ")}
                        </FileUploadListDescriptionText>
                    </FileUploadListDescription>
                </FileUploadListInfo>
                <FileUploadListActions>
                    {/* Retry button */}
                    <FileUploadListAction
                        className={cn(status === "error" ? "visible" : "hidden")}
                        onClick={() => retry()}
                    >
                        <RotateCcw />
                        <span className="sr-only">Retry</span>
                    </FileUploadListAction>

                    {/* Abort button */}
                    <FileUploadListAction
                        className={cn(status === "pending" ? "visible" : "hidden")}
                        onClick={() => abort()}
                    >
                        <X />
                        <span className="sr-only">Abort</span>
                    </FileUploadListAction>
                </FileUploadListActions>
            </FileUploadListHeader>
        </FileUploadListItem>
    );
}
type DownloadFsEntryDrawerProps = {
    callback?: () => void;
    children?: React.ReactNode;
    open: boolean;
    onOpenChange: (open: boolean) => void;
};

function DownloadFsEntryDrawer({
    open,
    onOpenChange,
    callback,
    children,
}: DownloadFsEntryDrawerProps) {
    const [openCollapsible, setOpenCollapsible] = useState<boolean>(true);

    const collapsibleToggle = useCallback(() => {
        setOpenCollapsible(prev => !prev);
    }, []);

    const onClose = useCallback(() => {
        callback?.();
        onOpenChange(false);
        setOpenCollapsible(true);
    }, [callback, onOpenChange]);

    return (
        <AnimatePresence>
            {open && (
                <motion.div
                    aria-describedby={"file-upload-list"}
                    className={
                        "fixed inset-x-0 bottom-0 mt-12 flex h-auto flex-col rounded-t-lg border border-border-primary bg-surface-primary md:left-auto md:right-6 z-20 max-h-96 md:w-96 left-0 right-0 w-full"
                    }
                    initial={{ y: "100%" }}
                    animate={{ y: 0 }}
                    exit={{ y: "100%" }}
                    transition={{
                        ease: "easeInOut",
                        duration: 0.3,
                    }}
                >
                    <header className={"flex w-full justify-end p-2"}>
                        <h5 className="sr-only">File download list</h5>

                        <Button size={"sm"} variant={"ghost"} onClick={collapsibleToggle}>
                            <ChevronDown className={cn(!openCollapsible && "rotate-[180deg]")} />
                        </Button>
                        <Button onClick={onClose} size={"sm"} variant={"ghost"}>
                            <X />
                        </Button>
                    </header>
                    <motion.div
                        className={"h-full overflow-auto"}
                        initial={{ opacity: 0, height: "auto" }}
                        animate={{
                            opacity: openCollapsible ? 1 : 0,
                            height: openCollapsible ? "auto" : 0,
                        }}
                        transition={{
                            ease: "easeInOut",
                            stiffness: 260,
                            damping: 20,
                            duration: 0.2,
                        }}
                    >
                        <div className={"flex h-full flex-col gap-2 p-3 md:p-4"}>{children}</div>
                    </motion.div>
                </motion.div>
            )}
        </AnimatePresence>
    );
}
