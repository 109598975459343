"use client";

import { ChevronRight } from "lucide-react";
import {
    Collapsible,
    CollapsibleContent,
    CollapsibleTrigger,
} from "@/components/ui/collapsible/Collapsible";
import {
    SidebarGroup,
    SidebarMenu,
    SidebarMenuButton,
    SidebarMenuItem,
    SidebarMenuSub,
    SidebarMenuSubButton,
    SidebarMenuSubItem,
    useSidebar,
} from "@/components/ui/sidebar/Sidebar";
import { NavLink } from "react-router";
import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuGroup,
    DropdownMenuItem,
    DropdownMenuLabel,
    DropdownMenuTrigger,
} from "@/components/ui/input/dropdown-menu/DropdownMenu";
import type { NavMainItemT } from "./AppSidebar";
import { useGetUnreadNotificationsCountQuery } from "@/api/queries/notificationsQueries";
import Chip from "@/components/ui/chip/chip";

type NavMainProps = {
    items: NavMainItemT[];
};

export function NavMain({ items }: NavMainProps) {
    return (
        <SidebarGroup>
            <SidebarMenu>
                {items.map(item => (
                    <NavMainItem key={item.title} {...item} />
                ))}
            </SidebarMenu>
        </SidebarGroup>
    );
}

function NavMainItem(item: NavMainItemT) {
    const { state, isMobile, setOpenMobile } = useSidebar();

    const handleNavClick = () => {
        if (isMobile) {
            setOpenMobile(false);
        }
    };

    const { data } = useGetUnreadNotificationsCountQuery(item.name, item.name ? true : false);

    if (item.items) {
        if (state === "expanded" || isMobile) {
            return (
                <Collapsible
                    key={item.title}
                    asChild
                    defaultOpen={item.isActive}
                    className={"group/collapsible"}
                >
                    <SidebarMenuItem>
                        <CollapsibleTrigger asChild>
                            <SidebarMenuButton
                                tooltip={item.title}
                                onClick={() => item.onClick?.()}
                            >
                                {item.icon && <item.icon />}
                                <span>{item.title}</span>
                                <ChevronRight className="ml-auto transition-transform duration-200 group-data-[state=open]/collapsible:rotate-90" />
                            </SidebarMenuButton>
                        </CollapsibleTrigger>
                        <CollapsibleContent>
                            <SidebarMenuSub>
                                {item.items.map(subItem => (
                                    <SidebarMenuSubItem key={subItem.title}>
                                        <SidebarMenuSubButton asChild>
                                            <NavLink
                                                to={subItem.url}
                                                className={"w-full"}
                                                onClick={() => {
                                                    handleNavClick();
                                                    subItem.onClick?.();
                                                }}
                                            >
                                                <span>{subItem.title}</span>
                                            </NavLink>
                                        </SidebarMenuSubButton>
                                    </SidebarMenuSubItem>
                                ))}
                            </SidebarMenuSub>
                        </CollapsibleContent>
                    </SidebarMenuItem>
                </Collapsible>
            );
        }

        return (
            <SidebarMenuItem>
                <DropdownMenu>
                    <DropdownMenuTrigger asChild>
                        <SidebarMenuButton tooltip={item.title}>
                            {item.icon && <item.icon />}
                            <span>{item.title}</span>
                        </SidebarMenuButton>
                    </DropdownMenuTrigger>
                    <DropdownMenuContent side="right">
                        <DropdownMenuLabel>Zarządznie</DropdownMenuLabel>
                        <DropdownMenuGroup>
                            {item.items.map(subItem => (
                                <DropdownMenuItem key={subItem.title} asChild>
                                    <NavLink
                                        to={subItem.url}
                                        className={"w-full"}
                                        onClick={() => {
                                            subItem.onClick?.();
                                            // This will allow navigation to work while
                                            // the dropdown closes automatically
                                        }}
                                    >
                                        <span>{subItem.title}</span>
                                    </NavLink>
                                </DropdownMenuItem>
                            ))}
                        </DropdownMenuGroup>
                    </DropdownMenuContent>
                </DropdownMenu>
            </SidebarMenuItem>
        );
    }

    return (
        <SidebarMenuItem>
            <Chip
                isInvisible={!data}
                variantColor={"success"}
                variant={"flat"}
                isDot={true}
                size={"sm"}
                placement={"top-left"}
                className={"w-full"}
            >
                <SidebarMenuButton asChild tooltip={item.title}>
                    <NavLink
                        onClick={() => {
                            handleNavClick();
                            item.onClick?.();
                        }}
                        to={item.url}
                    >
                        {item.icon && <item.icon />}
                        <span>{item.title}</span>
                    </NavLink>
                </SidebarMenuButton>
            </Chip>
        </SidebarMenuItem>
    );
}
