"use no memo";

import { VideoLayout } from "@/components/ui/media-viewer/VideoLayout";
import { cn } from "@/lib/utils";
import {
    MediaPlayer,
    MediaPlayerInstance,
    MediaPlayerProps,
    MediaProvider,
    Poster,
} from "@vidstack/react";
import { PlayerSrc } from "@vidstack/react";
import "@vidstack/react/player/styles/base.css";
import { Fragment, useRef } from "react";

interface MediaPosterProps {
    src: string;
    alt?: string;
    className?: string;
}

type MediaVideoPlayerProps = {
    src?: PlayerSrc;
    title: string;
    thumbnails?: string;
    className?: string;
    poster?: MediaPosterProps;
    variant?: "default" | "minimal";
} & Omit<MediaPlayerProps, "children">;

export default function VideoPlayer({
    src,
    title,
    poster,
    thumbnails,
    className,
    controlsDelay = 1000,
    hideControlsOnMouseLeave = false,
    variant = "default",
    ...props
}: MediaVideoPlayerProps) {
    const player = useRef<MediaPlayerInstance>(null);

    return (
        <Fragment>
            <MediaPlayer
                className={cn(
                    "aspect-video relative w-full rounded-sm bg-surface-primary text-text-tertiary outline-0 data-focus:outline-2 outline-ring-focus",
                    className,
                )}
                ref={player}
                title={title}
                src={src}
                hideControlsOnMouseLeave={hideControlsOnMouseLeave}
                controlsDelay={controlsDelay}
                playsInline
                streamType={"on-demand"}
                viewType={"video"}
                {...props}
            >
                <MediaProvider>
                    <Poster
                        className={cn(
                            "absolute inset-0 block h-full w-full object-cover opacity-0 transition-opacity data-visible:opacity-100",
                            poster?.className,
                        )}
                        src={poster?.src}
                        alt={poster?.alt}
                    />
                </MediaProvider>

                <VideoLayout thumbnails={thumbnails} variant={variant} />
            </MediaPlayer>
        </Fragment>
    );
}

export type { MediaVideoPlayerProps };
