import { Dispatch, SetStateAction, useState } from "react";
import { ImageCropper, Image } from "@/components/ui/image-cropper/ImageCropper";
import { Trash2, LoaderCircle } from "lucide-react";
import { ButtonGroupButton } from "@/components/ui/button/ButtonGroup";
import {
    usePostGroupAvatarMutation,
    useDeleteGroupAvatarMutation,
} from "@/api/queries/groupsQueries";
import { httpErrorHandler } from "@/api/api";
import { getAvatar } from "@/utils/getAvatar";

interface GroupAvatarEditorProps {
    open: boolean;
    onOpenChange: Dispatch<SetStateAction<boolean>>;
    id?: number;
    avatarURL?: string;
}

export function GroupAvatarEditor({ open, onOpenChange, id, avatarURL }: GroupAvatarEditorProps) {
    const [image, setImage] = useState<Image | null>(null);

    const { mutateAsync: deleteAvatar, isPending: isPendingDeleteAvatar } =
        useDeleteGroupAvatarMutation();
    const { mutateAsync: postAvatar, isPending: isPendingPostAvatar } =
        usePostGroupAvatarMutation();

    const handleDeleteAvatar = () => {
        if (!id) return;
        deleteAvatar(id).then(() => {
            setImage(null);
        });
    };

    const handleCropAvatar = async (blob: Blob) => {
        if (!id) return;
        await postAvatar({
            b64: blob,
            id: id,
        })
            .then(res => {})
            .catch(error => {
                const { detail } = httpErrorHandler(error);

                throw new Error(detail);
            });
    };

    const deleteControl = (
        <ButtonGroupButton
            disabled={isPendingDeleteAvatar}
            className={"w-full"}
            onClick={() => handleDeleteAvatar()}
        >
            {isPendingDeleteAvatar ? (
                <LoaderCircle className={"animate-spin"} aria-hidden={"true"} />
            ) : (
                <Trash2 className="*stroke-icon-brand" />
            )}
        </ButtonGroupButton>
    );

    return (
        <ImageCropper
            defaultSrc={getAvatar(avatarURL)}
            open={open}
            onOpenChange={onOpenChange}
            onCrop={handleCropAvatar}
            isPending={isPendingPostAvatar}
            title="Edytuj awatar"
            extraControls={deleteControl}
            aspectRatio={1}
            maxHeight={256}
            maxWidth={256}
            image={image}
            setImage={setImage}
        />
    );
}
