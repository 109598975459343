import { cn } from "@/lib/utils";
import * as CheckboxPrimitive from "@radix-ui/react-checkbox";
import { VariantProps, cva } from "class-variance-authority";
import { Check, Minus } from "lucide-react";
import React from "react";

const checkboxVariants = cva(
    "peer relative cursor-pointer shrink-0 duration-100 rounded-sm border-border-primary border bg-fill-primary hover:bg-fill-primary-hover aria-checked:border-border-brand aria-checked:bg-fill-brand aria-checked:text-text-on-brand-fill transition-outline opacity-100 transition-colors cursor-pointer disabled:cursor-not-allowed disabled:pointer-events-none outline-ring-focus outline-0 focus-visible:outline-2 disabled:opacity-75",
    {
        variants: {
            size: {
                sm: "size-5 [&_svg]:size-4",
                md: "size-6 [&_svg]:size-4",
                lg: "size-7 [&_svg]:size-5",
            },
        },
        defaultVariants: {
            size: "md",
        },
    },
);

const Checkbox = React.forwardRef<
    React.ElementRef<typeof CheckboxPrimitive.Root>,
    React.ComponentPropsWithoutRef<typeof CheckboxPrimitive.Root> &
        VariantProps<typeof checkboxVariants>
>(({ className, size, ...props }, ref) => (
    <CheckboxPrimitive.Root
        ref={ref}
        className={cn(checkboxVariants({ size }), className)}
        {...props}
    >
        <CheckboxPrimitive.Indicator
            className={cn("flex items-center justify-center text-current")}
        >
            {props.checked === true && <Check className={"shrink-0 pointer-events-none"} />}
            {props.checked === "indeterminate" && (
                <Minus className={"shrink-0 pointer-events-none"} />
            )}
        </CheckboxPrimitive.Indicator>
    </CheckboxPrimitive.Root>
));
Checkbox.displayName = CheckboxPrimitive.Root.displayName;

export { Checkbox };
