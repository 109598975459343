"use client";

import { cn } from "@/lib/utils";
import { getLocalTimeZone, today } from "@internationalized/date";
import { ChevronLeftIcon, ChevronRightIcon } from "lucide-react";
import { ComponentProps } from "react";
import {
    Button as ButtonRac,
    DatePicker as DatePickerRac,
    CalendarCell as CalendarCellRac,
    CalendarGridBody as CalendarGridBodyRac,
    CalendarGridHeader as CalendarGridHeaderRac,
    CalendarGrid as CalendarGridRac,
    CalendarHeaderCell as CalendarHeaderCellRac,
    Calendar as CalendarRac,
    Heading as HeadingRac,
    RangeCalendar as RangeCalendarRac,
    composeRenderProps,
    DateFieldProps,
    DateField as DateFieldRac,
    DateInputProps as DateInputPropsRac,
    DateInput as DateInputRac,
    DateSegmentProps,
    DateSegment as DateSegmentRac,
    DateValue as DateValueRac,
    TimeFieldProps as TimeFieldPropsRac,
    TimeField as TimeFieldRac,
    TimeValue as TimeValueRac,
    I18nProvider,
    DatePickerProps as DatePickerPropsRac,
} from "react-aria-components";
import { buttonVariants } from "@/components/ui/button/Button";
import { useMediaQuery } from "@/hooks/useMediaQuery";
import { Popover, PopoverContent, PopoverTrigger } from "@/components/ui/popover/Popover";
import {
    Drawer,
    DrawerContent,
    DrawerDescription,
    DrawerTitle,
    DrawerTrigger,
} from "@/components/ui/drawer/Drawer";

interface BaseCalendarProps {
    className?: string;
}
type CalendarProps = ComponentProps<typeof CalendarRac> & BaseCalendarProps;
type RangeCalendarProps = ComponentProps<typeof RangeCalendarRac> & BaseCalendarProps;

type DatePickerProps<T extends DateValueRac> = DatePickerPropsRac<T> &
    React.RefAttributes<HTMLDivElement> & {
        locale?: string;
    };

function DatePicker<T extends DateValueRac>({ locale = "pl-PL", ...props }: DatePickerProps<T>) {
    return (
        <I18nProvider locale={locale}>
            <DatePickerRac {...props} />
        </I18nProvider>
    );
}

type TimeFieldProps<T extends TimeValueRac> = TimeFieldPropsRac<T> &
    React.RefAttributes<HTMLDivElement> & {
        locale?: string;
    };

function TimePicker<T extends TimeValueRac>({ locale = "pl-PL", ...props }: TimeFieldProps<T>) {
    return (
        <I18nProvider locale={locale}>
            <TimeField {...props}>
                <DateInput />
            </TimeField>
        </I18nProvider>
    );
}

function CalendarHeader() {
    return (
        <header className="flex w-full items-center gap-1 pb-1">
            <ButtonRac
                slot="previous"
                className={cn(buttonVariants({ variant: "ghost", size: "sm" }))}
            >
                <ChevronLeftIcon />
            </ButtonRac>
            <HeadingRac className="grow text-center text-sm font-medium" />
            <ButtonRac slot="next" className={cn(buttonVariants({ variant: "ghost", size: "sm" }))}>
                <ChevronRightIcon />
            </ButtonRac>
        </header>
    );
}

function CalendarGridComponent({ isRange = false }: { isRange?: boolean }) {
    const now = today(getLocalTimeZone());

    return (
        <CalendarGridRac>
            <CalendarGridHeaderRac>
                {day => (
                    <CalendarHeaderCellRac className="text-text-secondary size-9 rounded-sm p-0 text-xs font-medium">
                        {day}
                    </CalendarHeaderCellRac>
                )}
            </CalendarGridHeaderRac>
            <CalendarGridBodyRac className="[&_td]:px-0 [&_td]:py-px">
                {date => (
                    <CalendarCellRac
                        date={date}
                        className={cn(
                            "cursor-pointer text-text-secondary data-hovered:bg-fill-primary-hover data-selected:bg-fill-gray data-hovered:text-text-primary data-selected:text-text-on-gray-fill data-focus-visible:ring-ring/50 relative flex size-9 items-center justify-center rounded-sm p-0 text-sm font-normal whitespace-nowrap [transition-property:color,background-color,border-radius,box-shadow] duration-150 outline-none data-disabled:pointer-events-none data-disabled:opacity-30 data-focus-visible:z-10 data-focus-visible:ring-[3px] data-unavailable:pointer-events-none data-unavailable:line-through data-unavailable:opacity-30",
                            // Range-specific styles
                            isRange &&
                                "data-selected:bg-fill-gray data-selected:text-text-on-gray-fill data-invalid:data-selection-end:bg-surface-destructive data-invalid:data-selection-start:bg-surface-destructive data-selection-end:bg-fill-gray data-selection-start:bg-fill-gray data-selection-end:text-text-on-gray-fill data-selection-start:text-text-on-gray-fill data-invalid:bg-red-100 data-selected:rounded-none data-selection-end:rounded-e-sm data-invalid:data-selection-end:text-white data-selection-start:rounded-s-sm data-invalid:data-selection-start:text-white",
                            // Today indicator styles
                            date.compare(now) === 0 &&
                                cn(
                                    "after:bg-surface-primary after:pointer-events-none after:absolute after:start-1/2 after:bottom-1 after:z-10 after:size-[3px] after:-translate-x-1/2 after:rounded-full",
                                    isRange
                                        ? "data-selection-end:after:bg-surface-primary data-selection-start:after:bg-surface-primary"
                                        : "data-selected:after:bg-surface-primary",
                                ),
                        )}
                    />
                )}
            </CalendarGridBodyRac>
        </CalendarGridRac>
    );
}

function Calendar({ className, ...props }: CalendarProps) {
    return (
        <CalendarRac
            {...props}
            className={composeRenderProps(className, className => cn("w-fit", className))}
        >
            <CalendarHeader />
            <CalendarGridComponent />
        </CalendarRac>
    );
}

function RangeCalendar({ className, ...props }: RangeCalendarProps) {
    return (
        <RangeCalendarRac
            {...props}
            className={composeRenderProps(className, className => cn("w-fit", className))}
        >
            <CalendarHeader />
            <CalendarGridComponent isRange />
        </RangeCalendarRac>
    );
}

function DateField<T extends DateValueRac>({ className, children, ...props }: DateFieldProps<T>) {
    return (
        <DateFieldRac
            className={composeRenderProps(className, className => cn(className))}
            {...props}
        >
            {children}
        </DateFieldRac>
    );
}

function TimeField<T extends TimeValueRac>({ className, children, ...props }: TimeFieldProps<T>) {
    return (
        <TimeFieldRac
            className={composeRenderProps(className, className =>
                cn(
                    "text-text-primary data-focused:bg-fill-brand data-focused:data-placeholder:text-text-on-brand-fill data-focused:text-text-on-brand-fill data-placeholder:text-text-tertiary/50 data-[type=literal]:text-text-tertiary inline rounded-sm p-0.5 caret-transparent outline-hidden data-disabled:cursor-not-allowed data-disabled:opacity-50 data-invalid:data-focused:data-placeholder:text-text-on-brand-fill data-[type=literal]:px-0",
                    className,
                ),
            )}
            {...props}
        >
            {children}
        </TimeFieldRac>
    );
}

function DateSegment({ className, ...props }: DateSegmentProps) {
    return (
        <DateSegmentRac
            className={composeRenderProps(className, className =>
                cn(
                    "text-text-primary data-focused:bg-fill-brand data-focused:data-placeholder:text-text-on-brand-fill data-focused:text-text-on-brand-fill data-placeholder:text-text-tertiary/50 data-[type=literal]:text-text-tertiary inline rounded-sm p-0.5 caret-transparent outline-hidden data-disabled:cursor-not-allowed data-disabled:opacity-50 data-invalid:data-focused:data-placeholder:text-text-on-brand-fill data-[type=literal]:px-0",
                    className,
                ),
            )}
            {...props}
            data-invalid
        />
    );
}

const dateInputStyle =
    "flex h-10 w-full rounded-sm border border-border-primary bg-transparent px-3 py-1 text-base duration-100 transition-colors transition-outline placeholder:text-text-tertiary/50 focus-within:z-10 outline-ring-focus outline-0 focus-within:outline-2 disabled:cursor-not-allowed disabled:opacity-50 [&::-webkit-search-cancel-button]:appearance-none [&::-webkit-search-decoration]:appearance-none [&::-webkit-search-results-button]:appearance-none [&::-webkit-search-results-decoration]:appearance-none";

interface DateInputProps extends DateInputPropsRac {
    className?: string;
}

function DateInput({ className, ...props }: Omit<DateInputProps, "children">) {
    return (
        <DateInputRac
            className={composeRenderProps(className, className => cn(dateInputStyle, className))}
            {...props}
        >
            {segment => <DateSegment segment={segment} />}
        </DateInputRac>
    );
}

type CalendarPopoverProps = ComponentProps<typeof Popover> | ComponentProps<typeof Drawer>;

function CalendarPopover({ children, ...props }: CalendarPopoverProps) {
    return (
        <Popover {...props}>
            <Drawer {...props}>{children}</Drawer>
        </Popover>
    );
}

type CalendarPopoverTriggerProps =
    | ComponentProps<typeof PopoverTrigger>
    | ComponentProps<typeof DrawerTrigger>;

function CalendarPopoverTrigger({ ...props }: CalendarPopoverTriggerProps) {
    const breakpoint = useMediaQuery("md");
    if (breakpoint) {
        return <PopoverTrigger {...props} />;
    }
    return <DrawerTrigger {...props} />;
}

type CalendarPopoverContentProps =
    | ComponentProps<typeof PopoverContent>
    | ComponentProps<typeof DrawerContent>;

function CalendarPopoverContent({ children, className, ...props }: CalendarPopoverContentProps) {
    const breakpoint = useMediaQuery("md");
    if (breakpoint) {
        return <PopoverContent {...props}>{children}</PopoverContent>;
    }
    return (
        <DrawerContent className={cn("!pb-11 items-center", className)} {...props}>
            <DrawerTitle className={"sr-only"}>Calendar picker drawer</DrawerTitle>
            <DrawerDescription className={"sr-only"}>
                Calendar picker drawer for selecting date
            </DrawerDescription>
            {children}
        </DrawerContent>
    );
}

export {
    DatePicker,
    TimePicker,
    Calendar,
    RangeCalendar,
    DateField,
    DateInput,
    DateSegment,
    TimeField,
    dateInputStyle,
    CalendarPopover,
    CalendarPopoverContent,
    CalendarPopoverTrigger,
};
export type { DateInputProps };
