import { httpErrorHandler } from "@/api/api";
import { useEditAttendanceMutation } from "@/api/queries/meetingsQuery";
import MeetingAttendanceToggles from "@/components/features/meeting/MeetingAttendanceToggles";
import UserCard from "@/components/features/user/UserCard";
import { Button } from "@/components/ui/button/Button";
import { Separator } from "@/components/ui/separator/Separator";
import { BookmarkPlus, LoaderCircle } from "lucide-react";
import { useCallback, useState } from "react";
import { toast } from "sonner";
import type { MeetingAttendance } from "@/types";

type MeetingAttendanceProps = {
    attendance: MeetingAttendance[];
    meetingId: number;
};

export default function MeetingAttendance(props: MeetingAttendanceProps) {
    const { attendance, meetingId } = props;

    const [hasChanges, setHasChanges] = useState<boolean>(false);
    const [attendanceData, setAttendanceData] = useState<MeetingAttendance[]>(attendance);

    const setAllPresent = useCallback(() => {
        setAttendanceData(prev => prev.map(a => ({ ...a, status: "present" })));
        setHasChanges(true);
    }, []);

    const { isPending, mutateAsync } = useEditAttendanceMutation();

    const handleAttendanceChange = useCallback((newStatus: string, userId: number) => {
        setAttendanceData(prevData => {
            return prevData.map(a =>
                a.userId == userId
                    ? { ...a, status: newStatus as "present" | "absent" | "late" }
                    : a,
            );
        });
        setHasChanges(true);
    }, []);
    const onCancel = useCallback(() => {
        setAttendanceData(attendance);
        setHasChanges(false);
    }, [attendance]);

    const onSubmit = () => {
        mutateAsync({
            id: meetingId,
            attendanceData: attendanceData,
        })
            .then(() => {
                setHasChanges(false);
            })
            .catch(error => {
                const { title, detail } = httpErrorHandler(error);
                toast.error(title, {
                    description: detail,
                });
            });
    };

    return (
        <div className={"flex h-full w-full grow flex-col overflow-auto"}>
            <header className={"flex items-center justify-between gap-2 p-3 md:p-4"}>
                <div className={"flex h-10 items-center"}>
                    <h3 className={"w-full truncate text-lg font-semibold"}>Lista obecności</h3>
                </div>
                <Button onClick={setAllPresent} size={"sm"} variant={"outline"}>
                    <BookmarkPlus />
                </Button>
            </header>
            <Separator />
            <div className={"h-full w-full p-3 md:p-4"}>
                {attendanceData.map(attendance => {
                    return (
                        <div
                            className={"w-full flex items-center justify-between gap-2"}
                            key={attendance.userId}
                        >
                            <UserCard user={attendance.user} />
                            <MeetingAttendanceToggles
                                status={attendance.status}
                                userId={attendance.userId}
                                onValueChange={handleAttendanceChange}
                            />
                        </div>
                    );
                })}
            </div>
            <div className={"flex justify-end gap-2 px-3 pb-3 md:px-4 md:pb-4"}>
                <Button
                    disabled={!hasChanges || isPending}
                    onClick={onCancel}
                    variant={"tertiary"}
                    className={"w-full"}
                >
                    Anuluj
                </Button>
                <Button
                    disabled={!hasChanges || isPending}
                    onClick={onSubmit}
                    variant={"secondary"}
                    className={"w-full"}
                >
                    {isPending ? (
                        <>
                            <LoaderCircle className={"animate-spin"} aria-hidden={"true"} />
                            Zapisywanie...
                        </>
                    ) : (
                        "Zapisz"
                    )}
                </Button>
            </div>
        </div>
    );
}
