import { httpErrorHandler } from "@/api/api";
import { useInfiniteQueryResult } from "@/api/api-utils";
import {
    useGetCreateConversationUsersInfiniteQuery,
    usePostConversationMutation,
} from "@/api/queries/chatQueries";
import { useGetAssignedGroupsInfiniteQuery } from "@/api/queries/groupsQueries";
import { Avatar, AvatarFallback, AvatarImage } from "@/components/ui/avatar/Avatar";
import { Button } from "@/components/ui/button/Button";
import {
    Dialog,
    DialogBody,
    DialogContent,
    DialogFooter,
    DialogHeader,
    DialogTitle,
    DialogDescription,
} from "@/components/ui/dialog/Dialog";
import { Input, InputWrapper } from "@/components/ui/input/Input";
import { SelectDataListElement } from "@/components/ui/select-list/SelectDataListElement";
import SelectDataList from "@/components/ui/select-list/SelectDataList";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs/Tabs";
import { Tag } from "@/components/ui/tags/Tags";
import { useDebounceValue } from "@/hooks/useDebounceValue";
import { GroupPrivate } from "@/types";
import { getUserLvl } from "@/utils/getUserLvl";
import { Search, CircleX, LoaderCircle, CheckCircle2, User, Users } from "lucide-react";
import { Dispatch, SetStateAction, useState } from "react";
import { toast } from "sonner";
import type { UserPublic } from "@/types";
import { getAvatar } from "@/utils/getAvatar";

interface CreateConversationFormProps {
    open: boolean;
    onOpenChange: Dispatch<SetStateAction<boolean>>;
}

export default function CreateChat({ open, onOpenChange }: CreateConversationFormProps) {
    const [searchValue, setSearchValue] = useState<string>("");
    const searchDebouncedValue = useDebounceValue(searchValue, 400);

    const [selectedUsers, setSelectedUsers] = useState<UserPublic[]>([]);
    const [selectedGroups, setSelectedGroups] = useState<GroupPrivate[]>([]);

    const getUsersInfiniteQuery = useGetCreateConversationUsersInfiniteQuery({
        name: searchDebouncedValue,
    });

    const {
        data: users,
        isEmpty: usersIsEmpty,
        noResults: usersNoResults,
    } = useInfiniteQueryResult(getUsersInfiniteQuery.data, searchDebouncedValue);

    const getAssignedGroupsInfiniteQuery = useGetAssignedGroupsInfiniteQuery({
        name: searchDebouncedValue,
    });

    const {
        data: groups,
        isEmpty: groupsIsEmpty,
        noResults: groupsNoResults,
    } = useInfiniteQueryResult(getAssignedGroupsInfiniteQuery.data, searchDebouncedValue);

    const { mutateAsync, isPending } = usePostConversationMutation();

    const onClose = () => {
        onOpenChange(false);
        setSelectedUsers([]);
        setSelectedGroups([]);
    };
    const onSubmit = async () => {
        const userIds = selectedUsers.map(user => user.id);
        const groupIds = selectedGroups.map(group => group.id);
        await mutateAsync({ userIds: userIds, groupIds: groupIds })
            .then(() => {
                onClose();
            })
            .catch(error => {
                const { title, detail } = httpErrorHandler(error);
                toast.error(title, {
                    description: detail,
                });
            });
    };
    const onRemoveUser = (id: number) => {
        setSelectedUsers(prev => prev.filter(user => user.id !== id));
    };
    const onRemoveGroup = (id: number) => {
        setSelectedGroups(prev => prev.filter(group => group.id !== id));
    };

    return (
        <Dialog open={open} onOpenChange={onOpenChange}>
            <DialogContent
                className={"sm:max-w-108 sm:h-[85dvh] sm:min-h-96"}
                onCloseAutoFocus={onClose}
                onEscapeKeyDown={onClose}
            >
                {isPending && (
                    <div
                        className={
                            "absolute inset-0 z-60 m-auto flex flex-wrap items-center justify-center gap-3 bg-surface-primary/75 backdrop-blur-md"
                        }
                    >
                        <LoaderCircle className={"animate-spin"} aria-hidden={"true"} />
                        <h5 className={"font-medium"}>Trwa tworzenie chatu...</h5>
                    </div>
                )}

                <DialogBody>
                    <DialogHeader className={"border-b border-border-primary py-5"}>
                        <DialogTitle>Utwórz konwersację</DialogTitle>
                    </DialogHeader>
                    <DialogDescription className={"sr-only"}>
                        Formularz do tworzenia chatu
                    </DialogDescription>
                    <Tabs
                        className={"flex flex-col gap-3 px-3 md:px-4 py-3 grow overflow-y-auto"}
                        defaultValue={"users"}
                    >
                        <div className={"flex flex-col gap-4"}>
                            <TabsList className={"grid w-full grid-cols-2"}>
                                <TabsTrigger value={"users"}>Użytkownicy</TabsTrigger>
                                <TabsTrigger value={"groups"}>Grupy</TabsTrigger>
                            </TabsList>
                            <InputWrapper className={"w-full"}>
                                <Input
                                    value={searchValue}
                                    onChange={e => setSearchValue(e.target.value)}
                                    className="peer pe-8 ps-8"
                                    placeholder="Wyszukaj..."
                                />
                                <div className="pointer-events-none absolute inset-y-0 start-0 flex items-center justify-center ps-2 peer-disabled:opacity-50">
                                    <Search className="size-3 stroke-icon-tertiary" />
                                </div>
                                {searchValue && (
                                    <button
                                        className="absolute cursor-pointer inset-y-0 end-0 flex h-full w-9 items-center justify-center rounded-e-sm text-text-tertiary transition-colors hover:text-text-tertiary-hover z-10 focus-visible:outline-2 outline-ring-focus disabled:pointer-events-none disabled:cursor-not-allowed disabled:opacity-50"
                                        aria-label="Clear input"
                                        onClick={() => setSearchValue("")}
                                    >
                                        <CircleX className={"size-3"} aria-hidden="true" />
                                    </button>
                                )}
                            </InputWrapper>
                            <div className="flex flex-wrap gap-1">
                                {selectedUsers.map(({ id, name, surname, avatarURL }) => (
                                    <Tag
                                        key={id}
                                        onRemove={() => onRemoveUser(id)}
                                        variant={"muted"}
                                        border
                                    >
                                        <Avatar size={"xs"}>
                                            <AvatarImage
                                                src={getAvatar(avatarURL)}
                                                alt={name + "_" + surname}
                                            />
                                            <AvatarFallback>
                                                <User />
                                            </AvatarFallback>
                                        </Avatar>
                                        {name} {surname.at(0)}.
                                    </Tag>
                                ))}
                                {selectedGroups.map(({ id, name, avatarURL }) => (
                                    <Tag
                                        key={id}
                                        onRemove={() => onRemoveGroup(id)}
                                        variant={"muted"}
                                        border
                                    >
                                        <Avatar size={"xs"}>
                                            <AvatarImage src={getAvatar(avatarURL)} alt={name} />
                                            <AvatarFallback>
                                                <Users />
                                            </AvatarFallback>
                                        </Avatar>
                                        {name}
                                    </Tag>
                                ))}
                            </div>
                        </div>
                        <TabsContent value={"users"}>
                            <SelectDataList
                                query={getUsersInfiniteQuery}
                                selected={selectedUsers}
                                setSelected={setSelectedUsers}
                                isEmpty={usersIsEmpty}
                                isNoResults={usersNoResults}
                                emptyMessage={"Wyszukaj użytkownika"}
                                searchNoResultsMessage={"Nie znaleziono uzytkownika"}
                            >
                                {users.map(entity => (
                                    <SelectDataListElement
                                        key={entity.id}
                                        entity={entity}
                                        data={{
                                            title: entity.name + " " + entity.surname,
                                            avatarURL: entity.avatarURL,
                                            detail: getUserLvl(entity.lvl),
                                        }}
                                    />
                                ))}
                            </SelectDataList>
                        </TabsContent>
                        <TabsContent value={"groups"}>
                            <SelectDataList
                                query={getAssignedGroupsInfiniteQuery}
                                selected={selectedGroups}
                                setSelected={setSelectedGroups}
                                isEmpty={groupsIsEmpty}
                                isNoResults={groupsNoResults}
                                emptyMessage={"Wyszukaj grupy"}
                                searchNoResultsMessage={"Nie znaleziono grupy"}
                            >
                                {groups.map(entity => (
                                    <SelectDataListElement
                                        key={entity.id}
                                        entity={entity}
                                        data={{
                                            title: entity.name,
                                            avatarURL: entity.avatarURL,
                                        }}
                                    />
                                ))}
                            </SelectDataList>
                        </TabsContent>
                    </Tabs>
                </DialogBody>
                <DialogFooter className={"border-t border-border-primary"}>
                    <Button
                        onClick={onClose}
                        type={"button"}
                        variant={"tertiary"}
                        className={"w-full md:w-fit"}
                    >
                        Anuluj
                    </Button>
                    <Button
                        disabled={
                            (selectedUsers.length === 0 && selectedGroups.length === 0) || isPending
                        }
                        onClick={onSubmit}
                        type={"submit"}
                        variant={"primary"}
                        className={"w-full md:w-fit"}
                    >
                        {isPending ? (
                            <>
                                <LoaderCircle className={"animate-spin"} aria-hidden={"true"} />
                                Zapisywanie...
                            </>
                        ) : (
                            <>
                                <CheckCircle2 />
                                Zapisz
                            </>
                        )}
                    </Button>
                </DialogFooter>
            </DialogContent>
        </Dialog>
    );
}
