import { httpErrorHandler } from "@/api/api";
import { useEditDirectoryMutation } from "@/api/queries/directoryQueries";
import { useEditFileMutation } from "@/api/queries/filesQueries";
import { Button } from "@/components/ui/button/Button";
import {
    Dialog,
    DialogBody,
    DialogContent,
    DialogFooter,
    DialogHeader,
    DialogTitle,
    DialogDescription,
} from "@/components/ui/dialog/Dialog";
import { Form, FormControl, FormField, FormItem, FormMessage } from "@/components/ui/form/Form";
import { Input, InputWrapper } from "@/components/ui/input/Input";
import { getIsFSEntityFile } from "@/utils/files";
import { yupResolver } from "@hookform/resolvers/yup";
import { LoaderCircle, ArrowRight, Folder, FileIcon } from "lucide-react";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { InferType, object, string } from "yup";
import { toast } from "sonner";
import type { DirectoryPublic, FilePublicWithPermissions } from "@/types";
interface StorageElementEditNameFormProps {
    element?: FilePublicWithPermissions | DirectoryPublic;
    directoryId?: string;
    onOpenChange: (open: boolean) => void;
    open: boolean;
}

const EditFSEntryNameSchema = object({
    id: string().required(),
    name: string().required("Nazwa jest wymagana"),
});

type EditFSEntryNameSchemaType = InferType<typeof EditFSEntryNameSchema>;

function useEditFSEntryForm(element?: FilePublicWithPermissions | DirectoryPublic) {
    const isFile = getIsFSEntityFile(element);
    return { mutation: isFile ? useEditFileMutation : useEditDirectoryMutation, isFile: isFile };
}

export default function EditFSEntryName({
    element,
    onOpenChange,
    open,
    directoryId,
}: StorageElementEditNameFormProps) {
    const { mutation: editMutation, isFile } = useEditFSEntryForm(element);
    const { mutateAsync, isPending } = editMutation(directoryId);

    const splitName = (fileName?: string) => {
        if (fileName === undefined) {
            return { name: "", extension: "" };
        }
        const lastDotIndex = fileName.lastIndexOf(".");
        if (lastDotIndex === -1) {
            return { name: fileName, extension: "" };
        }
        return {
            name: fileName.substring(0, lastDotIndex),
            extension: fileName.substring(lastDotIndex),
        };
    };

    const form = useForm<EditFSEntryNameSchemaType>({
        mode: "onBlur",
        defaultValues: {
            id: "",
            name: "",
        },
        resolver: yupResolver(EditFSEntryNameSchema),
    });

    const onClose = () => {
        form.reset();
        onOpenChange(false);
    };

    const onSubmit = (data: EditFSEntryNameSchemaType) => {
        const { extension } = splitName(element?.name);
        const newData = { ...data, name: data.name + extension };
        mutateAsync(newData)
            .then(() => {
                onClose();
            })
            .catch(error => {
                const { title, detail } = httpErrorHandler(error);
                toast.error(title, {
                    description: detail,
                });
            });
    };

    useEffect(() => {
        if (open && element) {
            const { name } = splitName(element.name);
            form.setValue("name", name);
            form.setValue("id", element.id);
        }
    }, [open, element, form]);

    return (
        <Dialog open={open} onOpenChange={onOpenChange}>
            <DialogContent
                className={"sm:max-w-108"}
                onCloseAutoFocus={onClose}
                onEscapeKeyDown={onClose}
            >
                <DialogDescription className={"sr-only"}>
                    Formularz do edycji pliku/folderu
                </DialogDescription>
                <Form {...form}>
                    <DialogBody>
                        <DialogHeader>
                            <DialogTitle>Edytuj nazwę</DialogTitle>
                        </DialogHeader>

                        <form
                            className={"flex flex-col gap-3 p-3 md:p-4 overflow-auto grow"}
                            onSubmit={form.handleSubmit(onSubmit)}
                            noValidate
                        >
                            <FormField
                                name={"name"}
                                control={form.control}
                                render={({ field }) => (
                                    <FormItem className={"col-span-1"}>
                                        <FormControl>
                                            <InputWrapper>
                                                <Input
                                                    {...field}
                                                    placeholder={"Nazwa"}
                                                    className="peer ps-9"
                                                />
                                                <div className="text-text-tertiary pointer-events-none absolute inset-y-0 start-0 flex items-center justify-center ps-3 peer-disabled:opacity-50">
                                                    {isFile ? (
                                                        <FileIcon aria-hidden="true" />
                                                    ) : (
                                                        <Folder aria-hidden="true" />
                                                    )}
                                                </div>
                                            </InputWrapper>
                                        </FormControl>
                                        <FormMessage />
                                    </FormItem>
                                )}
                            />
                        </form>
                    </DialogBody>
                </Form>
                <DialogFooter>
                    <Button
                        onClick={onClose}
                        type={"button"}
                        variant={"tertiary"}
                        className={"w-full md:w-fit"}
                    >
                        Anuluj
                    </Button>
                    <Button
                        disabled={isPending}
                        onClick={form.handleSubmit(onSubmit)}
                        type={"submit"}
                        variant={"primary"}
                        className={"w-full md:w-fit"}
                    >
                        {isPending ? (
                            <>
                                <LoaderCircle className={"animate-spin"} aria-hidden={"true"} />
                                Zapisywanie...
                            </>
                        ) : (
                            <>
                                Zapisz
                                <ArrowRight
                                    className={" transition-transform group-hover:translate-x-0.5"}
                                />
                            </>
                        )}
                    </Button>
                </DialogFooter>
            </DialogContent>
        </Dialog>
    );
}
