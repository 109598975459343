"use no memo";

import { filterByT } from "@/components/features/manage/tanstack-table/TanstackTable";
import { Button } from "@/components/ui/button/Button";
import {
    Drawer,
    DrawerBody,
    DrawerContent,
    DrawerDescription,
    DrawerTitle,
} from "@/components/ui/drawer/Drawer";
import {
    Sheet,
    SheetBody,
    SheetContent,
    SheetDescription,
    SheetTitle,
} from "@/components/ui/sheet/Sheet";
import { Tag } from "@/components/ui/tags/Tags";
import { Toggle } from "@/components/ui/toggle/Toggle";
import { useMediaQuery } from "@/hooks/useMediaQuery";
import { Slot } from "@radix-ui/react-slot";
import { Table as TableT } from "@tanstack/react-table";
import { ArrowDownIcon, ArrowUpDown, ArrowUpIcon, X } from "lucide-react";
import {
    Dispatch,
    HTMLAttributes,
    ReactNode,
    SetStateAction,
    createContext,
    forwardRef,
    useContext,
    useState,
} from "react";

interface TanstackTableViewOptionsContextT {
    open: boolean;
    onOpenChange: Dispatch<SetStateAction<boolean>>;
    table: TableT<any>;
}

const TanstackTableViewOptionsContext = createContext<TanstackTableViewOptionsContextT | null>(
    null,
);

const useTanstackTableViewOptionsContext = () => {
    const context = useContext(TanstackTableViewOptionsContext);
    if (!context) {
        throw new Error(
            "useTanstackTableViewOptionsContext must be used within a TanstackTableViewOptionsContext",
        );
    }
    return context;
};

interface TanstackTableViewOptionsProps<T> {
    table: TableT<T>;
    children?: ReactNode;
}

function TanstackTableViewOptions<TData>({
    table,
    children,
}: TanstackTableViewOptionsProps<TData>) {
    const [open, onOpenChange] = useState<boolean>(false);

    return (
        <TanstackTableViewOptionsContext.Provider value={{ open, onOpenChange, table }}>
            {children}
        </TanstackTableViewOptionsContext.Provider>
    );
}

const TanstackTableViewOptionsTrigger = forwardRef<HTMLElement, HTMLAttributes<HTMLElement>>(
    ({ children, ...props }, ref) => {
        const { open, onOpenChange } = useTanstackTableViewOptionsContext();

        const onClick = () => {
            onOpenChange(!open);
        };

        return (
            <Slot {...props} ref={ref} onClick={onClick}>
                {children}
            </Slot>
        );
    },
);
TanstackTableViewOptionsTrigger.displayName = "TanstackTableViewOptionsTrigger";

interface TanstackTableViewOptionsContentProps {
    children: ReactNode;
}

function TanstackTableViewOptionsContent({ children }: TanstackTableViewOptionsContentProps) {
    const { open, onOpenChange } = useTanstackTableViewOptionsContext();
    const breakpoint = useMediaQuery("sm");

    if (breakpoint) {
        return (
            <Sheet open={open} onOpenChange={onOpenChange}>
                <SheetContent className={"max-w-120"}>
                    <SheetTitle className={"sr-only"}>Filtry</SheetTitle>
                    <SheetDescription className={"sr-only"}>Filtry</SheetDescription>
                    <SheetBody className={"flex flex-col gap-6 p-3 md:p-4"}>{children}</SheetBody>
                </SheetContent>
            </Sheet>
        );
    }
    return (
        <Drawer open={open} onOpenChange={onOpenChange}>
            <DrawerContent>
                <DrawerTitle className={"sr-only"}>Filtry</DrawerTitle>
                <DrawerDescription className={"sr-only"}>Filtry</DrawerDescription>
                <DrawerBody className="gap-6 p-3 md:p-4">{children}</DrawerBody>
            </DrawerContent>
        </Drawer>
    );
}

interface TanstackTableViewOptionsContentFilterProps {
    filterBy?: filterByT[];
}

function TanstackTableViewOptionsContentFilter({
    filterBy,
}: TanstackTableViewOptionsContentFilterProps) {
    const { table } = useTanstackTableViewOptionsContext();

    if (!filterBy) {
        return null;
    }

    return (
        <div className={"flex flex-col gap-3"}>
            <h3 className={"text-lg font-medium"}>Filtry</h3>
            <div className={"flex flex-col gap-5"}>
                {filterBy.map(({ id, title, options }) => {
                    const column = table.getColumn(id);
                    const filterValue = column?.getFilterValue();
                    const selectedValues = new Set(Array.isArray(filterValue) ? filterValue : []);

                    return (
                        <div key={id} className={"flex flex-col gap-4"}>
                            <div className={"flex h-10 items-center justify-between"}>
                                <p className={"font-md text-md text-text-tertiary"}>{title}:</p>
                                {selectedValues.size > 0 && (
                                    <Button
                                        variant={"linkPrimary"}
                                        variantColor={"destructive"}
                                        size={"sm"}
                                        onClick={() => column?.setFilterValue(undefined)}
                                    >
                                        <X />
                                        Wyczyść
                                    </Button>
                                )}
                            </div>
                            <div className={"flex flex-wrap gap-2"}>
                                {options.map(option => {
                                    const isSelected = selectedValues.has(option.value);
                                    return (
                                        <Toggle
                                            key={option.value.toString()}
                                            data-state={isSelected ? "on" : "off"}
                                            aria-checked={isSelected}
                                            variant={"outline"}
                                            onClick={() => {
                                                if (isSelected) {
                                                    selectedValues.delete(option.value);
                                                } else {
                                                    selectedValues.add(option.value);
                                                }
                                                column?.setFilterValue(Array.from(selectedValues));
                                            }}
                                        >
                                            {option.label.toUpperCase()}
                                        </Toggle>
                                    );
                                })}
                            </div>
                        </div>
                    );
                })}
            </div>
        </div>
    );
}

function TanstackTableViewOptionsContentSort() {
    const { table } = useTanstackTableViewOptionsContext();

    return (
        <div className={"flex flex-col gap-3"}>
            <h3 className={"text-lg font-medium"}>Sortowanie</h3>
            <div className={"flex flex-wrap gap-2"}>
                {table
                    .getAllColumns()
                    .filter(column => column.getCanSort())
                    .map(column => (
                        <Tag
                            key={column.id}
                            variant={"muted"}
                            border
                            size={"md"}
                            className={"w-fit"}
                            onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
                        >
                            {column.getIsSorted() === "desc" ? (
                                <ArrowDownIcon aria-hidden="true" />
                            ) : column.getIsSorted() === "asc" ? (
                                <ArrowUpIcon aria-hidden="true" />
                            ) : (
                                <ArrowUpDown aria-hidden="true" />
                            )}
                            {column.columnDef.meta?.toString().toUpperCase() ?? ""}
                        </Tag>
                    ))}
            </div>
        </div>
    );
}

function TanstackTableViewOptionsContentVisibility() {
    const { table } = useTanstackTableViewOptionsContext();

    return (
        <div className={"flex flex-col gap-3"}>
            <h3 className={"text-lg font-medium"}>Kolumny</h3>
            <div className={"flex flex-wrap gap-2"}>
                {table
                    .getAllColumns()
                    .filter(column => column.getCanHide())
                    .map(column => {
                        const isSelected = column.getIsVisible();
                        return (
                            <Toggle
                                key={column.id}
                                data-state={isSelected ? "on" : "off"}
                                aria-checked={isSelected}
                                variant={"outline"}
                                className={"capitalize"}
                                onClick={() => column.toggleVisibility()}
                            >
                                {column.columnDef.meta?.toString().toUpperCase() ?? ""}
                            </Toggle>
                        );
                    })}
            </div>
        </div>
    );
}

export {
    TanstackTableViewOptions,
    TanstackTableViewOptionsContent,
    TanstackTableViewOptionsTrigger,
    TanstackTableViewOptionsContentFilter,
    TanstackTableViewOptionsContentSort,
    TanstackTableViewOptionsContentVisibility,
};
