import { PostMeetingProps, PostMeetingScheduleProps } from "@/api/endpoints/meetings";
import { CalendarFormSchemaType } from "@/schemas/meeting.schema";
import type { MeetingPrivate } from "@/types";
import { parseTime } from "@internationalized/date";
import { format, formatISO } from "date-fns";
import { serializeData } from "@/utils/serializeData";
import { CalendarFormSchema } from "@/schemas/meeting.schema";

function convertDataToForm(data?: MeetingPrivate): Partial<CalendarFormSchemaType> | undefined {
    if (!data) return;

    const { startDate, endDate, ...rest } = data;

    const handleFormatTime = (date: string) => {
        try {
            return format(new Date(date), "HH:mm:ss");
        } catch {
            return "";
        }
    };

    return CalendarFormSchema.cast(
        serializeData({
            date: startDate,
            startTime: handleFormatTime(startDate),
            endTime: handleFormatTime(endDate),
            ...rest,
        }),
        {
            stripUnknown: true,
            assert: false,
        },
    );
}

function convertFormToData({
    date,
    startDate,
    endDate,
    startTime,
    endTime,
    name,
    desc,
    type,
    location,
    meetingURL,
    groups,
    users,
    method,
    excludeHolidays,
    schedule,
}: Partial<CalendarFormSchemaType>): PostMeetingProps | PostMeetingScheduleProps {
    const handleSetTime = (dateString?: string, timeString?: string): string => {
        if (!dateString || !timeString) {
            return "";
        }
        const dateValue = new Date(dateString);
        const timeValue = parseTime(timeString);

        const combinedDateTime = new Date(
            dateValue.getFullYear(),
            dateValue.getMonth(),
            dateValue.getDate(),
            timeValue.hour,
            timeValue.minute,
            timeValue.second || 0,
            0,
        );
        return formatISO(combinedDateTime);
    };

    if (method === "schedule") {
        const processedSchedule = schedule?.map(item => ({
            ...item,
            locationId: item.type === "remote" ? null : item.locationId,
            meetingURL: item.type === "stationary" ? null : item.meetingURL,
        }));

        return {
            name: name,
            desc: desc,
            startDate: startDate,
            endDate: endDate,
            userArr: users?.map(user => user.id),
            groupArr: groups?.map(group => group.id),
            excludeHolidays: excludeHolidays,
            schedule: processedSchedule,
        } as PostMeetingScheduleProps;
    }
    return {
        name: name,
        desc: desc,
        type: type,
        startDate: handleSetTime(date, startTime),
        endDate: handleSetTime(date, endTime),
        locationId: type === "remote" ? null : location?.id,
        meetingURL: type === "stationary" ? null : meetingURL,
        userArr: users?.map(user => user.id),
        groupArr: groups?.map(group => group.id),
    } as PostMeetingProps;
}

export { convertDataToForm, convertFormToData };
