import { phoneRegex } from "@/lib/regex";
import { InferType, boolean, number, object, string } from "yup";
import type { MakeOptionalExcept } from "@/types";

const UserAddSchema = object({
    name: string().required("Wpisz imię"),
    surname: string().required("Wpisz nazwisko"),
    email: string().email("Niepoprawny adres email").required("Wpisz adres email"),
    lvl: string().required("Wybierz poziom dostępu"),
    phone: string()
        .matches(phoneRegex, {
            message: "Niepoprawny numer telefonu",
            excludeEmptyString: true,
        })
        .optional()
        .trim(),
    birthdate: string().nullable().optional(),
    password: string().min(8).required(),
});
type UserAddSchemaType = InferType<typeof UserAddSchema>;

const UserEditSchema = object({
    id: number().required(),
    name: string().required("Wpisz imię"),
    surname: string().required("Wpisz nazwisko"),
    email: string().email("Niepoprawny adres email").required("Wpisz adres email"),
    lvl: string().required("Wybierz poziom dostępu"),
    phone: string()
        .matches(phoneRegex, {
            message: "Niepoprawny numer telefonu",
            excludeEmptyString: true,
        })
        .optional()
        .trim(),
    birthdate: string().nullable().optional(),
    active: boolean().optional(),
    force_password_change: boolean().optional(),
});
type UserEditSchemaType = InferType<typeof UserEditSchema>;

const UserMassImportSchema = object({
    name: string().required("Imię jest wymagane"),
    surname: string().required("Nazwisko jest wymagane"),
    email: string().email("Niepoprawny email").required("Email jest wymagany"),
    phone: string()
        .matches(phoneRegex, {
            message: "Niepoprawny numer telefonu",
            excludeEmptyString: true,
        })
        .trim()
        .optional(),
    birthdate: string().optional(),
});
type UserMassImportSchemaType = InferType<typeof UserMassImportSchema>;

type UserEditType = MakeOptionalExcept<UserEditSchemaType, "id">;

export { UserAddSchema, UserEditSchema, UserMassImportSchema };
export type { UserAddSchemaType, UserEditType, UserEditSchemaType, UserMassImportSchemaType };
