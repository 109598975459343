import CreatePost from "@/components/features/manage/manage-posts/CreatePost";
import { Button } from "@/components/ui/button/Button";
import { Label } from "@/components/ui/label/Label";
import { FileUp, ImagePlus, Video } from "lucide-react";
import { Fragment, useState } from "react";

export default function HomeCreatePost() {
    const [openPostAdd, setOpenPostAdd] = useState<boolean>(false);
    const [, setForceFocus] = useState<"input" | "image" | "video" | "file" | undefined>(undefined);

    return (
        <Fragment>
            <CreatePost open={openPostAdd} onOpenChange={setOpenPostAdd} />
            <div
                className={
                    "flex w-full flex-col gap-5 rounded-lg border-1 border-border-primary bg-surface-primary p-3 md:p-4"
                }
            >
                <Label className={"text-xl font-semibold text-fg-primary"}>Utwórz Post</Label>
                <div className={"flex flex-col gap-3"}>
                    <button
                        onClick={() => {
                            setForceFocus("input");
                            setOpenPostAdd(true);
                        }}
                        className={
                            "transition-color inline-flex min-h-15 w-full cursor-pointer rounded-sm border border-border-primary bg-fill-primary px-4 py-2 text-md text-text-tertiary duration-100 hover:bg-fill-primary-hover"
                        }
                    >
                        Napisz wiadomość...
                    </button>
                    <div className={"flex w-full justify-end gap-2"}>
                        <Button
                            type={"button"}
                            variant={"tertiary"}
                            onClick={() => {
                                setForceFocus("image");
                                setOpenPostAdd(true);
                            }}
                        >
                            <ImagePlus />
                        </Button>
                        <Button
                            type={"button"}
                            variant={"tertiary"}
                            onClick={() => {
                                setForceFocus("video");
                                setOpenPostAdd(true);
                            }}
                        >
                            <Video />
                        </Button>
                        <Button
                            type={"button"}
                            variant={"tertiary"}
                            onClick={() => {
                                setForceFocus("file");
                                setOpenPostAdd(true);
                            }}
                        >
                            <FileUp />
                        </Button>
                    </div>
                </div>
            </div>
        </Fragment>
    );
}
