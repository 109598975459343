"use client";

import { cn } from "@/lib/utils";
import * as ProgressPrimitive from "@radix-ui/react-progress";
import * as React from "react";

interface ProgressWithValueProps
    extends React.ComponentPropsWithoutRef<typeof ProgressPrimitive.Root> {
    position?: "start" | "start-outside" | "follow" | "end" | "end-outside" | "none";
    label?: (value?: number | null) => React.ReactNode;
    valueClassName?: string;
}

const Progress = React.forwardRef<
    React.ElementRef<typeof ProgressPrimitive.Root>,
    ProgressWithValueProps
>(({ className, valueClassName, value, position = "none", label, ...props }, ref) => {
    const valueCommonClass = cn("absolute -top-0.5 left-0 h-fit px-4 w-full items-center hidden");

    const ProgressValue = () => (
        <span
            className={cn(
                "hidden text-xs text-text-secondary",
                position === "start-outside" && "block",
                position === "follow" && cn(valueCommonClass, "flex justify-end"),
                position === "start" && cn(valueCommonClass, "flex justify-start"),
                position === "end" && cn(valueCommonClass, "flex justify-end"),
                position === "end-outside" && "block",
                valueClassName,
            )}
        >
            {typeof label === "function" ? label(value) : `${value}%`}
        </span>
    );

    return (
        <div className="flex items-center gap-2">
            {position === "start-outside" && <ProgressValue />}
            <ProgressPrimitive.Root
                ref={ref}
                className={cn(
                    "relative h-1 w-full overflow-hidden rounded-lg bg-fill-secondary",
                    className,
                )}
                {...props}
            >
                <ProgressPrimitive.Indicator
                    className="h-full w-full flex-1 bg-fill-brand transition-all"
                    style={{ transform: `translateX(-${100 - (value || 0)}%)` }}
                >
                    {position === "follow" && <ProgressValue />}
                </ProgressPrimitive.Indicator>
                {(position === "start" || position === "end") && <ProgressValue />}
            </ProgressPrimitive.Root>
            {position === "end-outside" && <ProgressValue />}
        </div>
    );
});
Progress.displayName = ProgressPrimitive.Root.displayName;
export { Progress };
