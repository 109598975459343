import { useBatchMutation } from "@/api/api-utils";
import {
    createDirectory,
    deleteDirectory,
    editDirectory,
    getDirectory,
    getDirectoryPermissions,
    getSharedDirectory,
} from "@/api/endpoints/directories";
import { queryClient } from "@/api/query-client";
import { CreateDirectorySchemaType, EditDirectoryType } from "@/schemas/directory.schema";
import { useMutation, useQuery } from "@tanstack/react-query";
import type { DirectoryPrivate } from "@/types";

export const useGetDirectoryQuery = (directoryId: string, enable = true) => {
    return useQuery({
        queryKey: ["directory", directoryId],
        queryFn: () => getDirectory(directoryId),
        enabled: enable,
    });
};

export const useGetSharedDirectoryQuery = (enable: boolean) => {
    return useQuery({
        queryKey: ["sharedDirectory"],
        queryFn: () => getSharedDirectory(),
        enabled: enable,
    });
};

export const useGetDirectoryPermissionsQuery = (id?: string, enable = true) => {
    return useQuery({
        queryKey: ["directoryPermissions", id],
        queryFn: () => getDirectoryPermissions(id),
        enabled: enable && !!id,
    });
};

export const useCreateDirectoryMutation = () => {
    return useMutation({
        mutationFn: (data: CreateDirectorySchemaType) => createDirectory(data),
        onSuccess: (data, variables) => {
            queryClient.setQueryData<DirectoryPrivate>(
                ["directory", variables.parentDirId],
                prev => {
                    if (!prev) {
                        return;
                    }
                    return {
                        ...prev,
                        childDirs: [...prev.childDirs, data],
                    };
                },
            );
        },
        onSettled: (data, error, variables) => {
            void queryClient.invalidateQueries({
                queryKey: ["directory", variables.parentDirId],
            });
        },
    });
};

export const useEditDirectoryMutation = (directoryId?: string) => {
    return useMutation({
        mutationKey: ["editDir"],
        mutationFn: (data: EditDirectoryType) => editDirectory(data),
        onSettled: () => {
            void queryClient.invalidateQueries({
                queryKey: ["directory", directoryId],
            });
        },
    });
};

export const useEditDirectoriesMutation = (directoryId?: string) => {
    return useBatchMutation({
        mutationKey: ["editDir"],
        mutationFn: (data: EditDirectoryType) => editDirectory(data),
        onSettled: () => {
            void queryClient.invalidateQueries({
                queryKey: ["directory", directoryId],
            });
        },
    });
};

export const useDeleteDirectoryMutation = (directoryId?: string) => {
    return useMutation({
        mutationKey: ["deleteDir"],
        mutationFn: (id: string) => deleteDirectory(id),
        onMutate: async id => {
            await queryClient.cancelQueries({ queryKey: ["directory", directoryId] });
            const prevDirectory = queryClient.getQueryData(["directory", directoryId]);

            if (prevDirectory) {
                queryClient.setQueryData<DirectoryPrivate>(["directory", directoryId], prev => {
                    if (!prev) {
                        return;
                    }
                    return {
                        ...prev,
                        files: prev.files.filter(file => file.id !== id),
                    };
                });
            }

            return { prevDirectory };
        },
        onError: (err, variables, context) => {
            if (context?.prevDirectory) {
                queryClient.setQueryData(["directory", directoryId], context.prevDirectory);
            }
        },
        onSettled: () => {
            void queryClient.invalidateQueries({
                queryKey: ["directory", directoryId],
            });
        },
    });
};

export const useDeleteDirectoriesMutation = (directoryId?: string) => {
    return useBatchMutation({
        mutationKey: ["deleteDir"],
        mutationFn: (id: string) => deleteDirectory(id),
        onMutate: async id => {
            await queryClient.cancelQueries({ queryKey: ["directory", directoryId] });
            const prevDirectory = queryClient.getQueryData<DirectoryPrivate>([
                "directory",
                directoryId,
            ]);

            if (prevDirectory) {
                queryClient.setQueryData<DirectoryPrivate>(["directory", directoryId], prev => {
                    if (!prev) {
                        return;
                    }
                    return {
                        ...prev,
                        files: prev.files.filter(file => file.id !== id),
                    };
                });
            }

            return { prevDirectory };
        },
        onError: (err, variables, context) => {
            if (context?.prevDirectory) {
                queryClient.setQueryData(["directory", directoryId], context.prevDirectory);
            }
        },
        onSettled: () => {
            void queryClient.invalidateQueries({
                queryKey: ["directory", directoryId],
            });
        },
    });
};
