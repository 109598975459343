import { NotificationsSheetTrigger } from "@/components/features/notifications/NotificationsSheet";
import { Badge } from "@/components/ui/badge/Badge";
import { Button } from "@/components/ui/button/Button";
import Chip from "@/components/ui/chip/chip";
import { useCredentials } from "@/store/authStore";
import { format } from "date-fns";
import { Bell } from "lucide-react";
import { useEffect, useMemo, useState } from "react";
import { useGetUnreadNotificationsCountQuery } from "@/api/queries/notificationsQueries";

export default function HomeHeader() {
    const { name, surname } = useCredentials();
    const [time, setTime] = useState<string>(format(new Date(), "HH:mm"));
    const { data } = useGetUnreadNotificationsCountQuery("all");

    const notificationsNumber = useMemo(() => {
        if (data >= 100) {
            return "+99";
        }
        return String(data);
    }, [data]);

    useEffect(() => {
        const timer = setInterval(() => {
            setTime(format(new Date(), "HH:mm"));
        }, 10000);

        return () => clearInterval(timer);
    }, []);

    return (
        <>
            <div
                className={
                    "relative flex w-full flex-row items-center justify-between rounded-lg border border-border-primary bg-surface-primary p-3 md:p-4"
                }
            >
                <div className={"flex flex-col gap-2"}>
                    <h2 className={"text-3xl font-semibold text-fg-primary"}>{time}</h2>
                    <Badge variant={"muted"}>
                        {name} {surname}
                    </Badge>
                </div>
                <div>
                    <Chip
                        isInvisible={!data}
                        variantColor={"muted"}
                        variant={"flat"}
                        content={notificationsNumber}
                    >
                        <NotificationsSheetTrigger asChild>
                            <Button variant={"outline"}>
                                <Bell />
                                Powiadomienia
                            </Button>
                        </NotificationsSheetTrigger>
                    </Chip>
                </div>
            </div>
        </>
    );
}
