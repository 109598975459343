import {
    AlertDialog,
    AlertDialogAction,
    AlertDialogBody,
    AlertDialogCancel,
    AlertDialogContent,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogTitle,
} from "@/components/ui/dialog/AlertDialog";
import type { Dispatch, SetStateAction } from "react";
type CloseConfirmationAlertProps = {
    open: boolean;
    onOpenChange: Dispatch<SetStateAction<boolean>>;
    confirmAction: () => void;
};

export default function MeetingFormContentCloseAlert({
    open,
    onOpenChange,
    confirmAction,
}: CloseConfirmationAlertProps) {
    return (
        <AlertDialog open={open} onOpenChange={onOpenChange}>
            <AlertDialogContent className={"sm:max-w-96"}>
                <AlertDialogBody>
                    <AlertDialogHeader>
                        <AlertDialogTitle>Napewno chcesz zamknąć te okno?</AlertDialogTitle>
                    </AlertDialogHeader>
                    <div className={"p-3 md:p-4"}>
                        <p className={"text-text-secondary"}>
                            Czy napewno chesz zamknąć formularz tworzenia spotkania. Twoje zmiany
                            nie zostaną zachowane.
                        </p>
                    </div>
                </AlertDialogBody>
                <AlertDialogFooter>
                    <AlertDialogCancel>Anuluj</AlertDialogCancel>
                    <AlertDialogAction onClick={confirmAction}>Potwierdź</AlertDialogAction>
                </AlertDialogFooter>
            </AlertDialogContent>
        </AlertDialog>
    );
}
