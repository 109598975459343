import { Input, InputWrapper } from "@/components/ui/input/Input";
import { useMediaQuery } from "@/hooks/useMediaQuery";
import { cn } from "@/lib/utils";
import { useMainLayoutContext } from "@/page/MainLayout";
import { Search } from "lucide-react";
import { Dispatch, SetStateAction, useEffect, useState } from "react";

interface GroupsHeaderProps {
    onSearchChange: Dispatch<SetStateAction<string>>;
    searchValue: string;
}

export default function GroupsHeader({ onSearchChange, searchValue }: GroupsHeaderProps) {
    const breakpoint = useMediaQuery("sm");
    const { ref } = useMainLayoutContext();

    const [showShadow, setShowShadow] = useState<boolean>(false);

    useEffect(() => {
        const element = ref?.current;
        if (element) {
            element.addEventListener("scroll", () => {
                if (element.scrollTop > 44) {
                    setShowShadow(true);
                } else {
                    setShowShadow(false);
                }
            });
        }
    }, [ref]);

    return (
        <div
            className={cn(
                "grid grid-cols-3 grid-rows-1 gap-3 rounded-lg border border-border-primary bg-surface-primary p-3 md:p-4 w-full",
                showShadow ? "shadow-md" : null,
            )}
        >
            <h2 className={"self-center text-xl font-semibold text-fg-primary"}>Grupy</h2>
            <div
                className={cn(
                    breakpoint ? "col-[2_/_span_2] justify-self-end" : "col-[1_/_4] row-[2_/_2]",
                )}
            >
                <InputWrapper>
                    <Input
                        className={cn(breakpoint ? "max-w-[30ch]" : "w-full", "ps-9")}
                        value={searchValue}
                        onChange={e => onSearchChange(e.target.value)}
                        placeholder={"Szukaj..."}
                    />
                    <div className="absolute inset-y-0 start-0 ps-3 justify-center flex items-center">
                        <Search className="stroke-icon-tertiary" />
                    </div>
                </InputWrapper>
            </div>
        </div>
    );
}
