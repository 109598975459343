import Directory from "@/components/features/files/Directory";
import { DirectoryCardSkeleton } from "@/components/features/files/FsDirectoryCard";
import File from "@/components/features/files/File";
import { Label } from "@/components/ui/label/Label";
import { FileCardSkeleton } from "@/components/features/files/FsFileCard";
import type { DirectoryPublic, FilePublicWithPermissions } from "@/types";

interface FilesStorageContentGridProps {
    files?: FilePublicWithPermissions[];
    directories?: DirectoryPublic[];
    isSuccess: boolean;
    isLoading: boolean;
}

export default function FilesContentGrid({
    files = [],
    directories = [],
    isLoading,
    isSuccess,
}: FilesStorageContentGridProps) {
    const hasDirectories = isSuccess && directories.length > 0;
    const hasFiles = isSuccess && files.length > 0;

    return (
        <div className="flex h-full grow flex-col gap-4">
            {/* Directories Section */}
            {(isLoading || hasDirectories) && (
                <div className="flex flex-col">
                    <Label className="text-sm text-text-tertiary">Foldery</Label>
                    <div className="mt-3 grid grid-cols-[repeat(auto-fill,minmax(256px,1fr))] grid-rows-[auto] gap-4">
                        {isLoading ? (
                            <>
                                <DirectoryCardSkeleton />
                                <DirectoryCardSkeleton />
                                <DirectoryCardSkeleton />
                            </>
                        ) : (
                            directories.map(directory => (
                                <Directory key={directory.id} directory={directory} />
                            ))
                        )}
                    </div>
                </div>
            )}

            {/* Files Section */}
            {(isLoading || hasFiles) && (
                <div className="flex flex-col">
                    <Label className="text-sm text-text-tertiary">Pliki</Label>
                    <div className="mt-3 grid grid-cols-[repeat(auto-fill,minmax(256px,1fr))] grid-rows-[auto] gap-4">
                        {isLoading ? (
                            <>
                                <FileCardSkeleton />
                                <FileCardSkeleton />
                                <FileCardSkeleton />
                                <FileCardSkeleton />
                            </>
                        ) : (
                            files.map(file => <File key={file.id} file={file} />)
                        )}
                    </div>
                </div>
            )}
        </div>
    );
}
