import HomeCreatePost from "@/components/features/home/HomeCreatePost";
import HomeCalendarSchedule from "@/components/features/home/layout/HomeCalendarSchedule";
import HomeHeader from "@/components/features/home/layout/HomeHeader";
import PostList from "@/components/features/post/PostList";
import { useMediaQuery } from "@/hooks/useMediaQuery";
import { useCredentials } from "@/store/authStore";

export default function HomePage() {
    const isLaptop = useMediaQuery("lg");
    const { lvl } = useCredentials();
    const isAdmin = lvl === 2;
    return (
        <div className={"base-direction-row"}>
            <div className={"flex flex-1 flex-col gap-4"}>
                <HomeHeader />
                <div className={"flex w-full flex-1 flex-col items-center gap-5"}>
                    {isAdmin ? <HomeCreatePost /> : null}
                    <PostList />
                </div>
            </div>
            {isLaptop ? <HomeCalendarSchedule /> : null}
        </div>
    );
}
