import { axiosInstance } from "@/api/api";
import { UserAddSchemaType } from "@/schemas/user.schema";
import { UserEditType, UserEditSchemaType } from "@/schemas/user.schema";
import type { PaginationWithNameI } from "@/types";
import type { UserPublic, UserPrivate } from "@/types";
import type { GroupPrivate } from "@/types";

export interface UserAvatarUploadParams {
    b64: Blob;
    authToken?: string;
}

export const getManagedUsers = async (): Promise<UserPrivate[]> => {
    return await axiosInstance.get("/api/user/getManaged");
};

export const getUsers = async ({
    page,
    pageSize,
    name,
}: PaginationWithNameI): Promise<UserPublic[]> => {
    return await axiosInstance.get("/api/user/getAll", {
        params: { name, page, pageSize },
    });
};

export const getUser = async (id?: number): Promise<UserPrivate> => {
    return await axiosInstance.get("/api/user", { params: { id } });
};

export const getUserGroup = async (id?: number): Promise<GroupPrivate[]> => {
    return await axiosInstance.get("/api/user/getGroups", { params: { id } });
};

export const postUser = async (data: UserAddSchemaType): Promise<UserPrivate> => {
    return await axiosInstance.post("/api/user", data);
};

export const editUser = async (data: UserEditSchemaType | UserEditType): Promise<UserPrivate> => {
    return await axiosInstance.put("/api/user", data);
};

export const deleteUser = async (id: number): Promise<string> => {
    return await axiosInstance.delete("/api/user", { data: { id: id } });
};

export const deleteUserAvatar = async (data: { authToken?: string }): Promise<string> => {
    return await axiosInstance.delete("/api/user/avatar", { data: data });
};

export const postUserAvatar = async ({
    b64,
    authToken,
}: UserAvatarUploadParams): Promise<{ avatarURL: string }> => {
    const formData = new FormData();
    formData.set("avatar", b64);
    if (authToken) {
        formData.set("authToken", authToken);
    }
    return await axiosInstance.post("/api/user/avatarUpload", formData);
};
