import { httpErrorHandler } from "@/api/api";
import { useEditConversationMutation } from "@/api/queries/chatQueries";
import { Button } from "@/components/ui/button/Button";
import {
    Dialog,
    DialogBody,
    DialogContent,
    DialogFooter,
    DialogHeader,
    DialogTitle,
    DialogDescription,
} from "@/components/ui/dialog/Dialog";
import {
    Form,
    FormControl,
    FormField,
    FormItem,
    FormMessage,
    FormLabel,
} from "@/components/ui/form/Form";
import { Input } from "@/components/ui/input/Input";
import { ChatEditSchema, ChatEditSchemaType } from "@/schemas/chat.schema";
import { yupResolver } from "@hookform/resolvers/yup";
import { Dispatch, SetStateAction } from "react";
import { useForm } from "react-hook-form";
import { useLocation } from "react-router";
import { useNavigate } from "react-router-dom";
import { CheckCircle2, LoaderCircle } from "lucide-react";
import { toast } from "sonner";

interface ChatConversationEditNameFormProps {
    id: number;
    name: string;
    open: boolean;
    onOpenChange: Dispatch<SetStateAction<boolean>>;
}

export default function ChatEditName({
    id,
    name,
    open,
    onOpenChange,
}: ChatConversationEditNameFormProps) {
    const navigate = useNavigate();
    const location = useLocation();
    const { mutateAsync, isPending } = useEditConversationMutation();

    const form = useForm<ChatEditSchemaType>({
        values: {
            id: id,
            name: name,
        },
        mode: "onBlur",
        resolver: yupResolver(ChatEditSchema),
    });

    const onClose = () => {
        onOpenChange(false);
        form.reset();
    };

    const onSubmit = (data: ChatEditSchemaType) => {
        mutateAsync(data)
            .then(response => {
                navigate(".", {
                    replace: true,
                    state: { ...location.state, name: response.name },
                });
                onClose();
            })
            .catch(error => {
                const { title, detail } = httpErrorHandler(error);
                toast.error(title, {
                    description: detail,
                });
            });
    };

    return (
        <Dialog open={open} onOpenChange={onOpenChange}>
            <DialogContent
                className={"sm:max-w-96"}
                onCloseAutoFocus={onClose}
                onEscapeKeyDown={onClose}
            >
                <Form {...form}>
                    <DialogBody>
                        {isPending && (
                            <div
                                className={
                                    "absolute inset-0 z-60 m-auto flex flex-wrap items-center justify-center gap-3 bg-surface-primary/75 backdrop-blur-md"
                                }
                            >
                                <LoaderCircle className={"animate-spin"} aria-hidden={"true"} />
                                <h5 className={"font-medium"}>Trwa zmiana nazwy...</h5>
                            </div>
                        )}
                        <DialogHeader className={"border-b border-border-primary py-5"}>
                            <DialogTitle>Edytuj nazwę</DialogTitle>
                        </DialogHeader>
                        <DialogDescription className={"sr-only"}>
                            Formularz zmiany nazwy chatu
                        </DialogDescription>
                        <form
                            className={"flex w-full flex-col gap-3 py-3 px-3 md:px-4"}
                            onSubmit={form.handleSubmit(onSubmit)}
                            noValidate
                        >
                            <FormField
                                name={"name"}
                                control={form.control}
                                render={({ field }) => (
                                    <FormItem>
                                        <FormLabel>Nazwa</FormLabel>
                                        <FormControl>
                                            <Input {...field} placeholder={"Nazwa konwersacji"} />
                                        </FormControl>
                                        <FormMessage />
                                    </FormItem>
                                )}
                            />
                        </form>
                    </DialogBody>
                    <DialogFooter className={"border-t border-border-primary"}>
                        <Button
                            onClick={onClose}
                            type={"button"}
                            variant={"tertiary"}
                            className={"w-full md:w-fit"}
                        >
                            Anuluj
                        </Button>
                        <Button
                            disabled={isPending}
                            onClick={form.handleSubmit(onSubmit)}
                            type={"submit"}
                            variant={"primary"}
                            className={"w-full md:w-fit"}
                        >
                            {isPending ? (
                                <>
                                    <LoaderCircle className={"animate-spin"} aria-hidden={"true"} />
                                    Zapisywanie...
                                </>
                            ) : (
                                <>
                                    <CheckCircle2 />
                                    Zapisz
                                </>
                            )}
                        </Button>
                    </DialogFooter>
                </Form>
            </DialogContent>
        </Dialog>
    );
}
