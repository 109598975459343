import { cn } from "@/lib/utils";
import { forwardRef, HTMLAttributes } from "react";
import Circles from "@/assets/images/circles.svg?react";

const EmptyStateCircles = forwardRef<SVGSVGElement, HTMLAttributes<SVGSVGElement>>(
    ({ className, ...props }, ref) => {
        return (
            <Circles
                ref={ref}
                className={cn(
                    "aspect-square max-w-54 md:max-w-66 w-fit absolute left-2/4 -z-1 flex -translate-x-2/4 top-0 opacity-50",
                    className,
                )}
                {...props}
            />
        );
    },
);
EmptyStateCircles.displayName = "EmptyStateCircles";

const EmptyState = forwardRef<HTMLDivElement, HTMLAttributes<HTMLDivElement>>(
    ({ className, ...props }, ref) => {
        return (
            <div
                ref={ref}
                className={cn(
                    "relative flex flex-col items-center gap-4 has-[svg]:pt-24 md:has-[svg]:pt-32",
                    className,
                )}
                {...props}
            />
        );
    },
);
EmptyState.displayName = "EmptyState";

const EmptyStateContent = forwardRef<HTMLDivElement, HTMLAttributes<HTMLDivElement>>(
    ({ className, ...props }, ref) => {
        return (
            <div ref={ref} className={cn("z-10 flex flex-col p-4 gap-1", className)} {...props} />
        );
    },
);
EmptyStateContent.displayName = "EmptyStateContent";

const EmptyStateFooter = forwardRef<HTMLDivElement, HTMLAttributes<HTMLDivElement>>(
    ({ className, ...props }, ref) => {
        return (
            <div
                ref={ref}
                className={cn("flex gap-3 pt-5 justify-center w-full", className)}
                {...props}
            />
        );
    },
);
EmptyStateFooter.displayName = "EmptyStateFooter";

const EmptyStateTitle = forwardRef<HTMLHeadingElement, HTMLAttributes<HTMLHeadingElement>>(
    ({ className, ...props }, ref) => {
        return (
            <h2
                ref={ref}
                className={cn(
                    "text-text-primary text-center text-xl md:text-2xl font-semibold",
                    className,
                )}
                {...props}
            />
        );
    },
);
EmptyStateTitle.displayName = "EmptyStateTitle";

const EmptyStateDescription = forwardRef<
    HTMLParagraphElement,
    HTMLAttributes<HTMLParagraphElement>
>(({ className, ...props }, ref) => {
    return (
        <p
            ref={ref}
            className={cn("text-text-tertiary text-center text-xs md:text-sm", className)}
            {...props}
        />
    );
});
EmptyStateDescription.displayName = "EmptyStateDescription";

export {
    EmptyState,
    EmptyStateCircles,
    EmptyStateContent,
    EmptyStateFooter,
    EmptyStateTitle,
    EmptyStateDescription,
};
