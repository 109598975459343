import { httpErrorHandler } from "@/api/api";
import { useEditDirectoriesMutation, useGetDirectoryQuery } from "@/api/queries/directoryQueries";
import { useEditFilesMutation } from "@/api/queries/filesQueries";
import FSPath from "@/components/features/files/FsPath";
import { Badge } from "@/components/ui/badge/Badge";
import { Button } from "@/components/ui/button/Button";
import {
    AlertDialog,
    AlertDialogBody,
    AlertDialogContent,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogTitle,
    AlertDialogAction,
    AlertDialogCancel,
} from "@/components/ui/dialog/AlertDialog";
import {
    Dialog,
    DialogTitle,
    DialogBody,
    DialogContent,
    DialogFooter,
    DialogHeader,
    DialogDescription,
} from "@/components/ui/dialog/Dialog";
import { FeaturedIcon } from "@/components/ui/featured-icon/FeaturedIcon";
import { cn } from "@/lib/utils";
import { useCredentials } from "@/store/authStore";
import { useFilesStorage } from "@/store/filesStore";
import {
    ChevronRight,
    FolderInput,
    FolderKey,
    FolderLock,
    HardDrive,
    LoaderCircle,
    CheckCircle2,
    Folders,
} from "lucide-react";
import { ReactElement, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router";
import { Skeleton } from "@/components/ui/skeleton/Skeleton";
import { List, listElementVariants, ListItem, ListItemTitle } from "@/components/ui/list/List";
import { toast } from "sonner";
import type { DirectoryElements, DirectoryPublic } from "@/types";

interface FsEntryElement {
    dir: DirectoryPublic;
    isSelected: boolean;
    isWritable: boolean;
}

interface MoveFSEntryProps {
    elements: DirectoryElements;
    open: boolean;
    onOpenChange: (open: boolean) => void;
    callback?: () => void;
    directoryId?: string;
}
export default function MoveFSEntry({
    elements,
    open,
    onOpenChange,
    callback,
    directoryId,
}: MoveFSEntryProps) {
    const { id } = useParams();
    const { model } = useFilesStorage();

    const { directoryId: defaultDirectoryId } = useCredentials();
    const pageDirId = useMemo(() => {
        return id ? id : defaultDirectoryId;
    }, [id, defaultDirectoryId]);

    const [openAlertDialog, setOpenAlertDialog] = useState<boolean>(false);
    const [currentDirectoryId, setCurrentDirectoryId] = useState<string>(pageDirId);
    const [selectedDir, setSelectedDir] = useState<DirectoryPublic | null>(null);

    const {
        data: directory,
        isLoading,
        isSuccess,
        isError,
    } = useGetDirectoryQuery(currentDirectoryId, model === "my_drive");

    const { executeMutations: filesMutations, isPending: filesMutationIsPending } =
        useEditFilesMutation(directoryId);
    const { executeMutations: directoriesMutations, isPending: directoriesMutationIsPending } =
        useEditDirectoriesMutation(directoryId);

    const isPending = useMemo(
        () => filesMutationIsPending || directoriesMutationIsPending,
        [directoriesMutationIsPending, filesMutationIsPending],
    );

    const directories = useMemo(() => {
        if (!isSuccess) {
            return [];
        }
        return directory.childDirs.map(dir => {
            if (elements.directories.includes(dir)) {
                return {
                    dir: dir,
                    isSelected: true,
                    isWritable: dir.permissions.write,
                } as FsEntryElement;
            }
            return {
                dir: dir,
                isSelected: false,
                isWritable: dir.permissions.write,
            } as FsEntryElement;
        });
    }, [directory, elements.directories, isSuccess]);

    const isNoDeeper = directories.length === 0;

    const onChangeSelect = (dir: DirectoryPublic) => {
        if (dir.id === selectedDir?.id) {
            setSelectedDir(null);
        } else {
            setSelectedDir(dir);
        }
    };

    const onRouteChange = (id: string) => {
        setCurrentDirectoryId(id);
        setSelectedDir(null);
    };

    const onAlertDialogOpen = () => {
        setOpenAlertDialog(true);
    };

    const onSubmit = async () => {
        if (selectedDir === null) {
            return;
        }
        const { files, directories } = { ...elements };

        await filesMutations(
            files.map(file => ({ id: file.id, directoryId: selectedDir.id })),
        ).catch(error => {
            const { title, detail } = httpErrorHandler(error);
            toast.error(title, {
                description: detail,
            });
        });
        await directoriesMutations(
            directories.map(directory => ({
                id: directory.id,
                parentDirId: selectedDir.id,
            })),
        ).catch(error => {
            const { title, detail } = httpErrorHandler(error);
            toast.error(title, {
                description: detail,
            });
        });

        onClose();
    };

    const onClose = () => {
        onOpenChange(false);
        setOpenAlertDialog(false);
        setSelectedDir(null);
        setCurrentDirectoryId(pageDirId);
        callback?.();
    };

    useEffect(() => {
        if (directory) {
            setSelectedDir(directory);
        }
    }, [directory]);

    return (
        <>
            <AlertDialog open={openAlertDialog} onOpenChange={setOpenAlertDialog}>
                <AlertDialogContent className={"sm:max-w-96"}>
                    <AlertDialogBody>
                        <AlertDialogHeader>
                            <FeaturedIcon variant={"ghost"}>
                                <Folders />
                            </FeaturedIcon>
                            <div className={"flex flex-col gap-0.5 min-w-0 grow"}>
                                <AlertDialogTitle>Przenieś element/y ?</AlertDialogTitle>
                                <p className={"text-text-tertiary text-xs"}>
                                    Ten element będzie widoczny dla wszystkich użytkowników, którzy
                                    mogą wyświetlać folder „{selectedDir?.name}”
                                </p>
                            </div>
                        </AlertDialogHeader>
                    </AlertDialogBody>
                    <AlertDialogFooter>
                        <AlertDialogCancel variant={"tertiary"} onClick={onClose}>
                            Zamknij
                        </AlertDialogCancel>
                        <AlertDialogAction onClick={onSubmit}>Potwierdź</AlertDialogAction>
                    </AlertDialogFooter>
                </AlertDialogContent>
            </AlertDialog>

            <Dialog open={open} onOpenChange={onOpenChange}>
                <DialogContent
                    className={"sm:max-w-148"}
                    onCloseAutoFocus={onClose}
                    onEscapeKeyDown={onClose}
                >
                    <DialogDescription className={"sr-only"}>
                        Formularz do przenoszenia plików
                    </DialogDescription>
                    <DialogBody>
                        <DialogHeader className={"border-b border-border-primary"}>
                            <DialogTitle>Przenieś</DialogTitle>
                        </DialogHeader>
                        <div className={"flex flex-col gap-6 p-3 md:p-4 overflow-auto grow"}>
                            <div className={"flex flex-col gap-4"}>
                                <Button
                                    size={"sm"}
                                    onClick={() => onRouteChange(pageDirId)}
                                    variant={"outline"}
                                    className={"w-fit"}
                                >
                                    <HardDrive />
                                    Aktualna lokacja
                                </Button>
                                <FSPath
                                    fsEntry={directory}
                                    isLoading={isLoading}
                                    isError={isError}
                                    onRouteChange={onRouteChange}
                                />
                            </div>
                            <List>
                                {isNoDeeper && (
                                    <li>
                                        <p className={"text-sm italic text-text-tertiary"}>
                                            Brak folderów
                                        </p>
                                    </li>
                                )}
                                {directories.map(element => (
                                    <MoveFsEntryElement
                                        key={element.dir.id}
                                        isCurrentSelected={selectedDir?.id === element.dir.id}
                                        element={element}
                                        onOpenSummarize={onAlertDialogOpen}
                                        onRouteChange={onRouteChange}
                                        selectDirectory={onChangeSelect}
                                    />
                                ))}
                                {isLoading && (
                                    <>
                                        <MoveFsEntryElementSkeleton />
                                        <MoveFsEntryElementSkeleton />
                                        <MoveFsEntryElementSkeleton />
                                    </>
                                )}
                            </List>
                        </div>
                    </DialogBody>
                    <DialogFooter className={"border-t border-border-primary"}>
                        <Button
                            onClick={onClose}
                            type={"button"}
                            variant={"tertiary"}
                            className={"w-full md:w-fit"}
                        >
                            Anuluj
                        </Button>
                        <Button
                            disabled={isPending || !selectedDir}
                            onClick={onAlertDialogOpen}
                            type={"submit"}
                            variant={"primary"}
                            className={"w-full md:w-fit"}
                        >
                            {isPending ? (
                                <>
                                    <LoaderCircle className={"animate-spin"} aria-hidden={"true"} />
                                    Zapisywanie...
                                </>
                            ) : (
                                <>
                                    <CheckCircle2 />
                                    Zapisz
                                </>
                            )}
                        </Button>
                    </DialogFooter>
                </DialogContent>
            </Dialog>
        </>
    );
}

interface MoveFsEntryElementProps {
    element: FsEntryElement;
    isCurrentSelected: boolean;
    onOpenSummarize: () => void;
    selectDirectory: (dir: DirectoryPublic) => void;
    onRouteChange: (id: string) => void;
}

function MoveFsEntryElement({
    element,
    onOpenSummarize,
    selectDirectory,
    onRouteChange,
    isCurrentSelected,
}: MoveFsEntryElementProps) {
    const { dir, isSelected, isWritable } = element;

    const isDisabled = useMemo(() => isSelected || !isWritable, [isWritable, isSelected]);
    const { icon, menuButton } = useMemo(() => {
        let icon: ReactElement | undefined = undefined;
        let menuButton: ReactElement | undefined = undefined;

        if (isSelected) {
            icon = <FolderKey className={"size-4 text-text-muted"} />;
            menuButton = (
                <Badge size={"sm"} border variant={"muted"}>
                    Folder Przenoszony
                </Badge>
            );
        } else if (!isWritable) {
            icon = <FolderLock className={"size-4 text-text-muted"} />;
            menuButton = (
                <Badge variant={"destructive"} size={"sm"}>
                    Tylko do odczytu
                </Badge>
            );
        } else {
            icon = <FolderInput className={"size-4 text-text-muted"} />;
            menuButton = (
                <div className="flex shrink-0">
                    <Button
                        onClick={() => {
                            selectDirectory(dir);
                            onOpenSummarize();
                        }}
                        size={"sm"}
                        variant={"tertiary"}
                    >
                        Przenieś
                    </Button>
                    <Button
                        type={"button"}
                        onClick={e => {
                            e.stopPropagation();
                            onRouteChange(dir.id);
                        }}
                        size={"sm"}
                        variant={"ghost"}
                    >
                        <ChevronRight />
                    </Button>
                </div>
            );
        }
        return { icon, menuButton };
    }, [dir, isSelected, isWritable, onOpenSummarize, onRouteChange, selectDirectory]);

    return (
        <ListItem
            onClick={() => {
                if (isDisabled) {
                    return;
                }
                selectDirectory(dir);
            }}
            className={cn(
                isDisabled && "cursor-not-allowed opacity-50",
                isSelected && "opacity-75",
                isCurrentSelected && "bg-fill-primary-hover",
            )}
        >
            {icon}
            <ListItemTitle className={"mr-auto grow"}>{dir.name}</ListItemTitle>
            {menuButton}
        </ListItem>
    );
}

function MoveFsEntryElementSkeleton() {
    return (
        <li className={cn(listElementVariants())}>
            <Skeleton className={"size-4 rounded-sm"} />
            <Skeleton className={"w-20 h-3 rounded-sm"} />
        </li>
    );
}
