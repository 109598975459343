import {
    getSignup,
    postLogin,
    postLogout,
    postPasswordChange,
    postPasswordReset,
    postSignup,
} from "@/api/endpoints/auth";
import {
    LoginSchemaType,
    PasswordChangeSchemaType,
    PasswordResetSchemaType,
    SignupSchemaType,
} from "@/schemas/auth.schema";
import { useMutation, useQuery } from "@tanstack/react-query";

export const usePostLoginMutation = () => {
    return useMutation({
        mutationFn: (data: LoginSchemaType) => postLogin(data),
    });
};

export const usePostLogoutMutation = () => {
    return useMutation({
        mutationFn: () => postLogout(),
    });
};

export const useGetSignupQuery = (token?: string) => {
    return useQuery({
        queryKey: ["signup", token],
        queryFn: () => getSignup(token),
    });
};

export const usePostSignupMutation = () => {
    return useMutation({
        mutationFn: (data: SignupSchemaType) => postSignup(data),
    });
};

export const usePostPasswordChangeMutation = () => {
    return useMutation({
        mutationFn: (data: PasswordChangeSchemaType) => postPasswordChange(data),
    });
};

export const usePostPasswordResetMutation = () => {
    return useMutation({
        mutationFn: (data: PasswordResetSchemaType) => postPasswordReset(data),
    });
};
