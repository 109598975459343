import { ChevronsUpDown, LogOut, Settings, User } from "lucide-react";

import { Avatar, AvatarFallback, AvatarImage } from "@/components/ui/avatar/Avatar";
import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuGroup,
    DropdownMenuItem,
    DropdownMenuLabel,
    DropdownMenuSeparator,
    DropdownMenuTrigger,
} from "@/components/ui/input/dropdown-menu/DropdownMenu";
import {
    SidebarMenu,
    SidebarMenuButton,
    SidebarMenuItem,
    useSidebar,
} from "@/components/ui/sidebar/Sidebar";
import { useCredentials, useRemoveCredentials } from "@/store/authStore";
import { useLvl } from "@/hooks/useLvl";
import { useNavigate, NavLink } from "react-router-dom";
import { usePostLogoutMutation } from "@/api/queries/authQueries";
import { getAvatar } from "@/utils/getAvatar";

export function NavUser() {
    const { name, surname, lvl, avatarURL } = useCredentials();
    const userRole = useLvl(lvl);
    const { isMobile } = useSidebar();
    const navigate = useNavigate();
    const removeCredentials = useRemoveCredentials();
    const { mutateAsync } = usePostLogoutMutation();

    const logout = async () => {
        navigate("/login");
        await mutateAsync();
        removeCredentials();
    };

    return (
        <SidebarMenu>
            <SidebarMenuItem className={"overflow-hidden"}>
                <DropdownMenu>
                    <DropdownMenuTrigger asChild>
                        <SidebarMenuButton
                            size="lg"
                            className="data-[state=open]:bg-fill-primary-hover rounded-t-none rounded-b-lg h-full data-[state=open]:text-text-primary group-data-[collapsible=icon]:!px-3.5 group-data-[collapsible=icon]:!w-full group-data-[collapsible=icon]:!h-full"
                        >
                            <Avatar size={"lg"}>
                                <AvatarImage src={getAvatar(avatarURL)} alt={name} />
                                <AvatarFallback>
                                    <User />
                                </AvatarFallback>
                            </Avatar>
                            <div className="grid flex-1 text-left text-sm leading-tight">
                                <span className="truncate text-text-primary font-semibold">
                                    {name} {surname}
                                </span>
                                <span className="truncate text-text-tertiary text-xs">
                                    {userRole}
                                </span>
                            </div>
                            <ChevronsUpDown className="ml-auto size-4" />
                        </SidebarMenuButton>
                    </DropdownMenuTrigger>
                    <DropdownMenuContent
                        className="w-[--radix-dropdown-menu-trigger-width] min-w-56"
                        side={isMobile ? "bottom" : "right"}
                        align="end"
                        sideOffset={4}
                    >
                        <DropdownMenuLabel className="flex items-center gap-2 p-3">
                            <Avatar size={"md"}>
                                <AvatarImage src={getAvatar(avatarURL)} alt={name} />
                                <AvatarFallback>
                                    <User />
                                </AvatarFallback>
                            </Avatar>
                            <div className="grid flex-1 text-left text-sm leading-tight">
                                <span className="truncate text-text-primary font-semibold">
                                    {name} {surname}
                                </span>
                                <span className="truncate text-text-tertiary  text-xs">
                                    {userRole}
                                </span>
                            </div>
                        </DropdownMenuLabel>
                        <DropdownMenuSeparator />
                        <DropdownMenuGroup>
                            <DropdownMenuItem asChild>
                                <NavLink to="/settings">
                                    <Settings />
                                    Ustawienia
                                </NavLink>
                            </DropdownMenuItem>
                            <DropdownMenuItem asChild onClick={logout}>
                                <NavLink to="#">
                                    <LogOut />
                                    Wyloguj
                                </NavLink>
                            </DropdownMenuItem>
                        </DropdownMenuGroup>
                    </DropdownMenuContent>
                </DropdownMenu>
            </SidebarMenuItem>
        </SidebarMenu>
    );
}
