import {
    AlertDialog,
    AlertDialogAction,
    AlertDialogBody,
    AlertDialogCancel,
    AlertDialogContent,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogTitle,
} from "@/components/ui/dialog/AlertDialog";
import { FeaturedIcon } from "@/components/ui/featured-icon/FeaturedIcon";
import { useLocalStorage } from "@/hooks/useLocalStorage";
import { useManageNotifications } from "@/hooks/useNotifications";
import { BellRing } from "lucide-react";
import { useEffect, useState } from "react";

export default function NotificationPermissionAlert() {
    const [showAlert, setShowAlert] = useState<boolean>(false);

    const { isDefault, enableNotifications, disableNotifications } = useManageNotifications();
    const [hasPrompted, setHasPrompted] = useLocalStorage<boolean>("notificationPrompted", false);

    const onAllow = () => {
        enableNotifications();
        setHasPrompted(true);
        setShowAlert(false);
    };
    const onDeny = () => {
        disableNotifications();
        setHasPrompted(true);
        setShowAlert(false);
    };

    useEffect(() => {
        if (isDefault && !hasPrompted) {
            setShowAlert(true);
        }
        setHasPrompted(true);
    }, [isDefault, hasPrompted]);

    return (
        <AlertDialog open={showAlert} onOpenChange={setShowAlert}>
            <AlertDialogContent className={"sm:max-w-110"}>
                <AlertDialogBody>
                    <AlertDialogHeader>
                        <FeaturedIcon variant={"ghost"}>
                            <BellRing />
                        </FeaturedIcon>
                        <div className={"flex flex-col gap-0.5 min-w-0 grow"}>
                            <AlertDialogTitle>Chcesz otrzymywać powiadomienia ?</AlertDialogTitle>
                            <p className={"text-text-tertiary text-xs"}>
                                Wyraź zgodę na powiadomienia, aby otrzymywać najnowsze informacje.
                                Możesz w każdej chwili zmienić swoje preferencje w ustawieniach
                                aplikacji.
                            </p>
                        </div>
                    </AlertDialogHeader>
                </AlertDialogBody>
                <AlertDialogFooter>
                    <AlertDialogCancel variant={"tertiary"} onClick={onDeny}>
                        Zamknij
                    </AlertDialogCancel>
                    <AlertDialogAction onClick={onAllow}>Potwierdź</AlertDialogAction>
                </AlertDialogFooter>
            </AlertDialogContent>
        </AlertDialog>
    );
}
