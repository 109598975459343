import { forwardRef, HTMLAttributes, useMemo } from "react";
import { Button } from "@/components/ui/button/Button";
import {
    FileCard,
    FileCardContent,
    FileCardPreview,
    FileCardPreviewFallback,
    FileCardPreviewImage,
    FileCardTitle,
} from "@/components/ui/file-card/FileCard";
import { DropdownMenuTrigger } from "@/components/ui/input/dropdown-menu/DropdownMenu";
import { cn } from "@/lib/utils";
import { MoreVertical } from "lucide-react";
import { FileIcon } from "@/components/ui/file-icon/FileIcon";
import { FeaturedIcon } from "@/components/ui/featured-icon/FeaturedIcon";
import { geFileExtension } from "@/utils/files";
import { getBase64 } from "@/utils/getBase64";
import { Skeleton } from "@/components/ui/skeleton/Skeleton";
import type { FilePublic } from "@/types";

type FsFileCardProps = HTMLAttributes<HTMLDivElement> & {
    file: FilePublic;
    disabledMenu?: boolean;
};

const FsFileCard = forwardRef<HTMLDivElement, FsFileCardProps>(
    ({ file, className, disabledMenu = false, ...props }, ref) => {
        const thumbnail = useMemo(() => {
            if (!file.thumbnail) return null;
            return getBase64({
                fileType: file.fileType,
                base64: file.thumbnail,
                clear: true,
            });
        }, [file.fileType, file.thumbnail]);

        return (
            <FileCard
                ref={ref}
                key={file.id}
                className={cn(className, "cursor-pointer")}
                {...props}
            >
                <FileCardContent>
                    <FileIcon fileExtension={geFileExtension(file.name)} />
                    <FileCardTitle>{file.name}</FileCardTitle>
                    <DropdownMenuTrigger asChild disabled={disabledMenu}>
                        <Button variant={"ghost"} size={"sm"}>
                            <MoreVertical />
                        </Button>
                    </DropdownMenuTrigger>
                </FileCardContent>
                <FileCardPreview>
                    <FileCardPreviewImage className={"opacity-85"} src={thumbnail} />
                    <FileCardPreviewFallback>
                        <FeaturedIcon>
                            <FileIcon />
                        </FeaturedIcon>
                    </FileCardPreviewFallback>
                </FileCardPreview>
            </FileCard>
        );
    },
);
FsFileCard.displayName = "FsFileCard";

function FileCardSkeleton() {
    return (
        <div
            className={
                "flex flex-col w-full items-center gap-2 rounded-md border border-border-primary bg-surface-primary p-2"
            }
        >
            <div className="inline-flex w-full items-center gap-2">
                <Skeleton className={"h-10 w-10 shrink-0 rounded-sm"} />
                <div className={"w-full"}>
                    <Skeleton className={"h-3 w-14 rounded-md"} />
                </div>
            </div>
            <div className="border border-border-primary rounded-sm h-24 w-full" />
        </div>
    );
}

FileCardSkeleton.displayName = "FileCardSkeleton";

export { FsFileCard, FileCardSkeleton };
