import { getNotifications, readNotifications } from "@/api/endpoints/notifications";
import { getUnreadNotificationsCount, readAllNotifications } from "@/api/endpoints/notifications";
import { queryClient } from "@/api/query-client";
import {
    NotificationPublic,
    NotificationType,
    ReadNotificationsType,
    UnreadNotificationsCount,
} from "@/types";
import { isEmptyArray } from "@/utils/assertion";
import { InfiniteData, useInfiniteQuery, useMutation, useQuery } from "@tanstack/react-query";
import { useEffect } from "react";

const NOTIFICATIONS_KEY = ["notificationsInfinite"] as const;
const UNREAD_COUNT_KEY = ["unreadNotificationsCount"] as const;

export const useGetNotificationsInfiniteQuery = () => {
    useEffect(() => {
        const eventSource = new EventSource(
            import.meta.env.VITE_API_DOMAIN + "/api/notification/sse",
            {
                withCredentials: true,
            },
        );
        eventSource.onmessage = (event: MessageEvent) => {
            try {
                const data: NotificationPublic[] = JSON.parse(event.data);
                const sseDataLen = data.length;
                if (sseDataLen === 0) {
                    return;
                }
                queryClient.invalidateQueries({ queryKey: NOTIFICATIONS_KEY });
                queryClient.invalidateQueries({ queryKey: UNREAD_COUNT_KEY });
            } catch {
                console.error("Failed to parse event data");
            }
        };

        return () => eventSource.close();
    }, []);

    return useInfiniteQuery({
        queryKey: NOTIFICATIONS_KEY,
        queryFn: ({ pageParam }) => getNotifications(pageParam),
        initialPageParam: { page: 0, pageSize: 10 },
        getNextPageParam: (lastPage, allPages, lastPageParam) => {
            if (lastPage.length < lastPageParam.pageSize) {
                return undefined;
            }
            return { ...lastPageParam, page: lastPageParam.page + 1 };
        },
    });
};

export const useGetUnreadNotificationsCountQuery = (type?: NotificationType, enabled = true) => {
    return useQuery({
        queryKey: UNREAD_COUNT_KEY,
        enabled: enabled,
        select: (data: UnreadNotificationsCount[]) => {
            if (type === "all") {
                if (isEmptyArray(data)) {
                    return 0;
                }
                const res = data
                    .map(item => item.count)
                    .reduce((acc, curr) => (parseInt(acc) + parseInt(curr)).toString());
                if (!res) {
                    return 0;
                }
                return parseInt(res);
            }
            const res = data.find(item => item.type === type);
            if (!res) {
                return 0;
            }
            return parseInt(res.count);
        },
        initialData: (): UnreadNotificationsCount[] => {
            return [];
        },
        queryFn: async () => getUnreadNotificationsCount(),
    });
};

export const usePostReadNotificationsMutation = () => {
    return useMutation({
        mutationKey: ["postReadNotifications"],
        mutationFn: (data: ReadNotificationsType) => readNotifications(data),
        onMutate: async data => {
            await Promise.all([
                queryClient.cancelQueries({ queryKey: NOTIFICATIONS_KEY }),
                queryClient.cancelQueries({ queryKey: UNREAD_COUNT_KEY }),
            ]);

            const notificationsData =
                queryClient.getQueryData<InfiniteData<NotificationPublic[]>>(NOTIFICATIONS_KEY);
            const countNotificationsData =
                queryClient.getQueryData<UnreadNotificationsCount[]>(UNREAD_COUNT_KEY);

            if (!notificationsData?.pages) return { notificationsData, countNotificationsData };

            const updatedPages = notificationsData.pages.map(page =>
                page.map(notification => {
                    if (!data.arr.includes(notification.id)) return notification;

                    if (countNotificationsData) {
                        const typeCount = countNotificationsData.find(
                            c => c.type === notification.type,
                        );
                        if (typeCount) {
                            typeCount.count = Math.max(0, parseInt(typeCount.count) - 1).toString();
                        }
                    }

                    return { ...notification, read: true };
                }),
            );
            queryClient.setQueryData(NOTIFICATIONS_KEY, {
                ...notificationsData,
                pages: updatedPages,
            });

            if (countNotificationsData) {
                queryClient.setQueryData(UNREAD_COUNT_KEY, countNotificationsData);
            }

            return { notificationsData, countNotificationsData };
        },
        onError: (_, __, context) => {
            if (context?.notificationsData) {
                queryClient.setQueryData(NOTIFICATIONS_KEY, context.notificationsData);
            }
            if (context?.countNotificationsData) {
                queryClient.setQueryData(UNREAD_COUNT_KEY, context.countNotificationsData);
            }
        },
        onSettled: () => {
            return Promise.all([
                queryClient.invalidateQueries({ queryKey: NOTIFICATIONS_KEY }),
                queryClient.invalidateQueries({ queryKey: UNREAD_COUNT_KEY }),
            ]);
        },
    });
};

export const usePostReadAllNotificationsMutation = () => {
    return useMutation({
        mutationKey: ["postReadAllNotifications"],
        mutationFn: () => readAllNotifications(),
        onMutate: async () => {
            await Promise.all([
                queryClient.cancelQueries({ queryKey: UNREAD_COUNT_KEY }),
                queryClient.cancelQueries({ queryKey: NOTIFICATIONS_KEY }),
            ]);

            queryClient.setQueryData<UnreadNotificationsCount[] | undefined>(
                UNREAD_COUNT_KEY,
                (curr: UnreadNotificationsCount[] | undefined) => {
                    if (!curr) {
                        return;
                    }
                    return [];
                },
            );

            queryClient.setQueryData<InfiniteData<NotificationPublic[], unknown> | undefined>(
                NOTIFICATIONS_KEY,
                (curr: InfiniteData<NotificationPublic[], unknown> | undefined) => {
                    if (!curr) {
                        return;
                    }
                    return {
                        ...curr,
                        pages: curr.pages.map(page => {
                            return page.map(n => {
                                return { ...n, read: true };
                            });
                        }),
                    };
                },
            );
        },
        onSettled: () => {
            return Promise.all([
                queryClient.invalidateQueries({ queryKey: NOTIFICATIONS_KEY }),
                queryClient.invalidateQueries({ queryKey: UNREAD_COUNT_KEY }),
            ]);
        },
    });
};
