"use no memo";

import { TanstackTableFacetedFilters } from "./TanstackTableFacetedFilters";
import TanstackTableActions from "@/components/features/manage/tanstack-table/TanstackTableActions";
import TanstackTableLoading from "@/components/features/manage/tanstack-table/TanstackTableLoading";
import TanstackTableRows from "@/components/features/manage/tanstack-table/TanstackTableRows";
import TanstackTableSearch from "@/components/features/manage/tanstack-table/TanstackTableSearch";
import {
    TanstackTableViewOptions,
    TanstackTableViewOptionsContent,
    TanstackTableViewOptionsContentFilter,
    TanstackTableViewOptionsContentSort,
    TanstackTableViewOptionsContentVisibility,
    TanstackTableViewOptionsTrigger,
} from "@/components/features/manage/tanstack-table/TanstackTableViewOptions";
import { Button } from "@/components/ui/button/Button";
import {
    DropdownMenu,
    DropdownMenuCheckboxItem,
    DropdownMenuContent,
    DropdownMenuGroup,
    DropdownMenuLabel,
} from "@/components/ui/input/dropdown-menu/DropdownMenu";
import { Separator } from "@/components/ui/separator/Separator";
import { Table, TableBody, TableHead, TableHeader, TableRow } from "@/components/ui/table/Table";
import { useLocalStorage } from "@/hooks/useLocalStorage";
import { useMediaQuery } from "@/hooks/useMediaQuery";
import { cn } from "@/lib/utils";
import { DropdownMenuTrigger } from "@radix-ui/react-dropdown-menu";
import {
    ColumnDef,
    ColumnFiltersState,
    SortingState,
    VisibilityState,
    flexRender,
    getCoreRowModel,
    getFilteredRowModel,
    getSortedRowModel,
    useReactTable,
} from "@tanstack/react-table";
import { ChevronDownIcon, ChevronUpIcon, ColumnsIcon, ListFilter } from "lucide-react";
import { ComponentType, ReactElement, useState } from "react";

export interface tableAddGroupT {
    id: number;
    label: string;
    icon?: ReactElement;
    func?: () => void;
}

export interface filterByT {
    id: string;
    title: string;
    options: {
        label: string;
        value: string | number | boolean;
        icon?: ComponentType<{ className?: string }>;
    }[];
}

export interface DataTablePropsT<TData, TValue> {
    name: string;
    label?: string;
    columns: ColumnDef<TData, TValue>[];
    defaultColumnVisibility?: VisibilityState;
    data: TData[];
    isFetching?: boolean;
    isLoading?: boolean;
    addGroup: tableAddGroupT[];
    filterBy?: filterByT[];
    className?: string;
    getSelectedRows?: (rows: TData[]) => void;
}

export function TanstackTable<TData, TValue>({
    className,
    name,
    label,
    columns,
    defaultColumnVisibility = {},
    data,
    getSelectedRows,
    addGroup,
    filterBy,
    isLoading,
}: DataTablePropsT<TData, TValue>) {
    const breakpoint = useMediaQuery("md");
    const [globalFilter, setGlobalFilter] = useState<string>("");
    const [sorting, setSorting] = useState<SortingState>([]);
    const [columnFilters, setColumnFilters] = useLocalStorage<ColumnFiltersState>(
        `tanstackTable-${name}-columnFilters`,
        [],
    );
    const [columnVisibility, setColumnVisibility] = useLocalStorage(
        `tanstackTable-${name}-columnVisibility`,
        defaultColumnVisibility,
    );

    const handleDropRowsClick = () => {
        getSelectedRows?.(table.getSelectedRowModel().rows.map(row => row.original));
    };
    const table = useReactTable<TData>({
        data,
        columns,
        getCoreRowModel: getCoreRowModel(),
        //Row selection
        enableRowSelection: true,
        // onRowSelectionChange: setRowSelection,
        //Columns visibility
        onColumnVisibilityChange: setColumnVisibility,
        //Global filter
        enableFilters: true,
        getFilteredRowModel: getFilteredRowModel(),
        onGlobalFilterChange: setGlobalFilter,
        //Column filters
        onColumnFiltersChange: setColumnFilters,
        //Sorting
        onSortingChange: setSorting,
        getSortedRowModel: getSortedRowModel(),
        state: {
            sorting,
            columnVisibility,
            globalFilter,
            columnFilters,
        },
    });

    return (
        <div className={cn(className)}>
            <header
                className={
                    "bg-surface-primary flex flex-col rounded-lg border border-border-primary"
                }
            >
                <div className={"flex w-full items-center justify-between p-3 md:p-4"}>
                    <h2 className={"text-xl font-semibold"}>{label}</h2>
                    <div className={"flex gap-3"}>
                        {breakpoint ? (
                            <>
                                <TanstackTableSearch setGlobalFilter={setGlobalFilter} />
                                <DropdownMenu>
                                    <DropdownMenuTrigger asChild>
                                        <Button className={"shrink-0"} variant={"outline"}>
                                            <ColumnsIcon />
                                            Kolumny
                                        </Button>
                                    </DropdownMenuTrigger>
                                    <DropdownMenuContent align={"end"}>
                                        <DropdownMenuLabel>Wybierz kolumny</DropdownMenuLabel>
                                        <DropdownMenuGroup>
                                            {table
                                                .getAllColumns()
                                                .filter(
                                                    column =>
                                                        typeof column.accessorFn !== "undefined" &&
                                                        column.getCanHide(),
                                                )
                                                .map(column => {
                                                    return (
                                                        <DropdownMenuCheckboxItem
                                                            key={column.id}
                                                            className="capitalize"
                                                            checked={column.getIsVisible()}
                                                            onCheckedChange={value => {
                                                                column.toggleVisibility(value);
                                                            }}
                                                            onSelect={e => e.preventDefault()}
                                                        >
                                                            {column.columnDef.meta?.toString() ??
                                                                ""}
                                                        </DropdownMenuCheckboxItem>
                                                    );
                                                })}
                                        </DropdownMenuGroup>
                                    </DropdownMenuContent>
                                </DropdownMenu>
                            </>
                        ) : (
                            <TanstackTableViewOptions table={table}>
                                <TanstackTableViewOptionsTrigger>
                                    <Button variant={"outline"}>
                                        <ListFilter />
                                        Filtry
                                    </Button>
                                </TanstackTableViewOptionsTrigger>
                                <TanstackTableViewOptionsContent>
                                    <TanstackTableViewOptionsContentFilter filterBy={filterBy} />
                                    <TanstackTableViewOptionsContentSort />
                                    <TanstackTableViewOptionsContentVisibility />
                                </TanstackTableViewOptionsContent>
                            </TanstackTableViewOptions>
                        )}
                    </div>
                </div>
                <Separator />
                <div className={"flex justify-between gap-2 p-3 md:p-4"}>
                    {breakpoint ? (
                        <div className="flex shrink-0 items-center space-x-2">
                            {filterBy &&
                                filterBy.map(({ id, title, options }) => {
                                    return (
                                        <TanstackTableFacetedFilters
                                            key={id}
                                            column={table.getColumn(id)}
                                            title={title}
                                            options={options}
                                        />
                                    );
                                })}
                        </div>
                    ) : (
                        <TanstackTableSearch setGlobalFilter={setGlobalFilter} />
                    )}
                    <TanstackTableActions
                        addGroup={addGroup}
                        isSelection={table.getSelectedRowModel().rows.length > 0}
                        removeRows={handleDropRowsClick}
                    />
                </div>
            </header>
            {breakpoint ? (
                <Table>
                    <TableHeader
                        className={
                            "transition-all duration-200 z-10 border-b border-border-primary box-content "
                        }
                    >
                        {table.getHeaderGroups().map(headerGroup => (
                            <TableRow
                                key={headerGroup.id}
                                className={"hover:bg-surface-primary-hover"}
                            >
                                {headerGroup.headers.map(header => {
                                    return (
                                        <TableHead
                                            key={header.id}
                                            className="px-3 py-1 whitespace-nowrap h-full"
                                            aria-sort={
                                                header.column.getIsSorted() === "asc"
                                                    ? "ascending"
                                                    : header.column.getIsSorted() === "desc"
                                                      ? "descending"
                                                      : "none"
                                            }
                                        >
                                            {header.isPlaceholder ? null : header.column.getCanSort() ? (
                                                <div
                                                    className={cn(
                                                        header.column.getCanSort() &&
                                                            "flex h-full cursor-pointer items-center justify-between gap-2 select-none",
                                                    )}
                                                    onClick={header.column.getToggleSortingHandler()}
                                                    onKeyDown={e => {
                                                        // Enhanced keyboard handling for sorting
                                                        if (
                                                            header.column.getCanSort() &&
                                                            (e.key === "Enter" || e.key === " ")
                                                        ) {
                                                            e.preventDefault();
                                                            header.column.getToggleSortingHandler()?.(
                                                                e,
                                                            );
                                                        }
                                                    }}
                                                    tabIndex={
                                                        header.column.getCanSort() ? 0 : undefined
                                                    }
                                                >
                                                    {flexRender(
                                                        header.column.columnDef.header,
                                                        header.getContext(),
                                                    )}
                                                    {{
                                                        asc: (
                                                            <ChevronUpIcon
                                                                className="shrink-0 opacity-60 size-4"
                                                                aria-hidden="true"
                                                            />
                                                        ),
                                                        desc: (
                                                            <ChevronDownIcon
                                                                className="shrink-0 opacity-60 size-4"
                                                                aria-hidden="true"
                                                            />
                                                        ),
                                                    }[header.column.getIsSorted() as string] ?? (
                                                        <span
                                                            className="size-4"
                                                            aria-hidden="true"
                                                        />
                                                    )}
                                                </div>
                                            ) : (
                                                flexRender(
                                                    header.column.columnDef.header,
                                                    header.getContext(),
                                                )
                                            )}
                                        </TableHead>
                                    );
                                })}
                            </TableRow>
                        ))}
                    </TableHeader>
                    <TableBody>
                        {isLoading ? (
                            <TanstackTableLoading table={table} />
                        ) : (
                            <TanstackTableRows table={table} colSpan={columns.length} />
                        )}
                    </TableBody>
                </Table>
            ) : (
                <div className={"flex h-full flex-col gap-2"}>
                    {isLoading ? (
                        <TanstackTableLoading table={table} />
                    ) : (
                        <TanstackTableRows table={table} colSpan={columns.length} />
                    )}
                </div>
            )}
        </div>
    );
}
