import { cn } from "@/lib/utils";
import { forwardRef, HTMLAttributes } from "react";
import { cva, VariantProps } from "class-variance-authority";
import { X } from "lucide-react";

const eventStyles =
    "cursor-pointer outline-ring-focus outline-0 focus-visible:outline-2 disabled:cursor-not-allowed disabled:pointer-events-none disabled:opacity-75";

const tagVariants = cva(
    "group relative inline-flex w-fit gap-1 whitespace-nowrap font-medium truncate items-center transition-color transition-outline duration-100",
    {
        variants: {
            variant: {
                brand: "*:stroke-text-brand text-text-brand bg-fill-brand-secondary hover:bg-fill-brand-secondary-hover",
                muted: "*:stroke-text-secondary text-text-secondary",
                accent: "*:stroke-text-accent text-text-accent bg-fill-accent-secondary hover:bg-fill-accent-secondary-hover",
                success:
                    "*:stroke-text-success text-text-success bg-fill-success-secondary hover:bg-fill-success-secondary-hover",
                warning:
                    "*:stroke-text-warning text-text-warning bg-fill-warning-secondary hover:bg-fill-warning-secondary-hover",
                destructive:
                    "*:stroke-text-destructive text-text-destructive bg-fill-destructive-secondary hover:bg-fill-destructive-secondary-hover",
                purple: ":stroke-text-purple text-text-purple bg-fill-purple-secondary hover:bg-fill-purple-secondary-hover",
                turquoise:
                    ":stroke-text-turquoise text-text-turquoise bg-fill-turquoise-secondary hover:bg-fill-turquoise-secondary-hover",
                blue: ":stroke-text-blue text-text-blue bg-fill-blue-secondary hover:bg-fill-blue-secondary-hover",
            },
            border: {
                true: "border",
                false: "border-none",
            },
            size: {
                sm: "h-6 min-h-6 px-2 py-0.5 text-xs rounded-sm has-[button]:pe-0.5 [&_svg]:shrink-0 [&_svg]:w-3 [&_svg]:h-3",
                md: "h-7 min-h-7 px-3 py-1 text-sm rounded-sm has-[button]:pe-1 [&_svg]:shrink-0 [&_svg]:w-4 [&_svg]:h-4",
            },
        },
        compoundVariants: [
            {
                variant: "muted",
                border: false,
                className: "bg-fill-secondary hover:bg-fill-secondary-hover",
            },
            {
                variant: "muted",
                border: true,
                className: "border-border-primary bg-fill-primary hover:bg-fill-primary-hover",
            },
            {
                variant: "brand",
                border: true,
                className: "border-border-brand",
            },
            {
                variant: "accent",
                border: true,
                className: "border-border-accent",
            },
            {
                variant: "success",
                border: true,
                className: "border-border-success",
            },
            {
                variant: "warning",
                border: true,
                className: "border-border-warning",
            },
            {
                variant: "destructive",
                border: true,
                className: "border-border-destructive",
            },
            {
                variant: "purple",
                border: true,
                className: "border-border-purple",
            },
            {
                variant: "turquoise",
                border: true,
                className: "border-border-turquoise",
            },
            {
                variant: "blue",
                border: true,
                className: "border-border",
            },
        ],
        defaultVariants: {
            size: "sm",
            variant: "brand",
            border: false,
        },
    },
);

interface TagProps extends HTMLAttributes<HTMLElement>, VariantProps<typeof tagVariants> {
    onRemove?: () => void;
    onClick?: () => void;
}

const Tag = forwardRef<HTMLButtonElement | HTMLDivElement, TagProps>(
    ({ variant, border, size, children, onRemove, onClick, className, ...props }, ref) => {
        if (onClick && onRemove) {
            throw new Error("Tag cannot have both onClick and onRemove properties.");
        }

        if (onClick) {
            return (
                <button
                    ref={ref as React.Ref<HTMLButtonElement>}
                    data-removable={!!onRemove}
                    onClick={onClick}
                    className={cn(tagVariants({ variant, border, size }), eventStyles, className)}
                    {...props}
                >
                    {children}
                </button>
            );
        }

        return (
            <div
                ref={ref as React.Ref<HTMLDivElement>}
                data-removable={!!onRemove}
                className={cn(tagVariants({ variant, border, size }), className)}
                {...props}
            >
                {children}
                {onRemove && (
                    <button
                        onClick={onRemove}
                        className={cn(
                            "flex items-center justify-center rounded-full aspect-square h-full shrink-0 hover:backdrop-brightness-110",
                            eventStyles,
                        )}
                    >
                        <X className={cn("stroke-icon-tertiary")} />
                    </button>
                )}
            </div>
        );
    },
);
Tag.displayName = "Tag";

export { Tag, tagVariants };
