import { useMemo } from "react";

export const useLvl = (lvl?: number) => {
    return useMemo(() => {
        switch (lvl) {
            case 0:
                return "Uczeń";
            case 1:
                return "Nauczyciel";
            case 2:
                return "Administrator";
            default:
                return "";
        }
    }, [lvl]);
};
