import { useInfiniteQueryResult } from "@/api/api-utils";
import { useGetGroupsInfiniteQuery } from "@/api/queries/groupsQueries";
import { useGetUsersInfiniteQuery } from "@/api/queries/usersQueries";
import { SelectDataListElement } from "@/components/ui/select-list/SelectDataListElement";
import SelectDataList from "@/components/ui/select-list/SelectDataList";
import { SelectDataListLabel } from "@/components/ui/select-list/SelectDataList";
import { cn } from "@/lib/utils";
import type { GroupPublic } from "@/types";
import { getUserLvl } from "@/utils/getUserLvl";
import { UserPublic } from "@/types";

type SelectTabProps<T> = {
    searchValue: string;
    selected: T[];
    setSelected: (item: T[]) => void;
    enabled: boolean;
};

function UsersSelectTab({
    searchValue,
    selected,
    setSelected,
    enabled,
}: SelectTabProps<UserPublic>) {
    const getUsersInfiniteQuery = useGetUsersInfiniteQuery({
        name: searchValue,
        enabled: enabled,
    });

    const {
        data,
        isEmpty: isEmpty,
        noResults: noResults,
    } = useInfiniteQueryResult(getUsersInfiniteQuery.data, searchValue);

    return (
        <SelectDataList
            query={getUsersInfiniteQuery}
            selected={selected}
            setSelected={setSelected}
            isEmpty={isEmpty}
            isNoResults={noResults}
            emptyMessage={"Wyszukaj użytkownika"}
            searchNoResultsMessage={"Nie znaleziono uzytkownika"}
        >
            <SelectDataListLabel className={cn((!selected.length || searchValue) && "hidden")}>
                Dodani:
            </SelectDataListLabel>
            {!searchValue &&
                selected.map(entity => (
                    <SelectDataListElement
                        key={entity.id}
                        entity={entity}
                        data={{
                            title: entity.name + " " + entity.surname,
                            avatarURL: entity.avatarURL,
                            detail: getUserLvl(entity.lvl),
                        }}
                    />
                ))}
            <SelectDataListLabel>Wyszukiwane:</SelectDataListLabel>
            {data.map(entity => (
                <SelectDataListElement
                    key={entity.id}
                    entity={entity}
                    data={{
                        title: entity.name + " " + entity.surname,
                        avatarURL: entity.avatarURL,
                        detail: getUserLvl(entity.lvl),
                    }}
                />
            ))}
        </SelectDataList>
    );
}

function GroupsSelectTab({
    searchValue,
    selected,
    setSelected,
    enabled,
}: SelectTabProps<GroupPublic>) {
    const getGroupsInfiniteQuery = useGetGroupsInfiniteQuery({
        name: searchValue,
        enabled: enabled,
    });

    const {
        data: groups,
        isEmpty: isEmpty,
        noResults: noResults,
    } = useInfiniteQueryResult(getGroupsInfiniteQuery.data, searchValue);

    return (
        <SelectDataList
            query={getGroupsInfiniteQuery}
            selected={selected}
            setSelected={setSelected}
            isEmpty={isEmpty}
            isNoResults={noResults}
            emptyMessage={"Wyszukaj grupę"}
            searchNoResultsMessage={"Nie znaleziono grupy"}
        >
            <SelectDataListLabel className={cn((!selected.length || searchValue) && "hidden")}>
                Dodane:
            </SelectDataListLabel>
            {!searchValue &&
                selected.map(entity => (
                    <SelectDataListElement
                        key={entity.id}
                        entity={entity}
                        data={{
                            title: entity.name,
                            avatarURL: entity.avatarURL,
                            detail: "",
                        }}
                    />
                ))}
            <SelectDataListLabel>Wyszukiwane:</SelectDataListLabel>
            {groups.map(entity => (
                <SelectDataListElement
                    key={entity.id}
                    entity={entity}
                    data={{
                        title: entity.name,
                        avatarURL: entity.avatarURL,
                        detail: "",
                    }}
                />
            ))}
        </SelectDataList>
    );
}

export { UsersSelectTab, GroupsSelectTab };
