import CreateMeeting from "@/components/features/calendar/form/CreateMeeting";
import { Button } from "@/components/ui/button/Button";
import { useLocationActions } from "@/hooks/useLocationActions";
import { useCredentials } from "@/store/authStore";
import { Plus } from "lucide-react";
import { useCallback, useMemo, useState } from "react";
import { CalendarFormSchemaType } from "@/schemas/meeting.schema";

export default function CalendarHeaderAddButton() {
    const { lvl } = useCredentials();
    const [openAddMeeting, setOpenAddMeeting] = useState<boolean>(false);

    const handleOpenAddMeeting = useCallback(() => {
        setOpenAddMeeting(true);
    }, []);

    const { value: defaultValues } = useLocationActions<Partial<CalendarFormSchemaType>>({
        actionName: "createMeeting",
        onActionTrigger: handleOpenAddMeeting,
    });

    return (
        <>
            <CreateMeeting
                open={openAddMeeting}
                onOpenChange={setOpenAddMeeting}
                defaultValues={defaultValues}
            />
            <div className={"flex flex-row gap-2"}>
                {lvl > 0 ? (
                    <Button onClick={handleOpenAddMeeting} variant={"secondary"}>
                        <Plus /> Dodaj
                    </Button>
                ) : null}
            </div>
        </>
    );
}
