import { axiosInstance } from "@/api/api";
import {
    NotificationPublic,
    ReadNotificationsType,
    UnreadNotificationsCount,
    type PushSubscriptionType,
} from "@/types";
import { PaginationI } from "@/types";

export const getNotifications = async (params?: PaginationI): Promise<NotificationPublic[]> => {
    return await axiosInstance.get("/api/notification", {
        params: params,
    });
};

export const readNotifications = async (data?: ReadNotificationsType): Promise<string> => {
    return await axiosInstance.post("/api/notification/read", data);
};

export const readAllNotifications = async (): Promise<string> => {
    return await axiosInstance.post("/api/notification/readAll");
};

export const getUnreadNotificationsCount = async (): Promise<UnreadNotificationsCount[]> => {
    return await axiosInstance.get("/api/notification/unreadCnt");
};

export const subscribeWebPush = async (data: PushSubscriptionType): Promise<string> => {
    return await axiosInstance.post("/api/notification/subscribe", data);
};

export const unsubscribeWebPush = async (data: PushSubscriptionType): Promise<string> => {
    return await axiosInstance.post("/api/notification/unsubscribe", data);
};
