import { FileUploadDrawer } from "@/components/ui/file-upload/FileUpload";
import {
    FileUploadListItem,
    FileUploadListHeader,
    FileUploadListIcon,
    FileUploadListInfo,
    FileUploadListDescription,
    FileUploadListDescriptionSeparator,
    FileUploadListSize,
    FileUploadListAction,
    FileUploadList,
    FileUploadListActions,
    FileUploadListDescriptionText,
    FileUploadListContent,
    FileUploadListProgress,
    FileUploadListName,
} from "@/components/ui/file-upload/FileUploadList";
import { AlertCircle, CircleX, Folder, RotateCcw, Trash2, X } from "lucide-react";
import { useCallback, useEffect, useState, type Dispatch, type SetStateAction } from "react";
import { AggregatedEntry } from "../hooks/useUploadFsElements";
import { FileText } from "lucide-react";
import { cn } from "@/lib/utils";
import {
    Dialog,
    DialogContent,
    DialogDescription,
    DialogHeader,
    DialogTitle,
    DialogBody,
    DialogFooter,
} from "@/components/ui/dialog/Dialog";
import { Button } from "@/components/ui/button/Button";
import { FeaturedIcon } from "@/components/ui/featured-icon/FeaturedIcon";

type UploadFSEntiresProps = {
    open: boolean;
    onOpenChange: Dispatch<SetStateAction<boolean>>;
    uploadProgressData: AggregatedEntry[];
    deleteUplaodProgressEntry: (entry: AggregatedEntry) => void;
    resetUploadProgress: () => void;
};

export default function UploadFSEntires({
    open,
    onOpenChange,
    uploadProgressData,
    resetUploadProgress,
    deleteUplaodProgressEntry,
}: UploadFSEntiresProps) {
    const [errorDialogOpen, setErrorDialogOpen] = useState<boolean>(false);
    const [selectedErrorEntry, setSelectedErrorEntry] = useState<AggregatedEntry | null>(null);

    // Auto-open drawer when entries exist
    useEffect(() => {
        if (uploadProgressData.length > 0) {
            onOpenChange(true);
        }
    }, [uploadProgressData.length, onOpenChange]);

    const handleOpenChange = (open: boolean) => {
        if (!open) {
            resetUploadProgress();
        }
        onOpenChange(open);
    };

    const handleShowErrorDetails = (entry: AggregatedEntry) => {
        setSelectedErrorEntry(entry);
        setErrorDialogOpen(true);
    };

    const handleDeleteUpload = (entry: AggregatedEntry) => {
        deleteUplaodProgressEntry(entry);
    };

    return (
        <>
            <FileUploadDrawer open={open} onOpenChange={handleOpenChange}>
                <FileUploadList>
                    {uploadProgressData.map(entry => (
                        <UploadEntry
                            key={`${entry.name}_${entry.type}`}
                            entry={entry}
                            onShowError={handleShowErrorDetails}
                            onDelete={handleDeleteUpload}
                        />
                    ))}
                </FileUploadList>
            </FileUploadDrawer>

            <ErrorDetailsDialog
                open={errorDialogOpen}
                onOpenChange={setErrorDialogOpen}
                errorEntry={selectedErrorEntry}
            />
        </>
    );
}

type UploadEntryProps = {
    entry: AggregatedEntry;
    onShowError: (entry: AggregatedEntry) => void;
    onDelete: (entry: AggregatedEntry) => void;
};
function UploadEntry({ entry, onShowError, onDelete }: UploadEntryProps) {
    const progressValue = (entry.loaded / entry.total) * 100;
    const progressPercent = Math.round(1000 * (entry.loaded / entry.total)) / 10;
    return (
        <FileUploadListItem>
            <FileUploadListHeader>
                <FileUploadListIcon>
                    {entry.type === "DIR" ? <Folder /> : <FileText />}
                </FileUploadListIcon>
                <FileUploadListInfo>
                    <FileUploadListName
                        className={cn(entry.status === "error" && "text-text-destructive")}
                    >
                        {entry.name}
                    </FileUploadListName>
                    <FileUploadListDescription>
                        <FileUploadListSize>{entry.total}</FileUploadListSize>
                        <FileUploadListDescriptionSeparator />
                        <FileUploadListDescriptionText>
                            {progressPercent}%
                        </FileUploadListDescriptionText>
                    </FileUploadListDescription>
                </FileUploadListInfo>
                <FileUploadListActions>
                    {/* Error details button */}
                    {entry.status === "error" && entry.errors.length > 0 && (
                        <FileUploadListAction onClick={() => onShowError(entry)}>
                            <AlertCircle className="text-text-destructive" />
                            <span className="sr-only">Error Details</span>
                        </FileUploadListAction>
                    )}

                    {/* Delete button */}
                    {(entry.status === "aborted" || entry.status === "error") && (
                        <FileUploadListAction
                            onClick={() => onDelete(entry)}
                            className="hover:text-text-destructive"
                        >
                            <Trash2 />
                            <span className="sr-only">Delete</span>
                        </FileUploadListAction>
                    )}

                    {/* Retry button */}
                    <FileUploadListAction
                        className={cn(
                            entry.status === "aborted" || entry.status === "error"
                                ? "visible"
                                : "hidden",
                        )}
                        onClick={() => entry.retry?.()}
                    >
                        <RotateCcw />
                        <span className="sr-only">Retry</span>
                    </FileUploadListAction>

                    {/* Abort button */}
                    <FileUploadListAction
                        className={cn(entry.status === "uploading" ? "visible" : "hidden")}
                        onClick={() => entry.abort?.()}
                    >
                        <X />
                        <span className="sr-only">Abort</span>
                    </FileUploadListAction>
                </FileUploadListActions>
            </FileUploadListHeader>
            <FileUploadListContent>
                <FileUploadListProgress value={progressValue} />
            </FileUploadListContent>
        </FileUploadListItem>
    );
}

type ErrorDetailsDialogProps = {
    open: boolean;
    onOpenChange: Dispatch<SetStateAction<boolean>>;
    errorEntry: AggregatedEntry | null;
};

function ErrorDetailsDialog({ open, onOpenChange, errorEntry }: ErrorDetailsDialogProps) {
    const onClose = useCallback(() => {
        onOpenChange(false);
    }, [onOpenChange]);

    return (
        <Dialog open={open} onOpenChange={onOpenChange}>
            <DialogContent
                className={"sm:max-w-148"}
                onCloseAutoFocus={onClose}
                onEscapeKeyDown={onClose}
            >
                <DialogDescription className={"sr-only"}>
                    Wystąpiły błędy podczas przesyłania plików
                </DialogDescription>
                <DialogBody>
                    <DialogHeader className={"border-b border-border-primary"}>
                        <FeaturedIcon variant={"ghost"}>
                            <CircleX />
                        </FeaturedIcon>
                        <div className={"flex flex-col gap-0.5 min-w-0 grow"}>
                            <DialogTitle>Błędy przesyłania plików</DialogTitle>
                            <p className={"text-text-tertiary text-xs"}>
                                list plików, których przesłanie nie powiodło się.
                            </p>
                        </div>
                    </DialogHeader>
                    <div className={"flex flex-col p-3 md:p-4"}>
                        <ul className="space-y-2">
                            {errorEntry?.errors.map(({ path, message }, index) => (
                                <li
                                    key={index}
                                    className="text-sm py-2 px-3 flex justify-between items-center"
                                >
                                    <span className="break-all pr-2 text-text-tertiary truncate w-1/2">
                                        {path}
                                    </span>
                                    <p className={"text-text-destructive"}>{message}</p>
                                </li>
                            ))}
                        </ul>
                    </div>
                </DialogBody>
                <DialogFooter className={"border-t border-border-primary"}>
                    <Button
                        onClick={onClose}
                        type={"button"}
                        variant={"tertiary"}
                        className={"w-full md:w-fit"}
                    >
                        Wyjdź
                    </Button>
                </DialogFooter>
            </DialogContent>
        </Dialog>
    );
}
