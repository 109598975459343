import { VariantProps, cva } from "class-variance-authority";
import React from "react";
import { cn } from "@/lib/utils";
import { File, FolderOpen } from "lucide-react";

const fileIconVariants = cva(
    "relative [&_svg]:pointer-events-none shrink-0 [&_svg]:shrink-0 grid place-content-center rounded-sm border border-border-primary bg-fill-primary",
    {
        variants: {
            size: {
                sm: "[&_svg]:size-4 w-8 h-8",
                md: "[&_svg]:size-4 w-10 h-10",
                lg: "[&_svg]:size-4 w-12 h-12",
            },
        },
        compoundVariants: [],
        defaultVariants: {
            size: "md",
        },
    },
);

export interface FileIconProps
    extends React.HTMLAttributes<HTMLDivElement>,
        VariantProps<typeof fileIconVariants> {
    fileExtension?: string;
}

export interface FolderIconProps
    extends React.HTMLAttributes<HTMLDivElement>,
        VariantProps<typeof fileIconVariants> {}

const FileIcon = React.forwardRef<HTMLDivElement, FileIconProps>(
    ({ fileExtension, className, size, ...props }, ref) => {
        return (
            <div ref={ref} className={cn(fileIconVariants({ size, className }))} {...props}>
                <File className="stroke-icon-tertiary" />
                <span
                    className={
                        "absolute select-none bottom-1/5 right-1/5 text-icon-tertiary text-[10px] font-medium [text-shadow:-2px_-2px_0_var(--color-fill-primary),2px_-2px_0_var(--color-fill-primary),-2px_2px_0_var(--color-fill-primary),2px_2px_0_var(--color-fill-primary)]"
                    }
                >
                    {fileExtension?.toLowerCase()}
                </span>
            </div>
        );
    },
);
FileIcon.displayName = "FileIcon";

const FolderIcon = React.forwardRef<HTMLDivElement, FolderIconProps>(
    ({ className, size, ...props }, ref) => {
        return (
            <div ref={ref} className={cn(fileIconVariants({ size, className }))} {...props}>
                <FolderOpen className="stroke-icon-tertiary" />
            </div>
        );
    },
);
FolderIcon.displayName = "FolderIcon";

export { FileIcon, FolderIcon };
