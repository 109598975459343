import {
    deleteLocation,
    editLocation,
    getLocation,
    getLocations,
    postLocation,
} from "@/api/endpoints/locations";
import { queryClient } from "@/api/query-client";
import { AddLocationSchemaType, EditLocationSchemaType } from "@/schemas/location.schema";
import { LocationPublic } from "@/types";
import { useMutation, useQuery } from "@tanstack/react-query";

export const useGetLocationsQuery = () => {
    return useQuery({
        queryKey: ["locations"],
        queryFn: () => getLocations(),
    });
};

export const useGetLocationQuery = ({ id }: { id?: number }) => {
    return useQuery({
        queryKey: ["locations", id],
        queryFn: () => getLocation(id),
        enabled: !!id, // only run the query if id is not null or undefined
    });
};

export const useDeleteLocationMutation = () => {
    return useMutation({
        mutationFn: (id: number) => deleteLocation(id),
        onMutate: async (id: number) => {
            await queryClient.cancelQueries({ queryKey: ["locations"] });

            const previousLocations = queryClient.getQueryData<LocationPublic[]>(["locations"]);

            queryClient.setQueryData(["locations"], (prev: LocationPublic[] | undefined) =>
                prev ? prev.filter((location: LocationPublic) => location.id !== id) : [],
            );

            return { previousLocations };
        },
        onError: (err, id, context) => {
            queryClient.setQueryData(["locations"], context?.previousLocations);
        },
        onSettled: () => {
            void queryClient.invalidateQueries({ queryKey: ["locations"] });
        },
        onSuccess: (data, id) => {
            queryClient.setQueryData(["locations"], (prev: LocationPublic[] | undefined) =>
                prev ? prev.filter((location: LocationPublic) => location.id !== id) : [],
            );
        },
    });
};

export const useEditLocationMutation = () => {
    return useMutation({
        mutationFn: (data: EditLocationSchemaType) => editLocation(data),
        onSuccess: data => {
            queryClient.setQueryData(["locations", data.id], (curr: LocationPublic) => {
                return { ...curr, ...data };
            });
            queryClient.setQueryData(["locations"], (curr: LocationPublic[]) =>
                curr.map(l => (l.id === data.id ? data : l)),
            );
        },
    });
};

export const usePostLocationMutation = () => {
    return useMutation({
        mutationFn: (data: AddLocationSchemaType) => postLocation(data),
        onSuccess: data => {
            queryClient.setQueryData(["locations"], (curr?: LocationPublic[]) => {
                if (!curr) {
                    return;
                }
                return [...curr, data];
            });
        },
    });
};
