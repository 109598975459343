import { usePostFileUploadMutation } from "@/api/queries/chatQueries";
import ChatContent from "@/components/features/chat/layout/chat-conversation/ChatContent";
import ChatFooter from "@/components/features/chat/layout/chat-conversation/ChatFooter";
import ChatHeader from "@/components/features/chat/layout/chat-conversation/ChatHeader";
import { useCredentials } from "@/store/authStore";
import { useChatStore } from "@/store/chatStore";
import type { MessagePublic, MessageSend } from "@/types";
import { FileTypeE, getServeFileType } from "@/utils/getFileType";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import { chatSocketService } from "../ChatSocketService";
import { toast } from "sonner";
import { isEmptyArray } from "@/utils/assertion";
import MediaViewer from "@/components/features/media/MediaViewer";
import { Button } from "@/components/ui/button/Button";
import { RotateCcw } from "lucide-react";

export interface messageFormProps {
    content: string;
    files?: File[];
    replyingTo?: MessagePublic;
}
export type messageFormTextProps = Omit<messageFormProps, "files">;
export type messageFormMediaProps = messageFormProps;

export const CHAT_MAX_FILE_SIZE = 500 * 1024 * 1024;
export const CHAT_MAX_MESSAGE_SIZE = 2056;

export default function Chat() {
    const { state } = useLocation();
    const { type, name, id, avatarURL } = state || {};
    const credencials = useCredentials();
    const { mutateAsync: fileUpload } = usePostFileUploadMutation();
    const chatId = useChatStore(state => state.chatId);
    const isDisconnected = useChatStore(state => state.isDisconnected);
    const viewedFile = useChatStore(state => state.viewedFile);
    const openViewer = useChatStore(state => state.openViewer);

    const setOpenViewer = (open: boolean) => {
        useChatStore.getState().setOpenViewer(open);
    };

    const onMessageUpload = ({ content, replyingTo }: messageFormTextProps) => {
        const tempUUID = uuidv4();

        const message: MessageSend = {
            id: -1,
            tempUUID: tempUUID,
            type: "text",
            content: content,
            createdAt: new Date().toISOString(),
            updatedAt: new Date().toISOString(),
            sender: credencials,
            replyingTo: replyingTo,
            replyingToId: replyingTo?.id,
        };

        chatSocketService.sendMessage(message);
    };

    const onFileUpload = ({ files, replyingTo, content }: messageFormMediaProps) => {
        if (!files) return;
        files.forEach(async file => {
            const serveType = getServeFileType(file.type, {
                pick: [FileTypeE.IMAGE, FileTypeE.VIDEO, FileTypeE.AUDIO],
            });
            const tempUUID = uuidv4();
            const store = useChatStore.getState();
            const socket = chatSocketService.getSocket();

            const message: MessageSend = {
                id: -1,
                type: serveType,
                sender: credencials,
                file: file,
                createdAt: new Date().toISOString(),
                updatedAt: new Date().toISOString(),
                tempUUID: tempUUID,
            };

            store.appendMessages([message]);
            await fileUpload({
                id: id,
                file: file,
                type: serveType,
                content: file.name,
                tempUUID: tempUUID,
                socketId: socket?.id,
            })
                .then(response => {
                    store.confirmMessage(response);
                })
                .catch(() => {
                    store.confirmMessage({
                        action: "failed",
                        tempUUID: tempUUID,
                        content: "Nie udało się wysłać pliku",
                        type: "text",
                    });
                });
        });
    };

    const onSubmit = async ({ content, files, replyingTo }: messageFormProps) => {
        if (!isEmptyArray(files)) {
            onFileUpload({ content, files, replyingTo });
        }

        if (content) {
            onMessageUpload({ content, replyingTo });
        }
    };

    const onReconnect = () => {
        chatSocketService.reconnect();
    };

    useEffect(() => {
        if (id !== chatId) {
            // Connect to the new conversation
            chatSocketService.connect(id);
        }
    }, [chatId, id]);

    useEffect(() => {
        return () => {
            chatSocketService.disconnect();
        };
    }, []);

    return (
        <>
            <MediaViewer file={viewedFile} open={openViewer} onOpenChange={setOpenViewer} />
            <section
                className={
                    "grow relative flex min-w-0 h-[calc(100dvh-var(--spacing)*23)] md:h-[calc(100dvh-var(--spacing)*4*2)] flex-col overflow-hidden rounded-lg border border-border-primary bg-surface-primary"
                }
            >
                {isDisconnected && (
                    <Button
                        variant="tertiary"
                        variantColor={"destructive"}
                        size="sm"
                        onClick={onReconnect}
                        className="absolute left-1/2 -translate-x-1/2 top-20 w-fit z-70 shadow-md"
                    >
                        <RotateCcw />
                        Spróbuj ponownie
                    </Button>
                )}
                <ChatHeader name={name} avatarURL={avatarURL} id={id} conversationType={type} />
                <ChatContent conversationType={type} />
                <ChatFooter onSubmit={onSubmit} />
            </section>
        </>
    );
}
