import { cn } from "@/lib/utils";

export default function MeetingLocation({
    locationName,
    className,
}: {
    locationName: string;
    className?: string;
}) {
    return (
        <p className={cn("truncate text-start text-[clamp(0.7rem,1.5vw,0.8rem)]", className)}>
            {locationName}
        </p>
    );
}
