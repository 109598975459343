import { SidebarTrigger } from "@/components/ui/sidebar/Sidebar";
import { AlignCenter, Bell, User } from "lucide-react";
import { useCredentials } from "@/store/authStore";
import { Avatar, AvatarFallback, AvatarImage } from "@/components/ui/avatar/Avatar";
import { Button } from "@/components/ui/button/Button";
import { getAvatar } from "@/utils/getAvatar";
import { NotificationsSheetTrigger } from "@/components/features/notifications/NotificationsSheet";
import Chip from "@/components/ui/chip/chip";
import { useMemo } from "react";
import { useGetUnreadNotificationsCountQuery } from "@/api/queries/notificationsQueries";

export default function NavHeaderMobile() {
    const { name, avatarURL } = useCredentials();

    const { data } = useGetUnreadNotificationsCountQuery("all");

    return (
        <header
            className={
                "flex justify-between md:hidden sticky top-0 z-40 border-b border-border-primary p-3 bg-surface-primary"
            }
        >
            <SidebarTrigger>
                <AlignCenter />
            </SidebarTrigger>
            <div className={"flex gap-2"}>
                <Chip
                    isInvisible={!data}
                    variantColor={"success"}
                    variant={"flat"}
                    placement={"top-left"}
                >
                    <NotificationsSheetTrigger asChild>
                        <Button size={"md"} variant={"ghost"}>
                            <Bell />
                            <span className="sr-only">powiadomienia</span>
                        </Button>
                    </NotificationsSheetTrigger>
                </Chip>

                <Avatar size={"md"}>
                    <AvatarImage src={getAvatar(avatarURL)} alt={name} />
                    <AvatarFallback>
                        <User />
                    </AvatarFallback>
                </Avatar>
            </div>
        </header>
    );
}
