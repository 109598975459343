"use no memo";

import { Skeleton } from "@/components/ui/skeleton/Skeleton";
import { TableCell, TableRow } from "@/components/ui/table/Table";
import { useMediaQuery } from "@/hooks/useMediaQuery";
import { cn } from "@/lib/utils";
import { Table as TableT } from "@tanstack/table-core";
import { Fragment } from "react";

export default function TanstackTableLoading<TData>({ table }: { table: TableT<TData> }) {
    const breakpoint = useMediaQuery("md");

    if (breakpoint) {
        return Array.from({ length: 20 }, (_, index) => (
            <TableRow key={index}>
                {table.getHeaderGroups().map(headerGroup =>
                    headerGroup.headers.map((header, index, headers) => {
                        const isLast = index === headers.length - 1;
                        return (
                            <TableCell key={header.id} className={"px-3 py-1"}>
                                <Skeleton
                                    className={cn(
                                        "z-0 w-full rounded-sm",
                                        isLast ? "ml-auto h-10 w-10" : "h-4",
                                    )}
                                />
                            </TableCell>
                        );
                    }),
                )}
            </TableRow>
        ));
    }
    return Array.from({ length: 3 }, (_, index) => (
        <div
            key={index}
            className={
                "flex w-full flex-col rounded-xl border border-border-primary bg-surface-primary py-4"
            }
        >
            {table.getHeaderGroups().map(headerGroup =>
                headerGroup.headers.map((header, index, headers) => {
                    const isLast = index === headers.length - 1;
                    return (
                        <div
                            key={header.id}
                            className={cn(
                                "flex min-h-7 items-center justify-between gap-3 px-4",
                                isLast ? "mt-4 border-t border-border-primary px-4 pt-4" : null,
                            )}
                        >
                            {isLast ? (
                                <Skeleton className={"ml-auto h-10 w-10"} />
                            ) : (
                                <Fragment>
                                    <Skeleton className={"h-4 w-14 shrink-0"} />
                                    <Skeleton className={"h-4 w-full"} />
                                </Fragment>
                            )}
                        </div>
                    );
                }),
            )}
        </div>
    ));
}
