"use no memo";

import { Badge } from "@/components/ui/badge/Badge";
import { Button } from "@/components/ui/button/Button";
import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuGroup,
    DropdownMenuItem,
    DropdownMenuLabel,
    DropdownMenuSeparator,
    DropdownMenuTrigger,
} from "@/components/ui/input/dropdown-menu/DropdownMenu";
import { Separator } from "@/components/ui/separator/Separator";
import { cn } from "@/lib/utils";
import "@radix-ui/react-dropdown-menu";
import { Column } from "@tanstack/react-table";
import { CheckIcon, Plus } from "lucide-react";
import { ComponentType, useMemo } from "react";

interface DataTableFacetedFilterProps<TData, TValue> {
    column?: Column<TData, TValue>;
    title?: string;
    options: {
        label: string;
        value: string | number | boolean;
        icon?: ComponentType<{ className?: string }>;
    }[];
}

export function TanstackTableFacetedFilters<TData, TValue>({
    column,
    title,
    options,
}: DataTableFacetedFilterProps<TData, TValue>) {
    const facets = column?.getFacetedUniqueValues();

    const filterValue = column?.getFilterValue();

    const selectedValues = useMemo(() => {
        let values = new Set<string | number | boolean>();
        if (Array.isArray(filterValue) && filterValue.length > 0) {
            if (typeof filterValue[0] === "string") {
                values = new Set<string>(filterValue as string[]);
            } else if (typeof filterValue[0] === "number") {
                values = new Set<number>(filterValue as number[]);
            } else if (typeof filterValue[0] === "boolean") {
                values = new Set<boolean>(filterValue as boolean[]);
            }
        }
        return values;
    }, [filterValue]);
    const handleSelect = (optionValue: string | number | boolean, isSelected: boolean) => {
        const newSelectedValues = new Set(selectedValues);
        if (isSelected) {
            newSelectedValues.delete(optionValue);
        } else {
            newSelectedValues.add(optionValue);
        }
        const filterValues = Array.from(newSelectedValues);
        column?.setFilterValue(filterValues.length ? filterValues : undefined);
    };

    return (
        <DropdownMenu>
            <DropdownMenuTrigger asChild>
                <Button variant={"outline"} size={"sm"} className={"gap-1 border-dashed px-2"}>
                    <Plus />
                    {title}
                    {selectedValues.size > 0 && (
                        <>
                            <Separator orientation={"vertical"} className={"mx-1 h-4"} />
                            <Badge size={"sm"} variant={"muted"} className={"gap-0.5"}>
                                {selectedValues.size}
                            </Badge>
                        </>
                    )}
                </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent className="w-48" align="start">
                <DropdownMenuLabel>{title}</DropdownMenuLabel>
                <DropdownMenuGroup>
                    {options.map(option => {
                        const isSelected = selectedValues.has(option.value);
                        return (
                            <DropdownMenuItem
                                key={option.value.toString()}
                                onSelect={e => {
                                    e.preventDefault();
                                    handleSelect(option.value, isSelected);
                                }}
                            >
                                <div
                                    className={cn(
                                        "mr-2 flex h-4 w-4 items-center justify-center",
                                        isSelected
                                            ? "text-text-primary"
                                            : "opacity-50 [&_svg]:invisible",
                                    )}
                                >
                                    <CheckIcon className={cn("h-4 w-4")} />
                                </div>
                                {option.icon && (
                                    <option.icon className="mr-2 h-4 w-4 text-text-primary" />
                                )}
                                <span>{option.label}</span>
                                {facets?.get(option.value) && (
                                    <span className="ml-auto flex h-4 w-4 items-center justify-center text-xs">
                                        {facets.get(option.value)}
                                    </span>
                                )}
                            </DropdownMenuItem>
                        );
                    })}
                </DropdownMenuGroup>
                {selectedValues.size > 0 && (
                    <>
                        <DropdownMenuSeparator />
                        <DropdownMenuGroup>
                            <DropdownMenuItem
                                onSelect={() => column?.setFilterValue(undefined)}
                                className="justify-center text-center"
                            >
                                Wyczyść
                            </DropdownMenuItem>
                        </DropdownMenuGroup>
                    </>
                )}
            </DropdownMenuContent>
        </DropdownMenu>
    );
}
