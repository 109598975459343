import { flatQueryPages } from "@/api/api-utils";
import { GetMeetingsProps } from "@/api/endpoints/meetings";
import MeetingsList from "@/components/features/calendar/MeetingsList";
import MeetingsListContent from "@/components/features/calendar/MeetingsListContent";
import MeetingsListHeader from "@/components/features/calendar/MeetingsListHeader";
import { Button } from "@/components/ui/button/Button";
import { cn } from "@/lib/utils";
import { MeetingPublic } from "@/types";
import { InfiniteData, UseInfiniteQueryResult, UseQueryResult } from "@tanstack/react-query";
import { endOfDay, startOfDay } from "date-fns";
import { ArrowLeft } from "lucide-react";
import { Dispatch, SetStateAction, useMemo, useState } from "react";

interface CalendarCardProps {
    className?: string;
    defaultType?: "schedule" | "day";
    query:
        | UseInfiniteQueryResult<InfiniteData<MeetingPublic[], unknown>, Error>
        | UseQueryResult<MeetingPublic[], Error>;
    defaultDayParams?: Omit<GetMeetingsProps, "id">;
    defaultScheduleParams?: Omit<GetMeetingsProps, "id">;
    renderNavigateContextMenu?: (content: JSX.Element, day: Date, key: string) => JSX.Element;

    setQueryParams: Dispatch<SetStateAction<GetMeetingsProps>>;
}

function isInfiniteData(
    data: MeetingPublic[] | InfiniteData<MeetingPublic[], unknown>,
): data is InfiniteData<MeetingPublic[], unknown> {
    return typeof data === "object" && "pages" in data && "pageParams" in data;
}

export default function CalendarCard({
    className,
    query,
    defaultDayParams,
    defaultScheduleParams,
    renderNavigateContextMenu,
    setQueryParams,
    defaultType = "day",
}: CalendarCardProps) {
    const [date, setDate] = useState<Date>(new Date());
    const [type, setType] = useState<"schedule" | "day">(defaultType);

    const handleSetDate = (day: Date) => {
        setType("day");
        setQueryParams(curr => ({
            ...curr,
            ...(defaultDayParams
                ? defaultDayParams
                : {
                      after: startOfDay(day).toISOString(),
                      before: endOfDay(day).toISOString(),
                  }),
            page: 0,
        }));
        setDate(day);
    };

    const handleSetSchedule = () => {
        setType("schedule");
        setQueryParams(curr => ({
            ...curr,
            ...(defaultScheduleParams && defaultScheduleParams),
            after: startOfDay(new Date()).toISOString(),
            page: 0,
        }));
        setDate(new Date());
    };

    const { data = [] } = query;
    const meetings = useMemo(() => {
        if (isInfiniteData(data)) {
            return flatQueryPages(data);
        } else {
            return data;
        }
    }, [data]);

    return (
        <MeetingsList meetings={meetings} query={query}>
            <div className={cn("flex flex-col gap-4", className)}>
                <div
                    className={cn(
                        "flex flex-col gap-4 overflow-hidden rounded-lg border border-border-primary bg-surface-primary py-3 md:py-4",
                        type === "schedule" ? "shrink-0" : "grow",
                    )}
                >
                    <MeetingsListHeader
                        date={date}
                        setDate={handleSetDate}
                        renderContextMenu={renderNavigateContextMenu}
                    />
                    <div
                        className={cn(
                            "flex h-full grow flex-col gap-4 overflow-hidden",
                            type === "schedule" && "hidden",
                        )}
                    >
                        <MeetingsListContent />
                        <div className={"flex w-full justify-end px-3 md:px-4"}>
                            <Button size={"sm"} variant={"ghost"} onClick={handleSetSchedule}>
                                <ArrowLeft />
                                Do nadchodzących
                            </Button>
                        </div>
                    </div>
                </div>
                <div
                    className={cn(
                        "flex grow flex-col gap-5 overflow-hidden rounded-lg border border-border-primary bg-surface-primary py-3 md:py-4",
                        type === "day" && "hidden",
                    )}
                >
                    <div className={"px-3 md:px-4"}>
                        <h5>Nadchodzące spotkania</h5>
                    </div>
                    <MeetingsListContent />
                </div>
            </div>
        </MeetingsList>
    );
}
