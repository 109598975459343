import { io, Socket } from "socket.io-client";
import { useChatStore } from "@/store/chatStore";
import { MessagePublic, MessageSend, MessageConfirmation } from "@/types";

export const MESSAGES_PAGE_SIZE = 20;
export const MESSAGE_CONFIRMATION_TIMEOUT = 10000;

export function socketIOClient(id: number) {
    return io(import.meta.env.VITE_SOCKET_DOMAIN, {
        withCredentials: true,
        autoConnect: false,
        query: { chatId: id },
        path: import.meta.env.VITE_SOCKET_IO,
        reconnectionAttempts: 6,
        timeout: 100000,
    });
}

class ChatSocketService {
    private socket: Socket | null = null;
    private static instance: ChatSocketService | null = null;
    private disconnectionTimeout: NodeJS.Timeout | null = null;
    private readonly DISCONNECTION_TIMEOUT = 5000;

    // Private constructor prevents new instances
    private constructor() {}

    // Static method to get the instance
    public static getInstance(): ChatSocketService {
        if (!ChatSocketService.instance) {
            ChatSocketService.instance = new ChatSocketService();
        }
        return ChatSocketService.instance;
    }

    connect(chatId: number) {
        this.disconnect();
        const store = useChatStore.getState();
        store.resetToDefaults();
        store.setActiveChat(chatId);
        store.setConnectionStatus("connecting");

        this.socket = socketIOClient(chatId);
        // Set up event listeners
        this.socket.io.on("error", this.onErrorEvent);
        this.socket.on("connect", this.onConnect);
        this.socket.on("disconnect", this.onDisconnect);
        this.socket.on("message", this.onMessageEvent);
        this.socket.on("msgDelete", this.onMessageDelete);
        this.socket.on("confirm", this.onMessageConfirmationEvent);
        this.socket.on("reload", this.onReloadEvent);
        this.socket.on("getHistory", this.onGetHistoryEvent);

        // Connect to socket server
        this.socket.connect();
    }

    disconnect() {
        if (this.disconnectionTimeout) {
            clearTimeout(this.disconnectionTimeout);
            this.disconnectionTimeout = null;
        }

        if (this.socket) {
            useChatStore.getState().setConnectionStatus("disconnected");
            this.socket.disconnect();
        }
    }

    reconnect() {
        if (this.socket) {
            useChatStore.getState().setConnectionStatus("connecting");
            this.socket.connect();
        }
    }

    getSocket() {
        return this.socket;
    }

    sendMessage(message: MessageSend) {
        if (!this.socket) {
            return null;
        }
        const store = useChatStore.getState();
        if (!store.chatId) return null;

        store.appendMessages([message]);
        store.setReplyingTo(undefined);
        this.socket.emit("message", { ...message });

        if (message.tempUUID) {
            store.updateMessageStatus(message.tempUUID, "pending");
            store.setMessageConfirmationTimeout(message.tempUUID);
        }

        return message.tempUUID;
    }

    retryMessage(tempUUID?: string) {
        const store = useChatStore.getState();
        const message = store.messages.find(msg => msg.tempUUID === tempUUID);

        if (message && this.socket && tempUUID) {
            // Update message status to sending
            store.updateMessageStatus(tempUUID, "pending");

            // Resend the message
            this.socket.emit("message", { ...message });

            // Set a new confirmation timeout
            store.setMessageConfirmationTimeout(tempUUID);
        }
    }

    deleteMessage(id: number) {
        if (!this.socket) return;
        const store = useChatStore.getState();
        if (!store.chatId) return;
        this.socket.emit("msgDelete", { id });
    }

    requestHistory() {
        if (!this.socket) return;
        const store = useChatStore.getState();
        const { chatId, hasMore, loading, connectionStatus, last } = store;

        if (chatId && hasMore && !loading && connectionStatus === "connected") {
            store.setLoading(true);
            this.socket.emit("getHistory", {
                limit: MESSAGES_PAGE_SIZE,
                last: last,
            });
        }
    }

    private reloadMessages() {
        if (!this.socket) return;
        const store = useChatStore.getState();
        if (!store.chatId) return;

        const newestMessageId = [...store.messages].reverse().find(msg => msg.id)?.id;
        this.socket.emit("reload", { newest: newestMessageId });
    }

    // Event handlers
    private onErrorEvent = (error: Error) => {
        const store = useChatStore.getState();
        store.setConnectionStatus("disconnected");
    };

    private onConnect = () => {
        const store = useChatStore.getState();
        store.setConnectionStatus("connected");

        if (this.disconnectionTimeout) {
            clearTimeout(this.disconnectionTimeout);
            this.disconnectionTimeout = null;
        }

        if (store.messages.length > 0) {
            this.reloadMessages();
        }

        store.setIsDisconnected(false);
    };

    private onDisconnect = () => {
        const store = useChatStore.getState();
        store.setConnectionStatus("disconnected");

        if (this.disconnectionTimeout) {
            clearTimeout(this.disconnectionTimeout);
        }

        this.disconnectionTimeout = setTimeout(() => {
            const store = useChatStore.getState();
            if (store.connectionStatus === "disconnected") {
                store.setIsDisconnected(true);
            }
        }, this.DISCONNECTION_TIMEOUT);
    };

    private onMessageEvent = (message: MessageSend) => {
        const store = useChatStore.getState();

        if (!message.id) return;

        store.appendMessages([message]);
    };

    private onMessageConfirmationEvent = (data: MessageConfirmation) => {
        const store = useChatStore.getState();
        store.confirmMessage(data);
    };

    private onMessageDelete = (data: { id: number; content: string }) => {
        useChatStore.getState().deleteMessage(data.id, data.content);
    };

    private onReloadEvent = (messages: MessagePublic[]) => {
        const store = useChatStore.getState();
        if (!messages.length) return;

        store.appendMessages(messages);
    };

    private onGetHistoryEvent = (messages: MessagePublic[]) => {
        const store = useChatStore.getState();
        const latestMessageId = messages[0]?.id;
        store.setLoading(false);

        if (messages.length < MESSAGES_PAGE_SIZE) {
            store.setHasMore(false);
        }

        if (latestMessageId) {
            store.setLast(latestMessageId);
            store.prependMessages(messages);
        }
    };
}

// Singleton instance
export const chatSocketService = ChatSocketService.getInstance();
