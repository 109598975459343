import { cn } from "@/lib/utils";
import { Time } from "@vidstack/react";
import { Video } from "lucide-react";

interface VideoIndicatorProps {
    variant?: "default" | "minimal";
}

export function VideoIndicator({ variant }: VideoIndicatorProps) {
    return (
        <div
            className={cn(
                "absolute flex items-center gap-1 rounded-xs bg-fill-gray/85 px-1 py-0.5 text-text-on-gray-fill media-buffering:text-text-on-gray-fill/50",
                variant === "minimal" ? "bottom-2 left-2/4 -translate-x-2/4" : "left-2 top-2",
            )}
        >
            <Video className={"size-3.5"} />
            <Time type={"duration"} className={"time text-xs font-medium"} />
        </div>
    );
}
