import { httpErrorHandler } from "@/api/api";
import { usePostLocationMutation } from "@/api/queries/locationsQueries";
import { Button } from "@/components/ui/button/Button";
import {
    DialogBody,
    DialogContent,
    DialogFooter,
    DialogHeader,
    DialogTitle,
    DialogDescription,
    Dialog,
} from "@/components/ui/dialog/Dialog";
import {
    Form,
    FormControl,
    FormField,
    FormItem,
    FormLabel,
    FormMessage,
} from "@/components/ui/form/Form";
import { Input, InputActionButton, InputWrapper } from "@/components/ui/input/Input";
import { Textarea } from "@/components/ui/input/textarea/Textarea";
import { AddLocationSchema, AddLocationSchemaType } from "@/schemas/location.schema";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { LoaderCircle, CheckCircle2, MapPinned, ClipboardPaste } from "lucide-react";
import { FeaturedIcon } from "@/components/ui/featured-icon/FeaturedIcon";
import { toast } from "sonner";

interface LocationAddFormPropsT {
    open: boolean;
    onOpenChange: (value: boolean) => void;
}

export default function CreateLocation({ open, onOpenChange }: LocationAddFormPropsT) {
    const { mutateAsync, isPending } = usePostLocationMutation();

    const onClose = () => {
        onOpenChange(false);
        form.reset();
    };

    const handleClipboardPaste = () => {
        navigator.clipboard.readText().then(text => {
            form.setValue("coordinates", text);
        });
    };

    const form = useForm<AddLocationSchemaType>({
        mode: "onBlur",
        resolver: yupResolver(AddLocationSchema),
    });

    const onSubmit = (data: AddLocationSchemaType) => {
        mutateAsync(data)
            .then(() => {
                onClose();
            })
            .catch(error => {
                const { title, detail } = httpErrorHandler(error);
                toast.error(title, {
                    description: detail,
                });
            });
    };

    return (
        <Dialog open={open} onOpenChange={onOpenChange}>
            <DialogContent
                className={"sm:max-w-148"}
                onCloseAutoFocus={onClose}
                onEscapeKeyDown={onClose}
            >
                <DialogDescription className={"sr-only"}>
                    Formularz do tworzenia lokalizacji
                </DialogDescription>
                <Form {...form}>
                    <DialogBody>
                        {isPending && (
                            <div
                                className={
                                    "absolute inset-0 z-60 m-auto flex flex-wrap items-center justify-center gap-3 bg-surface-primary/75 backdrop-blur-md"
                                }
                            >
                                <LoaderCircle className={"animate-spin"} aria-hidden={"true"} />
                                <h5 className={"font-medium"}>Trwa tworzenie lokalizacji...</h5>
                            </div>
                        )}
                        <DialogHeader className={"border-b border-border-primary"}>
                            <FeaturedIcon variant={"ghost"}>
                                <MapPinned />
                            </FeaturedIcon>
                            <div className={"flex flex-col gap-0.5 min-w-0 grow"}>
                                <DialogTitle>Utwórz lokalizację</DialogTitle>
                                <p className={"text-text-tertiary text-xs"}>
                                    Wprawdź nazwę i współrzędne lokacji.
                                </p>
                            </div>
                        </DialogHeader>
                        <form
                            className={"flex flex-col gap-3 grow p-3 md:p-4 overflow-auto"}
                            onSubmit={form.handleSubmit(onSubmit)}
                            noValidate
                        >
                            <FormField
                                name={"name"}
                                control={form.control}
                                render={({ field }) => (
                                    <FormItem className={"col-span-1"}>
                                        <FormLabel>Nazwa</FormLabel>
                                        <FormControl>
                                            <Input {...field} placeholder={"Nazwa lokacji"} />
                                        </FormControl>
                                        <FormMessage />
                                    </FormItem>
                                )}
                            />
                            <FormField
                                name={"coordinates"}
                                control={form.control}
                                render={({ field }) => (
                                    <FormItem className={"col-span-1"}>
                                        <FormLabel>Lokalizacja</FormLabel>
                                        <FormControl>
                                            <InputWrapper>
                                                <Input
                                                    {...field}
                                                    placeholder={"Podaj lokalizację"}
                                                    className={"rounded-e-none"}
                                                />
                                                <InputActionButton
                                                    className={"-ms-px rounded-s-none"}
                                                    onClick={handleClipboardPaste}
                                                >
                                                    <ClipboardPaste />
                                                </InputActionButton>
                                            </InputWrapper>
                                        </FormControl>
                                        <FormMessage />
                                    </FormItem>
                                )}
                            />

                            <FormField
                                name={"desc"}
                                control={form.control}
                                render={({ field }) => (
                                    <FormItem>
                                        <FormLabel>
                                            Opis{" "}
                                            <small className={"text-sm text-text-tertiary"}>
                                                (opcionalne)
                                            </small>
                                        </FormLabel>
                                        <FormControl>
                                            <Textarea placeholder={"Opis"} {...field} />
                                        </FormControl>
                                        <FormMessage />
                                    </FormItem>
                                )}
                            />
                        </form>
                    </DialogBody>
                    <DialogFooter className={"border-t border-border-primary"}>
                        <Button
                            onClick={onClose}
                            type={"button"}
                            variant={"tertiary"}
                            className={"w-full md:w-fit"}
                        >
                            Anuluj
                        </Button>
                        <Button
                            disabled={isPending}
                            onClick={form.handleSubmit(onSubmit)}
                            type={"submit"}
                            variant={"primary"}
                            className={"w-full md:w-fit"}
                        >
                            {isPending ? (
                                <>
                                    <LoaderCircle className={"animate-spin"} aria-hidden={"true"} />
                                    Zapisywanie...
                                </>
                            ) : (
                                <>
                                    <CheckCircle2 />
                                    Zapisz
                                </>
                            )}
                        </Button>
                    </DialogFooter>
                </Form>
            </DialogContent>
        </Dialog>
    );
}
