"use client";

import { Button } from "@/components/ui/button/Button";
import { Progress } from "@/components/ui/progress/Progress";
import { cn } from "@/lib/utils";
import { FileText } from "lucide-react";
import prettyBytes from "pretty-bytes";
import { FeaturedIcon } from "@/components/ui/featured-icon/FeaturedIcon";

import * as React from "react";

export const FileUploadList = React.forwardRef<
    React.ElementRef<"div">,
    React.ComponentPropsWithoutRef<"div">
>(({ className, ...props }, ref) => (
    <div ref={ref} className={cn("grid gap-4", className)} {...props} />
));
FileUploadList.displayName = "FileUploadList";

export const FileUploadListItem = React.forwardRef<
    React.ElementRef<"div">,
    React.ComponentPropsWithoutRef<"div">
>(({ className, ...props }, ref) => (
    <div
        ref={ref}
        className={cn(
            "grid gap-2 rounded-md border border-border-primary bg-surface-primary p-2 overflow-hidden",
            className,
        )}
        {...props}
    />
));
FileUploadListItem.displayName = "FileUploadListItem";

export const FileUploadListHeader = React.forwardRef<
    React.ElementRef<"div">,
    React.ComponentPropsWithoutRef<"div">
>(({ className, ...props }, ref) => (
    <div ref={ref} className={cn("flex items-center gap-3", className)} {...props} />
));
FileUploadListHeader.displayName = "FileUploadListHeader";

export const FileUploadListIcon = React.forwardRef<
    React.ElementRef<"div">,
    React.ComponentPropsWithoutRef<"div">
>(({ className, children, ...props }, ref) => (
    <FeaturedIcon ref={ref} className={cn(className)} {...props}>
        {children ?? <FileText />}
    </FeaturedIcon>
));
FileUploadListIcon.displayName = "FileUploadListIcon";

export const FileUploadListInfo = React.forwardRef<
    React.ElementRef<"div">,
    React.ComponentPropsWithoutRef<"div">
>(({ className, ...props }, ref) => (
    <div ref={ref} className={cn("grid flex-1 gap-0.5", className)} {...props} />
));
FileUploadListInfo.displayName = "FileUploadListInfo";

export const FileUploadListName = React.forwardRef<
    React.ElementRef<"p">,
    React.ComponentPropsWithoutRef<"p">
>(({ className, ...props }, ref) => (
    <p
        ref={ref}
        className={cn(
            "text-sm text-text-primary truncate whitespace-nowrap font-medium leading-none tracking-tight",
            className,
        )}
        {...props}
    />
));
FileUploadListName.displayName = "FileUploadListName";

export const FileUploadListDescription = React.forwardRef<
    React.ElementRef<"div">,
    React.ComponentPropsWithoutRef<"div">
>(({ className, ...props }, ref) => (
    <div
        ref={ref}
        className={cn(
            "flex items-center gap-1 text-xs text-text-tertiary truncate whitespace-nowrap",
            className,
        )}
        {...props}
    />
));
FileUploadListDescription.displayName = "FileUploadListDescription";

export const FileUploadListDescriptionSeparator = React.forwardRef<
    React.ElementRef<"span">,
    React.ComponentPropsWithoutRef<"span">
>(({ children, ...props }, ref) => (
    <span ref={ref} {...props}>
        {children ?? "•"}
    </span>
));
FileUploadListDescriptionSeparator.displayName = "FileUploadListDescriptionSeparator";

export interface FileUploadListSizeProps extends React.ComponentPropsWithoutRef<"span"> {
    children: number;
}

export const FileUploadListSize = React.forwardRef<
    React.ElementRef<"span">,
    FileUploadListSizeProps
>(({ children, ...props }, ref) => (
    <span ref={ref} {...props}>
        {prettyBytes(children)}
    </span>
));
FileUploadListSize.displayName = "FileUploadListSize";

export const FileUploadListProgress = React.forwardRef<
    React.ElementRef<typeof Progress>,
    React.ComponentPropsWithoutRef<typeof Progress>
>(({ className, ...props }, ref) => (
    <Progress ref={ref} className={cn("h-1", className)} {...props} />
));
FileUploadListProgress.displayName = "FileUploadListProgress";

export const FileUploadListDescriptionText = React.forwardRef<
    React.ElementRef<"span">,
    React.ComponentPropsWithoutRef<"span">
>(({ className, ...props }, ref) => (
    <span
        ref={ref}
        className={cn(
            "flex items-center gap-1 [&>svg]:size-3 truncate whitespace-nowrap",
            className,
        )}
        {...props}
    />
));
FileUploadListDescriptionText.displayName = "FileUploadListDescriptionText";

export const FileUploadListContent = React.forwardRef<
    React.ElementRef<"div">,
    React.ComponentPropsWithoutRef<"div">
>((props, ref) => <div ref={ref} {...props} />);
FileUploadListContent.displayName = "FileUploadListContent";

export const FileUploadListActions = React.forwardRef<
    React.ElementRef<"div">,
    React.ComponentPropsWithoutRef<"div">
>(({ className, ...props }, ref) => (
    <div ref={ref} className={cn("flex items-center gap-1", className)} {...props} />
));
FileUploadListActions.displayName = "FileUploadListActions";

export const FileUploadListAction = React.forwardRef<
    React.ElementRef<typeof Button>,
    React.ComponentPropsWithoutRef<typeof Button>
>(({ className, variant = "ghost", size = "sm", ...props }, ref) => (
    <Button ref={ref} variant={variant} size={size} className={cn("", className)} {...props} />
));
FileUploadListAction.displayName = "FileUploadListAction";
