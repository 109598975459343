"use no memo";

import { Button } from "@/components/ui/button/Button";
import { cn } from "@/lib/utils";
import { useFilesStorage } from "@/store/filesStore";
import { Download, FolderSymlinkIcon, Trash2, X } from "lucide-react";
import { useCallback, useMemo } from "react";

interface FilesManageBarProps {
    className?: string;
}

export default function FilesManageBar({ className }: FilesManageBarProps) {
    const { resetSelected, selected, selectedSize, setOpenDelete, setOpenDownload, setOpenMove } =
        useFilesStorage();

    const size = selectedSize();

    const permissions = useMemo(() => {
        const selectedItems = [...selected.directories, ...selected.files];
        if (selectedItems.length === 0) return { read: false, edit: false, write: false };

        return {
            read: selectedItems.every(el => el.permissions.read),
            edit: selectedItems.every(el => el.permissions.edit),
            write: selectedItems.every(el => el.permissions.write),
        };
    }, [selected]);

    const handleOpenAction = useCallback(
        (action: "move" | "download" | "delete") => () => {
            switch (action) {
                case "move":
                    setOpenMove(true);
                    break;
                case "download":
                    setOpenDownload(true);
                    break;
                case "delete":
                    setOpenDelete(true);
                    break;
            }
        },
        [setOpenMove, setOpenDownload, setOpenDelete],
    );

    if (size === 0) return null;

    return (
        <div
            className={cn(
                "flex w-full items-center rounded-md border border-border-primary bg-surface-secondary hover:bg-surface-secondary/30 p-1",
                className,
            )}
        >
            <Button onClick={resetSelected} size="sm" variant="ghost">
                <X />
            </Button>
            <p className="mx-2 w-14 text-xs font-medium text-text-tertiary whitespace-nowrap">
                Wybrano: {size}
            </p>
            <Button
                onClick={handleOpenAction("move")}
                disabled={!permissions.edit}
                size="sm"
                variant="ghost"
            >
                <FolderSymlinkIcon />
            </Button>
            <Button
                onClick={handleOpenAction("download")}
                disabled={!permissions.read}
                size="sm"
                variant="ghost"
            >
                <Download />
            </Button>
            <Button
                onClick={handleOpenAction("delete")}
                disabled={!permissions.edit && !permissions.write}
                size="sm"
                variant="ghost"
            >
                <Trash2 />
            </Button>
        </div>
    );
}
