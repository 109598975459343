import { differenceInMilliseconds } from "date-fns";

export function timeToHeight(date: Date, parentHeight: number): number {
    const startOfDay = new Date(date.getFullYear(), date.getMonth(), date.getDate());

    const result = differenceInMilliseconds(date, startOfDay);

    const percentOfDay = result / (24 * 60 * 60 * 1000);

    return percentOfDay * parentHeight;
}
