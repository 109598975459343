import { httpErrorHandler } from "@/api/api";
import { useEditLocationMutation, useGetLocationQuery } from "@/api/queries/locationsQueries";
import { Button } from "@/components/ui/button/Button";
import {
    Dialog,
    DialogBody,
    DialogContent,
    DialogFooter,
    DialogHeader,
    DialogTitle,
    DialogDescription,
} from "@/components/ui/dialog/Dialog";
import {
    Form,
    FormControl,
    FormField,
    FormItem,
    FormLabel,
    FormMessage,
} from "@/components/ui/form/Form";
import { Input } from "@/components/ui/input/Input";
import { Textarea } from "@/components/ui/input/textarea/Textarea";
import { EditLocationSchema, EditLocationSchemaType } from "@/schemas/location.schema";
import { LocationPublic } from "@/types";
import { serializeData } from "@/utils/serializeData";
import { yupResolver } from "@hookform/resolvers/yup";
import { useCallback, useEffect } from "react";
import { useForm } from "react-hook-form";
import { LoaderCircle, CheckCircle2, MapPinned } from "lucide-react";
import { FeaturedIcon } from "@/components/ui/featured-icon/FeaturedIcon";
import { toast } from "sonner";

interface LocationEditFormPropsT {
    open: boolean;
    onOpenChange: (value: boolean) => void;
    location: LocationPublic | undefined;
    callback?: () => void;
}

export default function EditLocation({
    open,
    onOpenChange,
    location,
    callback,
}: LocationEditFormPropsT) {
    const {
        data,
        error,
        isError,
        isPending: isPendingQuery,
    } = useGetLocationQuery({ id: location?.id });

    const { mutateAsync, isPending: isPendingMutation } = useEditLocationMutation();

    const form = useForm<EditLocationSchemaType>({
        defaultValues: {
            name: "",
            desc: "",
            coordinates: "",
        },
        values: data && EditLocationSchema.cast(serializeData(data)),
        mode: "onBlur",
        resolver: yupResolver(EditLocationSchema),
    });

    const onSubmit = (data: EditLocationSchemaType) => {
        mutateAsync(data)
            .then(() => {
                onClose();
            })
            .catch(error => {
                const { title, detail } = httpErrorHandler(error);
                toast.error(title, {
                    description: detail,
                });
            });
    };

    const onClose = useCallback(() => {
        onOpenChange(false);
        callback?.();
        form.reset();
    }, [onOpenChange, callback, form]);

    useEffect(() => {
        if (isError) {
            const { title, detail } = httpErrorHandler(error);
            toast.error(title, {
                description: detail,
            });
            onClose();
        }
    }, [error, isError, onOpenChange, toast, onClose]);

    return (
        <Dialog open={open} onOpenChange={onOpenChange}>
            <DialogContent
                onCloseAutoFocus={onClose}
                onEscapeKeyDown={onClose}
                className={"md:max-w-148"}
            >
                <DialogDescription className={"sr-only"}>
                    Formularz do edycji lokalizacji
                </DialogDescription>
                <Form {...form}>
                    <DialogBody>
                        {isPendingQuery && (
                            <div
                                className={
                                    "absolute inset-0 z-60 m-auto flex flex-wrap items-center justify-center gap-3 bg-surface-primary/75 backdrop-blur-md"
                                }
                            >
                                <LoaderCircle className={"animate-spin"} aria-hidden={"true"} />
                                <h5 className={"font-medium"}>Trwa ładowanie danych...</h5>
                            </div>
                        )}
                        {isPendingMutation && (
                            <div
                                className={
                                    "absolute inset-0 z-60 m-auto flex flex-wrap items-center justify-center gap-3 bg-surface-primary/75 backdrop-blur-md"
                                }
                            >
                                <LoaderCircle className={"animate-spin"} aria-hidden={"true"} />
                                <h5 className={"font-medium"}>Trwa edycja danych...</h5>
                            </div>
                        )}
                        <DialogHeader className={"border-b border-border-primary"}>
                            <FeaturedIcon variant={"ghost"}>
                                <MapPinned />
                            </FeaturedIcon>
                            <div className={"flex flex-col gap-0.5 min-w-0 grow"}>
                                <DialogTitle>Edytuj lokalizację</DialogTitle>
                                <p className={"text-text-tertiary text-xs"}>
                                    Wprawdź nazwę i współrzędne lokacji.
                                </p>
                            </div>
                        </DialogHeader>
                        <form
                            className={"flex flex-col gap-3 grow p-3 md:p-4 overflow-auto"}
                            onSubmit={form.handleSubmit(onSubmit)}
                            noValidate
                        >
                            <FormField
                                name={"name"}
                                control={form.control}
                                render={({ field }) => (
                                    <FormItem className={"col-span-1"}>
                                        <FormLabel>Nazwa</FormLabel>
                                        <FormControl>
                                            <Input {...field} placeholder={"Nazwa lokacji"} />
                                        </FormControl>
                                        <FormMessage />
                                    </FormItem>
                                )}
                            />
                            <FormField
                                name={"coordinates"}
                                control={form.control}
                                render={({ field }) => (
                                    <FormItem className={"col-span-1"}>
                                        <FormLabel>Lokalizacja</FormLabel>
                                        <FormControl>
                                            <Input {...field} placeholder={"Podaj lokajizację"} />
                                        </FormControl>
                                        <FormMessage />
                                    </FormItem>
                                )}
                            />
                            <FormField
                                name={"desc"}
                                control={form.control}
                                render={({ field }) => (
                                    <FormItem>
                                        <FormLabel>
                                            Opis{" "}
                                            <small className={"text-sm text-text-tertiary"}>
                                                (opcionalne)
                                            </small>
                                        </FormLabel>
                                        <FormControl>
                                            <Textarea placeholder={"Opis"} {...field} />
                                        </FormControl>
                                        <FormMessage />
                                    </FormItem>
                                )}
                            />
                        </form>
                    </DialogBody>
                    <DialogFooter className={"border-t border-border-primary"}>
                        <Button
                            onClick={onClose}
                            type={"button"}
                            variant={"tertiary"}
                            className={"w-full md:w-fit"}
                        >
                            Anuluj
                        </Button>
                        <Button
                            disabled={isPendingMutation}
                            onClick={form.handleSubmit(onSubmit)}
                            type={"submit"}
                            variant={"primary"}
                            className={"w-full md:w-fit"}
                        >
                            {isPendingMutation ? (
                                <>
                                    <LoaderCircle className={"animate-spin"} aria-hidden={"true"} />
                                    Zapisywanie...
                                </>
                            ) : (
                                <>
                                    <CheckCircle2 />
                                    Zapisz
                                </>
                            )}
                        </Button>
                    </DialogFooter>
                </Form>
            </DialogContent>
        </Dialog>
    );
}
