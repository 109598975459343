import { ReactNode, createContext, useContext, HTMLAttributes, forwardRef } from "react";
import { Separator } from "@/components/ui/separator/Separator";
import { Skeleton } from "@/components/ui/skeleton/Skeleton";
import { cn } from "@/lib/utils";
import { cva, VariantProps } from "class-variance-authority";

interface Identifiable {
    id: number;
}

interface SelectListProps<T extends Identifiable> extends HTMLAttributes<HTMLUListElement> {
    children: ReactNode | ReactNode[] | null;
    selected: T[];
    setSelected: (data: T[]) => void;
}

interface SelectListContextT<T extends Identifiable> {
    selected: T[];
    unselectElement: (elementId: number | string) => void;
    selectElement: (element: T) => void;
    isSelected: (id: number) => boolean;
}

const SelectListContext = createContext<SelectListContextT<any>>({
    selected: [],
    unselectElement: () => {},
    selectElement: () => {},
    isSelected: () => false,
});

const SelectList = <T extends Identifiable>({
    children,
    selected,
    setSelected,
    className,
    ...props
}: SelectListProps<T>) => {
    const selectElement = (element: T) => {
        setSelected([...selected, element]);
    };
    const unselectElement = (id: number | string) => {
        const newSelected: T[] = selected.filter((element: T) => {
            return element.id !== id;
        });
        setSelected(newSelected);
    };

    const isSelected = (id: number): boolean => {
        return selected.some((element: T) => {
            return element.id === id;
        });
    };

    return (
        <SelectListContext.Provider
            value={{
                selected,
                unselectElement,
                selectElement,
                isSelected,
            }}
        >
            <ul className={cn("w-full", className)} {...props}>
                {children}
            </ul>
        </SelectListContext.Provider>
    );
};

const selectListElementVariants = cva(
    "group flex p-2 h-fit items-center rounded-sm [&>svg]:shrink-0 [&>svg]:stroke-icon-tertiary",
    {
        variants: {
            size: {
                default: "gap-2 [&>svg]:size-4",
                double: "gap-3 [&>svg]:size-5",
            },
        },
        defaultVariants: {
            size: "default",
        },
    },
);
interface SelectListElementProps
    extends HTMLAttributes<HTMLLIElement>,
        VariantProps<typeof selectListElementVariants> {
    className?: string;
}
const SelectListElement = forwardRef<HTMLLIElement, SelectListElementProps>(
    ({ size, className, ...props }, ref) => {
        return (
            <li
                ref={ref}
                className={cn(selectListElementVariants({ size, className }))}
                {...props}
            />
        );
    },
);
SelectListElement.displayName = "SelectListElement";

const SelectListElementContent = forwardRef<HTMLDivElement, HTMLAttributes<HTMLDivElement>>(
    ({ className, ...props }, ref) => {
        return (
            <div
                ref={ref}
                className={cn("flex flex-col gap-0.5 overflow-hidden w-full", className)}
                {...props}
            />
        );
    },
);
SelectListElementContent.displayName = "SelectListElementContent";

const SelectListElementTitle = forwardRef<HTMLDivElement, HTMLAttributes<HTMLDivElement>>(
    ({ className, ...props }, ref) => {
        return (
            <p
                ref={ref}
                className={cn("truncate text-sm text-text-secondary", className)}
                {...props}
            />
        );
    },
);
SelectListElementTitle.displayName = "SelectListElementTitle";

const SelectListElementDescription = forwardRef<HTMLDivElement, HTMLAttributes<HTMLDivElement>>(
    ({ className, ...props }, ref) => {
        return (
            <p
                ref={ref}
                className={cn("truncate text-xs text-text-tertiary", className)}
                {...props}
            />
        );
    },
);
SelectListElementDescription.displayName = "SelectListElementDescription";

const SelectListElementSkeleton = forwardRef<HTMLLIElement, HTMLAttributes<HTMLLIElement>>(
    ({ className, ...props }, ref) => {
        return (
            <li
                ref={ref}
                className={cn("flex h-fit items-center gap-3 rounded-sm py-2", className)}
                {...props}
            >
                <Skeleton className={"h-10 w-10 rounded-sm"} />
                <div className={"flex flex-col gap-1"}>
                    <Skeleton className={"h-3 w-28 rounded-sm"} />
                    <Skeleton className={"h-3 w-16 rounded-sm"} />
                </div>
            </li>
        );
    },
);
SelectListElementSkeleton.displayName = "SelectListElementSkeleton";

interface SelectDataListElementSeparatorProps extends HTMLAttributes<HTMLLIElement> {
    message?: string;
}
const SelectListSeparator = forwardRef<HTMLLIElement, SelectDataListElementSeparatorProps>(
    ({ className, message = "Wyszukiwane", ...props }, ref) => {
        return (
            <li ref={ref} className={cn("my-2", className)} {...props}>
                <p className="text-xs text-text-tertiary">{message}</p>
                <Separator className="mt-0.5" />
            </li>
        );
    },
);
SelectListSeparator.displayName = "SelectListSeparator";

const useSelectListContext = () => {
    return useContext(SelectListContext);
};

export {
    useSelectListContext,
    SelectList,
    SelectListElement,
    SelectListElementSkeleton,
    SelectListSeparator,
    SelectListElementContent,
    SelectListElementDescription,
    SelectListElementTitle,
};
export type { Identifiable };
