import { cn } from "@/lib/utils";

interface SettingOptionHeaderProps {
    title: string;
    description: string;
    className?: string;
}

export default function SettingOptionHeader({
    title,
    description,
    className,
}: SettingOptionHeaderProps) {
    return (
        <div className={cn("flex flex-col gap-1", className)}>
            <h5
                className={
                    "w-full overflow-hidden text-ellipsis whitespace-nowrap text-md font-medium text-text-primary"
                }
            >
                {title}
            </h5>
            <p
                className={
                    "overflow-hidden text-ellipsis whitespace-normal text-sm text-text-tertiary"
                }
            >
                {description}
            </p>
        </div>
    );
}
