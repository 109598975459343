import { InferType, array, boolean, number, object, string } from "yup";
import { MakeOptionalExcept } from "@/types";

const GroupAddSchema = object({
    name: string().required("Nazwa jest wymagana"),
    desc: string().optional(),
});

type GroupAddSchemaType = InferType<typeof GroupAddSchema>;

const GroupMemberChangesSchema = object({
    userIds: array().of(number()).optional(),
    groupIds: array().of(number()).optional(),
    add: boolean().optional(),
    remove: boolean().optional(),
});
type GroupMemberChangesSchemaType = InferType<typeof GroupMemberChangesSchema>;

const GroupEditSchema = object({
    id: number().required(),
    name: string().required("Nazwa jest wymagana"),
    desc: string().optional(),
    memberChanges: array().of(GroupMemberChangesSchema).optional(),
});

type GroupEditSchemaType = InferType<typeof GroupEditSchema>;
type GroupEditType = MakeOptionalExcept<GroupEditSchemaType, "id">;

export { GroupAddSchema, GroupEditSchema };
export type {
    GroupAddSchemaType,
    GroupEditSchemaType,
    GroupEditType,
    GroupMemberChangesSchemaType,
};
