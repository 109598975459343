import { Button } from "@/components/ui/button/Button";
import { ButtonGroup, ButtonGroupButton } from "@/components/ui/button/ButtonGroup";
import {
    AlertDialog,
    AlertDialogAction,
    AlertDialogBody,
    AlertDialogCancel,
    AlertDialogContent,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogTitle,
    AlertDialogTrigger,
} from "@/components/ui/dialog/AlertDialog";
import { FeaturedIcon } from "@/components/ui/featured-icon/FeaturedIcon";
import { cn } from "@/lib/utils";
import { LocationPublic } from "@/types";
import {
    APIProvider,
    Map,
    MapCameraChangedEvent,
    MapCameraProps,
    Marker,
} from "@vis.gl/react-google-maps";
import { Compass, Link2, MapIcon } from "lucide-react";
import { useCallback, useMemo, useState } from "react";
import { Link } from "react-router";

interface LocationCardProps {
    location: LocationPublic;
    className?: string;
    staticMap?: boolean;
}

export default function LocationCard({
    location,
    className,
    staticMap = false,
}: LocationCardProps) {
    const { name, desc, coordinates } = location;

    const { lat, lng } = useMemo(() => {
        const [lat, Ing] = coordinates.split(",");
        return { lat: Number(lat), lng: Number(Ing) };
    }, [coordinates]);

    const [cameraProps, setCameraProps] = useState<MapCameraProps>({
        zoom: 15,
        center: { lat, lng },
    });
    const handleCameraChange = useCallback(
        (ev: MapCameraChangedEvent) => {
            if (staticMap) return;
            setCameraProps(ev.detail);
        },
        [staticMap],
    );

    const handleGoBackToPoint = () => {
        if (staticMap) {
            return;
        }
        setCameraProps({
            zoom: 15,
            center: { lat, lng },
        });
    };

    return (
        <div
            className={cn(
                "relative h-full w-full overflow-hidden rounded-md border border-border-primary bg-surface-primary",
                className,
            )}
        >
            <APIProvider apiKey={import.meta.env.VITE_GOOGLE_API_KEY}>
                <Map
                    defaultCenter={{ lat, lng }}
                    defaultZoom={15}
                    gestureHandling={"greedy"}
                    disableDefaultUI={true}
                    {...cameraProps}
                    onCameraChanged={handleCameraChange}
                    style={{ height: "calc(100% - var(--spacing)*14)" }}
                >
                    <Marker position={{ lat, lng }} />
                </Map>
            </APIProvider>
            <div
                className={
                    "flex justify-between h-14 p-2 md:p-3 items-center w-full border-t border-border-primary"
                }
            >
                <div>
                    <h5 className={"truncate font-medium"}>{name}</h5>
                    <p className={"truncate text-xs text-text-tertiary empty:hidden"}>{desc}</p>
                </div>
                {staticMap ? (
                    <Link to={`https://www.google.com/maps/place/${coordinates}`} target="_blank">
                        <Button variant={"linkPrimary"} size={"sm"}>
                            Otwórz Mapę <Link2 />
                        </Button>
                    </Link>
                ) : (
                    <ButtonGroup>
                        <AlertDialog>
                            <AlertDialogTrigger asChild>
                                <ButtonGroupButton size={"sm"}>
                                    <MapIcon />
                                </ButtonGroupButton>
                            </AlertDialogTrigger>
                            <AlertDialogContent className={"md:max-w-96"}>
                                <AlertDialogBody>
                                    <AlertDialogHeader>
                                        <FeaturedIcon variant={"ghost"}>
                                            <MapIcon />
                                        </FeaturedIcon>
                                        <div
                                            className={
                                                "flex flex-col gap-0.5 min-w-0 grow overflow-hidden"
                                            }
                                        >
                                            <AlertDialogTitle>
                                                Chcesz przejść do strony Google Maps?
                                            </AlertDialogTitle>
                                            <p className={"text-text-tertiary text-xs"}>
                                                Przejdź do strony google maps aby dokładnie
                                                sprawdzić lokalizację.
                                            </p>
                                        </div>
                                    </AlertDialogHeader>
                                </AlertDialogBody>
                                <AlertDialogFooter>
                                    <AlertDialogCancel>Anuluj</AlertDialogCancel>
                                    <Link
                                        to={`https://www.google.com/maps/place/${coordinates}`}
                                        target={"_blank"}
                                    >
                                        <AlertDialogAction>
                                            <MapIcon /> Przejdź do map
                                        </AlertDialogAction>
                                    </Link>
                                </AlertDialogFooter>
                            </AlertDialogContent>
                        </AlertDialog>
                        <ButtonGroupButton
                            size={"sm"}
                            disabled={!location}
                            onClick={handleGoBackToPoint}
                        >
                            <Compass />
                        </ButtonGroupButton>
                    </ButtonGroup>
                )}
            </div>
        </div>
    );
}
