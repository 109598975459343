import { httpErrorHandler } from "@/api/api";
import { useInfiniteQueryResult } from "@/api/api-utils";
import { getGroupPrefetch, useGetAssignedGroupsInfiniteQuery } from "@/api/queries/groupsQueries";
import { GroupCardSkeleton, GroupCard } from "@/components/features/groups/GroupCard";
import GroupsHeader from "@/components/features/groups/layout/GroupsHeader";
import { InfiniteScroll } from "@/components/ui/infinite-scroll/InfiniteScroll";
import { toast } from "sonner";
import { useDebounceValue } from "@/hooks/useDebounceValue";
import { GroupPrivate } from "@/types";
import { Fragment, useEffect, useState } from "react";
import {
    EmptyState,
    EmptyStateContent,
    EmptyStateTitle,
    EmptyStateDescription,
    EmptyStateCircles,
} from "@/components/ui/empty-state/EmptyState";
import { FeaturedIcon } from "@/components/ui/featured-icon/FeaturedIcon";
import { Users } from "lucide-react";
import { Button } from "@/components/ui/button/Button";

export default function GroupsPage() {
    const [searchValue, setSearchValue] = useState<string>("");
    const searchDebouncedValue = useDebounceValue(searchValue, 400);

    const {
        data,
        isLoading,
        isFetching,
        isSuccess,
        isError,
        error,
        fetchNextPage,
        hasNextPage,
        refetch,
    } = useGetAssignedGroupsInfiniteQuery({
        name: searchDebouncedValue,
    });

    const { data: groups, isEmpty: groupsIsEmpty } = useInfiniteQueryResult(
        data,
        searchDebouncedValue,
    );

    const next = async () => {
        await fetchNextPage();
    };

    const prefetch = async (id: number, groupData: GroupPrivate) => {
        getGroupPrefetch({ id: id, initialData: groupData });
    };

    useEffect(() => {
        if (isError) {
            const { title, detail } = httpErrorHandler(error);
            toast.error(title, {
                description: detail,
                action: (
                    <Button
                        size={"sm"}
                        className={"ml-auto"}
                        variant={"tertiary"}
                        onClick={() => {
                            void refetch();
                        }}
                    >
                        Ponów
                    </Button>
                ),
            });
        }
    }, [isError, error, refetch]);

    return (
        <div className={"base-direction-col"}>
            <GroupsHeader onSearchChange={setSearchValue} searchValue={searchValue} />
            {isSuccess && groupsIsEmpty && (
                <EmptyState className="w-full">
                    <EmptyStateCircles />
                    <FeaturedIcon size={"lg"}>
                        <Users />
                    </FeaturedIcon>
                    <EmptyStateContent>
                        <EmptyStateTitle>Brak Grup</EmptyStateTitle>
                        <EmptyStateDescription>
                            Nie jesteś przypisany do żadnej z grup
                        </EmptyStateDescription>
                    </EmptyStateContent>
                </EmptyState>
            )}
            <div
                className={
                    "relative w-full grid grid-cols-[repeat(auto-fill,minmax(18rem,1fr))] gap-3 md:gap-4 grid-rows-[auto] "
                }
            >
                {isLoading && (
                    <Fragment>
                        <GroupCardSkeleton />
                        <GroupCardSkeleton />
                        <GroupCardSkeleton />
                        <GroupCardSkeleton />
                        <GroupCardSkeleton />
                    </Fragment>
                )}
                {groups.map(group => (
                    <GroupCard
                        key={group.id}
                        group={group}
                        onFocus={() => void prefetch(group.id, group)}
                        onMouseEnter={() => void prefetch(group.id, group)}
                    />
                ))}
                <InfiniteScroll
                    hasNextPage={hasNextPage}
                    isLoading={isLoading}
                    isFetching={isFetching}
                    next={next}
                    threshold={0.65}
                    root={null}
                >
                    {hasNextPage && !isError && <GroupCardSkeleton />}
                </InfiniteScroll>
            </div>
        </div>
    );
}
