import * as Buttons from "@/components/ui/media-viewer/Buttons";
import * as Sliders from "@/components/ui/media-viewer/Slider";
import { TimeGroup } from "@/components/ui/media-viewer/TimeGroup";
import { cn } from "@/lib/utils";
import { Controls } from "@vidstack/react";
import { motion } from "framer-motion";
import { Fragment } from "react";

// Offset tooltips/menus/slider previews in the lower controls group so they're clearly visible.

interface AudioLayoutProps {
    variant?: "default" | "minimal";
}

export function AudioLayout({ variant }: AudioLayoutProps) {
    return (
        <>
            <Controls.Root
                className={cn(
                    "flex h-full w-full flex-col",
                    variant === "minimal" && "items-center justify-center",
                )}
            >
                {variant === "default" ? (
                    <Fragment>
                        <motion.div drag={false}>
                            <Controls.Group className={"flex w-full items-center px-2"}>
                                <Sliders.Time />
                            </Controls.Group>
                        </motion.div>
                        <Controls.Group className={"-mt-0.5 flex w-full items-center px-2 pb-2"}>
                            <Buttons.Play />
                            <Buttons.Mute />
                            <TimeGroup />
                        </Controls.Group>
                    </Fragment>
                ) : (
                    <Controls.Group>
                        <Buttons.Play />
                    </Controls.Group>
                )}
            </Controls.Root>
        </>
    );
}
