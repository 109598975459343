import { cn } from "@/lib/utils";
import * as RadioGroupPrimitive from "@radix-ui/react-radio-group";
import { VariantProps, cva } from "class-variance-authority";
import { Circle } from "lucide-react";
import * as React from "react";

const RadioGroup = React.forwardRef<
    React.ElementRef<typeof RadioGroupPrimitive.Root>,
    React.ComponentPropsWithoutRef<typeof RadioGroupPrimitive.Root>
>(({ className, ...props }, ref) => {
    return (
        <RadioGroupPrimitive.Root className={cn("grid gap-3", className)} {...props} ref={ref} />
    );
});
RadioGroup.displayName = RadioGroupPrimitive.Root.displayName;

const RadioGroupItemVariant = cva(
    "border-border-primary text-primary focus-visible:outline-2 outline-0 outline-ring-focus aria-invalid:outline-ring-focus-destructive/25 aria-invalid:border-border-destructive aspect-square size-4 shrink-0 rounded-full border shadow-xs transition-[color,box-shadow] disabled:cursor-not-allowed disabled:opacity-50",
    {
        variants: {
            colorVariant: {
                default: "data-[state=checked]:bg-fill-brand",
                brand: "data-[state=checked]:bg-fill-brand",
                accent: "data-[state=checked]:bg-fill-accent",
                blue: "data-[state=checked]:bg-fill-blue",
                turquoise: "data-[state=checked]:bg-fill-turquoise",
                purple: "data-[state=checked]:bg-fill-purple",
            },
            size: {
                sm: "size-5",
                md: "size-6",
                lg: "size-7",
            },
        },
        defaultVariants: {
            colorVariant: "default",
            size: "md",
        },
    },
);

interface RadioGroupItemProps
    extends React.ComponentPropsWithoutRef<typeof RadioGroupPrimitive.Item>,
        VariantProps<typeof RadioGroupItemVariant> {
    className?: string;
}

const RadioGroupItem = React.forwardRef<
    React.ElementRef<typeof RadioGroupPrimitive.Item>,
    RadioGroupItemProps
>(({ className, colorVariant, ...props }, ref) => {
    return (
        <RadioGroupPrimitive.Item
            ref={ref}
            className={cn(RadioGroupItemVariant({ colorVariant }), className)}
            {...props}
        >
            <RadioGroupPrimitive.Indicator className={"flex items-center justify-center"}>
                <Circle className="fill-fill-primary stroke-none h-[65%] w-[65%]" />
            </RadioGroupPrimitive.Indicator>
        </RadioGroupPrimitive.Item>
    );
});
RadioGroupItem.displayName = RadioGroupPrimitive.Item.displayName;

export { RadioGroup, RadioGroupItem };
