import { BufferingIndicator } from "@/components/ui/media-viewer/BufferingIndicator";
import * as Buttons from "@/components/ui/media-viewer/Buttons";
import * as Sliders from "@/components/ui/media-viewer/Slider";
import { VideoIndicator } from "@/components/ui/media-viewer/VideoIndicator";
import { cn } from "@/lib/utils";
import { Controls, Gesture } from "@vidstack/react";
import { Fragment } from "react";

// Offset tooltips/menus/slider previews in the lower controls group so they're clearly visible.

export interface VideoLayoutProps {
    thumbnails?: string;
    variant?: "default" | "minimal";
}

export function VideoLayout({ thumbnails, variant }: VideoLayoutProps) {
    return (
        <>
            <VideoIndicator variant={variant} />
            <BufferingIndicator />
            {variant === "minimal" ? null : (
                <Controls.Root
                    className={cn(
                        "absolute inset-0 z-10 flex h-full w-full flex-col transition-opacity media-controls:opacity-100",
                    )}
                >
                    <div className={"flex-1"} />
                    <Controls.Group className={"flex w-full items-center px-2"}>
                        <Sliders.Time thumbnails={thumbnails} />
                    </Controls.Group>
                    <Controls.Group className={"-mt-0.5 flex w-full items-center px-2 pb-2"}>
                        <Buttons.Play />
                        <Buttons.Mute />
                        <div className={"flex-1"} />
                        <Buttons.Fullscreen />
                    </Controls.Group>
                </Controls.Root>
            )}
        </>
    );
}

interface GesturesProps {
    variant?: "default" | "minimal";
}

export function Gestures({ variant }: GesturesProps) {
    return (
        <Fragment>
            {variant === "default" && (
                <Fragment>
                    <Gesture
                        className="absolute inset-0 z-0 block h-full w-full"
                        event="dblpointerup"
                        action="toggle:fullscreen"
                    />
                    <Gesture
                        className="absolute left-0 top-0 z-10 block h-full w-1/5"
                        event="dblpointerup"
                        action="seek:-10"
                    />
                    <Gesture
                        className="absolute right-0 top-0 z-10 block h-full w-1/5"
                        event="dblpointerup"
                        action="seek:10"
                    />
                </Fragment>
            )}
            <Gesture
                className="absolute inset-0 z-0 block h-full w-full"
                event="pointerup"
                action="toggle:paused"
            />
        </Fragment>
    );
}
