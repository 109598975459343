import { CHAT_MAX_MESSAGE_SIZE } from "@/components/features/chat/layout/Chat";
import { Button } from "@/components/ui/button/Button";
import FileInputCard from "@/components/ui/input/file-input/FileInputCard";
import {
    Tooltip,
    TooltipContent,
    TooltipProvider,
    TooltipTrigger,
} from "@/components/ui/tooltip/Tooltip";
import { ImagePlus, Paperclip, SendHorizonal, X } from "lucide-react";
import { ChangeEvent, KeyboardEvent, useRef, useState } from "react";
import { Textarea } from "@/components/ui/input/textarea/Textarea";
import { v4 as uuidv4 } from "uuid";
import { toast } from "sonner";
import { CHAT_MAX_FILE_SIZE, messageFormProps } from "@/components/features/chat/layout/Chat";
import { useChatStore } from "@/store/chatStore";
import prettyBytes from "pretty-bytes";
interface ChatConversationFooterProps {
    onSubmit: (props: messageFormProps) => void;
}

export default function ChatFooter({ onSubmit }: ChatConversationFooterProps) {
    const attachmentsInputRef = useRef<HTMLInputElement>(null);

    const [text, setText] = useState<string>("");
    const [files, setFiles] = useState<{ id: string; file: File }[]>([]);

    const replyingTo = useChatStore(state => state.replyingTo);
    const connectionStatus = useChatStore(state => state.connectionStatus);

    const disableSubmition =
        connectionStatus === "connecting" || connectionStatus === "disconnected";

    const onCloseReply = () => {
        useChatStore.getState().setReplyingTo(undefined);
    };
    const mediaTrigger = () => {
        if (attachmentsInputRef.current) {
            attachmentsInputRef.current.accept =
                "image/jpeg, image/png, image/gif, image/svg+xml, image/webp, audio/mpeg, audio/ogg, audio/3gp, audio/mp4, audio/webm, audio/flac, audio/object, video/mp4, video/webm, video/3gp, video/ogg, video/avi, video/mpeg";
            attachmentsInputRef.current.click();
        }
    };

    const allFilesTrigger = () => {
        if (attachmentsInputRef.current) {
            attachmentsInputRef.current.accept = "*";
            attachmentsInputRef.current.click();
        }
    };

    const onRemove = (id: string) => {
        setFiles(curr => curr.filter(file => file.id !== id));
    };

    const handleKeyDown = (event: KeyboardEvent<HTMLTextAreaElement>) => {
        if (event.key === "Enter" && event.shiftKey) {
            return event;
        } else if (event.key === "Enter") {
            event.preventDefault();
            handleOnSubmit();
        }
    };

    const onValueChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
        const text = event.target.value;
        if (text.trim().length > CHAT_MAX_MESSAGE_SIZE) {
            toast(`Wiadomość jest za długa,  ${text.trim().length}/2056 znaków`);
        }
        setText(text);
    };

    const onFliesChange = async (e: ChangeEvent<HTMLInputElement>) => {
        if (!e.target.files) {
            return;
        }
        const newFiles = Array.from(e.target.files).map(file => {
            if (file.size > CHAT_MAX_FILE_SIZE) {
                toast.error("Błąd", {
                    description: "Maksymalny rozmiar pliku to 500MB",
                });
            }
            return {
                id: uuidv4(),
                file,
            };
        });
        setFiles(prevFiles => [...prevFiles, ...newFiles]);
        return e.target.files;
    };

    const handleOnSubmit = () => {
        if (!text && !files.length) {
            return;
        }
        onSubmit({
            content: text.trim(),
            files: files.map(file => file.file),
            replyingTo: replyingTo,
        });

        setText("");
        setFiles([]);
        onCloseReply();
    };

    return (
        <footer className="space-y-2 p-3 md:p-4 border-t border-border-primary">
            {replyingTo && (
                <div className={"flex gap-2"}>
                    <span className={"shrink-0 text-sm text-text-tertiary"}>Odpowiedz:</span>
                    <p
                        className={
                            "w-full line-clamp-3 whitespace-pre-line text-text-tertiary text-sm break-words"
                        }
                    >
                        {replyingTo.content}
                    </p>
                    <Button
                        size={"sm"}
                        variant={"ghost"}
                        className={"h-8 min-h-8 w-8 min-w-8"}
                        onClick={onCloseReply}
                    >
                        <X />
                    </Button>
                </div>
            )}
            <div className={"flex gap-1 overflow-x-auto empty:hidden"}>
                <TooltipProvider delayDuration={200}>
                    {files.map(({ id, file }) => (
                        <Tooltip key={id}>
                            <TooltipTrigger asChild>
                                <FileInputCard
                                    className={
                                        "h-14 min-h-14 w-14 min-w-14 items-start [&_span]:hidden [&_svg]:size-3 [&_p]:text-[8px]"
                                    }
                                    file={file}
                                    onRemove={() => onRemove(id)}
                                />
                            </TooltipTrigger>
                            <TooltipContent className={"max-w-64"}>
                                <p className={"truncate text-xs text-text-tertiary"}>{file.name}</p>
                                <p className={"truncate text-xs text-text-tertiary"}>
                                    {prettyBytes(file.size)}
                                </p>
                            </TooltipContent>
                        </Tooltip>
                    ))}
                </TooltipProvider>
            </div>
            <div className={"relative"}>
                <Textarea
                    autoFocus
                    onKeyDown={handleKeyDown}
                    placeholder={"Wiadomość...."}
                    value={text}
                    onChange={onValueChange}
                    className={
                        "field-sizing-content min-w-full max-h-20 min-h-10 ring-offset-none resize-none rounded-none border-none p-0 hover:bg-transparent focus-visible:outline-hidden focus-visible:outline-0 focus-visible:outline-offset-0"
                    }
                />
            </div>
            <div className={"flex gap-2"}>
                <input
                    ref={attachmentsInputRef}
                    onChange={onFliesChange}
                    multiple
                    className={"hidden"}
                    type={"file"}
                />
                <Button type={"button"} variant={"outline"} onClick={mediaTrigger}>
                    <ImagePlus />
                </Button>
                <Button type={"button"} variant={"outline"} onClick={allFilesTrigger}>
                    <Paperclip />
                </Button>
                <Button
                    className={"ml-auto"}
                    variant={"secondary"}
                    disabled={disableSubmition}
                    onClick={handleOnSubmit}
                >
                    Wyślij
                    <SendHorizonal className="transition-transform group-hover:translate-x-0.5" />
                </Button>
            </div>
        </footer>
    );
}
