import { axiosInstance } from "@/api/api";
import { PostAddSchemaType } from "@/schemas/post.schema";
import { PostEditSchemaType } from "@/schemas/post.schema";
import type { PostPublic } from "@/types";
import type { PaginationWithSortI } from "@/types";

export const getPosts = async (): Promise<PostPublic[]> => {
    return await axiosInstance.get("/api/post/getAll");
};

export const getAssignedPosts = async (params?: PaginationWithSortI): Promise<PostPublic[]> => {
    return await axiosInstance.get("/api/post/getAssigned", { params: params });
};

export const getPost = async (id?: number): Promise<PostPublic> => {
    return await axiosInstance.get("/api/post", { params: { id } });
};

export const postPost = async (data: PostAddSchemaType): Promise<PostPublic> => {
    const form = new FormData();

    form.append("name", data.name);
    form.append("active", String(data.active));
    if (data.content) {
        form.append("content", data.content);
    }

    if (data.attachments) {
        for (let i = 0; i < data.attachments.length; i++) {
            form.append("attachments", data.attachments[i]);
        }
    }

    return await axiosInstance.post("/api/post", form, {
        headers: { "content-type": "multipart/form-data" },
    });
};

export const editPost = async (data: PostEditSchemaType): Promise<PostPublic> => {
    const form = new FormData();

    form.append("id", String(data.id));
    if (data.name) {
        form.append("name", data.name);
    }
    if (typeof data.active !== "undefined") {
        form.append("active", String(data.active));
    }
    if (data.content) {
        form.append("content", data.content);
    }
    if (data.attachments) {
        for (let i = 0; i < data.attachments.length; i++) {
            form.append("attachments", data.attachments[i]);
        }
    }

    return await axiosInstance.put("/api/post", form);
};

export const deletePost = async (id: number): Promise<string> => {
    return await axiosInstance.delete("/api/post", { data: { id: id } });
};
