import type { FilePublic } from "@/types";

const imageMimeTypes = ["image/jpeg", "image/png", "image/gif", "image/svg+xml", "image/webp"];

const videoMimeTypes = [
    "video/mp4",
    "video/webm",
    "video/3gp",
    "video/ogg",
    "video/avi",
    "video/mpeg",
];

const audioMimeTypes = [
    "audio/mpeg",
    "audio/ogg",
    "audio/3gp",
    "audio/mp4",
    "audio/webm",
    "audio/flac",
    "audio/object",
];

const applicationMimeTypes = ["application/pdf"];

enum FileTypeE {
    IMAGE = "image",
    VIDEO = "video",
    AUDIO = "audio",
    APPLICATION = "application",
    FILE = "file",
}
type MediaFileType = Exclude<FileTypeE, FileTypeE.FILE>;

const mimeTypeGroups: Record<MediaFileType, string[]> = {
    [FileTypeE.IMAGE]: imageMimeTypes,
    [FileTypeE.VIDEO]: videoMimeTypes,
    [FileTypeE.AUDIO]: audioMimeTypes,
    [FileTypeE.APPLICATION]: applicationMimeTypes,
};

interface getServeFileTypeOptions {
    omit?: MediaFileType[];
    pick?: MediaFileType[];
}

function getServeFileType(mimeType: string, options?: getServeFileTypeOptions) {
    const { omit = [], pick = [] } = options || {};

    for (const [fileType, mimeTypes] of Object.entries(mimeTypeGroups)) {
        if (mimeTypes.includes(mimeType)) {
            if (pick.length > 0 && !pick.includes(fileType as MediaFileType)) {
                continue;
            }
            if (omit.length > 0 && omit.includes(fileType as MediaFileType)) {
                continue;
            }
            return fileType as FileTypeE;
        }
    }

    return FileTypeE.FILE;
}

function getMimeTypeStructure(mimeType: string) {
    const [type, subtypeAndParams] = mimeType.split("/");
    const [subtype, ...paramsArray] = subtypeAndParams.split(";");
    return { type, subtype, params: paramsArray };
}

function getFileType(file?: File | FilePublic, options?: getServeFileTypeOptions) {
    if (!file) {
        return { mimeType: "", serveType: FileTypeE.FILE, type: "", subtype: "", params: [] };
    }

    const isFileInstance = file instanceof File;
    const mimeType = isFileInstance ? file.type : file.fileType;

    const mimeTypeStruct = getMimeTypeStructure(mimeType);
    const serveType = getServeFileType(mimeType, options);

    return { mimeType, serveType, ...mimeTypeStruct };
}

export { getServeFileType, getMimeTypeStructure, getFileType, FileTypeE };

export type { MediaFileType };
