import {
    EmptyState,
    EmptyStateCircles,
    EmptyStateContent,
    EmptyStateDescription,
    EmptyStateTitle,
} from "@/components/ui/empty-state/EmptyState";
import { FeaturedIcon } from "@/components/ui/featured-icon/FeaturedIcon";
import { FolderSearch } from "lucide-react";

export default function FilesPageNoResults() {
    return (
        <EmptyState>
            <EmptyStateCircles className={"z-0"} />
            <FeaturedIcon size={"xl"} className={"z-10"}>
                <FolderSearch />
            </FeaturedIcon>
            <EmptyStateContent>
                <EmptyStateTitle>Brak wyników</EmptyStateTitle>
                <EmptyStateDescription>
                    Brak wyników spełniających kryteria wyszukiwania
                </EmptyStateDescription>
            </EmptyStateContent>
        </EmptyState>
    );
}
