import { useState, useCallback } from "react";
import { useFilesStorage } from "@/store/filesStore";
import { DirectoryPublic, FilePublicWithPermissions } from "@/types";

type FSEntityType = "file" | "directory";

interface UseEntityMenusProps<T extends FilePublicWithPermissions | DirectoryPublic> {
    entity: T;
    entityType: FSEntityType;
}

export const useEntityMenus = <T extends FilePublicWithPermissions | DirectoryPublic>({
    entity,
    entityType,
}: UseEntityMenusProps<T>) => {
    const [openDropdownMenu, setOpenDropdownMenu] = useState<boolean>(false);
    const [openContextMenu, setOpenContextMenu] = useState<boolean>(false);
    const { onFSEntityClick } = useFilesStorage();

    const handleOpenDropdownMenu = useCallback(
        (isOpen: boolean) => {
            setOpenDropdownMenu(isOpen);
            if (isOpen) {
                onFSEntityClick({ [entityType]: entity });
            }
        },
        [entity, entityType, onFSEntityClick],
    );

    const handleOpenContextMenu = useCallback(
        (isOpen: boolean) => {
            setOpenContextMenu(isOpen);
            if (isOpen) {
                onFSEntityClick({ [entityType]: entity });
            }
        },
        [entity, entityType, onFSEntityClick],
    );

    return {
        openContextMenu,
        openDropdownMenu,
        handleOpenDropdownMenu,
        handleOpenContextMenu,
    };
};
