"use no memo";

import { Input, InputWrapper } from "@/components/ui/input/Input";
import { useDebounceValue } from "@/hooks/useDebounceValue";
import { cn } from "@/lib/utils";
import { CircleX, Search } from "lucide-react";
import { useEffect, useState } from "react";

interface TanstackTableSearchProps {
    placeholder?: string;
    setGlobalFilter: (value: string) => void;
    className?: string;
}

export default function TanstackTableSearch({
    placeholder = "Wyszykaj...",
    setGlobalFilter,
    className,
}: TanstackTableSearchProps) {
    const [searchValue, setSearchValue] = useState<string>("");
    const searchDebouncedValue = useDebounceValue(searchValue, 400);

    useEffect(() => {
        setGlobalFilter(searchDebouncedValue);
    }, [searchDebouncedValue, setGlobalFilter]);

    return (
        <InputWrapper className={cn("w-full", className)}>
            <Input
                value={searchValue}
                onChange={e => setSearchValue(e.target.value)}
                className="peer pe-8 ps-8"
                placeholder={placeholder}
            />
            <div className="pointer-events-none absolute inset-y-0 start-0 flex items-center justify-center ps-2 peer-disabled:opacity-50">
                <Search className="size-3 stroke-icon-tertiary" />
            </div>
            {searchValue && (
                <button
                    className="absolute cursor-pointer inset-y-0 end-0 flex h-full w-9 items-center justify-center rounded-e-sm text-text-tertiary transition-colors hover:text-text-tertiary-hover z-10 focus-visible:outline-2 outline-ring-focus disabled:pointer-events-none disabled:cursor-not-allowed disabled:opacity-50"
                    aria-label="Clear input"
                    onClick={() => setSearchValue("")}
                >
                    <CircleX className={"size-3"} aria-hidden="true" />
                </button>
            )}
        </InputWrapper>
    );
}
