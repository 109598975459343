import CalendarHeaderAddButton from "@/components/features/calendar/layout/header/CalendarHeaderAddButton";
import CalendarToggle from "@/components/features/calendar/layout/header/CalendarToggle";
import { SetNavigate, VIEW_KEY } from "@/types";
import CalendarHeaderDatePicker from "@/components/features/calendar/layout/header/CalendarHeaderDatePicker";
import CalendarHeaderNavigation from "@/components/features/calendar/layout/header/CalendarHeaderNavigation";
import { useMediaQuery } from "@/hooks/useMediaQuery";
interface CalendarHeaderProps {
    date: Date;
    changeDate: (value: number) => void;
    setNavigate: SetNavigate;
    viewKey: VIEW_KEY;
}

export default function CalendarHeader({
    date,
    changeDate,
    viewKey,
    setNavigate,
}: CalendarHeaderProps) {
    const isDesktop = useMediaQuery("lg");

    if (isDesktop) {
        return (
            <div className={"flex w-full flex-row justify-between gap-2 p-3 md:p-4"}>
                <CalendarHeaderDatePicker date={date} viewKey={viewKey} setNavigate={setNavigate} />
                <CalendarHeaderNavigation changeDate={changeDate} setNavigate={setNavigate} />
                <CalendarToggle setNavigate={setNavigate} viewKey={viewKey} />
                <CalendarHeaderAddButton />
            </div>
        );
    }

    return (
        <div className={"grid w-full grid-rows-2 grid-cols-2 gap-2 place-items-end p-3 md:p-4"}>
            <div className="mr-auto">
                <CalendarHeaderDatePicker date={date} viewKey={viewKey} setNavigate={setNavigate} />
            </div>

            <CalendarHeaderAddButton />

            <div className="mr-auto">
                <CalendarHeaderNavigation changeDate={changeDate} setNavigate={setNavigate} />
            </div>

            <CalendarToggle setNavigate={setNavigate} viewKey={viewKey} />
        </div>
    );
}
