import { httpErrorHandler } from "@/api/api";
import { usePostUserMutation } from "@/api/queries/usersQueries";
import { Button } from "@/components/ui/button/Button";
import {
    Dialog,
    DialogBody,
    DialogContent,
    DialogFooter,
    DialogHeader,
    DialogTitle,
    DialogDescription,
} from "@/components/ui/dialog/Dialog";
import {
    Form,
    FormControl,
    FormField,
    FormItem,
    FormLabel,
    FormMessage,
} from "@/components/ui/form/Form";
import { Input, InputActionButton, InputWrapper } from "@/components/ui/input/Input";
import {
    Select,
    SelectContent,
    SelectGroup,
    SelectItem,
    SelectTrigger,
    SelectValue,
} from "@/components/ui/input/select/Select";
import { UserAddSchema, UserAddSchemaType } from "@/schemas/user.schema";
import { useCredentials } from "@/store/authStore";
import generatePassword from "@/utils/generatePassword";
import { yupResolver } from "@hookform/resolvers/yup";
import {
    Download,
    LoaderCircle,
    CheckCircle2,
    User,
    CheckIcon,
    CopyIcon,
    CalendarIcon,
} from "lucide-react";
import { useState, Dispatch, SetStateAction } from "react";
import { useForm } from "react-hook-form";
import { FeaturedIcon } from "@/components/ui/featured-icon/FeaturedIcon";
import { Badge } from "@/components/ui/badge/Badge";
import { PhoneInput } from "@/components/ui/input/phone-input/PhoneInput";
import { cn } from "@/lib/utils";
import { useCopyToClipboard } from "@/hooks/useCopyToClipboard";
import {
    Calendar,
    CalendarPopoverContent,
    CalendarPopoverTrigger,
    CalendarPopover,
    DateInput,
    DatePicker,
} from "@/components/ui/input/date-picker/DatetimePicker";
import { parseDate } from "@internationalized/date";
import { toast } from "sonner";

interface UserAddFormPropsT {
    open: boolean;
    onOpenChange: Dispatch<SetStateAction<boolean>>;
}

export default function CreateUser({ open, onOpenChange }: UserAddFormPropsT) {
    const [password, setPassword] = useState<string>(generatePassword(10));
    const [page, setPage] = useState<number>(0);
    const { lvl } = useCredentials();

    const [copied, setCopied] = useState<boolean>(false);
    const [_, copy] = useCopyToClipboard();

    const handleCopy = async () => {
        if (!password) return;
        try {
            await copy(password);
            setCopied(true);
            setTimeout(() => setCopied(false), 1500);
        } catch (err) {
            console.error("Failed to copy text: ", err);
        }
    };

    const { mutateAsync, isPending } = usePostUserMutation();

    const onClose = () => {
        onOpenChange(false);
        form.reset();
        setPassword(generatePassword(10));
        setPage(0);
    };

    const form = useForm<UserAddSchemaType>({
        values: {
            name: "",
            surname: "",
            email: "",
            phone: "",
            birthdate: null,
            lvl: "0",
            password: password,
        },
        mode: "onBlur",
        resolver: yupResolver(UserAddSchema),
    });

    const onSubmit = (data: UserAddSchemaType) => {
        mutateAsync(data)
            .then(() => {
                setPage(1);
            })
            .catch(error => {
                const { title, detail } = httpErrorHandler(error);
                toast.error(title, {
                    description: detail,
                });
            });
    };

    return (
        <Dialog open={open} onOpenChange={onOpenChange}>
            <DialogContent
                className={"sm:max-w-148"}
                onCloseAutoFocus={onClose}
                onEscapeKeyDown={onClose}
            >
                <DialogDescription className={"sr-only"}>
                    Formularz do tworzenia użytkownika
                </DialogDescription>
                <Form {...form}>
                    <DialogBody>
                        {isPending && (
                            <div
                                className={
                                    "absolute inset-0 z-60 m-auto flex flex-wrap items-center justify-center gap-3 bg-surface-primary/75 backdrop-blur-md"
                                }
                            >
                                <LoaderCircle className={"animate-spin"} aria-hidden={"true"} />
                                <h5 className={"font-medium"}>Trwa tworzenie użytkownika...</h5>
                            </div>
                        )}
                        <DialogHeader className={"border-b border-border-primary"}>
                            <FeaturedIcon variant={"ghost"}>
                                <User />
                            </FeaturedIcon>
                            <div className={"flex flex-col gap-0.5 min-w-0 grow"}>
                                <DialogTitle>Utwórz Użytkownika</DialogTitle>
                                <p className={"text-text-tertiary text-xs"}>
                                    Wprawdź wymagane dane użytkownika.
                                </p>
                            </div>
                        </DialogHeader>
                        {page === 0 ? (
                            <form
                                className={"flex flex-col gap-3 p-3 md:p-4 overflow-auto grow"}
                                onSubmit={form.handleSubmit(onSubmit)}
                                noValidate
                            >
                                <FormField
                                    name={"name"}
                                    control={form.control}
                                    render={({ field }) => (
                                        <FormItem>
                                            <FormLabel>Imię</FormLabel>
                                            <FormControl>
                                                <Input {...field} placeholder={"Imię"} />
                                            </FormControl>
                                            <FormMessage />
                                        </FormItem>
                                    )}
                                />
                                <FormField
                                    name={"surname"}
                                    control={form.control}
                                    render={({ field }) => (
                                        <FormItem>
                                            <FormLabel>Nazwisko</FormLabel>
                                            <FormControl>
                                                <Input {...field} placeholder={"Nazwisko"} />
                                            </FormControl>
                                            <FormMessage />
                                        </FormItem>
                                    )}
                                />
                                <FormField
                                    name={"email"}
                                    control={form.control}
                                    render={({ field }) => (
                                        <FormItem>
                                            <FormLabel>Email</FormLabel>
                                            <FormControl>
                                                <Input {...field} placeholder={"Email"} />
                                            </FormControl>
                                            <FormMessage />
                                        </FormItem>
                                    )}
                                />
                                <FormField
                                    name={"lvl"}
                                    control={form.control}
                                    render={({ field }) => (
                                        <FormItem>
                                            <FormLabel>Poświadczenia</FormLabel>
                                            <Select
                                                onValueChange={field.onChange}
                                                defaultValue={field.value}
                                            >
                                                <FormControl>
                                                    <SelectTrigger className="ps-2 [&>span]:flex [&>span]:items-center [&>span]:gap-2 [&>span_[data-square]]:shrink-0">
                                                        <SelectValue
                                                            placeholder={"Poświadczenia"}
                                                        />
                                                    </SelectTrigger>
                                                </FormControl>
                                                <SelectContent className="[&_*[role=option]]:ps-2 [&_*[role=option]]:pe-8 [&_*[role=option]>span]:start-auto [&_*[role=option]>span]:end-2 [&_*[role=option]>span]:flex [&_*[role=option]>span]:items-center [&_*[role=option]>span]:gap-2">
                                                    <SelectGroup>
                                                        <SelectItem value={"0"}>
                                                            <Badge variant={"blue"}>U</Badge>
                                                            <span className={"truncate"}>
                                                                Uczeń
                                                            </span>
                                                        </SelectItem>
                                                        <SelectItem value={"1"}>
                                                            <Badge variant={"turquoise"}>N</Badge>
                                                            <span className={"truncate"}>
                                                                Nauczyciel
                                                            </span>
                                                        </SelectItem>
                                                        <SelectItem disabled={lvl != 2} value={"2"}>
                                                            <Badge variant={"purple"}>A</Badge>
                                                            <span className={"truncate"}>
                                                                Administrator
                                                            </span>
                                                        </SelectItem>
                                                    </SelectGroup>
                                                </SelectContent>
                                            </Select>
                                            <FormMessage />
                                        </FormItem>
                                    )}
                                />
                                <FormField
                                    name={"phone"}
                                    control={form.control}
                                    render={({ field }) => (
                                        <FormItem>
                                            <FormLabel>
                                                Numer telefonu{" "}
                                                <small className={"text-text-tertiary text-sm"}>
                                                    (opcionalne)
                                                </small>
                                            </FormLabel>
                                            <FormControl>
                                                <PhoneInput
                                                    {...field}
                                                    defaultCountry={"PL"}
                                                    placeholder={"Numer telefonu"}
                                                />
                                            </FormControl>
                                            <FormMessage />
                                        </FormItem>
                                    )}
                                />
                                <FormField
                                    name={"birthdate"}
                                    control={form.control}
                                    render={({ field }) => {
                                        return (
                                            <FormItem>
                                                <FormLabel>
                                                    Data urodzenia{" "}
                                                    <small className={"text-sm text-text-tertiary"}>
                                                        (opcionalne)
                                                    </small>
                                                </FormLabel>
                                                <FormControl>
                                                    <DatePicker
                                                        aria-label={"birthdate"}
                                                        granularity={"day"}
                                                        value={
                                                            field.value
                                                                ? parseDate(field.value)
                                                                : null
                                                        }
                                                        onChange={date => {
                                                            if (date) {
                                                                field.onChange(date.toString());
                                                            } else {
                                                                field.onChange(null);
                                                            }
                                                        }}
                                                    >
                                                        <CalendarPopover>
                                                            <InputWrapper>
                                                                <DateInput
                                                                    className={"rounded-e-none"}
                                                                />
                                                                <CalendarPopoverTrigger asChild>
                                                                    <InputActionButton
                                                                        className={
                                                                            "-ms-px rounded-s-none"
                                                                        }
                                                                    >
                                                                        <CalendarIcon />
                                                                    </InputActionButton>
                                                                </CalendarPopoverTrigger>
                                                            </InputWrapper>
                                                            <CalendarPopoverContent
                                                                align={"end"}
                                                                className={"p-2"}
                                                            >
                                                                <Calendar />
                                                            </CalendarPopoverContent>
                                                        </CalendarPopover>
                                                    </DatePicker>
                                                </FormControl>
                                                <FormMessage />
                                            </FormItem>
                                        );
                                    }}
                                />
                            </form>
                        ) : (
                            <div className={"flex flex-col gap-3 p-3 md:p-4"}>
                                <div>
                                    <h5 className={"pb-2 text-sm"}>Karta użytkownika</h5>
                                    <div
                                        className={"flex gap-3 border-t border-border-primary py-2"}
                                    >
                                        <p className={"text-xs text-text-tertiary"}>
                                            Pobierz kartę użytkownika ( Karta zostanie automatycznie
                                            wysłana użytkownikowi na wskazany adres email )
                                        </p>
                                        <Button variant={"outline"} className="shrink-0" disabled>
                                            <Download />
                                            Pobierz
                                        </Button>
                                    </div>
                                </div>
                                <div>
                                    <h5 className={"pb-2 text-sm"}>Hasło</h5>
                                    <div
                                        className={"flex gap-3 border-t border-border-primary py-2"}
                                    >
                                        <p className={"text-xs text-text-tertiary w-full"}>
                                            Hasło jest generowane i przydzielane do użytkownika
                                            automatycznie.
                                        </p>
                                        <Button
                                            variant={"outline"}
                                            className="shrink-0"
                                            onClick={handleCopy}
                                            aria-label={copied ? "Copied" : "Copy to clipboard"}
                                            disabled={copied}
                                        >
                                            <div
                                                className={cn(
                                                    "transition-all",
                                                    copied
                                                        ? "scale-100 opacity-100"
                                                        : "scale-0 opacity-0",
                                                )}
                                            >
                                                <CheckIcon
                                                    className="stroke-icon-success"
                                                    aria-hidden="true"
                                                />
                                            </div>
                                            <div
                                                className={cn(
                                                    "absolute transition-all left-3",
                                                    copied
                                                        ? "scale-0 opacity-0"
                                                        : "scale-100 opacity-100",
                                                )}
                                            >
                                                <CopyIcon aria-hidden="true" />
                                            </div>
                                            Kopiuj
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        )}
                    </DialogBody>
                    <DialogFooter className={"border-t border-border-primary"}>
                        {page === 0 ? (
                            <>
                                <Button
                                    onClick={onClose}
                                    type={"button"}
                                    variant={"tertiary"}
                                    className={"w-full md:w-fit"}
                                >
                                    Anuluj
                                </Button>
                                <Button
                                    disabled={isPending}
                                    onClick={form.handleSubmit(onSubmit)}
                                    type={"submit"}
                                    variant={"primary"}
                                    className={"w-full md:w-fit"}
                                >
                                    {isPending ? (
                                        <>
                                            <LoaderCircle
                                                className={"animate-spin"}
                                                aria-hidden={"true"}
                                            />
                                            Zapisywanie...
                                        </>
                                    ) : (
                                        <>
                                            <CheckCircle2 />
                                            Zapisz
                                        </>
                                    )}
                                </Button>
                            </>
                        ) : (
                            <Button
                                onClick={onClose}
                                type={"button"}
                                variant={"tertiary"}
                                className={"w-full md:w-fit"}
                            >
                                Zamknij
                            </Button>
                        )}
                    </DialogFooter>
                </Form>
            </DialogContent>
        </Dialog>
    );
}
