import { scan } from "react-scan";
import React from "react";
import { queryClient } from "@/api/query-client";
import { ThemeProvider } from "@/context/Theme";
import { router } from "@/routes/routes";
import { QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { HelmetProvider } from "react-helmet-async";
import { RouterProvider } from "react-router-dom";

if (typeof window !== "undefined") {
    scan({
        enabled: true,
        log: true,
    });
}

export default function App() {
    return (
        <React.StrictMode>
            <HelmetProvider>
                <ThemeProvider defaultTheme="system" storageKey="theme">
                    <QueryClientProvider client={queryClient}>
                        <ReactQueryDevtools
                            initialIsOpen={false}
                            position={"top"}
                            buttonPosition={"top-right"}
                        />
                        <RouterProvider router={router} />
                    </QueryClientProvider>
                </ThemeProvider>
            </HelmetProvider>
        </React.StrictMode>
    );
}
