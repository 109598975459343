import {
    ContextMenu,
    ContextMenuContent,
    ContextMenuGroup,
    ContextMenuItem,
    ContextMenuTrigger,
} from "@/components/ui/context-menu/ContextMenu";
import { cn } from "@/lib/utils";
import { useCredentials } from "@/store/authStore";
import { useChatStore } from "@/store/chatStore";
import type { ConversationType, UserPublic } from "@/types";
import getFileUrl from "@/utils/getFileUrl";
import { AudioMimeType, VideoMimeType } from "@vidstack/react";
import { Download, Reply, Trash, CheckIcon, CopyIcon, LoaderCircle } from "lucide-react";
import { ReactNode, forwardRef, useMemo, useRef, useState } from "react";
import type { MessageSend } from "@/types";
import { chatSocketService } from "./ChatSocketService";
import { useCopyToClipboard } from "@/hooks/useCopyToClipboard";
import {
    MessageBubble,
    MessageBubbleAudio,
    MessageBubbleContent,
    MessageBubbleFile,
    MessageBubbleFooter,
    MessageBubbleImage,
    MessageBubbleMessage,
    MessageBubbleUsername,
    MessageBubbleContainer,
    MessageBubbleReply,
    MessageBubbleVideo,
} from "@/components/ui/message-bubble/MessageBubble";
import { toast } from "sonner";

interface ChatMessageProps {
    message: MessageSend;
    conversationType: ConversationType;
}

const Message = forwardRef<HTMLDivElement, ChatMessageProps>(
    ({ message, conversationType }, ref) => {
        const { tempUUID, sender, createdAt, id, content, replyingTo, type, status, file } =
            message;

        const { id: userId } = useCredentials();

        const isUserMessage = sender?.id === userId;
        const isPrimary = isUserMessage && status !== "error";

        const getName = (sender?: UserPublic) => {
            if (sender) {
                return isUserMessage ? "@Ty" : "@" + sender.name + " " + sender.surname.at(0) + ".";
            } else {
                return "Użytkownik został usunięty";
            }
        };

        return (
            <div
                ref={ref}
                className={cn(
                    "flex select-none items-center justify-start gap-2",
                    isUserMessage ? "flex-row-reverse" : "flex-row",
                )}
                id={String(id)}
                data-message-date={createdAt}
                data-message-sender={sender?.id}
            >
                <ChatMessageMoreContext message={message} isUserMessage={isUserMessage}>
                    <MessageBubble
                        key={message.id}
                        variant={isPrimary ? "primary" : "secondary"}
                        side={isUserMessage ? "end" : "start"}
                        className={type === "deleted" ? "opacity-75" : ""}
                    >
                        <MessageBubbleContainer className="max-w-2xl">
                            <MessageBubbleUsername username={getName(sender)} />
                            <MessageBubbleContent>
                                {replyingTo && (
                                    <MessageBubbleReply
                                        content={replyingTo.content}
                                        username={getName(replyingTo.sender)}
                                    >
                                        <MessageMedia message={replyingTo} />
                                    </MessageBubbleReply>
                                )}
                                <MessageMedia message={message} />
                                {content && <MessageBubbleMessage>{content}</MessageBubbleMessage>}
                                <MessageBubbleFooter
                                    createdAt={message.createdAt}
                                    isPending={status === "pending"}
                                    isError={status === "error"}
                                    onRetry={() => chatSocketService.retryMessage(tempUUID)}
                                />
                            </MessageBubbleContent>
                        </MessageBubbleContainer>
                    </MessageBubble>
                </ChatMessageMoreContext>
            </div>
        );
    },
);

Message.displayName = "Message";

interface ChatMessageHeaderProps {
    message: MessageSend;
}

function MessageMedia({ message }: ChatMessageHeaderProps) {
    const { type, content, attachment, file } = message;

    const { fileURL, fileURLObject, mimeType } = useMemo(() => {
        if (attachment) {
            return {
                fileURL: getFileUrl(attachment.id),
                fileURLObject: undefined,
                mimeType: attachment.fileType,
            };
        } else if (file) {
            return {
                fileURL: undefined,
                fileURLObject: URL.createObjectURL(file),
                mimeType: file.type,
            };
        }
        return {
            fileURL: undefined,
            fileURLObject: undefined,
            mimeType: undefined,
        };
    }, [file, attachment]);

    const onOpenModal = () => {
        useChatStore.getState().setViewedFile(file || attachment);
        useChatStore.getState().setOpenViewer(true);
    };

    switch (type) {
        case "image":
            return (
                <MessageBubbleImage
                    src={fileURL || fileURLObject}
                    alt={content}
                    thumbnail={attachment?.thumbnail}
                    type={attachment?.fileType}
                    onClick={onOpenModal}
                />
            );
        case "video":
            return (
                <MessageBubbleVideo
                    src={{
                        src: fileURL || (fileURLObject as string),
                        type: mimeType as VideoMimeType,
                    }}
                    title={attachment?.name ? attachment.name : "video file"}
                    onClick={onOpenModal}
                />
            );
        case "audio":
            return (
                <MessageBubbleAudio
                    src={{
                        src: fileURL || (fileURLObject as string),
                        type: mimeType as AudioMimeType,
                    }}
                    title={attachment?.name ? attachment.name : "audio file"}
                />
            );
        case "file":
            return <MessageBubbleFile name={attachment?.name || file?.name} url={fileURL} />;
        default:
            return null;
    }
}

interface ChatMessageMoreContextProps {
    message: MessageSend;
    isUserMessage: boolean;
    children: ReactNode;
}

function ChatMessageMoreContext({ children, message, isUserMessage }: ChatMessageMoreContextProps) {
    const downloadButtonRef = useRef<HTMLAnchorElement>(null);
    const { id, type, attachment, content, status } = message;

    const [_, copy] = useCopyToClipboard();

    const onCopyText = async () => {
        if (!content) return;
        try {
            await copy(content);
            toast.success("Skopiowano do schowka");
        } catch (err) {
            toast.error("Nie udało się skopiować do schowka");
        }
    };

    const onDelete = () => {
        if (!id) return;
        chatSocketService.deleteMessage(id);
    };

    const onDownload = () => {
        if (!downloadButtonRef.current) return;
        downloadButtonRef.current.click();
    };

    const onReplyOn = () => {
        useChatStore.getState().setReplyingTo(message);
    };

    if (type === "deleted" || status === ("error" as const) || status === ("pending" as const)) {
        return children;
    }

    return (
        <ContextMenu>
            <ContextMenuTrigger asChild>{children}</ContextMenuTrigger>
            <ContextMenuContent>
                <ContextMenuGroup>
                    <ContextMenuItem onClick={onReplyOn} disabled={!id}>
                        <Reply className={"size-4"} />
                        Odpowiedz
                    </ContextMenuItem>
                    {type === "text" ? (
                        <ContextMenuItem onClick={onCopyText}>
                            <CopyIcon aria-hidden="true" />
                            Kopiuj tekst
                        </ContextMenuItem>
                    ) : null}
                    {attachment ? (
                        <ContextMenuItem onClick={onDownload}>
                            <a
                                ref={downloadButtonRef}
                                href={getFileUrl(attachment.id)}
                                className={"hidden"}
                            />
                            <Download className={"size-4"} />
                            Pobierz
                        </ContextMenuItem>
                    ) : null}
                    {isUserMessage ? (
                        <ContextMenuItem
                            onClick={onDelete}
                            disabled={!id}
                            className={"text-text-destructive"}
                        >
                            <Trash className={"size-4"} />
                            Usuń
                        </ContextMenuItem>
                    ) : null}
                </ContextMenuGroup>
            </ContextMenuContent>
        </ContextMenu>
    );
}

export const MessageSkeleton = forwardRef<HTMLDivElement>((props = {}, ref) => {
    return (
        <div ref={ref} className={"w-full py-8"} {...props}>
            <LoaderCircle
                className={"animate-spin size-6 text-text-brand mx-auto"}
                aria-hidden={"true"}
            />
        </div>
    );
});

MessageSkeleton.displayName = "MessageSkeleton";

export default Message;
