import { forwardRef, HTMLAttributes } from "react";
import { cn } from "@/lib/utils";

const AvatarGroup = forwardRef<HTMLDivElement, HTMLAttributes<HTMLDivElement>>(
    ({ className, ...props }, ref) => {
        return (
            <div
                ref={ref}
                className={cn(
                    "flex items-center gap-0.5 rounded-md bg-surface-secondary w-fit shrink-0",
                    className,
                )}
                {...props}
            />
        );
    },
);
AvatarGroup.displayName = "AvatarGroup";

const AvatarGroupContent = forwardRef<HTMLDivElement, HTMLAttributes<HTMLDivElement>>(
    ({ className, ...props }, ref) => {
        return (
            <div
                ref={ref}
                className={cn(
                    "flex -space-x-1 [&>span]:border-2 [&>span]:border-surface-secondary",
                    className,
                )}
                {...props}
            />
        );
    },
);
AvatarGroupContent.displayName = "AvatarGroupContent";

const AvatarGroupButtonMore = forwardRef<HTMLButtonElement, HTMLAttributes<HTMLButtonElement>>(
    ({ className, ...props }, ref) => {
        return (
            <button
                ref={ref}
                className={cn(
                    "flex items-center shrink-0 bg-surface-secondary hover:bg-surface-secondary-hover active:bg-surface-secondary-hover text-text-tertiary hover:text-text-secondary active:text-text-secondary justify-center aspect-square h-full rounded-md ease-out duration-100 transition-colors opacity-100 cursor-pointer disabled:cursor-not-allowed disabled:pointer-events-none outline-ring-focus outline-0 focus-visible:outline-2 disabled:opacity-75 [&_svg]:pointer-events-none [&_svg]:size-4 [&_svg]:shrink-0",
                    className,
                )}
                {...props}
            />
        );
    },
);
AvatarGroupButtonMore.displayName = "AvatarGroupButtonMore";

export { AvatarGroup, AvatarGroupContent, AvatarGroupButtonMore };
