import {
    Dialog,
    DialogBody,
    DialogContent,
    DialogFooter,
    DialogHeader,
    DialogTitle,
} from "@/components/ui/dialog/Dialog";
import { Dispatch, SetStateAction } from "react";

type UserMassImportInfoProps = {
    open: boolean;
    onOpenChange: Dispatch<SetStateAction<boolean>>;
};

export default function UserMassImportInfo({ open, onOpenChange }: UserMassImportInfoProps) {
    return (
        <Dialog open={open} onOpenChange={onOpenChange}>
            <DialogContent className={"md:max-w-86"}>
                <DialogBody>
                    <DialogHeader>
                        <DialogTitle>Nazwy kolumn</DialogTitle>
                    </DialogHeader>
                    <ul className={"list-disc space-y-1 p-3 md:p-4 ps-7 md:ps-8"}>
                        <li className={"text-sm text-text-secondary"}>Imię / imie / name</li>
                        <li className={"text-sm text-text-secondary"}>nazwisko / surname</li>
                        <li className={"text-sm text-text-secondary"}>
                            data urodzenia / birthdate / birthday
                        </li>
                        <li className={"text-sm  text-text-secondary"}>email</li>
                        <li className={"text-sm  text-text-secondary"}>
                            telefon / nr tel / numer telefonu / phone number / phone
                        </li>
                        <li className={"text-sm  text-text-secondary"}>
                            poświadczenia / rola / lvl / permissions
                        </li>
                    </ul>
                </DialogBody>
                <DialogFooter></DialogFooter>
            </DialogContent>
        </Dialog>
    );
}
