import NotificationPermissionAlert from "@/components/features/notifications/NotificationPermissionAlert";
import { Toaster } from "@/components/ui/sonner/Sonner";
import { createContext, useContext, useRef } from "react";
import { Outlet } from "react-router";
import { AppSidebar } from "@/components/features/app-sidebar/AppSidebar";
import { SidebarInset, SidebarProvider } from "@/components/ui/sidebar/Sidebar";
import NavHeaderMobile from "@/components/features/app-sidebar/NavHeaderMobile";
import { NotificationsSheet } from "@/components/features/notifications/NotificationsSheet";
import { NotificationsSheetProvider } from "../components/features/notifications/NotificationsSheet";

interface MainLayoutContextT {
    ref: React.RefObject<HTMLDivElement> | null;
}

const MainLayoutContext = createContext<MainLayoutContextT>({
    ref: null,
});

export const useMainLayoutContext = () => {
    return useContext(MainLayoutContext);
};

export default function MainLayout() {
    const ref = useRef<HTMLDivElement>(null);

    return (
        <MainLayoutContext.Provider value={{ ref: ref }}>
            <NotificationsSheetProvider>
                <SidebarProvider className="max-w-dvw max-h-dvh overflow-x-hidden">
                    <AppSidebar />
                    <SidebarInset className="overflow-x-hidden">
                        <NavHeaderMobile />
                        <div className={"flex flex-1 flex-col gap-4"}>
                            <Outlet />
                        </div>
                    </SidebarInset>
                </SidebarProvider>
                <NotificationsSheet />
            </NotificationsSheetProvider>
            <Toaster
                expand={false}
                position={"bottom-right"}
                offset={"1.5rem"}
                mobileOffset={"1.25rem"}
                closeButton={true}
            />
            <NotificationPermissionAlert />
        </MainLayoutContext.Provider>
    );
}
