import { Badge } from "@/components/ui/badge/Badge";
import { Skeleton } from "@/components/ui/skeleton/Skeleton";
import { MeetingPrivate, MeetingPublic } from "@/types";
import { EmptyObject } from "@/types";
import { format } from "date-fns";
import { pl } from "date-fns/locale";
import { Dot, Video } from "lucide-react";
import LocationCard from "../location/LocationCard";
import { Link } from "react-router";
import { Button } from "@/components/ui/button/Button";

interface InfoItemProps {
    label: string;
    children: React.ReactNode;
}

// Reusable component for info items with consistent styling
function InfoItem({ label, children }: InfoItemProps) {
    return (
        <div className="flex min-h-8 items-center">
            <span className="min-w-16 text-sm text-text-tertiary">{label}:</span>
            {children}
        </div>
    );
}

// Skeleton loader for info items
function InfoItemSkeleton() {
    return (
        <div className="flex min-h-11 max-w-[35ch] items-center gap-3">
            <Skeleton className="h-4 w-[10ch]" />
            <Skeleton className="h-4 w-[35ch]" />
        </div>
    );
}

interface MeetingInfoContentProps {
    data: MeetingPrivate | EmptyObject;
    isSuccess: boolean;
    isFetching: boolean;
    basicData: Pick<MeetingPublic, "startDate" | "endDate" | "type" | "desc" | "isCancelled">;
}

export default function MeetingInfoContent({
    data,
    isSuccess,
    isFetching,
    basicData,
}: MeetingInfoContentProps) {
    const { startDate, endDate, type, desc, isCancelled } = basicData;
    const { location, meetingURL } = data;

    const formattedDate = format(new Date(startDate), "dd MMM yyyy", { locale: pl });
    const startTime = format(new Date(startDate), "H:mm");
    const endTime = format(new Date(endDate), "H:mm");

    const meetingType = type === "remote" ? "Zdalnie" : "Stacjonarnie";

    return (
        <>
            <div className="flex flex-col gap-1">
                {/* Date and time */}
                <InfoItem label="Data">
                    <p className="flex items-center truncate whitespace-nowrap text-text-secondary">
                        {formattedDate}
                        <Dot className="size-4 stroke-icon-secondary" />
                        {startTime}-{endTime}
                    </p>
                </InfoItem>

                {/* Meeting type */}
                <InfoItem label="Typ">
                    <div>
                        <Badge variant="muted">{meetingType}</Badge>
                    </div>
                </InfoItem>

                {/* Loading state */}
                {isFetching && (
                    <>
                        <InfoItemSkeleton />
                        <InfoItemSkeleton />
                    </>
                )}

                {/* Cancelled status */}
                {isCancelled && (
                    <InfoItem label="Stan">
                        <Badge variant="destructive">Odwołano spotkanie</Badge>
                    </InfoItem>
                )}

                {/* Meeting URL if available */}
                {isSuccess && meetingURL && (
                    <InfoItem label="Link">
                        <Link to={meetingURL} target={"_blank"}>
                            <Button variant={"linkSecondary"} size={"sm"}>
                                <Video />
                                Link do spotkania
                            </Button>
                        </Link>
                    </InfoItem>
                )}

                {/* Location if available */}
                {isSuccess && location && (
                    <InfoItem label="Lokacja">
                        <p className="text-text-secondary">{location.name}</p>
                    </InfoItem>
                )}
                {isSuccess && location && (
                    <LocationCard location={location} className={"h-58"} staticMap />
                )}
            </div>

            {/* Meeting description */}
            {isSuccess && desc && (
                <div className="flex flex-col gap-2">
                    <h5 className="text-lg font-medium">Opis</h5>
                    <p className=" text-text-secondary">{desc}</p>
                </div>
            )}
        </>
    );
}
