import { httpErrorHandler } from "@/api/api";
import { useGetAssignedMeetingsQuery } from "@/api/queries/meetingsQuery";
import CalendarContentHours from "@/components/features/calendar/layout/content/CalendarContentHours";
import CalendarContentRowLines from "@/components/features/calendar/layout/content/CalendarContentRowLines";
import CalendarContentTimeLine from "@/components/features/calendar/layout/content/CalendarContentTimeLine";
import { useMapMeetingsByDate } from "@/components/features/calendar/layout/content/map-meetings-by-date";
import MeetingContext from "@/components/features/meeting/MeetingContext";
import MeetingCalendarCard from "@/components/features/meeting/meeting-calendar-card/MeetingCalendarCard";
import { MeetingPublic } from "@/types";
import { getWeek } from "@/utils/dateFormat";
import { eventOverlap } from "@/utils/eventOverlap";
import { endOfDay, endOfWeek, isToday, startOfDay, startOfWeek } from "date-fns";
import { useEffect, useMemo } from "react";
import { toast } from "sonner";
import { Button } from "@/components/ui/button/Button";

interface CalendarContentWeekProps {
    date: Date;
    containerHeight: number;
}

export default function CalendarContentWeek({
    date,

    containerHeight,
}: CalendarContentWeekProps) {
    const week = getWeek(date);

    const queryParams = useMemo(
        () => ({
            after: startOfWeek(startOfDay(date), { weekStartsOn: 1 }).toISOString(),
            before: endOfWeek(endOfDay(date), { weekStartsOn: 1 }).toISOString(),
        }),
        [date],
    );

    const { data = [], isError, error, refetch } = useGetAssignedMeetingsQuery(queryParams);

    const meetings = useMapMeetingsByDate(week, data);

    useEffect(() => {
        if (isError) {
            const { title, detail } = httpErrorHandler(error);
            toast.error(title, {
                description: detail,
                action: (
                    <Button
                        size={"sm"}
                        className={"ml-auto"}
                        variant={"tertiary"}
                        onClick={() => {
                            refetch();
                        }}
                    >
                        Ponów
                    </Button>
                ),
            });
        }
    }, [error, isError, refetch]);

    return (
        <div
            className={"relative flex w-full flex-row"}
            style={{ height: `${containerHeight}px`, minHeight: `${containerHeight}px` }}
        >
            <CalendarContentHours />
            <CalendarContentRowLines
                space={containerHeight / 24}
                className={"absolute h-full w-full pl-12 md:pl-14"}
            />
            <div className={"relative flex w-full"}>
                {week.map(weekDayDate => (
                    <div
                        key={weekDayDate.toISOString()}
                        className={
                            "relative w-full first:border-l border-border-primary border-r last:border-0"
                        }
                    >
                        <CalendarContentTimeLine
                            hide={!isToday(weekDayDate)}
                            containerHeight={containerHeight}
                        />
                        <CalendarContentDataWeek
                            meetings={meetings[weekDayDate.toISOString()]}
                            queryParams={queryParams}
                            containerHeight={containerHeight}
                        />
                    </div>
                ))}
            </div>
        </div>
    );
}

interface CalendarContentDataWeekProps {
    meetings: MeetingPublic[];
    queryParams: { before: string; after: string };
    containerHeight: number;
}

function CalendarContentDataWeek({
    meetings,
    queryParams,
    containerHeight,
}: CalendarContentDataWeekProps) {
    const styles = useMemo(() => eventOverlap(meetings), [meetings]);

    return meetings.map(meeting => (
        <MeetingContext key={meeting.id} queryParams={queryParams} meeting={meeting}>
            <MeetingCalendarCard
                meeting={meeting}
                options={{
                    containerHeight: containerHeight,
                    style: styles[meeting.id],
                }}
            />
        </MeetingContext>
    ));
}
