import { useGetPostsQuery } from "@/api/queries/postsQueries";
import ActivatePost from "@/components/features/manage/manage-posts/ActivatePost";
import CreatePost from "@/components/features/manage/manage-posts/CreatePost";
import DeletePost from "@/components/features/manage/manage-posts/DeletePost";
import EditPost from "@/components/features/manage/manage-posts/EditPost";
import { TanstackTable } from "@/components/features/manage/tanstack-table/TanstackTable";
import { Badge } from "@/components/ui/badge/Badge";
import { Button } from "@/components/ui/button/Button";
import { Checkbox } from "@/components/ui/input/checkbox/Checkbox";
import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuGroup,
    DropdownMenuItem,
    DropdownMenuSeparator,
    DropdownMenuTrigger,
} from "@/components/ui/input/dropdown-menu/DropdownMenu";
import type { PostPublic } from "@/types";
import { ColumnDef } from "@tanstack/react-table";
import { format, isValid, parseISO } from "date-fns";
import { Edit, Plus, Trash2 } from "lucide-react";
import { useMemo, useState } from "react";
import { UserPublic } from "@/types";

export default function ManagePosts() {
    const [openEdit, setOpenEdit] = useState<boolean>(false);
    const [openChangeActive, setOpenChangeActive] = useState<boolean>(false);
    const [openDelete, setOpenDelete] = useState<boolean>(false);
    const [openPostAdd, setOpenPostAdd] = useState<boolean>(false);

    const [selectedRows, setSelectedRows] = useState<PostPublic[]>([]);
    const [selectedPost, setSelectedPost] = useState<PostPublic | undefined>(undefined);

    const { data = [], isPending } = useGetPostsQuery();
    const getSelectedRowsFn = (rows: PostPublic[]) => {
        setSelectedRows(rows);
        setOpenDelete(true);
    };

    const triggerPopupEdit = (post?: PostPublic) => {
        if (post) {
            setSelectedPost(post);
            setOpenEdit(true);
        }
    };
    const triggerPopupChangeActive = (post?: PostPublic) => {
        if (post) {
            setSelectedPost(post);
            setOpenChangeActive(true);
        }
    };

    const triggerPopupAddPost = () => {
        setOpenPostAdd(true);
    };

    const addGroup = useMemo(() => {
        return [
            {
                id: 1,
                label: "Dodaj post",
                icon: <Plus className={"h-4 w-4"} />,
                func: () => triggerPopupAddPost(),
            },
        ];
    }, []);

    const columns: ColumnDef<PostPublic>[] = useMemo(
        () => [
            {
                id: "select",
                header: ({ table }) => (
                    <Checkbox
                        checked={
                            table.getIsAllPageRowsSelected()
                                ? true
                                : table.getIsSomePageRowsSelected()
                                  ? "indeterminate"
                                  : false
                        }
                        onCheckedChange={value => table.toggleAllPageRowsSelected(!!value)}
                        aria-label="Select all"
                    />
                ),
                cell: ({ row }) => (
                    <Checkbox
                        checked={row.getIsSelected()}
                        onCheckedChange={value => row.toggleSelected(!!value)}
                        aria-label={"Select row"}
                    />
                ),
                enableSorting: false,
                enableHiding: false,
            },
            {
                meta: "Tytuł",
                id: "name",
                accessorKey: "name",
                header: "Tytuł",
            },
            {
                meta: "Twórca",
                id: "creator",
                accessorKey: "creator",
                cell: ({ getValue }) => {
                    const creator = getValue() as UserPublic | null;
                    if (creator && creator.name && creator.surname) {
                        return creator.name + " " + creator.surname;
                    }
                    return "";
                },
                header: "Twórca",
            },
            {
                meta: "Aktywność",
                id: "active",
                accessorKey: "active",
                cell: ({ getValue }) => {
                    const active = getValue();
                    if (active === true) {
                        return <Badge variant={"success"}>Aktywny</Badge>;
                    }
                    if (active === false) {
                        return <Badge variant={"destructive"}>Nieaktywny</Badge>;
                    }
                    return "";
                },
                header: "Aktywność",
                filterFn: (row, id, value) => {
                    return value.includes(row.getValue(id));
                },
            },
            {
                meta: "Ostatnie zmiany",
                id: "updatedAt",
                accessorKey: "updatedAt",
                sortType: "datetime",
                cell: ({ getValue }) => {
                    const date = getValue();
                    if (typeof date === "string" && isValid(parseISO(date))) {
                        return format(new Date(date), "dd.MM.yyyy");
                    }
                    return date;
                },
                header: "Ostatnie zmiany",
            },
            {
                meta: "Data utwotrzenia",
                id: "createdAt",
                accessorKey: "createdAt",
                sortType: "datetime",
                cell: ({ getValue }) => {
                    const date = getValue();
                    if (typeof date === "string" && isValid(parseISO(date))) {
                        return format(new Date(date), "dd.MM.yyyy");
                    }
                    return date;
                },
                header: "Data utworzenia",
            },
            {
                id: "actions",
                enableHiding: false,
                enableSorting: false,
                cell: ({ row }) => {
                    const rowOrigin = row.original;
                    return (
                        <div className={"flex justify-end"}>
                            <Button
                                size={"sm"}
                                variant={"ghost"}
                                variantColor={"destructive"}
                                onClick={() => getSelectedRowsFn([rowOrigin])}
                            >
                                <Trash2 />
                            </Button>
                            <DropdownMenu>
                                <DropdownMenuTrigger asChild>
                                    <Button size={"sm"} variant={"ghost"}>
                                        <Edit />
                                    </Button>
                                </DropdownMenuTrigger>
                                <DropdownMenuContent align={"end"}>
                                    <DropdownMenuGroup>
                                        <DropdownMenuItem
                                            onClick={() => triggerPopupEdit(rowOrigin)}
                                        >
                                            Edytuj
                                        </DropdownMenuItem>
                                    </DropdownMenuGroup>
                                    <DropdownMenuSeparator />
                                    <DropdownMenuGroup>
                                        <DropdownMenuItem
                                            className={"text-fg-destructive"}
                                            onClick={() => triggerPopupChangeActive(rowOrigin)}
                                        >
                                            {rowOrigin.active ? "Dezaktywuj" : "Aktywuj"}
                                        </DropdownMenuItem>
                                    </DropdownMenuGroup>
                                </DropdownMenuContent>
                            </DropdownMenu>
                        </div>
                    );
                },
            },
        ],
        [],
    );

    return (
        <>
            <CreatePost open={openPostAdd} onOpenChange={setOpenPostAdd} />
            <EditPost
                post={selectedPost}
                open={openEdit}
                onOpenChange={setOpenEdit}
                callback={() => {
                    setOpenEdit(false);
                    setSelectedPost(undefined);
                }}
            />
            <DeletePost
                selected={selectedRows}
                callback={() => setSelectedRows([])}
                open={openDelete}
                onOpenChange={setOpenDelete}
            />
            <ActivatePost
                post={selectedPost}
                open={openChangeActive}
                onOpenChange={setOpenChangeActive}
            />

            <div className={"base-direction-col"}>
                <TanstackTable
                    className={"flex flex-col gap-4 w-full viewport-height"}
                    name={"posts"}
                    label={"Posty"}
                    isLoading={isPending}
                    columns={columns}
                    defaultColumnVisibility={{
                        updatedAt: false,
                        createdAt: false,
                    }}
                    data={data}
                    getSelectedRows={getSelectedRowsFn}
                    filterBy={[
                        {
                            id: "active",
                            title: "Aktywność",
                            options: [
                                {
                                    label: "Aktywny",
                                    value: true,
                                },
                                {
                                    label: "Nieaktywny",
                                    value: false,
                                },
                            ],
                        },
                    ]}
                    addGroup={addGroup}
                />
            </div>
        </>
    );
}
