import { cn } from "@/lib/utils";
import * as React from "react";

const TextareaWrapper = React.forwardRef<HTMLDivElement, React.ComponentProps<"div">>(
    ({ className, ...props }, ref) => {
        return (
            <div
                ref={ref}
                className={cn(
                    "relative flex flex-row [&_svg]:pointer-events-none [&_svg]:size-4 [&_svg]:shrink-0 *:stroke-icon-tertiary",
                    className,
                )}
                {...props}
            />
        );
    },
);
TextareaWrapper.displayName = "TextareaWrapper";

const Textarea = React.forwardRef<
    HTMLTextAreaElement,
    React.TextareaHTMLAttributes<HTMLTextAreaElement> & { className?: string }
>(({ className, ...props }, ref) => {
    return (
        <textarea
            ref={ref}
            className={cn(
                "flex min-h-15 w-full rounded-sm border border-border-primary bg-transparent px-3 py-1 text-base duration-100 transition-colors transition-outline file:border-0 file:bg-transparent file:text-sm file:font-medium file:text-text-secondary placeholder:text-text-tertiary/50 focus-visible:z-10 outline-ring-focus outline-0 focus-visible:outline-2 disabled:cursor-not-allowed disabled:opacity-50",
                className,
            )}
            {...props}
        />
    );
});
Textarea.displayName = "Textarea";

export { Textarea, TextareaWrapper };
