import { RadioGroup, RadioGroupItem } from "@/components/ui/input/radio-group/RadioGroup";
import { useTheme } from "@/context/Theme";
import { LaptopMinimal, Moon, Sun } from "lucide-react";
import { useId } from "react";

function ThemeRadioGroup() {
    const { theme, setTheme } = useTheme();

    return (
        <RadioGroup className="grid-cols-3 max-w-92" value={theme} onValueChange={setTheme}>
            {/* System */}
            <ThemeRadioGroupItem value={"system"} title="Systemowy" icon={<LaptopMinimal />} />

            {/* Light */}
            <ThemeRadioGroupItem value={"light"} title="Jasny" icon={<Sun />} />

            {/* Dark */}
            <ThemeRadioGroupItem value={"dark"} title="Ciemny" icon={<Moon />} />
        </RadioGroup>
    );
}

interface ThemeRadioGroupItemProps {
    value: string;
    title: string;
    icon: React.ReactNode;
}

function ThemeRadioGroupItem({ value, icon, title }: ThemeRadioGroupItemProps) {
    const id = useId();

    return (
        <div className="border-border-primary has-data-[state=checked]:border-border-brand has-data-[state=checked]:bg-fill-brand/5 has-data-[state=checked]:text-text-brand focus-within:outlin-2 outline-0 outline-ring-focus relative flex cursor-pointer flex-col items-center gap-3 rounded-md border px-3 py-2 text-center shadow-xs transition-[color,box-shadow] outline-none [&>svg]:size-4 ">
            <RadioGroupItem id={`${id}-radio-group-item`} value={value} className="sr-only" />
            {icon}
            <label
                htmlFor={`${id}-radio-group-item`}
                className="cursor-pointer text-xs leading-none font-medium after:absolute after:inset-0"
            >
                {title}
            </label>
        </div>
    );
}

export { ThemeRadioGroup };
