import { Tabs, TabsList, TabsTrigger } from "@/components/ui/tabs/Tabs";
import { cn } from "@/lib/utils";
import { HardDrive, Users } from "lucide-react";

interface FilesDriveTypeListProps {
    value: string;
    onValueChange: (value: string) => void;
    className?: string;
    hidden?: boolean;
}

export default function FilesDriveTypeList({
    value,
    onValueChange,
    className,
    hidden = false,
}: FilesDriveTypeListProps) {
    return (
        <Tabs
            value={value}
            onValueChange={onValueChange}
            className={cn(className, hidden && "hidden")}
        >
            <TabsList className="grid w-fit grid-cols-2">
                <TabsTrigger value="my_drive" className="sm:px-4">
                    <HardDrive className="h-4 w-4" />
                    <span className="hidden lg:block">Mój Dysk</span>
                </TabsTrigger>
                <TabsTrigger value="shared_with_me" className="sm:px-4" disabled>
                    <Users className="h-4 w-4" />
                    <span className="hidden lg:block">Udostępnione</span>
                </TabsTrigger>
            </TabsList>
        </Tabs>
    );
}
