import { Badge } from "@/components/ui/badge/Badge";
import { Button } from "@/components/ui/button/Button";
import {
    Dialog,
    DialogBody,
    DialogContent,
    DialogDescription,
    DialogFooter,
    DialogHeader,
    DialogTitle,
} from "@/components/ui/dialog/Dialog";
import {
    Select,
    SelectContent,
    SelectGroup,
    SelectItem,
    SelectLabel,
    SelectTrigger,
    SelectValue,
} from "@/components/ui/input/select/Select";
import {
    Table,
    TableBody,
    TableCaption,
    TableCell,
    TableHead,
    TableHeader,
    TableRow,
} from "@/components/ui/table/Table";
import { cn } from "@/lib/utils";
import { UsersMassImportRowT } from "@/page/pages/manage/ManageUsersMassImport";
import { ColumnDef, flexRender, getCoreRowModel, useReactTable } from "@tanstack/react-table";
import { format, isValid, parseISO } from "date-fns";
import { CheckCircle2, Download } from "lucide-react";
import { ChangeEvent, Dispatch, SetStateAction, useEffect, useMemo, useRef, useState } from "react";
import { v4 as uuidv4 } from "uuid";

type UserMassImportContentProps = {
    open: boolean;
    onOpenChange: Dispatch<SetStateAction<boolean>>;
    setData: Dispatch<SetStateAction<UsersMassImportRowT[]>>;
};

export default function UserMassImportContent({
    open,
    onOpenChange,
    setData,
}: UserMassImportContentProps) {
    const inputRef = useRef<HTMLInputElement>(null);
    const [fileRawData, setFileRawData] = useState<string | null>(null);
    const [tableData, setTableData] = useState<UsersMassImportRowT[]>([]);
    const [separator, setSeparator] = useState<string>(",");
    const [fileName, setFileName] = useState<string>("");
    const [error, setError] = useState<string | null>(null);

    const columns: ColumnDef<UsersMassImportRowT>[] = useMemo(
        () => [
            {
                meta: "Rola",
                id: "lvl",
                accessorKey: "lvl",
                cell: ({ getValue }) => {
                    const lvl = getValue();
                    if (lvl === "0") {
                        return (
                            <Badge size={"sm"} variant={"blue"}>
                                Uczeń
                            </Badge>
                        );
                    }
                    if (lvl === "1") {
                        return (
                            <Badge size={"sm"} variant={"turquoise"}>
                                Nauczyciel
                            </Badge>
                        );
                    }
                    if (lvl === "2") {
                        return (
                            <Badge size={"sm"} variant={"purple"}>
                                Administrator
                            </Badge>
                        );
                    }
                },
                header: "Stanowisko",
            },
            {
                meta: "Imię",
                id: "name",
                accessorKey: "name",
                header: "Imię",
            },
            {
                meta: "Nazwisko",
                id: "surname",
                accessorKey: "surname",
                header: "Nazwisko",
            },
            {
                meta: "Email",
                id: "email",
                accessorKey: "email",
                header: "Email",
            },
            {
                meta: "Nr.tel",
                id: "phone",
                accessorKey: "phone",
                header: "Nr.tel",
            },
            {
                meta: "Data ur.",
                id: "birthdate",
                accessorKey: "birthdate",
                cell: ({ getValue }) => {
                    const date = getValue();
                    if (typeof date === "string" && isValid(parseISO(date))) {
                        return format(new Date(date), "dd.MM.yyyy");
                    }
                    return date;
                },
                header: "Data ur.",
            },
        ],
        [],
    );
    const table = useReactTable<UsersMassImportRowT>({
        data: tableData,
        columns,
        getCoreRowModel: getCoreRowModel(),
    });

    const onSubmit = () => {
        setData(curr => [...curr, ...tableData]);
        onClose();
    };

    const onClose = () => {
        onOpenChange(false);
        setTableData([]);
        setFileRawData(null);
        setFileName("");
    };

    const handleFileInputClick = () => {
        if (inputRef.current) {
            inputRef.current.click();
        }
    };

    const parseFile = async (file: File): Promise<string> => {
        return (await file.text()).trim();
    };

    const handleLoadFile = async (event: ChangeEvent<HTMLInputElement>) => {
        if (!event.target.files) {
            return;
        }
        const file = event.target.files[0];
        setFileName(file.name);
        const parsedFile = await parseFile(file);
        setFileRawData(parsedFile);
        const parsedTableData = handleParseStringToUsersList(parsedFile);
        if (parsedTableData) {
            setTableData(parsedTableData);
        }
    };

    const handleParseStringToUsersList = (
        fileString: string,
        newSeparator?: string,
    ): UsersMassImportRowT[] | undefined => {
        const iter: string[] = fileString.split("\n");

        const header: string[] = iter[0].trim().split(newSeparator ? newSeparator : separator);

        const headers = [
            {
                field: "name",
                pl: ["imię", "imie"],
                en: ["name"],
                obligatory: true,
            },
            {
                field: "surname",
                pl: ["nazwisko"],
                en: ["surname"],
                obligatory: true,
            },
            {
                field: "birthdate",
                pl: ["data urodzenia"],
                en: ["birthdate", "birthday"],
            },
            {
                field: "email",
                pl: ["email"],
                en: ["email"],
            },
            {
                field: "phone",
                pl: ["telefon", "nr tel", "numer telefonu"],
                en: ["phone number", "phone"],
            },
            {
                field: "lvl",
                pl: ["poświadczenia", "rola"],
                en: ["lvl", "permissions"],
            },
        ];

        const labels: string[] = [];

        for (const [idx, label] of header.entries()) {
            for (const field of headers) {
                if ([...field.pl, ...field.en].includes(label.toLowerCase())) {
                    labels[idx] = field.field;
                    break;
                }
            }
        }

        if (!labels.length) {
            setError("Nie wykryto poprawnych nagłówków kolumn");
            setTableData([]);
            return;
        }

        const users: UsersMassImportRowT[] = [];

        for (let i = 1; i < iter.length; i++) {
            const line: string[] = iter[i].trim().split(newSeparator ? newSeparator : separator);

            const user: Partial<UsersMassImportRowT> = {};

            user.id = uuidv4();

            for (const [idx, cell] of line.entries()) {
                if (labels[idx] && cell) {
                    user[labels[idx] as keyof UsersMassImportRowT] = cell;
                }
            }

            if (user.lvl == undefined) {
                user.lvl = "0";
            }

            if (Object.keys(user).length) {
                users[i - 1] = user as UsersMassImportRowT;
            }
        }
        return users;
    };

    useEffect(() => {
        if (separator && fileRawData) {
            const parsedTableData = handleParseStringToUsersList(fileRawData, separator);
            if (parsedTableData) {
                setTableData(parsedTableData);
            }
        }
    }, [separator, fileRawData]);

    return (
        <Dialog open={open} onOpenChange={onOpenChange}>
            <DialogContent className={"w-[65vw] sm:w-full"}>
                <DialogDescription className={"sr-only"}>
                    Formularz do importowania użytkowników
                </DialogDescription>
                <DialogBody>
                    <DialogHeader className={"border-b border-border-primary"}>
                        <DialogTitle>Importuj dane</DialogTitle>
                    </DialogHeader>
                    <div className={"flex flex-col gap-3 p-3 md:p-4"}>
                        <input
                            ref={inputRef}
                            className={"hidden"}
                            type={"file"}
                            name={"file"}
                            onChange={handleLoadFile}
                            accept={".xls,.xlsx,.csv"}
                        />
                        <div className={"flex gap-2 py-1"}>
                            <Select onValueChange={setSeparator} value={separator}>
                                <SelectTrigger className={"w-44"}>
                                    <SelectValue placeholder="Separator" />
                                </SelectTrigger>
                                <SelectContent>
                                    <SelectGroup>
                                        <SelectLabel>Separator</SelectLabel>
                                        <SelectItem value={";"}>;</SelectItem>
                                        <SelectItem value={","}>,</SelectItem>
                                        <SelectItem value={" "}>*spacja*</SelectItem>
                                        <SelectItem value={"&#9"}>*tabulator*</SelectItem>
                                    </SelectGroup>
                                </SelectContent>
                            </Select>
                            <Button onClick={handleFileInputClick} variant={"outline"}>
                                <Download />
                                {fileName ? (
                                    <p className={"truncate"}>{fileName}</p>
                                ) : (
                                    "Wybierz plik"
                                )}
                            </Button>
                        </div>
                        <div className={"py-1"}>
                            <Table className={"h-fit text-center"}>
                                <colgroup>
                                    <col className="w-[15%]" />
                                    <col className="w-[20%]" />
                                    <col className="w-[20%]" />
                                    <col className="w-[15%]" />
                                    <col className="w-[15%]" />
                                    <col className="w-[15%]" />
                                </colgroup>
                                <TableCaption
                                    className={cn(
                                        "my-4",
                                        tableData.length && "hidden",
                                        error && "text-text-destructive",
                                    )}
                                >
                                    {error
                                        ? error
                                        : "Wybierz plik do załadowania oraz wybierz separator"}
                                </TableCaption>
                                <TableHeader className={"w-full overflow-x-auto"}>
                                    {table.getHeaderGroups().map(headerGroup => (
                                        <TableRow key={headerGroup.id}>
                                            {headerGroup.headers.map(header => (
                                                <TableHead
                                                    className={
                                                        "text-nowrap p-1 text-center text-xs"
                                                    }
                                                    key={header.id}
                                                >
                                                    {header.isPlaceholder
                                                        ? null
                                                        : flexRender(
                                                              header.column.columnDef.header,
                                                              header.getContext(),
                                                          )}
                                                </TableHead>
                                            ))}
                                        </TableRow>
                                    ))}
                                </TableHeader>
                                <TableBody>
                                    {table.getRowModel().rows.map(row => (
                                        <TableRow key={row.id} className={"h-8"}>
                                            {row.getVisibleCells().map(cell => (
                                                <TableCell key={cell.id} className={"text-xs"}>
                                                    {flexRender(
                                                        cell.column.columnDef.cell,
                                                        cell.getContext(),
                                                    )}
                                                </TableCell>
                                            ))}
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </div>
                    </div>
                </DialogBody>
                <DialogFooter className={"border-t border-border-primary"}>
                    <Button variant={"tertiary"} onClick={onClose}>
                        Anuluj
                    </Button>
                    <Button onClick={onSubmit}>
                        <CheckCircle2 />
                        Dodaj
                    </Button>
                </DialogFooter>
            </DialogContent>
        </Dialog>
    );
}
