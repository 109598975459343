"use no memo";
import { AudioLayout } from "@/components/ui/media-viewer/AudioLayout";
import { MediaPlayer, MediaPlayerInstance, MediaPlayerProps, MediaProvider } from "@vidstack/react";
import { PlayerSrc } from "@vidstack/react";
import "@vidstack/react/player/styles/base.css";
import { useRef } from "react";

type AudioPlayerProps = {
    src?: PlayerSrc;
    title: string;
    variant?: "default" | "minimal";
} & Omit<MediaPlayerProps, "children">;

export default function AudioPlayer({
    title,
    src,
    variant = "default",
    ...props
}: AudioPlayerProps) {
    const player = useRef<MediaPlayerInstance>(null);

    return (
        <MediaPlayer
            className={
                "ring-media-focus relative w-full overflow-hidden rounded-sm bg-surface-primary text-text-tertiary outline-0 data-focus:outline-2 outline-ring-focus"
            }
            title={title}
            src={src}
            ref={player}
            playsInline
            streamType={"on-demand"}
            viewType={"audio"}
            {...props}
        >
            <MediaProvider />
            <AudioLayout variant={variant} />
        </MediaPlayer>
    );
}

export type { AudioPlayerProps };
