import { httpErrorHandler } from "@/api/api";
import { useEditMeetingMutation, useGetMeetingQuery } from "@/api/queries/meetingsQuery";
import DeleteMeeting from "@/components/features/calendar/form/DeleteMeeting";
import EditMeeting from "@/components/features/calendar/form/EditMeeting";

import { Button } from "@/components/ui/button/Button";
import {
    ContextMenu,
    ContextMenuContent,
    ContextMenuGroup,
    ContextMenuItem,
    ContextMenuLabel,
    ContextMenuSeparator,
    ContextMenuTrigger,
} from "@/components/ui/context-menu/ContextMenu";
import {
    DrawerContent,
    DrawerHeader,
    Drawer,
    DrawerTrigger,
    DrawerBody,
    DrawerTitle,
    DrawerFooter,
    DrawerDescription,
} from "@/components/ui/drawer/Drawer";
import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuGroup,
    DropdownMenuItem,
    DropdownMenuTrigger,
} from "@/components/ui/input/dropdown-menu/DropdownMenu";
import {
    Sheet,
    SheetContent,
    SheetHeader,
    SheetTitle,
    SheetTrigger,
    SheetBody,
    SheetFooter,
    SheetDescription,
} from "@/components/ui/sheet/Sheet";
import { useMediaQuery } from "@/hooks/useMediaQuery";
import { useCredentials } from "@/store/authStore";
import { MeetingPublic } from "@/types";
import {
    ArchiveRestore,
    Ban,
    ChevronDown,
    FileEdit,
    MoreVertical,
    Pen,
    Trash2,
} from "lucide-react";
import * as React from "react";
import { forwardRef, useState } from "react";
import { Link } from "react-router";
import MeetingInfoContent from "./MeetingInfoContent";
import { toast } from "sonner";

interface MeetingProps {
    meeting: MeetingPublic;
    queryParams: { before: string; after: string };
    children: React.ReactNode;
}

// Component for edit dropdown menu
function EditDropdownMenu({
    isCancelled,
    onCancellation,
    onEdit,
    forwardedRef,
}: {
    isCancelled: boolean;
    onCancellation: () => void;
    onEdit: () => void;
    forwardedRef: React.ForwardedRef<HTMLDivElement>;
}) {
    return (
        <DropdownMenu>
            <DropdownMenuTrigger asChild>
                <Button variant="ghost" size="sm">
                    Edytuj
                    <ChevronDown />
                </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent ref={forwardedRef} align="end">
                <DropdownMenuGroup>
                    <DropdownMenuItem onClick={onEdit}>
                        <FileEdit />
                        Edytuj
                    </DropdownMenuItem>
                    <DropdownMenuItem onClick={onCancellation}>
                        {isCancelled ? (
                            <>
                                <ArchiveRestore /> Przywróć
                            </>
                        ) : (
                            <>
                                <Ban /> Odwołaj
                            </>
                        )}
                    </DropdownMenuItem>
                </DropdownMenuGroup>
            </DropdownMenuContent>
        </DropdownMenu>
    );
}

// Component for meeting actions in footer
function MeetingActions({
    meeting,
    lvl,
    onDelete,
    onEdit,
    onCancellation,
    forwardedRef,
}: {
    meeting: MeetingPublic;
    lvl: number;
    onDelete: () => void;
    onEdit: () => void;
    onCancellation: () => void;
    forwardedRef: React.ForwardedRef<HTMLDivElement>;
}) {
    return (
        <>
            <Link to={`/calendar/meeting/${meeting.id}`} className="mr-auto">
                <Button size="sm" variant="outline">
                    <MoreVertical />
                    Więcej
                </Button>
            </Link>
            {lvl > 0 ? (
                <>
                    <EditDropdownMenu
                        isCancelled={meeting.isCancelled}
                        onCancellation={onCancellation}
                        onEdit={onEdit}
                        forwardedRef={forwardedRef}
                    />
                    <Button onClick={onDelete} variant="ghost" variantColor="destructive" size="sm">
                        <Trash2 />
                        Usuń
                    </Button>
                </>
            ) : null}
        </>
    );
}

// Component for context menu items
function MeetingContextMenuItems({
    isCancelled,
    onDelete,
    onEdit,
    onCancellation,
}: {
    isCancelled: boolean;
    onDelete: () => void;
    onEdit: () => void;
    onCancellation: () => void;
}) {
    return (
        <>
            <ContextMenuLabel>Ustawienia</ContextMenuLabel>
            <ContextMenuSeparator />
            <ContextMenuGroup>
                <ContextMenuItem onClick={onDelete} className="text-text-destructive">
                    <Trash2 />
                    Usuń
                </ContextMenuItem>
                <ContextMenuItem onClick={onEdit}>
                    <Pen />
                    Edytuj
                </ContextMenuItem>
                <ContextMenuItem onClick={onCancellation}>
                    {isCancelled ? (
                        <>
                            <ArchiveRestore />
                            Przywróć
                        </>
                    ) : (
                        <>
                            <Ban />
                            Odwołaj
                        </>
                    )}
                </ContextMenuItem>
            </ContextMenuGroup>
        </>
    );
}

// Main Meeting component
const MeetingContext = forwardRef<HTMLDivElement, MeetingProps>(
    ({ children, meeting, queryParams }, ref) => {
        const { id, startDate, endDate, name, type, desc, isCancelled } = meeting;
        const { lvl } = useCredentials();
        const breakpoint = useMediaQuery("sm");

        // State
        const [open, setOpen] = useState<boolean>(false);
        const [openEdit, setOpenEdit] = useState<boolean>(false);
        const [openDelete, setOpenDelete] = useState<boolean>(false);

        // Queries and mutations
        const { data: meetingData = {}, isPending, isSuccess } = useGetMeetingQuery(id, open);
        const { mutateAsync } = useEditMeetingMutation(queryParams);

        // Handlers
        const onSubmitCancellation = async () => {
            mutateAsync({ id: id, isCancelled: !isCancelled }).catch(error => {
                const { title, detail } = httpErrorHandler(error);
                toast.error(title, {
                    description: detail,
                });
            });
        };

        // Meeting content
        const meetingContent = (
            <div className="p-3 md:p-4 overflow-y-auto grow shrink space-y-6">
                <MeetingInfoContent
                    basicData={{ startDate, endDate, type, desc, isCancelled }}
                    data={meetingData}
                    isSuccess={isSuccess}
                    isFetching={isPending}
                />
            </div>
        );

        // View components
        const mobileView = (
            <DrawerContent ref={ref}>
                <DrawerBody>
                    <DrawerHeader className="border-border-primary border-b">
                        <DrawerTitle>{name}</DrawerTitle>
                    </DrawerHeader>
                    <DrawerDescription className={"sr-only"}>Opisz spotkania</DrawerDescription>
                    {meetingContent}
                </DrawerBody>
                <DrawerFooter className="border-border-primary border-t">
                    <MeetingActions
                        meeting={meeting}
                        lvl={lvl}
                        onDelete={() => setOpenDelete(true)}
                        onEdit={() => setOpenEdit(true)}
                        onCancellation={() => void onSubmitCancellation()}
                        forwardedRef={ref}
                    />
                </DrawerFooter>
            </DrawerContent>
        );

        const desktopView = (
            <SheetContent ref={ref} className="max-w-86">
                <SheetBody>
                    <SheetHeader className="border-border-primary border-b">
                        <SheetTitle>{name}</SheetTitle>
                    </SheetHeader>
                    <SheetDescription className={"sr-only"}>Opisz spotkania</SheetDescription>
                    {meetingContent}
                </SheetBody>
                <SheetFooter className="border-border-primary border-t">
                    <MeetingActions
                        meeting={meeting}
                        lvl={lvl}
                        onDelete={() => setOpenDelete(true)}
                        onEdit={() => setOpenEdit(true)}
                        onCancellation={() => void onSubmitCancellation()}
                        forwardedRef={ref}
                    />
                </SheetFooter>
            </SheetContent>
        );

        return (
            <>
                <EditMeeting
                    id={id}
                    open={openEdit}
                    onOpenChange={setOpenEdit}
                    queryParams={queryParams}
                />
                <DeleteMeeting
                    open={openDelete}
                    onOpenChange={setOpenDelete}
                    data={meeting}
                    queryParams={queryParams}
                />

                <ContextMenu>
                    <Drawer open={open} onOpenChange={setOpen}>
                        <Sheet open={open} onOpenChange={setOpen}>
                            <ContextMenuTrigger asChild>
                                <DrawerTrigger asChild>
                                    <SheetTrigger asChild>{children}</SheetTrigger>
                                </DrawerTrigger>
                            </ContextMenuTrigger>
                            {breakpoint ? desktopView : mobileView}
                        </Sheet>
                    </Drawer>

                    {lvl > 0 && (
                        <ContextMenuContent>
                            <MeetingContextMenuItems
                                isCancelled={isCancelled}
                                onDelete={() => setOpenDelete(true)}
                                onEdit={() => setOpenEdit(true)}
                                onCancellation={() => void onSubmitCancellation()}
                            />
                        </ContextMenuContent>
                    )}
                </ContextMenu>
            </>
        );
    },
);

MeetingContext.displayName = "MeetingContext";

export default MeetingContext;
