import { FeaturedIcon } from "@/components/ui/featured-icon/FeaturedIcon";
import { RadioGroup, RadioGroupItem } from "@/components/ui/input/radio-group/RadioGroup";
import { Label } from "@/components/ui/label/Label";
import { Calendar1, CalendarSync } from "lucide-react";
import { FormControl, FormItem } from "@/components/ui/form/Form";

interface MeetingFormMethodRadioGroupProps {
    defaultValue: string;
    onValueChange: (value: string) => void;
}

export default function MeetingFormMethodRadioGroup({
    defaultValue,
    onValueChange,
    ...props
}: MeetingFormMethodRadioGroupProps) {
    return (
        <RadioGroup
            className={"gap-2 grid-cols-1 sm:grid-cols-2 w-full"}
            defaultValue={defaultValue}
            onValueChange={onValueChange}
            {...props}
        >
            {/* Radio card #1 */}
            <FormItem>
                <FormControl>
                    <div
                        className="select-none cursor-pointer w-full bg-surface-primary hover:bg-surface-primary-hover
            border border-border-primary has-data-[state=checked]:border-border-brand relative flex gap-2 rounded-md p-3 shadow-xs outline-none grow"
                    >
                        <RadioGroupItem
                            value={"once"}
                            id={"once"}
                            aria-describedby={"once-description"}
                            className="order-1 after:absolute after:inset-0"
                        />
                        <div className="flex grow gap-3">
                            <FeaturedIcon>
                                <Calendar1 />
                            </FeaturedIcon>
                            <div className="grid gap-2 h-fit">
                                <Label htmlFor={"once"}>Jednorazowo</Label>
                                <p id={"once-description"} className="text-text-tertiary text-xs">
                                    Pojedyncze spotkanie.
                                </p>
                            </div>
                        </div>
                    </div>
                </FormControl>
            </FormItem>
            {/* Radio card #2 */}
            <FormItem>
                <FormControl>
                    <div
                        className="select-none cursor-pointer w-full bg-surface-primary hover:bg-surface-primary-hover
            border border-border-primary has-data-[state=checked]:border-border-brand relative flex gap-2 rounded-md p-3 shadow-xs outline-none grow"
                    >
                        <RadioGroupItem
                            value={"schedule"}
                            id={"schedule"}
                            aria-describedby={"schedule-description"}
                            className="order-1 after:absolute after:inset-0"
                        />
                        <div className="flex grow gap-3">
                            <FeaturedIcon>
                                <CalendarSync />
                            </FeaturedIcon>
                            <div className="grid gap-2 h-fit">
                                <Label htmlFor={"schedule"}>Harmonogram</Label>
                                <p
                                    id={"schedule-description"}
                                    className="text-text-tertiary text-xs"
                                >
                                    Cyklicznie powtarzające się spotkania.
                                </p>
                            </div>
                        </div>
                    </div>
                </FormControl>
            </FormItem>
        </RadioGroup>
    );
}
