import { cn } from "@/lib/utils";
import { Slot } from "@radix-ui/react-slot";
import * as attrAccept from "attr-accept";
import { FileWithPath, fromEvent } from "file-selector";
import { AnimatePresence, motion } from "framer-motion";
import { ChevronDown, X } from "lucide-react";
import {
    ChangeEvent,
    type DragEvent,
    HTMLAttributes,
    InputHTMLAttributes,
    RefObject,
    SyntheticEvent,
    createContext,
    useCallback,
    useContext,
    useMemo,
    useRef,
    useState,
} from "react";
import { Button } from "@/components/ui/button/Button";

// Error codes
const FILE_INVALID_TYPE = "file-invalid-type";
const FILE_TOO_LARGE = "file-too-large";
const FILE_TOO_SMALL = "file-too-small";
const TOO_MANY_FILES = "too-many-files";

const getInvalidTypeRejectionErr = (accept: string | string[]) => {
    const acceptRes = Array.isArray(accept) && accept.length === 1 ? accept[0] : accept;
    const messageSuffix = Array.isArray(acceptRes) ? `one of ${acceptRes.join(", ")}` : acceptRes;
    return {
        code: FILE_INVALID_TYPE,
        message: `File type must be ${messageSuffix}`,
    };
};

const TOO_MANY_FILES_REJECTION = {
    code: TOO_MANY_FILES,
    message: "Too many files",
};

const getTooLargeRejectionErr = (maxSize: number) => {
    return {
        code: FILE_TOO_LARGE,
        message: `File is larger than ${maxSize} ${maxSize === 1 ? "byte" : "bytes"}`,
    };
};

const getTooSmallRejectionErr = (minSize: number) => {
    return {
        code: FILE_TOO_SMALL,
        message: `File is smaller than ${minSize} ${minSize === 1 ? "byte" : "bytes"}`,
    };
};

function isMIMEType(v: string) {
    return (
        v === "audio/*" ||
        v === "video/*" ||
        v === "image/*" ||
        v === "text/*" ||
        /\w+\/[-+.\w]+/g.test(v)
    );
}

function isExt(v: string) {
    return /^.*\.[\w]+$/.test(v);
}

function acceptPropAsAcceptAttr(accept?: Record<string, string[]>) {
    if (accept) {
        return (
            Object.entries(accept)
                .reduce<string[]>((a, [mimeType, ext]) => [...a, mimeType, ...ext], [])
                // Silently discard invalid entries as pickerOptionsFromAccept warns about these
                .filter(v => isMIMEType(v) || isExt(v))
                .join(",")
        );
    }

    return "";
}

function fileAccepted(file: FileWithPath | DataTransferItem, accept: string | string[]) {
    const isAcceptable = file.type === "application/x-moz-file" || attrAccept.default(file, accept);
    return [isAcceptable, isAcceptable ? null : getInvalidTypeRejectionErr(accept)];
}

function fileMatchSize(file: FileWithPath | DataTransferItem, minSize: number, maxSize: number) {
    if ("size" in file) {
        if (isDefined(minSize) && isDefined(maxSize)) {
            if (file.size > maxSize) {
                return [false, getTooLargeRejectionErr(maxSize)];
            }
            if (file.size < minSize) {
                return [false, getTooSmallRejectionErr(minSize)];
            }
        } else if (isDefined(minSize) && file.size < minSize) {
            return [false, getTooSmallRejectionErr(minSize)];
        } else if (isDefined(maxSize) && file.size > maxSize) {
            return [false, getTooLargeRejectionErr(maxSize)];
        }
    }
    return [true, null];
}

function composeEventHandlers<T extends SyntheticEvent>(
    ...fns: ((event: T, ...args: any[]) => void | undefined)[]
) {
    return (event: T, ...args: any[]) => {
        fns.filter(Boolean).some(fn => {
            return fn(event, ...args) !== undefined;
        });
    };
}

function isDefined(value?: null | number | boolean) {
    return value !== undefined && value !== null;
}

const isDragEvent = (
    event: DragEvent<Element> | ChangeEvent<HTMLInputElement>,
): event is DragEvent<Element> => {
    return "dataTransfer" in event;
};

const isEvtWithFiles = (event: DragEvent<Element> | ChangeEvent<HTMLInputElement>) => {
    if (isDragEvent(event)) {
        return Array.prototype.some.call(
            event.dataTransfer.types,
            (type: string) => type === "Files" || type === "application/x-moz-file",
        );
    }
    return !!event.target.files;
};

interface fileRejectionsT {
    file: FileWithPath | DataTransferItem;
    errors: (boolean | { code: string; message: string } | null)[];
}

type getInputPropsT = {
    ref: RefObject<HTMLElement>;
} & InputHTMLAttributes<HTMLInputElement>;

type getRootPropsT = HTMLAttributes<HTMLDivElement>;

interface FileUploadContextProps {
    fileList: (FileWithPath | DataTransferItem)[];
    dragActive: boolean;
    disabled: boolean;
    clearFiles: () => void;
    onClick?: (
        event: React.MouseEvent<HTMLElement, MouseEvent>,
        ref: RefObject<HTMLInputElement>,
    ) => void;
    dropzoneNoClick?: boolean;
    getRootProps: (props?: getRootPropsT) => getRootPropsT;
    getInputProps: (props?: getInputPropsT) => InputHTMLAttributes<HTMLInputElement>;
}

const FileUploadContext = createContext<FileUploadContextProps | null>(null);

function useFileUpload() {
    const context = useContext(FileUploadContext);

    if (!context) {
        throw new Error("useFileUpload must be used within a <FileUpload />");
    }

    return context;
}

interface FileUploadProps {
    children: React.ReactNode;
    disabled?: boolean;
    multipleFiles?: boolean;
    dropzoneNoClick?: boolean;
    maxFiles?: number;
    minSize?: number;
    maxSize?: number;
    accept?: Record<string, string[]>;
    onError?: (e: Error) => void;
    onChange?: (
        filesAccepted: (FileWithPath | DataTransferItem)[],
        filesRejected: fileRejectionsT[],
        event: ChangeEvent<HTMLInputElement>,
    ) => void;
    onDrop?: (
        filesAccepted: (FileWithPath | DataTransferItem)[],
        filesRejected: fileRejectionsT[],
        event: DragEvent<Element>,
    ) => void;
    onDropAccepted?: (
        files: (FileWithPath | DataTransferItem)[],
        event: DragEvent<Element>,
    ) => void;
    onDropRejected?: (files: fileRejectionsT[], event: DragEvent<Element>) => void;
}

function FileUpload(props: FileUploadProps) {
    const {
        children,
        disabled = false,
        multipleFiles = true,
        minSize = 0,
        maxSize = Infinity,
        maxFiles = Infinity,
        accept,
        onChange,
        onDrop,
        onDropAccepted,
        onDropRejected,
        onError,
    } = props;

    const rootRef = useRef<HTMLElement>(null);

    const dragTargetsRef = useRef<EventTarget[]>([]);

    const [fileList, setFileList] = useState<(FileWithPath | DataTransferItem)[]>([]);

    const [dragActive, setDragActive] = useState<boolean>(false);

    const acceptAttr = useMemo(() => acceptPropAsAcceptAttr(accept), [accept]);

    const composeHandler = <FuncT,>(fn: FuncT) => {
        return disabled ? undefined : fn;
    };

    const clearFiles = useCallback(() => {
        setFileList([]);
    }, []);

    const setFiles = useCallback(
        (
            files: (FileWithPath | DataTransferItem)[],
            event: DragEvent<Element> | ChangeEvent<HTMLInputElement>,
        ) => {
            const acceptedFiles: (FileWithPath | DataTransferItem)[] = [];
            const fileRejections: fileRejectionsT[] = [];

            files.forEach(file => {
                const [accepted, acceptError] = fileAccepted(file, acceptAttr);
                const [sizeMatch, sizeError] = fileMatchSize(file, minSize, maxSize);
                // const customErrors = validator ? validator(file) : null;

                if (accepted && sizeMatch) {
                    acceptedFiles.push(file);
                } else {
                    const errors = [acceptError, sizeError];

                    fileRejections.push({ file, errors: errors.filter(e => e) });
                }
            });

            if (
                (!multipleFiles && acceptedFiles.length > 1) ||
                (multipleFiles && maxFiles >= 1 && acceptedFiles.length > maxFiles)
            ) {
                // Reject everything and empty accepted files
                acceptedFiles.forEach(file => {
                    fileRejections.push({ file, errors: [TOO_MANY_FILES_REJECTION] });
                });
                acceptedFiles.splice(0);
            }

            setFileList(acceptedFiles);

            if (event.type === "change") {
                if (onChange) {
                    onChange(acceptedFiles, fileRejections, event as ChangeEvent<HTMLInputElement>);
                }
            } else {
                if (onDrop) {
                    onDrop(acceptedFiles, fileRejections, event as DragEvent<Element>);
                }

                if (fileRejections.length > 0 && onDropRejected) {
                    onDropRejected(fileRejections, event as DragEvent<Element>);
                }

                if (acceptedFiles.length > 0 && onDropAccepted) {
                    onDropAccepted(acceptedFiles, event as DragEvent<Element>);
                }
            }
        },
        [onDrop, onDropAccepted, onDropRejected],
    );

    const onErrCb = useCallback(
        (error: any) => {
            if (onError) {
                onError(error);
            } else {
                // Let the user know something's gone wrong if they haven't provided the onError cb.
                console.error(error);
            }
        },
        [onError],
    );

    const onDragEnterCb = useCallback((event: DragEvent) => {
        event.preventDefault();
        event.persist();
        event.stopPropagation();

        dragTargetsRef.current = [...dragTargetsRef.current, event.target];

        const hasFiles = isEvtWithFiles(event);
        if (hasFiles) {
            setDragActive(true);
        }
    }, []);

    const onDragOverCb = useCallback((event: DragEvent) => {
        event.preventDefault();
        event.persist();
        event.stopPropagation();
        const hasFiles = isEvtWithFiles(event);

        if (hasFiles) {
            try {
                event.dataTransfer.dropEffect = "copy";
            } catch (_error) {
                // continue regardless of error
            }
        }
        return false;
    }, []);

    const onDragLeaveCb = useCallback(
        (event: DragEvent) => {
            event.preventDefault();
            event.persist();
            event.stopPropagation();

            // Only deactivate once the dropzone and all children have been left
            const targets = dragTargetsRef.current.filter(
                target => rootRef.current && rootRef.current.contains(target as Node),
            );
            // Make sure to remove a target present multiple times only once
            // (Firefox may fire dragenter/dragleave multiple times on the same element)
            const targetIdx = targets.indexOf(event.target as Node);
            if (targetIdx !== -1) {
                targets.splice(targetIdx, 1);
            }
            dragTargetsRef.current = targets;
            if (targets.length > 0) {
                return;
            }
            setDragActive(false);
        },
        [rootRef],
    );

    const onDropCb = useCallback((event: DragEvent) => {
        event.preventDefault();
        event.persist();
        event.stopPropagation();

        dragTargetsRef.current = [];
        setDragActive(false);

        if (isEvtWithFiles(event)) {
            Promise.resolve(fromEvent(event))
                .then(files => {
                    setFiles(files, event);
                })
                .catch(error => onErrCb(error));
        }
    }, []);

    const onChangeCb = useCallback((event: ChangeEvent<HTMLInputElement>) => {
        event.preventDefault();
        event.persist();
        event.stopPropagation();
        if (isEvtWithFiles(event)) {
            Promise.resolve(fromEvent(event))
                .then(files => {
                    setFiles(files, event);
                })
                .catch(error => onErrCb(error));
        }
    }, []);

    const onClickCb = useCallback(
        (event: React.MouseEvent<HTMLElement, MouseEvent>, ref: RefObject<HTMLInputElement>) => {
            event.preventDefault();
            event.stopPropagation();
            if (ref.current) {
                // Clear the file input value to ensure change events fire consistently
                // even when selecting the same file repeatedly
                if (ref.current.value) {
                    ref.current.value = "";
                }
                ref.current.click();
            }
        },
        [],
    );
    const getRootProps = useMemo(
        () => (props?: HTMLAttributes<HTMLDivElement>) => {
            const rootProps = {
                ref: rootRef,
                onDrop: composeHandler(onDropCb),
                onDragEnter: composeHandler(onDragEnterCb),
                onDragOver: composeHandler(onDragOverCb),
                onDragLeave: composeHandler(onDragLeaveCb),
            };

            return {
                ...props,
                ...rootProps,
            };
        },
        [onDropCb, onDragEnterCb, onDragOverCb, onDragLeaveCb],
    );
    const getInputProps = (props?: getInputPropsT) => {
        const { accept, multiple, onChange, ref, ...rest } = props || {};
        const noop = () => {};
        const inputProps = {
            accept: accept,
            multiple: isDefined(multiple) ? multiple : multipleFiles,
            type: "file",
            style: { display: "none" },
            onChange: composeHandler(
                composeEventHandlers<ChangeEvent<HTMLInputElement>>(onChange || noop, onChangeCb),
            ),
            tabIndex: -1,
            ref: ref,
        };

        return {
            ...inputProps,
            ...rest,
        };
    };

    return (
        <FileUploadContext.Provider
            value={{
                disabled,
                dragActive,
                fileList,
                clearFiles,
                getInputProps,
                getRootProps,
                onClick: composeHandler(onClickCb),
            }}
        >
            {children}
        </FileUploadContext.Provider>
    );
}

type FileUploadContentProps = HTMLAttributes<HTMLDivElement>;

function FileUploadDropzone({ children, className, ...props }: FileUploadContentProps) {
    const { getRootProps, dragActive } = useFileUpload();

    return (
        <div
            {...getRootProps(props)}
            className={cn(
                "outline-dashed outline-offset-4 outline-border-brand",
                dragActive ? "bg-surface-secondary/50 outline-1" : "outline-0",
                className,
            )}
        >
            {children}
        </div>
    );
}

interface FileUploadTriggerProps extends InputHTMLAttributes<HTMLInputElement> {
    asChild?: boolean;
    children: React.ReactNode;
    directory?: string;
    webkitdirectory?: string;
}

function FileUploadTrigger({ children, asChild = true, ...props }: FileUploadTriggerProps) {
    const { onClick, getInputProps, clearFiles } = useFileUpload();
    const ref = useRef<HTMLInputElement>(null);
    const handleOnClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        clearFiles();
        if (onClick) {
            onClick(event, ref);
        }
    };

    const Comp = asChild ? Slot : "button";
    return (
        <>
            <Comp onClick={handleOnClick}>{children}</Comp>
            <input ref={ref} {...getInputProps({ ...props, ref })} />
        </>
    );
}

interface FileUploadDrawerProps {
    callback?: () => void;
    children?: React.ReactNode;
    open: boolean;
    onOpenChange: (open: boolean) => void;
}

function FileUploadDrawer({ open, onOpenChange, callback, children }: FileUploadDrawerProps) {
    const [openCollapsible, setOpenCollapsible] = useState<boolean>(true);

    const collapsibleToggle = useCallback(() => {
        setOpenCollapsible(prev => !prev);
    }, []);

    const onClose = useCallback(() => {
        callback?.();
        onOpenChange(false);
        setOpenCollapsible(true);
    }, [callback, onOpenChange]);

    return (
        <AnimatePresence>
            {open && (
                <motion.div
                    aria-describedby={"file-upload-list"}
                    className={
                        "fixed inset-x-0 bottom-0 mt-12 flex h-auto flex-col rounded-t-lg border border-border-primary bg-surface-primary md:left-auto md:right-6 z-20 max-h-96 md:w-96 left-0 right-0 w-full"
                    }
                    initial={{ y: "100%" }}
                    animate={{ y: 0 }}
                    exit={{ y: "100%" }}
                    transition={{
                        ease: "easeInOut",
                        duration: 0.3,
                    }}
                >
                    <header className={"flex w-full justify-end p-2"}>
                        <h5 className="sr-only">File upload list</h5>

                        <Button size={"sm"} variant={"ghost"} onClick={collapsibleToggle}>
                            <ChevronDown className={cn(!openCollapsible && "rotate-[180deg]")} />
                        </Button>
                        <Button onClick={onClose} size={"sm"} variant={"ghost"}>
                            <X />
                        </Button>
                    </header>
                    <motion.div
                        className={"h-full overflow-auto"}
                        initial={{ opacity: 0, height: "auto" }}
                        animate={{
                            opacity: openCollapsible ? 1 : 0,
                            height: openCollapsible ? "auto" : 0,
                        }}
                        transition={{
                            ease: "easeInOut",
                            stiffness: 260,
                            damping: 20,
                            duration: 0.2,
                        }}
                    >
                        <div className={"flex h-full flex-col gap-2 p-3 md:p-4"}>{children}</div>
                    </motion.div>
                </motion.div>
            )}
        </AnimatePresence>
    );
}

export { FileUpload, FileUploadDropzone, FileUploadTrigger, useFileUpload, FileUploadDrawer };
