import { httpErrorHandler } from "@/api/api";
import { useDeleteLocationMutation } from "@/api/queries/locationsQueries";
import { Badge } from "@/components/ui/badge/Badge";
import {
    AlertDialog,
    AlertDialogBody,
    AlertDialogContent,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogTitle,
    AlertDialogAction,
    AlertDialogCancel,
} from "@/components/ui/dialog/AlertDialog";
import { LocationPublic } from "@/types";
import { BookText } from "lucide-react";
import { useEffect, useMemo } from "react";
import { FeaturedIcon } from "@/components/ui/featured-icon/FeaturedIcon";
import { toast } from "sonner";

type DeleteLocationProps = {
    selected: LocationPublic[] | LocationPublic;
    callback?: () => void;
    open: boolean;
    onOpenChange: (value: boolean) => void;
};
export default function DeleteLocation({
    selected,
    callback,
    open,
    onOpenChange,
}: DeleteLocationProps) {
    const isArray = Array.isArray(selected);
    const isSingle = !isArray;

    const { mutateAsync, reset } = useDeleteLocationMutation();

    useEffect(() => {
        if (open) {
            reset();
        }
    }, [open, reset]);

    const onSubmit = async () => {
        if (isArray) {
            await Promise.all(selected.map(element => mutateAsync(element.id))).catch(error => {
                const { title, detail } = httpErrorHandler(error);
                toast.error(title, {
                    description: detail,
                });
            });
            onClose();
        } else if (isSingle) {
            await mutateAsync(selected.id).catch(error => {
                const { title, detail } = httpErrorHandler(error);
                toast.error(title, {
                    description: detail,
                });
            });
            onClose();
        }
    };

    const onClose = () => {
        onOpenChange(false);
        callback?.();
    };

    const header = useMemo(() => {
        if (isSingle) {
            return " Usunąć Lokację ?";
        } else {
            return `Usunąć ${selected.length} Lokacje?`;
        }
    }, [isSingle, selected]);

    const content = useMemo(() => {
        if (isSingle) {
            return (
                <p className={"text-text-secondary"}>
                    Czy na pewno chcesz usunąć lokację{"  "}
                    <b>{selected.name}</b>?
                </p>
            );
        } else {
            return (
                <p className={"text-text-secondary"}>
                    Czy na pewno chcesz usunąć wybrane lokacje ?
                </p>
            );
        }
    }, [isSingle, selected]);

    return (
        <AlertDialog open={open} onOpenChange={onOpenChange}>
            <AlertDialogContent className={"sm:max-w-96"}>
                <AlertDialogBody>
                    <AlertDialogHeader>
                        <FeaturedIcon variant={"ghost"}>
                            <BookText />
                        </FeaturedIcon>
                        <div className={"flex flex-col gap-0.5 min-w-0 grow"}>
                            <AlertDialogTitle>{header}</AlertDialogTitle>
                            <p className={"text-text-tertiary text-xs"}>Usuń wybrane lokacje.</p>
                        </div>
                    </AlertDialogHeader>
                    <div className={"flex flex-col gap-3 px-3 md:px-4 overflow-auto"}>
                        {content}
                        {!isSingle && (
                            <div className={"flex flex-col gap-3"}>
                                <h5 className={"text-sm text-text-tertiary font-medium"}>
                                    Lokacje:
                                </h5>
                                <div className={"flex flex-wrap gap-2"}>
                                    {selected.map(({ name, id }) => {
                                        return (
                                            <Badge key={id} size={"sm"} variant={"muted"}>
                                                {name}
                                            </Badge>
                                        );
                                    })}
                                </div>
                            </div>
                        )}
                    </div>
                </AlertDialogBody>
                <AlertDialogFooter>
                    <AlertDialogCancel variant={"tertiary"} onClick={onClose}>
                        Zamknij
                    </AlertDialogCancel>
                    <AlertDialogAction onClick={onSubmit}>Potwierdź</AlertDialogAction>
                </AlertDialogFooter>
            </AlertDialogContent>
        </AlertDialog>
    );
}
