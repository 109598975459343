import { httpErrorHandler } from "@/api/api";
import { useDeletePostFileMutation, useEditPostMutation } from "@/api/queries/postsQueries";
import { Button } from "@/components/ui/button/Button";
import {
    Dialog,
    DialogBody,
    DialogContent,
    DialogDescription,
    DialogFooter,
    DialogHeader,
    DialogTitle,
} from "@/components/ui/dialog/Dialog";
import {
    Form,
    FormControl,
    FormField,
    FormItem,
    FormLabel,
    FormMessage,
} from "@/components/ui/form/Form";
import { Input } from "@/components/ui/input/Input";
import FileInput from "@/components/ui/input/file-input/FileInput";
import FileInputCard from "@/components/ui/input/file-input/FileInputCard";
import { Textarea } from "@/components/ui/input/textarea/Textarea";
import { Label } from "@/components/ui/label/Label";
import { PostEditFormSchema, PostEditFormSchemaType } from "@/schemas/post.schema";
import type { PostPublic } from "@/types";
import { serializeData } from "@/utils/serializeData";
import { yupResolver } from "@hookform/resolvers/yup";
import { ChangeEvent, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { v4 as uuidv4 } from "uuid";
import { LoaderCircle, CheckCircle2, BookText } from "lucide-react";
import { FeaturedIcon } from "@/components/ui/featured-icon/FeaturedIcon";
import { toast } from "sonner";
import type { FilePublic } from "@/types";

interface PostEditFormPropsT {
    open?: boolean;
    onOpenChange?: (bool: boolean) => void;
    post?: PostPublic;
    callback?: () => void;
}

export default function EditPost({ onOpenChange, open, post, callback }: PostEditFormPropsT) {
    const [files, setFiles] = useState<{ id: string; file: File }[]>([]);
    const [currentFiles, setCurrentFiles] = useState<FilePublic[]>([]);

    const { mutateAsync: editPost, isPending } = useEditPostMutation();
    const { mutateAsync: deletePostFile } = useDeletePostFileMutation(post?.id);

    const handleAddFiles = async (e: ChangeEvent<HTMLInputElement>) => {
        if (!e.target.files) {
            return;
        }
        const newFiles = Array.from(e.target.files).map(file => {
            if (file.size > 500 * 1024 * 1024) {
                toast.error("Błąd", {
                    description: "Maksymalny rozmiar pliku to 500MB",
                });
            }
            return {
                id: uuidv4(),
                file,
            };
        });
        setFiles(prevFiles => [...prevFiles, ...newFiles]);
        return e.target.files;
    };

    const form = useForm<PostEditFormSchemaType>({
        mode: "onBlur",
        defaultValues: {
            name: "",
            content: "",
            active: true,
            attachments: undefined,
        },
        values: post && PostEditFormSchema.cast(serializeData(post)),
        resolver: yupResolver(PostEditFormSchema),
    });

    const onRemove = (id: string) => {
        setFiles(curr => curr.filter(file => file.id !== id));
        setCurrentFiles(curr => curr.filter(file => file.id !== id));
    };

    const onSubmit = async (data: PostEditFormSchemaType) => {
        let deletePromises: Promise<string>[] = [];
        if (post?.files) {
            const filesToDelete = post.files.filter(
                file => !currentFiles.some(curr => curr.id === file.id),
            );
            deletePromises = filesToDelete.map(file => deletePostFile(file.id));
        }

        const attachments = files.map(({ file }) => file);
        const editPostPromise = editPost({ ...data, attachments });

        Promise.all([...deletePromises, editPostPromise])
            .then(() => {
                onClose();
            })
            .catch(error => {
                const { title, detail } = httpErrorHandler(error);
                toast.error(title, {
                    description: detail,
                });
            });
    };

    const onClose = () => {
        callback?.();
        onOpenChange?.(false);
        setFiles([]);
        form.reset();
    };

    useEffect(() => {
        if (open && post && post.files.length) {
            setCurrentFiles(post.files);
        }
    }, [open, post]);

    return (
        <Dialog open={open} onOpenChange={onOpenChange}>
            <DialogContent
                onCloseAutoFocus={onClose}
                onEscapeKeyDown={onClose}
                className={"sm:max-w-148"}
            >
                <DialogDescription className={"sr-only"}>
                    Formularz do edycji postu
                </DialogDescription>
                <Form {...form}>
                    <DialogBody>
                        {isPending && (
                            <div
                                className={
                                    "absolute inset-0 z-60 m-auto flex flex-wrap items-center justify-center gap-3 bg-surface-primary/75 backdrop-blur-md"
                                }
                            >
                                <LoaderCircle className={"animate-spin"} aria-hidden={"true"} />
                                <h5 className={"font-medium"}>Trwa edycja postu...</h5>
                            </div>
                        )}
                        <DialogHeader className={"border-b border-border-primary"}>
                            <FeaturedIcon variant={"ghost"}>
                                <BookText />
                            </FeaturedIcon>
                            <div className={"flex flex-col gap-0.5 min-w-0 grow"}>
                                <DialogTitle>Edytuj Post</DialogTitle>
                                <p className={"text-text-tertiary text-xs"}>Edytuj opisz postu</p>
                            </div>
                        </DialogHeader>
                        <form
                            className={"flex flex-col gap-3 p-3 md:p-4 overflow-auto grow"}
                            onSubmit={form.handleSubmit(onSubmit)}
                            noValidate
                        >
                            <FormField
                                name={"name"}
                                control={form.control}
                                render={({ field }) => (
                                    <FormItem>
                                        <FormLabel>Tytuł</FormLabel>
                                        <FormControl>
                                            <Input {...field} placeholder={"Tytuł"} />
                                        </FormControl>
                                        <FormMessage />
                                    </FormItem>
                                )}
                            />
                            <FormField
                                name={"content"}
                                control={form.control}
                                render={({ field }) => (
                                    <FormItem>
                                        <FormLabel>Treść</FormLabel>
                                        <FormControl>
                                            <Textarea
                                                placeholder={"Co chcesz przekazać ?"}
                                                {...field}
                                            />
                                        </FormControl>
                                        <FormMessage />
                                    </FormItem>
                                )}
                            />
                            <div className={"flex flex-col gap-2"}>
                                <Label>Media</Label>
                                <FileInput onAddFiles={handleAddFiles}>
                                    {files.map(({ id, file }) => (
                                        <FileInputCard
                                            key={id}
                                            file={file}
                                            onRemove={() => onRemove(id)}
                                        />
                                    ))}
                                    {currentFiles.map(file => (
                                        <FileInputCard
                                            key={file.id}
                                            file={file}
                                            onRemove={() => onRemove(file.id)}
                                        />
                                    ))}
                                </FileInput>
                            </div>
                        </form>
                    </DialogBody>
                    <DialogFooter className={"border-t border-border-primary"}>
                        <Button
                            onClick={onClose}
                            type={"button"}
                            variant={"tertiary"}
                            className={"w-full md:w-fit"}
                        >
                            Anuluj
                        </Button>
                        <Button
                            disabled={isPending}
                            onClick={form.handleSubmit(onSubmit)}
                            type={"submit"}
                            variant={"primary"}
                            className={"w-full md:w-fit"}
                        >
                            {isPending ? (
                                <>
                                    <LoaderCircle className={"animate-spin"} aria-hidden={"true"} />
                                    Zapisywanie...
                                </>
                            ) : (
                                <>
                                    <CheckCircle2 />
                                    Zapisz
                                </>
                            )}
                        </Button>
                    </DialogFooter>
                </Form>
            </DialogContent>
        </Dialog>
    );
}
