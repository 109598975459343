import { Avatar, AvatarFallback, AvatarImage } from "@/components/ui/avatar/Avatar";
import { Badge } from "@/components/ui/badge/Badge";
import { Button } from "@/components/ui/button/Button";
import {
    Drawer,
    DrawerBody,
    DrawerContent,
    DrawerHeader,
    DrawerTitle,
    DrawerTrigger,
} from "@/components/ui/drawer/Drawer";

import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuGroup,
    DropdownMenuItem,
    DropdownMenuRadioGroup,
    DropdownMenuRadioItem,
    DropdownMenuSeparator,
    DropdownMenuTrigger,
} from "@/components/ui/input/dropdown-menu/DropdownMenu";
import { RadioGroup, RadioGroupItem } from "@/components/ui/input/radio-group/RadioGroup";
import {
    ListItem,
    ListItemContent,
    ListItemDescription,
    ListItemTitle,
} from "@/components/ui/list/List";
import { Separator } from "@/components/ui/separator/Separator";
import { useMediaQuery } from "@/hooks/useMediaQuery";
import { getAvatar } from "@/utils/getAvatar";
import { Label } from "@radix-ui/react-label";
import { ChevronDown, UserX, Users } from "lucide-react";
import { useCallback, useMemo, useState } from "react";
import useFSEntryPermissions from "./hooks/useFSEntryPermissions";
import type { Permissions, PermissionSource } from "@/types";

interface EntityData {
    id: number;
    title: string;
    detail?: string;
    avatarURL?: string;
}

interface PermissionLiEntityProps {
    data: EntityData;
    sources?: PermissionSource[];
    permissions: Permissions;
    onRemove: () => void;
    onPermissionChange: (id: number, newValue: string) => void;
}

export default function PermissionLiEntity({
    data,
    permissions,
    sources,
    onRemove,
    onPermissionChange,
}: PermissionLiEntityProps) {
    const isDesktop = useMediaQuery("sm");
    const { currentPermission, permissionsArray } = useFSEntryPermissions({
        permissions,
    });

    const [access, setAccess] = useState<string>(currentPermission.type);
    const isInherited = useMemo(() => sources?.some(source => source.isInherited), [sources]);

    const handleAccessChange = useCallback(
        (value: string) => {
            onPermissionChange(data.id, value);
            setAccess(value);
        },
        [data.id, onPermissionChange],
    );

    // Common content for both mobile and desktop views
    const avatarContent = (
        <Avatar size={"md"}>
            <AvatarImage src={getAvatar(data.avatarURL)} alt={"avatar"} />
            <AvatarFallback>
                <Users />
            </AvatarFallback>
        </Avatar>
    );

    const textContent = (
        <ListItemContent>
            <ListItemTitle>{data.title}</ListItemTitle>
            <ListItemDescription>{currentPermission.title}</ListItemDescription>
        </ListItemContent>
    );

    // Action button based on inheritance and device
    const actionButton = useMemo(() => {
        if (isInherited) {
            return (
                <Badge variant={"muted"} border size={"sm"} className="ml-auto">
                    Dziedziczone
                </Badge>
            );
        }

        if (isDesktop) {
            return (
                <DropdownMenuTrigger asChild>
                    <Button
                        className={"w-[18ch] min-w-[18ch] ml-auto"}
                        variant={"outline"}
                        size={"sm"}
                    >
                        {currentPermission.title}
                        <ChevronDown className={"ml-auto size-3"} />
                    </Button>
                </DropdownMenuTrigger>
            );
        }

        return <ChevronDown className={"ml-auto size-4 stroke-icon-tertiary"} />;
    }, [isInherited, isDesktop, currentPermission.title]);

    // Base list item that's used in all scenarios
    const listItemContent = (
        <ListItem size={"double"}>
            {avatarContent}
            {textContent}
            {actionButton}
        </ListItem>
    );

    // Return different views based on inheritance and device type
    if (isInherited) {
        return listItemContent;
    }

    if (isDesktop) {
        return (
            <DropdownMenu>
                {listItemContent}
                <DropdownMenuContent align={"end"}>
                    <DropdownMenuRadioGroup value={access} onValueChange={handleAccessChange}>
                        {permissionsArray.map(permission => (
                            <DropdownMenuRadioItem key={permission.type} value={permission.type}>
                                {permission.title}
                            </DropdownMenuRadioItem>
                        ))}
                    </DropdownMenuRadioGroup>
                    <DropdownMenuSeparator />
                    <DropdownMenuGroup>
                        <DropdownMenuItem className={"text-text-destructive"} onClick={onRemove}>
                            <UserX />
                            Usuń dostęp
                        </DropdownMenuItem>
                    </DropdownMenuGroup>
                </DropdownMenuContent>
            </DropdownMenu>
        );
    }

    return (
        <Drawer>
            <DrawerTrigger asChild>{listItemContent}</DrawerTrigger>
            <DrawerContent>
                <DrawerHeader>
                    <DrawerTitle>Poświadczenia</DrawerTitle>
                </DrawerHeader>
                <DrawerBody className={"flex flex-col gap-4"}>
                    <RadioGroup value={access} onValueChange={handleAccessChange}>
                        {permissionsArray.map(permission => (
                            <div key={permission.type} className="flex items-center space-x-2">
                                <RadioGroupItem value={permission.type} id={permission.type} />
                                <Label htmlFor={permission.type}>{permission.title}</Label>
                            </div>
                        ))}
                    </RadioGroup>
                    <Separator />
                    <Button
                        onClick={onRemove}
                        className={"w-full"}
                        variant={"ghost"}
                        variantColor={"destructive"}
                    >
                        <UserX />
                        Usuń dostęp
                    </Button>
                </DrawerBody>
            </DrawerContent>
        </Drawer>
    );
}
