"use no memo";

import FsFilters from "@/components/features/files/FsFilters";
import FilesManageBar from "@/components/features/files/layouts/header/FilesManageBar";
import FSPath from "@/components/features/files/FsPath";
import FilesDriveTypeList from "@/components/features/files/layouts/header/FilesDriveTypeList";
import FilesActionButtons from "@/components/features/files/layouts/header/FilesActionButtons";
import { cn } from "@/lib/utils";
import { useCredentials } from "@/store/authStore";
import { useFilesStorage } from "@/store/filesStore";
import { Table } from "@tanstack/react-table";
import { motion } from "framer-motion";
import { Dispatch, SetStateAction, useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { useMediaQuery } from "@/hooks/useMediaQuery";
import { FilesNavigationBack } from "@/components/features/files/layouts/header/FilesNavigationBack";
import type { DirectoryPrivate } from "@/types";

interface StorageHeaderNavigationProps<T> {
    fsEntry?: DirectoryPrivate;
    dataModel: Table<T>;
    setGlobalFilter: Dispatch<SetStateAction<string>>;
    isLoading: boolean;
    isError: boolean;
    showTabsList: boolean;
}

export default function FilesPageHeader<ModelType>({
    fsEntry,
    isError,
    isLoading,
    showTabsList,
    dataModel,
    setGlobalFilter,
}: StorageHeaderNavigationProps<ModelType>) {
    const breakpoint = useMediaQuery("md");
    const navigate = useNavigate();
    const [open, setOpen] = useState<boolean>(false);
    const { model, permissions, setModel, setOpenCreateDir, selectedSize } = useFilesStorage();

    const size = selectedSize();

    const { directoryId: defaultDirectoryId } = useCredentials();
    const { id } = useParams();

    // Reset filters when breakpoint changes
    useEffect(() => {
        setOpen(false);
        setGlobalFilter("");
    }, [breakpoint, dataModel]);

    const handleOnCreateDir = useCallback(() => {
        setOpenCreateDir(true);
    }, [setOpenCreateDir]);

    const handleToggleFilters = useCallback(() => {
        setOpen(prev => !prev);
    }, [setGlobalFilter]);

    const handleModelChange = useCallback(
        (value: string) => {
            setModel(value as "my_drive" | "shared_with_me");
        },
        [setModel],
    );

    const navigateBack = useCallback(() => {
        const parentDirId = fsEntry?.parentDir.id;
        if (parentDirId === defaultDirectoryId) {
            navigate("/fs");
        } else {
            navigate(`/fs/dir/${parentDirId}`);
        }
    }, [fsEntry, defaultDirectoryId, navigate]);

    const isActionsDisabled = isError || isLoading || !permissions.write;
    const isSelectionActive = size > 0;

    return (
        <header className="grid auto-rows-auto grid-cols-2 gap-3 rounded-lg border border-border-primary bg-surface-primary p-3 md:p-4">
            {/* Path navigation section */}
            <div className="row-[1] flex gap-0.5 col-[1_/_span_2] md:col-[1] h-12">
                <FilesNavigationBack onClick={navigateBack} show={!!id} />
                <FSPath fsEntry={fsEntry} isLoading={isLoading} isError={isError} />
            </div>

            {/* Action buttons section */}
            <div
                className={cn(
                    "col-[2] md:row-[1] row-[2] flex shrink-0 justify-end gap-2 h-12",
                    isSelectionActive && "hidden md:flex",
                )}
            >
                <FilesActionButtons
                    onCreateDir={handleOnCreateDir}
                    onToggleFilters={handleToggleFilters}
                    isDisabled={isActionsDisabled}
                    openFilters={open}
                />
            </div>

            {/* Selection management bar - conditionally rendered */}
            <FilesManageBar className={cn("col-[1_/_span_2] row-[2] h-12")} />

            {/* Tabs section */}
            <FilesDriveTypeList
                value={model}
                onValueChange={handleModelChange}
                className="col-[1] row-[2] h-12 flex items-end"
                hidden={!showTabsList || isSelectionActive}
            />

            {/* Filters section - animated */}
            <motion.section
                data-open={open}
                initial={{ opacity: 0, height: "auto" }}
                exit={{ opacity: 0, height: 0 }}
                animate={{
                    opacity: open || breakpoint ? 1 : 0,
                    height: open || breakpoint ? "auto" : 0,
                }}
                transition={{
                    ease: "easeInOut",
                    stiffness: 260,
                    damping: 20,
                    duration: 0.25,
                }}
                className={cn(
                    "md:col-[2] md:row-[2] col-[1_/_span_2] h-12",
                    open || breakpoint ? "row-[3]" : "",
                    isSelectionActive && "hidden",
                )}
            >
                <FsFilters
                    className={
                        "md:col-[2] md:row-[2] col-[1_/_span_2] row-[3] md:h-12 md:items-end"
                    }
                    dataModel={dataModel}
                    setGlobalFilter={setGlobalFilter}
                />
            </motion.section>
        </header>
    );
}
