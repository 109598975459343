import type { DirectoryPublic } from "@/types";
import type { FilePublic } from "@/types";

export function getIsFSEntityFile(entity?: DirectoryPublic | FilePublic): boolean {
    if (!entity) {
        return false;
    }
    return "fileType" in entity;
}

export function geFileExtension(file?: File | string): string {
    if (!file) {
        return "";
    }

    if (file instanceof File) {
        const ext = file.name.split(".").pop() || "";
        return ext.slice(0, 3);
    }
    const ext = file.split(".").pop() || "";
    return ext.slice(0, 3);
}
