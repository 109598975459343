import { useCallback, useEffect, useMemo } from "react";
import { useLocation } from "react-router-dom";

interface LocationActionOptions<T> {
    actionName: string;
    onActionTrigger: () => void;
}

export function useLocationActions<T>({ actionName, onActionTrigger }: LocationActionOptions<T>) {
    const location = useLocation();

    // Check if current location state has the specified action
    const isActionTriggered = useMemo(
        () => !!(location.state?.action === actionName),
        [location, actionName],
    );

    // Clear location state from history
    const clearLocationState = useCallback(() => {
        window.history.replaceState({}, "");
    }, []);

    // Extract default values from location state if present
    const value = useMemo(() => {
        if (location.state?.value) {
            clearLocationState();
            return location.state.value as T;
        }
        return undefined;
    }, [clearLocationState, location.state]);

    // Trigger action callback when action is detected in location state
    useEffect(() => {
        if (isActionTriggered) {
            onActionTrigger();
        }
    }, [isActionTriggered, onActionTrigger]);

    return { isActionTriggered, value, clearLocationState };
}
