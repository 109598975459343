import { Avatar, AvatarFallback, AvatarImage } from "@/components/ui/avatar/Avatar";
import { Skeleton } from "@/components/ui/skeleton/Skeleton";
import { useCredentials } from "@/store/authStore";
import { ConversationPublic } from "@/types";
import { User, Users } from "lucide-react";
import { forwardRef, HTMLAttributes, useCallback, useMemo, useEffect, useState } from "react";
import { Link, NavLink } from "react-router-dom";
import type { ConversationType } from "@/types";
import { formatDate } from "@/utils/dateFormat";
import { parseISO } from "date-fns";
import { getAvatar } from "@/utils/getAvatar";

export const ConversationsListSkeletonItem = forwardRef<
    HTMLLIElement,
    HTMLAttributes<HTMLLIElement>
>((props = {}, ref) => {
    return (
        <li ref={ref} className={"inline-flex items-center gap-2 rounded-md w-full p-2"} {...props}>
            <Skeleton className={"size-10 shrink-0 rounded-md"} />
            <div className={"space-y-2 w-full"}>
                <Skeleton className={"h-3 w-full max-w-[20ch]"} />
                <Skeleton className={"h-3 w-full max-w-[14ch]"} />
            </div>
        </li>
    );
});

ConversationsListSkeletonItem.displayName = "ConversationsListSkeletonItem";

type ConversationItemTimeToPresentProps = {
    createdAt?: string;
};

function ConversationItemTimeToPresent({ createdAt }: ConversationItemTimeToPresentProps) {
    const [data, setData] = useState<string>("");

    useEffect(() => {
        const formatTimeToPresentDate = (createdAt?: string) => {
            if (!createdAt) return;
            setData(formatDate(parseISO(createdAt)));
        };

        formatTimeToPresentDate(createdAt);

        const interval = setInterval(() => formatTimeToPresentDate(createdAt), 300000);

        return () => clearInterval(interval);
    }, [createdAt]);

    return <p className={"text-xs shrink-0 whitespace-nowrap text-text-tertiary"}>{data}</p>;
}

type ConversationItemContentProps = {
    name: string;
    message: string | null;
    type: ConversationType;
    avatarURL?: string;
    messageCreatedAt?: string;
};

function ConversationItemContent({
    name,
    message,
    avatarURL,
    type,
    messageCreatedAt,
}: ConversationItemContentProps) {
    return (
        <>
            <Avatar size={"md"}>
                <AvatarImage src={getAvatar(avatarURL)} alt={"avatar"} />
                <AvatarFallback>{type === "group" ? <Users /> : <User />}</AvatarFallback>
            </Avatar>
            <div className="space-y-0.5 flex flex-col grow min-w-0 overflow-hidden">
                <h5 className="truncate w-full text-sm font-medium text-text-primary">{name}</h5>
                <p className="truncate text-xs text-text-tertiary">{message}</p>
            </div>
            <div className="shrink-0">
                <ConversationItemTimeToPresent createdAt={messageCreatedAt} />
            </div>
        </>
    );
}

export function ConversationsListItem({ id, name, type, message, avatarURL }: ConversationPublic) {
    const { id: userId } = useCredentials();

    const getObjectKeyByValue = useCallback(
        (obj: Record<string, string>, targetKey: number | undefined) => {
            const entry = Object.entries(obj).find(([key]) => String(key) === String(targetKey));
            return entry ? entry[1] : undefined;
        },
        [],
    );

    const chatName = useMemo(() => {
        if (type === "individual") {
            try {
                return getObjectKeyByValue(JSON.parse(name), userId);
            } catch (error) {
                console.error("Error parsing chat name:", error);
                return name;
            }
        }
        return name;
    }, [type, name, getObjectKeyByValue, userId]);

    const chatMessage = useMemo(() => {
        if (message?.sender) {
            return `${message.sender.name} ${message.sender.surname.at(0) || ""}: ${message.content}`;
        }
        return "brak wiadomości";
    }, [message]);

    const messageCreatedAt = message?.createdAt;

    const linkState = useMemo(
        () => ({
            id,
            type,
            name: chatName,
            avatarURL,
        }),
        [id, type, chatName],
    );

    return (
        <li className={"py-px"}>
            <NavLink
                to={`/chat/${String(id)}`}
                state={linkState}
                className={({ isActive }) =>
                    `group relative p-2 gap-2 items-center inline-flex w-full overflow-hidden rounded-sm ease-out duration-100 transition-colors transition-outline opacity-100 cursor-pointer aria-disabled:cursor-not-allowed disabled:pointer-events-none outline-ring-focus outline-0 focus-visible:outline-2 disabled:opacity-75 bg-fill-primary text-text-primary hover:bg-fill-primary-hover active:bg-fill-primary-active ${isActive ? "bg-fill-primary-active/25" : ""}`
                }
            >
                <ConversationItemContent
                    avatarURL={avatarURL}
                    name={chatName || ""}
                    message={chatMessage}
                    type={type}
                    messageCreatedAt={messageCreatedAt}
                />
            </NavLink>
        </li>
    );
}
