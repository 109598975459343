import { useInfiniteQueryResult } from "@/api/api-utils";
import {
    NotificationCard,
    NotificationCardSkeleton,
} from "@/components/features/notifications/NotificationCard";
import NotificationsEmpty from "@/components/features/notifications/NotificationsEmpty";
import { InfiniteScroll } from "@/components/ui/infinite-scroll/InfiniteScroll";
import { NotificationPublic } from "@/types";
import { InfiniteData, UseInfiniteQueryResult } from "@tanstack/react-query";

type NotificationsListProps = UseInfiniteQueryResult<
    InfiniteData<NotificationPublic[], unknown>,
    Error
> & { makeNotificationsRead: (id: number[]) => void };

export default function NotificationsList(props: NotificationsListProps) {
    const {
        data,
        fetchNextPage,
        hasNextPage,
        isFetching,
        isLoading,
        isError,
        error,
        makeNotificationsRead,
    } = props;

    const { data: notifications, isEmpty } = useInfiniteQueryResult(data);

    const next = () => {
        fetchNextPage();
    };

    return (
        <div className="p-3 md:p-4 overflow-y-auto grow shrink space-y-2">
            {isError ? (
                <span className={"w-full py-8 text-center text-sm italic text-text-destructive"}>
                    {error.message}
                </span>
            ) : null}
            {isEmpty && !isLoading && !isError ? <NotificationsEmpty /> : null}
            {notifications.map(notification => (
                <NotificationCard
                    key={notification.id}
                    makeRead={id => makeNotificationsRead([id])}
                    {...notification}
                />
            ))}

            {isLoading && (
                <>
                    <NotificationCardSkeleton />
                    <NotificationCardSkeleton />
                    <NotificationCardSkeleton />
                </>
            )}
            <InfiniteScroll
                hasNextPage={hasNextPage}
                isLoading={isFetching}
                isFetching={isFetching}
                next={next}
                threshold={0.65}
                root={null}
            >
                {hasNextPage && !isError && <NotificationCardSkeleton />}
            </InfiniteScroll>
        </div>
    );
}
