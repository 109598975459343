import { useInfiniteQueryResult } from "@/api/api-utils";
import {
    useEditDirectoryMutation,
    useGetDirectoryPermissionsQuery,
} from "@/api/queries/directoryQueries";
import { useEditFileMutation, useGetFilePermissionsQuery } from "@/api/queries/filesQueries";
import { useGetGroupsInfiniteQuery } from "@/api/queries/groupsQueries";
import { useGetUsersInfiniteQuery } from "@/api/queries/usersQueries";
import PermissionLiEntity from "@/components/features/files/PermissionLiEntity";
import { Button } from "@/components/ui/button/Button";
import {
    Dialog,
    DialogBody,
    DialogContent,
    DialogFooter,
    DialogHeader,
    DialogTitle,
    DialogDescription,
} from "@/components/ui/dialog/Dialog";
import { Input, InputWrapper } from "@/components/ui/input/Input";
import { SelectDataListElement } from "@/components/ui/select-list/SelectDataListElement";
import SelectDataList from "@/components/ui/select-list/SelectDataList";
import { Tabs, TabsContent } from "@/components/ui/tabs/Tabs";
import { getIsFSEntityFile } from "@/utils/files";
import { ChevronLeft, CircleX, Plus, Search, LoaderCircle, CheckCircle2 } from "lucide-react";
import { Dispatch, SetStateAction, useCallback, useEffect, useMemo, useState } from "react";
import { cn } from "@/lib/utils";
import { SelectDataListLabel } from "@/components/ui/select-list/SelectDataList";
import { List } from "@/components/ui/list/List";
import usePermissionChanges from "../hooks/usePermissionChanges";
import { toast } from "sonner";

import {
    UserPublic,
    GroupPermissions,
    UserPermissions,
    DirectoryPublic,
    FilePublicWithPermissions,
} from "@/types";

type DialogPageType = "main" | "usersSelectedList" | "groupsSelectedList";

interface ShareFSEntryProps {
    element?: FilePublicWithPermissions | DirectoryPublic;
    open: boolean;
    onOpenChange: (open: boolean) => void;
    callback?: () => void;
    directoryId: string;
}

export default function ShareFSEntry({
    element,
    open,
    directoryId,
    onOpenChange,
    callback,
}: ShareFSEntryProps) {
    // State management
    const [tabs, setTabs] = useState<DialogPageType>("main");
    const [searchValue, setSearchValue] = useState<string>("");
    const [selectedUsers, setSelectedUsers] = useState<UserPermissions[]>([]);
    const [selectedGroups, setSelectedGroups] = useState<GroupPermissions[]>([]);

    // Derived state
    const isMainTab = tabs === "main";
    const isFile = getIsFSEntityFile(element);

    // API mutations
    const { mutateAsync: directoriesMutation, isPending: isDirectoryMutationPending } =
        useEditDirectoryMutation(directoryId);

    const {
        mutateAsync: filesMutation,
        isPending: isFileMutationPending,
        reset,
    } = useEditFileMutation();

    const isPending = useMemo(
        () => isDirectoryMutationPending || isFileMutationPending,
        [isDirectoryMutationPending, isFileMutationPending],
    );

    // Permissions queries
    const {
        data: fileData,
        isError: fileIsError,
        isSuccess: fileIsSuccess,
        isLoading: fileIsLoading,
    } = useGetFilePermissionsQuery(element?.id, open && isFile);
    const {
        data: dirData,
        isError: dirIsError,
        isSuccess: dirIsSuccess,
        isLoading: dirIsLoading,
    } = useGetDirectoryPermissionsQuery(element?.id, open && !isFile);

    // Use custom hook for generating permission changes
    const permissionPayload = usePermissionChanges({
        selectedUsers,
        selectedGroups,
        defaultUsers: isFile ? fileData?.users : dirData?.users,
        defaultGroups: isFile ? fileData?.groups : dirData?.groups,
    });

    const isLoading = isFile ? fileIsLoading : dirIsLoading;

    // Handlers
    const onClose = useCallback(() => {
        setSelectedUsers([]);
        setSelectedGroups([]);
        onOpenChange(false);
        setSearchValue("");
        reset();
        callback?.();
    }, [callback, onOpenChange, reset]);

    const onSubmit = async () => {
        if (!element) {
            toast.error("Błąd", {
                description: "Formularz nie zawiera poprawnego pliku lub folderu",
            });
            return;
        }

        try {
            if (isFile) {
                await filesMutation({
                    id: element.id,
                    permissionChanges: permissionPayload,
                });
            } else {
                await directoriesMutation({
                    id: element.id,
                    permissionChanges: permissionPayload,
                });
            }
            onClose();
        } catch (_error) {
            toast.error("Błąd", {
                description: "Nie udało się zaktualizować uprawnień",
            });
        }
    };

    useEffect(() => {
        if (fileIsSuccess && open) {
            setSelectedUsers(fileData.users);
            setSelectedGroups(fileData.groups);
        }
    }, [fileIsSuccess, open, fileData]);

    useEffect(() => {
        if (dirIsSuccess && open) {
            setSelectedUsers(dirData.users);
            setSelectedGroups(dirData.groups);
        }
    }, [fileIsSuccess, open, fileData]);

    // Load initial permissions data
    useEffect(() => {
        if (fileIsError || dirIsError) {
            onClose();
            toast.error("Błąd", {
                description: `Nie udało się pobrać uprawnień do ${isFile ? "pliku" : "folderu"}`,
            });
            return;
        }
    }, [fileIsError, dirIsError, onClose, toast]);

    return (
        <Dialog open={open} onOpenChange={onOpenChange}>
            <DialogContent
                isClosable={isMainTab}
                className={"sm:max-w-148 overflow-x-hidden"}
                onCloseAutoFocus={onClose}
                onEscapeKeyDown={onClose}
            >
                <DialogDescription className={"sr-only"}>
                    Udostępnij plik lub folder
                </DialogDescription>
                <DialogBody>
                    {isLoading && (
                        <div
                            className={
                                "absolute inset-0 z-60 m-auto flex flex-wrap items-center justify-center gap-3 bg-surface-primary/75 backdrop-blur-md"
                            }
                        >
                            <LoaderCircle className={"animate-spin"} aria-hidden={"true"} />
                            <h5 className={"font-medium"}>Trwa ładowanie danych...</h5>
                        </div>
                    )}
                    {isMainTab ? (
                        <DialogHeader className={"border-b border-border-primary"}>
                            <DialogTitle>Dostęp: {element?.name}</DialogTitle>
                        </DialogHeader>
                    ) : (
                        <DialogHeader className={"border-b border-border-primary"}>
                            <DialogTitle className={"sr-only"}>Dostęp</DialogTitle>
                            <Button variant={"outline"} onClick={() => setTabs("main")}>
                                <ChevronLeft />
                            </Button>
                            <InputWrapper className={"w-full"}>
                                <Input
                                    value={searchValue}
                                    onChange={e => setSearchValue(e.target.value)}
                                    className="peer pe-8 ps-8"
                                    placeholder="Wyszukaj..."
                                />
                                <div className="pointer-events-none absolute inset-y-0 start-0 flex items-center justify-center ps-2 peer-disabled:opacity-50">
                                    <Search className="size-3 stroke-icon-tertiary" />
                                </div>
                                {searchValue && (
                                    <button
                                        className="absolute cursor-pointer inset-y-0 end-0 flex h-full w-9 items-center justify-center rounded-e-sm text-text-tertiary transition-colors hover:text-text-tertiary-hover z-10 focus-visible:outline-2 outline-ring-focus disabled:pointer-events-none disabled:cursor-not-allowed disabled:opacity-50"
                                        aria-label="Clear input"
                                        onClick={() => setSearchValue("")}
                                    >
                                        <CircleX className={"size-3"} aria-hidden="true" />
                                    </button>
                                )}
                            </InputWrapper>
                        </DialogHeader>
                    )}

                    <Tabs
                        value={tabs}
                        onValueChange={value => {
                            setSearchValue("");
                            setTabs(value as DialogPageType);
                        }}
                        className={"flex flex-col gap-3 p-3 md:p-4 overflow-auto grow"}
                    >
                        <TabsContent value={"main"} className={"space-y-6"}>
                            <ShareFSEntryUsersList
                                setSelected={setSelectedUsers}
                                setTab={setTabs}
                                selected={selectedUsers}
                            />
                            <ShareFSEntryGroupsList
                                setSelected={setSelectedGroups}
                                setTab={setTabs}
                                selected={selectedGroups}
                            />
                        </TabsContent>
                        <TabsContent
                            value={"usersSelectedList"}
                            className={"flex flex-col data-[state=active]:h-full"}
                        >
                            <ShareFSEntryUsersSelectedDataList
                                searchValue={searchValue}
                                selected={selectedUsers}
                                open={open}
                                setSelected={setSelectedUsers}
                            />
                        </TabsContent>
                        <TabsContent
                            value={"groupsSelectedList"}
                            className={"flex flex-col data-[state=active]:h-full"}
                        >
                            <ShareFSEntryGroupsSelectedDataList
                                searchValue={searchValue}
                                selected={selectedGroups}
                                open={open}
                                setSelected={setSelectedGroups}
                            />
                        </TabsContent>
                    </Tabs>
                </DialogBody>
                <DialogFooter
                    className={cn("border-t border-border-primary", !isMainTab && "hidden")}
                >
                    <Button
                        onClick={onClose}
                        type={"button"}
                        variant={"tertiary"}
                        className={"w-full md:w-fit"}
                    >
                        Anuluj
                    </Button>
                    <Button
                        disabled={isPending}
                        onClick={onSubmit}
                        type={"submit"}
                        variant={"primary"}
                        className={"w-full md:w-fit"}
                    >
                        {isPending ? (
                            <>
                                <LoaderCircle className={"animate-spin"} aria-hidden={"true"} />
                                Zapisywanie...
                            </>
                        ) : (
                            <>
                                <CheckCircle2 />
                                Zapisz
                            </>
                        )}
                    </Button>
                </DialogFooter>
            </DialogContent>
        </Dialog>
    );
}

interface ShareFSEntryListProps<T> {
    selected: T[];
    setSelected: Dispatch<SetStateAction<T[]>>;
    setTab: Dispatch<SetStateAction<DialogPageType>>;
}

function ShareFSEntryUsersList(props: ShareFSEntryListProps<UserPermissions>) {
    const { selected, setTab, setSelected } = props;

    const handleRemoveEntity = useCallback(
        (id: number) => {
            setSelected(prev => prev.filter(user => user.id !== id));
        },
        [setSelected],
    );
    const handlePermissionChange = useCallback(
        (id: number, newValue: string) => {
            setSelected(prev =>
                prev.map(user =>
                    user.id === id
                        ? {
                              ...user,
                              permissions: {
                                  read: false,
                                  write: false,
                                  edit: false,
                                  [newValue]: true,
                              },
                          }
                        : user,
                ),
            );
        },
        [setSelected],
    );

    return (
        <div className={"flex flex-col gap-4"}>
            <div className={"flex justify-between"}>
                <h5 className={"font-medium"}>Użytkownicy:</h5>
                <Button size="sm" variant="tertiary" onClick={() => setTab("usersSelectedList")}>
                    <Plus />
                    Dodaj
                </Button>
            </div>

            <List>
                {selected.length === 0 && (
                    <li className="flex py-4">
                        <p className="text-sm italic text-text-tertiary">Dodaj użytkowników</p>
                    </li>
                )}
                {selected.map(entity => (
                    <PermissionLiEntity
                        key={entity.id}
                        data={{
                            id: entity.id,
                            title: entity.name + " " + entity.surname,
                            avatarURL: entity.avatarURL,
                        }}
                        permissions={entity.permissions}
                        sources={entity.sources}
                        onPermissionChange={(id, value) => handlePermissionChange(id, value)}
                        onRemove={() => {
                            handleRemoveEntity(entity.id);
                        }}
                    />
                ))}
            </List>
        </div>
    );
}

function ShareFSEntryGroupsList(props: ShareFSEntryListProps<GroupPermissions>) {
    const { selected, setTab, setSelected } = props;

    const handleRemoveEntity = useCallback(
        (id: number) => {
            setSelected(prev => prev.filter(group => group.id !== id));
        },
        [setSelected],
    );
    const handlePermissionChange = useCallback(
        (id: number, newValue: string) => {
            setSelected(prev =>
                prev.map(group =>
                    group.id === id
                        ? {
                              ...group,
                              permissions: {
                                  read: false,
                                  write: false,
                                  edit: false,
                                  [newValue]: true,
                              },
                          }
                        : group,
                ),
            );
        },
        [setSelected],
    );

    return (
        <div className={"flex flex-col gap-4"}>
            <div className={"flex justify-between"}>
                <h5 className={"font-medium"}>Grupy:</h5>
                <Button size="sm" variant="tertiary" onClick={() => setTab("groupsSelectedList")}>
                    <Plus />
                    Dodaj
                </Button>
            </div>

            <List>
                {selected.length === 0 && (
                    <li className="flex py-4">
                        <p className="text-sm italic text-text-tertiary">Dodaj grupy</p>
                    </li>
                )}
                {selected.map(entity => (
                    <PermissionLiEntity
                        key={entity.id}
                        data={{
                            id: entity.id,
                            title: entity.name,
                            avatarURL: entity.avatarURL,
                        }}
                        permissions={entity.permissions}
                        sources={entity.sources}
                        onPermissionChange={(id, value) => handlePermissionChange(id, value)}
                        onRemove={() => {
                            handleRemoveEntity(entity.id);
                        }}
                    />
                ))}
            </List>
        </div>
    );
}

interface ShareFSEntrySelectedDataListProps<SelectedT> {
    searchValue: string;
    selected: SelectedT[];
    open: boolean;
    setSelected: Dispatch<SetStateAction<SelectedT[]>>;
}
function ShareFSEntryUsersSelectedDataList({
    selected,
    open,
    setSelected,
    searchValue,
}: ShareFSEntrySelectedDataListProps<UserPermissions>) {
    const getUsersInfiniteQuery = useGetUsersInfiniteQuery({
        name: searchValue,
        enabled: open,
    });

    const {
        data: users,
        isEmpty: usersIsEmpty,
        noResults: usersNoResults,
    } = useInfiniteQueryResult(getUsersInfiniteQuery.data, searchValue);

    const setSelectedCb = useCallback(
        (selected: any[]) => {
            setSelected(prevSelected => {
                // Map new selections, preserving existing items and their permissions
                return selected.map((user): UserPermissions => {
                    // If this user already exists in previous selection, keep their settings
                    const existingUser = prevSelected.find(prev => prev.id === user.id);
                    if (existingUser) {
                        return existingUser;
                    }

                    // For new users, set default permissions
                    if ("permissions" in user) {
                        return user as UserPermissions;
                    }
                    return {
                        ...user,
                        permissions: {
                            read: true,
                            write: false,
                            edit: false,
                        },
                    } as UserPermissions;
                });
            });
        },
        [setSelected],
    );

    const isUserInherited = useCallback(
        (user: UserPublic | UserPermissions) => {
            return selected.some(
                u => Number(u.id) === Number(user.id) && u.sources?.some(s => s.isInherited),
            );
        },
        [selected],
    );

    return (
        <>
            <SelectDataList
                query={getUsersInfiniteQuery}
                selected={selected}
                setSelected={setSelectedCb}
                isEmpty={usersIsEmpty}
                isNoResults={usersNoResults}
                emptyMessage={"Wyszukaj użytkownika"}
                searchNoResultsMessage={"Nie znaleziono użytkownika"}
            >
                <SelectDataListLabel className={cn((!selected.length || searchValue) && "hidden")}>
                    Dodane:
                </SelectDataListLabel>

                {!searchValue &&
                    selected.map(entity => {
                        const disabled = isUserInherited(entity);
                        return (
                            <SelectDataListElement
                                key={entity.id}
                                entity={entity}
                                disabled={disabled}
                                data={{
                                    title: entity.name,
                                    avatarURL: entity.avatarURL,
                                }}
                            />
                        );
                    })}
                <SelectDataListLabel>Wyszukiwane:</SelectDataListLabel>
                {users.map(entity => {
                    const disabled = isUserInherited(entity);
                    return (
                        <SelectDataListElement
                            key={entity.id}
                            entity={entity}
                            disabled={disabled}
                            data={{
                                title: entity.name + " " + entity.surname,
                                detail: entity.email,
                                avatarURL: entity.avatarURL,
                            }}
                        />
                    );
                })}
            </SelectDataList>
        </>
    );
}

function ShareFSEntryGroupsSelectedDataList({
    selected,
    open,
    setSelected,
    searchValue,
}: ShareFSEntrySelectedDataListProps<GroupPermissions>) {
    const getGroupsInfiniteQuery = useGetGroupsInfiniteQuery({
        name: searchValue,
        enabled: open,
    });

    const {
        data: groups,
        isEmpty: groupsIsEmpty,
        noResults: groupsNoResults,
    } = useInfiniteQueryResult(getGroupsInfiniteQuery.data, searchValue);

    const setSelectedCb = useCallback(
        (selected: any[]) => {
            setSelected(prevSelected => {
                // Map new selections, preserving existing items and their permissions
                return selected.map((group): GroupPermissions => {
                    // If this group already exists in previous selection, keep their settings
                    const existingGroup = prevSelected.find(prev => prev.id === group.id);
                    if (existingGroup) {
                        return existingGroup;
                    }

                    // For new groups, set default permissions
                    if ("permissions" in group) {
                        return group as GroupPermissions;
                    }
                    return {
                        ...group,
                        permissions: {
                            read: true,
                            write: false,
                            edit: false,
                        },
                    } as GroupPermissions;
                });
            });
        },
        [setSelected],
    );

    return (
        <SelectDataList
            query={getGroupsInfiniteQuery}
            selected={selected}
            setSelected={setSelectedCb}
            isEmpty={groupsIsEmpty}
            isNoResults={groupsNoResults}
            emptyMessage={"Wyszukaj grupy"}
            searchNoResultsMessage={"Nie znaleziono grupy"}
        >
            <SelectDataListLabel className={cn((!selected.length || searchValue) && "hidden")}>
                Dodane:
            </SelectDataListLabel>

            {!searchValue &&
                selected.map(entity => (
                    <SelectDataListElement
                        key={entity.id}
                        entity={entity}
                        data={{
                            title: entity.name,
                            avatarURL: entity.avatarURL,
                        }}
                    />
                ))}
            <SelectDataListLabel>Wyszukiwane:</SelectDataListLabel>

            {groups.map(entity => (
                <SelectDataListElement
                    key={entity.id}
                    entity={entity}
                    data={{
                        title: entity.name,
                        detail: entity.users.length + " użytkownik/ów",
                        avatarURL: entity.avatarURL,
                    }}
                />
            ))}
        </SelectDataList>
    );
}
