import { useDeleteConversationMutation } from "@/api/queries/chatQueries";

import {
    AlertDialog,
    AlertDialogBody,
    AlertDialogContent,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogTitle,
    AlertDialogAction,
    AlertDialogCancel,
    AlertDialogDescription,
} from "@/components/ui/dialog/AlertDialog";
import { CheckCircle2, MessageCircle } from "lucide-react";
import { Dispatch, SetStateAction } from "react";
import { useNavigate } from "react-router-dom";
import { cn } from "@/lib/utils";
import { FeaturedIcon } from "@/components/ui/featured-icon/FeaturedIcon";
import { httpErrorHandler } from "@/api/api";
import { toast } from "sonner";

interface ChatConversationDeleteFormProps {
    name: string;
    id: number;
    open: boolean;
    onOpenChange: Dispatch<SetStateAction<boolean>>;
}

export default function DeleteChat({
    name,
    id,
    open,
    onOpenChange,
}: ChatConversationDeleteFormProps) {
    const navigate = useNavigate();

    const { mutateAsync, isPending, isSuccess } = useDeleteConversationMutation();

    const onSubmit = async () => {
        navigate("/chat");
        await mutateAsync(id).catch(error => {
            const { title, detail } = httpErrorHandler(error);
            toast.error(title, {
                description: detail,
            });
        });
    };

    return (
        <AlertDialog open={open} onOpenChange={onOpenChange}>
            <AlertDialogContent className={"sm:max-w-96"}>
                <AlertDialogBody>
                    <AlertDialogDescription className={"sr-only"}>
                        Formularz usuwanie chatu.
                    </AlertDialogDescription>
                    <AlertDialogHeader>
                        <FeaturedIcon variant={"ghost"}>
                            <MessageCircle />
                        </FeaturedIcon>
                        <div className={"flex flex-col gap-0.5 min-w-0 grow"}>
                            <AlertDialogTitle>Czy chcesz usunąć to chat ?</AlertDialogTitle>
                            <p className={"text-text-tertiary text-xs"}>Usuń wybrane chat.</p>
                        </div>
                    </AlertDialogHeader>
                    <div className={"p-3 md:p-4"}>
                        <p className={"text-sm text-text-secondary"}>
                            Czy na pewno chcesz usunąć czat: <b> {name} ?</b>
                        </p>
                    </div>
                </AlertDialogBody>
                <AlertDialogFooter>
                    <AlertDialogCancel variant={"tertiary"}>Zamknij</AlertDialogCancel>
                    <AlertDialogAction
                        className={cn(isSuccess && "hidden")}
                        disabled={isPending}
                        onClick={onSubmit}
                    >
                        <CheckCircle2 />
                        Potwierdź
                    </AlertDialogAction>
                </AlertDialogFooter>
            </AlertDialogContent>
        </AlertDialog>
    );
}
