import { Link } from "@/components/ui/link/Link";
import { cn } from "@/lib/utils";
import { Video } from "lucide-react";

export function MeetingLink({ meetingURL, className }: { meetingURL: string; className?: string }) {
    return (
        <Link
            href={meetingURL}
            openInNewTab
            size={"sm"}
            className={cn("text-[clamp(0.7rem,1.5vw,0.8rem)]", className)}
        >
            <Video className={"size-3"} />
            Otwórz link
        </Link>
    );
}
