import {
    deleteUser,
    deleteUserAvatar,
    editUser,
    getManagedUsers,
    getUser,
    getUserGroup,
    getUsers,
    postUser,
    postUserAvatar,
    type UserAvatarUploadParams,
} from "@/api/endpoints/users";
import { queryClient } from "@/api/query-client";
import { UserEditType, UserAddSchemaType, UserEditSchemaType } from "@/schemas/user.schema";
import { useInfiniteQuery, useMutation, useQuery } from "@tanstack/react-query";
import { UserPrivate } from "@/types";

export const useGetManagedUsersQuery = () => {
    return useQuery({
        queryKey: ["users"],
        placeholderData: [],
        queryFn: () => getManagedUsers(),
    });
};

export const useGetUsersInfiniteQuery = ({
    name,
    enabled = true,
}: {
    name?: string;
    enabled?: boolean;
}) => {
    return useInfiniteQuery({
        queryKey: ["usersInfinite", name],
        queryFn: ({ pageParam }) => getUsers({ ...pageParam, name }),
        initialPageParam: { page: 0, pageSize: 15, name: undefined },
        getNextPageParam: (lastPage, allPages, lastPageParam) => {
            if (lastPage.length < lastPageParam.pageSize) {
                return undefined;
            }
            return { ...lastPageParam, page: lastPageParam.page + 1 };
        },
        enabled: enabled || !!name ? true : false,
    });
};

export const useGetUserQuery = (id?: number) => {
    return useQuery({
        queryKey: ["users", id],
        queryFn: () => getUser(id),
        enabled: !!id, // only run the query if id is not null or undefined
    });
};

export const useGetUserGroupsQuery = (id?: number) => {
    return useQuery({
        queryKey: ["userGroups", id],
        queryFn: () => getUserGroup(id),
        enabled: !!id, // only run the query if id is not null or undefined
    });
};

export const useDeleteUserMutation = () => {
    return useMutation({
        mutationFn: (id: number) => deleteUser(id),
        onMutate: async (id: number) => {
            await queryClient.cancelQueries({ queryKey: ["users"] });

            const previousUsers = queryClient.getQueryData<UserPrivate[]>(["users"]);

            queryClient.setQueryData(["users"], (prev?: UserPrivate[]) =>
                prev ? prev.filter((user: UserPrivate) => user.id !== id) : [],
            );

            return { previousUsers };
        },
        onError: (err, id, context) => {
            queryClient.setQueryData(["users"], context?.previousUsers);
        },
        onSettled: () => {
            void queryClient.invalidateQueries({ queryKey: ["users"] });
        },
        onSuccess: (data, id) => {
            queryClient.setQueryData(["users"], (prev?: UserPrivate[]) =>
                prev ? prev.filter((user: UserPrivate) => user.id !== id) : [],
            );
        },
    });
};

export const usePostUserMutation = () => {
    return useMutation({
        mutationFn: (data: UserAddSchemaType) => postUser(data),
        onSuccess: data => {
            queryClient.setQueryData(["users"], (curr?: UserPrivate[]) => {
                if (curr) {
                    return [...curr, data];
                }
                return [data];
            });
        },
    });
};

export const useEditUserMutation = () => {
    return useMutation({
        mutationFn: (data: UserEditSchemaType | UserEditType) => editUser(data),
        onSuccess: data => {
            queryClient.setQueryData(["users", data.id], (curr: UserPrivate) => {
                return { ...curr, ...data };
            });
            queryClient.setQueryData(["users"], (curr?: UserPrivate[]) => {
                if (curr) {
                    return curr.map(u => (u.id === data.id ? data : u));
                }
                return [data];
            });
        },
    });
};

export const usePostUserAvatarMutation = () => {
    return useMutation({
        mutationFn: (data: UserAvatarUploadParams) => postUserAvatar(data),
    });
};

export const useDeleteUserAvatarMutation = () => {
    return useMutation({
        mutationFn: (data: { authToken?: string }) => deleteUserAvatar(data),
    });
};
