import { LoaderCircle } from "lucide-react";

export function BufferingIndicator() {
    return (
        <div
            className={
                "pointer-events-none absolute inset-0 z-20 flex h-full w-full items-center justify-center opacity-0 media-buffering:opacity-100"
            }
        >
            <LoaderCircle
                className={"media-buffering:animate-spin size-7 text-text-tertiary"}
                aria-hidden={"true"}
            />
        </div>
    );
}
