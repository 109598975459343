"use client";

import { Toaster as Sonner } from "sonner";
import { useTheme } from "@/context/Theme";
import { CircleAlert, CircleCheck, CircleX, Info } from "lucide-react";

type ToasterProps = React.ComponentProps<typeof Sonner>;

const Toaster = ({ ...props }: ToasterProps) => {
    const { theme } = useTheme();

    return (
        <Sonner
            theme={theme as ToasterProps["theme"]}
            className={"toaster group z-[9999]"}
            icons={{
                success: <CircleCheck className={"size-4"} />,
                info: <Info className={"size-4"} />,
                warning: <CircleAlert className={"size-4"} />,
                error: <CircleX className={"size-4"} />,
            }}
            toastOptions={{
                classNames: {
                    toast: "group toast group-[.toaster]:bg-fill-primary !bg-fill-primary group-[.toaster]:text-text-primary group-[.toaster]:border group-[.toaster]:border-border-primary group-[.toaster]:shadow-lg !p-3 md:!p-4 !rounded-lg",
                    description: "group-[.toast]:text-text-tertiary",
                    actionButton:
                        "group-[.toast]:bg-fill-gray group-[.toast]:text-text-on-gray-fill min-h-9 px-2 rounded-md",
                    cancelButton:
                        "group-[.toast]:bg-fill-secondary group-[.toast]:text-text-secondary min-h-9 px-2 rounded-md",
                },
            }}
            {...props}
        />
    );
};

export { Toaster };
