import { useGetDirectoryQuery } from "@/api/queries/directoryQueries";
import FilePageForms from "@/components/features/files/layouts/files-page/FilePageForms";
import FilesPageContent from "@/components/features/files/layouts/files-page/FilesPageContent";
import FilesPageHeader from "@/components/features/files/layouts/header/FilesPageHeader";
import { FileUpload } from "@/components/ui/file-upload/FileUpload";
import { useCredentials } from "@/store/authStore";
import { useFilesStorage } from "@/store/filesStore";
import {
    ColumnDef,
    Row,
    SortingState,
    getCoreRowModel,
    useReactTable,
    getFilteredRowModel,
    getSortedRowModel,
} from "@tanstack/react-table";
import { format, isValid, parseISO } from "date-fns";
import { FileWithPath } from "file-selector";
import { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { useUploadFsElements } from "@/components/features/files/hooks/useUploadFsElements";
import UploadFSEntires from "@/components/features/files/forms/UploadFSEntires";
import { queryClient } from "@/api/query-client";
import type { DirectoryPublic, FilePublicWithPermissions } from "@/types";

// Custom hook for table configuration
function useFilesTable(data: (DirectoryPublic | FilePublicWithPermissions)[]) {
    const [sorting, setSorting] = useState<SortingState>([{ id: "Nazwa", desc: false }]);
    const [globalFilter, setGlobalFilter] = useState<string>("");
    // Define columns
    const columns: ColumnDef<DirectoryPublic | FilePublicWithPermissions>[] = useMemo(
        () => [
            {
                meta: "Nazwa",
                id: "Nazwa",
                accessorKey: "name",
                header: "Nazwa",
                enableSorting: true,
                enableFilters: true,
                getFilteredRowModel: getFilteredRowModel(),
                onGlobalFilterChange: setGlobalFilter,
                //Column filters
                //Sorting
                onSortingChange: setSorting,
                getSortedRowModel: getSortedRowModel(),
                state: {
                    sorting,
                    globalFilter,
                },
            },
            {
                meta: "Utworzono",
                id: "Utworzono",
                accessorKey: "createdAt",
                header: "Utworzono",
                sortType: "datetime",
                cell: ({ getValue }) => {
                    const date = getValue();
                    if (typeof date === "string" && isValid(parseISO(date))) {
                        return format(new Date(date), "dd.MM.yyyy");
                    }
                    return date;
                },
            },
        ],
        [],
    );

    // Create table instance
    const dataModel = useReactTable({
        data,
        columns,
        getCoreRowModel: getCoreRowModel(),
        enableRowSelection: true,
        enableFilters: true,
        getFilteredRowModel: getFilteredRowModel(),
        onGlobalFilterChange: setGlobalFilter,
        onSortingChange: setSorting,
        getSortedRowModel: getSortedRowModel(),
        state: {
            sorting,
            globalFilter,
        },
    });
    return { dataModel, setGlobalFilter };
}

// Custom hook for file handling
function useDirectoryData(directoryId: string, model: string) {
    const {
        data: directory,
        isSuccess,
        isPending,
        isLoading,
        isError,
    } = useGetDirectoryQuery(directoryId, model === "my_drive");

    // Process directory data
    const { data, dataLen } = useMemo(() => {
        if (directory) {
            const dirData = [...directory.files, ...directory.childDirs];
            return { data: dirData, dataLen: dirData.length };
        }
        return { data: [], dataLen: 0 };
    }, [directory?.files, directory?.childDirs]);

    // Create sets for efficient filtering
    const fileIds = useMemo(
        () => new Set(directory?.files.map(file => file.id)),
        [directory?.files],
    );

    const childDirIds = useMemo(
        () => new Set(directory?.childDirs.map(dir => dir.id)),
        [directory?.childDirs],
    );

    return {
        directory,
        data,
        dataLen,
        fileIds,
        childDirIds,
        isSuccess,
        isPending,
        isLoading,
        isError,
    };
}

export default function FilesPage() {
    const { id } = useParams();
    const { directoryId: defaultDirectoryId } = useCredentials();
    const directoryId = useMemo(() => id || defaultDirectoryId, [id, defaultDirectoryId]);

    const [openFileUploadDrawer, setOpenFileUploadDrawer] = useState<boolean>(false);

    // Files storage state
    const { model, resetSelected, setPermissions } = useFilesStorage();

    // Directory data
    const {
        directory,
        data,
        dataLen,
        fileIds,
        childDirIds,
        isSuccess,
        isPending,
        isLoading,
        isError,
    } = useDirectoryData(directoryId, model);

    // Table setup
    const { dataModel, setGlobalFilter } = useFilesTable(data);

    // File upload handling
    const {
        aggregatedDataArray,
        buildFilesTree,
        uploadFSDirs,
        deleteUplaodProgressEntry,
        resetUploadProgress,
    } = useUploadFsElements({
        shouldAggregateData: true,
    });

    const handleFileUpload = async (files: FileList | (FileWithPath | DataTransferItem)[]) => {
        const tree = buildFilesTree({ files: files, baseId: directoryId });
        await uploadFSDirs(tree);
        queryClient.invalidateQueries({
            queryKey: ["directory", directoryId],
        });
    };

    const rows = dataModel.getRowModel().rows;

    // Filter rows by type
    const files = useMemo(
        () =>
            rows.filter(element =>
                fileIds.has(element.original.id),
            ) as Row<FilePublicWithPermissions>[],
        [rows, fileIds],
    );
    const directories = useMemo(
        () =>
            rows.filter(element => childDirIds.has(element.original.id)) as Row<DirectoryPublic>[],
        [rows, childDirIds],
    );

    // Set permissions when directory changes
    useEffect(() => {
        if (directory) {
            setPermissions(directory.permissions);
        }
    }, [directory, setPermissions]);

    // Reset selection when directory changes
    useEffect(() => {
        resetSelected();
    }, [directoryId, resetSelected]);

    return (
        <FileUpload onDrop={handleFileUpload} onChange={handleFileUpload}>
            <FilePageForms directoryId={directoryId} />
            <UploadFSEntires
                open={openFileUploadDrawer}
                onOpenChange={setOpenFileUploadDrawer}
                uploadProgressData={aggregatedDataArray}
                resetUploadProgress={resetUploadProgress}
                deleteUplaodProgressEntry={deleteUplaodProgressEntry}
            />
            <div className="base-direction-row">
                <div className="flex flex-1 flex-col gap-4">
                    <FilesPageHeader
                        showTabsList={!id}
                        fsEntry={directory}
                        dataModel={dataModel}
                        setGlobalFilter={setGlobalFilter}
                        isLoading={isLoading}
                        isError={isError}
                    />
                    <FilesPageContent
                        files={files}
                        directories={directories}
                        isEmpty={dataLen === 0}
                        isSuccess={isSuccess}
                        isLoading={isPending}
                        isError={isError}
                    />
                </div>
            </div>
        </FileUpload>
    );
}
