import { cn } from "@/lib/utils";
import { VariantProps, cva } from "class-variance-authority";
import React from "react";

const badgeVariants = cva(
    "relative inline-flex w-fit gap-1 whitespace-nowrap font-medium truncate items-center transition-color duration-100",
    {
        variants: {
            variant: {
                brand: "*:stroke-text-brand text-text-brand bg-fill-brand-secondary hover:bg-fill-brand-secondary-hover",
                muted: "*:stroke-text-secondary text-text-secondary",
                accent: "*:stroke-text-accent text-text-accent bg-fill-accent-secondary hover:bg-fill-accent-secondary-hover",
                success:
                    "*:stroke-text-success text-text-success bg-fill-success-secondary hover:bg-fill-success-secondary-hover",
                warning:
                    "*:stroke-text-warning text-text-warning bg-fill-warning-secondary hover:bg-fill-warning-secondary-hover",
                destructive:
                    "*:stroke-text-destructive text-text-destructive bg-fill-destructive-secondary hover:bg-fill-destructive-secondary-hover",
                purple: ":stroke-fill-purple text-fill-purple bg-fill-purple-secondary hover:bg-fill-purple-secondary-hover",
                turquoise:
                    ":stroke-fill-turquoise text-fill-turquoise bg-fill-turquoise-secondary hover:bg-fill-turquoise-secondary-hover",
                blue: ":stroke-fill-blue text-fill-blue bg-fill-blue-secondary hover:bg-fill-blue-secondary-hover",
            },
            border: {
                true: "border",
                false: "border-none",
            },
            size: {
                sm: "h-6 px-2 py-0.5 text-xs rounded-sm [&>svg]:shrink-0 [&>svg]:w-3 [&>svg]:h-3",
                md: "h-7 px-3 py-1 text-sm rounded-sm [&>svg]:shrink-0 [&>svg]:w-4 [&>svg]:h-4",
            },
        },
        compoundVariants: [
            {
                variant: "muted",
                border: false,
                className: "bg-fill-secondary hover:bg-fill-secondary-hover",
            },
            {
                variant: "muted",
                border: true,
                className: "border-border-primary bg-fill-primary hover:bg-fill-primary-hover",
            },
            {
                variant: "brand",
                border: true,
                className: "border-border-brand",
            },
            {
                variant: "accent",
                border: true,
                className: "border-border-accent",
            },
            {
                variant: "success",
                border: true,
                className: "border-border-success",
            },
            {
                variant: "warning",
                border: true,
                className: "border-border-warning",
            },
            {
                variant: "destructive",
                border: true,
                className: "border-border-destructive",
            },
            {
                variant: "purple",
                border: true,
                className: "border-border-purple",
            },
            {
                variant: "turquoise",
                border: true,
                className: "border-border-turquoise",
            },
            {
                variant: "blue",
                border: true,
                className: "border-border",
            },
        ],
        defaultVariants: {
            size: "sm",
            variant: "brand",
            border: false,
        },
    },
);

export interface BadgeProps
    extends React.HTMLAttributes<HTMLDivElement>,
        VariantProps<typeof badgeVariants> {}

function Badge({ className, variant, size, border, ...props }: BadgeProps) {
    return <div className={cn(badgeVariants({ variant, size, border }), className)} {...props} />;
}

export { Badge, badgeVariants };
