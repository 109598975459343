import { axiosInstance } from "@/api/api";
import { ChatEditSchemaType } from "@/schemas/chat.schema";
import type { ChatPrivate, ConversationPublic } from "@/types";
import type { MessageConfirmation } from "@/types";
import type { UserPublic } from "@/types";
import type { PaginationWithNameI } from "@/types";

export interface ChatConversationProps {
    userIds: number[];
    groupIds: number[];
    name?: string;
}

export interface ChatFileUploadProps {
    file: File;
    content?: string;
    id: number;
    tempUUID: string;
    replyingToId?: number;
    type: string;
    socketId?: string;
}

export const getChat = async (id: number): Promise<ChatPrivate> => {
    return await axiosInstance.get("/api/chat", { params: { id: id } });
};

export const getCreateConversationUsers = async (
    params: PaginationWithNameI,
): Promise<UserPublic[]> => {
    return await axiosInstance.get("/api/chat/getCreateConversationUsers", {
        params: params,
    });
};

export const getLastConversations = async (
    params: PaginationWithNameI,
): Promise<ConversationPublic[]> => {
    return await axiosInstance.get("/api/chat/getLastConversations", {
        params: params,
    });
};

export const postChat = async (data: ChatConversationProps): Promise<ChatPrivate> => {
    return await axiosInstance.post("/api/chat", data);
};
export const postChatFileUpload = async ({
    file,
    type,
    content,
    tempUUID,
    replyingToId,
    id,
    socketId,
}: ChatFileUploadProps): Promise<MessageConfirmation> => {
    const form = new FormData();
    if (content) {
        form.append("content", content);
    }
    if (replyingToId) {
        form.append("replyingToId", String(replyingToId));
    }
    if (socketId) {
        form.append("socketId", socketId);
    }
    form.append("file", file);
    form.append("id", String(id));
    form.append("tempUUID", tempUUID);
    form.append("type", type);

    return await axiosInstance.post("/api/chat/fileUpload", form, {
        headers: { "Content-Type": "multipart/form-data" },
    });
};

export const editChat = async (data: ChatEditSchemaType): Promise<ChatPrivate> => {
    return await axiosInstance.put("/api/chat", data);
};

export const deleteChat = async (id: number): Promise<string> => {
    return await axiosInstance.delete("/api/chat", { data: { id: id } });
};
