import CalendarContent from "@/components/features/calendar/layout/content/CalendarContent";
import CalendarHeader from "@/components/features/calendar/layout/header/CalendarHeader";
import CalendarSubHeader from "@/components/features/calendar/layout/subheader/CalendarSubHeader";
import { NavigationI, VIEW_KEY } from "@/types";
import { timeToHeight } from "@/utils/timeToHeight";
import { addDays, addMonths, addWeeks, format, isValid, parse } from "date-fns";
import { useCallback, useEffect, useMemo, useRef } from "react";
import { useSearchParams } from "react-router-dom";

export const CONTAINER_HEIGHT = 2112;

/**
 * Calendar page component that handles different view types and navigation
 */
export default function CalendarPage() {
    const containerRef = useRef<HTMLDivElement>(null);

    // URL parameters for storing and controlling calendar state
    const [searchParams, setSearchParams] = useSearchParams({
        viewKey: VIEW_KEY.DAY,
        date: format(new Date(), "dd-MM-yyyy"),
    });

    //Validates and parses view key from URL params
    const handleViewKeyParser = (viewKey: string | null): VIEW_KEY => {
        if (!viewKey || !Object.values(VIEW_KEY).includes(viewKey as VIEW_KEY)) {
            return VIEW_KEY.DAY;
        } else {
            return viewKey as VIEW_KEY;
        }
    };

    //Parses date string from URL params into Date object
    const handleDateParser = (dateString: string | null): Date => {
        if (!dateString) {
            return new Date();
        }
        const dateObject = parse(dateString, "dd-MM-yyyy", new Date());
        if (isValid(dateObject)) {
            return dateObject;
        } else {
            return new Date();
        }
    };

    const date = useMemo(() => handleDateParser(searchParams.get("date")), [searchParams]);

    const viewKey = useMemo(() => handleViewKeyParser(searchParams.get("viewKey")), [searchParams]);

    // Updates URL params to navigate to a new date or view type
    const handleNavigate = useCallback(
        ({ newViewKey, newDate }: NavigationI): void => {
            let dateString = searchParams.get("date") ? searchParams.get("date") : "";
            let viewKey = searchParams.get("viewKey") ? searchParams.get("viewKey") : VIEW_KEY.DAY;

            if (newDate && isValid(newDate)) {
                dateString = format(newDate, "dd-MM-yyyy");
            }
            if (newViewKey) {
                viewKey = newViewKey;
            }
            setSearchParams(`viewKey=${viewKey}&date=${dateString}`, {
                replace: true,
            });
        },
        [searchParams, setSearchParams],
    );

    // Changes the current date by a specified number of days/weeks/months based on the current view type
    const handleChangeDate = useCallback(
        (number: number): void => {
            if (!isValid(date)) {
                return;
            }
            switch (viewKey) {
                case VIEW_KEY.DAY:
                    handleNavigate({ newDate: addDays(date, number) });
                    break;
                case VIEW_KEY.WEEK:
                    handleNavigate({ newDate: addWeeks(date, number) });
                    break;
                case VIEW_KEY.MONTH:
                    handleNavigate({ newDate: addMonths(date, number) });
                    break;
            }
        },
        [date, viewKey, handleNavigate],
    );

    // Scroll to current time when component mounts
    useEffect(() => {
        const offset = timeToHeight(new Date(), CONTAINER_HEIGHT);
        if (containerRef.current) {
            containerRef.current.scrollTo(0, offset - 64);
        }
    }, []);

    return (
        <div className={"base-direction-col"}>
            <div
                ref={containerRef}
                className={
                    "flex flex-col w-full grow border border-border-primary bg-surface-primary/50 rounded-lg viewport-height overflow-y-auto"
                }
            >
                <div className={"flex flex-col sticky z-10 top-0 bg-surface-primary"}>
                    <CalendarHeader
                        date={date}
                        viewKey={viewKey}
                        setNavigate={handleNavigate}
                        changeDate={handleChangeDate}
                    />
                    <CalendarSubHeader
                        date={date}
                        handleNavigate={handleNavigate}
                        viewKey={viewKey}
                    />
                </div>
                <CalendarContent date={date} viewKey={viewKey} onNavigate={handleNavigate} />
            </div>
        </div>
    );
}
