import { httpErrorHandler } from "@/api/api";
import { usePostUserMutation } from "@/api/queries/usersQueries";
import { Badge } from "@/components/ui/badge/Badge";
import { Button } from "@/components/ui/button/Button";
import {
    Dialog,
    DialogBody,
    DialogContent,
    DialogFooter,
    DialogHeader,
    DialogTitle,
} from "@/components/ui/dialog/Dialog";
import { Tag } from "@/components/ui/tags/Tags";
import { cn } from "@/lib/utils";
import { UsersMassImportRowT } from "@/page/pages/manage/ManageUsersMassImport";
import { UserAddSchemaType } from "@/schemas/user.schema";
import { selectedRowsI } from "@/types";
import generatePassword from "@/utils/generatePassword";
import { Check, X, CheckCircle2, LoaderCircle } from "lucide-react";
import { Dispatch, Fragment, SetStateAction, useEffect, useState } from "react";

type UserMassImportProps = {
    data: UsersMassImportRowT[];
    open: boolean;
    onOpenChange: Dispatch<SetStateAction<boolean>>;
};

export default function UserMassImport({ data, open, onOpenChange }: UserMassImportProps) {
    const { mutateAsync, isPending } = usePostUserMutation();
    const [isFinished, setIsFinished] = useState<boolean>(false);

    const [selectedRows, setSelectedRows] = useState<selectedRowsI<UserAddSchemaType>[]>([]);
    const [, setSuccessRows] = useState<selectedRowsI<UserAddSchemaType>[]>([]);

    useEffect(() => {
        setSelectedRows(
            data.map(row => {
                const userEntity: UserAddSchemaType = {
                    ...row,
                    lvl: row.lvl,
                    birthdate: row.birthdate,
                    password: generatePassword(12),
                };
                return { data: userEntity, status: "isIdle" };
            }),
        );
        setIsFinished(false);
    }, [data]);

    const onClose = () => {
        onOpenChange(false);
        setIsFinished(false);
    };

    const onSubmit = async () => {
        const successfullyAddedUsers: selectedRowsI<UserAddSchemaType>[] = [];

        selectedRows.forEach(row => {
            const rowIndex = selectedRows.findIndex(r => r.data === row.data);

            // Set the status of the current row to 'isLoading'
            setSelectedRows(prevRows => {
                const newRows = [...prevRows];
                newRows[rowIndex].status = "isPending";
                return newRows;
            });

            mutateAsync(row.data)
                .then(() => {
                    successfullyAddedUsers.push({ ...row, status: "isSuccess" });

                    // If the request is successful, set the status of the current row to 'isSuccess'
                    setSelectedRows(prevRows => {
                        const newRows = [...prevRows];
                        newRows[rowIndex].status = "isSuccess";
                        return newRows;
                    });
                })
                .catch(error => {
                    // If the request fails, set the status of the current row to 'isError'
                    setSelectedRows(prevRows => {
                        const newRows = [...prevRows];
                        newRows[rowIndex].status = "isError";
                        newRows[rowIndex].error = error;
                        return newRows;
                    });
                });
        });

        setIsFinished(true);
        setSuccessRows(successfullyAddedUsers);
    };

    return (
        <Dialog open={open} onOpenChange={onOpenChange}>
            <DialogContent className={"md:max-w-96"}>
                <DialogBody>
                    <DialogHeader>
                        <DialogTitle>Dodaj użytkowników</DialogTitle>
                    </DialogHeader>
                    <div className={"flex flex-col gap-1 p-3 md:p-4"}>
                        {selectedRows.map(({ data, status, error }, index) => {
                            const { detail } = httpErrorHandler(error);
                            return (
                                <div
                                    key={index}
                                    className={cn(
                                        "border-border-primary flex h-8 items-center gap-2 border-b",
                                        status === "isError" && "text-text-destructive!",
                                        status === "isSuccess" && "text-text-success!",
                                    )}
                                >
                                    <div className={"size-4"}>
                                        {status === "isError" && (
                                            <X className={"size-4 shrink-0"} />
                                        )}
                                        {status === "isPending" && (
                                            <LoaderCircle
                                                className={"animate-spin size-4 shrink-0"}
                                            />
                                        )}
                                        {status === "isSuccess" && (
                                            <Check className={"size-4 shrink-0"} />
                                        )}
                                    </div>
                                    <p className={"w-3 text-sm text-text-tertiary"}>{index + 1}.</p>
                                    <p className={"text-sm"}>
                                        {data.name} {data.surname}
                                    </p>

                                    {status === "isError" && (
                                        <Badge
                                            variant={"destructive"}
                                            size={"sm"}
                                            className={"ml-auto"}
                                        >
                                            {detail}
                                        </Badge>
                                    )}
                                    {status === "isSuccess" && (
                                        <Tag variant={"muted"} size={"sm"} className={"ml-auto"}>
                                            Pobierz kartę
                                        </Tag>
                                    )}
                                </div>
                            );
                        })}
                    </div>
                </DialogBody>
                <DialogFooter>
                    {isFinished ? (
                        <Button variant={"ghost"} onClick={onClose}>
                            Zamknij
                        </Button>
                    ) : (
                        <Fragment>
                            <Button variant={"ghost"} disabled={isPending} onClick={onClose}>
                                Anuluj
                            </Button>
                            <Button type={"button"} onClick={onSubmit} disabled={isPending}>
                                {isPending ? (
                                    <>
                                        <LoaderCircle
                                            className={"animate-spin"}
                                            aria-hidden={"true"}
                                        />
                                        Zapisywanie...
                                    </>
                                ) : (
                                    <>
                                        <CheckCircle2 />
                                        Zapisz
                                    </>
                                )}
                            </Button>
                        </Fragment>
                    )}
                </DialogFooter>
            </DialogContent>
        </Dialog>
    );
}
