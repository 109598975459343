enum VIEW_KEY {
    DAY = "day",
    WEEK = "week",
    MONTH = "month",
}

interface NavigationI {
    newViewKey?: VIEW_KEY;
    newDate?: Date;
}
type SetNavigate = (navigation: NavigationI) => void;

export { VIEW_KEY };
export type { NavigationI, SetNavigate };
