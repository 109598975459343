import { useGetGroupsQuery } from "@/api/queries/groupsQueries";
import CreateGroup from "@/components/features/manage/manage-groups/CreateGroup";
import DeleteGroup from "@/components/features/manage/manage-groups/DeleteGroup";
import EditGroup from "@/components/features/manage/manage-groups/EditGroup";
import { TanstackTable } from "@/components/features/manage/tanstack-table/TanstackTable";
import { Button } from "@/components/ui/button/Button";
import { AlertDialog } from "@/components/ui/dialog/AlertDialog";
import { Checkbox } from "@/components/ui/input/checkbox/Checkbox";
import type { GroupPrivate } from "@/types";
import { ColumnDef } from "@tanstack/react-table";
import { Edit, Plus, Trash2 } from "lucide-react";
import { useMemo, useState } from "react";
import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuGroup,
    DropdownMenuItem,
    DropdownMenuTrigger,
} from "@/components/ui/input/dropdown-menu/DropdownMenu";
import { GroupAvatarEditor } from "@/components/features/groups/GroupAvatarEditor";

export default function ManageGroups() {
    const [openEdit, setOpenEdit] = useState<boolean>(false);
    const [openEditAvatar, setOpenEditAvatar] = useState<boolean>(false);
    const [openDelete, setOpenDelete] = useState<boolean>(false);
    const [openGroupAdd, setOpenGroupAdd] = useState<boolean>(false);

    const [selectedRows, setSelectedRows] = useState<GroupPrivate[]>([]);
    const [selectedGroup, setSelectedGroup] = useState<GroupPrivate | undefined>(undefined);

    const { data = [], isPending } = useGetGroupsQuery();

    const getSelectedRowsFn = (rows: GroupPrivate[]) => {
        setSelectedRows(rows);
        setOpenDelete(true);
    };

    const triggerPopupEdit = (user?: GroupPrivate) => {
        if (user) {
            setSelectedGroup(user);
            setOpenEdit(true);
        }
    };

    const triggerPopupEditAvatar = (user?: GroupPrivate) => {
        if (user) {
            setSelectedGroup(user);
            setOpenEditAvatar(true);
        }
    };

    const triggerPopupAddGroup = () => {
        setOpenGroupAdd(true);
    };

    const addGroup = useMemo(() => {
        return [
            {
                id: 1,
                label: "Dodaj Grupę",
                icon: <Plus className="h-4 w-4" />,
                func: () => triggerPopupAddGroup(),
            },
        ];
    }, []);

    const columns: ColumnDef<GroupPrivate>[] = useMemo(
        () => [
            {
                id: "select",
                header: ({ table }) => (
                    <Checkbox
                        checked={
                            table.getIsAllPageRowsSelected()
                                ? true
                                : table.getIsSomePageRowsSelected()
                                  ? "indeterminate"
                                  : false
                        }
                        onCheckedChange={value => table.toggleAllPageRowsSelected(!!value)}
                        aria-label="Select all"
                    />
                ),
                cell: ({ row }) => (
                    <Checkbox
                        checked={row.getIsSelected()}
                        onCheckedChange={value => row.toggleSelected(!!value)}
                        aria-label={"Select row"}
                    />
                ),
                enableSorting: false,
                enableHiding: false,
            },
            {
                meta: "Nazwa",
                id: "name",
                accessorKey: "name",
                header: "Nazwa",
            },
            {
                meta: "Opis",
                id: "desc",
                accessorKey: "desc",
                header: "Opis",
            },
            {
                meta: "Tagi",
                id: "tags",
                accessorKey: "tags",
                header: "Tagi",
                cell: () => {
                    return "TAGS";
                },
                filterFn: (row, id, value) => {
                    return value.includes(row.getValue(id));
                },
            },
            {
                id: "actions",
                enableHiding: false,
                enableSorting: false,
                cell: ({ row }) => {
                    const rowOrigin = row.original;
                    return (
                        <div className={"flex w-full justify-end"}>
                            <Button
                                size={"sm"}
                                variant={"ghost"}
                                variantColor={"destructive"}
                                onClick={() => getSelectedRowsFn([rowOrigin])}
                            >
                                <Trash2 />
                            </Button>
                            <DropdownMenu>
                                <DropdownMenuTrigger asChild>
                                    <Button size={"sm"} variant={"ghost"}>
                                        <Edit />
                                    </Button>
                                </DropdownMenuTrigger>
                                <DropdownMenuContent align={"end"}>
                                    <DropdownMenuGroup>
                                        <DropdownMenuItem
                                            onClick={() => triggerPopupEdit(rowOrigin)}
                                        >
                                            Edytuj
                                        </DropdownMenuItem>
                                        <DropdownMenuItem
                                            onClick={() => triggerPopupEditAvatar(rowOrigin)}
                                        >
                                            Edytuj awatar
                                        </DropdownMenuItem>
                                    </DropdownMenuGroup>
                                </DropdownMenuContent>
                            </DropdownMenu>
                        </div>
                    );
                },
            },
        ],
        [],
    );

    return (
        <>
            <CreateGroup open={openGroupAdd} onOpenChange={setOpenGroupAdd} />
            <EditGroup
                id={selectedGroup?.id}
                open={openEdit}
                onOpenChange={setOpenEdit}
                callback={() => {
                    setSelectedGroup(undefined);
                }}
            />
            <GroupAvatarEditor
                id={selectedGroup?.id}
                avatarURL={selectedGroup?.avatarURL}
                open={openEditAvatar}
                onOpenChange={setOpenEditAvatar}
            />
            <DeleteGroup selected={selectedRows} open={openDelete} onOpenChange={setOpenDelete} />
            <div className={"base-direction-col"}>
                <TanstackTable
                    className={"flex flex-col gap-4 w-full viewport-height"}
                    label={"Grupy"}
                    name={"groups"}
                    isLoading={isPending}
                    columns={columns}
                    data={data}
                    getSelectedRows={getSelectedRowsFn}
                    filterBy={[]}
                    addGroup={addGroup}
                />
            </div>
        </>
    );
}
