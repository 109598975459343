import { Input, InputActionButton, InputWrapper } from "@/components/ui/input/Input";
import { RadioGroup, RadioGroupItem } from "@/components/ui/input/radio-group/RadioGroup";
import { Label } from "@/components/ui/label/Label";
import { useId, useRef } from "react";
import { FormControl, FormField, FormItem, FormMessage } from "@/components/ui/form/Form";
import { Check, ChevronsUpDown, ClipboardPaste } from "lucide-react";
import { useWatch, type UseFormReturn } from "react-hook-form";
import { cn } from "@/lib/utils";
import { Popover, PopoverContent, PopoverTrigger } from "@/components/ui/popover/Popover";
import {
    Command,
    CommandEmpty,
    CommandGroup,
    CommandInput,
    CommandItem,
    CommandList,
} from "@/components/ui/command/Command";
import { Button } from "@/components/ui/button/Button";
import type { CalendarFormSchemaType } from "@/schemas/meeting.schema";
import { useGetLocationsQuery } from "@/api/queries/locationsQueries";

type MeetingFormTypeRadioGroupProps = {
    form: UseFormReturn<CalendarFormSchemaType, unknown, undefined>;
    typeName?: "type" | `schedule.${number}.type`;
    meetingURL?: "meetingURL" | `schedule.${number}.meetingURL`;
};

export default function MeetingFormTypeRadioGroup({
    form,
    typeName = "type",
    meetingURL = "meetingURL",
}: MeetingFormTypeRadioGroupProps) {
    const radioId = useId();
    const remoteId = useId();
    const stationaryId = useId();

    const inputMeetingURLRef = useRef<HTMLInputElement>(null);

    // Watch method and type fields for changes need to use useWatch hook instead of watch function because of re-rendering issues with react-compiler
    const type = useWatch({ control: form.control, name: typeName });

    const { data: locations = [], isPending } = useGetLocationsQuery();

    const handlePasteMeetingURL = async () => {
        const text = await navigator.clipboard.readText();
        if (text) {
            form.setValue(meetingURL, text);
        }
    };

    return (
        <FormField
            control={form.control}
            name={typeName}
            render={({ field }) => (
                <FormItem className="space-y-3">
                    <FormControl>
                        <RadioGroup
                            className={"gap-3"}
                            value={field.value}
                            onValueChange={value => {
                                field.onChange(value);
                                if (value === "remote" && inputMeetingURLRef.current) {
                                    // Use setTimeout to ensure focus happens after UI update
                                    setTimeout(() => {
                                        inputMeetingURLRef.current?.focus();
                                    }, 0);
                                }
                            }}
                        >
                            {/* Offline meeting group */}
                            <div>
                                <div className="flex items-start gap-2">
                                    <RadioGroupItem
                                        value="remote"
                                        id={`${radioId}-1`}
                                        aria-describedby={`${radioId}-1-description`}
                                        aria-controls={remoteId}
                                    />
                                    <div className="grow">
                                        <div className="grid grow gap-2">
                                            <Label htmlFor={`${radioId}-1`}>Zdalnie</Label>
                                            <p
                                                id={`${radioId}-1-description`}
                                                className="text-text-tertiary text-xs"
                                            >
                                                Wybierz jeżeli chcesz aby spotkanie odbyło się
                                                online.
                                            </p>
                                        </div>
                                        {/* Expandable input field */}
                                        <div
                                            role="region"
                                            id={remoteId}
                                            aria-labelledby={`${radioId}-1`}
                                            className="grid transition-all ease-in-out data-[state=collapsed]:grid-rows-[0fr] data-[state=collapsed]:opacity-0 data-[state=expanded]:grid-rows-[1fr] data-[state=expanded]:opacity-100"
                                            data-state={
                                                type === "remote" ? "expanded" : "collapsed"
                                            }
                                        >
                                            <div className="pointer-events-none -m-2 overflow-hidden p-2">
                                                <div className="pointer-events-auto mt-3">
                                                    <FormField
                                                        name={meetingURL}
                                                        control={form.control}
                                                        render={({ field }) => (
                                                            <FormItem>
                                                                <FormControl>
                                                                    <InputWrapper>
                                                                        <Input
                                                                            {...field}
                                                                            ref={e => {
                                                                                (
                                                                                    inputMeetingURLRef as React.MutableRefObject<HTMLInputElement | null>
                                                                                ).current = e;
                                                                                field.ref(e);
                                                                            }}
                                                                            disabled={
                                                                                type !== "remote"
                                                                            }
                                                                            placeholder={
                                                                                "Link do spotkania"
                                                                            }
                                                                            className={
                                                                                "rounded-e-none"
                                                                            }
                                                                        />
                                                                        <InputActionButton
                                                                            className={
                                                                                "-ms-px rounded-s-none"
                                                                            }
                                                                            onClick={
                                                                                handlePasteMeetingURL
                                                                            }
                                                                        >
                                                                            <ClipboardPaste />
                                                                        </InputActionButton>
                                                                    </InputWrapper>
                                                                </FormControl>
                                                                <FormMessage />
                                                            </FormItem>
                                                        )}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/* Online meeting group */}
                            <div>
                                <div className="flex items-start gap-2">
                                    <RadioGroupItem
                                        value="stationary"
                                        id={`${radioId}-2`}
                                        aria-describedby={`${radioId}-2-description`}
                                        aria-controls={stationaryId}
                                    />
                                    <div className="grow">
                                        <div className="grid grow gap-2">
                                            <Label htmlFor={`${radioId}-2`}>Stacjonarnie</Label>
                                            <p
                                                id={`${radioId}-2-description`}
                                                className="text-text-tertiary text-xs"
                                            >
                                                Wybierz jeżeli chcesz aby spotkanie odbyło się w
                                                skazanej lokalizacji.
                                            </p>
                                        </div>
                                        {/* Expandable select field */}
                                        <div
                                            role="region"
                                            id={stationaryId}
                                            aria-labelledby={`${radioId}-2`}
                                            className="grid transition-all ease-in-out data-[state=collapsed]:grid-rows-[0fr] data-[state=collapsed]:opacity-0 data-[state=expanded]:grid-rows-[1fr] data-[state=expanded]:opacity-100"
                                            data-state={
                                                type === "stationary" ? "expanded" : "collapsed"
                                            }
                                        >
                                            <div className="pointer-events-none -m-2 overflow-hidden p-2">
                                                <div className="pointer-events-auto mt-3">
                                                    <FormField
                                                        control={form.control}
                                                        name={"location"}
                                                        render={({ field }) => (
                                                            <FormItem className="flex flex-col">
                                                                <Popover>
                                                                    <PopoverTrigger asChild>
                                                                        <FormControl>
                                                                            <Button
                                                                                variant="outline"
                                                                                role="combobox"
                                                                                className={cn(
                                                                                    "w-full justify-between",
                                                                                    !field.value &&
                                                                                        "text-text-tertiary",
                                                                                )}
                                                                            >
                                                                                {field.value
                                                                                    ? field.value
                                                                                          .name
                                                                                    : "Wybierz lokalizację"}
                                                                                <ChevronsUpDown className="stroke-icon-tertiary" />
                                                                            </Button>
                                                                        </FormControl>
                                                                    </PopoverTrigger>
                                                                    <PopoverContent className="w-full p-0 rounded-sm overflow-hidden">
                                                                        <Command>
                                                                            <CommandInput
                                                                                placeholder="Wyszukaj lokację..."
                                                                                className="h-9"
                                                                            />
                                                                            <CommandList>
                                                                                <CommandEmpty>
                                                                                    {isPending
                                                                                        ? "Ładowanie..."
                                                                                        : "Nie znaleziono lokacji."}
                                                                                </CommandEmpty>
                                                                                <CommandGroup>
                                                                                    {locations.map(
                                                                                        location => (
                                                                                            <CommandItem
                                                                                                value={String(
                                                                                                    location.id,
                                                                                                )}
                                                                                                key={
                                                                                                    location.id
                                                                                                }
                                                                                                onSelect={() => {
                                                                                                    form.setValue(
                                                                                                        "location",
                                                                                                        location,
                                                                                                    );
                                                                                                }}
                                                                                            >
                                                                                                {
                                                                                                    location.name
                                                                                                }
                                                                                                <Check
                                                                                                    className={cn(
                                                                                                        "ml-auto",
                                                                                                        location.id ===
                                                                                                            Number(
                                                                                                                field
                                                                                                                    .value
                                                                                                                    ?.id,
                                                                                                            )
                                                                                                            ? "opacity-100"
                                                                                                            : "opacity-0",
                                                                                                    )}
                                                                                                />
                                                                                            </CommandItem>
                                                                                        ),
                                                                                    )}
                                                                                </CommandGroup>
                                                                            </CommandList>
                                                                        </Command>
                                                                    </PopoverContent>
                                                                </Popover>
                                                                <FormMessage />
                                                            </FormItem>
                                                        )}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </RadioGroup>
                    </FormControl>
                    <FormMessage />
                </FormItem>
            )}
        />
    );
}
