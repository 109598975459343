import { Button } from "@/components/ui/button/Button";
import {
    Calendar,
    CalendarPopover,
    CalendarPopoverContent,
    CalendarPopoverTrigger,
    DatePicker,
} from "@/components/ui/input/date-picker/DatetimePicker";
import { format, startOfWeek, endOfWeek, startOfMonth, add } from "date-fns";
import { pl } from "date-fns/locale";
import { ChevronDown } from "lucide-react";
import { useMemo } from "react";
import { SetNavigate, VIEW_KEY } from "@/types";
import { CalendarDate } from "@internationalized/date";

type CalendarHeaderDatePickerProps = {
    viewKey: VIEW_KEY;
    date: Date;
    setNavigate: SetNavigate;
};

export default function CalendarHeaderDatePicker({
    setNavigate,
    viewKey,
    date,
}: CalendarHeaderDatePickerProps) {
    const viewportDateRange = useMemo(() => {
        const dateFormat = "MMM dd, yyyy";
        const options = { locale: pl };

        if (viewKey == VIEW_KEY.DAY) return format(date, dateFormat, options);
        else if (viewKey == VIEW_KEY.WEEK) {
            return `${format(startOfWeek(date, { weekStartsOn: 1 }), dateFormat, options)} - ${format(endOfWeek(date, { weekStartsOn: 1 }), dateFormat, options)}`;
        }

        //default case - month view
        const start = startOfWeek(startOfMonth(date), { weekStartsOn: 1 });
        const end = add(start, {
            weeks: 5,
            days: 6,
        });

        return `${format(start, dateFormat, options)} - ${format(end, dateFormat, options)}`;
    }, [date, viewKey]);

    const onNavigate = (date: CalendarDate | null) => {
        if (!date) return;
        setNavigate({ newDate: date.toDate("UTC") });
    };

    const calendarDateValue = useMemo(() => {
        // Convert JS Date to CalendarDate
        return new CalendarDate(date.getFullYear(), date.getMonth() + 1, date.getDate());
    }, [date]);

    return (
        <DatePicker
            aria-label={"date-picker"}
            granularity={"day"}
            value={calendarDateValue}
            onChange={onNavigate}
        >
            <CalendarPopover>
                <CalendarPopoverTrigger asChild>
                    <Button variant={"ghost"} className={"p-0.5 min-h-fit h-fit w-fit"}>
                        <div>
                            <div className="text-xl text-start font-semibold capitalize">
                                {format(date, "LLLL yyyy", { locale: pl })}
                            </div>
                            <div className="text-sm text-start text-text-tertiary">
                                {viewportDateRange}
                            </div>
                        </div>
                        <ChevronDown className="stroke-icon-tertiary size-4" />
                    </Button>
                </CalendarPopoverTrigger>
                <CalendarPopoverContent align={"start"} className={"p-2"}>
                    <Calendar />
                </CalendarPopoverContent>
            </CalendarPopover>
        </DatePicker>
    );
}
