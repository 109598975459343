import CreateFSDir from "@/components/features/files/forms/CreateFSDir";
import DeleteFSEntry from "@/components/features/files/forms/DeleteFSEntry";
import DownloadFSEntry from "@/components/features/files/forms/DownloadFSEntry";
import EditFSEntryName from "@/components/features/files/forms/EditFSEntryName";
import InfoFSEntry from "@/components/features/files/forms/InfoFSEntry";
import MoveFSEntry from "@/components/features/files/forms/MoveFSEntry";
import ShareFSEntry from "@/components/features/files/forms/ShareFSEntry";
import { useFilesStorage } from "@/store/filesStore";
import MediaViewer from "@/components/features/media/MediaViewer";

interface FilePageFormsProps {
    directoryId: string;
}

export default function FilePageForms({ directoryId }: FilePageFormsProps) {
    const {
        selected: elements,
        fileToOpen,
        resetSelected,
        openDelete,
        openDownload,
        openEditName,
        openShare,
        openMove,
        openMoreInfo,
        openCreateDir,
        openViewer,
        setOpenViewer,
        setOpenCreateDir,
        setOpenDelete,
        setOpenDownload,
        setOpenEditName,
        setOpenMoreInfo,
        setOpenMove,
        setOpenShare,
    } = useFilesStorage();

    return (
        <>
            <MediaViewer file={fileToOpen} open={openViewer} onOpenChange={setOpenViewer} />
            <CreateFSDir
                parentDirId={directoryId}
                open={openCreateDir}
                onOpenChange={setOpenCreateDir}
            />
            <EditFSEntryName
                open={openEditName}
                onOpenChange={setOpenEditName}
                element={elements.directories[0] || elements.files[0]}
                directoryId={directoryId}
            />
            <InfoFSEntry
                open={openMoreInfo}
                onOpenChange={setOpenMoreInfo}
                element={elements.directories[0] || elements.files[0]}
            />
            <MoveFSEntry
                open={openMove}
                onOpenChange={setOpenMove}
                elements={elements}
                callback={resetSelected}
                directoryId={directoryId}
            />
            <DeleteFSEntry
                open={openDelete}
                onOpenChange={setOpenDelete}
                elements={elements}
                callback={resetSelected}
                directoryId={directoryId}
            />
            <DownloadFSEntry
                open={openDownload}
                onOpenChange={setOpenDownload}
                elements={elements}
                callback={resetSelected}
            />
            <ShareFSEntry
                open={openShare}
                onOpenChange={setOpenShare}
                element={elements.directories[0] || elements.files[0]}
                callback={() => {
                    resetSelected();
                    setOpenMoreInfo(false);
                }}
                directoryId={directoryId}
            />
        </>
    );
}
