import type { Permissions } from "@/types";

interface useFSEntryPermissionsProps {
    permissions?: Permissions;
}

export default function useFSEntryPermissions({ permissions }: useFSEntryPermissionsProps) {
    const readable = { type: "read", title: "Przeglądający" };
    const writable = {
        type: "write",
        title: "Edytujący",
    };
    const editable = { type: "edit", title: "Pełen dostęp" };

    return {
        permissionsArray: [readable, writable, editable],
        currentPermission: permissions?.edit ? editable : permissions?.write ? writable : readable,
    };
}
