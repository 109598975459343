import { cn } from "@/lib/utils";
import { type VariantProps, cva } from "class-variance-authority";
import { ReactNode, RefObject, forwardRef, useMemo } from "react";

const colorVariants = {
    solid: {
        brand: "bg-fill-brand text-text-on-brand-fill *:stroke-icon-brand",
        accent: "bg-fill-accent text-text-on-accent-fill *:stroke-icon-accent",
        muted: "bg-fill-secondary text-text-primary *:stroke-icon-primary",
        success: "bg-fill-success text-text-on-success-fill *:stroke-icon-success",
        warning: "bg-fill-warning text-text-on-warning-fill *:stroke-icon-warning",
        destructive: "bg-fill-destructive text-text-on-destructive-fill *:stroke-icon-destructive",
    },
    flat: {
        brand: "bg-fill-brand-secondary text-text-brand *:stroke-icon-brand",
        accent: "bg-fill-accent-secondary text-text-accent *:stroke-icon-accent",
        muted: "bg-fill-secondary-secondary text-text-primary *:stroke-icon-primary",
        success: "bg-fill-success-secondary text-text-success *:stroke-icon-success",
        warning: "bg-fill-warning-secondary text-text-warning *:stroke-icon-warning",
        destructive:
            "bg-fill-destructive-secondary text-text-destructive *:stroke-icon-destructive",
    },
};

const ChipVariants = cva(
    "flex z-10 flex-wrap absolute box-border origin-center scale-100 select-none place-content-center items-center font-regular text-inherit select-none whitespace-nowrap rounded-md opacity-100 subpixel-antialiased data-[invisible=true]:scale-0 data-[invisible=true]:opacity-0",
    {
        variants: {
            variant: {
                solid: "",
                flat: "",
            },
            variantColor: {
                brand: "",
                accent: "",
                muted: "",
                success: "",
                warning: "",
                destructive: "",
            },
            size: {
                sm: "px-px text-xs",
                md: "px-px text-xs",
                lg: "px-px text-sm",
            },
            isInvisible: {
                true: "",
            },
            isOneChar: {
                true: "px-0",
            },
            isDot: {
                true: "",
            },
            placement: {
                "top-right": "",
                "top-left": "",
                "bottom-right": "",
                "bottom-left": "",
            },

            showOutline: {
                true: "border-2 border-border-primary",
                false: "border-transparent border-0",
            },
        },
        defaultVariants: {
            variant: "solid",
            variantColor: "brand",
            size: "md",
            placement: "top-right",
            showOutline: false,
            isInvisible: false,
        },
        compoundVariants: [
            // solid / color
            {
                variant: "solid",
                variantColor: "muted",
                className: colorVariants.solid.muted,
            },
            {
                variant: "solid",
                variantColor: "brand",
                className: colorVariants.solid.brand,
            },
            {
                variant: "solid",
                variantColor: "accent",
                className: colorVariants.solid.accent,
            },
            {
                variant: "solid",
                variantColor: "success",
                className: colorVariants.solid.success,
            },
            {
                variant: "solid",
                variantColor: "warning",
                className: colorVariants.solid.warning,
            },
            {
                variant: "solid",
                variantColor: "destructive",
                className: colorVariants.solid.destructive,
            },
            // flat / color
            {
                variant: "flat",
                variantColor: "muted",
                className: colorVariants.flat.muted,
            },
            {
                variant: "flat",
                variantColor: "brand",
                className: colorVariants.flat.brand,
            },
            {
                variant: "flat",
                variantColor: "accent",
                className: colorVariants.flat.accent,
            },
            {
                variant: "flat",
                variantColor: "success",
                className: colorVariants.flat.success,
            },
            {
                variant: "flat",
                variantColor: "warning",
                className: colorVariants.flat.warning,
            },
            {
                variant: "flat",
                variantColor: "destructive",
                className: colorVariants.flat.destructive,
            },

            // isOneChar / size
            {
                isOneChar: true,
                size: "sm",
                className: "w-3 h-3 min-w-3 min-h-3",
            },
            {
                isOneChar: true,
                size: "md",
                className: "w-4 h-4 min-w-4 min-h-4",
            },
            {
                isOneChar: true,
                size: "lg",
                className: "w-5 h-5 min-w-5 min-h-5",
            },
            // isDot / size
            {
                isDot: true,
                size: "sm",
                className: "w-1.5 h-1.5 min-w-1.5 min-h-1.5",
            },
            {
                isDot: true,
                size: "md",
                className: "w-2 h-2 min-w-2 min-h-2",
            },
            {
                isDot: true,
                size: "lg",
                className: "w-2.5 h-2.5 min-w-2.5 min-h-2.5",
            },
            // placement
            {
                placement: "top-right",
                className: "top-1 right-1 translate-x-1/2 -translate-y-1/2",
            },
            {
                placement: "top-left",
                className: "top-1 left-1 -translate-x-1/2 -translate-y-1/2",
            },
            {
                placement: "bottom-right",
                className: "bottom-1 right-1 translate-x-1/2 translate-y-1/2",
            },
            {
                placement: "bottom-left",
                className: "bottom-1 left-1 -translate-x-1/2 translate-y-1/2",
            },
        ],
    },
);

export interface ChipProps extends VariantProps<typeof ChipVariants> {
    /**
     * Ref to the DOM node.
     */
    ref?: RefObject<HTMLSpanElement | null>;
    /**
     * The children of the chip.
     */
    children: ReactNode;
    /**
     * The content of the chip. The chip will be rendered relative to its children.
     */
    content?: string | number | ReactNode;

    className?: string;
    /**
     * @example
     * ```ts
     * <Chip classNames={{
     *    base:"base-classes", // wrapper
     *    chip: "chip-classes",
     * }} />
     * ```
     */
}

const Chip = forwardRef<HTMLSpanElement, ChipProps>((props, ref) => {
    const { children, content, className, ...otherProps } = props;

    const { getChipProps } = useChip({ ...otherProps, content });

    return (
        <div
            className={cn("relative inline-flex h-fit w-fit shrink-0 overflow-visible", className)}
        >
            {children}
            <span ref={ref} {...getChipProps()}>
                {content}
            </span>
        </div>
    );
});

Chip.displayName = "Chip";

export default Chip;

type useChipProps = Omit<ChipProps, "children">;
interface DOMElement extends Element, HTMLOrSVGElement {}

type DataAttributes = Record<string, any>;

type DOMAttributes<T = DOMElement> = React.AriaAttributes &
    React.DOMAttributes<T> &
    DataAttributes & {
        id?: string;
        role?: React.AriaRole;
        tabIndex?: number;
        style?: React.CSSProperties;
    };

type Merge<M, N> = N extends Record<string, unknown> ? M : Omit<M, keyof N> & N;

type PropGetter<P = Record<string, unknown>, R = DOMAttributes> = (
    props?: Merge<DOMAttributes, P>,
    ref?: React.Ref<any>,
) => R & React.RefAttributes<any>;

function useChip(props: useChipProps) {
    const {
        content,
        className,
        isOneChar,
        isDot,
        variant,
        variantColor,
        isInvisible,
        size,
        placement,
        showOutline,
        ...otherProps
    } = props;

    const isOneCharProp = useMemo(
        () => String(content).length === 1 || isOneChar,
        [content, isOneChar],
    );

    const isDotProp = useMemo(() => String(content).length === 0 || isDot, [content, isDot]);

    const getChipProps: PropGetter = () => {
        return {
            className: cn(
                ChipVariants({
                    variant: variant,
                    variantColor: variantColor,
                    size: size,
                    placement: placement,
                    showOutline: showOutline,
                    isOneChar: isOneCharProp,
                    isDot: isDotProp,
                }),
                className,
            ),
            "data-invisible": isInvisible,
            ...otherProps,
        };
    };

    return {
        isInvisible: isInvisible,
        getChipProps,
    };
}
