import {
    ContextMenu,
    ContextMenuContent,
    ContextMenuGroup,
    ContextMenuItem,
    ContextMenuLabel,
    ContextMenuSeparator,
    ContextMenuTrigger,
} from "@/components/ui/context-menu/ContextMenu";
import { useFilesStorage } from "@/store/filesStore";
import { FileUp, FolderPlus, FolderUp } from "lucide-react";
import { useCallback } from "react";
import { FileUploadTrigger } from "../../ui/file-upload/FileUpload";

interface FilePageContextMenuProps {
    children: React.ReactNode;
    disabled?: boolean;
}

export default function FilePageContextMenu(props: FilePageContextMenuProps) {
    const { children, disabled = false } = props;

    const { setOpenCreateDir, permissions } = useFilesStorage();

    const handleOnCreateDir = useCallback(() => {
        setOpenCreateDir(true);
    }, [setOpenCreateDir]);

    return (
        <ContextMenu>
            <ContextMenuTrigger disabled={disabled}>{children}</ContextMenuTrigger>
            <ContextMenuContent>
                <ContextMenuLabel>Akcje</ContextMenuLabel>
                <ContextMenuSeparator />
                <ContextMenuGroup>
                    <ContextMenuItem
                        disabled={disabled || !permissions.write}
                        onClick={handleOnCreateDir}
                    >
                        <FolderPlus className={"size-4"} />
                        Stwórz folder
                    </ContextMenuItem>
                    <FileUploadTrigger multiple={true} disabled={disabled || !permissions.write}>
                        <ContextMenuItem>
                            <FileUp className={"size-4"} />
                            Prześlij pliki
                        </ContextMenuItem>
                    </FileUploadTrigger>

                    <FileUploadTrigger
                        directory={""}
                        webkitdirectory={""}
                        disabled={disabled || !permissions.write}
                    >
                        <ContextMenuItem>
                            <FolderUp className={"size-4"} />
                            Prześlij folder
                        </ContextMenuItem>
                    </FileUploadTrigger>
                </ContextMenuGroup>
            </ContextMenuContent>
        </ContextMenu>
    );
}
