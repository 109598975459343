import { Avatar, AvatarFallback, AvatarImage } from "@/components/ui/avatar/Avatar";
import {
    AvatarGroup,
    AvatarGroupButtonMore,
    AvatarGroupContent,
} from "@/components/ui/avatar/AvatarGroup";
import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuTrigger,
    DropdownMenuLabel,
} from "@/components/ui/input/dropdown-menu/DropdownMenu";
import { MeetingCard } from "@/components/features/meeting/MeetingCard";
import { Badge } from "@/components/ui/badge/Badge";
import { Button } from "@/components/ui/button/Button";
import { Skeleton } from "@/components/ui/skeleton/Skeleton";
import useElementData from "@/hooks/useElementData";
import type { GroupPrivate } from "@/types";
import type { UserPublic } from "@/types";
import { getAvatar } from "@/utils/getAvatar";
import { format, parseISO } from "date-fns";
import { pl } from "date-fns/locale";
import { ArrowUpRight, User, Users } from "lucide-react";
import { forwardRef, useMemo, useRef } from "react";
import { Link } from "react-router-dom";

function sliceUsers(users: UserPublic[], containerWidth: number) {
    let sliceIndex = containerWidth > 0 ? Math.floor(containerWidth / 30) : 4;
    sliceIndex = sliceIndex > 0 ? sliceIndex - 1 : sliceIndex;
    return [users.slice(0, sliceIndex), users.slice(sliceIndex)];
}

export const GroupCardSkeleton = forwardRef<HTMLDivElement>((props = {}, ref) => {
    return (
        <div
            ref={ref}
            className={
                "flex flex-col rounded-lg border h-fit border-border-primary bg-surface-primary"
            }
            {...props}
        >
            <div className={"flex items-center gap-3 border-b-1 border-border-primary p-4"}>
                <Skeleton className={"h-9 w-9 rounded-sm"} />
                <Skeleton className={"h-4 w-[12ch] rounded-sm text-lg"} />
            </div>
            <div className={"flex h-full flex-col gap-3 p-4"}>
                <div className={"flex flex-col gap-2"}>
                    <Skeleton className={"h-4 w-[11ch] rounded-sm text-xs"} />
                    <div className={"flex flex-wrap gap-1"}>
                        <Skeleton className={"h-6 w-14 rounded-sm"} />
                        <Skeleton className={"h-6 w-13 rounded-sm"} />
                        <Skeleton className={"h-6 w-13 rounded-sm"} />
                    </div>
                </div>
                <div className={"flex flex-col gap-2"}>
                    <Skeleton className={"h-4 w-[11ch] rounded-sm text-xs"} />
                    <div className={"flex flex-wrap gap-1"}>
                        <Skeleton className={"h-6 w-14 rounded-sm"} />
                        <Skeleton className={"h-6 w-13 rounded-sm"} />
                        <Skeleton className={"h-6 w-13 rounded-sm"} />
                    </div>
                </div>
                <div className={"flex items-center justify-between gap-2"}>
                    <div className={"flex"}></div>
                    <Skeleton className={"h-10 w-[6rem] rounded-md"} />
                </div>
            </div>
        </div>
    );
});

GroupCardSkeleton.displayName = "GroupCardSkeleton";

interface GroupBoxProps {
    group: GroupPrivate;
    onMouseEnter?: () => void;
    onFocus?: () => void;
}

export function GroupCard({ group, onFocus, onMouseEnter, ...props }: GroupBoxProps) {
    const avatarsContainerRef = useRef<HTMLDivElement>(null);
    const { id, name, meetings, users, avatarURL } = group;
    const [{ width }] = useElementData(avatarsContainerRef);

    const avatarGroup = useMemo(() => {
        const [firstGroup, secondGroup] = sliceUsers(users, width);

        return (
            <AvatarGroup>
                <AvatarGroupContent>
                    {firstGroup.map(({ id, name, surname, avatarURL }) => {
                        return (
                            <Avatar key={id} size={"sm"}>
                                <AvatarImage
                                    src={getAvatar(avatarURL)}
                                    alt={`${name} ${surname}`}
                                />
                                <AvatarFallback>
                                    <User />
                                </AvatarFallback>
                            </Avatar>
                        );
                    })}
                </AvatarGroupContent>
                {secondGroup.length > 0 && (
                    <DropdownMenu>
                        <DropdownMenuTrigger asChild>
                            <AvatarGroupButtonMore>+{secondGroup.length}</AvatarGroupButtonMore>
                        </DropdownMenuTrigger>
                        <DropdownMenuContent>
                            {secondGroup.map(({ id, name, surname, avatarURL }) => {
                                return (
                                    <DropdownMenuLabel
                                        key={id}
                                        className={"flex items-center gap-2 py-1 font-normal"}
                                    >
                                        <Avatar key={id} size={"md"}>
                                            <AvatarImage
                                                src={getAvatar(avatarURL)}
                                                alt={`${name} ${surname}`}
                                            />
                                            <AvatarFallback>
                                                <User />
                                            </AvatarFallback>
                                        </Avatar>
                                        <p>{name + " " + surname}</p>
                                    </DropdownMenuLabel>
                                );
                            })}
                        </DropdownMenuContent>
                    </DropdownMenu>
                )}
            </AvatarGroup>
        );
    }, [users, width]);

    return (
        <div
            tabIndex={0}
            className={
                "flex flex-col rounded-lg transition-outline border border-border-primary bg-surface-primary h-fit outline-ring-focus outline-0 focus-visible:outline-2 disabled:opacity-75"
            }
            onMouseEnter={onMouseEnter}
            onFocus={onFocus}
            {...props}
        >
            <div className={"flex items-center gap-3 border-b border-border-primary p-3 md:p-4"}>
                <Avatar size={"md"}>
                    <AvatarImage src={getAvatar(avatarURL)} alt={"avatar"} />
                    <AvatarFallback>
                        <Users className={"size-4"} />
                    </AvatarFallback>
                </Avatar>
                <h5 className={"w-full truncate text-lg font-semibold text-fg-primary"}>{name}</h5>
            </div>
            <div className={"flex h-full flex-col gap-3 p-3 md:p-4"}>
                <div className={"flex flex-col gap-2"}>
                    <p className={"text-xs font-medium text-text-tertiary"}>Najbliższe zajęcia:</p>
                    <div className={"flex flex-wrap gap-1 min-h-6"}>
                        {meetings.length ? (
                            //limit shown meetings to 2
                            meetings.slice(0, 2).map(meeting => (
                                <DropdownMenu key={meeting.id}>
                                    <DropdownMenuTrigger>
                                        <Badge key={id} variant={"muted"} size={"sm"}>
                                            {format(
                                                parseISO(meeting.startDate),
                                                "EEE do MMM, HH:mm",
                                                { locale: pl },
                                            )}
                                        </Badge>
                                    </DropdownMenuTrigger>
                                    <DropdownMenuContent>
                                        <MeetingCard
                                            meeting={{
                                                ...meeting,
                                                desc: "",
                                                isCancelled: false,
                                                updatedAt: "",
                                                createdAt: "",
                                            }}
                                        />
                                    </DropdownMenuContent>
                                </DropdownMenu>
                            ))
                        ) : (
                            <p className="text-xs text-text-tertiary whitespace-nowrap truncate italic">
                                Brak zajęć w tym tygodniu
                            </p>
                        )}
                    </div>
                </div>
                <div className={"mt-auto flex items-end justify-between gap-2"}>
                    <div ref={avatarsContainerRef} className={"flex h-full w-full items-end"}>
                        {users.length > 0 && avatarGroup}
                    </div>
                    <Link to={`/group/${id}`} state={group} className={"w-fit"}>
                        <Button variant={"outline"}>
                            Otwórz{" "}
                            <ArrowUpRight className="transition-transform group-hover:translate-x-0.5" />
                        </Button>
                    </Link>
                </div>
            </div>
        </div>
    );
}
