import { useGetDirectoryPermissionsQuery } from "@/api/queries/directoryQueries";
import { useGetFilePermissionsQuery } from "@/api/queries/filesQueries";
import { Avatar, AvatarFallback, AvatarImage } from "@/components/ui/avatar/Avatar";
import { Badge } from "@/components/ui/badge/Badge";
import { Button } from "@/components/ui/button/Button";
import {
    Drawer,
    DrawerBody,
    DrawerContent,
    DrawerDescription,
    DrawerTitle,
} from "@/components/ui/drawer/Drawer";
import {
    Sheet,
    SheetBody,
    SheetContent,
    SheetDescription,
    SheetHeader,
    SheetTitle,
} from "@/components/ui/sheet/Sheet";
import { Skeleton } from "@/components/ui/skeleton/Skeleton";
import { useMediaQuery } from "@/hooks/useMediaQuery";
import type { Permissions } from "@/types";
import { getIsFSEntityFile } from "@/utils/files";
import { getAvatar } from "@/utils/getAvatar";
import { format } from "date-fns";
import { pl } from "date-fns/locale";
import { SquareArrowOutUpRight, User, Users } from "lucide-react";
import { ReactNode, useMemo } from "react";
import { useFilesStorage } from "@/store/filesStore";
import useFSEntryPermissions from "@/components/features/files/hooks/useFSEntryPermissions";
import { FilePrivate, FilePublicWithPermissions, DirectoryPublic } from "@/types";

interface StorageElementMoreInfoProps {
    open: boolean;
    onOpenChange: (open: boolean) => void;
    element?: FilePublicWithPermissions | DirectoryPublic;
}

export default function InfoFSEntry({ element, open, onOpenChange }: StorageElementMoreInfoProps) {
    const { id, permissions, name, createdAt, updatedAt } = element ?? {};
    const breakpoint = useMediaQuery("sm");

    const isFile = getIsFSEntityFile(element);

    const { setOpenEditName, setOpenShare } = useFilesStorage();

    const { currentPermission } = useFSEntryPermissions({
        permissions: permissions,
    });

    const {
        data: fileData,
        isSuccess: FileIsSuccess,
        isLoading: FileIsLoading,
    } = useGetFilePermissionsQuery(id, open && isFile);
    const {
        data: DirData,
        isSuccess: DirIsSuccess,
        isLoading: DirIsLoading,
    } = useGetDirectoryPermissionsQuery(id, open && !isFile);

    const { data, isSuccess, isLoading } = useMemo(() => {
        if (isFile) {
            return {
                data: fileData,
                isSuccess: FileIsSuccess,
                isLoading: FileIsLoading,
            };
        } else {
            return {
                data: DirData,
                isSuccess: DirIsSuccess,
                isLoading: DirIsLoading,
            };
        }
    }, [isFile, fileData, FileIsSuccess, DirData, DirIsSuccess, DirIsLoading, FileIsLoading]);

    const content = useMemo(() => {
        return (
            <>
                <div className={"flex flex-col gap-2 p-3 md:p-4"}>
                    <div className={"flex justify-between gap-2"}>
                        <h5 className={"text-md font-medium"}>Informacje:</h5>
                        <Button
                            size={"sm"}
                            variant={"linkSecondary"}
                            onClick={() => setOpenEditName(true)}
                        >
                            Edytuj nazwę
                            <SquareArrowOutUpRight />
                        </Button>
                    </div>
                    <div className={"flex flex-col gap-1"}>
                        {name && (
                            <div className={"flex min-h-9 items-center"}>
                                <span className={"min-w-[14ch] text-text-tertiary"}>Nazwa:</span>
                                <p className={"truncate whitespace-nowrap"}>{name}</p>
                            </div>
                        )}
                        {
                            <div className={"flex min-h-9 items-center"}>
                                <span className={"min-w-[14ch] text-text-tertiary"}>Typ:</span>
                                <p className={"truncate whitespace-nowrap"}>
                                    {isFile ? "Plik" : "Folder"}
                                </p>
                            </div>
                        }
                        {createdAt && (
                            <div className={"flex min-h-9 items-center"}>
                                <span className={"min-w-[14ch] text-text-tertiary"}>
                                    Utworzono:
                                </span>
                                <p className={"flex items-center"}>
                                    {format(new Date(createdAt), "dd.MM.yyyy", {
                                        locale: pl,
                                    })}
                                </p>
                            </div>
                        )}
                        {updatedAt && (
                            <div className={"flex min-h-9 items-center"}>
                                <span className={"min-w-[14ch] text-text-tertiary"}>
                                    Edytowano:
                                </span>
                                <p className={"flex items-center"}>
                                    {format(new Date(updatedAt), "dd.MM.yyyy", {
                                        locale: pl,
                                    })}
                                </p>
                            </div>
                        )}
                        {permissions && (
                            <div className={"flex min-h-9 items-center"}>
                                <span className={"min-w-[14ch] text-text-tertiary"}>
                                    Poświadczenia:
                                </span>
                                <div className={"flex flex-wrap gap-1"}>
                                    <Badge size={"sm"} variant={"muted"}>
                                        {currentPermission.title}
                                    </Badge>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
                <div className={"flex flex-col gap-2 p-3 md:p-4"}>
                    <div className={"flex justify-between gap-2"}>
                        <h5 className={"text-md font-medium"}>Uprawnienia:</h5>
                        <Button
                            size={"sm"}
                            variant={"linkSecondary"}
                            onClick={() => setOpenShare(true)}
                        >
                            Edytuj uprawnienia
                            <SquareArrowOutUpRight />
                        </Button>
                    </div>
                    <div className={"flex w-full flex-col"}>
                        {!data?.users.length && !data?.groups.length && isSuccess && (
                            <p className="text-sm italic text-text-tertiary">
                                Brak dodanych poświadczeń
                            </p>
                        )}
                        {isSuccess &&
                            data &&
                            data.groups.map(group => (
                                <PermissionListElement
                                    key={group.id}
                                    permissions={group.permissions}
                                    data={{
                                        title: group.name,
                                        avatarURL: "",
                                        fallbackIcon: <Users className="size-4" />,
                                    }}
                                />
                            ))}
                        {isSuccess &&
                            data &&
                            data.users.map(user => (
                                <PermissionListElement
                                    key={user.id}
                                    permissions={user.permissions}
                                    data={{
                                        title: user.name + " " + user.surname,
                                        avatarURL: user.avatarURL,
                                        fallbackIcon: <User className="size-4" />,
                                    }}
                                />
                            ))}

                        {isLoading && (
                            <>
                                <div className="flex items-center gap-2 py-1">
                                    <Skeleton className="h-10 w-10 rounded-sm" />
                                    <Skeleton className="h-3 w-14 rounded-sm" />
                                    <Skeleton className="ml-auto h-3 w-11 rounded-sm" />
                                </div>
                            </>
                        )}
                    </div>
                </div>
            </>
        );
    }, [
        name,
        isFile,
        createdAt,
        updatedAt,
        permissions,
        currentPermission.title,
        data,
        isSuccess,
        isLoading,
        setOpenEditName,
        setOpenShare,
    ]);

    if (breakpoint) {
        return (
            <Sheet onOpenChange={onOpenChange} open={open}>
                <SheetContent className={"max-w-86"} side={"right"}>
                    <SheetBody>
                        <SheetHeader className="border-border-primary border-b">
                            <SheetTitle>{name}</SheetTitle>
                        </SheetHeader>
                        <SheetDescription className={"sr-only"}>
                            Informacje o pliku/folderze
                        </SheetDescription>
                        {content}
                    </SheetBody>
                </SheetContent>
            </Sheet>
        );
    }
    return (
        <Drawer open={open} onOpenChange={onOpenChange}>
            <DrawerContent>
                <DrawerBody className={"h-fit overflow-auto gap-6 pb-4"}>
                    <DrawerTitle>{name}</DrawerTitle>
                    <DrawerDescription className={"sr-only"}>
                        Informacje o pliku/folderze
                    </DrawerDescription>
                    {content}
                </DrawerBody>
            </DrawerContent>
        </Drawer>
    );
}

interface PermissionListElementProps {
    data: { title: string; avatarURL: string; fallbackIcon?: ReactNode };
    permissions: Permissions;
}

function PermissionListElement({ data, permissions }: PermissionListElementProps) {
    const { title, avatarURL, fallbackIcon } = data;
    const { currentPermission } = useFSEntryPermissions({ permissions });

    return (
        <div className={"flex items-center gap-2 py-1"}>
            <Avatar size={"sm"}>
                <AvatarImage src={getAvatar(avatarURL)} />
                <AvatarFallback>
                    {fallbackIcon ? fallbackIcon : <User className={"h-4 w-4"} />}
                </AvatarFallback>
            </Avatar>
            <p className="shrink truncate">{title}</p>
            <Badge variant={"muted"} border size={"sm"} className={"ml-auto"}>
                {currentPermission.title}
            </Badge>
        </div>
    );
}
