import GroupElementHeader from "@/components/features/groups/layout/GroupElementHeader";
import { Button } from "@/components/ui/button/Button";
import { Label } from "@/components/ui/label/Label";
import { ScrollArea, ScrollBar } from "@/components/ui/scroll-area/ScrollArea";
import { cn } from "@/lib/utils";
import { useCredentials } from "@/store/authStore";
import type { GroupPrivate } from "@/types";
import { Book, CalendarPlus, ClipboardSignature, Folder } from "lucide-react";
import { Fragment, useCallback } from "react";
import { useNavigate } from "react-router";
import { CalendarFormSchemaType } from "@/schemas/meeting.schema";

interface GroupQuickMenuProps {
    className?: string;
    group?: GroupPrivate;
}

export default function GroupQuickMenu({ className, group }: GroupQuickMenuProps) {
    const navigate = useNavigate();
    const { lvl } = useCredentials();

    const handleOpenMeetingForm = useCallback(() => {
        navigate("/calendar/?viewKey=day", {
            state: {
                action: "createMeeting",
                value: { groups: [group] } as Partial<CalendarFormSchemaType>,
            },
        });
    }, [group, navigate]);

    return (
        <Fragment>
            <div
                className={cn(
                    "flex flex-col rounded-lg border-1 border-border-primary bg-surface-primary w-full",
                    className,
                )}
            >
                <GroupElementHeader label={"Menu wyboru"} />
                <div className={cn(lvl > 0 && "grid grid-cols-2")}>
                    {/* teacher menu */}
                    {lvl > 0 && (
                        <div className="flex flex-col gap-3 p-4">
                            <Label className={cn("flex font-medium text-text-tertiary")}>
                                Szybkie akcje
                            </Label>
                            {/* teacher menu content */}
                            <ScrollArea>
                                <div className={"flex flex-col gap-2 w-full"}>
                                    <Button
                                        variant={"outline"}
                                        className={"shrink-0 justify-start w-full"}
                                        onClick={handleOpenMeetingForm}
                                    >
                                        <CalendarPlus /> Dodaj spotkanie
                                    </Button>
                                    <Button
                                        disabled
                                        variant={"outline"}
                                        className={"shrink-0 justify-start w-full"}
                                    >
                                        <Book /> Dodaj lekcję
                                    </Button>
                                    <Button
                                        disabled
                                        variant={"outline"}
                                        className={"shrink-0 justify-start w-full"}
                                    >
                                        <ClipboardSignature /> Dodaj zadanie
                                    </Button>
                                </div>
                                <ScrollBar orientation="horizontal" />
                            </ScrollArea>
                        </div>
                    )}
                    {/* all users menu */}
                    <div className="flex flex-col gap-3 p-4">
                        <Label className={cn("flex font-medium text-text-tertiary")}>Menu</Label>
                        <ScrollArea>
                            <div className={"flex flex-col gap-2 w-full"}>
                                <Button
                                    disabled={!group?.directoryId}
                                    variant={"outline"}
                                    className={"shrink-0 justify-start w-full"}
                                    onClick={() => navigate(`/fs/dir/${group?.directoryId}`)}
                                >
                                    <Folder /> Pliki
                                </Button>
                                <Button
                                    disabled={true}
                                    variant={"outline"}
                                    className={"shrink-0 justify-start w-full"}
                                >
                                    <Book /> Lekcje
                                </Button>
                                <Button
                                    disabled={true}
                                    variant={"outline"}
                                    className={"shrink-0 justify-start w-full"}
                                >
                                    <ClipboardSignature /> Zadania
                                </Button>
                            </div>
                            <ScrollBar orientation="horizontal" />
                        </ScrollArea>
                    </div>
                </div>
            </div>
        </Fragment>
    );
}
