import { httpErrorHandler } from "@/api/api";
import { usePostPasswordResetMutation } from "@/api/queries/authQueries";
import { Alert, AlertContent, AlertDescription } from "@/components/ui/alert/Alert";
import { Button } from "@/components/ui/button/Button";
import { FeaturedIcon } from "@/components/ui/featured-icon/FeaturedIcon";
import {
    Form,
    FormControl,
    FormField,
    FormItem,
    FormLabel,
    FormMessage,
} from "@/components/ui/form/Form";
import { ProgressiveImageLoader } from "@/components/ui/image/ProgressiveImageLoader";
import { Input, InputWrapper } from "@/components/ui/input/Input";
import { cn } from "@/lib/utils";
import { PasswordResetSchema, PasswordResetSchemaType } from "@/schemas/auth.schema";
import { yupResolver } from "@hookform/resolvers/yup";
import { LoaderCircle, AtSign, Info } from "lucide-react";
import { useForm } from "react-hook-form";
import { useLocation, useNavigate, useParams } from "react-router-dom";

export default function PasswordResetPage() {
    const location = useLocation();
    const navigate = useNavigate();
    const params = useParams();
    const authToken = params.t;

    const { mutateAsync, isPending, isSuccess } = usePostPasswordResetMutation();

    const form = useForm<PasswordResetSchemaType>({
        defaultValues: {
            email: "",
            authToken: authToken,
        },
        mode: "all",
        resolver: yupResolver(PasswordResetSchema),
    });

    const email = form.getValues("email");

    const checkIfCanGoBack = (): boolean => {
        return location.key !== "default";
    };

    const onSubmit = async (data: PasswordResetSchemaType) => {
        mutateAsync(data).catch(error => {
            const { detail } = httpErrorHandler(error);
            form.setError("email", {
                type: "manual",
                message: detail,
            });
        });
    };

    return (
        <>
            <ProgressiveImageLoader
                alt="Background image"
                className={"absolute inset-0 z-0 opacity-80"}
                sources={{
                    mobile: {
                        lowRes: {
                            webp: "/images/background/auth_bg_img_small.webp",
                            jpeg: "/auth_bg/auth_bg_img_small.jpeg",
                        },
                        highRes: {
                            webp: "/images/background/auth_bg_img_medium.webp",
                            jpeg: "/images/background/auth_bg_img_medium.jpeg",
                        },
                    },
                    tablet: {
                        lowRes: {
                            webp: "/images/background/auth_bg_img_medium.webp",
                            jpeg: "/images/background/auth_bg_img_medium.jpeg",
                        },
                        highRes: {
                            webp: "/images/background/auth_bg_img_large.webp",
                            jpeg: "/images/background/auth_bg_img_large.jpeg",
                        },
                    },
                    desktop: {
                        lowRes: {
                            webp: "/images/background/auth_bg_img_large.webp",
                            jpeg: "/images/background/auth_bg_img_large.jpeg",
                        },
                        highRes: {
                            webp: "/images/background/auth_bg_img_2k.webp",
                            jpeg: "/images/background/auth_bg_img_2k.jpeg",
                        },
                    },
                }}
            />
            <div
                className={
                    "flex min-h-dvh w-full items-end md:items-center justify-center p-0 pt-6 md:p-6"
                }
            >
                <div
                    className={
                        "z-10 flex p-4 md:p-6 h-[85dvh] max-h-[calc(100dvh_-_var(--spacing)*4*2)] md:h-fit min-h-100 w-full rounded-b-none md:rounded-2xl border-0 md:border md:w-[clamp(20rem,50dvw,28rem)] flex-col items-center gap-6 overflow-auto rounded-2xl border-border-primary bg-surface-primary"
                    }
                >
                    <Form {...form}>
                        <form
                            className={"flex w-full h-full grow flex-col gap-7"}
                            onSubmit={form.handleSubmit(onSubmit)}
                            noValidate
                        >
                            <h1 className={"py-3 text-4xl font-semibold"}>Resetuj hasło</h1>
                            <Alert variant={"default"}>
                                <FeaturedIcon size={"sm"}>
                                    <Info />
                                </FeaturedIcon>
                                <AlertContent>
                                    <AlertDescription>
                                        {isSuccess
                                            ? `Link do resetu hasła został wysłany na adres email (${email})`
                                            : " Wprowadź adres email, na który zostanie wysłany link do resetu hasła."}
                                    </AlertDescription>
                                </AlertContent>
                            </Alert>
                            <FormField
                                name={"email"}
                                control={form.control}
                                render={({ field }) => (
                                    <FormItem>
                                        <FormLabel>Email</FormLabel>
                                        <FormControl>
                                            <InputWrapper>
                                                <div
                                                    className={
                                                        "pointer-events-none absolute inset-y-0 start-0 flex items-center justify-center ps-3 peer-disabled:opacity-50"
                                                    }
                                                >
                                                    <AtSign
                                                        aria-hidden={"true"}
                                                        className={"stroke-icon-tertiary"}
                                                    />
                                                </div>
                                                <Input
                                                    {...field}
                                                    className={"peer ps-9"}
                                                    placeholder={"Email"}
                                                />
                                            </InputWrapper>
                                        </FormControl>
                                        <FormMessage />
                                    </FormItem>
                                )}
                            />
                            <footer className={"flex h-full grow flex-row items-end gap-3 mt-auto"}>
                                <Button
                                    type={"button"}
                                    size={"lg"}
                                    variant={"outline"}
                                    onClick={() => void navigate(-1)}
                                    className={cn(checkIfCanGoBack() ? "w-full" : "hidden")}
                                >
                                    Cofnij
                                </Button>
                                <Button
                                    disabled={isPending}
                                    type={"submit"}
                                    size={"lg"}
                                    variant={"primary"}
                                    onClick={form.handleSubmit(onSubmit)}
                                    className={"w-full"}
                                >
                                    {isPending ? (
                                        <>
                                            <LoaderCircle
                                                className={"animate-spin"}
                                                aria-hidden={"true"}
                                            />
                                            Wysyłanie...
                                        </>
                                    ) : (
                                        "Wyślij"
                                    )}
                                </Button>
                            </footer>
                        </form>
                    </Form>
                </div>
            </div>
        </>
    );
}
