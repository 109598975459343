import EditGroup from "@/components/features/manage/manage-groups/EditGroup";
import EditGroupUsers from "@/components/features/manage/manage-groups/EditGroupUsers";
import { Button } from "@/components/ui/button/Button";
import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuGroup,
    DropdownMenuItem,
    DropdownMenuTrigger,
} from "@/components/ui/input/dropdown-menu/DropdownMenu";
import { cn } from "@/lib/utils";
import { useCredentials } from "@/store/authStore";
import type { GroupPrivate } from "@/types";
import { ArrowLeft, MoreVertical, PenLine, SquareUserRound, Users } from "lucide-react";
import { Fragment, useState, type SetStateAction } from "react";
import { useNavigate } from "react-router-dom";
import { GroupAvatarEditor } from "../GroupAvatarEditor";

interface GroupHeaderProps {
    group?: GroupPrivate;
    className?: string;
}

export default function GroupHeader({ className, group }: GroupHeaderProps) {
    const { lvl } = useCredentials();

    const navigate = useNavigate();
    const [openEdit, setOpenEdit] = useState<boolean>(false);
    const [openEditAvatar, setOpenEditAvatar] = useState<boolean>(false);
    const [openEditUsers, setOpenEditUsers] = useState<boolean>(false);

    const handleNavigateBack = () => {
        navigate(-1);
    };

    return (
        <Fragment>
            {lvl > 0 && (
                <>
                    <GroupAvatarEditor
                        open={openEditAvatar}
                        onOpenChange={setOpenEditAvatar}
                        id={group?.id}
                        avatarURL={group?.avatarURL}
                    />
                    <EditGroupUsers
                        id={group?.id}
                        open={openEditUsers}
                        onOpenChange={setOpenEditUsers}
                    />
                    <EditGroup id={group?.id} open={openEdit} onOpenChange={setOpenEdit} />
                </>
            )}

            <div
                className={cn(
                    "flex flex-col gap-3 rounded-lg border-1 border-border-primary bg-surface-primary p-4",
                    className,
                )}
            >
                <div className={"flex gap-2"}>
                    <Button variant={"ghost"} onClick={handleNavigateBack}>
                        <ArrowLeft />
                    </Button>
                    <h2 className={"self-center text-xl font-semibold text-fg-primary"}>
                        {group?.name}
                    </h2>
                    {lvl > 0 && (
                        <DropdownMenu>
                            <DropdownMenuTrigger asChild>
                                <Button variant={"outline"} className="ml-auto">
                                    <MoreVertical />
                                </Button>
                            </DropdownMenuTrigger>
                            <DropdownMenuContent align={"end"}>
                                <DropdownMenuGroup>
                                    <DropdownMenuItem onClick={() => setOpenEditUsers(true)}>
                                        <Users className="size-4" />
                                        Edytuj użytkowników
                                    </DropdownMenuItem>
                                    <DropdownMenuItem onClick={() => setOpenEdit(true)}>
                                        <PenLine className="size-4" />
                                        Edytuj grupę
                                    </DropdownMenuItem>
                                    <DropdownMenuItem onClick={() => setOpenEditAvatar(true)}>
                                        <SquareUserRound className="size-4" />
                                        Edytuj awatar
                                    </DropdownMenuItem>
                                </DropdownMenuGroup>
                            </DropdownMenuContent>
                        </DropdownMenu>
                    )}
                </div>
                <div className={"gap- flex flex-wrap empty:hidden"}></div>
            </div>
        </Fragment>
    );
}
